import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Link, Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import { Container, Div, List, Typography } from '@icecreamsocial/components';
import { useStore } from '../../../../../utils/hooks';
import {
	UserSettingsForm,
	EditFormControls,
	Pagename,
} from '../../../../presentational';
import { DestructiveUserActions } from '../../../../containers';
import { flags } from '../../../../../utils';

/**
 * @feature-flags
 */
const ENABLE_DEACTIVATE_SELF = flags.isActive(
	flags.names.ENABLE_EXPERIMENTAL_DEACTIVATE_SELF
);

const PersonalSettings = () => {
	const store = useStore();

	useEffect(() => {
		store.resolveUserFlow(store.userId);

		return () => store.resolveUserFlowCleanUp();
	}, [store.userId]);

	const initialValues = {
		email: store.Orchestrators.Auth.user.email,
		firstName: store.Orchestrators.Auth.user.firstName,
		lastName: store.Orchestrators.Auth.user.lastName,
		role: store.userRole,
		affiliateId: store.userParentAffiliateId,
		clientId: store.userClientId,
		isTeamAdmin: store.isTeamLeader,
		password: '',
	};

	const handleSubmit = async (values, { resetForm }) => {
		await store.updateUserSettingsFlow({
			role: store.userRole,
			affiliateId: store.userParentAffiliateId,
			clientId: store.userClientId,
			...values,
			company: store.userBusinessId,
		});
		resetForm({
			values: {
				...values,
				password: '',
			},
		});
	};

	return (
		<>
			<Div>
				<Pagename>Account Settings</Pagename>
				<UserSettingsForm
					onSubmit={handleSubmit}
					isEditing={true}
					initialValues={initialValues}
					userAffiliateId={store.userParentAffiliateId}
					userClientId={store.userClientId}
					userRole={store.userRole}
					userIsTeamLeader={store.isTeamLeader}
					renderControls={(props) => (
						<EditFormControls
							isLoading={store.isLoading('userSettings')}
							disabled={store.isLoading('userSettings')}
							{...props}
						/>
					)}
				/>

				{ENABLE_DEACTIVATE_SELF && (
					<>
						<Div
							as="hr"
							borderColor="ambient"
							borderTop="none"
							marginBottom="lg"
						/>
						<DestructiveUserActions
							userId={store.Orchestrators.Auth.user?.id}
							userName={store.userName}
							currentStatus={store.Orchestrators.Auth.user?.status}
							isArchived={store.Orchestrators.Auth.user?.isArchived}
							overridePrompt={{
								CONFIRM_DEACTIVATE: {
									title: 'Deactivate your account?',
									message:
										'You will be logged out and no longer be able to access the admin',
									onConfirm: async () => {
										await store.updateUserStatusFlow(
											{ status: 'disabled' },
											store.Orchestrators.Auth.user?.id
										);
										store.logoutFlow(
											'Your account is disabled.  Contact a Team Leader if you wish to re-enable your account.'
										);
									},
								},
							}}
						/>
					</>
				)}
			</Div>
		</>
	);
};

PersonalSettings.displayName = 'Settings.Personal';

export default observer(PersonalSettings);
