import { createQueryKeys } from '@lukemorales/query-key-factory';

/**
 * reuable query keys for Affiliates for use with useQuery or useMutation
 */
export const queryKeys = createQueryKeys('clients', {
	getAffilaites: ({ affiliateId }) => ({
		affiliateId,
	}),
});
