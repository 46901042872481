import { types } from 'mobx-state-tree';
import { IcsAffiliate } from '@icecreamsocial/mst-models';

const AffiliatesCollection = types
	.model({
		affiliateMap: types.map(IcsAffiliate),
		list: types.array(types.reference(IcsAffiliate)),
	})
	.named('AffiliatesCollection')
	.preProcessSnapshot((snapshot = {}) => ({
		affiliateMap: snapshot.affiliateMap || {},
		list: snapshot.list || [],
	}))
	.volatile((self) => ({selectable: []}) )
	.views((self) => ({
		get hasAffiliates() {
			return !!self.list.length;
		},
		/**
		 * @note preserves insertion order, does not sort
		 */
		get all() {
			const list = [];
			self.affiliateMap.forEach(value => list.push(value));
			return list;
		},
		find(id) {
			return self.list.find((ref) => ref === id);
		},
		affiliate(id) {
			return self.affiliateMap.get(id);
		},
	}))
	.actions((self) => ({
		addAffiliate(affiliate) {
			self.affiliateMap.put(affiliate);
		},
		addAffiliates(affiliates = []) {
			affiliates.forEach((affiliate) => self.addAffiliate(affiliate));
		},
		setAffiliateList(affiliateRefs = []) {
			self.list.replace(affiliateRefs);
		},
		setSelectable(selectable = []) {
			self.selectable = selectable;
		},
		updateAffiliate(id, affiliate) {
			self.affiliateMap.set(id, affiliate);
		},
		clearAll() {
			self.setAffiliateList();
			self.affiliateMap.clear();
		},
	}));

export default AffiliatesCollection;
