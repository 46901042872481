import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
/**
 * @module useRouteBlockerPrompt
 * @category Shared
 * @subcategory Hooks
 * @exports  useRouteBlockerPrompt
 */

/**
 * @callback PromptMessageCallback
 * @param {*} nextLocation history object from `react-router-dom`
 * @returns {boolean} if false, will show the prompt and block route
 */

/**
 * @typedef RouteBlockerControls
 * @property {boolean} showPrompt
 * @property {module:useRouteBlockerPrompt~PromptMessageCallback} overrideMessage
 * @property {function():void} handleConfirmNavigation hides the prompt and allows navigation
 * @property {function():void} handleCancelNavigation shows the prompt and stops navigation
 */

/**
 * @function useRouteBlockerPrompt
 * @param {boolean} when whether or not to block a route and show the prompt
 * @returns {module:useRouteBlockerPrompt~RouteBlockerControls}
 * @description
 * Based on [this article](https://medium.com/@michaelchan_13570/using-react-router-v4-prompt-with-custom-modal-component-ca839f5faf39)
 * To be used with a Prompt component from react-router-dom.  Handles the showing and hiding of a custom prompt, whether to show a custom prompt, and how to handle a confirmation.  everything is opt-in
 */
export const useRouteBlockerPrompt = (when) => {
	const history = useHistory();
	const [showPrompt, setShowPrompt] = useState(false);
	const [confirmedNavigation, setConfirmedNavigation] = useState(false);
	const [redirect, setRedirect] = useState(null);

	const overrideMessage = (nextLocation) => {
		if (!confirmedNavigation) {
			setShowPrompt(true);
			setRedirect(nextLocation.pathname);
			return false;
		}
		return true;
	};

	const handleConfirmNavigation = () => {
		setShowPrompt(false);
		setConfirmedNavigation(true);
	};

	const handleCancelNavigation = () => {
		setShowPrompt(false);
	};

	/**
	 * handle refreshes/closes
	 * we can't show a custom prompt for these events unfortunately.  This will show the default browser prompt
	 */
	useEffect(() => {
		const handleRefreshClose = (e) => {
			if (when) {
				e.preventDefault();
				e.returnValue = '';
			}
		};

		window.addEventListener('beforeunload', handleRefreshClose);
		return () => {
			window.removeEventListener('beforeunload', handleRefreshClose);
		};
	}, [when]);

	/**
	 * Redirect/Confirmation logic
	 */
	useEffect(() => {
		if (confirmedNavigation && redirect) {
			history.push(redirect);
			// reset the confirmation state
			setConfirmedNavigation(false);
		}
	}, [confirmedNavigation, redirect]);

	return {
		showPrompt,
		overrideMessage,
		handleConfirmNavigation,
		handleCancelNavigation,
	};
};
