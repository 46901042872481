import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Div, Panel, Typography } from '@icecreamsocial/components';
import { Link } from 'react-router-dom';

const NoProcessorPanel = () => {
	return (
		<Panel backgroundColor="warningLight" border="none">
			<Panel.Body display="flex" alignItems="center">
				<Div marginRight="md">
					<Typography.Span
						textTransform="capitalize"
						typoWeight="600"
						textColor="warningDark"
					>
						No Reward Processor connected
					</Typography.Span>
					<Typography.P>
						To automatically grant rewards to your customers, connect a payment
						processor
					</Typography.P>
				</Div>
				<Button
					as={Link}
					to={'/settings/reward-processor'}
					flexShrink="0"
					textDecoration="none"
					backgroundColor="bodyInverse"
					textColor="body"
				>
					Connect a Processor
				</Button>
			</Panel.Body>
		</Panel>
	);
};
NoProcessorPanel.displayName = 'NoProcessorPanel';

export default observer(NoProcessorPanel);
