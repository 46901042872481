import { types } from "mobx-state-tree";
import { Pagination, Conversion } from "../../models";

const CampaignConversionsCollection = types
  .compose(
    Pagination,
    types.model({
      conversionMap: types.map(Conversion),
      list: types.array(types.reference(Conversion)),
    })
  )
  .named("ConversionsCollection")
  .preProcessSnapshot((snapshot = {}) => ({
    conversionMap: snapshot.conversionMap || {},
    list: snapshot.list || [],
  }))
  .views((self) => ({
    get hasConversions() {
      return !!self.list.length;
    },
    find(id) {
      return self.list.find((ref) => ref.id === id);
    },
  }))
  .actions((self) => ({
    addConversion(conversion) {
      self.conversionMap.put(conversion);
    },
    addConversions(conversions = []) {
      conversions.forEach((conversion) => self.addConversion(conversion));
    },
    // deleteConversion(id) {
    //   self.list.remove(id);
    //   self.conversionMap.delete(id);
    // },
    setConversions(conversions = []) {
      try {
        self.list.replace([]); // clean up
        conversions.forEach((conversion) => {
          self.addConversion(conversion);
          self.list.push(conversion.id);
        });
      } catch (e) {
        console.error(e);
      }
    },
    setConversionList(conversionRefs = []) {
      self.list.replace(conversionRefs);
    },
    clearAll() {
      self.setConversionList();
      self.conversionMap.clear();
    },
  }));

export default CampaignConversionsCollection;
