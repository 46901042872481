import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Container } from '@icecreamsocial/components';
import ClientsList from './sub-routes/List';
import Client from './sub-routes/Client';
import CreateClient from './sub-routes/Create';
import { PageMeta } from '../../presentational';

const Clients = () => {
	const { path } = useRouteMatch();
	const CreateClientComponent = () => <CreateClient nextPage={path} />;
	const routes = [
		{
			path,
			component: ClientsList,
			exact: true,
		},
		{
			path: `${path}/create`,
			component: CreateClientComponent,
			exact: false,
		},
		{
			path: `${path}/:clientId`,
			component: Client,
			exact: false,
		},
	];

	return (
		<>
			<PageMeta
				title={'Clients | Ice Cream Social'}
				description={'Manage Clients'}
			/>
			<Container>
				<Switch>
					{routes.map((route, i) => (
						<Route key={i} {...route} />
					))}
				</Switch>
			</Container>
		</>
	);
};
Clients.displayName = 'Clients';
export default observer(Clients);
