import React, { useEffect, useState, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { Div, Panel, Typography } from '@icecreamsocial/components';
import {
	ArrowBackIcon,
	CondensedConversions,
	EnhancedForm,
	EditFormControls,
	ConfirmationPrompt,
} from '../../../presentational';
import EditPane from '../EditPane';
import { useStore } from '../../../../utils/hooks';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../../../../queries/influencers';

/**
 * @typedef {import('../../../presentational/CondensedConversions').CondensedConversion} CondensedConversion
 */
/**
 * @typedef {import('../../../presentational/CondensedConversions').OnConversionSelected} OnConversionSelected
 */
/**
 * @typedef {import('../../../presentational/CondensedConversions').MapCollectionToSelected} MapCollectionToSelected
 */

const InfluencerConversionsCancel = ({
	influencer,
	campaignId,
	onConversionCancel,
}) => {
	const store = useStore();
	const [selected, setSelected] = useState(new Set());
	const [showConfirmation, setShowConfirmation] = useState(false);
	const { isLoading, isError, data: conversions } = useQuery(
		queryKeys.listConversions({
			influencerId: influencer?.id,
			campaignId,
		}),
		async () => {
			const conversions = await store.resolveInfluencerConversionsFlow({
				influencerId: influencer?.id,
				campaignId,
			});
			return conversions;
		},
		{ enabled: !!influencer && !!campaignId }
	);

	const cancelRemove = () => setShowConfirmation(false);
	const promptRemoval = () => setShowConfirmation(true);
	const confirmRemoval = async () => {
		await onConversionCancel(selected);
		setShowConfirmation(false);
		handleReset();
	};

	/**
	 * @type {MapCollectionToSelected}
	 */
	const mapCollectionToSelected = (conversion) => selected.has(conversion.id);

	/**
	 * @type {OnConversionSelected}
	 */
	const handleSelect = ({ id }) => {
		selected.has(id) ? selected.delete(id) : selected.add(id);
		setSelected(new Set(selected));
	};

	/**
	 * Resets all selected rows
	 */
	const handleReset = () => {
		setSelected(new Set());
	};

	/**
	 * shows prompt for cancelation
	 */
	const handleSubmit = () => {
		promptRemoval();
	};

	return (
		<EditPane>
			<ConfirmationPrompt
				when={showConfirmation}
				onConfirm={confirmRemoval}
				onCancel={cancelRemove}
				title='Cancel selected referrals?'
				message='This cannot be undone'
				confirmText='Yes, Cancel Referrals'
				cancelText='No, Go Back'
				dangerous
			/>
			<Panel backgroundColor='bodyInverse'>
				<Panel.Body>
					<Typography.H2 typoWeight='500' marginTop='0' textAlign='center'>
						Remove Referrals
					</Typography.H2>
					<Typography.P>
						Referrals can be{' '}
						<Typography.Span typoWeight='bold'>permanently</Typography.Span>{' '}
						canceled and they will no longer contribute to the influencer's
						reward progress, however any generated revenue will still be
						attributed to the influenccer for reporting purposes
					</Typography.P>
					<CondensedConversions
						collection={conversions}
						onSelect={handleSelect}
						mapCollectionToSelected={mapCollectionToSelected}
						selectable
					/>
				</Panel.Body>
			</Panel>
			<EditPane.Controls>
				<EditFormControls
					position='sticky'
					dirty={selected?.size}
					handleReset={handleReset}
					handleSubmit={handleSubmit}
					applyText={'Cancel Referral'}
					dangerous
				/>
			</EditPane.Controls>
		</EditPane>
	);
};
InfluencerConversionsCancel.displayName =
	'InfluencerManager.Conversions.Cancel';

export default observer(InfluencerConversionsCancel);
