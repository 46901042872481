import React, { useEffect, useMemo } from 'react';
import { Instance } from 'mobx-state-tree';
import { observer } from 'mobx-react-lite';
import { useLocation, Link } from 'react-router-dom';
import { IcsPaymentGateway } from '@icecreamsocial/mst-models';
import {
	Div,
	Button,
	InputGroup,
	List,
	Panel,
	Typography,
} from '@icecreamsocial/components';
import { addMinutes, formatISO } from 'date-fns';
import { ProcessorPanel, SquareConnectionStatus } from '../';
import squareLogo from '../../../assets/logo_square.svg';
import { flags, toggle } from '../../../utils';
import { useQueryString, useStore, useCookies } from '../../../utils/hooks';

/**
 * @featureFlags
 */
const ENABLE_EXPERIMENTAL_SQUARE_CONNECT_URL = flags.isActive(
	flags.names.ENABLE_EXPERIMENTAL_SQUARE_CONNECT_URL
);

const DEPRECATED_SQUARE_URL = 'https://connect.squareup.com/oauth2/authorize';
const NEW_SQUARE_URL =
	'https://squareup.com/t/cmtp_performance/pr_cross_product/d_partnerships/subch_oauth/p_icecreamsocial/l_us/';

/**
 * @module SquarePanel
 * @category Components
 * @subcategory Presentational
 */

/**
 * @depends on IcsPaymentGateway model
 */

/**
 * @callback onProcessorToggle
 * @param {boolean} selected whether or not the toggle object has been selected
 * @return {*}
 */

/**
 *
 * @param {object} props square panel props
 * @param {boolean} [props.activated = false]
 * @param {boolean} [props.selected = false]
 * @param {Instance<typeof IcsPaymentGateway> | object} [props.processorData]
 * @param {function} [props.beforeRedirect = undefined]
 * @param {module:SquarePanel~onProcessorToggle} props.onProcessorToggle
 * @param {*} props.onDisconnect
 */
//TODO remove store jocelyn

const SquarePanel = ({
	selected = false,
	processorData = {},
	beforeRedirect = () => undefined,
	onProcessorToggle,
	onDisconnect,
}) => {
	const store = useStore();
	const { query, qs } = useQueryString();
	const { pathname } = useLocation();
	const { squareLocationId } = processorData;
	const { getCookie, setCookie } = useCookies();
	const scope = store.Orchestrators.Auth.squarePermissionRequests.join(' ');
	const squareOAuthUrl = toggle(
		ENABLE_EXPERIMENTAL_SQUARE_CONNECT_URL,
		NEW_SQUARE_URL,
		DEPRECATED_SQUARE_URL
	);

	const deprecated_params = {
		scope,
		client_id: process.env.SQUARE_CLIENT_ID,
	};
	const new_params = {
		route: 'oauth2/authorize',
		client_id: process.env.SQUARE_CLIENT_ID,
	};
	const params = toggle(
		ENABLE_EXPERIMENTAL_SQUARE_CONNECT_URL,
		new_params,
		deprecated_params
	);
	const encode = toggle(ENABLE_EXPERIMENTAL_SQUARE_CONNECT_URL, false, true);
	const authParams = qs.stringify(params, { encode });
	const squareUrl = `${squareOAuthUrl}?${authParams}`;

	const handleNavigateToSquare = async (e) => {
		e.preventDefault();
		beforeRedirect();

		const data = await store.generateStateParam();
		const param = (data.user[0] || {}).stateParameter;
		// cookie will expire 15 minutes from when the initial auth request is made
		const expires = addMinutes(Date.now(), 15);
		setCookie('squareConnectOrigin', pathname, expires, '/');
		window.open(`${squareUrl}&state=${param}&session=false`, '_self');
	};

	const activated = useMemo(() => !!processorData.cardFlexSecurityKey, [
		processorData,
	]);

	return (
		<ProcessorPanel
			name='Square'
			description='Reward influencers with a Square gift card'
			isActivated={activated}
			isFocused={selected}
			handleChangeProcessor={onProcessorToggle}
			logoSrc={squareLogo}
		>
			{!Boolean(squareLocationId) ? (
				<>
					<Typography.Span typoWeight='bold'>
						Connect with Square
					</Typography.Span>
					<Typography.P>
						To connect with Square, you'll be redirected to their site
					</Typography.P>
					<Button
						onClick={async (e) => await handleNavigateToSquare(e)}
						backgroundColor='brand'
						textColor='bodyInverse'
					>
						Connect Square Account
					</Button>
				</>
			) : (
				<SquareConnectionStatus onDisconnect={onDisconnect} />
			)}
		</ProcessorPanel>
	);
};
SquarePanel.displayName = 'BusinessSquarePanelForm';

export default observer(SquarePanel);
