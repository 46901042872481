import React from 'react';
import { observer } from 'mobx-react-lite';
import { Img, Div, Container, Typography } from '@icecreamsocial/components';
import icsRewards from '../../../assets/ics-reward.png';

const NoRewardData = () => {
	return (
		<Container padding='md' backgroundColor='bodyInverse'>
			<Div textAlign='center'>
				<Img elWidth='40%' flexShrink='0' src={icsRewards} />
			</Div>
			<Div textAlign='center' paddingTop='md'>
				<Typography.Span typoWeight='600'>No reward data yet</Typography.Span>
				<Typography.P>
					It looks like we don't have any data to show yet! As soon as your
					influencers start hitting their goals and you issue rewards, you'll
					see reward details here
				</Typography.P>
			</Div>
		</Container>
	);
};

NoRewardData.displayName = 'NoRewardData';
export default observer(NoRewardData);
