import { Button, Div, Panel, Typography } from '@icecreamsocial/components';
import React from 'react';
import BaseModal from '../../../BaseModal';
import CloseButton from '../../../CloseButton';

export const Disclaimer = ({
	isOpen,
	onClose,
	onAgree,
	onCancel,
	showButtons,
}) => (
	<BaseModal isOpen={isOpen}>
		<Panel
			backgroundColor='ambientLight'
			border='none'
			color='inverse'
			elWidth={['100%', '50%']}
			elHeight={['100%', '90%']}
			margin='auto'
			shadow='raised'
		>
			<Panel.Header
				backgroundColor='transparent'
				display='flex'
				justifyContent='space-between'
				alignItems='center'
				textColor='body'
			>
				<Typography.H1 margin='none'>Client Policy Disclaimer</Typography.H1>
				<CloseButton onClick={onClose} />
			</Panel.Header>
			<Panel.Body paddingTop='none'>
				<Typography.P>
					It’s your responsibility to familiarize yourself with your
					organization's data and privacy laws, regulations, and internal
					policies in addition to understanding how Ice Cream Social processes
					data.
				</Typography.P>
				<Typography.P>
					Your organization's policies and referral campaigns must adhere to and
					be compliant with any applicable regional data laws, like GDPR or
					CCPA.
				</Typography.P>

				<Typography.P>
					EU, EEA, UK, and Switzerland, for example, require customers to give
					permission before their data can be collected and used, whereas other
					regions might vary in this respect.
				</Typography.P>

				<Typography.P>
					As data controller, and as regional privacy and data laws dictate, you
					are responsible for fulfilling any requests from your Ice Cream Social
					influencers to exercise their rights pertaining to their data, such as
					removal or modification. Ice Cream Social cannot fulfill these
					requests on your behalf, but will provide the tools needed to do so.
				</Typography.P>

				<Typography.P>
					This disclaimer does not constitute legal advice and you should seek
					help from legal counsel as needed. Any binding contractual or legal
					terms are included in Ice Cream Social's{' '}
					<Typography.A
						target='_blank'
						href='https://icecreamsocial.io/privacy-policy-terms/ '
					>
						Privacy Policy
					</Typography.A>{' '}
					and{' '}
					<Typography.A
						target='_blank'
						href='https://icecreamsocial.io/terms-of-service/'
					>
						Terms of Service
					</Typography.A>
				</Typography.P>
				{showButtons && (
					<Div marginTop='lg'>
						<Button
							type='button'
							onClick={onAgree}
							backgroundColor='brand'
							textColor='bodyInverse'
							display='block'
							elWidth='100%'
							marginBottom='md'
						>
							Agree
						</Button>
						<Button
							type='button'
							display='block'
							elWidth='100%'
							onClick={onCancel}
						>
							Cancel
						</Button>
					</Div>
				)}
			</Panel.Body>
		</Panel>
	</BaseModal>
);
