import React from "react";
import { observer } from "mobx-react-lite";
import { Typography, Panel } from "@icecreamsocial/components";

const ProcessingError = ({ message }) => {
  return (
    <Panel textColor="bad" borderColor="bad">
      <Panel.Body>
        <Typography.H3>Reward not issued!</Typography.H3>
        <Typography.P>
          An error occured while attempting to process this transaction. Please
          try again later or review error message:
        </Typography.P>
        <Typography.P style={{ fontStyle: "italic" }}>{message}</Typography.P>
      </Panel.Body>
    </Panel>
  );
};
ProcessingError.displayName = "InfluencerManager.ProcessingError";

export default observer(ProcessingError);
