import React from 'react';
import { filterProviderFactory } from '../../../../../utils/filterProviderFactory';

const { Provider, useFilters } = filterProviderFactory();
/**
 * Dashboard filters
 */
export const DashboardFiltersProvider = ({ children }) => {
	const mapControlsToState = ({
		chart = 'totalRevenue',
		affiliate,
		campaign,
		client,
		fromDate,
		toDate,
		...ignored
	}) => {
		const state = {
			affiliateId: affiliate?.value,
			clientId: client?.value,
			campaignId: campaign?.value,
			chart,
			fromDate,
			toDate,
		};

		return state;
	};

	return (
		<Provider mapControlsToState={mapControlsToState}>{children}</Provider>
	);
};

export const useDashboardFilters = useFilters;
