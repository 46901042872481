"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ic_add = void 0;
var ic_add = {
  "viewBox": "0 0 24 24",
  "children": [{
    "name": "path",
    "attribs": {
      "d": "M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"
    }
  }]
};
exports.ic_add = ic_add;