import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Div, InputGroup, Typography, Panel } from '@icecreamsocial/components';
import { RequiredAsterisk } from '../../../../presentational';
import { RadioGroup, Radio } from '@material-ui/core';
import { flags } from '../../../../../utils';

const SquareOffers = ({
	value,
	defaultFixedGiftCardCriteria,
	handleChange,
}) => {
	const [rewardType, setRewardType] = useState(value ? 'credit' : 'custom');

	useEffect(() => {
		setRewardType(value ? 'credit' : 'custom');
	}, [value]);

	console.debug(defaultFixedGiftCardCriteria);

	return (
		<RadioGroup
			name='offerMethod'
			value={rewardType}
			onChange={(e) => setRewardType(e.target.value)}
		>
			<Panel marginBottom='lg' backgroundColor='ambientLight'>
				<Panel.Body padding='md'>
					<InputGroup>
						<InputGroup.Fields alignItems='center' border='none' shadow='none'>
							<Div typoSize='lg'>
								<Radio
									name='credit'
									value='credit'
									onChange={handleChange}
									color='primary'
								/>
							</Div>
							<Div>
								<InputGroup.Label typoWeight='600'>
									Square Gift Card
								</InputGroup.Label>
								<Typography.P marginTop='sm'>
									Since your business has a Square account, influencers can be
									easily rewarded with a{' '}
									<Typography.A
										href='https://squareup.com/us/en/gift-cards'
										visitedTextColor='link'
									>
										Square Gift Card
									</Typography.A>
								</Typography.P>
							</Div>
						</InputGroup.Fields>
					</InputGroup>
					{rewardType === 'credit' && (
						<Div>
							<hr />
							<InputGroup marginBottom='lg'>
								<InputGroup.Label typoWeight='500'>
									Gift Card Value <RequiredAsterisk />
								</InputGroup.Label>
								<Div display='flex' alignItems='center'>
									<InputGroup.Fields marginRight='md'>
										<InputGroup.Input
											id='giftCardAmount'
											name='giftCardAmount'
											paddingLeft='md'
											value={value}
											onChange={handleChange}
										/>
									</InputGroup.Fields>
									<Typography.Span textColor='ambientDark'>USD</Typography.Span>
								</Div>
							</InputGroup>
							<RadioGroup
								name='useFixedGiftCardValue'
								defaultValue={`${defaultFixedGiftCardCriteria}`}
							>
								<InputGroup.Label typoWeight='500'>
									Gift Card Value Criteria
								</InputGroup.Label>
								<InputGroup elWidth='100%'>
									<Div display='flex' alignItems='center' marginBottom='sm'>
										<Radio
											color='primary'
											value='true'
											defaultChecked='true'
											onChange={handleChange}
										/>{' '}
										<InputGroup.Label display='inline-block' typoSize='1.1em'>
											Always issue full amount{' '}
											<Typography.Span typoWeight='500'>
												(
												{value.toLocaleString('en-US', {
													style: 'currency',
													currency: 'USD',
													minimumFractionDigits: 0,
												})}
												)
											</Typography.Span>
										</InputGroup.Label>
									</Div>
								</InputGroup>
								<InputGroup elWidth='100%' marginBottom='md'>
									<Div display='flex' alignItems='center' marginBottom='sm'>
										<Radio
											color='primary'
											value='false'
											onChange={handleChange}
										/>{' '}
										<InputGroup.Label display='inline-block' typoSize='1.1em'>
											Match influencer's transaction value{' '}
											<Typography.Span typoWeight='500'>
												(up to{' '}
												{value.toLocaleString('en-US', {
													style: 'currency',
													currency: 'USD',
													minimumFractionDigits: 0,
												})}
												)
											</Typography.Span>
										</InputGroup.Label>
									</Div>
								</InputGroup>
							</RadioGroup>
						</Div>
					)}
				</Panel.Body>
			</Panel>
			{flags.isActive(flags.names.ENABLE_SQUARE_CUSTOM_REWARD) && (
				<InputGroup>
					<InputGroup.Fields
						padding='md'
						backgroundColor='ambientLight'
						alignItems='center'
						border='none'
					>
						<Div>
							<Radio value='custom' onChange={handleChange} color='primary' />
						</Div>
						<Div>
							<InputGroup.Label typoWeight='600'>
								Custom Reward
							</InputGroup.Label>
							<Typography.P marginTop='sm'>
								Select this option if you will manually reward an influencer by
								another method (For example: gift card, store credit, free
								product)
							</Typography.P>
						</Div>
					</InputGroup.Fields>
				</InputGroup>
			)}
		</RadioGroup>
	);
};
SquareOffers.displayName = 'SquareOffers';

export default SquareOffers;
