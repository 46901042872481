import { useLocation, Link } from 'react-router-dom';
import queryString from 'query-string';
import { useMemo } from 'react';
/**
 * @module useQueryString
 * @category Shared
 * @subcategory Hooks
 * @exports  useQueryString
 */

/**
 * @typedef QueryStringFns
 * @property {Object.<string, string>} query
 * @property {module:useQueryString~contains} contains
 * @property {module:useQueryString~deleteParam} deleteParam
 * @property {module:useQueryString~withoutParam} withoutParam
 * @property {module:useQueryString~withoutParams} withoutParams
 * @property {module:useQueryString~getParam} getParam
 * @property {module:useQueryString~addParam} addParam
 * @property {*} qs the instance of `query-string`
 */

/**
 * @function
 * @description A wrapper around the `query-string` library that exposes some commonly used
 * methods
 * @returns {module:useQueryString~QueryStringFns} the utility methods
 */
export const useQueryString = () => {
	const { search } = useLocation();
	const query = useMemo(() => queryString.parse(search), [search]);

	/**
	 * @callback contains
	 * @param {string} param the query param to check for
	 * @return {boolean} whether or not the current query string contains the param
	 */
	function contains(param) {
		return Boolean(query[param]);
	}

	/**
	 * @callback deleteParam
	 * @param {string} param the query param to delete
	 * @return {Object.<string, string>} the query object without the param
	 */
	function deleteParam(param) {
		const q = { ...query };
		if (contains(param)) {
			delete q[param];
		}
		return q;
	}

	/**
	 * @callback withoutParam
	 * @param {string} param the query param to remove
	 * @returns {string} the query string without the param
	 */
	function withoutParam(param) {
		return queryString.stringify(deleteParam(param));
	}

	/**
	 * @callback withoutParams
	 * @param {...string} param the query params
	 * @returns {string} the query string without the params
	 */
	function withoutParams(...params) {
		const q = { ...query };
		params.forEach((param) => {
			delete q[param];
		});
		return queryString.stringify(q);
	}

	function numParams() {
		return query.size;
	}

	/**
	 * @callback getParam
	 * @param {string} param the param to get
	 * @returns {string|undefined} the value of the param or undefined if not found
	 */
	function getParam(param) {
		return query[param];
	}

	/**
	 * @callback hasParam
	 * @param {string} param the param to check
	 * @returns {boolean} the value of the param or undefined if not found
	 */
	function hasParam(param) {
		return !!query[param];
	}

	/**
	 * @callback addParam
	 * @param {object} paramToAdd
	 * @param {string} paramToAdd.key the name of the param to add
	 * @param {string} paramToAdd.value the value of the param to add
	 * @returns {string} the created query string
	 */
	function addParam({ key, value }) {
		const q = { ...query };
		q[key] = value;
		return queryString.stringify(q);
	}

	function addParams(...keyValues) {
		const q = { ...query };
		const search = keyValues.reduce(
			(qNew, pair) => ({ ...qNew, [pair.key]: pair.value }),
			q
		);
		return queryString.stringify(search);
	}

	return {
		query,
		contains,
		withoutParam,
		withoutParams,
		deleteParam,
		getParam,
		hasParam,
		addParam,
		addParams,
		qs: queryString,
	};
};
