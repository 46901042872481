import React, { useState, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import {
	Div,
	Button,
	InputGroup,
	List,
	Panel,
	Tag,
	Typography,
} from '@icecreamsocial/components';
import { useFormik } from 'formik';
import { ProcessorPanel, RequiredAsterisk, RouteChangeBlocker } from '..';
import nmiLogo from '../../../assets/logo_nmi.jpg';
import { useStore } from '../../../utils/hooks';

const NmiPanel = ({
	selected = false,
	processorData = {},
	onProcessorToggle,
	onSubmit,
	onDisconnect,
}) => {
	const nmiForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			cardFlexSecurityKey: processorData.cardFlexSecurityKey,
		},
		onSubmit,
	});

	const activated = useMemo(() => !!processorData.cardFlexSecurityKey, [
		processorData.cardFlexSecurityKey,
	]);

	return (
		<>
			<ProcessorPanel
				name="NMI"
				description="Reward influencers through order refund credit"
				isActivated={activated}
				isFocused={selected}
				handleChangeProcessor={onProcessorToggle}
				logoSrc={nmiLogo}
			>
				{!activated ? (
					<form onSubmit={nmiForm.handleSubmit}>
						<InputGroup elWidth="100%" marginBottom="md">
							<InputGroup.Label>
								Security Key <RequiredAsterisk />
							</InputGroup.Label>
							<InputGroup.Fields>
								<InputGroup.Input
									id="cardFlexSecurityKey"
									name="cardFlexSecurityKey"
									paddingLeft="md"
									value={nmiForm.values.cardFlexSecurityKey}
									onChange={nmiForm.handleChange}
								/>
							</InputGroup.Fields>
						</InputGroup>
						<Div textAlign="right">
							<Button
								type="submit"
								backgroundColor="brand"
								textColor="bodyInverse"
							>
								Connect
							</Button>
						</Div>
					</form>
				) : (
					<Div display="flex" alignItems="center">
						<Tag
							textColor="good"
							borderColor="good"
							marginRight="lg"
							textTransform="capitalize"
						>
							{processorData.status}
						</Tag>
						<Div flexGrow="1">
							<Typography.Span display="block" typoWeight="bold">
								Security Key
							</Typography.Span>
							<Typography.Span>*************</Typography.Span>
						</Div>
						<Button
							onClick={onDisconnect}
							backgroundColor="ambientLight"
							padding="sm"
							paddingLeft="lg"
							paddingRight="lg"
						>
							Disconnect
						</Button>
					</Div>
				)}
			</ProcessorPanel>
			<RouteChangeBlocker
				when={nmiForm.dirty}
				title={'Exit Reward Processor Setup?'}
				message={"Your progress won't be saved"}
				confirmText="Discard Changes & Exit"
				cancelText={'Continue Setup'}
			/>
		</>
	);
};
NmiPanel.displayName = 'BusinessNmiPanelForm';

export default observer(NmiPanel);
