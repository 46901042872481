import React from 'react';
import { Switch } from '@material-ui/core';
import { InputGroup, Typography } from '@icecreamsocial/components';
import { useScriptSettings } from '../../../../providers';

export const V1BehaviorCustomizer = () => {
	const { settings, updateBehavior } = useScriptSettings();
	const handleChange = (field) => (e) => {
		const { checked } = e.currentTarget;
		updateBehavior({ [field]: checked });
	};

	return (
		<form>
			<Typography.H3 marginTop='none'>Display</Typography.H3>
			<InputGroup display='flex' alignItems='center'>
				<Switch
					name={'hide'}
					color='primary'
					defaultChecked={false}
					checked={settings.behavior?.hide}
					onChange={handleChange('hide')}
				/>{' '}
				<InputGroup.Label display='inline-block'>
					Hide ICS on page load
				</InputGroup.Label>
			</InputGroup>
			<Typography.Span as='small' typoSize='70%'>
				If active, the script will run in the background but the widget won't be
				viewable
			</Typography.Span>
		</form>
	);
};
