import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Div, Typography } from '@icecreamsocial/components';
import OfferSettingsForm from '../../../../components/OfferSettingsForm';
import { useStore } from '../../../../../../../utils/hooks';
import {
	NoProcessorPanel,
	SteppedFormControls,
	Pagename,
} from '../../../../../../presentational';

const Offer = ({ handleSubmit, returnTo, savedValues = {} }) => {
	const store = useStore();

	useEffect(() => {
		store.getProcessorFlow();
	}, []);

	const isSquare = useMemo(
		() => store.userProcessorType?.toLowerCase().indexOf('square') > -1,
		[store.userProcessorType]
	);

	const hasProcessor = useMemo(() => !!store.userProcessorType, [
		store.userProcessorType,
	]);

	return (
		<>
			{!hasProcessor && <NoProcessorPanel />}
			<Pagename>Offer Settings</Pagename>
			<OfferSettingsForm
				onSubmit={handleSubmit}
				isSquare={isSquare}
				hasProcessor={hasProcessor}
				allowRouteBlock={false}
				renderControls={() => <SteppedFormControls onNav={returnTo} />}
				{...savedValues}
			/>
		</>
	);
};
Offer.displayName = 'Campaigns.Setup.Offer';

export default observer(Offer);
