import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Div, Panel, Typography } from '@icecreamsocial/components';
import BaseTable from '../BaseTable';
import { formatCurrency, formatDateTime } from '../../../utils';

const RewardsTable = ({ collection = [], ...rest }) => {
	const textOverflowStyles = {
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
	};

	let columns = [
		{
			name: 'Value',
			selector: (row) => row.rewardAmount,
			cell: (row) => (
				<Typography.Span>
					{formatCurrency(row.rewardAmount, 'en-US', {
						currency: 'USD',
						style: 'currency',
					})}
				</Typography.Span>
			),
			grow: 0,
			sortable: true,
			center: true,
		},
		{
			name: 'Influencer',
			selector: (row) => row.influencerId || row.influencerEmail,
			cell: (row) => (
				<Div padding='sm' paddingLeft='none'>
					{row.name && (
						<Typography.Span
							display='block'
							textTransform='capitalize'
							textDecoration='none'
						>
							{row.name || '-'}
						</Typography.Span>
					)}
					<Typography.Span
						typoWeight='bold'
						textTransform='capitalize'
						display='block'
						textDecoration='none'
						marginBottom='sm'
					>
						{row.influencerEmail || '-'}
					</Typography.Span>
				</Div>
			),
			sortable: true,
			grow: 1,
		},
		{
			name: 'Reward Type',
			selector: (row) => row.rewardType,
			cell: (row) => (
				<Typography.Span textTransform='capitalize'>
					{row.rewardType}
				</Typography.Span>
			),
			grow: 0,
		},
		{
			name: 'Transaction Id',
			selector: (row) => row.transactionId,
			cell: (row) => (
				<Typography.Span textDecoration='none'>
					{row.transactionId || '-'}
				</Typography.Span>
			),
			grow: 1,
		},
		{
			name: 'Issued',
			selector: (row) => row.createdAt,
			cell: (row) => (
				<Typography.Span
					style={textOverflowStyles}
					title={formatDateTime(new Date(row.createdAt))}
				>
					{formatDateTime(new Date(row.createdAt))}
				</Typography.Span>
			),
			sortable: true,
		},
	];

	return <BaseTable columns={columns} collection={collection} {...rest} />;
};
RewardsTable.displayName = 'RewardsTable';

export default observer(RewardsTable);
