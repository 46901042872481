import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory, Link } from 'react-router-dom';
import { Div, Spanner, Typography } from '@icecreamsocial/components';
import { useStore } from '../../../../../utils/hooks';
import { HeaderPortal } from '../../../Main';
import {
	UserAccountSettingsForm,
	UserSettingsForm,
	ArrowBackIcon,
	EditFormControls,
	RouteChangeBlocker,
} from '../../../../presentational';

const CreateUser = ({ nextPage = undefined }) => {
	const store = useStore();
	const history = useHistory();
	const [allowNavigation, setAllowNavigation] = useState(false);

	// Focus current authenticated user so that their parent client/affiliate will be used as defaults
	const { id: userId } = store.Orchestrators.Auth.user;

	useEffect(() => {
		store.resolveUserFlow(userId);

		return () => store.resolveUserFlowCleanUp();
	}, [userId]);

	const initialValues = {
		isTeamAdmin: false,
		firstName: '',
		lastName: '',
		email: '',
		password: '',
		role: store.userRole === 'affiliate' ? '' : 'client',
		affiliateId: store.userParentAffiliateId,
		clientId: store.userClientId
			? { value: store.userClientId, label: '' }
			: null,
	};

	const handleSubmit = async ({ clientId, ...values }) => {
		await store.createUserFlow({ clientId: clientId?.value, ...values });
		if (nextPage) {
			setAllowNavigation(true);
			history.push(nextPage);
		}
	};

	return (
		<>
			<HeaderPortal>
				<Div backgroundColor='#EBEBEB' textAlign='center'>
					<Spanner grid gridColumns='repeat(3, 1fr)'>
						<Typography.A
							as={Link}
							to='/users'
							textColor='body'
							visitedTextColor='body'
							display='flex'
							alignItems='center'
						>
							<ArrowBackIcon marginRight='md' /> Back
						</Typography.A>
						<Typography.Span>New User</Typography.Span>
					</Spanner>
				</Div>
			</HeaderPortal>
			<Div marginTop='lg'>
				<UserSettingsForm
					allowRouteBlock={false}
					initialValues={initialValues}
					userClientId={store.userClientId}
					userAffiliateId={store.userParentAffiliateId}
					userIsTeamLeader={store.isTeamLeader}
					userRole={store.userRole}
					onSubmit={handleSubmit}
					renderControls={(props) => (
						<EditFormControls
							forceShow={true}
							isLoading={store.isLoading('userSettings')}
							disabled={store.isLoading('userSettings')}
							{...props}
						/>
					)}
				/>
			</Div>
			<RouteChangeBlocker
				when={!allowNavigation}
				title='Abandon User?'
				message='This user will not be created'
				confirmText='Abandon User'
				cancelText='Continue User Creation'
			/>
		</>
	);
};

CreateUser.displayName = 'Users.Create';

export default observer(CreateUser);
