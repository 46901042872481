import React from 'react';
import { Icon } from 'react-icons-kit';
import { ic_account_circle } from 'react-icons-kit/md/ic_account_circle';
import { ic_add } from 'react-icons-kit/md/ic_add';
import { ic_arrow_back } from 'react-icons-kit/md/ic_arrow_back';
import { ic_arrow_drop_down } from 'react-icons-kit/md/ic_arrow_drop_down';
import { ic_arrow_drop_up } from 'react-icons-kit/md/ic_arrow_drop_up';
import { ic_check } from 'react-icons-kit/md/ic_check';
import { ic_close } from 'react-icons-kit/md/ic_close';
import { ic_delete } from 'react-icons-kit/md/ic_delete';
import { ic_edit } from 'react-icons-kit/md/ic_edit';
import { ic_email } from 'react-icons-kit/md/ic_email';
import { ic_error } from 'react-icons-kit/md/ic_error';
import { ic_event } from 'react-icons-kit/md/ic_event';
import { ic_info } from 'react-icons-kit/md/ic_info';
import { ic_link } from 'react-icons-kit/md/ic_link';
import { ic_location_on } from 'react-icons-kit/md/ic_location_on';
import { ic_lock } from 'react-icons-kit/md/ic_lock';
import { ic_remove } from 'react-icons-kit/md/ic_remove';
import { ic_search } from 'react-icons-kit/md/ic_search';
import { ic_share } from 'react-icons-kit/md/ic_share';
import { ic_shopping_cart } from 'react-icons-kit/md/ic_shopping_cart';
import { ic_sms } from 'react-icons-kit/md/ic_sms';
import { ic_timer } from 'react-icons-kit/md/ic_timer';
import { ic_loyalty } from 'react-icons-kit/md/ic_loyalty';
import { ic_dashboard } from 'react-icons-kit/md/ic_dashboard';
import { ic_star_border } from 'react-icons-kit/md/ic_star_border';
import { ic_people_outline } from 'react-icons-kit/md/ic_people_outline';
import { ic_reorder } from 'react-icons-kit/md/ic_reorder';
import { ic_settings } from 'react-icons-kit/md/ic_settings';
import { ic_file_download } from 'react-icons-kit/md/ic_file_download';
import { ic_file_upload } from 'react-icons-kit/md/ic_file_upload';
import { ic_code } from 'react-icons-kit/md/ic_code';
import { ic_open_in_new } from 'react-icons-kit/md/ic_open_in_new';
import { ic_content_copy } from 'react-icons-kit/md/ic_content_copy';
import { ic_business } from 'react-icons-kit/md/ic_business';
import { ic_store } from 'react-icons-kit/md/ic_store';
import { ic_phone } from 'react-icons-kit/md/ic_phone';
import { ic_chevron_left } from 'react-icons-kit/md/ic_chevron_left';
import { ic_chevron_right } from 'react-icons-kit/md/ic_chevron_right';
import { Typography } from '@icecreamsocial/components';

/**
 * @module SupportedIcons
 * @category Components
 * @subcategory Presentational
 * @description
 * This file can be narrowed down to just one generic icon component, but doing this for convenience.  It does make the bundle a bit bigger and doesn't take advantage of code splitting
 * but the semantics and readability of it are very useful.  it will also give us a single point for changing icons in case we want to switch later on.
 */

/**
 * @typedef IconSize
 * @property {number} [size = 24] the icon size
 */

/**
 * @param {module:SupportedIcons~IconSize} props
 * @description
 * A + icon.
 * @component
 * @example
 * return (<AddIcon size={36}/>)
 * @todo
 * document this and the other icons
 */
export const AddIcon = ({ size = 24, ...rest }) => (
	<Typography.Span
		as={Icon}
		icon={ic_add}
		size={size}
		typoWeight={'bold'}
		display='inline-flex !important'
		alignItems='center'
		{...rest}
	/>
);

export const ArrowBackIcon = ({ size = 24, ...rest }) => (
	<Typography.Span
		as={Icon}
		icon={ic_arrow_back}
		size={size}
		typoWeight={'bold'}
		display='inline-flex !important'
		alignItems='center'
		{...rest}
	/>
);

export const ShareIcon = ({ size = 24, ...rest }) => (
	<Typography.Span
		as={Icon}
		icon={ic_share}
		size={size}
		typoWeight={'bold'}
		display='inline-flex !important'
		alignItems='center'
		{...rest}
	/>
);

export const CartIcon = ({ size = 24, ...rest }) => (
	<Typography.Span
		as={Icon}
		icon={ic_shopping_cart}
		size={size}
		typoWeight={'bold'}
		display='inline-flex !important'
		alignItems='center'
		{...rest}
	/>
);

export const SmsIcon = ({ size = 24, ...rest }) => (
	<Typography.Span
		as={Icon}
		icon={ic_sms}
		size={size}
		typoWeight={'bold'}
		display='inline-flex !important'
		alignItems='center'
		{...rest}
	/>
);

export const CheckIcon = ({ size = 24, ...rest }) => (
	<Typography.Span
		as={Icon}
		icon={ic_check}
		size={size}
		typoWeight={'bold'}
		display='inline-flex !important'
		alignItems='center'
		{...rest}
	/>
);

export const CloseIcon = ({ size = 24, ...rest }) => (
	<Typography.Span
		as={Icon}
		icon={ic_close}
		size={size}
		typoWeight={'bold'}
		display='inline-flex !important'
		alignItems='center'
		{...rest}
	/>
);

export const DeleteIcon = ({ size = 24, ...rest }) => (
	<Typography.Span
		as={Icon}
		icon={ic_delete}
		size={size}
		typoWeight={'bold'}
		display='inline-flex !important'
		alignItems='center'
		{...rest}
	/>
);

export const DownloadIcon = ({ size = 24, ...rest }) => (
	<Typography.Span
		as={Icon}
		icon={ic_file_download}
		size={size}
		typoWeight={'bold'}
		display='inline-flex !important'
		alignItems='center'
		{...rest}
	/>
);
export const DropDownIcon = ({ size = 24, ...rest }) => (
	<Typography.Span
		as={Icon}
		icon={ic_arrow_drop_down}
		size={size}
		typoWeight={'bold'}
		display='inline-flex !important'
		alignItems='center'
		{...rest}
	/>
);

export const DropUpIcon = ({ size = 24, ...rest }) => (
	<Typography.Span
		as={Icon}
		icon={ic_arrow_drop_up}
		size={size}
		typoWeight={'bold'}
		display='inline-flex !important'
		alignItems='center'
		{...rest}
	/>
);
export const EditIcon = ({ size = 24, ...rest }) => (
	<Typography.Span
		as={Icon}
		icon={ic_edit}
		size={size}
		typoWeight={'bold'}
		display='inline-flex !important'
		alignItems='center'
		{...rest}
	/>
);

export const EmailIcon = ({ size = 24, ...rest }) => (
	<Typography.Span
		as={Icon}
		icon={ic_email}
		size={size}
		typoWeight={'bold'}
		display='inline-flex !important'
		alignItems='center'
		{...rest}
	/>
);

export const ErrorIcon = ({ size = 24, ...rest }) => (
	<Typography.Span
		as={Icon}
		icon={ic_error}
		size={size}
		typoWeight={'bold'}
		display='inline-flex !important'
		alignItems='center'
		{...rest}
	/>
);

export const EventIcon = ({ size = 24, ...rest }) => (
	<Typography.Span
		as={Icon}
		icon={ic_event}
		size={size}
		typoWeight={'bold'}
		display='inline-flex !important'
		alignItems='center'
		{...rest}
	/>
);

export const InfoIcon = ({ size = 24, ...rest }) => (
	<Typography.Span
		as={Icon}
		icon={ic_info}
		size={size}
		typoWeight={'bold'}
		display='inline-flex !important'
		alignItems='center'
		{...rest}
	/>
);

export const LinkIcon = ({ size = 24, ...rest }) => (
	<Typography.Span
		as={Icon}
		icon={ic_link}
		size={size}
		typoWeight={'bold'}
		display='inline-flex !important'
		alignItems='center'
		{...rest}
	/>
);

export const LocationIcon = ({ size = 24, ...rest }) => (
	<Typography.Span
		as={Icon}
		icon={ic_location_on}
		size={size}
		typoWeight={'bold'}
		display='inline-flex !important'
		alignItems='center'
		{...rest}
	/>
);

export const PromoCodeIcon = ({ size = 24, ...rest }) => (
	<Typography.Span
		as={Icon}
		icon={ic_loyalty}
		size={size}
		typoWeight={'bold'}
		display='inline-flex !important'
		alignItems='center'
		{...rest}
		{...rest}
	/>
);

export const RemoveIcon = ({ size = 24, ...rest }) => (
	<Typography.Span
		as={Icon}
		icon={ic_remove}
		size={size}
		typoWeight={'bold'}
		display='inline-flex !important'
		alignItems='center'
		{...rest}
	/>
);

export const SearchIcon = ({ size = 24, ...rest }) => (
	<Typography.Span
		as={Icon}
		icon={ic_search}
		size={size}
		typoWeight={'bold'}
		display='inline-flex !important'
		alignItems='center'
		{...rest}
	/>
);

export const SecureIcon = ({ size = 24, ...rest }) => (
	<Typography.Span
		as={Icon}
		icon={ic_lock}
		size={size}
		typoWeight={'bold'}
		display='inline-flex !important'
		alignItems='center'
		{...rest}
	/>
);

export const TimerIcon = ({ size = 24, ...rest }) => (
	<Typography.Span
		as={Icon}
		icon={ic_timer}
		size={size}
		typoWeight={'bold'}
		display='inline-flex !important'
		alignItems='center'
		{...rest}
	/>
);

export const UserIcon = ({ size = 24, ...rest }) => (
	<Typography.Span
		as={Icon}
		icon={ic_account_circle}
		size={size}
		typoWeight={'bold'}
		display='inline-flex !important'
		alignItems='center'
		{...rest}
	/>
);

export const DashboardIcon = ({ size = 24, ...rest }) => (
	<Typography.Span
		as={Icon}
		icon={ic_dashboard}
		size={size}
		typoWeight={'bold'}
		display='inline-flex !important'
		alignItems='center'
		{...rest}
	/>
);

export const CampaignsIcon = ({ size = 24, ...rest }) => (
	<Typography.Span
		as={Icon}
		icon={ic_star_border}
		size={size}
		typoWeight={'bold'}
		display='inline-flex !important'
		alignItems='center'
		{...rest}
	/>
);

export const UsersIcon = ({ size = 24, ...rest }) => (
	<Typography.Span
		as={Icon}
		icon={ic_people_outline}
		size={size}
		typoWeight={'bold'}
		display='inline-flex !important'
		alignItems='center'
		{...rest}
	/>
);

export const LogsIcon = ({ size = 24, ...rest }) => (
	<Typography.Span
		as={Icon}
		icon={ic_reorder}
		size={size}
		typoWeight={'bold'}
		display='inline-flex !important'
		alignItems='center'
		{...rest}
	/>
);

export const SettingsIcon = ({ size = 24, ...rest }) => (
	<Typography.Span
		as={Icon}
		icon={ic_settings}
		size={size}
		typoWeight={'bold'}
		display='inline-flex !important'
		alignItems='center'
		{...rest}
	/>
);

export const UploadIcon = ({ size = 24, ...rest }) => (
	<Typography.Span
		as={Icon}
		icon={ic_file_upload}
		size={size}
		typoWeight={'bold'}
		display='inline-flex !important'
		alignItems='center'
		{...rest}
	/>
);

export const CodeIcon = ({ size = 24, ...rest }) => (
	<Typography.Span
		as={Icon}
		icon={ic_code}
		size={size}
		typoWeight={'bold'}
		display='inline-flex !important'
		alignItems='center'
		{...rest}
	/>
);

export const OpenInNewIcon = ({ size = 24, ...rest }) => (
	<Typography.Span
		as={Icon}
		icon={ic_open_in_new}
		size={size}
		typoWeight={'bold'}
		display='inline-flex !important'
		alignItems='center'
		{...rest}
	/>
);

export const ContentCopyIcon = ({ size = 24, ...rest }) => (
	<Typography.Span
		as={Icon}
		icon={ic_content_copy}
		size={size}
		typoWeight={'bold'}
		display='inline-flex !important'
		alignItems='center'
		{...rest}
	/>
);

export const AffiliatesIcon = ({ size = 24, ...rest }) => (
	<Typography.Span
		as={Icon}
		icon={ic_business}
		size={size}
		typoWeight={'bold'}
		display='inline-flex !important'
		alignItems='center'
		{...rest}
	/>
);

export const ClientsIcon = ({ size = 24, ...rest }) => (
	<Typography.Span
		as={Icon}
		icon={ic_store}
		size={size}
		typoWeight={'bold'}
		display='inline-flex !important'
		alignItems='center'
		{...rest}
	/>
);

export const PhoneIcon = ({ size = 24, ...rest }) => (
	<Typography.Span
		as={Icon}
		icon={ic_phone}
		size={size}
		typoWeight={'bold'}
		display='inline-flex !important'
		alignItems='center'
		{...rest}
	/>
);

export const ChevronLeftIcon = ({ size = 24, ...rest }) => (
	<Typography.Span
		as={Icon}
		icon={ic_chevron_left}
		size={size}
		typoWeight={'bold'}
		display='inline-flex !important'
		alignItems='center'
		{...rest}
	/>
);

export const ChevronRightIcon = ({ size = 24, ...rest }) => (
	<Typography.Span
		as={Icon}
		icon={ic_chevron_right}
		size={size}
		typoWeight={'bold'}
		display='inline-flex !important'
		alignItems='center'
		{...rest}
	/>
);
