import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Div, Typography, Input } from '@icecreamsocial/components';
import { useStore, useCopyRef } from '../../../../utils/hooks';

const InfluencerLink = ({ link }) => {
  const store = useStore();

  const { ref: linkRef, copy } = useCopyRef(() =>
    store.Orchestrators.View.addNotification({
      text: 'Code copied to clipboard!',
      type: 'emphasis',
    })
  );

  return (
    <Div marginBottom='lg'>
      <Typography.P textColor='ambientDark' marginBottom='sm' marginTop='md'>
        Influencer Link
      </Typography.P>
      {link ? (
        <Div display='flex' alignItems='center' justifyContent='space-between'>
          <Input
            ref={linkRef}
            defaultValue={link}
            type='text'
            border='0'
            borderRadius='none'
            padding='0'
            typoHeight='md'
            textDecoration='underline'
            readOnly
            overflow='hidden'
            style={{ whiteSpace: 'no-wrap', textOverflow: 'ellipsis' }}
          />
          <Button
            onClick={copy}
            backgroundColor='transparent'
            textColor='link'
            paddingTop='sm'
            paddingBottom='sm'
          >
            Copy
          </Button>
        </Div>
      ) : (
        <Typography.Span style={{ fontStyle: 'italic' }}>
          None Available
        </Typography.Span>
      )}
    </Div>
  );
};
InfluencerLink.displayName = 'InfluencerManager.InfluencerLink';

export default observer(InfluencerLink);
