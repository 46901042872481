import React from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import {
	Div,
	Typography,
	Panel,
	Img,
	Button,
} from '@icecreamsocial/components';
import scoops from '../../../../../../assets/scoops_thumbs_up.png';

const ProcessingSuccess = ({
	transactionId,
	reward,
	influencerName,
	backLink,
	processorType,
}) => {
	const successText =
		processorType?.toLowerCase() === 'manual' || !reward
			? `Reward issued to ${influencerName}`
			: `${reward} reward sent to ${influencerName}`;

	return (
		<Panel elHeight='100%' backgroundColor='bodyInverse'>
			<Panel.Body display='flex' alignItems='center' justifyContent='center'>
				<Div textAlign='center'>
					<Img src={scoops} elWidth='33%' />
					<Typography.H2
						typoSize='md'
						typoWeight='400'
						marginTop='md'
						marginBottom='sm'
					>
						{successText}
					</Typography.H2>
					<Typography.P typoSize='sm'>
						We've notified them via email
					</Typography.P>
					<Button
						as={Link}
						to={backLink}
						backgroundColor='ambient'
						marginTop='md'
						marginBottom='lg'
						textColor='body'
						visitedTextColor='body'
					>
						Back to Details
					</Button>
					{transactionId && (
						<Typography.Span display='block'>
							Transaction ID {transactionId}
						</Typography.Span>
					)}
				</Div>
			</Panel.Body>
		</Panel>
	);
};
ProcessingSuccess.displayName = 'InfluencerManager.ProcessingSuccess';

export default observer(ProcessingSuccess);
