import { Div } from '@icecreamsocial/components';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { Typography } from '@icecreamsocial/components';
import { useReleaseNotes } from '../../../utils/hooks';
const ReleaseNotes = (props) => {
	useReleaseNotes();

	return (
		<Typography.A
			href='https://widget.releasenotes.io/icecreamsocial.releasenotes.io/api/v1.1/release/latest.json'
			className='rn-badge'
			{...props}
		>
			Updates
		</Typography.A>
	);
};

export default observer(ReleaseNotes);
