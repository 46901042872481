import { Div, InputGroup, Typography } from '@icecreamsocial/components';
import { Radio, RadioGroup } from '@material-ui/core';
import React from 'react';
import { useScriptSettings } from '../../../../providers';

export const DisplayCustomizer = () => {
	const { settings, updateBehavior } = useScriptSettings();

	const handleChange = (e) => {
		const { value } = e.currentTarget;
		updateBehavior({ flavor: value });
	};

	return (
		<form>
			<Typography.H4 marginTop='none'>Flavor</Typography.H4>
			<RadioGroup name='flavor'>
				<InputGroup elWidth='100%' marginBottom='md'>
					<Div display='flex' alignItems='center' marginBottom='sm'>
						<Radio
							color='primary'
							value='callout'
							checked={
								settings.behavior?.flavor === 'callout' ||
								!settings.behavior?.flavor
							}
							onChange={handleChange}
						/>{' '}
						<InputGroup.Label display='inline-block' typoSize='1.1em'>
							Callout
						</InputGroup.Label>
					</Div>
					<Typography.Span display='block' paddingLeft='sm'>
						A small popover that takes up less space and doesn't obscure page
						content
					</Typography.Span>
				</InputGroup>
				<InputGroup elWidth='100%' marginBottom='md'>
					<Div display='flex' alignItems='center' marginBottom='sm'>
						<Radio
							color='primary'
							value='modal'
							checked={settings.behavior?.flavor === 'modal'}
							onChange={handleChange}
						/>{' '}
						<InputGroup.Label display='inline-block'>Modal</InputGroup.Label>
					</Div>
					<Typography.Span display='block' paddingLeft='sm'>
						A full page pop up
					</Typography.Span>
				</InputGroup>
				<InputGroup elWidth='100%' marginBottom='md'>
					<Div display='flex' alignItems='center' marginBottom='sm'>
						<Radio
							color='primary'
							value='embedded'
							checked={settings.behavior?.flavor === 'embedded'}
							onChange={handleChange}
						/>{' '}
						<InputGroup.Label display='inline-block'>Embedded</InputGroup.Label>
					</Div>
					<Typography.Span display='block' paddingLeft='sm'>
						Choose this if you want to embed the widget directly into a page
					</Typography.Span>
				</InputGroup>
			</RadioGroup>
			<InputGroup marginBottom='md'>
				<InputGroup.Label typoWeight='500'>Mount Selector</InputGroup.Label>
				<InputGroup.Fields>
					<InputGroup.Input
						defaultValue='body'
						value={settings?.behavior?.mountSelector}
						onChange={(e) =>
							updateBehavior({ mountSelector: e.currentTarget.value })
						}
						marginLeft='md'
					/>
				</InputGroup.Fields>
				<InputGroup.Label as='small'>
					The CSS selector of the element where the widget will be inserted.
				</InputGroup.Label>
			</InputGroup>
		</form>
	);
};
