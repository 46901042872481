import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button } from '@icecreamsocial/components';
import { DynamicBar } from '../';
import { withLoader } from '../../../utils/HOCs';

const LoadingButton = withLoader(Button);
/**
 * @module EditFormControls
 * @category Components
 * @subcategory Presentational
 */

/**
 * @name EditFormControls
 * @description used for forms where the user is editing OR creating previously saved data.  MUST be in a form to work
 * @todo rename this to something that reflects that it can also be used for creating
 * @param {object} 		props
 * @param {boolean} 	[props.disabled=false]
 * @param {boolean} 	[props.isLoading=false]
 * @param {boolean} 	[props.forceShow=false]
 * @param {boolean} 	[props.dangerous=false]
 * @param {string} 	[props.position='fixed']
 * @param {string} 	[props.cancelText='Cancel Changes']
 * @param {string} 	[props.applyText='Apply Changes']
 * @param {boolean} 	props.dirty
 * @param {function} 	props.handleReset
 * @param {function} 	props.handleSubmit
 */
const EditFormControls = ({
  disabled = false,
  isLoading = false,
  forceShow = false,
  dangerous = false,
  position = 'fixed',
  cancelText = 'Cancel Changes',
  applyText = 'Apply Changes',
  dirty,
  handleReset,
  handleSubmit,
  ...rest
}) => (
  <DynamicBar
    show={forceShow || dirty}
    zIndex='10'
    position={position}
    {...rest}
  >
    <Button
      data-slate-action='cancelChanges'
      disabled={disabled}
      onClick={handleReset}
      marginRight='lg'
    >
      {cancelText}
    </Button>
    <LoadingButton
      data-slate-action='applyChanges'
      type='submit'
      onClick={handleSubmit}
      disabled={disabled}
      isLoading={isLoading}
      containerProps={{ display: 'inline-block' }}
      backgroundColor={dangerous ? 'bad' : 'brand'}
      textColor='bodyInverse'
    >
      {applyText}
    </LoadingButton>
  </DynamicBar>
);

EditFormControls.displayName = 'EditFormControls';
export default observer(EditFormControls);
