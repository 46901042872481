import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
	Button,
	Div,
	InputGroup,
	Select,
	Typography,
} from '@icecreamsocial/components';
import { Formik } from 'formik';
import {
	AffiliateSelector,
	AffiliateSelectorNew,
	CampaignSelector,
	ClientSelector,
} from '../../../../containers';
import { CalendarPicker } from '../../../../presentational';
import { useDashboardFilters } from '../FilterProvider';
import { formatISO, startOfMonth } from 'date-fns';
/**
 * @module DashboardFilterForm
 * @category Components
 * @subcategory Presentational
 */

/**
 * @param {DashboardFilters.Props} props
 */
const FilterForm = ({ userClientId = null, userAffiliateId, role }) => {
	const affiliateSelectRef = useRef();
	const clientSelectRef = useRef();
	const campaignSelectRef = useRef();
	const { state, update, clear } = useDashboardFilters();
	const [formikProps] = useState({
		enableReinitialize: true,
		initialValues: mapStateToForm(state),
		onSubmit: update,
	});

	/**
	 *
	 * Labels will be handled by their select component
	 *
	 * @param {DashboardFilters.State} values
	 * @returns {DashboardFilters.Controls}
	 */
	function mapStateToForm(values) {
		const { clientId, affiliateId, campaignId, fromDate, toDate } = values;
		const today = Date.now();

		const getValue = (val) => (val ? { value: val, label: '' } : null);

		return {
			fromDate: fromDate || formatISO(startOfMonth(today)),
			toDate: toDate || formatISO(today),
			affiliate: getValue(affiliateId),
			client: getValue(clientId),
			campaign: getValue(campaignId),
		};
	}

	return (
		<Formik {...formikProps}>
			{(filtersForm) => (
				<form onSubmit={filtersForm.handleSubmit}>
					<Div
						display='flex'
						alignItems='center'
						justifyContent='space-between'
					>
						<Typography.H2 typoWeight='500'>Filters</Typography.H2>
						<Button
							onClick={() => {
								clear();
								affiliateSelectRef.current?.clearSelect();
								clientSelectRef.current?.clearSelect();
								campaignSelectRef.current?.clearSelect();
							}}
							typoSize='small'
							textDecoration='underline'
							border='none'
							shadow='none'
							hoverShadow='none'
							padding='sm'
							backgroundColor='transparent'
						>
							Reset filters
						</Button>
					</Div>
					<CalendarPicker
						label='Date Created'
						fromDate={filtersForm.values.fromDate}
						onFromDateChange={filtersForm.handleChange}
						toDate={filtersForm.values.toDate}
						onToDateChange={filtersForm.handleChange}
					/>
					{role !== 'client' && (
						<InputGroup elWidth='100%' marginBottom='sm'>
							<InputGroup.Label>Affiliate</InputGroup.Label>
							<AffiliateSelectorNew
								name='affiliate'
								ref={affiliateSelectRef}
								placeholder='All affiliates'
								affiliateId={userAffiliateId}
							/>
						</InputGroup>
					)}
					{role !== 'client' && (
						<InputGroup elWidth='100%' marginBottom='sm'>
							<InputGroup.Label>Client</InputGroup.Label>
							<ClientSelector
								ref={clientSelectRef}
								name='client'
								placeholder='All clients'
								affiliateId={
									filtersForm.values.affiliate?.value || userAffiliateId
								}
							/>
						</InputGroup>
					)}
					<InputGroup elWidth='100%' marginBottom='lg'>
						<InputGroup.Label>Campaign</InputGroup.Label>
						<CampaignSelector
							ref={campaignSelectRef}
							name='campaign'
							affiliateId={
								filtersForm.values.affiliate?.value || userAffiliateId
							}
							queryWhen={
								role === 'client'
									? userAffiliateId && userClientId
									: filtersForm.values.affiliate?.value || userAffiliateId
							}
							clientId={filtersForm.values.client?.value || userClientId}
							clientSearch={filtersForm.values.client?.value || userClientId}
							searchText={state.searchText}
							placeholder='All campaigns'
						/>
					</InputGroup>

					<Button
						type='submit'
						disabled={!filtersForm.isValid}
						elWidth='100%'
						textColor='bodyInverse'
						backgroundColor='brand'
						padding='sm'
					>
						Apply Filters
					</Button>
				</form>
			)}
		</Formik>
	);
};

FilterForm.displayName = 'Dashboard.FilterForm';
export default observer(FilterForm);
