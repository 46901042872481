import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import {
	Button,
	Div,
	Panel,
	Spanner,
	Tag,
	Typography,
} from '@icecreamsocial/components';
import { useStore } from '../../../../../utils/hooks';
import { HeaderPortal } from '../../../Main';
import {
	UserSettingsForm,
	ArrowBackIcon,
	EditFormControls,
} from '../../../../presentational';
import { DestructiveUserActions } from '../../../../containers';
import { flags, toggle } from '../../../../../utils';

const User = (props) => {
	const store = useStore();
	const history = useHistory();
	const { userId } = useParams();
	const [allowRouteBlock, setAllowRouteBlock] = useState(true);
	let userEmail = '';

	const resolveUserFlow = async () => {
		await store.resolveUserFlow(userId);

		userEmail = store.Orchestrators.Focused.user.email;
	};

	const focusedUser = store.Orchestrators.Focused.user;

	const initialValues = {
		email: store.Orchestrators.Focused.user?.email,
		firstName: store.Orchestrators.Focused.user?.firstName,
		lastName: store.Orchestrators.Focused.user?.lastName,
		password: '',
		role: store.Orchestrators.Focused.user?.role,
		isTeamAdmin: store.Orchestrators.Focused.user?.isTeamAdmin || false,
		affiliateId: store.Orchestrators.Focused.user?.affiliateId,
		clientId: { value: store.Orchestrators.Focused.user?.clientId, label: '' },
	};

	const handleSubmit = async (values) => {
		await store.updateUserSettingsFlow({
			...values,
			clientId: values.clientId?.value,
			ip: values.ip ? `{${values.ip}}` : '{}',
		});

		if (props.nextPage) {
			setAllowRouteBlock(false);
			setTimeout(() => history.push(props.nextPage), 250);
		}
	};

	// Focus current authenticated user so that their parent client/affiliate will be used as defaults
	useEffect(() => {
		resolveUserFlow();

		return () => store.resolveUserFlowCleanUp();
	}, [userId, userEmail]);

	return (
		<>
			<HeaderPortal>
				<Div backgroundColor='#EBEBEB' textAlign='center' padding='md'>
					<Spanner grid gridColumns='repeat(3, 1fr)' padding='0'>
						<Div display='flex' alignItems='center'>
							<Div elWidth='100%' textAlign='center'>
								<Button
									alignItems='center'
									backgroundColor='transparent'
									display='flex'
									hoverShadow='none'
									margin='0'
									onClick={history.goBack}
									padding='0'
									shadow='none'
								>
									<ArrowBackIcon marginRight='md' /> Back
								</Button>
							</Div>
						</Div>
						<Div display='flex' flexDirection='column' alignItems='center'>
							{focusedUser && (
								<>
									<Div
										elWidth='100%'
										textAlign='center'
										data-slate-page-title='editUser'
									>
										<Typography.Span typoWeight='bold' display='block'>
											{focusedUser.name}
										</Typography.Span>
										<Typography.Span
											as='small'
											textTransform='capitalize'
											marginTop='md'
											textColor='ambientDark'
										>
											{focusedUser.role}{' '}
											{focusedUser.isTeamAdmin ? 'team leader' : 'member'}
										</Typography.Span>
									</Div>
								</>
							)}
						</Div>
						<Div elWidth='100%' textAlign='right'>
							{focusedUser && (
								<Tag
									textTransform='capitalize'
									textColor={
										focusedUser.status === 'active' ? 'good' : 'accent'
									}
									borderColor={
										focusedUser.status === 'active' ? 'good' : 'accent'
									}
								>
									{focusedUser.status}
								</Tag>
							)}
						</Div>
					</Spanner>
				</Div>
			</HeaderPortal>
			<Div marginTop='lg'>
				<UserSettingsForm
					isEditing={true}
					initialValues={initialValues}
					userClientId={store.userClientId}
					userAffiliateId={store.userParentAffiliateId}
					userRole={store.userRole}
					userIsTeamLeader={store.isTeamLeader}
					onSubmit={handleSubmit}
					allowRouteBlock={allowRouteBlock}
					renderControls={(props) => (
						<EditFormControls
							isLoading={store.isLoading('userSettings')}
							disabled={store.isLoading('userSettings')}
							{...props}
						/>
					)}
				/>
				<Div as='hr' borderColor='ambient' borderTop='none' marginBottom='lg' />
				<DestructiveUserActions
					userId={focusedUser?.id}
					userName={focusedUser?.name}
					currentStatus={focusedUser?.status}
					isArchived={focusedUser?.isArchived}
				/>
			</Div>
		</>
	);
};

User.displayName = 'Users.User';

export default observer(User);
