import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Link, Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import {
	Container,
	Div,
	Spanner,
	Typography,
} from '@icecreamsocial/components';
import { HeaderPortal } from '../Main';
import { SideTabsNav, PageMeta } from '../../presentational';
import { CompanyLogoLink } from '../../containers';

import { useStore, useRouteConfig } from '../../../utils/hooks';
import AccountSettings from './sub-routes/Personal';
import BusinessProfile from './sub-routes/BusinessProfile';
import Policies from './sub-routes/Policies';
import ECommerce from './sub-routes/ECommerce';
import Brand from './sub-routes/Brand';
import PaymentProcessor from './sub-routes/PaymentProcessor';
import Redirector from './sub-routes/Redirector';

const Settings = () => {
	const store = useStore();
	const { path } = useRouteMatch();
	// this route should exist for all admins
	const { routes: baseRoute } = useRouteConfig([
		{
			component: Redirector,
		},
	]);
	// these routes only exist for clients
	const { routes, links } = useRouteConfig([
		{
			path: `business-profile`,
			component: BusinessProfile,
			linkText: 'Business Profile',
			name: 'profile',
			disableLink: store.needsBusinessSetup,
		},
		{
			path: `ecommerce`,
			component: ECommerce,
			name: 'ecomm',
			linkText: ' eCommerce',
			disableLink: store.needsBusinessSetup,
		},
		{
			path: `brand`,
			component: Brand,
			name: 'brand',
			linkText: 'Brand & Appearance',
			disableLink: store.needsBusinessSetup,
		},
		{
			path: `data-and-policy`,
			component: Policies,
			name: 'dataAndPolicies',
			linkText: 'Data & Policy',
			disableLink: store.needsBusinessSetup,
		},
		{
			path: `reward-processor`,
			component: PaymentProcessor,
			name: 'payment',
			linkText: 'Reward Processing',
			disableLink: store.needsBusinessSetup,
		},
	]);
	// these routes exist for everyone
	const { routes: personalRoutes, links: personalLinks } = useRouteConfig([
		{
			path: `account`,
			component: AccountSettings,
			linkText: 'Account Settings',
		},
	]);

	return (
		<>
			<PageMeta
				title={'Settings | Ice Cream Social'}
				description={'Manage your settings'}
			/>
			<HeaderPortal>
				<Div backgroundColor="#EBEBEB" textAlign="center" padding="md">
					<Spanner grid gridColumns="repeat(3, 1fr)" padding="0">
						<Div display="flex" alignItems="center">
							<CompanyLogoLink />
						</Div>
						<Div display="flex" alignItems="center">
							<Div elWidth="100%" textAlign="center">
								<Typography.Span typoWeight="bold">
									Ice Cream Social
								</Typography.Span>
							</Div>
						</Div>
					</Spanner>
				</Div>
			</HeaderPortal>
			<Container>
				<Div display="flex" padding="md">
					<Div>
						<Div position="sticky" positionTop="16px">
							{store.isClient && (
								<>
									<Typography.Span
										typoSize="lg"
										typoWeight="500"
										marginBottom="lg"
										display="block"
									>
										Business
									</Typography.Span>
									<SideTabsNav routes={links} />
								</>
							)}
							<Typography.Span
								typoSize="lg"
								typoWeight="500"
								marginBottom="lg"
								display="block"
							>
								Personal
							</Typography.Span>
							<SideTabsNav routes={personalLinks} />
						</Div>
					</Div>

					<Div marginLeft="lg" flexGrow="1">
						<Switch>
							{baseRoute.map(({ link, ...route }, i) => (
								<Route key={i} {...route} />
							))}
							{store.isClient &&
								routes.map(({ link, ...route }, i) => (
									<Route key={i} {...route} />
								))}

							{personalRoutes.map(({ link, ...route }, i) => (
								<Route key={i} {...route} />
							))}
						</Switch>
					</Div>
				</Div>
			</Container>
		</>
	);
};
Settings.displayName = 'Settings';

export default observer(Settings);
