import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory, Link } from 'react-router-dom';
import {
	Button,
	Div,
	Panel,
	Spanner,
	Typography,
} from '@icecreamsocial/components';
import { useStore } from '../../../../../utils/hooks';
import { HeaderPortal } from '../../../Main';
import {
	ClientSettingsForm,
	EditFormControls,
} from '../../../../presentational';

const ClientSettings = (props) => {
	const store = useStore();
	const history = useHistory();

	const focusedClient = store.Orchestrators.Focused.client || {};

	const initialValues = {
		id: focusedClient.id,
		name: focusedClient.name,
		clientId: focusedClient.parentId,
		status: focusedClient.status,
		isArchived: focusedClient.isArchived,
		allowSMS: focusedClient.allowSMS,
	};

	const [allowNavigation, setAllowNavigation] = useState(false);

	// Focus current authenticated user so that their parent client will be used as defaults
	const userId = store.Orchestrators.Auth.user.id;

	useEffect(() => {
		store.resolveUserFlow(userId);

		return () => store.resolveUserFlowCleanUp();
	}, [store.Orchestrators.Auth.user.id]);

	const handleSubmit = async (values) => {
		await store.updateClientSettingsFlow(values);

		if (props.nextPage) {
			setAllowNavigation(true);
			history.push(props.nextPage);
		}
	};

	return (
		<>
			<Div marginTop='lg'>
				<ClientSettingsForm
					onSubmit={handleSubmit}
					affiliateId={store.userParentAffiliateId}
					editMode={true}
					renderControls={(props) => (
						<EditFormControls
							isLoading={store.isLoading('clientSettings')}
							disabled={store.isLoading('clientSettings')}
							{...props}
						/>
					)}
					cannotActivate={focusedClient.isArchived}
					{...initialValues}
				/>
				{/* <Panel
					backgroundColor='ambient'
					marginBottom='lg'
					flexDirection='row'
					alignItems='center'
				>
					<Panel.Body>
						<Typography.H2 marginTop='0' typoSize='md'>
							{focusedClient.isArchived ? 'Unarchive' : 'Archive'} Client
						</Typography.H2>
						<Typography.P>
							Archiving helps clear clutter by hiding old items so you're only
							seeing the most relevant results. Archived items can be restored
							anytime.
						</Typography.P>
					</Panel.Body>
					<Panel.Footer flexShrink='0'>
						<Button
							onClick={(e) => {
								const body = {
									isArchived: focusedClient.isArchived ? false : true,
									status:
										focusedClient.isArchived === true ? 'active' : undefined,
								};
								store.updateClientSettingsFlow(body);
							}}
							backgroundColor={focusedClient.isArchived ? 'good' : 'bad'}
							textColor='bodyInverse'
						>
							{focusedClient.isArchived ? 'Unarchive' : 'Archive'} Client
						</Button>
					</Panel.Footer>
				</Panel> */}
			</Div>
		</>
	);
};

ClientSettings.displayName = 'Clients.Settings';

export default observer(ClientSettings);
