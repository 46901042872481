import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
	Button,
	Div,
	Link,
	Modal,
	// ModalBackground,
	Panel,
	Spanner,
	Typography,
} from '@icecreamsocial/components';
import {
	BaseModal,
	CloseIcon,
	ConfirmConversionDeletionModal,
	ConversionsTable,
} from '../../presentational';
import { useStore, usePagination } from '../../../utils/hooks';
//TODO remove store jocelyn

const InfluencerConversionsModal = ({ isOpen, closeModal }) => {
	const store = useStore();
	const focusedCampaign = store.Orchestrators.Focused.campaign || {};
	const focusedInfluencer = store.Orchestrators.Focused.influencer || {};

	const { id: campaignId } = focusedCampaign;

	const { id: influencerId, email, name } = focusedInfluencer;

	const [confirmDeletionModalIsOpen, openConfirmDeletionModal] = useState(
		false
	);
	const [selectedConversions, setSelectedConversions] = useState([]);

	const deleteConversions = () => {
		selectedConversions.forEach(
			({ campaignId, influencerId, id: conversionId }) => {
				store.deleteConversionFlow({
					campaignId,
					influencerId,
					conversionId,
				});
			}
		);
		openConfirmDeletionModal(false);
	};

	const [getPagination, resetPagination] = usePagination({ limit: 10 });
	/**
	 * will get influencers everytime the campaign, influencer, or query changes
	 */
	useEffect(() => {
		if (isOpen && campaignId && influencerId) {
			console.log({ campaignId, influencerId });
			store.getConversionsFlow({ campaignId, influencerId });
		}
		return () => {
			store.Collections.Conversions.setConversionList([]);
			resetPagination();
		};
	}, [isOpen, campaignId, influencerId]);

	const paginationConfig = {
		paginationServer: true,
		paginationDefaultPage: 1, // make this from a query param in the future
		paginationTotalRows: store.Collections.Conversions.total,
		paginationPerPage: 10,
		onChangePage: (page) => {
			const { skip, limit } = getPagination({ page });
			store.getConverionsFlow({
				campaignId,
				influencerId,
				skip,
				limit,
			});
		},
		onChangeRowsPerPage: (currentRowsPerPage, page) => {
			const { skip, limit } = getPagination({
				page,
				limit: currentRowsPerPage,
			});
			store.getConverionsFlow({
				campaignId,
				influencerId,
				skip,
				limit,
			});
		},
	};

	const sortConfig = {
		sortServer: true,
		defaultSortField: 'updatedAt',
	};

	return (
		<>
			<BaseModal isOpen={isOpen}>
				<Panel
					backgroundColor='ambientLight'
					border='none'
					color='inverse'
					elWidth='100%'
					elHeight={['100%', '90%']}
					shadow='raised'
				>
					<Panel.Header padding='sm'>
						<Spanner alignItems='center' backgroundColor='brand' display='flex'>
							<CloseIcon onClick={closeModal} textAlign='left' />
							<Div marginLeft='md' flex='1'>
								{name && (
									<Div typoWeight='bold' marginBottom='sm'>
										{name || '-'}
									</Div>
								)}
								<Div>{email}</Div>
							</Div>
						</Spanner>
					</Panel.Header>
					<Panel.Body>
						<Typography.H3 marginTop='none' marginBottom='md'>
							Influencer Referrals
						</Typography.H3>
						<Div
							style={{
								maxHeight: '30vh',
								overflowY: 'scroll',
							}}
						>
							<ConversionsTable
								collection={store.Collections.Conversions.list.toJS()}
								fixedHeader={true}
								onChecked={({ allSelected, selectedCount, selectedRows }) => {
									setSelectedConversions(selectedRows);
								}}
								overflowY={true}
								{...paginationConfig}
								{...sortConfig}
							/>
						</Div>
					</Panel.Body>
					<Panel.Footer>
						<Spanner padding='none' paddingTop='sm' paddingBottom='sm'>
							<Button
								elWidth='100%'
								onClick={() => openConfirmDeletionModal(true)}
								disabled={!(selectedConversions.length > 0)}
								backgroundColor={
									selectedConversions.length > 0 ? 'bad' : undefined
								}
								textColor={
									selectedConversions.length > 0 ? 'bodyInverse' : undefined
								}
							>
								Delete Referrals
							</Button>
						</Spanner>
					</Panel.Footer>
				</Panel>
			</BaseModal>
			<ConfirmConversionDeletionModal
				isOpen={confirmDeletionModalIsOpen}
				conversions={selectedConversions}
				onConfirm={deleteConversions}
				closeModal={() => openConfirmDeletionModal(false)}
			/>
		</>
	);
};

InfluencerConversionsModal.displayName = 'InfluencerConversionsModal';

export default observer(InfluencerConversionsModal);
