import { Div, InputGroup, Typography } from '@icecreamsocial/components';
import { Radio, RadioGroup } from '@material-ui/core';
import React from 'react';
import { useScriptSettings } from '../../../../providers';

export const TypeCustomizer = () => {
	const { settings, updateBehavior } = useScriptSettings();

	const handleChange = (e) => {
		const { value } = e.currentTarget;
		updateBehavior({ type: value });
	};

	return (
		<form>
			<RadioGroup name='type'>
				<InputGroup elWidth='100%' marginBottom='md'>
					<Div display='flex' alignItems='center' marginBottom='sm'>
						<Radio
							color='primary'
							value='referral'
							checked={
								settings.behavior?.type === 'referral' ||
								!settings.behavior?.type
							}
							onChange={handleChange}
						/>{' '}
						<InputGroup.Label display='inline-block' typoSize='1.1em'>
							Referral
						</InputGroup.Label>
					</Div>
					<Typography.Span display='block' paddingLeft='sm'>
						Allows for influencer enrollment, access to sharing tools and
						referral tracking
					</Typography.Span>
				</InputGroup>
				<InputGroup elWidth='100%' marginBottom='md'>
					<Div display='flex' alignItems='center' marginBottom='sm'>
						<Radio
							color='primary'
							value='incentive'
							checked={settings.behavior?.type === 'incentive'}
							onChange={handleChange}
						/>{' '}
						<InputGroup.Label display='inline-block'>
							Incentive
						</InputGroup.Label>
					</Div>
					<Typography.Span display='block' paddingLeft='sm'>
						Allows for invitees to enroll and claim a discount code reward to
						encourage referrals
					</Typography.Span>
				</InputGroup>
			</RadioGroup>
		</form>
	);
};
