import { format } from 'date-fns-tz';

export * from './hooks';
export * from './featureFlags';
/**
 * @module Utils
 * @category Shared
 * @subcategory Utils
 * @description
 * Helper functions used throughout the admin
 */

/**
 * @function
 * @param {object} file a file input
 * @returns {Promise}
 * @description
 * Reads a file from an input and reads it as a data URL.  Rejects on a bad file.
 * Data URL is used for uploading images or creating a preview image.
 */
export const readFileAsDataUrl = (file) => {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject("Couldn't read file");
    };

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.readAsDataURL(file);
  });
};

/**
 * @function
 * @param {string} queryString a URL string
 * @returns {object}
 * @description
 * Gets query params and returns an object
 * @deprecated use {@link module:useQueryString} hook or `query-string` library instead
 */
export const parseQueryString = (queryString) => {
  // parse query string
  const params = new URLSearchParams(queryString);
  const obj = {};

  // iterate over all keys
  for (const key of params.keys()) {
    if (params.getAll(key).length > 1) {
      obj[key] = params.getAll(key);
    } else {
      obj[key] = params.get(key);
    }
  }

  return obj;
};

/**
 * @function
 * @param {Array<*>} csv data
 * @param {string} title filename
 * @returns {void}
 * @description
 * Initiates a download of a csv file
 * @todo use {@link module:useDownloadLink} instead of this function.
 * Will deprecate soon.
 */

export const exportToCSV = (
  csv,
  title = `ICS_export-${new Date().toISOString().split('.')[0]}.csv`
) => {
  let csvContent = 'data:text/csv;charset=utf-8,';
  csv.forEach((rowArray) => {
    let row = rowArray.join(',');
    csvContent += row + '\r\n';
  });
  var encodedUri = window.encodeURI(csvContent);

  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', title);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

/**
 * @function
 * @param {string} v string to captialize
 * @returns {string}
 * @description
 * Capitalizes a string.  Useful, but might want to just use css to accomplish this instead.
 */
export const capitalize = (v) =>
  typeof v === 'string' ? v.replace(/^\w/, (c) => c.toUpperCase()) : v;

/**
 * @function
 * @param {string} uri uri to format into full URL
 * @returns {string | undefined}
 * @description
 * Takes URI and prepends the base image URL if it exists and is not a full URL
 * returns the URI if it is a full URL.
 * returns undefined if URI is undefined
 */
export const formatImageURL = (uri) => {
  if (!uri) return undefined;
  const check = /^http/;
  const isFullURL = check.test(uri);

  return isFullURL ? uri : `${process.env.BASE_IMAGE_ENDPOINT}/${uri}`;
};

/**
 * @function
 * @param {string} url
 * @returns {boolean}
 * @description
 * Checks if a URL is on the icecreamsocial.io domain
 */
export const isICSURL = (url) => {
  if (!url) return false;
  const ICS_URL = /(https?:\/\/)?(app.|support.)?icecreamsocial.io/g;

  return ICS_URL.test(url);
};

/**
 * @function
 * @description
 * @template Truthy
 * @template Falsy
 * simple utility that returns a function depending on the toggle condition.  Does not call it.
 * exists mainly to encourage abstraction for larger refactors instead of commenting out code.
 * @param {boolean} condition
 * @param {Truthy} truthy return when condition is true
 * @param {Falsy} falsy return when condition is false
 * @returns {Truthy | Falsy}
 */
export const toggle = (condition, truthy, falsy) =>
  condition ? truthy : falsy;

/**
 *
 * @param {Date} date a date object
 * @returns {string}
 */
export const formatDateTime = (date) =>
  `${format(date, 'Pp z', {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  })}`;

/**
 * formats a number to currency
 * @param {number} value
 * @param {string} [locale = 'en-US']
 * @param {Intl.NumberFormatOptions} [options]
 * @returns
 */
export const formatCurrency = (value, locale = 'en-US', options = {}) => {
  return new Intl.NumberFormat(locale, options).format(value);
};
