import React from 'react';
import { ThemeProvider } from 'styled-components';
import { observer } from 'mobx-react-lite';
import { makeTheme } from '@icecreamsocial/themes';
// import useStore from '../../mst/useStore';
/**
 * @module ICSAdminThemeProvider
 * @category Components
 * @subcategory Containers
 */

/**
 * @description
 * The storefront contains a very similar component.  The reason why this is not in the library
 * is because this is dependent on the structure of the store, which may differ from app to app.  If
 * we remove the store logic, it becomes the default theme provider from styled components and just
 * becomes an unnecessay abstraction
 * @todo
 * document this
 */

const ICSAdminThemeProvider = ({ children }) => {
	// const store = useStore();
	const theme = makeTheme({
		colors: {
			accentLight: '#DDE1F9',
			// accent: (store.SiteInfo.appearanceSettings || {}).accentColor,
			// brand: (store.SiteInfo.appearanceSettings || {}).brandColor,
		},
	});
	// const theme = makeTheme();

	return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

ICSAdminThemeProvider.displayName = 'ICSAdminThemeProvider';
export default observer(ICSAdminThemeProvider);
