import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Div, Typography } from '@icecreamsocial/components';
import ShareSettingsForm from '../../../../components/ShareSettingsForm';
import {
	SteppedFormControls,
	Pagename,
} from '../../../../../../presentational';

const Share = ({ handleSubmit, returnTo }) => {
	return (
		<Div>
			<Pagename>Share Settings</Pagename>
			<ShareSettingsForm
				onSubmit={handleSubmit}
				allowRouteBlock={false}
				renderControls={() => <SteppedFormControls onNav={returnTo} />}
			/>
		</Div>
	);
};
Share.displayName = 'Campaigns.Setup.Share';

export default observer(Share);
