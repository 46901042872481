import styled, { css, keyframes } from 'styled-components';

function makeAnatomy(rules) {
    if (!Array.isArray(rules))
        throw Error('Rules must be an array');
    return { rules };
}
const styledAnatomyFactory = (anatomy) => {
    return (displayName) => makeStyledObject(anatomy, displayName);
};
const makeStyledObject = (anatomy, displayName) => {
    let styledFn = (Component) => {
        const fn = createStyledAnatomyFunction(anatomy, displayName, Component);
        fn.attrs = makeAttrsFunction(anatomy, displayName, Component);
        return fn;
    };
    const styledObjProps = getPropsFromStyledComponents((styleObj, element) => {
        const attrsFn = makeAttrsFunction(anatomy, displayName, element);
        const styleFn = createStyledAnatomyFunction(anatomy, displayName, element);
        styleFn.attrs = attrsFn;
        return Object.assign({}, styleObj, {
            [element]: styleFn,
        });
    });
    styledFn = Object.assign(styledFn, styledObjProps);
    return styledFn;
};
const getPropsFromStyledComponents = (reduceCallback) => Object.keys(styled).reduce(reduceCallback, {});
const makeAttrsFunction = (anatomy, displayName, element) => (attrs) => createStyledAnatomyFunction(anatomy, displayName, element, attrs);
const createStyledAnatomyFunction = (anatomy, displayName, element, attrs = null) => (...styles) => {
    const Component = (styled[element] || styled(element)).attrs(attrs) `
		${composeStyles(...anatomy.rules)};
		${css(...styles)};
	`;
    Component.propTypes = anatomy.propTypes;
    Component.displayName = displayName;
    return Component;
};
const composeStyles = (...styleRules) => styleRules.reduce((style, rule) => css `
			${style}
			${css(rule)}
		`, css ``);
const mergeAnatomies = (...anatomies) => {
    return anatomies.reduce((newAnatomy, Anatomy) => ({
        rules: [...newAnatomy.rules, ...Anatomy.rules],
        propTypes: Object.assign(Object.assign({}, newAnatomy.propTypes), Anatomy.propTypes),
    }));
};
const getValueFromTheme = (affectedProp, themeObject, fallback) => {
    if (themeObject[affectedProp] ||
        themeObject[affectedProp] === 0 ||
        themeObject[affectedProp] === false) {
        return themeObject[affectedProp];
    }
    return fallback || themeObject.default;
};
const attachDataAttr = (dataAttrValue, dataAttrPrefix = 'component') => (attrs = {}) => (Object.assign({ [`data-slate-${dataAttrPrefix}`]: dataAttrValue }, attrs));
const makeResponsiveStyles = (breakpoints, propVal, styleFn) => {
    if (!Array.isArray(propVal) ||
        (Array.isArray(propVal) && propVal.length === 1))
        return styleFn(propVal);
    const copy = [...propVal];
    const mobileFirst = copy.shift();
    const styles = copy.reduce((responsive, val, index) => breakpoints[++index]
        ? css `
						${responsive};
						@media (min-width: ${breakpoints[index]}px) {
							${styleFn(val)};
						}
				  `
        : responsive, css `
			${styleFn(mobileFirst)};
		`);
    styles.fallback = withFallbackStyles(styles);
    return styles;
};
const withFallbackStyles = (styles) => (when) => {
    try {
        if (!when && typeof when !== 'function') {
            throw Error("Make sure you pass the 'when' function for your fallbacks");
        }
        const applyFallback = when();
        return {
            apply: (styleFn) => applyFallback ? css(styleFn(styles, applyFallback)) : css(styles),
        };
    }
    catch (e) {
        console.error(e);
    }
};
const composeStyleRules = (...rules) => (props) => rules.reduce((rule, ruleFn) => css `
			${rule};
			${ruleFn(props)};
		`, '');

const textColorRules = (props) => props.textColor &&
    css `
		color: ${getValueFromTheme(props.textColor, props.theme.colors, props.textColor)};
	`;
const visitedTextColorRules = (props) => props.visitedTextColor &&
    css `
		&:visited {
			color: ${getValueFromTheme(props.visitedTextColor, props.theme.colors)};
		}
	`;
const borderColorRules = (props) => props.borderColor &&
    makeResponsiveStyles(props.theme.breakpoints, props.borderColor, (val) => css `
			border-color: ${getValueFromTheme(val, props.theme.colors, val)};
		`);
const backgroundColorRules = (props) => props.backgroundColor &&
    css `
		background-color: ${getValueFromTheme(props.backgroundColor, props.theme.colors, props.backgroundColor)};
	`;
const backgroundGradientRules = (props) => props.backgroundGradientColor &&
    css `
		background-image: linear-gradient(${props.backgroundGradientColor});
	`;
const allColorRules = [
    textColorRules,
    visitedTextColorRules,
    borderColorRules,
    backgroundColorRules,
    backgroundGradientRules,
];
const anatomy$f = makeAnatomy(allColorRules);
var index$f = styledAnatomyFactory(anatomy$f);

const paddingRules = (props) => props.padding &&
    makeResponsiveStyles(props.theme.breakpoints, props.padding, (val) => css `
			padding: ${getValueFromTheme(val, props.theme.spacing, val)};
		`);
const paddingLeftRules = (props) => props.paddingLeft &&
    makeResponsiveStyles(props.theme.breakpoints, props.paddingLeft, (val) => css `
			padding-left: ${getValueFromTheme(val, props.theme.spacing, val)};
		`);
const paddingRightRules = (props) => props.paddingRight &&
    makeResponsiveStyles(props.theme.breakpoints, props.paddingRight, (val) => css `
			padding-right: ${getValueFromTheme(val, props.theme.spacing, val)};
		`);
const paddingTopRules = (props) => props.paddingTop &&
    makeResponsiveStyles(props.theme.breakpoints, props.paddingTop, (val) => css `
			padding-top: ${getValueFromTheme(val, props.theme.spacing, val)};
		`);
const paddingBottomRules = (props) => props.paddingBottom &&
    makeResponsiveStyles(props.theme.breakpoints, props.paddingBottom, (val) => css `
			padding-bottom: ${getValueFromTheme(val, props.theme.spacing, val)};
		`);
const marginRules = (props) => props.margin &&
    makeResponsiveStyles(props.theme.breakpoints, props.margin, (val) => css `
			margin: ${getValueFromTheme(val, props.theme.spacing, val)};
		`);
const marginLeftRules = (props) => props.marginLeft &&
    makeResponsiveStyles(props.theme.breakpoints, props.marginLeft, (val) => css `
			margin-left: ${getValueFromTheme(val, props.theme.spacing, val)};
		`);
const marginRightRules = (props) => props.marginRight &&
    makeResponsiveStyles(props.theme.breakpoints, props.marginRight, (val) => css `
			margin-right: ${getValueFromTheme(val, props.theme.spacing, val)};
		`);
const marginBottomRules = (props) => props.marginBottom &&
    makeResponsiveStyles(props.theme.breakpoints, props.marginBottom, (val) => css `
			margin-bottom: ${getValueFromTheme(val, props.theme.spacing, val)};
		`);
const marginTopRules = (props) => props.marginTop &&
    makeResponsiveStyles(props.theme.breakpoints, props.marginTop, (val) => css `
			margin-top: ${getValueFromTheme(val, props.theme.spacing, val)};
		`);
const allPaddingRules = [
    paddingRules,
    paddingLeftRules,
    paddingRightRules,
    paddingBottomRules,
    paddingTopRules,
];
const allMarginRules = [
    marginRules,
    marginLeftRules,
    marginRightRules,
    marginBottomRules,
    marginTopRules,
];
const allSpacingRules = [...allPaddingRules, ...allMarginRules];
const anatomy$e = makeAnatomy(allSpacingRules);
var index$e = styledAnatomyFactory(anatomy$e);

const positionRules = (props) => props.position &&
    makeResponsiveStyles(props.theme.breakpoints, props.position, (val) => css `
			position: ${val};
		`);
const positionTopRules = (props) => props.positionTop &&
    makeResponsiveStyles(props.theme.breakpoints, props.positionTop, (val) => css `
			top: ${val};
		`);
const positionBottomRules = (props) => props.positionBottom &&
    makeResponsiveStyles(props.theme.breakpoints, props.positionBottom, (val) => css `
			bottom: ${val};
		`);
const positionLeftRules = (props) => props.positionLeft &&
    makeResponsiveStyles(props.theme.breakpoints, props.positionLeft, (val) => css `
			left: ${val};
		`);
const positionRightRules = (props) => props.positionRight &&
    makeResponsiveStyles(props.theme.breakpoints, props.positionRight, (val) => css `
			right: ${val};
		`);
const allPositionRules = [
    positionRules,
    positionTopRules,
    positionBottomRules,
    positionLeftRules,
    positionRightRules,
];
const anatomy$d = makeAnatomy(allPositionRules);
var index$d = styledAnatomyFactory(anatomy$d);

const typoHeightRules = (props) => props.typoHeight &&
    makeResponsiveStyles(props.theme.breakpoints, props.typoHeight, (val) => css `
			line-height: ${getValueFromTheme(val, props.theme.typography.lineHeight, val)};
		`);
const typoWeightRules = (props) => props.typoWeight &&
    makeResponsiveStyles(props.theme.breakpoints, props.typoWeight, (val) => css `
			font-weight: ${getValueFromTheme(val, props.theme.typography.fontWeight, val)};
		`);
const typoSizeRules = (props) => props.typoSize &&
    makeResponsiveStyles(props.theme.breakpoints, props.typoSize, (val) => css `
			font-size: ${getValueFromTheme(val, props.theme.typography.fontSize, val)};
		`);
const allTypographyRules = [
    typoHeightRules,
    typoWeightRules,
    typoSizeRules,
];
const anatomy$c = makeAnatomy(allTypographyRules);
var index$c = styledAnatomyFactory(anatomy$c);

const displayRules = (props) => props.display &&
    makeResponsiveStyles(props.theme.breakpoints, props.display, (val) => css `
			display: ${val};
		`);
const allDisplayRules = [displayRules];
const anatomy$b = makeAnatomy(allDisplayRules);
var index$b = styledAnatomyFactory(anatomy$b);

const flexRules = (props) => props.flex &&
    makeResponsiveStyles(props.theme.breakpoints, props.flex, (val) => css `
			flex: ${val};
		`);
const flexBasisRules = (props) => props.flexBasis &&
    makeResponsiveStyles(props.theme.breakpoints, props.flexBasis, (val) => css `
			flex-basis: ${val};
		`);
const flexFlowRules = (props) => props.flexFlow &&
    makeResponsiveStyles(props.theme.breakpoints, props.flexFlow, (val) => css `
			flex-flow: ${val};
		`);
const flexGrowRules = (props) => props.flexGrow &&
    makeResponsiveStyles(props.theme.breakpoints, props.flexGrow, (val) => css `
			flex-grow: ${val};
		`);
const flexShrinkRules = (props) => props.flexShrink &&
    makeResponsiveStyles(props.theme.breakpoints, props.flexShrink, (val) => css `
			flex-shrink: ${val};
		`);
const flexWrapRules = (props) => props.flexWrap &&
    makeResponsiveStyles(props.theme.breakpoints, props.flexWrap, (val) => css `
			flex-wrap: ${val};
		`);
const flexDirectionRules = (props) => props.flexDirection &&
    makeResponsiveStyles(props.theme.breakpoints, props.flexDirection, (val) => css `
			flex-direction: ${val};
		`);
const alignContentRules = (props) => props.alignContent &&
    makeResponsiveStyles(props.theme.breakpoints, props.alignContent, (val) => css `
			align-content: ${val};
		`);
const alignItemsRules = (props) => props.alignItems &&
    makeResponsiveStyles(props.theme.breakpoints, props.alignItems, (val) => css `
			align-items: ${val};
		`);
const alignSelfRules = (props) => props.alignSelf &&
    makeResponsiveStyles(props.theme.breakpoints, props.alignSelf, (val) => css `
			align-self: ${val};
		`);
const justifyContentRules = (props) => props.justifyContent &&
    makeResponsiveStyles(props.theme.breakpoints, props.justifyContent, (val) => css `
			justify-content: ${val};
		`);
const flexOrderRules = (props) => props.flexOrder &&
    makeResponsiveStyles(props.theme.breakpoints, props.flexOrder, (val) => css `
			order: ${val};
		`);
const allFlexRules = [
    flexRules,
    flexBasisRules,
    flexFlowRules,
    flexGrowRules,
    flexShrinkRules,
    flexWrapRules,
    flexDirectionRules,
    alignContentRules,
    alignItemsRules,
    alignSelfRules,
    justifyContentRules,
    flexOrderRules,
];
const anatomy$a = makeAnatomy(allFlexRules);
var index$a = styledAnatomyFactory(anatomy$a);

const widthRules = (props) => props.elWidth &&
    makeResponsiveStyles(props.theme.breakpoints, props.elWidth, (val) => css `
				width: ${val};
			`);
const heightRules = (props) => props.elHeight &&
    makeResponsiveStyles(props.theme.breakpoints, props.elHeight, (val) => css `
				height: ${val};
			`);
const minWidthRules = (props) => props.elMinWidth &&
    makeResponsiveStyles(props.theme.breakpoints, props.elMinWidth, (val) => css `
				min-width: ${val};
			`);
const minHeightRules = (props) => props.elMinHeight &&
    makeResponsiveStyles(props.theme.breakpoints, props.elMinHeight, (val) => css `
				min-height: ${val};
			`);
const maxWidthRules = (props) => props.elMaxWidth &&
    makeResponsiveStyles(props.theme.breakpoints, props.elMaxWidth, (val) => css `
				max-width: ${val};
			`);
const maxHeightRules = (props) => props.elMaxHeight &&
    makeResponsiveStyles(props.theme.breakpoints, props.elMaxHeight, (val) => css `
				max-height: ${val};
			`);
const allDimensionsRules = [
    widthRules,
    heightRules,
    minWidthRules,
    minHeightRules,
    maxWidthRules,
    maxHeightRules,
];
const anatomy$9 = makeAnatomy(allDimensionsRules);
var index$9 = styledAnatomyFactory(anatomy$9);

const borderRules = (props) => props.border &&
    makeResponsiveStyles(props.theme.breakpoints, props.border, (val) => css `
				border: ${getValueFromTheme(val, props.theme.borders, val)};
			`);
const borderWidthRules = (props) => props.borderWidth &&
    makeResponsiveStyles(props.theme.breakpoints, props.borderWidth, (val) => css `
				border-width: ${val};
			`);
const borderStyleRules = (props) => props.borderStyle &&
    makeResponsiveStyles(props.theme.breakpoints, props.borderStyle, (val) => css `
				border-style: ${val};
			`);
const borderRadiusRules = (props) => props.borderRadius &&
    makeResponsiveStyles(props.theme.breakpoints, props.borderRadius, (val) => css `
			border-radius: ${getValueFromTheme(val, props.theme.borders.radius, val)};
		`);
const borderTopRules = (props) => props.borderTop &&
    makeResponsiveStyles(props.theme.breakpoints, props.borderTop, (val) => css `
			border-top: ${getValueFromTheme(val, props.theme.borders, val)};
		`);
const borderBottomRules = (props) => props.borderBottom &&
    makeResponsiveStyles(props.theme.breakpoints, props.borderBottom, (val) => css `
			border-bottom: ${getValueFromTheme(val, props.theme.borders, val)};
		`);
const borderLeftRules = (props) => props.borderLeft &&
    makeResponsiveStyles(props.theme.breakpoints, props.borderLeft, (val) => css `
			border-left: ${getValueFromTheme(val, props.theme.borders, val)};
		`);
const borderRightRules = (props) => props.borderRight &&
    makeResponsiveStyles(props.theme.breakpoints, props.borderRight, (val) => css `
			border-right: ${getValueFromTheme(val, props.theme.borders, val)};
		`);
const borderTopRightRadiusRules = (props) => props.borderTopRightRadius &&
    makeResponsiveStyles(props.theme.breakpoints, props.borderTopRightRadius, (val) => css `
			border-top-right-radius: ${getValueFromTheme(val, props.theme.borders.radius, val)};
		`);
const borderTopLeftRadiusRules = (props) => props.borderTopLeftRadius &&
    makeResponsiveStyles(props.theme.breakpoints, props.borderTopLeftRadius, (val) => css `
			border-top-left-radius: ${getValueFromTheme(val, props.theme.borders.radius, val)};
		`);
const borderBottomRightRadiusRules = (props) => props.borderBottomRightRadius &&
    makeResponsiveStyles(props.theme.breakpoints, props.borderBottomRightRadius, (val) => css `
			border-bottom-right-radius: ${getValueFromTheme(val, props.theme.borders.radius, val)};
		`);
const borderBottomLeftRadiusRules = (props) => props.borderBottomLeftRadius &&
    makeResponsiveStyles(props.theme.breakpoints, props.borderBottomLeftRadius, (val) => css `
			border-bottom-left-radius: ${getValueFromTheme(val, props.theme.borders.radius, val)};
		`);
const allBorderRules = [
    borderRules,
    borderWidthRules,
    borderStyleRules,
    borderRadiusRules,
    borderTopRules,
    borderBottomRules,
    borderLeftRules,
    borderRightRules,
    borderTopRightRadiusRules,
    borderTopLeftRadiusRules,
    borderBottomRightRadiusRules,
    borderBottomLeftRadiusRules,
];
const anatomy$8 = makeAnatomy(allBorderRules);
var index$8 = styledAnatomyFactory(anatomy$8);

const boxShadowRules = (props) => props.shadow &&
    makeResponsiveStyles(props.theme.breakpoints, props.shadow, (val) => css `
			box-shadow: ${getValueFromTheme(val, props.theme.boxShadows, val)};
		`);
const allShadowsRules = [boxShadowRules];
const anatomy$7 = makeAnatomy(allShadowsRules);
var index$7 = styledAnatomyFactory(anatomy$7);

const overflow = ({ theme, overflow }) => overflow &&
    makeResponsiveStyles(theme.breakpoints, overflow, (val) => css `
			overflow: ${val};
		`);
const overflowX = ({ theme, overflowX }) => overflowX &&
    makeResponsiveStyles(theme.breakpoints, overflowX, (val) => css `
			overflow-x: ${val};
		`);
const overflowY = ({ theme, overflowY }) => overflowY &&
    makeResponsiveStyles(theme.breakpoints, overflowY, (val) => css `
			overflow-y: ${val};
		`);
const allOverflowRules = [overflow, overflowY, overflowX];
const anatomy$6 = makeAnatomy(allOverflowRules);
var index$6 = styledAnatomyFactory(anatomy$6);

const textAlignRules = ({ theme, textAlign }) => textAlign &&
    makeResponsiveStyles(theme.breakpoints, textAlign, (val) => css `
			text-align: ${val};
		`);
const textDecorationRules = ({ theme, textDecoration }) => textDecoration &&
    makeResponsiveStyles(theme.breakpoints, textDecoration, (val) => css `
			text-decoration: ${val};
		`);
const textTransformRules = ({ theme, textTransform }) => textTransform &&
    makeResponsiveStyles(theme.breakpoints, textTransform, (val) => css `
			text-transform: ${val};
		`);
const allTextFormatRules = [
    textAlignRules,
    textDecorationRules,
    textTransformRules,
];
const anatomy$5 = makeAnatomy(allTextFormatRules);
var index$5 = styledAnatomyFactory(anatomy$5);

const shimmer = keyframes `
	0% {
		background-position: top left;
	}
	100% {
		background-position: top right;
	}
`;
const optimisticRules = ({ theme, optimistic }) => optimistic &&
    css `
		background-image: linear-gradient(
			to right,
			${theme.colors.ambient} 0%,
			${theme.colors.ambient} 33%,
			${theme.colors.ambientDark} 50%,
			${theme.colors.ambient} 66%,
			${theme.colors.ambient} 100%
		);
		background-repeat: no-repeat;
		background-size: 1000px 100%;
		animation: ${shimmer} 1000ms ease-out infinite forwards;
	`;
const allOptimisticRules = [optimisticRules];
const anatomy$4 = makeAnatomy(allOptimisticRules);
var index$4 = styledAnatomyFactory(anatomy$4);

const zIndexRules = ({ zIndex, theme }) => zIndex &&
    makeResponsiveStyles(theme.breakpoints, zIndex, (val) => css `
			z-index: ${getValueFromTheme(val, theme.zIndex, val)};
		`);
const allZDepthRules = [zIndexRules];
const anatomy$3 = makeAnatomy(allZDepthRules);
var index$3 = styledAnatomyFactory(anatomy$3);

const gridRules = ({ theme, grid }) => grid &&
    makeResponsiveStyles(theme.breakpoints, grid, (val) => css `
				display: ${grid ? 'grid' : val};
			`);
const gridRulesFallback = ({ theme, grid }) => grid &&
    makeResponsiveStyles(theme.breakpoints, grid, (val) => css `
				display: ${grid ? 'flex' : val};
				flex-direction: column;
			`);
const gridRowsRules = ({ theme, grid, gridRows }) => grid &&
    gridRows &&
    makeResponsiveStyles(theme.breakpoints, gridRows, (val) => css `
			grid-template-rows: ${val};
		`);
const gridColumnsRules = ({ theme, grid, gridColumns }) => grid &&
    gridColumns &&
    makeResponsiveStyles(theme.breakpoints, gridColumns, (val) => css `
			grid-template-columns: ${val};
		`);
const gridColumnGapRules = ({ theme, grid, gridColumnGap }) => grid &&
    gridColumnGap &&
    makeResponsiveStyles(theme.breakpoints, gridColumnGap, (val) => css `
			grid-column-gap: ${val};
		`);
const gridRowGapRules = ({ theme, grid, gridRowGap }) => grid &&
    gridRowGap &&
    makeResponsiveStyles(theme.breakpoints, gridRowGap, (val) => css `
			grid-row-gap: ${val};
		`);
const gridGapRules = ({ theme, grid, gridGap }) => grid &&
    gridGap &&
    makeResponsiveStyles(theme.breakpoints, gridGap, (val) => css `
			grid-gap: ${val};
		`);
const gridRowRules = ({ theme, gridRow }) => gridRow &&
    makeResponsiveStyles(theme.breakpoints, gridRow, (val) => css `
			grid-row: ${val};
		`);
const gridColumnRules = ({ theme, gridColumn }) => gridColumn &&
    makeResponsiveStyles(theme.breakpoints, gridColumn, (val) => css `
			grid-column: ${val};
		`);
const composedGridRules = (props) => css `
	${gridRulesFallback};
	@supports (display: grid) {
		${composeStyleRules(gridRules, gridRowsRules, gridColumnsRules, gridColumnGapRules, gridRowGapRules, gridGapRules, gridRowRules, gridColumnRules)(props)};
	}
`;
const allGridRules = [composedGridRules];
const anatomy$2 = makeAnatomy(allGridRules);
var index$2 = styledAnatomyFactory(anatomy$2);

const anatomy$1 = mergeAnatomies(anatomy$8, anatomy$f, anatomy$7, anatomy$e, anatomy$c, anatomy$3);
Object.assign({}, anatomy$1.propTypes);
anatomy$1.rules;
var index$1 = styledAnatomyFactory(anatomy$1);

const anatomy = mergeAnatomies(anatomy$9, anatomy$b, anatomy$a, anatomy$2, anatomy$4, anatomy$6, anatomy$d, anatomy$5);
anatomy.rules;
var index = styledAnatomyFactory(anatomy);

export { attachDataAttr, anatomy$8 as borderAnatomy, index$8 as borderStyles, anatomy$f as colorAnatomy, index$f as colorStyles, anatomy$1 as coreAnatomy, index$1 as coreStyles, anatomy$9 as dimensionsAnatomy, index$9 as dimensionsStyles, anatomy$b as displayAnatomy, index$b as displayStyles, anatomy$a as flexAnatomy, index$a as flexStyles, getValueFromTheme, anatomy$2 as gridAnatomy, index$2 as gridStyles, anatomy as helperAnatomy, index as helperStyles, mergeAnatomies, anatomy$4 as optimisticAnatomy, index$4 as optimisticStyles, anatomy$6 as overflowAnatomy, index$6 as overflowStyles, anatomy$d as positionAnatomy, index$d as positionStyles, anatomy$7 as shadowsAnatomy, index$7 as shadowsStyles, anatomy$e as spacingAnatomy, index$e as spacingStyles, styledAnatomyFactory, anatomy$5 as textFormatAnatomy, index$5 as textFormatStyles, anatomy$c as typographyAnatomy, index$c as typographyStyles, anatomy$3 as zDepthAnatomy, index$3 as zDepthStyles };
