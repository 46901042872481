import React from 'react';
import { observer } from 'mobx-react-lite';
import { Typography, Tag } from '@icecreamsocial/components';

const TableStatusLabel = ({ status = '' }) => {
	const statusColor =
		status === 'active'
			? 'good'
			: status === 'disabled'
			? 'ambientDark'
			: 'warning';

	return (
		<Tag display="block" textColor={statusColor} borderColor={statusColor}>
			<Typography.Span textTransform="capitalize">{status}</Typography.Span>
		</Tag>
	);
};
TableStatusLabel.displayName = 'TableStatusLabel';

export default observer(TableStatusLabel);
