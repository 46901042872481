import { Div, InputGroup, Typography } from '@icecreamsocial/components';
import { Radio, RadioGroup } from '@material-ui/core';
import React from 'react';
import { useScriptSettings } from '../../../../providers';

export const TrackingCustomizer = () => {
	const { settings, update } = useScriptSettings();

	const handleChange = (field) => (e) => {
		const { value } = e.currentTarget;
		update({ [field]: value });
	};

	return (
		<form>
			<Typography.H4 marginTop='none'>Engagement Type</Typography.H4>
			<RadioGroup name='engagementType'>
				{settings.version === 'latest' && (
					<InputGroup elWidth='100%' marginBottom='md'>
						<Div display='flex' alignItems='center' marginBottom='sm'>
							<Radio
								color='primary'
								value={undefined}
								checked={!settings.engagementType}
								onChange={handleChange('engagementType')}
							/>{' '}
							<InputGroup.Label display='inline-block' typoSize='1.1em'>
								Automatic (recommended)
							</InputGroup.Label>
						</Div>
						<Typography.Span display='block' paddingLeft='sm'>
							The script will determine the engagement type based on the data
							passed to it. If the subtotal and orderId are passed to it, this
							will automatically record a transaction
						</Typography.Span>
					</InputGroup>
				)}
				<InputGroup elWidth='100%' marginBottom='md'>
					<Div display='flex' alignItems='center' marginBottom='sm'>
						<Radio
							color='primary'
							value='transaction'
							checked={
								settings.engagementType === 'transaction' ||
								(settings.version === 'v1' && !settings.engagementType)
							}
							onChange={handleChange('engagementType')}
						/>{' '}
						<InputGroup.Label display='inline-block' typoSize='1.1em'>
							Transaction
						</InputGroup.Label>
					</Div>
					<Typography.Span display='block' paddingLeft='sm'>
						Most commonly used, this tracks influencer purchases and relies on
						collecting order information, such as totals and transaction IDs
					</Typography.Span>
				</InputGroup>
				<InputGroup elWidth='100%' marginBottom='md'>
					<Div display='flex' alignItems='center' marginBottom='sm'>
						<Radio
							color='primary'
							value='signup'
							checked={settings.engagementType === 'signup'}
							onChange={handleChange('engagementType')}
						/>{' '}
						<InputGroup.Label display='inline-block'>Sign Up</InputGroup.Label>
					</Div>
					<Typography.Span display='block' paddingLeft='sm'>
						Use this to track sign-ups where you are encouraging users to
						register for an account, newsletters, product updates, etc.
					</Typography.Span>
				</InputGroup>
				<InputGroup elWidth='100%' marginBottom='md'>
					<Div display='flex' alignItems='center' marginBottom='sm'>
						<Radio
							color='primary'
							value='participation'
							checked={settings.engagementType === 'participation'}
							onChange={handleChange('engagementType')}
						/>{' '}
						<InputGroup.Label display='inline-block'>
							Participation
						</InputGroup.Label>
					</Div>
					<Typography.Span display='block' paddingLeft='sm'>
						Catch-all, generic engagement type, if used strategically you can
						track any user behavior you like (completion of a survey, submiting
						a review, watching a video, for example)
					</Typography.Span>
				</InputGroup>
			</RadioGroup>
		</form>
	);
};
