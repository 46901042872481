import React, { useEffect, useLayoutEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory, Link } from 'react-router-dom';
import {
	Button,
	Div,
	Container,
	Spanner,
	Panel,
	Typography,
} from '@icecreamsocial/components';
import { HeaderPortal } from '../Main';
import { useStore, useCookies, useQueryString } from '../../../utils/hooks';
import {
	CloseIcon,
	SquareLocationsForm,
	PageMeta,
	PopsicleLoader,
	Pagename,
} from '../../presentational';
import { flags, toggle } from '../../../utils';

// import { BusinessSquareConnectForm } from '../../presentational';

/**
 * @featureFlags
 */
const ENABLE_EXPERIMENTAL_SQUARE_CONNECT_URL = flags.isActive(
	flags.names.ENABLE_EXPERIMENTAL_SQUARE_CONNECT_URL
);

const DEPRECATED_SQUARE_URL = 'https://connect.squareup.com/oauth2/authorize';
const NEW_SQUARE_URL =
	'https://squareup.com/t/cmtp_performance/pr_cross_product/d_partnerships/subch_oauth/p_icecreamsocial/l_us/';

const SquareConnect = () => {
	// initial hooks
	const store = useStore();
	const { getCookie, deleteCookie } = useCookies();
	const { qs, getParam, query } = useQueryString();

	// collect some variables
	const redirect =
		getCookie('squareConnectOrigin') || '/settings/reward-processor';

	// if response_type is code, it was successfully authorized
	const success = getParam('response_type') === 'code';
	const authorizationCode = getParam('code');
	const stateParam = getParam('state');

	const scope = store.Orchestrators.Auth.squarePermissionRequests.join(' ');
	const squareOAuthUrl = toggle(
		ENABLE_EXPERIMENTAL_SQUARE_CONNECT_URL,
		NEW_SQUARE_URL,
		DEPRECATED_SQUARE_URL
	);

	const deprecated_params = {
		scope,
		client_id: process.env.SQUARE_CLIENT_ID,
	};
	const new_params = {
		route: 'oauth2/authorize',
		client_id: process.env.SQUARE_CLIENT_ID,
	};
	const params = toggle(
		ENABLE_EXPERIMENTAL_SQUARE_CONNECT_URL,
		new_params,
		deprecated_params
	);
	const encode = toggle(ENABLE_EXPERIMENTAL_SQUARE_CONNECT_URL, false, true);
	const authParams = qs.stringify(params, { encode });
	const squareUrl = `${squareOAuthUrl}?${authParams}`;

	const handleNavigateToSquare = async (e) => {
		e.preventDefault();
		const data = await store.generateStateParam();
		const param = (data.user[0] || {}).stateParameter;
		window.open(`${squareUrl}&state=${param}&session=false`, '_self');
	};

	// handle the location save
	const onSubmit = useCallback(({ squareLocationId }) => {
		const { createdAt, updatedAt, ...processor } = store.userProcessor || {};
		const { id, name } = store.Collections.SquareLocations.list[
			squareLocationId
		];
		store.updateProcessorFlow({
			...processor,
			squareLocationId: id,
			squareLocationName: name,
		});
	}, []);

	// effect for the view.  only run once
	useLayoutEffect(() => {
		store.Orchestrators.View.setSingleColumn();
		return () => {
			store.Orchestrators.View.setSingleColumn(false);
		};
	}, []);
	// effect for creating the processor
	useEffect(() => {
		if (success && !!authorizationCode) {
			store.createSquareProcessorFlow({
				authorizationCode,
				state: stateParam,
			});
		}
		// clean up the cookie
		return () => {
			deleteCookie('squareConnectOrigin');
		};
	}, []);
	// effect for cleaning up errors
	useEffect(
		() => () => {
			store.Orchestrators.View.removeError('creatingSquareProcessor');
			store.Orchestrators.View.removeError('creatingProcessor');
		},
		[]
	);

	return (
		<>
			<PageMeta
				title={'Square Connect | Ice Cream Social'}
				description={'Finalize Square Connection'}
			/>
			<HeaderPortal>
				<Spanner
					grid
					gridColumns='repeat(3, 1fr)'
					backgroundColor='brandDark'
					textColor='bodyInverse'
				>
					<Typography.Span
						as={Link}
						to={redirect}
						textColor='bodyInverse'
						display='flex'
						alignItems='center'
					>
						<CloseIcon marginRight='md' /> Cancel Setup
					</Typography.Span>
					<Typography.Span textAlign='center'>Square Connect</Typography.Span>
				</Spanner>
			</HeaderPortal>
			<Container>
				{store.isLoading('creatingSquareProcessor') ? (
					<Panel marginTop='xlg' backgroundColor='bodyInverse'>
						<Panel.Body textAlign='center'>
							<PopsicleLoader />
							<Typography.P marginTop='none'>
								Setting up your Square processor...
							</Typography.P>
						</Panel.Body>
					</Panel>
				) : store.hasErrors('creatingSquareProcessor') ||
				  store.hasErrors('creatingProcessor') ? (
					<Panel marginTop='xlg' backgroundColor='warningLight' border='none'>
						<Panel.Body textAlign='center'>
							<Typography.H1 typoWeight='500'>
								Whoops! Something unexpected happened
							</Typography.H1>
							<Typography.P>
								{store.getErrors('creatingProcessor')}
							</Typography.P>
							<Typography.P>
								{store.getErrors('creatingSquareProcessor')}
							</Typography.P>
							<Button
								as={Link}
								to='/settings/reward-processor'
								textDecoration='none'
								textColor='body'
								backgroundColor='ambientLight'
							>
								Return to Setup
							</Button>
						</Panel.Body>
					</Panel>
				) : (
					<>
						<Pagename marginTop='lg'>Finalize Square Processor Setup</Pagename>
						{query.error === 'access_denied' ? (
							<Panel backgroundColor='bodyInverse' marginBottom='xlg'>
								<Panel.Header backgroundColor='transparent' padding='0'>
									<Typography.H2
										textColor='body'
										typoWeight='500'
										textAlign='center'
									>
										Please allow permissions
									</Typography.H2>
								</Panel.Header>
								<Panel.Body>
									<Typography.P>
										We need permission to process rewards using Square. We will
										not sell your information and will only be used to
										automatically process rewards on your behalf.
									</Typography.P>
									<Div display='flex' flexDirection='column'>
										<Button
											onClick={async (e) => await handleNavigateToSquare(e)}
											backgroundColor='brand'
											textColor='bodyInverse'
											margin='auto'
											marginBottom='lg'
										>
											Connect Square Account
										</Button>
										<Typography.A
											as={Link}
											to={redirect}
											textColor='ambientDark'
											alignItems='center'
											margin='auto'
											marginBottom='xlg'
										>
											Cancel Setup
										</Typography.A>
									</Div>
								</Panel.Body>
							</Panel>
						) : (
							<>
								<Typography.P>Please choose a Square Location</Typography.P>
								<Panel backgroundColor='bodyInverse'>
									<Panel.Body>
										{!(store.userProcessor || {}).squareLocationId ? (
											<SquareLocationsForm
												locations={store.Collections.SquareLocations.list}
												onSubmit={onSubmit}
											/>
										) : (
											<Div>
												<Typography.P>
													You've successfully connected{' '}
													<Typography.Span typoWeight='bold'>
														{store.userProcessor.squareLocationName}
													</Typography.Span>
												</Typography.P>
												<Div textAlign='right'>
													<Button
														as={Link}
														to={redirect}
														textDecoration='none'
														backgroundColor='brand'
														textColor='bodyInverse'
													>
														Continue Setup
													</Button>
												</Div>
											</Div>
										)}
									</Panel.Body>
								</Panel>
							</>
						)}
					</>
				)}
			</Container>
		</>
	);
};
SquareConnect.displayName = 'SquareConnect';

export default observer(SquareConnect);
