import { types } from 'mobx-state-tree';
import Affiliates from './Affiliates';
import Businesses from './Businesses';
import Campaigns from './Campaigns';
import Clients from './Clients';
import Conversions from './Conversions';
import Influencers from './Influencers';
import Invites from './Invites';
import RewardProcessors from './RewardProcessors';
import Stats from './Stats';
import SquareLocations from './SquareLocations';
import Users from './Users';
import CampaignConversions from './CampaignConversions';
import Rewards from './Rewards';

const Collections = types
	.model({
		Affiliates,
		Businesses,
		Campaigns,
		Clients,
		Conversions,
		Influencers,
		Invites,
		RewardProcessors,
		Stats,
		SquareLocations,
		Users,
		CampaignConversions,
		Rewards,
	})
	.named('Collections')
	.preProcessSnapshot((snapshot = {}) => ({
		...snapshot,
		Affiliates: snapshot.Affiliates || {},
		Campaigns: snapshot.Campaigns || {},
		Clients: snapshot.Clients || {},
		Conversions: snapshot.Conversions || {},
		Influencers: snapshot.Influencers || {},
		Invites: snapshot.Invites || {},
		SquareLocations: snapshot.SquareLocations || {},
		Stats: snapshot.Stats || {},
		Users: snapshot.Users || {},
		CampaignConversions: snapshot.CampaignConversions || {},
		Rewards: snapshot.Rewards || {},
	}))
	.actions((self) => ({
		unsetAll() {
			self.Affiliates.clearAll();
			self.Businesses.clear();
			self.Campaigns.clearAll();
			self.Clients.clearAll();
			self.Conversions.clearAll();
			self.Influencers.clearAll();
			self.Invites.clearAll();
			self.RewardProcessors.clear();
			self.SquareLocations.clearAll();
			self.Stats.clearAll();
			self.Users.clearAll();
			self.CampaignConversions.clearAll();
			self.Rewards.clearAll();
		},
	}));

export default Collections;
