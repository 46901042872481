import { types } from 'mobx-state-tree';
import {
	IcsAffiliate,
	IcsCampaign,
	IcsClient,
	IcsInfluencer,
	IcsInvite,
	IcsUser,
} from '@icecreamsocial/mst-models';

const Focused = types
	.model({
		affiliate: types.maybe(types.reference(IcsAffiliate)),
		campaign: types.maybe(types.reference(IcsCampaign)),
		client: types.maybe(types.reference(IcsClient)),
		influencer: types.maybe(types.reference(IcsInfluencer)),
		invite: types.maybe(types.reference(IcsInvite)),
		user: types.maybe(types.reference(IcsUser)),
	})
	.named('FocusedOrchestrator')
	.actions((self) => ({
		setFocus(key, val) {
			self[key] = val;
		},
		unsetFocus(key) {
			self[key] = undefined;
		},
	}))
	.views((self) => ({
		get campaignId() {
			return self.campaign && self.campaign.id;
		},
		get campaignName() {
			return self.campaign && self.campaign.name;
		},
		get campaignLocale() {
			const locale = self.campaign?.locale ?? 'en';
			const localeMap = {
				de: 'de-de',
				en: 'en-US',
				es: 'es',
				fr: 'fr-FR',
				ja: 'ja-JP',
				ko: 'ko-KR',
				zh: 'zh-CN',
			};
			return locale.length === 2 ? localeMap[locale] : locale;
		},
		getCampaignSetting(setting) {
			return self.campaign ? self.campaign[setting] : '';
		},
		get userId() {
			return self.user ? self.user.id : '';
		},
		get affiliateId() {
			return self.affiliate ? self.affiliate.id : '';
		},
		get clientId() {
			return self.client ? self.client.id : '';
		},
	}));

export default Focused;
