import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@icecreamsocial/components';

const NavbarLink = ({ active = false, ...props }) => (
	<Button
		as={Link}
		display="flex"
		alignItems="center"
		elWidth="100%"
		backgroundColor={active ? 'brandDark' : 'transparent'}
		shadow={active ? 'default' : 'none'}
		hoverShadow={active ? 'default' : 'raised'}
		textColor="bodyInverse"
		textAlign="left"
		textDecoration="none"
		padding="md"
		{...props}
	/>
);

NavbarLink.displayName = 'NavbarLink';

export default NavbarLink;
