import React from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import { Div, Container } from '@icecreamsocial/components';
import { useRouteConfig } from '../../../../../../../utils/hooks';

import General from './sub-routes/General';
import Offer from './sub-routes/Offer';
import Share from './sub-routes/Share';
import Policy from './sub-routes/Policy';
import { SideTabsNav } from '../../../../../../presentational';
import { OptimisticSettings } from '../../../../../../optimistic';
import Notifications from './sub-routes/Notifications';
import { useCampaign } from '../../providers/Campaign';

const Settings = () => {
	const { campaign } = useCampaign();
	const { isLoading } = campaign;

	const routeConfig = [
		{
			path: `general`,
			component: General,
			linkText: 'general',
		},
		{
			path: `offer`,
			component: Offer,
			linkText: 'offer',
		},
		{
			path: `share`,
			component: Share,
			linkText: 'share',
		},
		{
			path: 'notifications',
			component: Notifications,
			linkText: 'Notifications',
		},
		{
			path: `data-and-policy`,
			component: Policy,
			linkText: 'data & policy',
		},
	];

	const { routes, links } = useRouteConfig(routeConfig);

	return (
		<Container>
			{isLoading ? (
				<OptimisticSettings />
			) : (
				<Div display='flex' padding='md'>
					<Div>
						<Div position='sticky' positionTop='16px'>
							<SideTabsNav routes={links} />
						</Div>
					</Div>
					<Div marginLeft='lg' flexGrow='1'>
						<Switch>
							{routes.map(({ link, ...route }, i) => (
								<Route key={i} {...route} />
							))}
							<Redirect to={`settings/general`} />
						</Switch>
					</Div>
				</Div>
			)}
		</Container>
	);
};

Settings.displayName = 'Campaigns.Settings';

export default Settings;
