import React, {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useState,
} from 'react';
import { observer } from 'mobx-react-lite';
import { Div, Select, Typography } from '@icecreamsocial/components';
import { useStore } from '../../../utils/hooks';
import { queryKeys } from '../../../queries/clients';
import { useQuery } from '@tanstack/react-query';
import { useField, useFormikContext } from 'formik';

/**
 * Container component that retrieves the affiliateId's accessible clients
 */

const ClientSelector = (
	{ isMulti = false, name, affiliateId, placeholder, ...rest },
	ref
) => {
	const { getClients } = useStore();
	const formik = useFormikContext();
	const [field, meta, helpers] = useField(name);

	/**
	 * since the label is not present when the default value is being set, we update the default value
	 * once the data has been fetched
	 */
	const getDefault = (options) => {
		if (!options) return;

		const def = options.filter(
			({ value: optValue }) =>
				optValue.toString() === formik.initialValues[name]?.value
		)?.[0];
		helpers.setValue(def);
		// don't want to count this as the input being touched
		helpers.setTouched(false);
	};

	/**
	 * Fetch the client data
	 */
	const { data, isLoading } = useQuery(
		queryKeys.getClients({ affiliateId }),
		async () => {
			const { clients } = await getClients({
				affiliateId,
			});

			const options = clients?.map(({ id: value, name: label }) => ({
				value,
				label,
			}));

			getDefault(options);

			return options;
		},
		{ refetchOnWindowFocus: false }
	);

	/**
	 * imperatively set the field value on change
	 */
	const onChange = (value) => helpers.setValue(value);

	/**
	 * imperatively set the field to touched on blur
	 */
	const onBlur = () => helpers.setTouched(true);

	/**
	 * expose the `clearSelect` method on the ref of this component
	 */
	useImperativeHandle(ref, () => ({
		clearSelect: () => {
			helpers.setValue(isMulti ? [] : null);
		},
	}));

	return isLoading ? (
		<Div optimistic padding='12px' borderRadius='default'>
			<Typography.P margin='0'>&nbsp;</Typography.P>
		</Div>
	) : (
		<Select
			{...field}
			placeholder={data.length ? placeholder : 'No items'}
			isLoading={isLoading}
			isDisabled={isLoading}
			onChange={onChange}
			onBlur={onBlur}
			options={data}
			{...rest}
		/>
	);
};

ClientSelector.displayName = 'ClientSelector';

export default observer(ClientSelector, { forwardRef: true });
