import React from 'react';
import { Panel, Typography, Div } from '@icecreamsocial/components';
import { NmiLogo } from '../../../../../presentational';

const NmiDescription = () => {
	return (
		<Panel marginBottom="md" backgroundColor="bodyInverse">
			<Panel.Body display="flex" alignItems="center" style={{ gap: '16px' }}>
				<NmiLogo />
				<Div flex="1 0">
					<Typography.H4
						display="block"
						typoSize="lg"
						typoWeight="400"
						marginTop="0"
						marginBottom="md"
					>
						NMI
					</Typography.H4>
					<Typography.Span display="block">
						Reward influencers through order refund credit
					</Typography.Span>
				</Div>
			</Panel.Body>
		</Panel>
	);
};

NmiDescription.displayName = 'NmiDescription';

export default NmiDescription;
