import React from 'react';
import { Switch } from '@material-ui/core';
import { InputGroup, Typography, Div } from '@icecreamsocial/components';
import { useScriptSettings } from '../../../../providers';
import { MultiToggle } from '../../../../../../../../../../presentational';

export const BehaviorCustomizer = () => {
	const { settings, updateBehavior } = useScriptSettings();

	const handleChange = (field) => (e) => {
		const { checked } = e.currentTarget;
		updateBehavior({ [field]: checked });
	};

	return (
		<form>
			{settings.behavior?.flavor !== 'embedded' && (
				<>
					<InputGroup marginBottom='sm'>
						<InputGroup.Label display='inline-block' typoWeight='500'>
							Open by default
						</InputGroup.Label>
						<Div display='flex' alignItems='center'>
							<Switch
								name={`openByDefault`}
								color='primary'
								defaultChecked={false}
								checked={settings.behavior?.openByDefault}
								onChange={handleChange('openByDefault')}
							/>{' '}
							<Typography.Span typoSize='sm'>
								If disabled, the widget won't launch until a user interacts with
								a triggering element, if present or if launched via{' '}
								<Typography.Span as='code'>ICS.serve</Typography.Span> or{' '}
								<Typography.Span as='code'>ICS.open</Typography.Span>
							</Typography.Span>
						</Div>
					</InputGroup>
					<InputGroup marginBottom='sm'>
						<InputGroup.Label display='inline-block' typoWeight='500'>
							Force open
						</InputGroup.Label>
						<Div display='flex' alignItems='center'>
							<Switch
								name={`forceOpen`}
								color='primary'
								value={settings.behavior?.forceOpen}
								checked={settings.behavior?.forceOpen}
								onChange={handleChange('forceOpen')}
							/>{' '}
							<Typography.Span typoSize='sm'>
								If active, widget will always open on page load, even if user
								dimissed it elsewhere previously or there's no trigger element
							</Typography.Span>
						</Div>
					</InputGroup>
					<InputGroup marginBottom='md'>
						<InputGroup.Label display='inline-block' typoWeight='500'>
							Open when invited
						</InputGroup.Label>
						<Div display='flex' alignItems='center'>
							<Switch
								name={`openOnInvite`}
								color='primary'
								defaultChecked={true}
								value={settings.behavior?.openOnInvite}
								checked={settings.behavior?.openOnInvite}
								onChange={handleChange('openOnInvite')}
							/>{' '}
							<Typography.Span typoSize='sm'>
								If active, the widget will always open the user lands on the
								page from an influencer's share link
							</Typography.Span>
						</Div>
					</InputGroup>
					<Typography.Span display='block' marginBottom='sm' typoWeight='500'>
						Launch delay
					</Typography.Span>
					<MultiToggle
						options={[
							{
								text: 'Show immediately',
								onClick: () => updateBehavior({ delay: undefined }),
							},
							{
								text: 'Show after X milliseconds',
								onClick: () => updateBehavior({ delay: 0 }),
							},
						]}
					/>
					{settings.behavior?.delay >= 0 && (
						<InputGroup marginBottom='md'>
							<InputGroup.Label typoWeight='500'>
								Delay (milliseconds)
							</InputGroup.Label>
							<Div
								display='flex'
								alignItems='center'
								elWidth='100%'
								style={{ gap: '16px' }}
							>
								<InputGroup.Fields>
									<InputGroup.Input
										name='delay'
										onChange={(e) => {
											const { value } = e.currentTarget;
											updateBehavior({ delay: value });
										}}
										defaultValue={0}
										value={settings.behavior?.delay}
										paddingLeft='md'
									/>
								</InputGroup.Fields>
								<InputGroup.Label>
									ms{' '}
									<Typography.Span as='small' typoSize='70%'>
										(1000 ms = 1 s)
									</Typography.Span>
								</InputGroup.Label>
							</Div>
						</InputGroup>
					)}
				</>
			)}
		</form>
	);
};
