"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ic_chevron_left = void 0;
var ic_chevron_left = {
  "viewBox": "0 0 24 24",
  "children": [{
    "name": "path",
    "attribs": {
      "d": "M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"
    }
  }]
};
exports.ic_chevron_left = ic_chevron_left;