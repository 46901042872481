import React from 'react';
import { Thumbnail } from '@icecreamsocial/components';
import authorizeNet from '../../../assets/logo_authorize-net.svg';

const AuthorizeNetLogo = ({ ...props }) => (
	<Thumbnail src={authorizeNet} {...props} />
);

AuthorizeNetLogo.displayName = 'AuthorizeNetLogo';

export default AuthorizeNetLogo;
