import React, { useEffect, useState, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useFormik } from "formik";
import {
  Link,
  useRouteMatch,
  useLocation,
  useParams,
  useHistory,
} from "react-router-dom";
import { Container, Div, Spanner, Typography } from "@icecreamsocial/components";
import { HeaderPortal } from "../../../Main";
import {
  ClientsFilterForm,
  ClientsTable,
  EmphasizedAction,
  Pagename,
  QuerySearchBar,
} from "../../../../presentational";
import { CompanyLogoLink } from "../../../../containers";

import { useStore, useQueryString } from "../../../../../utils/hooks";

const ClientsList = () => {
  const store = useStore();
  const history = useHistory();
  const { path } = useRouteMatch();
  const { query, addParam, withoutParam, contains, qs } = useQueryString();
  const { pathname, search: qParams } = useLocation();

  const [filters, setFilters] = useState({
    affiliateId: query.affiliateId,
    active: contains("active"),
    disabled: contains("disabled"),
    archived: contains("archived"),
  });

  useEffect(() => {
    let status = undefined;
    let isArchived = query.archived || false;
    // If status filter includes both 'active' and 'disabled' the API will return nothing
    if (!(query.active === "true" && query.disabled === "true")) {
      if (query.active === "true") {
        status = "active";
      }
      if (query.disabled === "true") {
        status = "disabled";
      }
    }
    store.getClientsFlow({
      ...query,
      status,
      isArchived,
    });
  }, [qParams]);

  /**
   * for the filters
   */
  const clearForm = () => {
    setFilters({
      affiliateId: undefined,
      active: false,
      disabled: false,
      archived: false,
    });
  };

  const filterBy = useMemo(
    () => ({
      affiliateId: query.affiliateId,
      status: query.status,
      isArchived: contains("archived") && !!query.archived,
    }),
    [qParams]
  );

  // map values from form to query
  const filterSubmit = ({ affiliateId, active, disabled, archived }) => {
    const q = { affiliateId };
    if (active) {
      q.active = active;
    }
    if (disabled) {
      q.disabled = disabled;
    }
    if (archived) {
      q.archived = archived;
    }
    if (contains("searchText")) {
      q.searchText = query.searchText;
    }
    // clean the object of params we don't want to include in the url
    if (q.archived === false) {
      delete q.archived;
    }
    const params = qs.stringify(q) ? `?${qs.stringify(q)}` : "";
    const url = `${pathname}${params}`;
    history.push(url);
  };

  return (
    <>
      <HeaderPortal>
        <Div backgroundColor="#EBEBEB" textAlign="center" padding="md">
          <Spanner grid gridColumns="repeat(3, 1fr)" padding="0">
            <Div display="flex" alignItems="center">
              <CompanyLogoLink />
            </Div>
            <Div display="flex" alignItems="center">
              <Div elWidth="100%" textAlign="center">
                <Typography.Span typoWeight="bold">
                  Ice Cream Social
                </Typography.Span>
              </Div>
            </Div>
          </Spanner>
        </Div>
      </HeaderPortal>
      <Div display="flex">
        <Div elWidth="75%">
          <Div
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Pagename>Clients</Pagename>
            <EmphasizedAction
              as={Link}
              to={`${path}/create`}
              textDecoration="none"
            >
              New Client
            </EmphasizedAction>
          </Div>
          <QuerySearchBar
            id="clientSearch"
            name="searchText"
            placeholder="Find Clients"
          />
          <ClientsTable
            collection={store.Collections.Clients.list.toJS()}
            progressPending={store.isLoading("clients")}
          />
        </Div>
        <Div as="aside" elWidth="25%" marginLeft="lg">
          <Div position="sticky" positionTop="0">
            <ClientsFilterForm
              affiliateId={store.userParentAffiliateId}
              onSubmit={filterSubmit}
              onReset={clearForm}
              initialValues={filters}
            />
          </Div>
        </Div>
      </Div>
    </>
  );
};

ClientsList.displayName = "Clients.List";

export default observer(ClientsList);
