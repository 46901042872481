import React, { useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import {
	Button,
	Div,
	InputGroup,
	Typography,
} from '@icecreamsocial/components';
import { useFormik } from 'formik';
import { Checkbox, Radio, RadioGroup, Switch } from '@material-ui/core';
import {
	CalendarPicker,
	MinMaxToggle,
} from '../../../../../../../../../../presentational';
import { useRewardsFilters } from '../FilterProvider';
import { useQueryString } from '../../../../../../../../../../../utils/hooks';

/**
 * @module RewardsFilterForm
 * @category Components
 * @subcategory Containers
 */
/**
 * @type {React.FC<{}>}
 */
const FilterForm = () => {
	/**
	 * @type {RewardsFilters.Context<RewardsFilterForm.Props>}
	 */
	const { state, update, clear } = useRewardsFilters();
	const { contains } = useQueryString();

	const clearControls = () => {
		const exceptions = ['search', 'page', 'limit', 'skip'];
		clear(...exceptions);
	};

	const mapStateToForm = (values) => {
		const {
			page,
			skip,
			limit,
			rewardDateGTOE,
			rewardDateLTOE,
			...rest
		} = values;

		const rewardAmountSwitch =
			contains('rewardAmountGTOE') || contains('rewardAmountLTOE');

		return {
			fromDate: rewardDateGTOE,
			toDate: rewardDateLTOE,
			rewardAmountSwitch,
			...rest,
		};
	};

	/**
	 * for the filters
	 */
	const filtersForm = useFormik({
		enableReinitialize: true,
		initialValues: mapStateToForm(state),
		onSubmit: update,
	});

	return (
		<form onSubmit={filtersForm.handleSubmit}>
			<Div display='flex' alignItems='center' justifyContent='space-between'>
				<Typography.H2 typoWeight='500'>Filters</Typography.H2>
				<Button
					type='button'
					onClick={clearControls}
					typoSize='small'
					textDecoration='underline'
					border='none'
					shadow='none'
					hoverShadow='none'
					padding='sm'
					backgroundColor='transparent'
				>
					Reset filters
				</Button>
			</Div>
			<Div as='fieldset' border='none' elWidth='100%'>
				<CalendarPicker
					label='Date Created'
					fromDate={filtersForm.values.fromDate}
					onFromDateChange={filtersForm.handleChange}
					toDate={filtersForm.values.toDate}
					onToDateChange={filtersForm.handleChange}
				/>
			</Div>
			<Div as='fieldset' border='none' elWidth='100%'>
				<Typography.Span
					display='block'
					typoWeight='500'
					typoSize='.9em'
					marginBottom='md'
				>
					Metrics
				</Typography.Span>
				<MinMaxToggle
					name='rewardAmount'
					label='Value'
					show={filtersForm.values.rewardAmountSwitch}
					minValue={filtersForm.values.rewardAmountGTOE}
					maxValue={filtersForm.values.rewardAmountLTOE}
					handleChange={filtersForm.handleChange}
				/>
			</Div>

			<Button
				type='submit'
				disable={!filtersForm.isValid}
				elWidth='100%'
				textColor='bodyInverse'
				backgroundColor='brand'
				padding='sm'
			>
				Apply Filters
			</Button>
		</form>
	);
};

FilterForm.displayName = 'RewardsFilterForm';
export default observer(FilterForm);
