import React, { Component } from 'react';

class ErrorBoundary extends Component {
	componentDidCatch(error, errorInfo) {
		// You can also log the error to an error reporting service
		console.warn(error, errorInfo);
	}
	render() {
		return this.props.children;
	}
}

export default ErrorBoundary;
