import React from 'react';
import { Panel } from '@icecreamsocial/components';
import BusinessSetup from '../BusinessSetup';

const BusinessSetupPanel = ({ ...rest }) => {
	return (
		<Panel backgroundColor="accentLight" elWidth="100%" {...rest}>
			<BusinessSetup as={Panel.Body} textColor="body" />
		</Panel>
	);
};

BusinessSetupPanel.displayName = 'BusinessSetupPanel';

export default BusinessSetupPanel;
