import React from 'react';
import { observer } from 'mobx-react-lite';
import { Div, Typography, Panel, List } from '@icecreamsocial/components';
import {
	BusinessPoliciesForm,
	Pagename,
	SteppedFormControls,
} from '../../../../presentational';
import { useStore } from '../../../../../utils/hooks';

/**
 * @todo pass initial values as an object instead of spreading it
 */
const BusinessPolicies = ({ handleSubmit, returnTo }) => {
	const store = useStore();
	const initialValues = {
		termsAndConditionsURL: store.userBusiness?.termsAndConditionsURL,
		privacyPolicyURL: store.userBusiness?.privacyPolicyURL,
		disclaimerAcknowledgement: !!(
			store.userBusiness?.termsAndConditionsURL &&
			store.userBusiness?.privacyPolicyURL
		),
	};

	return (
		<Div display='flex'>
			<Div elWidth='75%'>
				<Pagename>Data &amp; Policy</Pagename>
				<BusinessPoliciesForm
					onSubmit={handleSubmit}
					allowRouteBlock={false}
					renderControls={() => <SteppedFormControls onNav={returnTo} />}
					{...initialValues}
				/>
			</Div>
			<Div flexGrow='1' marginLeft='md'>
				<Panel backgroundColor='ambient' marginTop='lg'>
					<Panel.Header
						backgroundColor='transparent'
						textColor='body'
						paddingBottom='sm'
					>
						<Typography.H3 typoWeight='400' margin='none'>
							Related Articles:
						</Typography.H3>
					</Panel.Header>
					<Panel.Body padding='sm'>
						<List>
							<List.Item>
								<Typography.A
									href='https://support.icecreamsocial.io/en/support/solutions/articles/66000509929-ics-gdpr-support'
									target='_blank'
								>
									GDPR Support
								</Typography.A>
							</List.Item>
							<List.Item>
								<Typography.A
									href='https://support.icecreamsocial.io/support/solutions/articles/66000509930-collecting-influencer-consent'
									target='_blank'
								>
									Collecting influencer consent
								</Typography.A>
							</List.Item>
							<List.Item>
								<Typography.A
									href='https://support.icecreamsocial.io/support/solutions/articles/66000509999-responding-to-influencer-data-requests'
									target='_blank'
								>
									Responding to Data Requests
								</Typography.A>
							</List.Item>
						</List>
					</Panel.Body>
				</Panel>
			</Div>
		</Div>
	);
};
BusinessPolicies.displayName = 'BusinessSetUp.Policies';

export default observer(BusinessPolicies);
