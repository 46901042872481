import React from 'react';
import { observer } from 'mobx-react-lite';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Container } from '@icecreamsocial/components';
import { useRouteConfig } from '../../../utils/hooks';
import UsersList from './sub-routes/List';
import User from './sub-routes/User';
import CreateUser from './sub-routes/Create';
import { PageMeta } from '../../presentational';

const Users = () => {
	const { path } = useRouteMatch();
	const CreateUserComponent = () => <CreateUser nextPage={path} />;
	const UpdateUserComponent = () => <User nextPage={path} />;

	const { routes } = useRouteConfig([
		{
			component: UsersList,
		},
		{
			path: `create`,
			component: CreateUserComponent,
			exact: false,
		},
		{
			path: `:userId`,
			component: UpdateUserComponent,
			exact: true,
		},
	]);

	return (
		<>
			<PageMeta
				title={'Users | Ice Cream Social'}
				description={'Manage Users'}
			/>
			<Container>
				<Switch>
					{routes.map((route, i) => (
						<Route key={i} {...route} />
					))}
				</Switch>
			</Container>
		</>
	);
};
Users.displayName = 'Users';
export default observer(Users);
