import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import {
	Div,
	Panel,
	Typography,
	Img,
	Button,
	Container,
} from '@icecreamsocial/components';
import { OptimisticChart } from '../../../../optimistic';
import { useDashboardFilters } from '../FilterProvider';
import icsMelted from '../../../../../assets/ics-melting.png';

export const Chart = ({
	isLoading = true,
	color,
	name,
	data,
	yLabel,
	yFormat,
	...rest
}) => {
	const { clear, update } = useDashboardFilters();

	const bottomAxis = {
		tickSize: 4,
		tickPadding: 8,
		legend: 'Date',
		legendOffset: 32,
		legendPosition: 'middle',
		truncateTickAt: 4,
	};

	const hasData = data.some((d) => d.y > 0);

	if (!isLoading && !hasData)
		return (
			<Panel {...rest}>
				<Panel.Body grid alignItems='center' elHeight='32rem'>
					<Container textAlign='center'>
						<Img
							src={icsMelted}
							display='block'
							elMaxWidth='160px'
							flexShrink='0'
							margin='-32px auto 16px'
						/>
						<Typography.Span typoWeight='600'>No data found!</Typography.Span>
						<Typography.P>
							We didn't find any data for the date range or filters selected.
							Try reseting them or using broader data range.
						</Typography.P>
						<Button onClick={() => update({})}>Reset Filters</Button>
					</Container>
				</Panel.Body>
			</Panel>
		);

	return (
		<Panel {...rest}>
			<Panel.Body display='flex' alignItems='center' elHeight='32rem'>
				{!isLoading ? (
					<ResponsiveLine
						data={[{ id: name, data }]}
						colors={color}
						axisBottom={null}
						curve='monotoneX'
						axisLeft={{
							legend: yLabel,
							legendOffset: -44,
							legendPosition: 'middle',
						}}
						margin={{ top: 24, right: 16, bottom: 24, left: 52 }}
						enableGridX={false}
						enableGridY={true}
						enableCrosshair={true}
						enableSlices='x'
						lineWidth={2}
						enablePoints={false}
						pointBorderColor={color}
						pointBorderWidth={1}
						pointSize={4}
						xScale={{ type: 'point' }}
						yScale={{
							type: 'linear',
							stacked: true,
							min: 0,
							max: 'auto',
						}}
						yFormat={yFormat}
						sliceTooltip={({ slice }) => (
							<>
								{slice.points &&
									slice.points.map((point, i) => (
										<Div
											key={i}
											shadow='raised'
											padding='sm'
											textAlign='center'
											backgroundColor='body'
											borderRadius='3px'
											textColor='bodyInverse'
										>
											<Typography.Span typoSize='lg' display='block'>
												{point.data.yFormatted}
											</Typography.Span>
											<Typography.Span typoWeight='light' display='block'>
												{point.data.xFormatted}
											</Typography.Span>
										</Div>
									))}
							</>
						)}
						// style={{
						// 	width: '100%',
						// }}
					/>
				) : (
					<OptimisticChart marginLeft='md' />
				)}
			</Panel.Body>
		</Panel>
	);
};
