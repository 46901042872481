import React from 'react';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { useStore } from '../../../../../utils/hooks';
import { BusinessSetupPanel } from '../../../../presentational';

const Redirector = () => {
	const store = useStore();
	const { path } = useRouteMatch();

	return !store.isClient ? (
		<Redirect to={`${path}/account`} />
	) : !store.needsBusinessSetup ? (
		<Redirect to={`${path}/business-profile`} />
	) : (
		<BusinessSetupPanel />
	);
};
Redirector.displayName = 'Settings.Redirector';

export default observer(Redirector);
