import React from 'react';
import { observer } from 'mobx-react-lite';
import { Div } from '@icecreamsocial/components';

import { InfluencerFiltersProvider } from './components/FilterProvider';
import FilterForm from './components/FilterForm';
import DataView from './components/DataView';

const InfluencerPerformance = () => {
	return (
		<InfluencerFiltersProvider>
			<Div display='flex'>
				<Div elWidth='75%' flexShrink='1' flexGrow='0'>
					<DataView />
				</Div>
				<Div
					as='aside'
					elWidth='25%'
					marginLeft='lg'
					marginBottom='lg'
					flexShrink='1'
				>
					<Div position='sticky' positionTop='0'>
						<FilterForm />
					</Div>
				</Div>
			</Div>
		</InfluencerFiltersProvider>
	);
};

InfluencerPerformance.displayName =
	'Campaigns.Campaign.Reports.InfluencerPerformance';
export default observer(InfluencerPerformance);
