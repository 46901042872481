import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import {
	Link,
	Route,
	Redirect,
	Switch,
	useParams,
	useRouteMatch,
} from 'react-router-dom';
import { Button, Container, Div, List } from '@icecreamsocial/components';
import { Tooltip, withStyles } from '@material-ui/core';
import { useRouteConfig } from '../../../../../../../utils/hooks';
import Referrals from './sub-routes/Referrals';
import InfluencerPerformance from './sub-routes/InfluencerPerformance';
import NewInvites from './sub-routes/Invites';
import Rewards from './sub-routes/Rewards';
import { flags } from '../../../../../../../utils';

const DISABLE_INVITE_REPORT = flags.isActive(flags.names.DISABLE_INVITE_REPORT);

const Reports = () => {
	const routeConfigList = [
		{
			path: `influencers`,
			component: InfluencerPerformance,
			linkText: 'influencers',
		},
		{
			path: `referrals`,
			component: Referrals,
			linkText: 'referrals',
		},
		{
			path: `invites`,
			component: NewInvites,
			linkText: 'invites',
		},
		{
			path: `rewards`,
			component: Rewards,
			linkText: 'rewards',
		},
	];

	if (DISABLE_INVITE_REPORT) {
		routeConfigList.splice(2, 1);
	}

	const { path } = useRouteMatch();
	const { routes, links } = useRouteConfig(routeConfigList);

	// const permissableRoutes = useMemo(() => routes, []);
	// const permissableLinks = useMemo(() => links, []);
	const routeTooltips = {
		influencers:
			'Details lifetime stats of influencers to gauge the effectiveness of their participation in your campaign',
		referrals: 'Details referrals and revenue generated through referrals',
		invites:
			'Details verified information from invitees added through influencer invites',
		rewards: 'Rewards issued to influencers',
	};

	const ReportToolTip = withStyles(() => ({
		arrow: {
			color: '#222',
		},
		tooltip: {
			lineHeight: '1.6em',
			backgroundColor: '#222',
			color: '#fff',
			fontWeight: '500',
			fontSize: '0.8rem',
			wordWrap: 'break-word',
			borderRadius: '16px',
			textAligin: 'center',
		},
	}))(Tooltip);

	return (
		<Container>
			<Div elMaxWidth={['66%', '40%']} marginTop='md'>
				<List
					bullets='none'
					display='flex'
					justifyContent='space-between'
					padding='md'
					align='left'
				>
					{links.map(({ active, text, to }, i) => (
						<List.Item
							key={i}
							flexGrow='1'
							textAlign='center'
							marginBottom='0'
							marginRight='md'
						>
							<ReportToolTip title={routeTooltips[text]} placement='top' arrow>
								<Button
									as={Link}
									to={to}
									border={active ? '1px solid' : 'none'}
									borderColor={active ? '#BBBBBB' : 'transparent'}
									backgroundColor={active ? '' : '#DDDDDD'}
									textTransform='capitalize'
									textColor='default'
									textDecoration={active ? 'none' : 'underline'}
									elWidth='100%'
								>
									{text}
								</Button>
							</ReportToolTip>
						</List.Item>
					))}
				</List>
			</Div>
			<Switch>
				{routes.map(({ link, ...route }, i) => (
					<Route key={i} {...route} />
				))}
				<Redirect to={`${path}/influencers`} />
			</Switch>
		</Container>
	);
};

Reports.displayName = 'Campaigns.Campaign.Reports';

export default observer(Reports);
