import React from 'react';
import { observer } from 'mobx-react-lite';
import { Div, Typography, List } from '@icecreamsocial/components';
import { EmailIcon, PhoneIcon } from '../../../presentational';
import { Link } from 'react-router-dom';

const ProfileSummary = ({
  email = 'None provided',
  phone = 'None provided',
  editLink,
}) => {
  return (
    <Div borderBottom='1px solid' borderColor='ambientLight' marginBottom='md'>
      <Div display='flex' justifyContent='space-between' alignItems='center'>
        <Typography.P textColor='ambientDark'>Profile</Typography.P>
        <Typography.A as={Link} to={editLink} typoWeight='600'>
          Edit
        </Typography.A>
      </Div>
      <List bullets='none'>
        <List.Item>
          <Div display='flex' alignItems='center'>
            <EmailIcon size={18} marginRight='md' textColor='ambientDark' />
            <Typography.Span
              overflow='hidden'
              style={{ whiteSpace: 'no-wrap', textOverflow: 'ellipsis' }}
            >
              {email}
            </Typography.Span>
          </Div>
        </List.Item>
        <List.Item>
          <Div display='flex' alignItems='center'>
            <PhoneIcon size={18} marginRight='md' textColor='ambientDark' />
            <Typography.Span
              overflow='hidden'
              style={{ whiteSpace: 'no-wrap', textOverflow: 'ellipsis' }}
            >
              {phone}
            </Typography.Span>
          </Div>
        </List.Item>
      </List>
    </Div>
  );
};
ProfileSummary.displayName = 'InfluencerManager.ProfileSummary';

export default observer(ProfileSummary);
