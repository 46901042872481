"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ic_arrow_back = void 0;
var ic_arrow_back = {
  "viewBox": "0 0 24 24",
  "children": [{
    "name": "path",
    "attribs": {
      "d": "M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"
    }
  }]
};
exports.ic_arrow_back = ic_arrow_back;