import React, { useState } from 'react';
import { Link, Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { Div, Typography } from '@icecreamsocial/components';
import { useStore } from '../../../../../utils/hooks';
import {
	BusinessProfileForm,
	EditFormControls,
	Pagename,
} from '../../../../presentational';

const BusinessSettings = () => {
	const store = useStore();
	const initialValues = {
		businessName: store.userBusiness?.name,
		businessAddress: store.userBusiness?.businessAddress,
		supportEmail: store.userBusiness?.supportEmail,
		currency: store.userBusiness?.currency,
		language: store.userBusiness?.language,
	};

	const handleSubmit = (values) => store.updateCompanyFlow(values);

	return (
		<Div>
			<Pagename>Business Profile</Pagename>
			<BusinessProfileForm
				{...initialValues}
				onSubmit={handleSubmit}
				renderControls={(props) => (
					<EditFormControls
						isLoading={store.isLoading('businessSettings')}
						disabled={store.isLoading('businessSettings')}
						{...props}
					/>
				)}
			/>
		</Div>
	);
};

BusinessSettings.displayName = 'Settings.Business.Profile';
export default observer(BusinessSettings);
