import React, { useState, useEffect, createContext } from 'react';
import { observer } from 'mobx-react';
import { Container, Div, Typography } from '@icecreamsocial/components';

import { useStore } from '../../../../../../../utils/hooks';
import { ScriptCustomizer } from './components/ScriptCustomizer';
import { ScriptPreview } from './components/ScriptPreview';
import { ScriptSettings } from './providers';
import { useCampaign } from '../../providers/Campaign';

const Integrations = () => {
	const store = useStore();
	const { campaign } = useCampaign();
	const { data } = campaign;
	const { business } = store.Orchestrators.Auth;
	const initialValues = {
		version: 'latest',
		locale: data?.locale,
		platform: business?.eCommerceSolution,
	};

	return (
		<ScriptSettings initialValues={initialValues}>
			<Div grid gridColumns='2fr minmax(25%, 1fr)' elHeight='100%'>
				<Container
					elWidth={['100%', '75%']}
					display='flex'
					flexDirection='column'
					elHeight='100%'
				>
					<Typography.H1 marginBottom='none' typoWeight='500'>
						Integration Script
					</Typography.H1>
					<Typography.P>
						The ICS integration script is your gateway to installing ICS widgets
						on your site. This script takes in a host of options and settings
						that can be used to customize various aspects of the widget to suit
						your campaign and use case.
					</Typography.P>
					<ScriptPreview />
				</Container>
				<Div
					backgroundColor='bodyInverse'
					elMinHeight='100%'
					position='sticky'
					positionTop='0'
					marginBottom='-24px'
				>
					<ScriptCustomizer />
				</Div>
			</Div>
		</ScriptSettings>
	);
};

Integrations.displayName = 'Campaigns.Campaign.Integrations';

export default observer(Integrations);
