import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import {
	Link,
	Switch,
	Route,
	useHistory,
	useRouteMatch,
	useLocation,
	useParams,
	Redirect,
} from 'react-router-dom';
import {
	Button,
	Div,
	List,
	Spanner,
	Tag,
	Typography,
} from '@icecreamsocial/components';
import { HeaderPortal } from '../../../Main';
import { useStore } from '../../../../../utils/hooks';
import { ArrowBackIcon } from '../../../../presentational';
import Campaigns from '../Campaigns';
import Campaign from '../../../Campaigns/sub-routes/Campaign';
import Users from '../Users';
import Settings from '../Settings';

const Client = () => {
	const store = useStore();
	const history = useHistory();
	const { path, url } = useRouteMatch();
	const { clientId } = useParams();

	useEffect(() => {
		store.resolveClientFlow(clientId);

		return () => store.resolveClientFlowCleanUp();
	}, [clientId]);

	const routes = useMemo(
		() => [
			{
				path: `${path}`,
				component: () => <Redirect to={`${url}/campaigns`} />,
				exact: true,
			},
			{
				path: `${path}/campaigns/view/:campaignId`,
				component: () => <Campaign hideHeader />,
				exact: false,
			},
			{
				path: `${path}/campaigns`,
				component: Campaigns,
				exact: true,
				link: {
					text: 'campaigns',
					to: `${url}/campaigns`,
					active: useRouteMatch(`${path}/campaigns`),
				},
			},
			{
				path: `${path}/users`,
				component: Users,
				exact: true,
				link: {
					text: 'users',
					to: `${url}/users`,
					active: useRouteMatch(`${path}/users`),
				},
			},
			{
				path: `${path}/settings`,
				component: Settings,
				exact: false,
				link: {
					text: 'settings',
					to: `${url}/settings`,
					active: useRouteMatch(`${path}/settings`),
				},
			},
		],
		[path, url]
	);

	const focusedClient = store.Orchestrators.Focused.client || {};

	return (
		<>
			<HeaderPortal>
				<Div backgroundColor='#EBEBEB' textAlign='center' padding='md'>
					<Spanner grid gridColumns='repeat(3, 1fr)' padding='0'>
						<Div display='flex' alignItems='center'>
							<Div elWidth='100%' textAlign='center'>
								<Button
									alignItems='center'
									backgroundColor='transparent'
									display='flex'
									hoverShadow='none'
									margin='0'
									onClick={() => history.push('/clients')}
									padding='0'
									shadow='none'
								>
									<ArrowBackIcon marginRight='md' /> Clients
								</Button>
							</Div>
						</Div>
						<Div display='flex' flexDirection='column' alignItems='center'>
							{focusedClient && (
								<>
									<Div elWidth='100%' textAlign='center'>
										<Typography.Span>Client</Typography.Span>
										<Typography.Span
											marginLeft='sm'
											textTransform='capitalize'
											typoWeight='bold'
										>
											{focusedClient.name}
										</Typography.Span>
									</Div>
									<Div
										elWidth='100%'
										textAlign='center'
										marginTop='sm'
										textColor='grey'
									>
										<Typography.Span as='small' textTransform='capitalize'>
											Client ID &#x23;{focusedClient.id}
										</Typography.Span>
									</Div>
								</>
							)}
						</Div>
						<Div elWidth='100%' textAlign='right'>
							{focusedClient && (
								<Tag
									textTransform='capitalize'
									textColor={
										focusedClient.status === 'active' ? 'good' : 'accent'
									}
									borderColor={
										focusedClient.status === 'active' ? 'good' : 'accent'
									}
								>
									{focusedClient.status}
								</Tag>
							)}
						</Div>
					</Spanner>
				</Div>
				<Div backgroundColor='#EBEBEB'>
					<Div elMaxWidth={['100%', '66%']} margin='auto'>
						<List
							bullets='none'
							display='flex'
							justifyContent='space-between'
							padding='md'
						>
							{routes.slice(2).map(({ link }, i) => (
								<List.Item
									key={i}
									flexGrow='1'
									textAlign='center'
									borderBottom='3px solid'
									borderColor={link.active ? 'brand' : 'transparent'}
									marginBottom='0'
								>
									<Typography.Span
										as={Link}
										to={link.to}
										display='block'
										elWidth='100%'
										paddingTop='md'
										paddingBottom='md'
										textTransform='capitalize'
										textDecoration='none'
										textColor='default'
									>
										{link.text}
									</Typography.Span>
								</List.Item>
							))}
						</List>
					</Div>
				</Div>
			</HeaderPortal>
			<Switch>
				{routes.map(({ link, ...route }, i) => (
					<Route key={i} {...route} />
				))}
				<Redirect to={routes[0].path} />
			</Switch>
		</>
	);
};

Client.displayName = 'Clients.Client';

export default observer(Client);
