"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ic_arrow_drop_up = void 0;
var ic_arrow_drop_up = {
  "viewBox": "0 0 24 24",
  "children": [{
    "name": "path",
    "attribs": {
      "d": "M7 14l5-5 5 5z"
    }
  }]
};
exports.ic_arrow_drop_up = ic_arrow_drop_up;