import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
	Button,
	Div,
	InputGroup,
	Typography,
} from '@icecreamsocial/components';

import { Switch } from '@material-ui/core';

const SwitchField = ({ show = false, children, name, label, handleToggle }) => {
	// const [showInput, setShowInput] = useState(show);
	// const onToggle = () => {
	// 	setShowInput(!showInput);
	// 	// passing the opposite so that the callback gets the correct value it's being changed to
	// 	handleToggle(!showInput);
	// };
	return (
		<>
			<InputGroup display='flex' alignItems='center'>
				<Switch
					name={`${name}Switch`}
					color='primary'
					value={show}
					checked={show}
					onChange={handleToggle}
				/>{' '}
				<InputGroup.Label display='inline-block'>{label}</InputGroup.Label>
			</InputGroup>
			{!!show && children}
		</>
	);
};

SwitchField.displayName = 'SwitchField';
export default observer(SwitchField);
