import React from "react";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router";
import {
  Button,
  Div,
  Panel,
  Typography,
  Img,
} from "@icecreamsocial/components";
import rewardImage from "../../../../assets/scoops-reward.png";

const InfluencerEligibleState = ({ userRole, rewardLink }) => {
  const history = useHistory();
  const onIssueRewards = () => history.push(rewardLink);

  return (
    <Panel
      flexDirection="row"
      alignItems="center"
      backgroundColor=""
      backgroundGradientColor={`-225deg, #4568dc 0%, #321575 100%`}
      marginBottom="lg"
    >
      <Panel.Body
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        paddingTop="sm"
        paddingBottom="sm"
      >
        <Div flexShrink="0">
          <Typography.P
            textColor="bodyInverse"
            marginTop="sm"
            typoSize="1.1rem"
          >
            Influencer is eligible for rewards
          </Typography.P>
          <Button
            disabled={!(userRole === "client" || userRole === "superuser")}
            backgroundColor="transparent"
            borderColor="bodyInverse"
            border="2px solid"
            textColor="bodyInverse"
            shadow="none"
            onClick={onIssueRewards}
          >
            Issue Rewards
          </Button>
        </Div>
        <Div flexShrink="1">
          <Img src={rewardImage} elHeight="120px" />
        </Div>
      </Panel.Body>
    </Panel>
  );
};
InfluencerEligibleState.displayName = "InfluencerEligibleState";

export default observer(InfluencerEligibleState);
