import { types } from 'mobx-state-tree';

const Pagination = types
	.model({
		skip: 0,
		limit: 10,
		total: 0,
	})
	.actions((self) => ({
		setSkip(skip = 0) {
			return (self.skip = skip);
		},
		setLimit(limit = 1) {
			return (self.limit = limit);
		},
		setTotal(total = 0) {
			return (self.total = total);
		},
		setSkipByPage(page = 1) {
			return (self.skip = (page - 1) * self.limit);
		},
	}));

export default Pagination;
