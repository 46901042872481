import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Div, Panel, Typography, Tag } from '@icecreamsocial/components';
import { Checkbox } from '@material-ui/core';
import DataTable from 'react-data-table-component';

const ConversionsTable = ({
	collection = [],
	onChecked,
	...rest
}) => {
	let columns = [
		{
			name: 'Invitee',
			selector: (row) => row.invitedInfluencerEmail,
			cell: (row) => (
				<Div
					padding="sm"
					paddingLeft="none"
				>
					<Typography.Span
						typoWeight="bold"
						textTransform="capitalize"
						display="block"
						textDecoration="none"
						marginBottom="sm"
					>
						{row.invitedInfluencerName || '-'}
					</Typography.Span>
					{row.invitedInfluencerEmail && (
						<Typography.Span
							display="block"
							textDecoration="none"
						>
							{row.invitedInfluencerEmail}
						</Typography.Span>
					)}
				</Div>
			),
			sortable: true,
			grow: 3,
			width: '250px',
		},
		{
			name: 'Order ID',
			selector: (row) => row.identifier,
			cell: (row) => (
				<Typography.Span>
					{row.identifier}
				</Typography.Span>
			),
			sortable: true,
			grow: 1,
		},
		{
			name: 'Order Total',
			selector: (row) => row.revenue,
			cell: (row) => (
				<Typography.Span>
					{row.revenue}
				</Typography.Span>
			),
			sortable: true,
			grow: 2,
		},
		{
			name: 'Date Created',
			selector: (row) => row.createdAt,
			cell: (row) => (
				<Typography.Span>
					{row.createdAt.lightFormat()}
				</Typography.Span>
			),
			sortable: true,
			grow: 2,
			width: '150px',
		},
	];

	const selectableRowsProps = typeof onChecked === "function" ? {
		selectableRows: true,
		selectableRowsComponent: Checkbox,
		onSelectedRowsChange: onChecked,
		// selectableRowsComponentProps: {}
	} : null;

	return (
		<Panel style={{ overflow: 'hidden' }}>
			<DataTable
				columns={columns}
				data={collection}
				highlightOnHover
				pagination
				noHeader
				{...selectableRowsProps}
				{...rest}
			/>
		</Panel>
	);
};

ConversionsTable.displayName = 'ConversionsTable';

export default observer(ConversionsTable);