import React, { createContext, useCallback, useContext, useState } from 'react';
import { createPortal } from 'react-dom';
import { observer } from 'mobx-react-lite';
import { Div } from '@icecreamsocial/components';
import MainPane from '../MainPane';

// Create a context for the portal ref so that we can retrieve it throughout the app
const EditPaneContext = createContext({ mountNode: null });
EditPaneContext.displayName = 'EditPaneContext';

const controlsPortalElId = 'influencer-manager-controls-portal';

const useEditPanePortal = () => {
	return (children) => {
		const { mountNode } = useContext(EditPaneContext);
		return mountNode ? createPortal(children, mountNode) : null;
	};
};
const EditPanePortal = ({ children }) => {
	const renderPortal = useEditPanePortal();
	return renderPortal(children);
};

const EditPane = ({ children, ...rest }) => {
	const [mountNode, setMountNode] = useState();
	const onRefChange = useCallback((node) => {
		if (node) {
			setMountNode(node);
		}
	}, []);

	return (
		<EditPaneContext.Provider value={{ mountNode }}>
			<MainPane {...rest}>{children}</MainPane>
			<Div
				id={controlsPortalElId}
				ref={onRefChange}
				position='sticky'
				positionBottom='0'
			/>
		</EditPaneContext.Provider>
	);
};
EditPane.displayName = 'InfluencerManager.EditPane';

EditPane.Controls = EditPanePortal;
EditPane.Controls.displayName = 'EditPane.Controls';

export default observer(EditPane);
