import React from 'react';
import { Panel, Typography, Div } from '@icecreamsocial/components';
import { SquareLogo } from '../../../../../presentational';

const SquareDescription = () => {
	return (
		<Panel marginBottom="md" backgroundColor="bodyInverse">
			<Panel.Body display="flex" alignItems="center" style={{ gap: '16px' }}>
				<SquareLogo />
				<Div flex="1 0">
					<Typography.H4
						display="block"
						typoSize="lg"
						typoWeight="400"
						marginTop="0"
						marginBottom="md"
					>
						Square
					</Typography.H4>
					<Typography.Span display="block">
						Reward influencers with a Square gift card
					</Typography.Span>
				</Div>
			</Panel.Body>
		</Panel>
	);
};

SquareDescription.displayName = 'SquareDescription';

export default SquareDescription;
