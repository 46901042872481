import React from 'react';
import { observer } from 'mobx-react-lite';
import { Div } from '@icecreamsocial/components';
import { ReferralFiltersProvider } from './components/FilterProvider';
import FilterForm from './components/FilterForm';
import DataView from './components/DataView';

const Referrals = () => {
	return (
		<ReferralFiltersProvider>
			<Div display='flex'>
				<Div elWidth='75%' flexShring='1' flexGrow='0'>
					<DataView />
				</Div>
				<Div as='aside' elWidth='25%' marginLeft='lg' marginBottom='lg'>
					<Div position='sticky' positionTop='0'>
						<FilterForm />
					</Div>
				</Div>
			</Div>
		</ReferralFiltersProvider>
	);
};

Referrals.displayName = 'Campaigns.Campaign.Reports.Referrals';
export default observer(Referrals);
