import React from 'react';
import { observer } from 'mobx-react-lite';
import { Div, InputGroup, Panel, Typography } from '@icecreamsocial/components';
import {
	Checkbox,
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup,
} from '@material-ui/core';
import { EnhancedForm, RequiredAsterisk } from '../';
import { AffiliateSelector } from '../../containers';
import { flags } from '../../../utils';

const ClientSettings = ({
	onSubmit = () => undefined,
	allowRouteBlock = true,
	renderControls = null,
	editMode,
	id,
	name,
	affiliateId,
	status,
	isArchived,
	allowSMS,
	cannotActivate,
}) => {
	const formikProps = {
		enableReinitialize: true,
		initialValues: {
			id,
			name,
			allowSMS,
			affiliateId: affiliateId,
			status: status || 'active',
			isArchived:
				isArchived || typeof isArchived === 'boolean' ? isArchived : 'false',
		},
		onSubmit,
	};

	return (
		<>
			<EnhancedForm
				formikProps={formikProps}
				promptIf={({ dirty }) => allowRouteBlock && dirty}
				renderControls={renderControls}
			>
				{(clientSettings) => (
					<>
						<Panel backgroundColor='bodyInverse' marginBottom='lg'>
							<Panel.Body>
								<InputGroup elWidth='100%' marginBottom='sm'>
									<InputGroup.Label>
										Name <RequiredAsterisk />
									</InputGroup.Label>
									<InputGroup.Fields>
										<InputGroup.Input
											id='name'
											name='name'
											value={clientSettings.values.name}
											onChange={clientSettings.handleChange}
											paddingLeft='md'
											autofocus
											required
										/>
									</InputGroup.Fields>
								</InputGroup>

								<InputGroup elWidth='100%' marginBottom='sm'>
									<InputGroup.Label>
										Parent Affiliate <RequiredAsterisk />
									</InputGroup.Label>
									<AffiliateSelector
										as='select'
										id='affiliateId'
										name='affiliateId'
										affiliateId={affiliateId}
										value={clientSettings.values.affiliateId}
										onChange={clientSettings.handleChange}
										disabled={editMode}
										paddingLeft='md'
										required
									/>
								</InputGroup>

								{/* <Div marginTop='sm' marginBottom='sm'>
									<Div paddingTop='sm' paddingBottom='sm'>
										<FormLabel>Client Status</FormLabel>
									</Div>
									<RadioGroup
										name='status'
										value={`${clientSettings.values.status}`}
										onChange={clientSettings.handleChange}
										aria-label='status'
									>
										<FormControlLabel
											value='active'
											control={
												<Radio id='status' name='status' color='primary' />
											}
											label='Active'
											disabled={cannotActivate}
										/>
										<FormControlLabel
											value='disabled'
											control={<Radio id='status' name='status' />}
											label='Disabled'
											disabled={cannotActivate}
										/>
									</RadioGroup>
								</Div> */}

								{flags.isActive(
									flags.names.ENABLE_EXPERIMENTAL_CAMPAIGN_SMS_SETTINGS
								) && (
									<>
										<Div marginTop='sm' marginBottom='sm'>
											<Typography.H4 typoWeight='500'>SMS</Typography.H4>
											<Typography.P>
												Enabling this will allow clients to send SMS
												notifications to influencers. Once a client’s Business
												Settings are completed, they'll be assigned a phone
												number.
											</Typography.P>
											<Typography.P>
												Note: there are content restrictions for marketing SMS
												messages. Before activating, please refer to this
												article to confirm their content is appropriate:{' '}
												<Typography.A
													href='https://support.twilio.com/hc/en-us/articles/360045004974-Forbidden-Message-Categories-for-SMS-and-MMS-in-the-US-and-Canada'
													target='_blank'
												>
													Forbiden Message Categories
												</Typography.A>
											</Typography.P>
										</Div>

										<InputGroup>
											<InputGroup.Fields
												display='flex'
												alignItems='center'
												paddingRight='md'
												style={{ gap: '8px' }}
											>
												<Checkbox
													id='allowSMS'
													name='allowSMS'
													color='primary'
													checked={clientSettings.values.allowSMS === true}
													value={clientSettings.values.allowSMS}
													defaultValue={clientSettings.values.allowSMS}
													onChange={clientSettings.handleChange}
												/>
												<InputGroup.Label>Allow SMS</InputGroup.Label>
											</InputGroup.Fields>
										</InputGroup>
									</>
								)}
							</Panel.Body>
						</Panel>
					</>
				)}
			</EnhancedForm>
		</>
	);
};
ClientSettings.displayName = 'ClientSettingsForm';

export default observer(ClientSettings);
