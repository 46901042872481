import React from 'react';
import { observer } from 'mobx-react-lite';
import { Spanner } from '@icecreamsocial/components';

const DynamicBar = ({ show = true, ...props }) => {
	return (
		show && (
			<Spanner
				position="fixed"
				positionBottom="0"
				positionLeft="0"
				backgroundColor="bodyInverse"
				borderColor="ambient"
				borderTop="1px solid"
				elWidth="100%"
				textAlign="right"
				{...props}
			/>
		)
	);
};
DynamicBar.displayName = 'DynamicBar';

export default observer(DynamicBar);
