import { types, SnapshotIn } from 'mobx-state-tree';
import { RewardProcessor } from '../../models';

/**
 * @module RewardProcessorCollection
 * @category Shared
 * @subcategory Models
 * @description
 * Contains instances of a RewardProcessor model
 */

const __RewardProcessorCollection = types
	.model({
		Map: types.map(RewardProcessor),
	})
	.named('RewardProcessorCollection')
	.views((self) => ({
		has(id) {
			return self.Map.has(id);
		},
		get(id) {
			return self.Map.get(id);
		},
		getType(id) {
			return self.get(id)?.type;
		},
	}))
	.actions((self) => ({
		/**
		 * format an incoming snapshot of an ICS payment gateway
		 * @param {string} id
		 * @param {SnapshotIn<typeof RewardProcessor>} rewardProcessor
		 */
		set(id, rewardProcessor) {
			self.Map.set(id, rewardProcessor);
		},
		delete(id) {
			if (self.has(id)) {
				self.Map.delete(id);
			}
		},
		clear() {
			self.Map.clear();
		},
	}));

const RewardProcessorCollection = types.snapshotProcessor(
	__RewardProcessorCollection,
	{
		preProcessor(snapshot) {
			return snapshot?.Map ? snapshot : { Map: {} };
		},
	}
);

export default RewardProcessorCollection;
