import React, { useCallback } from 'react';
import { useRouteMatch, Redirect } from 'react-router-dom';
import {
	DashboardIcon,
	CampaignsIcon,
	AffiliatesIcon,
	ClientsIcon,
	UsersIcon,
	LogsIcon,
	SettingsIcon,
} from '../../components/presentational';
import Forbidden from '../../components/pages/403';
import NotFound from '../../components/pages/404';
import Affiliates from '../../components/pages/Affiliates';
import BusinessSetUp from '../../components/pages/BusinessSetUp';
import Dashboard from '../../components/pages/Dashboard';
import Campaigns from '../../components/pages/Campaigns';
import Clients from '../../components/pages/Clients';
import Users from '../../components/pages/Users';
import Logs from '../../components/pages/Logs';
import Settings from '../../components/pages/Settings';
import SquareConnect from '../../components/pages/SquareConnect';
import StripeConnect from '../../components/pages/StripeConnect';

import { useStore, useUserRole } from './';
import { useRouteConfig } from './useRouteConfig';
import { flags } from '../featureFlags';

/**
 * @module useAllowedRoutes
 * @category Shared
 * @subcategory Hooks
 * @exports  useAllowedRoutes
 */

/**
 * @todo unify this structure with RouteConfig
 *
 * @function
 * @description
 * determines a list of routes based on the logged in user's role.  Used
 * for generating role protected routes and navigation links such as the
 * Navbar
 * @example
 * // routes.js
 * const allowedRoutes = useAllowedRoutes();
 *
 * return allowedRoutes.map({ route, ...other} => <Route {...route} />);
 */
export const useAllowedRoutes = () => {
	const store = useStore();
	const hasModuleAccess = useUserRole();

	const defaultRoutes = [
		{
			route: {
				exact: true,
				path: '/dashboard',
				component: Dashboard,
			},
			name: 'Dashboard',
			to: '/dashboard',
			active: useRouteMatch('/dashboard'),
			icon: DashboardIcon,
		},
		{
			route: {
				path: '/campaigns',
				component: Campaigns,
			},
			name: 'Campaigns',
			to: '/campaigns',
			active: useRouteMatch('/campaigns'),
			icon: CampaignsIcon,
		},
		{
			route: { path: '/affiliates', component: Affiliates },
			name: 'Affiliates',
			to: '/affiliates',
			active: useRouteMatch('/affiliates'),
			icon: AffiliatesIcon,
		},
		{
			route: { path: '/clients', component: Clients },
			name: 'Clients',
			to: '/clients',
			active: useRouteMatch('/clients'),
			icon: ClientsIcon,
		},
		{
			route: { path: '/users', component: Users },
			name: 'Users',
			to: '/users',
			active: useRouteMatch('/users'),
			icon: UsersIcon,
		},
		// {
		// 	route: { exact: true, path: '/logs', component: Logs },
		// 	name: 'Logs',
		// 	to: '/logs',
		// 	active: useRouteMatch('/logs'),
		// 	icon: LogsIcon,
		// },
		{
			route: { exact: false, path: '/settings', component: Settings },
			name: 'Settings',
			to: '/settings',
			active: useRouteMatch('/settings'),
			icon: SettingsIcon,
		},
		{
			route: {
				exact: false,
				path: '/business-setup',
				component: BusinessSetUp,
			},
			name: 'Business',
		},
		{
			route: {
				exact: true,
				path: '/square-connect',
				component: SquareConnect,
			},
			name: 'Processors',
		},
		{
			route: {
				exact: true,
				path: '/stripe-connect',
				component: StripeConnect,
			},
			name: 'Processors',
		},
		{
			route: { path: '/access-denied', component: Forbidden },
			name: 'Not Authorized',
			allAccess: true,
		},
		{
			route: {
				path: '/',
				exact: true,
				component: () => <Redirect to='/auth' />,
			},
			name: 'redirect',
			allAccess: true,
		},
		{
			route: { path: '*', component: NotFound },
			name: 'Not Found',
			allAccess: true,
		},
	];

	// const filterCallback = useCallback(
	// 	({ name }) => hasModuleAccess(name.toLowerCase()),
	// 	[store.Orchestrators.Auth.token]
	// );
	const allowedRoutes = defaultRoutes.filter(
		({ name, allAccess }) => hasModuleAccess(name.toLowerCase()) || allAccess
	);
	// const allowedLinks = allowedRoutes.map(({ link }) => link);
	return allowedRoutes;
};
