import React from 'react';
import { observer } from 'mobx-react-lite';
import { Div, Typography } from '@icecreamsocial/components';
import {
	BusinessProfileForm,
	Pagename,
	SteppedFormControls,
} from '../../../../presentational';
import { useStore } from '../../../../../utils/hooks';

/**
 * @todo pass initial values as an object instead of spreading it
 */
const BusinessProfile = ({ handleSubmit, returnTo }) => {
	const store = useStore();
	const initialValues = {
		businessName: store.userBusiness?.name,
		businessAddress: store.userBusiness?.businessAddress,
		currency: store.userBusiness?.currency,
		language: store.Orchestrators.Auth.locale,
		termsAndConditionsURL: store.userBusiness?.termsAndConditionsURL || '',
		privacyPolicyURL: store.userBusiness?.privacyPolicyURL || '',
	};

	return (
		<Div>
			<Pagename>Business Profile</Pagename>
			<Typography.P>Some basic questions about your business</Typography.P>
			<BusinessProfileForm
				onSubmit={handleSubmit}
				allowRouteBlock={false}
				renderControls={() => (
					<SteppedFormControls first={true} onNav={returnTo} />
				)}
				{...initialValues}
			/>
		</Div>
	);
};
BusinessProfile.displayName = 'BusinessSetUp.Profile';

export default observer(BusinessProfile);
