import { useEffect, useMemo } from 'react';

const SCRIPT_ID = 'release-notes-script';
/**
 * @module useReleaseNotes
 * @category Shared
 * @subcategory Hooks
 * @exports  useReleaseNotes
 */
/**
 * @function
 * @param {*} onLoad
 */
export const useReleaseNotes = (onLoad = (rnw) => {}) => {
	const releaseNotesEmbedded = useMemo(
		() => document.querySelectorAll(`#${SCRIPT_ID}`)?.[0],
		[]
	);
	useEffect(() => {
		if (!releaseNotesEmbedded) {
			embedReleaseNotesScript(() => {
				window.rnw('init', {
					account: 'icecreamsocial.releasenotes.io',
					selector: '.rn-badge', // changes the selector to apply the badge and link to
					title: 'Latest Updates from Ice Cream Social', // changes the CSS main title
					auto_show_unseen: true, // if enabled, the release notes will auto open if the user has unseen releases
					auto_show_new_releases: false,
				});
			});
		}
	}, []);
};

function embedReleaseNotesScript(onLoad) {
	const body = document.querySelectorAll('body')?.[0];
	const script = document.createElement('script');
	script.id = SCRIPT_ID;
	// slightly modifying the script so that we can be notified when it loads
	script.innerHTML = `
	(function (w,d,s,o,f,js,fjs) { 
		w['ReleaseNotesWidget']=o;w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) }; 
		js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];
		js.id = o; js.src = f; js.async = 1; js.onload = ${onLoad};
		fjs.parentNode.insertBefore(js, fjs);
	} (window, document, 'script', 'rnw', 'https://s3.amazonaws.com/cdn.releasenotes.io/v1/bootstrap.js'));
	`;
	body.appendChild(script);
}
