import React from 'react';
import { observer } from 'mobx-react-lite';
import { Div, Typography, Tag } from '@icecreamsocial/components';
import BaseTable from '../BaseTable';
import { formatCurrency, formatDateTime } from '../../../utils';

const ConversionsTableNew = ({ collection = [], onChecked, ...rest }) => {
	const textOverflowStyles = {
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
	};

	const colorContextMap = {
		Confirmed: 'good',
		Canceled: 'bad',
		Pending: 'warning',
	};

	const colorContext = (context) => ({
		textColor: context,
		borderColor: context,
	});

	let columns = [
		{
			name: 'Invitee',
			selector: (row) => row.InfluencerEmail,
			cell: (row) => (
				<Div padding='sm' paddingLeft='none' style={textOverflowStyles}>
					<Typography.Span
						typoWeight='bold'
						textTransform='capitalize'
						display='block'
						textDecoration='none'
						marginBottom='sm'
						style={textOverflowStyles}
						title={row.influencerName || '-'}
					>
						{row.influencerName || '-'}
					</Typography.Span>
					{row.influencerEmail && (
						<Typography.Span
							display='block'
							textDecoration='none'
							style={textOverflowStyles}
							title={row.influencerEmail}
						>
							{row.influencerEmail}
						</Typography.Span>
					)}
				</Div>
			),
			sortable: true,
			grow: 3,
			width: '250px',
		},
		{
			name: 'Order Total',
			selector: (row) => row.revenue,
			cell: (row) => (
				<Typography.Span>
					{formatCurrency(row.revenue, 'en-US', {
						style: 'currency',
						currency: 'USD',
					})}
				</Typography.Span>
			),
			sortable: true,
			grow: 2,
		},
		{
			name: 'Order ID',
			selector: (row) => row.identifier,
			cell: (row) => <Typography.Span>{row.identifier}</Typography.Span>,
			sortable: true,
			grow: 1,
		},
		{
			name: 'Date Created',
			selector: (row) => row.createdAt,
			cell: (row) => (
				<Typography.Span
					style={textOverflowStyles}
					title={formatDateTime(new Date(row.createdAt))}
				>
					{formatDateTime(new Date(row.createdAt))}
				</Typography.Span>
			),
			sortable: true,
			grow: 2,
			width: '150px',
		},
		{
			name: 'Influencer',
			selector: (row) => row.inviterInfluencerEmail || '',
			cell: (row) => (
				<Div padding='sm' paddingLeft='none' style={textOverflowStyles}>
					<Typography.Span
						typoWeight='bold'
						textTransform='capitalize'
						display='block'
						textDecoration='none'
						marginBottom='sm'
						style={textOverflowStyles}
						title={row.inviterInfluencerName || '-'}
					>
						{row.inviterInfluencerName || '-'}
					</Typography.Span>
					{row.inviterInfluencerEmail && (
						<Typography.Span
							display='block'
							textDecoration='none'
							style={textOverflowStyles}
							title={row.inviterInfluencerName}
						>
							{row.inviterInfluencerEmail}
						</Typography.Span>
					)}
				</Div>
			),
			sortable: true,
			grow: 3,
			width: '250px',
		},
		{
			name: 'Status',
			selector: (row) => row.status,
			cell: (row) => (
				<Tag
					textTransform='capitalize'
					{...colorContext(colorContextMap[row.status])}
					style={{ whiteSpace: 'nowrap' }}
				>
					{row.status}
				</Tag>
			),
			grow: 1,
			minWidth: '200px',
			center: true,
		},
	];

	return <BaseTable columns={columns} collection={collection} {...rest} />;
};

ConversionsTableNew.displayName = 'ConversionsTableNew';

export default observer(ConversionsTableNew);
