import React, { createContext, useMemo, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import {
	Button,
	Div,
	InputGroup,
	Panel,
	Typography,
} from '@icecreamsocial/components';
import { Checkbox, FormControlLabel, Radio } from '@material-ui/core';
import { EnhancedForm2, RequiredAsterisk } from '../';
import ProfileSettings from './ProfileSettings';
import UserRole from './UserRole';
import TeamLeader from './TeamLeader';

/**
 * @module UserSettingsForm
 * @category Components
 * @subcategory Presentational
 */

/**
 * @typedef {object} UserSettingValues

 */

/**
 * @name UserSettingsFormContext
 * @description only used for child components of UserSettingsForm
 */
export const UserSettingsFormContext = createContext({});
export const useUserSettingsForm = () => useContext(UserSettingsFormContext);

/**
 * @name UserSettingsForm
 * @description
 * A form for creating and editing a user.
 * @param {object} 																			props
 * @param {boolean} 																		[props.isEditing = false] whether or not the form is in edit mode
 * @param {boolean} 																		[props.allowRouteBlock = true] whether or not the prompt should be shown when the form is dirty
 * @param {boolean}																			[props.userIsTeamLeader = false] is the logged in user a team admin
 * @param {object}	 																		props.initialValues the values to initialize formik with. need to pass all values, even if not all of them will be used.
 * @param {boolean} 																		props.initialValues.isTeamAdmin
 * @param {'affiliate'|'client'} 												props.initialValues.role the type of user being created. not to be confused with `userRole`
 * @param {string} 																			props.initialValues.email
 * @param {string}																			props.initialValues.password
 * @param {string}																			props.initialValues.firstName
 * @param {string}																			props.initialValues.lastName
 * @param {string}																			props.initialValues.affiliateId which affiliate will this user belong to
 * @param {string}																			props.initialValues.clientId which client will this user belong to
 * @param {function} 																		props.onSubmit
 * @param {string} 		 																	props.userAffiliateId the logged in user's affiliate id.  required if an affiliate user.
 * @param {string} 		 																	props.userClientId the logged in user's client id.  required if a client user.
 * @param {'affiliate'|'client'}												props.userRole the logged in user's role
 * @param {React.FunctionComponent} 										props.renderControls component passed to `EnhancedForm2`
 */
const UserSettings = ({
	isEditing = false,
	allowRouteBlock = true,
	userIsTeamLeader = false,
	initialValues,
	onSubmit,
	userAffiliateId,
	userClientId,
	userRole,
	renderControls,
}) => {
	const canSetUserRole = useMemo(() => userRole === 'affiliate', [userRole]);
	const formikProps = {
		enableReinitialize: true,
		initialValues,
		onSubmit,
	};

	return (
		<EnhancedForm2
			formikProps={formikProps}
			promptIf={({ dirty }) => allowRouteBlock && dirty}
			renderControls={renderControls}
			autoComplete='off'
		>
			{(userSettings) => (
				<UserSettingsFormContext.Provider
					value={{
						allowRouteBlock,
						initialValues,
						isEditing,
						onSubmit,
						userAffiliateId,
						userClientId,
						userRole,
						userIsTeamLeader,
						renderControls,
						form: userSettings,
					}}
				>
					<ProfileSettings />
					{canSetUserRole && <UserRole userAffiliateId={userAffiliateId} />}
					{userIsTeamLeader && <TeamLeader />}
				</UserSettingsFormContext.Provider>
			)}
		</EnhancedForm2>
	);
};
UserSettings.displayName = 'UserSettingsForm';

export default observer(UserSettings);
