import { types } from 'mobx-state-tree';
import { IcsCampaign } from '@icecreamsocial/mst-models';

/**
 * @module CampaignsCollection
 * @category Shared
 * @subcategory Models
 * @description
 * Contains Campaign instances and references
 * @todo document inner methods
 */

const CampaignsCollection = types
	.model({
		campaignMap: types.map(IcsCampaign),
		list: types.array(types.reference(IcsCampaign)),
	})
	.named('CampaignsCollection')
	.preProcessSnapshot((snapshot = {}) => ({
		campaignMap: snapshot.campaignMap || {},
		list: snapshot.list || [],
	}))
	.volatile((self) => ({
		start: 0,
		end: 0,
		include: {
			archived: false,
			active: true,
		},
	}))
	.views((self) => ({
		get hasCampaigns() {
			return !!self.list.length;
		},
		get total() {
			return self.list.length;
		},
		/**
		 * @static
		 * @type {Array<*>}
		 * @description
		 * Returns a filtered and paginated list.  might want to include one without filters if necessary use case
		 */
		get paginated() {
			return self.list
				.filter(({ isArchived }) => isArchived === self.include.archived)
				.slice(self.start, Math.min(self.end, self.list.length));
		},
		filterBy({ status = 'active', isArchived = false }) {
			return self.list
				.filter((campaign) => campaign.isArchived === isArchived)
				.filter((campaign) => campaign.status === status);
		},
		paginateBy({ status = 'active', isArchived = false }) {
			return self.filterBy({ status, isArchived }).slice(self.start, self.end);
		},
		find(id) {
			return self.list.find((ref) => ref.id === id);
		},
	}))
	.actions((self) => ({
		addCampaign(campaign) {
			self.campaignMap.put(campaign);
		},
		addCampaigns(campaigns = []) {
			campaigns.forEach((campaign) => self.addCampaign(campaign));
		},
		deleteCampaign(id) {
			self.list.remove(id);
			self.campaignMap.delete(id);
		},
		setCampaigns(campaigns = []) {
			let refs = [];
			campaigns.forEach((campaign) => {
				self.addCampaign(campaign);
				refs.push(campaign.id);
			});
			self.setCampaignList(refs);
		},
		setLocalPagination(start = 0, end = 0) {
			self.start = start;
			self.end = end;
		},
		setIncludedBy(values = {}) {
			self.include = { ...self.include, ...values };
		},
		setCampaignList(campaignRefs = []) {
			self.list.replace(campaignRefs);
		},
		updateCampaign(id, campaign) {
			self.campaignMap.set(id, campaign);
		},
		clearAll() {
			self.setCampaignList();
			self.campaignMap.clear();
		},
	}));

export default CampaignsCollection;
