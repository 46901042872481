import React from 'react';
import { Typography } from '@icecreamsocial/components';

const RequiredAsterisk = (props) => (
	<Typography.Span textColor="bad" {...props}>
		*
	</Typography.Span>
);

export default RequiredAsterisk;
