import { createQueryKeys } from '@lukemorales/query-key-factory';

/**
 * reuable query keys for Converions for use with useQuery or useMutation
 */
export const queryKeys = createQueryKeys('users', {
	/**
	 * @param {Partial<StatsQueries.CoreParams>} params
	 */
	getStatsOverview: ({
		affiliateId,
		clientId,
		fromDate,
		toDate,
		campaignId,
	}) => ({ affiliateId, clientId, fromDate, toDate, campaignId }),
	/**
	 * @param {Partial<StatsQueries.CoreParams>} params
	 */
	getDailyStats: ({ affiliateId, clientId, fromDate, toDate, campaignId }) => ({
		affiliateId,
		clientId,
		fromDate,
		toDate,
		campaignId,
	}),
});
