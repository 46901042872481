import { createQueryKeys } from '@lukemorales/query-key-factory';

/**
 * reusable parameters for influencer queries
 */
interface CoreParams {
	campaignId: string;
}

interface FilterParams {
	search: string;
	influencerId: string;
	rewardAmountGTOE: string;
	rewardAmountLTOE: string;
	rewardDateGTOE: string;
	rewardDateLTOE: string;
	skip: string | number;
	limit: string | number;
}

/**
 * parameters for listing rewards
 */
type ListParams = CoreParams & FilterParams;
interface List extends Partial<ListParams> {
	limit?: string | number;
}

/**
 * parameters for exporting rewards
 */
type ExportParams = CoreParams & FilterParams;
type ExportRewards = ExportParams;

export const queryKeys = createQueryKeys('rewards', {
	list: ({
		campaignId,
		influencerId = undefined,
		search = undefined,
		rewardAmountGTOE = undefined,
		rewardAmountLTOE = undefined,
		rewardDateGTOE = undefined,
		rewardDateLTOE = undefined,
		skip = 0,
		limit = 10,
	}: Partial<List>) => ({
		campaignId,
		influencerId,
		search,
		rewardAmountGTOE,
		rewardAmountLTOE,
		rewardDateGTOE,
		rewardDateLTOE,
		skip,
		limit,
	}),

	/**
	 * Exports a csv of the Rewards report
	 */
	exportRewardsReport: (params: ExportRewards) => ({ ...params }),
});
