import { types } from 'mobx-state-tree';
import { IcsUser } from '@icecreamsocial/mst-models';

const UsersCollection = types
	.model({
		userMap: types.map(IcsUser),
		list: types.array(types.reference(IcsUser)),
	})
	.named('UsersCollection')
	.preProcessSnapshot((snapshot = {}) => ({
		userMap: snapshot.userMap || {},
		list: snapshot.list || [],
	}))
	.views((self) => ({
		get hasUsers()
		{
			return !!self.list.length;
		},
		find(id)
		{
			return self.list.find((ref) => ref.id === id);
		},
	}))
	.actions((self) => ({
		addUser(user)
		{
			self.userMap.put(user);
		},
		addUsers(users = [])
		{
			users.forEach((user) => self.addUser(user));
		},
		setUserList(userRefs = [])
		{
			self.list.replace(userRefs);
		},
		updateUser(id, user)
		{
			self.userMap.set(id, user);
		},
		clearAll()
		{
			self.list.clear();
			self.userMap.clear();
		}
	}));

export default UsersCollection;
