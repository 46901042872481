import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { Div, Panel, Typography } from '@icecreamsocial/components';
import { V2 } from '@icecreamsocial/api-resources';
import { CondensedConversions } from '../../../presentational';
import { useStore, useQueryString } from '../../../../utils/hooks';
import { formatCurrency } from '../../../../utils';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../../../../queries/conversions';

const InfluencerConversions = ({
	conversionsGenerated = 0,
	revenueGenerated = 0,
	manageLink,
}) => {
	const store = useStore();
	const { campaignId } = useParams();
	const { query } = useQueryString();

	const { isFetching, data } = useQuery(
		queryKeys.listInfluencedConversions({
			influencerId: query.iid,
			limit: 5,
			conversionType: 'Transaction',
			campaignId,
		}),
		async () => {
			const {
				data,
			} = await V2.Campaigns.getInfluencedConversionsByInvitedInfluencerId(
				{
					influencerId: query.iid,
					campaignId,
				},
				{
					limit: 5,
					conversionType: 'Transaction',
				},
				{
					Authorization: store.Orchestrators.Auth.authHeader,
				}
			);
			return data?.data?.conversions;
		},
		{ enabled: !!query.iid }
	);

	return (
		<Panel backgroundColor='bodyInverse'>
			<Panel.Body>
				<Div
					display='flex'
					alignItems='center'
					justifyContent='space-between'
					marginBottom='lg'
				>
					<Typography.H2 typoWeight='500' mar gin='0'>
						Referrals
					</Typography.H2>
					{!!data?.length && (
						<Typography.A
							as={Link}
							to={manageLink}
							visitedTextColor='link'
							textColor='link'
							typoWeight='600'
						>
							Manage
						</Typography.A>
					)}
				</Div>
				<Div
					grid
					gridColumns='repeat(2, 1fr)'
					style={{ gap: '16px' }}
					marginBottom='lg'
				>
					<Panel backgroundColor='ambientLight' border='none'>
						<Panel.Body textAlign='center'>
							<Typography.Span
								marginBottom='md'
								typoSize='lg'
								typoWeight='bold'
								display='block'
							>
								{conversionsGenerated}
							</Typography.Span>
							<Typography.Span display='block'>
								Referrals Generated
							</Typography.Span>
						</Panel.Body>
					</Panel>
					<Panel backgroundColor='ambientLight' border='none'>
						<Panel.Body textAlign='center'>
							<Typography.Span
								marginBottom='md'
								typoSize='lg'
								typoWeight='bold'
								display='block'
							>
								{formatCurrency(revenueGenerated, 'en-US', {
									style: 'currency',
									currency: 'USD',
								})}
							</Typography.Span>
							<Typography.Span display='block'>
								Revenue Generated
							</Typography.Span>
						</Panel.Body>
					</Panel>
				</Div>

				<CondensedConversions collection={data} />
			</Panel.Body>
		</Panel>
	);
};
InfluencerConversions.displayName = 'InfluencerManager.Conversions';

export default observer(InfluencerConversions);
