import { types, SnapshotIn, getRoot } from 'mobx-state-tree';
import {
	IcsUser,
	IcsCompany,
	// IcsPaymentGateway,
} from '@icecreamsocial/mst-models';
import { formatImageURL } from '../../../utils';
import { Business } from '../../models';

const DEFAULT_MODULE_ACCESS = {
	dashboard: true,
	campaigns: true,
	affiliates: true,
	clients: true,
	logs: true,
	users: true,
	settings: true,
	business: true,
	processors: true,
};

const Auth = types
	.model({
		token: types.maybe(types.string),
		user: types.maybe(types.reference(IcsUser)),
		business: types.maybe(
			types.reference(Business, {
				/**
				 * @ignore
				 * this is a workaround because snapshotProcessors can't be referenced
				 * this is not really ideal because it assumes the structure of the store
				 * but it shouldn't be an issue since this is not planned to be reusable
				 * @param {*} id
				 * @param {*} parent
				 */
				get(id, parent) {
					return getRoot(parent)?.Collections?.Businesses?.get(id);
				},
				set(id) {
					return id;
				},
			})
		),
		moduleAccess: types.map(types.boolean),
	})
	.named('AuthOrchestrator')
	.preProcessSnapshot((snapshot = {}) => {
		return {
			token: snapshot.token || undefined,
			user: snapshot.user || undefined,
			business: snapshot.business || undefined,
			moduleAccess: { ...DEFAULT_MODULE_ACCESS },
		};
	})
	.volatile((self) => ({
		squarePermissionRequests: [
			'DEVICE_CREDENTIAL_MANAGEMENT',
			// 'BORDERS_READ',
			'CUSTOMERS_READ',
			'CUSTOMERS_WRITE',
			'ITEMS_READ',
			'MERCHANT_PROFILE_READ',
			'PAYMENTS_READ',
			'GIFTCARDS_WRITE',
			'GIFTCARDS_READ',
		],
		stripePermissionRequests: 'read_write',
	}))
	.actions((self) => ({
		setToken(token) {
			self.token = token;
		},
		setUser(userData) {
			self.user = userData;
			self.setModuleAccess();
		},
		setUserBusiness(businessId) {
			self.user.setBusiness(businessId);
			self.business = businessId;
		},
		unsetAll() {
			self.user = self.token = undefined;
			self.moduleAccess = { ...DEFAULT_MODULE_ACCESS };
		},
		unsetToken() {
			self.token = undefined;
		},
		unsetUser() {
			self.user = undefined;
		},
		setModuleAccess() {
			switch (self.user.role) {
				case 'affiliate': {
					self.moduleAccess.set('business', false);
					break;
				}
				case 'client': {
					self.moduleAccess.set('affiliates', false);
					self.moduleAccess.set('clients', false);
					break;
				}
				default: {
					break;
				}
			}
		},
	}))
	.views((self) => ({
		get authHeader() {
			return `JWT ${self.token}`;
		},
		get locale() {
			const locale = self.business?.language ?? 'en';
			const localeMap = {
				de: 'de-de',
				en: 'en-US',
				fr: 'fr-FR',
				ja: 'ja-JP',
				ko: 'ko-KR',
				zh: 'zh-CN',
			};

			return localeMap[locale];
		},
		get clientId() {
			return self.user?.clientId;
		},
		get parentAffiliateId() {
			return self.user?.affiliateId;
		},
		get userId() {
			return self.user?.id;
		},
		get isAuthenticated() {
			return !!self.token && !!self.user;
		},
		get isTeamLeader() {
			return self.user?.isTeamAdmin;
		},
		get role() {
			return self.user?.role;
		},
		get userInitials() {
			return self.user?.initials;
		},
		get userName() {
			return self.user?.name;
		},
		get businessId() {
			return self.business?.id;
		},
		hasModuleAccess(moduleName) {
			return self.moduleAccess.get(moduleName);
		},
	}));

export default Auth;
