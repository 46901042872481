import React, { createContext, useContext } from 'react';
import { useHistory, useLocation } from 'react-router';
import { filterProviderFactory } from '../../../../../../../../../../../utils/filterProviderFactory';
import { useQueryString } from '../../../../../../../../../../../utils/hooks';

const { Provider, useFilters } = filterProviderFactory();
/**
 * Referral filters
 */
export const ReferralFiltersProvider = ({ children }) => {
	/**
	 * @param {ReferralFilters.ControlState} controlValues
	 */
	const mapControlsToState = (controlValues) => {
		const { revenueSwitch, fromDate, toDate, ...state } = controlValues;
		return {
			ReferralDateGTOE: fromDate,
			ReferralDateLTOE: toDate,
			...state,
		};
	};

	return (
		<Provider mapControlsToState={mapControlsToState}>{children}</Provider>
	);
};

export const useReferralFilters = useFilters;
