import React, { createContext, ReactNode, useContext } from 'react';
import {
	useMutation,
	UseMutationResult,
	useQuery,
	useQueryClient,
	UseQueryResult,
} from '@tanstack/react-query';
import { useStore } from '../../../../../../utils/hooks';
import { V2 } from '@icecreamsocial/api-resources';
import { Campaign, queryKeys } from '../../../../../../queries/campaigns';

type CampaignContext = {
	campaign: UseQueryResult<Campaign>;
	update: UseMutationResult<Campaign>;
};

const CampaignCtx = createContext<CampaignContext | null>(null);

/**
 * Makes the campaign data and loading state available to all child components
 */
export const CampaignProvider = ({
	children,
	campaignId,
}: {
	campaignId: string;
	children: ReactNode;
}) => {
	const { Orchestrators, __displayFlowSuccess } = useStore();
	const headers = { Authorization: Orchestrators.Auth.authHeader };
	const queryClient = useQueryClient();

	const campaign = useQuery<Campaign>(
		queryKeys.getCampaign({ campaignId }),
		async () => {
			const { data } = await V2.Campaigns.getCampaignById(
				{ campaignId },
				{},
				headers
			);
			return data?.data?.campaign;
		},
		{ enabled: !!campaignId, refetchOnWindowFocus: false }
	);

	const update = useMutation<Campaign>(
		makeUpdateCampaign({ campaignId, headers }),
		{
			onSuccess: (data: Campaign) => {
				queryClient.invalidateQueries(queryKeys.getCampaigns._def);
				queryClient.setQueryData(queryKeys.getCampaign({ campaignId }), data);
				/**
				 * @todo eliminate store dependency
				 */
				__displayFlowSuccess('Campaign settings updated!');
			},
		}
	);

	const context = {
		campaign,
		update,
	};

	return (
		<CampaignCtx.Provider value={context}>{children}</CampaignCtx.Provider>
	);
};

export const useCampaign = (): CampaignContext => {
	const campaignContext = useContext(CampaignCtx);
	return campaignContext;
};

function makeUpdateCampaign({ campaignId, headers }) {
	return async (values) => {
		const formData = new FormData();
		// we need to append images last
		const { offerImage, thankYouImage, shareImage, ...textValues } = values;

		Object.keys(textValues).forEach((key) => {
			// don't add null values because api doesn't know how to handle them
			if (
				(textValues[key] === undefined || textValues[key] === null) &&
				textValues[key] !== ''
			)
				return;

			formData.set(key, textValues[key]);
		});

		// set images only if they are files.  if they are strings, they are most likely the previous uri
		// this is so that we don't overwrite the image on subsequent saves
		if (typeof offerImage?.name === 'string') {
			formData.set('offerImage', offerImage);
		}
		if (typeof thankYouImage?.name === 'string') {
			formData.set('thankYouImage', thankYouImage);
		}
		if (typeof shareImage?.name === 'string') {
			formData.set('shareImage', shareImage);
		}

		const { data } = await V2.Campaigns.updateCampaign(
			{
				campaignId,
				formData,
			},
			{},
			headers
		);

		return data?.data?.campaign;
	};
}
