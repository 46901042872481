import React, { useState, useMemo, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useFormik, FormikConfig, FormikProps, Formik } from 'formik';
import { RouteChangeBlocker } from '../';
/**
 * @module EnhancedForm2
 * @category Components
 * @subcategory Presentational
 */

/**
 * @description simplified Formik by using the component directly
 * @callback PromptIfCallback
 * @param {FormikProps} formikValues
 * @returns {boolean}
 */

/**
 * @description callback function that receives the FormikProps and returns a react element
 * @callback RenderFunc
 * @param {FormikProps<*>} formikValues
 * @returns {React.ReactElement | null}
 */

/**
 * @description A form with extra capabilities for blocking routes and rendering specific controls
 * @param {object} 											props
 * @param {module:EnhancedForm~RenderFunc} 									props.children React children with the form inputs
 * @param {FormikConfig}								props.formikProps configuration for the form using formik
 * @param {PromptIfCallback | boolean}	props.promptIf A callback that returns a boolean or a boolean to let the form know when it should prompt the users when submitting
 * @param {module:EnhancedForm~RenderFunc | null}						[props.renderControls = null] A render function mainly for convenience.  It takes care of a common pattern for creating forms
 * @param {string}											[props.promptMsg = null] message to be prompted with
 */
const EnhancedForm = ({
	children,
	formikProps,
	promptIf,
	renderControls = null,
	promptMsg = null,
	...rest
}) => {
	const shouldPrompt = useCallback(
		(formik) => {
			const evalPrompt =
				typeof promptIf === 'function' ? promptIf(formik) : promptIf;

			return evalPrompt;
		},
		[formikProps, promptIf]
	);

	return (
		<>
			<Formik {...formikProps} {...rest}>
				{(formik) => (
					<>
						<form onSubmit={formik.handleSubmit}>
							{children(formik)}
							{renderControls && renderControls(formik)}
						</form>
						<RouteChangeBlocker
							when={shouldPrompt(formik)}
							message={promptMsg}
						/>
					</>
				)}
			</Formik>
		</>
	);
};
EnhancedForm.displayName = 'EnhancedForm2';

export default observer(EnhancedForm);
