import { types } from "mobx-state-tree";
import { IcsEntityMetaData, Price } from "@icecreamsocial/mst-models";

/**
 * IcsCampaignConversion Model
 * A campaign conversion as represented by the ics api
 */
const Conversion = types
  .compose(
    IcsEntityMetaData,
    types.model({
      campaignId: types.number,
      identifier: types.string,
      influencerId: types.number,
      influencerName: types.maybeNull(types.string),
      influencerEmail: types.maybeNull(types.string),
      inviteId: types.maybeNull(types.number),
      inviterInfluencerId: types.maybeNull(types.number),
      inviterInfluencerName: types.maybeNull(types.string),
      inviterInfluencerEmail: types.maybeNull(types.string),
      revenue: types.maybeNull(Price),
      transactionGateway: types.maybeNull(types.string),
      transactionId: types.maybeNull(types.string),
    })
  )
  .named("Conversion")
  .views((self) => ({}));

export default Conversion;
