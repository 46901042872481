import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Typography } from '@icecreamsocial/components';
import { useStore } from '../../../../../../../../../utils/hooks';
import OfferSettingsForm from '../../../../../../components/OfferSettingsForm';
import {
	NoProcessorPanel,
	EditFormControls,
	Pagename,
} from '../../../../../../../../presentational';
import { formatImageURL } from '../../../../../../../../../utils';
import { useCampaign } from '../../../../providers/Campaign';
import { queryKeys } from '../../../../../../../../../queries/campaigns';

const OfferSettings = () => {
	const store = useStore();
	const { campaignId } = useParams();
	const { campaign, update } = useCampaign();
	const { data } = campaign;
	const queryClient = useQueryClient();

	/**
	 * gets the discount data
	 */
	const { data: discount } = useQuery(
		queryKeys.getDiscounts({ campaignId }),
		async () => {
			const { discount } = await store.getCampaignDiscounts({ campaignId });
			return discount || {};
		},
		{ refetchOnWindowFocus: false }
	);

	/**
	 * updates a campaign's discount
	 * @returns
	 */
	const updateCampaignDiscountsMutation = async ({
		campaignId,
		discountCode,
		discountCodeDesc,
	}) => {
		const { discount } = await store.updateCampaignDiscounts({
			campaignId,
			discountCode,
			discountCodeDesc,
		});

		return discount;
	};

	/**
	 * mutate campaign discount data
	 */
	const { mutate: updateCampaignDiscounts } = useMutation(
		updateCampaignDiscountsMutation,
		{
			onSuccess: (data) => {
				queryClient.invalidateQueries(queryKeys.getDiscounts._def);
				queryClient.setQueryData(queryKeys.getDiscounts({ campaignId }), data);
			},
		}
	);

	const initialValues = {
		giftCardAmount: data?.giftCardAmount,
		conversionGoal: data?.conversionGoal,
		discountCode: discount?.discountCode,
		discountCodeDesc: discount?.discountCodeDesc,
		offerHeading: data?.offerHeading,
		offerInstructions: data?.offerInstructions,
		offerImageCaption: data?.offerImageCaption,
		offerImageTitle: data?.offerImageTitle,
		offerImage: formatImageURL(data?.offerImage),
		refundAmount: data?.refundAmount,
		useFixedGiftCardValue: data?.useFixedGiftCardValue,
	};
	const isSquare = useMemo(
		() => store.userProcessorType?.toLowerCase().indexOf('square') > -1,
		[store.userProcessorType]
	);

	const hasProcessor = useMemo(() => !!store.userProcessorType, [
		store.userProcessorType,
	]);

	const onSubmit = async ({
		discountCode,
		discountCodeDesc,
		useFixedGiftCardValue,
		...values
	}) => {
		const toSubmit = { ...values };
		if (parseInt(values.refundAmount) === 0) {
			toSubmit.useCardFlexRefund = false;
		}

		toSubmit.useFixedGiftCardValue =
			useFixedGiftCardValue === 'true' ? true : false;

		await update.mutate({
			...toSubmit,
		});

		await updateCampaignDiscounts({
			campaignId,
			discountCode,
			discountCodeDesc,
		});
	};

	return (
		<>
			{!store.userProcessorType && <NoProcessorPanel />}
			<Pagename>Offer Settings</Pagename>
			<Typography.P>
				Describe your offer to the person sharing it. We've provided some
				default copy that we've seen perform best. Customize it and make it your
				own to fit your campaign needs.
			</Typography.P>
			{data && (
				<OfferSettingsForm
					onSubmit={onSubmit}
					isSquare={isSquare}
					hasProcessor={hasProcessor}
					renderControls={(props) => <EditFormControls {...props} />}
					{...initialValues}
				/>
			)}
		</>
	);
};
OfferSettings.displayName = 'OfferSettingsForm';

export default observer(OfferSettings);
