import React, { useState, useRef, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import {
	Container,
	Modal,
	ModalBackground,
	Panel,
} from '@icecreamsocial/components';

/**
 * @todo document this
 * @note including the background in here for now so that we can animate this later
 * @note state changes aren't firing in the "beforeClose" callback and therefore animation out doesn't work
 * animating in only works on the first time the modal is opened
 */
const BaseModal = ({ isOpen, children }) => {
	return (
		<Modal isOpen={isOpen}>
			<ModalBackground open={isOpen}>
				<Container>{children}</Container>
			</ModalBackground>
		</Modal>
	);
};
BaseModal.displayName = 'BaseModal';

export default observer(BaseModal);
