import React from 'react';
import { observer } from 'mobx-react-lite';
import { Link, useRouteMatch } from 'react-router-dom';
import { Button, Panel, Typography } from '@icecreamsocial/components';
import { BaseTable, EditIcon, TableStatusLabel } from '../../presentational';

const ClientsTable = ({ collection = [], ...rest }) => {
	const columns = [
		{
			name: '',
			cell: (row) => (
				<Button
					as={Link}
					to={`/clients/${row.id}/settings`}
					padding='sm'
					borderRadius='50%'
					shadow='none'
					backgroundColor='transparent'
					textColor='ambientDark'
					textDecoration='none'
				>
					<EditIcon size={16} />
				</Button>
			),
			width: '56px',
			grow: 0,
		},
		{
			name: 'Client',
			grow: 1,
			selector: (row) => row.name,
			cell: (row) => (
				<Typography.A
					as={Link}
					to={`/clients/${row.id}`}
					typoWeight='bold'
					textTransform='capitalize'
					textDecoration='none'
				>
					{row.name}
				</Typography.A>
			),
			sortable: true,
		},
		{
			name: 'Affiliate',
			selector: (row) => row.affiliateName,
			sortable: true,
			grow: 1,
		},
		{
			name: 'Campaigns',
			selector: (row) => row.campaignCount,
			cell: (row) => (
				<Typography.Span textTransform='capitalize'>
					{row.campaignCount || '-'}
				</Typography.Span>
			),
			sortable: true,
			grow: 0,
			center: true,
		},
		{
			name: 'Status',
			selector: (row) => row.status,
			cell: (row) => (
				<TableStatusLabel status={row.isArchived ? 'archived' : row.status} />
			),
			sortable: true,
			grow: 0,
			width: '120px',
		},
	];

	return <BaseTable columns={columns} collection={collection} {...rest} />;
};

ClientsTable.displayName = 'ClientsTable';

export default observer(ClientsTable);
