import React from 'react';
import {
	Div,
	Panel,
	Img,
	Button,
	Typography,
} from '@icecreamsocial/components';
import { observer } from 'mobx-react-lite';

const ExamplePanel = ({
	exampleImg = undefined,
	title = '',
	text = '',
	isExampleUsed = undefined,
	onClick,
}) => {
	return (
		<Panel border='none' marginTop='lg' backgroundColor='ambientLight'>
			<Panel.Header paddingTop='sm' backgroundColor='inherit' paddingBottom='0'>
				<Div grid gridColumns='3fr 1fr' alignItems='center'>
					<Typography.H2
						textColor='body'
						textAlign='left'
						margin='md'
						typoSize='md'
					>
						Example Text
					</Typography.H2>
					<Div>
						<Button
							type='button'
							paddingLeft='md'
							paddingRight='md'
							paddingTop='sm'
							paddingBottom='sm'
							textDecoration={isExampleUsed ? 'underline' : undefined}
							textColor={isExampleUsed ? 'link' : 'ambient'}
							onClick={onClick}
							backgroundColor='transparent'
							shadow='none'
							border='none'
							hoverColor='bodyInverse'
							style={{ float: 'right' }}
						>
							Use Example
						</Button>
					</Div>
				</Div>
			</Panel.Header>
			<Panel.Body paddingTop='0' paddingButton='0'>
				<Div
					padding='md'
					border='1px solid'
					borderColor='ambientDark'
					borderRadius='20px'
					grid
					gridColumns='1fr 3fr'
					alignItems='center'
				>
					<Div textAlign='center'>
						<Img
							borderRadius='20px'
							src={
								exampleImg && typeof exampleImg.name === 'string'
									? URL.createObjectURL(exampleImg)
									: exampleImg
							}
							elHeight='170px'
							elWidth='170px'
							style={{ objectFit: 'cover' }}
						/>
					</Div>
					<Div paddingLeft='md'>
						<Typography.H4>{title}</Typography.H4>
						<Typography.P>{text}</Typography.P>
					</Div>
				</Div>
			</Panel.Body>
		</Panel>
	);
};

ExamplePanel.displayName = 'ExamplePanel';

export default observer(ExamplePanel);
