import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Div, Typography } from '@icecreamsocial/components';
import { useStore } from '../../../../../utils/hooks';
import {
	BusinessEcommerceForm,
	EditFormControls,
	Pagename,
} from '../../../../presentational';

const ECommerceSettings = () => {
	const store = useStore();
	const initialValues = {
		webstoreURL: store.userBusiness?.webstoreURL,
		eCommerceSolution: store.userBusiness?.eCommerceSolution,
	};

	const handleSubmit = (values) => store.updateCompanyFlow(values);

	return (
		<Div>
			<Pagename>eCommerce</Pagename>
			<BusinessEcommerceForm
				onSubmit={handleSubmit}
				renderControls={(props) => (
					<EditFormControls
						isLoading={store.isLoading('businessSettings')}
						disabled={store.isLoading('businessSettings')}
						{...props}
					/>
				)}
				{...initialValues}
			/>
		</Div>
	);
};

ECommerceSettings.displayName = 'Settings.Business.Ecommerce';
export default observer(ECommerceSettings);
