import { useCallback } from 'react';
import { useLocation } from 'react-router';
import { addMinutes } from 'date-fns';
import { useStore } from './useStore';
import { useQueryString } from './useQueryString';
import { useCookies } from './useCookies';

const COOKIE_NAME = 'stripeConnectOrigin';
const OAUTH_URL = 'https://connect.stripe.com/oauth/authorize';
const CLIENT_ID = process.env.STRIPE_CLIENT_ID; // all envs except prod use test credentials

/**
 * @typedef StripeConnectOpts
 * @property {function} handleOAuthRedirect a callback that redirects the user to the Stripe OAuth page. Calls `beforeOAuthRedirect`
 * @property {string} authorizationCode only defined on successful oauth.  undefined if there is an error.
 * @property {string} error only defined if the error is `access_denied`.  The Stripe OAuth flow will not redirect for any other errors
 * @property {string} errorDescription only defined if there is an error.
 * @property {string} stateParam returned by Stripe after the OAuth flow.  Pass to API to verify
 * @property {string} redirectURL a string denoting where the user should be redirected to after successfully connecting their Stripe account or canceling the process.  Defaults to the location where they initiated the OAuth flow
 * @property {function} cleanCookie a helper function to remove the cookie.  Only to be called at the end of the OAuth process
 */

/**
 * @description
 * A hook containing the Stripe OAuth redirect logic.  This is not responsible for creating processors, only for making sure the user can get to
 * the correct URLs during their connection journey.  Outputs a callback for directing the user to the OAuth URL as well as the responses if present.
 * Also keeps track of the originating URL of the process so that the user can be redirected back to where they started once they finish or cancel the process.
 *
 * @note Not sure if this should be a hook or not, but it does use several other hooks so it might make sense
 * @param {function} [beforeOAuthRedirect = function(){}] function to be called before redirecting to OAuth
 * @param {boolean} [setCookie = true] whether or not the cookie for the redirect origin should be set
 * @returns {StripeConnectOpts}
 */
export const useStripeConnect = (
	beforeOAuthRedirect = () => {},
	setCookie = true
) => {
	const store = useStore();
	const { qs, getParam } = useQueryString();
	const { deleteCookie, getCookie, setCookie: setStripeCookie } = useCookies();
	const { pathname, ...rest } = useLocation();

	// collect some variables
	const redirect = getCookie(COOKIE_NAME) || '/settings/reward-processor';
	const authorizationCode = getParam('code');
	const stateParam = getParam('state');
	const error = getParam('error');
	const errorDescription = getParam('error_description');
	const scope = store.Orchestrators.Auth.stripePermissionRequests;
	const redirect_uri = `${window.location.origin}/stripe-connect`;
	const params = {
		response_type: 'code',
		client_id: CLIENT_ID,
		scope,
	};
	const authParams = qs.stringify(params);
	const stripeURL = `${OAUTH_URL}?${authParams}&redirect_uri=${redirect_uri}`;

	/**
	 * Opens square oauth page
	 */
	const handleOAuthRedirect = useCallback(
		async (e) => {
			e.preventDefault();
			beforeOAuthRedirect();

			const param = await store.generateStateParam();

			if (setCookie) {
				// cookie will expire 15 minutes from when the initial auth request is made
				const expires = addMinutes(Date.now(), 15);
				setStripeCookie(COOKIE_NAME, pathname, expires, '/');
			}

			window.open(`${stripeURL}&state=${param}`, '_self');
		},
		[beforeOAuthRedirect, setCookie, stripeURL]
	);

	return {
		cleanCookie: () => deleteCookie(COOKIE_NAME),
		redirectURL: redirect,
		handleOAuthRedirect,
		authorizationCode,
		error,
		errorDescription,
		stateParam,
	};
};
