import React from 'react';
import { Link } from 'react-router-dom';

import {
	Button,
	Typography,
	Spanner,
	Container,
} from '@icecreamsocial/components';

const PolicyWarningBanner = ({ show = false }) => {
	return (
		show && (
			<Spanner
				backgroundColor="warningLight"
				position="sticky"
				top="0"
				marginBottom="xlg"
			>
				<Container>
					<Typography.H1 margin="none">Notice</Typography.H1>
					<Typography.P>
						Some of your Data &amp; Policy Settings require attention
					</Typography.P>
					<Button
						as={Link}
						to="/settings/data-and-policy"
						textDecoration="none"
						textColor="body"
						visitedTextColor="body"
						backgroundColor="bodyInverse"
					>
						Learn more
					</Button>
				</Container>
			</Spanner>
		)
	);
};

PolicyWarningBanner.displayName = 'PolicyWarningBanner';
export default PolicyWarningBanner;
