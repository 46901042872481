import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Checkbox } from '@material-ui/core';
import { useField, useFormikContext } from 'formik';

const SMSConsent = (props) => {
	const {
		values: { useSMS },
		touched,
		setFieldValue,
	} = useFormikContext();
	const [field] = useField(props);

	useEffect(() => {
		if (touched.useSMS) {
			setFieldValue(props.name, useSMS);
		}
	}, [useSMS, touched.useSMS, setFieldValue, props.name]);

	return (
		<Checkbox
			color='primary'
			checked={field.value === true}
			{...props}
			{...field}
			disabled
		/>
	);
};
SMSConsent.displayName = 'Campaigns.Campaign.Settings.SMSConsentInput';

export default observer(SMSConsent);
