import React, { useState, useEffect, useMemo } from 'react';
/**
 * @module useIntegrationScript
 * @category Shared
 * @subcategory Hooks
 * @exports  useIntegrationScript
 */
/**
 * @typedef IntegrationOptions
 * @property {string} productName
 * @property {string} productUrl
 * @property {boolean} getOrCreateInfluencer
 * @property {boolean} trackByInvite
 * @property {boolean} isDiscreet
 */

/**
 * @callback SetIntegrationOptions
 * @param {module:useIntegrationScript~IntegrationOptions} options
 * @returns {module:useIntegrationScript~IntegrationOptions} options
 */

/**
 * @typedef IntegrationScriptHookReturn
 * @property {string} processedScript
 * @property {SetIntegrationOptions} setOptions
 * @property {module:useIntegrationScript~IntegrationOptions} options
 */

/**
 * @function
 * @category Shared
 * @subcategory Hooks
 * @description Updates a script string when options change
 * @param {string} args - an object of options to be passed into the hook
 * @param {string} args.campaignId - The ID of the campaign for this script
 * @param {string} args.publicKey - The public key of the campaign for this script
 * @param {string} args.rawScript - a litteral string of an integration script
 * @returns {module:useIntegrationScript~IntegrationScriptHookReturn}
 */
export const useIntegrationScript = ({
	campaignId,
	publicKey,
	locale,
	rawScript,
	defaults,
}) => {
	const emptyState = {
		productName: '',
		productUrl: '',
		getOrCreateInfluencer: true,
		trackByInvite: true,
		isDiscreet: false,
	};
	const [options, setOptions] = useState(emptyState);

	const resetState = () => setOptions(emptyState);

	// const scriptCampaignId = useMemo(() => campaignId, [campaignId]);
	// const scriptPublicKey = useMemo(() => publicKey, [publicKey]);
	// const scriptLocale = useMemo(() => locale, [locale]);

	const [processedScript, setProcessedScript] = useState(rawScript);

	useEffect(() => {
		setProcessedScript(
			parseScript(
				rawScript,
				{
					campaignId,
					publicKey,
					locale,
					...options,
				},
				{ defaults }
			)
		);
	}, [campaignId, publicKey, rawScript, options, locale, defaults]);

	return {
		processedScript,
		options,
		setOptions,
		resetOptions: resetState,
	};
};

const parseScript = (rawScript = '', variables, options = {}) => {
	const delL = options.delimiterL || '{{';
	const delR = options.delimiterR || '}}';
	const defaults = options.defaults || {};
	return Object.keys(variables).reduce((script, variable) => {
		const value =
			variables[variable] !== undefined && variables[variable] !== ''
				? variables[variable]
				: defaults[variable];
		return script.replace(`${delL}${variable}${delR}`, value);
	}, rawScript);
};
