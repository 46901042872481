import React, { createContext, useContext } from 'react';
import { useHistory, useLocation } from 'react-router';
import { filterProviderFactory } from '../../../../../../../../../../../utils/filterProviderFactory';
import { useQueryString } from '../../../../../../../../../../../utils/hooks';

const { Provider, useFilters } = filterProviderFactory();
/**
 * Rewards filters
 */
export const RewardsFiltersProvider = ({ children }) => {
	/**
	 * @param {RewardsFilters.ControlState} controlValues
	 */
	const mapControlsToState = (controlVals) => {
		const { rewardAmountSwitch, fromDate, toDate, ...state } = controlVals;
		console.log('mapControlsToState RewardFilterProvide', controlVals);

		return {
			rewardDateGTOE: fromDate,
			rewardDateLTOE: toDate,
			...state,
		};
	};

	return (
		<Provider mapControlsToState={mapControlsToState}>{children}</Provider>
	);
};

export const useRewardsFilters = useFilters;
