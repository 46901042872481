import { useStore } from '../hooks';

/**
 * @module useDownloadLink
 * @category Shared
 * @subcategory Hooks
 * @exports  useDownloadLink
 */

/**
 * @callback InitDownloadFn
 * @param {string} [url] a file url
 * @param {string} [fileName] the name of the file
 * @returns {Void}
 */

/**
 * @function
 * @category Shared
 * @subcategory Hooks
 * @description Returns a function that initiates a file download given a file url
 * @returns {module:useDownloadLink~InitDownloadFn}
 */
export const useDownloadLink = () => {
	const store = useStore();
	const downloadLink = document.createElement('a');

	return (url, fileName) => {
		if (url) {
			store.Orchestrators.View.addNotification({
				text: `${fileName} added to Downloads`,
				type: 'emphasis',
			});
		}
		downloadLink.setAttribute('href', encodeURI(url));
		downloadLink.setAttribute('download', fileName);
		document.body.appendChild(downloadLink);
		downloadLink.click();
		document.body.removeChild(downloadLink);
	};
};
