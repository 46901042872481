import { createUseStoreHook } from '@icecreamsocial/mst-react-utils';
import Root from '../../mst/root';
/**
 * @module useStore
 * @category Shared
 * @subcategory Hooks
 */
const store = createUseStoreHook(Root);
/**
 * @function
 * @category Shared
 * @subcategory Hooks
 */
export const StoreProvider = store.Provider;
export const useStore = store.useStore;
