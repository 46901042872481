import React from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { Div, List, Button } from '@icecreamsocial/components';

const SideTabsNav = ({ routes = [] }) => (
	<Div as="aside" elWidth={['200px']} flexShrink="0">
		<List bullets="none" position="sticky" positionTop="16px">
			{routes.map(({ to, active, text, disabled }, i) => (
				<List.Item key={i}>
					<Button
						as={disabled ? undefined : Link}
						to={to}
						textColor={active ? 'body' : disabled ? 'ambient' : 'link'}
						hoverShadow={disabled ? 'none' : 'raised'}
						textTransform="capitalize"
						textAlign="left"
						elWidth="100%"
						backgroundColor={active ? 'ambient' : 'transparent !important'}
						shadow="none"
						disabled={disabled}
					>
						{text}
					</Button>
				</List.Item>
			))}
		</List>
	</Div>
);

SideTabsNav.displayName = 'SideTabsNav';

export default observer(SideTabsNav);
