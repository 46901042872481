import { types } from 'mobx-state-tree';
import { IcsInfluencer } from '@icecreamsocial/mst-models';
import { Pagination } from '../../models';

const InfluencersCollection = types
	.compose(
		Pagination,
		types.model({
			influencerMap: types.map(IcsInfluencer),
			list: types.array(types.reference(IcsInfluencer)),
		})
	)
	.named('InfluencersCollection')
	.preProcessSnapshot((snapshot = {}) => ({
		influencerMap: snapshot.influencerMap || {},
		list: snapshot.list || [],
	}))
	.views((self) => ({
		get hasInfluencers()
		{
			return !!self.list.length;
		},
		find(id)
		{
			return self.list.find((ref) => ref.id === id);
		},
	}))
	.actions((self) => ({
		addInfluencer(influencer)
		{
			self.influencerMap.put(influencer);
		},
		addInfluencers(influencers = [])
		{
			influencers.forEach((influencer) => self.addInfluencer(influencer));
		},
		setInfluencers(influencers = [])
		{
			try
			{
				self.list.replace([]); // clean up
				influencers.forEach((influencer) =>
				{
					self.addInfluencer(influencer);
					self.list.push(influencer.id);
				});
			} catch (e)
			{
				console.error(e);
			}
		},
		setInfluencerList(influencerRefs = [])
		{
			self.list.replace(influencerRefs);
		},
		updateInfluencer(id, influencer)
		{
			self.influencerMap.set(id, influencer);
		},
		clearAll()
		{
			self.setInfluencerList();
			self.influencerMap.clear();
		}
	}));

export default InfluencersCollection;
