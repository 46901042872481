import React from "react";
import { Panel, Typography, Div } from "@icecreamsocial/components";
import { StripeLogo } from "../../../../../presentational";

const StripeDescription = () => {
  return (
    <Panel marginBottom="md" backgroundColor="bodyInverse">
      <Panel.Body display="flex" alignItems="center" style={{ gap: "16px" }}>
        <StripeLogo />
        <Div flex="1 0">
          <Typography.H4
            display="block"
            typoSize="lg"
            typoWeight="400"
            marginTop="0"
            marginBottom="md"
          >
            Stripe
          </Typography.H4>
          <Typography.Span display="block">
            Reward influencers with your Stripe account
          </Typography.Span>
        </Div>
      </Panel.Body>
    </Panel>
  );
};

StripeDescription.displayName = "StripeDescription";

export default StripeDescription;
