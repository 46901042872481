import React, { useEffect, useRef, useState } from 'react';

import { observer } from 'mobx-react-lite';
import {
	Link,
	Switch,
	Redirect,
	Route,
	useRouteMatch,
	useLocation,
	useParams,
	useHistory,
} from 'react-router-dom';
import { Stepper, Step, StepLabel } from '@material-ui/core';
import {
	Container,
	Div,
	Spanner,
	Typography,
} from '@icecreamsocial/components';
import { CloseIcon, RouteChangeBlocker } from '../../../../presentational';
import { HeaderPortal } from '../../../Main';
import GeneralSettings from './sub-routes/GeneralSettings';
import OfferSettings from './sub-routes/OfferSettings';
import ShareSettings from './sub-routes/ShareSettings';
import PolicySettings from './sub-routes/PolicySettings';
import {
	useStore,
	useRouteConfig,
	useScrollTop,
} from '../../../../../utils/hooks';

const CreateCampaign = () => {
	const store = useStore();
	const campaignFormRef = useRef({});
	const { path, url } = useRouteMatch();
	const history = useHistory();
	const { pathname } = useLocation();
	const [navigateMessage, setNavigateMessage] = useState({
		title: '',
		message: '',
		confirmText: '',
		cancelText: '',
	});

	useScrollTop([pathname]);

	useEffect(() => {
		store.Orchestrators.View.setBlockRouteChange(true);
	}, [pathname]);

	const resetNavigateMessage = () => {
		const title = 'Exit Campaign Setup?';
		const message = "Your progress won't be saved";
		const confirmText = 'Discard Changes & Exit';
		const cancelText = 'Continue Setup';

		setNavigateMessage({ title, message, confirmText, cancelText });
		store.Orchestrators.View.setBlockRouteChange(true);
	};

	/**
	 * update the route message whenever the pathname changes
	 */
	useEffect(() => {
		resetNavigateMessage();
	}, [pathname]);

	const activeStep = determineStepFromPath(pathname);
	const handlePartialFormSubmit = (values, nextRoute) => {
		const name = values.campaignName || campaignFormRef.current.name;
		campaignFormRef.current = { ...campaignFormRef.current, name, ...values };
		store.Orchestrators.View.setBlockRouteChange(false);
		history.push(nextRoute);
	};
	const handleFinalSubmit = async (values) => {
		campaignFormRef.current = { ...campaignFormRef.current, ...values };
		const campaign = await store.createCampaignFlow(campaignFormRef.current);
		store.Orchestrators.View.setBlockRouteChange(false);
		history.push(`/campaigns/view/${campaign.id}/integrations`);
	};

	const createBackHandler = (returnTo) => () => {
		const title = 'Go to Previous Step?';
		const message = "Your progress on the current step won't be saved";
		const confirmText = 'Discard Changes & Go Back a Step';
		const cancelText = 'Continue Current Step';
		setNavigateMessage({ title, message, confirmText, cancelText });
		history.push(returnTo);
		store.Orchestrators.View.setBlockRouteChange(true);
	};

	const { routes, links } = useRouteConfig([
		{
			component: () => <Redirect to={`${path}/general`} />,
		},
		{
			path: `general`,
			name: `General`,
			component: () => (
				<GeneralSettings
					handleSubmit={(values) =>
						handlePartialFormSubmit(values, `${path}/offer`)
					}
					returnTo={() => history.push('/campaigns')}
					savedValues={{
						locale: store.Orchestrators.Auth.locale,
						campaignName: campaignFormRef.current?.name,
						fromName: store.userBusiness?.name,
						...campaignFormRef.current,
					}}
				/>
			),
		},
		{
			path: `offer`,
			name: `Offer`,
			component: () => (
				<OfferSettings
					handleSubmit={(values) =>
						handlePartialFormSubmit(values, `${path}/share`)
					}
					returnTo={createBackHandler(`${path}/general`)}
					savedValues={{ ...campaignFormRef.current }}
				/>
			),
		},
		{
			path: `share`,
			name: `Share`,
			component: observer(() => (
				<ShareSettings
					handleSubmit={(values) =>
						handlePartialFormSubmit(values, `${path}/data-and-policy`)
					}
					returnTo={createBackHandler(`${path}/offer`)}
					disabled={store.isLoading('createCampaign')}
				/>
			)),
		},
		{
			path: `data-and-policy`,
			name: `Data & Policy`,
			component: observer(() => (
				<PolicySettings
					handleSubmit={(values) => handleFinalSubmit(values)}
					returnTo={createBackHandler(`${path}/share`)}
					isLoading={store.isLoading('createCampaign')}
					disabled={store.isLoading('createCampaign')}
					savedValues={{
						termsAndConditionsURL: store.userBusiness?.termsAndConditionsURL,
						privacyPolicyURL: store.userBusiness?.privacyPolicyURL,
					}}
				/>
			)),
		},
	]);

	useEffect(() => {
		store.Orchestrators.View.setSingleColumn();
		return () => {
			store.Orchestrators.View.setSingleColumn(false);
		};
	}, []);

	return (
		<>
			<HeaderPortal>
				<Spanner
					grid
					gridColumns="repeat(3, 1fr)"
					backgroundColor="brandDark"
					textColor="bodyInverse"
				>
					<Typography.Span
						as={Link}
						to="/campaigns"
						textColor="bodyInverse"
						display="flex"
						alignItems="center"
					>
						<CloseIcon marginRight="md" /> Cancel Setup
					</Typography.Span>
				</Spanner>
			</HeaderPortal>
			<Container>
				<Div
					as={Stepper}
					activeStep={activeStep}
					backgroundColor="transparent !important"
				>
					{links.slice(1).map(({ text }, i) => (
						<Step key={i}>
							<StepLabel>{text}</StepLabel>
						</Step>
					))}
				</Div>
				<Switch>
					{routes.map(({ link, ...route }, i) => (
						<Route key={i} {...route} />
					))}
				</Switch>
			</Container>
			<RouteChangeBlocker
				when={store.Orchestrators.View.blockRouteChange}
				title={navigateMessage.title}
				message={navigateMessage.message}
				confirmText={navigateMessage.confirmText}
				cancelText={navigateMessage.cancelText}
				beforeCancelNavigation={resetNavigateMessage}
			/>
		</>
	);
};
CreateCampaign.displayName = 'CreateCampaign';

function determineStepFromPath(path) {
	const steps = {
		general: 0,
		offer: 1,
		share: 2,
		'data-and-policy': 3,
	};
	const key = path.split('/create/')[1];

	return steps[key];
}
export default observer(CreateCampaign);
