import { types } from 'mobx-state-tree';
import { IcsReward } from '@icecreamsocial/mst-models';
import { Pagination } from '../../models';

const RewardsCollection = types
	.compose(
		Pagination,
		types.model({
			rewardMap: types.map(IcsReward),
			list: types.array(types.reference(IcsReward)),
		})
	)
	.named('RewardsCollection')
	.preProcessSnapshot((snapshot = {}) => ({
		rewardMap: snapshot.rewardMap || {},
		list: snapshot.list || [],
	}))
	.views((self) => ({
		get hasRewards() {
			return !!self.list.length;
		},
		find(id) {
			return self.list.find((ref) => ref.id === id);
		},
	}))
	.actions((self) => ({
		addReward(reward) {
			self.rewardMap.put(reward);
		},
		addRewards(rewards = []) {
			rewards.forEach((reward) => self.addReward(reward));
		},
		setRewards(rewards = []) {
			try {
				self.list.replace([]); // clean up
				rewards.forEach((reward) => {
					self.addReward(reward);
					self.list.push(reward.id);
				});
			} catch (e) {
				console.error(e);
			}
		},
		setRewardList(rewardRefs = []) {
			self.list.replace(rewardRefs);
		},
		updateReward(id, reward) {
			self.rewardMap.set(id, reward);
		},
		clearAll() {
			self.setRewardList();
			self.rewardMap.clear();
		},
	}));

export default RewardsCollection;
