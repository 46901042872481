import React from 'react';
import { Img } from '@icecreamsocial/components';
import { observer } from 'mobx-react-lite';
import loader from '../../../assets/loader_ics.gif';

const PopsicleLoader = ({ ...rest }) => {
	return (
		<Img
			src={loader}
			elWidth="100px"
			elHeight="100px"
			borderRadius="50%"
			{...rest}
		/>
	);
};

PopsicleLoader.displayName = 'PopsicleLoader';
export default observer(PopsicleLoader);
