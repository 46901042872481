import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory, Link } from 'react-router-dom';
import {
	Button,
	Div,
	Container,
	Spanner,
	Panel,
	Typography,
} from '@icecreamsocial/components';
import { HeaderPortal } from '../Main';
import {
	useStore,
	useCookies,
	useQueryString,
	useStripeConnect,
} from '../../../utils/hooks';
import {
	CloseIcon,
	PageMeta,
	PopsicleLoader,
	Pagename,
	CheckIcon,
} from '../../presentational';

// import { BusinessStripeConnectForm } from '../../presentational';

/**
 * The page where all stripe oauth connections are redirected to.  The only errors that will occur here
 * are when the oauth access is denied or there is an issue creating the processor.
 *
 * The processor will immediately be created on success and redirect them back to where they initiated the oauth
 * flow (business setup or business settings) determined by the stripeConnectOrigin cookie.  If no cookie is set,
 * will default to the reward processor page in the business settings.
 *
 * On access_denied, will prompt user to reconnect and allow permissions or to go back and choose another processor
 * On creation error, say that something went wrong and provide a link to return to setup or settings
 *
 * @returns {void}
 */
const StripeConnect = () => {
	// initial hooks
	const store = useStore();
	const history = useHistory();
	const successTimeoutRef = useRef(null);

	const {
		authorizationCode,
		stateParam,
		error,
		redirectURL,
		handleOAuthRedirect,
		cleanCookie,
	} = useStripeConnect(undefined, true);

	/**
	 * Creates a timeout with a reference so that we can redirect the user after 5 seconds on success
	 */
	const successRedirectTimeout = () => {
		successTimeoutRef.current = setTimeout(() => {
			history.replace(redirectURL);
		}, 5000);
	};

	/**
	 * Creates processor only if there is an authorization code and there is
	 * no existing processor
	 * @returns {void}
	 */
	const handleCreation = async () => {
		// exit because there was an error from oauth
		if (!authorizationCode) return;

		// exit because processor already exists
		if (!store.userProcessorId) {
			await store.createStripeProcessorFlow({
				authorizationCode,
				state: stateParam,
			});
		}

		successRedirectTimeout();
	};

	// effect for the view.  only run once
	useLayoutEffect(() => {
		store.Orchestrators.View.setSingleColumn();
		return () => {
			store.Orchestrators.View.setSingleColumn(false);
		};
	}, []);

	// effect for creating the processor
	useEffect(() => {
		handleCreation();
		// clean up the cookie
		return () => {
			cleanCookie();
			clearTimeout(successTimeoutRef.current);
		};
	}, []);

	// effect for cleaning up errors
	useEffect(
		() => () => {
			store.Orchestrators.View.removeError('creatingStripeProcessor');
			store.Orchestrators.View.removeError('creatingProcessor');
		},
		[]
	);

	return (
		<>
			<PageMeta
				title={'Stripe Connect | Ice Cream Social'}
				description={'Finalize Stripe Connection'}
			/>
			<HeaderPortal>
				<Spanner
					grid
					gridColumns="repeat(3, 1fr)"
					backgroundColor="brandDark"
					textColor="bodyInverse"
				>
					<Typography.Span
						as={Link}
						to={redirectURL}
						textColor="bodyInverse"
						display="flex"
						alignItems="center"
					>
						<CloseIcon marginRight="md" /> Cancel Setup
					</Typography.Span>
					<Typography.Span textAlign="center">Stripe Connect</Typography.Span>
				</Spanner>
			</HeaderPortal>
			<Container>
				<Pagename marginTop="lg">Finalize Stripe Processor Setup</Pagename>
				{store.isLoading('creatingStripeProcessor') ? (
					<Panel marginTop="xlg" backgroundColor="bodyInverse">
						<Panel.Body textAlign="center">
							<PopsicleLoader />
							<Typography.P marginTop="none">
								Setting up your Stripe processor...
							</Typography.P>
						</Panel.Body>
					</Panel>
				) : store.hasErrors('creatingStripeProcessor') ||
				  store.hasErrors('creatingProcessor') ? (
					<Panel marginTop="xlg" backgroundColor="warningLight" border="none">
						<Panel.Body textAlign="center">
							<Typography.H1 typoWeight="500">
								Whoops! Something unexpected happened
							</Typography.H1>
							<Typography.P>
								{store.getErrors('creatingProcessor')}
							</Typography.P>
							<Typography.P>
								{store.getErrors('creatingStripeProcessor')}
							</Typography.P>
							<Button
								as={Link}
								to={redirectURL}
								textDecoration="none"
								textColor="body"
								backgroundColor="ambientLight"
							>
								Return to Setup
							</Button>
						</Panel.Body>
					</Panel>
				) : (
					<>
						{error === 'access_denied' ? (
							<Panel backgroundColor="bodyInverse" marginBottom="xlg">
								<Panel.Header backgroundColor="transparent" padding="0">
									<Typography.H2
										textColor="body"
										typoWeight="500"
										textAlign="center"
									>
										Please allow permissions
									</Typography.H2>
								</Panel.Header>
								<Panel.Body>
									<Typography.P>
										We need permission to process rewards using Stripe. We will
										not sell your information and will only be used to
										automatically process rewards on your behalf.
									</Typography.P>
									<Div display="flex" flexDirection="column">
										<Button
											onClick={async (e) => await handleOAuthRedirect(e)}
											backgroundColor="brand"
											textColor="bodyInverse"
											margin="auto"
											marginBottom="lg"
										>
											Connect Stripe Account
										</Button>
										<Typography.A
											as={Link}
											to={redirectURL}
											textColor="ambientDark"
											alignItems="center"
											margin="auto"
											marginBottom="xlg"
										>
											Cancel Setup
										</Typography.A>
									</Div>
								</Panel.Body>
							</Panel>
						) : (
							<Panel backgroundColor="bodyInverse" marginBottom="xlg">
								<Panel.Header
									display="flex"
									alignItems="center"
									justifyContent="center"
									backgroundColor="good"
									padding="0"
									style={{
										gap: '12px',
									}}
								>
									<CheckIcon size={36} />
									<Typography.H2
										textColor="bodyInverse"
										typoWeight="500"
										textAlign="center"
									>
										You're connected!
									</Typography.H2>
								</Panel.Header>
								<Panel.Body textAlign="center">
									<Typography.P margin="none">
										Your Stripe processor has successfully been connected. You
										will automatically be redirected to your settings in 5
										seconds...
									</Typography.P>
									<Button
										as={Link}
										to={redirectURL}
										textColor="body"
										visitedTextColor="body"
										backgroundColor="ambientLight"
										textDecoration="none"
										margin="auto"
									>
										Back to Settings
									</Button>
								</Panel.Body>
							</Panel>
						)}
					</>
				)}
			</Container>
		</>
	);
};
StripeConnect.displayName = 'StripeConnect';

export default observer(StripeConnect);
