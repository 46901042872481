import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Div, Typography } from '@icecreamsocial/components';
import {
	BusinessBrandForm,
	Pagename,
	SteppedFormControls,
} from '../../../../presentational';

const Brand = ({ handleSubmit, returnTo, savedValues = {} }) => {
	return (
		<Div>
			<Pagename>Brand &amp; Appearance</Pagename>
			<Typography.P>
				A few settings about your brand will help us make sure Ice Cream Social
				fits right into your online ecosystem
			</Typography.P>
			<BusinessBrandForm
				onSubmit={handleSubmit}
				renderControls={() => <SteppedFormControls onNav={returnTo} />}
				allowRouteBlock={false}
				{...savedValues}
			/>
		</Div>
	);
};
Brand.displayName = 'BusinessSetUp.Brand';

export default observer(Brand);
