import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { InputGroup, Panel, Typography } from '@icecreamsocial/components';
import { EnhancedForm, RequiredAsterisk } from '../';
import { AffiliateSelector } from '../../containers';

const AffiliateSettings = ({
	isEditing = false,
	allowRouteBlock = true,
	initialValues,
	onSubmit,
	userAffiliateId,
	renderControls,
}) => {
	const formikProps = {
		enableReinitialize: true,
		initialValues,
		onSubmit,
	};

	return (
		<>
			<EnhancedForm
				formikProps={formikProps}
				promptIf={({ dirty }) => allowRouteBlock && dirty}
				renderControls={renderControls}
			>
				{(affiliateSettings) => (
					<>
						<Panel backgroundColor="bodyInverse" marginBottom="lg">
							<Panel.Header backgroundColor="bodyInverse" paddingBottom="0">
								<Typography.H2
									textColor="body"
									textAlign="center"
									margin="none"
									typoSize="md"
								>
									Affiliate Profile
								</Typography.H2>
							</Panel.Header>
							<Panel.Body>
								<InputGroup elWidth="100%" marginBottom="sm">
									<InputGroup.Label>
										Name <RequiredAsterisk />
									</InputGroup.Label>
									<InputGroup.Fields>
										<InputGroup.Input
											id="name"
											name="name"
											value={affiliateSettings.values.name}
											onChange={affiliateSettings.handleChange}
											paddingLeft="md"
											autoFocus
											required
										/>
									</InputGroup.Fields>
								</InputGroup>
							</Panel.Body>
						</Panel>
						<Panel backgroundColor="bodyInverse" marginBottom="lg">
							<Panel.Header backgroundColor="bodyInverse" paddingBottom="0">
								<Typography.H2
									textColor="body"
									textAlign="center"
									margin="none"
									typoSize="md"
								>
									Parent Affiliate
								</Typography.H2>
							</Panel.Header>
							<Panel.Body>
								<InputGroup elWidth="100%" marginBottom="sm">
									<InputGroup.Label>
										Parent Affiliate <RequiredAsterisk />
									</InputGroup.Label>
									<AffiliateSelector
										as="select"
										id="parentId"
										name="parentId"
										affiliateId={userAffiliateId}
										value={affiliateSettings.values.parentId}
										onChange={affiliateSettings.handleChange}
										disabled={isEditing}
										readOnly={isEditing}
										includeParent={isEditing}
										paddingLeft="md"
										required
									/>
								</InputGroup>
							</Panel.Body>
						</Panel>
					</>
				)}
			</EnhancedForm>
		</>
	);
};
AffiliateSettings.displayName = 'AffiliateSettingsForm';

export default observer(AffiliateSettings);
