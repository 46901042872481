import { types } from 'mobx-state-tree';
import {
	IcsUser,
	IcsCompany,
	DateRange,
	IcsStat,
} from '@icecreamsocial/mst-models';
import { formatISO, subMonths } from 'date-fns';

const DashboardStats = types
	.model({
		DateRange,
		affiliateId: types.maybe(types.string),
		clientId: types.maybe(types.string),
		campaignId: types.maybe(types.string),
		StatOverview: types.maybe(IcsStat),
	})
	.named('DashboardStatsOrchestrator')
	.preProcessSnapshot((snapshot = {}) => ({
		...snapshot,
		DateRange: snapshot.DateRange || {
			From: formatISO(subMonths(Date.now(), 1)),
			To: formatISO(Date.now()),
		},
	}))
	.volatile((self) => ({
		focusedCampaign: undefined,
	}))
	.actions((self) => ({
		setFocusedCampaign(campaignId = undefined) {
			self.focusedCampaign = campaignId;
		},
		setDateRange({ from: From, to: To }) {
			self.DateRange.setRange({ From, To });
		},
		setFilters({
			affiliateId,
			clientId,
			campaignId,
		}) {
			self.affiliateId = affiliateId;
			self.clientId = clientId;
			self.campaignId = campaignId;
		},
		setStatOverview(stats) {
			self.StatOverview = stats;
		},
	}));

export default DashboardStats;
