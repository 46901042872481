import { types, SnapshotIn } from 'mobx-state-tree';
import { Notifications } from '../../models';

const View = types
	.model({
		singleColumn: false,
		fromPersistedState: false,
		isLoading: types.map(types.boolean),
		hasErrors: types.map(types.string),
		notifications: Notifications,
	})
	.named('ViewOrchestrator')
	.volatile((self) => ({
		blockRouteChange: false,
	}))
	.actions((self) => ({
		addNotification(notification) {
			self.notifications.add(notification);
		},
		setSingleColumn(isSingleColumn = true) {
			self.singleColumn = isSingleColumn;
		},
		setIsLoading(what, value = true) {
			self.isLoading.set(what, value);
		},
		setHasErrors(what, value = '') {
			self.hasErrors.set(what, value);
		},
		setBlockRouteChange(block = false) {
			self.blockRouteChange = block;
		},
		removeError(what) {
			self.hasErrors.delete(what);
		},
		clearAllErrors() {
			self.hasErrors.clear();
		},
		clearAllLoading() {
			self.isLoading.clear();
		},
	}));

export default View;
