"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ic_file_upload = void 0;
var ic_file_upload = {
  "viewBox": "0 0 24 24",
  "children": [{
    "name": "path",
    "attribs": {
      "d": "M9 16h6v-6h4l-7-7-7 7h4zm-4 2h14v2H5z"
    }
  }]
};
exports.ic_file_upload = ic_file_upload;