import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory, Link } from 'react-router-dom';
import { Div, Spanner, Typography } from '@icecreamsocial/components';
import { useStore } from '../../../../../utils/hooks';
import { HeaderPortal } from '../../../Main';
import {
	ArrowBackIcon,
	ClientSettingsForm,
	EditFormControls,
	RouteChangeBlocker,
} from '../../../../presentational';

const CreateClient = (props) => {
	const store = useStore();
	const history = useHistory();

	const [allowNavigation, setAllowNavigation] = useState(false);

	// Focus current authenticated user so that their parent affiliate will be used as defaults
	const userId = store.Orchestrators.Auth.user.id;

	useEffect(() => {
		store.resolveUserFlow(userId);

		return () => store.resolveUserFlowCleanUp();
	}, [store.Orchestrators.Auth.user.id]);

	const handleSubmit = async (values) => {
		await store.createClientFlow(values);

		if (props.nextPage) {
			setAllowNavigation(true);
			history.push(props.nextPage);
		}
	};

	return (
		<>
			<HeaderPortal>
				<Div backgroundColor='#EBEBEB' textAlign='center'>
					<Spanner grid gridColumns='repeat(3, 1fr)'>
						<Typography.Span
							as={Link}
							to='/clients'
							textColor='body'
							display='flex'
							alignItems='center'
						>
							<ArrowBackIcon marginRight='md' /> Back
						</Typography.Span>
						<Typography.Span>New Client</Typography.Span>
					</Spanner>
				</Div>
			</HeaderPortal>
			<Div marginTop='lg'>
				<ClientSettingsForm
					onSubmit={handleSubmit}
					affiliateId={store.userParentAffiliateId}
					renderControls={(props) => (
						<EditFormControls
							isLoading={store.isLoading('clientSettings')}
							disabled={store.isLoading('clientSettings')}
							{...props}
						/>
					)}
					alwaysShowSubmit={true}
					allowRouteBlock={false}
				/>
			</Div>
			<RouteChangeBlocker
				when={!allowNavigation}
				title='Abandon Client?'
				message='This client will not be created'
				confirmText='Abandon Client'
				cancelText='Continue Client Creation'
			/>
		</>
	);
};

CreateClient.displayName = 'Clients.Create';

export default observer(CreateClient);
