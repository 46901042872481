import React from 'react';
import { observer } from 'mobx-react-lite';
import { Div } from '@icecreamsocial/components';

const OptimisticChart = ({ ...rest }) => (
	<Div
		optimistic
		elHeight="8px"
		elWidth="100%"
		borderRadius="default"
		{...rest}
	/>
);
OptimisticChart.displayName = 'OptimisticChart';

export default observer(OptimisticChart);
