import React, { useCallback } from 'react';
import { useRouteMatch, matchPath } from 'react-router-dom';

/**
 * @module useRouteConfig
 * @category Shared
 * @subcategory Hooks
 * @exports  useRouteConfig
 */

/**
 * Used for creating routes and links for those routes.  Useful for sub-routes
 *
 * @typedef RouteConfig
 * @property {string} [path]  the path to route to.  will default to the current relative path.  if absolute, will default to '/'
 * @property {React.FunctionComponent} component the component to show at this route
 * @property {boolean} [exact = true] whether the route is exact or not
 * @property {boolean} [strict = true] whether it should count trailing slashes
 * @property {boolean} [isAbsolute = false] whether or not the link should be an absolute route.  if true, it will be a root route.  Useful if configuring sub-routes and routes to higher levels of navigation at the same time
 * @property {boolean} [disableLink = false] whether or not the link should be disabled
 * @property {string} [linkText = undefined] what the text for the link should be
 * @property {string} [name = undefined] optional name. not really used. if no link text, it will try and use name
 * @property {React.FunctionComponent} [icon = undefined] a component for an icon.  useful for main navigation
 */
/**
 * @typedef RouteLinkProps
 * @property {boolean} active
 * @property {boolean} disabled
 * @property {string} text
 * @property {string} linkPath
 * @property {React.FunctionComponent} icon
 */
/**
 * @typedef RouteObj
 * @property {string} name
 * @property {string} routePath
 * @property {boolean} exact
 * @property {boolean} strict
 * @property {React.FunctionComponent} component
 */

/**
 * @function
 * @param {Array.<module:useRouteConfig~RouteConfig>} settings
 * @returns {*} an object containing routes and link configs
 */
export const useRouteConfig = (settings = []) => {
	const { path: relativePath, url } = useRouteMatch();
	const mapper = useCallback(
		({
			isAbsolute,
			linkText,
			component,
			icon,
			name,
			path = undefined,
			exact = true,
			strict = true,
			disableLink = false,
		}) => {
			const routePath = isAbsolute
				? path
				: path
				? `${relativePath}/${path}`
				: relativePath;
			const linkPath = isAbsolute ? path : path ? `${url}/${path}` : url;
			const active = !!useRouteMatch(routePath);

			return {
				exact,
				strict,
				component,
				name,
				path: routePath,
				link: {
					active,
					icon,
					disabled: disableLink,
					text: linkText || name,
					to: linkPath,
				},
			};
		},
		[settings, url]
	);
	const routes = settings.map(mapper);

	const links = routes.map(useCallback(({ link }) => link, [routes]));

	return { routes, links };
};
