import { types, flow, SnapshotIn, SnapshotOut } from 'mobx-state-tree';
import { IcsCompany } from '@icecreamsocial/mst-models';
import { Business } from './models';
import Communicators from './Communicators';
import Collections from './Collections';
import Orchestrators from './Orchestrators';
import {
	capitalize,
	exportToCSV,
	formatImageURL,
	flags,
	toggle,
} from '../utils';
import icsLoader from '../assets/loader_ics.gif';

const V2Options = {
	transformResponse: ({ data }) => ({
		...data.data,
		success: data.success,
		message: data.message,
	}),
	transformErrorResponse: ({ data, status }) => ({
		...data.response,
		status,
	}),
};

const V1Options = {
	transformResponse: ({ data }) => data, //data.data.user,
	transformErrorResponse: ({ data, status }) => ({
		...data,
		status,
	}),
};

/**
 * temporary extraction layer to toggle between V1 and V2 responses
 * @param {FeatureFlags} flag
 * @param {*} extra
 * @returns {*}
 */
const APIOptionsAdapter = (flag, extra = {}) =>
	toggle(flag, { ...V2Options, ...extra }, { ...V1Options, ...extra });

/**
 * @module RootStore
 * @category Shared
 * @subcategory Models
 * @description
 * This is the main store for the admin's state management.  Controls all fetching and data flow
 */

const RootStore = types
	.model({
		Collections,
		Communicators,
		Orchestrators,
	})
	.named('RootStore')
	.preProcessSnapshot((snapshot = {}) => ({
		...snapshot,
		Collections: snapshot.Collections || {},
		Communicators: snapshot.Communicators || {},
		Orchestrators: snapshot.Orchestrators || {},
	}))
	.volatile((self) => ({
		isCreatingProcessor: false,
		loginError: undefined,
		error: undefined,
	}))
	.views((self) => ({
		/**
		 * @static
		 * @type {boolean}
		 * @description
		 * If the user is authenticated.  Determined by the *Auth Orchestrator*
		 */
		get isAuthenticated() {
			return self.Orchestrators.Auth.isAuthenticated;
		},
		/**
		 * @static
		 * @type {object}
		 * @description
		 * Used to display a summary of data on the dashboard
		 */
		get dashboardStatsOverview() {
			return self.Orchestrators.DashboardStats.StatOverview;
		},
		/**
		 * @static
		 * @type {string}
		 * @description
		 * The logged in user's id. Determined by the *Auth Orchestrator*
		 */
		get userId() {
			return self.Orchestrators.Auth.userId;
		},
		/**
		 * @static
		 * @type {string}
		 * @description
		 * The logged in user's initials. Determined by the *Auth Orchestrator*
		 */
		get userInitials() {
			return self.Orchestrators.Auth.userInitials;
		},
		/**
		 * @static
		 * @type {string}
		 * @description
		 * The logged in user's name. Determined by the *Auth Orchestrator*
		 */
		get userName() {
			return self.Orchestrators.Auth.userName;
		},
		/**
		 * @static
		 * @type {string}
		 * @description
		 * The logged in user's role. Determined by the *Auth Orchestrator*
		 */
		get userRole() {
			return self.Orchestrators.Auth.role;
		},
		/**
		 * @static
		 * @type {string | null}
		 * @description
		 * The logged in user's clientId. If null, then the user is __probably__ an affiliate user. Determined by the *Auth Orchestrator*
		 */
		get userClientId() {
			return self.Orchestrators.Auth.clientId;
		},
		/**
		 * @static
		 * @type {string}
		 * @description
		 * The logged in user's parent affiliate. Determined by the *Auth Orchestrator*
		 */
		get userParentAffiliateId() {
			return self.Orchestrators.Auth.parentAffiliateId;
		},
		/**
		 * @static
		 * @type {boolean}
		 * @description
		 * If
		 * 		you are a client **AND** you have **NO** business **OR** your business is **NOT** configured,
		 * then
		 * 		you need business setup
		 */
		get needsBusinessSetup() {
			return (
				self.isClient &&
				(!self.userBusiness || !self.userBusiness?.isConfigured)
			);
		},
		/**
		 * @static
		 * @type {object}
		 * @description
		 * The logged in user's business. Determined by the *Auth Orchestrator*
		 */
		get userBusiness() {
			return self.Orchestrators.Auth.business;
		},
		/**
		 * @static
		 * @type {string}
		 * @description
		 * The logged in user's business ID. Determined by the *Auth Orchestrator*
		 */
		get userBusinessId() {
			return self.Orchestrators.Auth.businessId;
		},
		/**
		 * @static
		 * @type {string}
		 * @description
		 * The logged in user's business' logo URL. Determined by the *Auth Orchestrator*
		 */
		get userBusinessLogo() {
			return self.userBusiness && formatImageURL(self.userBusiness?.logo);
		},
		/**
		 * @static
		 * @type {boolean}
		 * @description
		 * Whether or not the user's business has all necessary data. Determined by the *Auth Orchestrator*
		 */
		get isUserBusinessSetupComplete() {
			return self.userBusiness?.isSetupComplete;
		},
		/**
		 * @static
		 * @type {boolean}
		 * @description
		 * Whether or not the user can modify other users. Determined by the *Auth Orchestrator*
		 */
		get isTeamLeader() {
			return self.Orchestrators.Auth.isTeamLeader;
		},
		/**
		 * @static
		 * @type {string}
		 * @description
		 * The logged in user's business processor. Determined by the *Auth Orchestrator* and *Processor Collection*
		 */
		get userProcessor() {
			return self.getProcessor(self.userBusiness?.processorId);
		},
		/**
		 * @static
		 * @type {string}
		 * @description
		 * The logged in user's business' reward processor ID. Determined by the *Auth Orchestrator*
		 */
		get userProcessorId() {
			return self.userBusiness?.processorId;
		},
		/**
		 * @static
		 * @type {('square'| 'nmi')}
		 * @description
		 * The logged in user's reward processor type. Determined by the *Auth Orchestrator* and *Processor Collection*
		 */
		get userProcessorType() {
			return self.getProcessorType(self.userBusiness?.processorId);
		},
		/**
		 * @static
		 * @type {boolean}
		 * @description
		 * Whether or not the logged in user is a client. Determined by the *Auth Orchestrator*
		 */
		get isClient() {
			return !!self.Orchestrators.Auth.clientId;
		},
		/**
		 * @static
		 * @type {boolean}
		 * @deprecated
		 * @description
		 * Whether or not the current session was bootstrapped from persisted storage
		 */
		get wasPersisted() {
			return self.Orchestrators.View.fromPersistedState;
		},
		/**
		 * @static
		 * @function
		 * @param {string} what
		 * @returns {boolean}
		 * @description
		 * Used to determine if a module is loading or not
		 */
		isLoading(what) {
			return self.Orchestrators.View.isLoading.get(what);
		},
		/**
		 * @static
		 * @function
		 * @param {string} what
		 * @returns {boolean}
		 * @description
		 * Used to determine if a module generated or contains an error.
		 */
		hasErrors(what) {
			return self.Orchestrators.View.hasErrors.has(what);
		},
		/**
		 * @static
		 * @function
		 * @param {string} what
		 * @returns {string | undefined}
		 * @description
		 * Gets the error for the what
		 */
		getErrors(what) {
			return self.Orchestrators.View.hasErrors.get(what);
		},
		/**
		 * @static
		 * @function
		 * @param {string} id
		 * @returns {object | undefined}
		 * @description
		 * Gets the processor for the given id.  Determined by the *Processor Collection*
		 */
		getProcessor(id) {
			return self.Collections.RewardProcessors.get(id);
		},
		/**
		 * @static
		 * @function
		 * @param {string} id
		 * @returns {'square' | 'nmi' | undefined | null}
		 * @description
		 * Gets the processor type for the given id.  Determined by the *Processor Collection*
		 */
		getProcessorType(id) {
			return self.getProcessor(id)?.type;
		},
		/**
		 * @static
		 * @function
		 * @param {string} id
		 * @returns {object}
		 * @description
		 * Gets the business for the given id.  Determined by the *Business Collection*
		 */
		getBusiness(id) {
			return self.Collections.Businesses.get(id);
		},
	}))
	/** api requests */
	.actions((self) => ({
		createAffiliate({ body }) {
			const api = self.Communicators.AffiliatesAPIV2.createAnAffiliate;
			return self.Communicators.createRequest(api)
				.withHeaders({ Authorization: self.Orchestrators.Auth.authHeader })
				.withArgs({
					...body,
				})
				.withOptions({
					transformResponse: ({ data }) => ({
						...data.data,
						success: data.success,
						message: data.message,
					}),
					transformErrorResponse: ({ data, status }) => ({
						...data.response,
						status,
					}),
				})
				.call();
		},
		createCampaign(formData) {
			const api = self.Communicators.CampaignsAPIV2.createCampaign;
			const options = { ...V2Options, maxAge: -1 };

			return self.Communicators.createRequest(api)
				.withHeaders({ Authorization: self.Orchestrators.Auth.authHeader })
				.withArgs({
					formData,
				})
				.withOptions(options)
				.call();
		},
		createClient({ body }) {
			//const api = self.Communicators.ClientsAPI.createClient;
			const api = self.Communicators.ClientsAPIV2.createAClient;
			return self.Communicators.createRequest(api)
				.withHeaders({ Authorization: self.Orchestrators.Auth.authHeader })
				.withArgs({
					...body,
				})
				.withOptions({
					transformResponse: ({ data }) => ({
						...data.data,
						success: data.success,
						message: data.message,
					}),
					transformErrorResponse: ({ data, status }) => ({
						...data.response,
						status,
					}),
					maxAge: -1,
				})
				.call();
		},
		createClientUseCase({ clientId }) {
			const api = self.Communicators.ClientsAPIV2.createClientUseCase;
			return self.Communicators.createRequest(api)
				.withHeaders({ Authorization: self.Orchestrators.Auth.authHeader })
				.withArgs({
					clientId,
				})
				.withOptions({
					transformResponse: ({ data }) => ({
						...data.data,
						success: data.success,
						message: data.message,
					}),
					transformErrorResponse: ({ data, status }) => ({
						...data.response,
						status,
					}),
					maxAge: -1,
				})
				.call();
		},
		createProcessor({ clientId, companyId, body }) {
			const api = self.Communicators.ProcessorsAPIV2.createProcessor;

			return self.Communicators.createRequest(api)
				.withHeaders({ Authorization: self.Orchestrators.Auth.authHeader })
				.withArgs({
					clientId,
					companyId,
					...body,
				})
				.withOptions(V2Options)
				.call();
		},
		createUser({ body }) {
			const api = self.Communicators.UsersAPIV2.createsAdminUser;
			const options = V2Options;

			return self.Communicators.createRequest(api)
				.withHeaders({ Authorization: self.Orchestrators.Auth.authHeader })
				.withArgs({
					...body,
				})
				.withOptions(options)
				.call();
		},
		deleteCampaign({ campaignId }) {
			//const api = self.Communicators.CampaignsAPI.deleteCampaign;
			const api = self.Communicators.CampaignsAPIV2.deleteCampaign;
			return self.Communicators.createRequest(api)
				.withHeaders({ Authorization: self.Orchestrators.Auth.authHeader })
				.withArgs({
					campaignId,
				})
				.withOptions({
					transformResponse: ({ data }) => ({
						...data.data,
						success: data.success,
						message: data.message,
					}),
					transformErrorResponse: ({ data, status }) => ({
						...data.response,
						status,
					}),
				})
				.call();
		},
		cancelConversion({ campaignId, influencerId, conversionId }) {
			const api = self.Communicators.CampaignsAPIV2.cancelConversion;
			return (
				self.Communicators.createRequest(api)
					//.withBaseUrl(process.env.BASE_API_ENDPOINT)
					.withHeaders({ Authorization: self.Orchestrators.Auth.authHeader })
					.withArgs({
						campaignId,
						influencerId,
						conversionId,
					})
					.withOptions({
						transformResponse: ({ data }) => ({
							...data.data,
							success: data.success,
							message: data.message,
						}),
						transformErrorResponse: ({ data, status }) => ({
							...data.response,
							status,
						}),
					})
					.call()
			);
		},
		deleteConversion({ campaignId, influencerId, conversionId }) {
			//const api = self.Communicators.InfluencersAPI.deleteConversion;
			const api = self.Communicators.CampaignsAPIV2.deleteAConversion;
			return (
				self.Communicators.createRequest(api)
					//.withBaseUrl(process.env.BASE_API_ENDPOINT)
					.withHeaders({ Authorization: self.Orchestrators.Auth.authHeader })
					.withArgs({
						campaignId,
						influencerId,
						conversionId,
					})
					.withOptions({
						transformResponse: ({ data }) => ({
							...data.data,
							success: data.success,
							message: data.message,
						}),
						transformErrorResponse: ({ data, status }) => ({
							...data.response,
							status,
						}),
					})
					.call()
			);
		},
		deleteProcessor({ clientId, companyId, processorId }) {
			const api = self.Communicators.ProcessorsAPIV2.deleteProcessor;
			const options = V2Options;

			return self.Communicators.createRequest(api)
				.withHeaders({ Authorization: self.Orchestrators.Auth.authHeader })
				.withArgs({
					clientId,
					companyId,
					processorId,
				})
				.withOptions(options)
				.call();
		},
		exportInfluencers({
			campaignId,
			search = undefined,
			createdDateGTOE = undefined,
			createdDateLTOE = undefined,
			goalReached = undefined,
			processed = undefined,
			invitesGTOE = undefined,
			invitesLTOE = undefined,
			invitesClickCountGTOE = undefined,
			invitesClickCountLTOE = undefined,
			conversionsGTOE = undefined,
			conversionsLTOE = undefined,
			revenueGTOE = undefined,
			revenueLTOE = undefined,
		}) {
			//const api = self.Communicators.InfluencersAPI.exportInfluencers;
			const api = self.Communicators.CampaignsAPIV2.exportInfluencers;
			return self.Communicators.createRequest(api)
				.withHeaders({ Authorization: self.Orchestrators.Auth.authHeader })
				.withArgs({
					campaignId,
				})
				.withParams({
					search,
					createdDateGTOE,
					createdDateLTOE,
					goalReached,
					processed,
					invitesGTOE,
					invitesLTOE,
					invitesClickCountGTOE,
					invitesClickCountLTOE,
					conversionsGTOE,
					conversionsLTOE,
					revenueGTOE,
					revenueLTOE,
				})
				.withOptions({
					transformResponse: ({ data }) => ({
						...data.data,
						success: data.success,
						message: data.message,
					}),
					transformErrorResponse: ({ data, status }) => ({
						...data.response,
						status,
					}),
				})
				.call();
		},
		exportInvites({
			campaignId,
			search = undefined,
			inviteClickCountGTOE = undefined,
			inviteClickCountLTOE = undefined,
			conversionsGTOE = undefined,
			conversionsLTOE = undefined,
			revenueGTOE = undefined,
			revenueLTOE = undefined,
			invitationDateGTOE = undefined,
			invitationDateLTOE = undefined,
		}) {
			const api = self.Communicators.CampaignsAPIV2.exportInvites;
			const options = V2Options;

			return self.Communicators.createRequest(api)
				.withHeaders({ Authorization: self.Orchestrators.Auth.authHeader })
				.withArgs({
					campaignId,
				})
				.withParams({
					search,
					inviteClickCountGTOE,
					inviteClickCountLTOE,
					conversionsGTOE,
					conversionsLTOE,
					revenueGTOE,
					revenueLTOE,
					invitationDateGTOE,
					invitationDateLTOE,
				})
				.withOptions(options)
				.call();
		},
		exportConversions({
			campaignId,
			search = undefined,
			conversionDateGTOE = undefined,
			conversionDateLTOE = undefined,
			revenueGTOE = undefined,
			revenueLTOE = undefined,
		}) {
			//const api = self.Communicators.CampaignsAPI.exportCampaignConversions;
			const api = self.Communicators.CampaignsAPIV2.exportConversions;
			return (
				self.Communicators.createRequest(api)
					//.withBaseUrl(process.env.BASE_API_ENDPOINT)
					.withHeaders({ Authorization: self.Orchestrators.Auth.authHeader })
					.withArgs({
						campaignId,
					})
					.withParams({
						search,
						conversionDateGTOE,
						conversionDateLTOE,
						revenueGTOE,
						revenueLTOE,
					})
					.withOptions({
						transformResponse: ({ data }) => ({
							...data.data,
							success: data.success,
							message: data.message,
						}),
						transformErrorResponse: ({ data, status }) => ({ ...data, status }),
					})
					.call()
			);
		},
		exportRewards({
			campaignId,
			search = undefined,
			rewardDateGTOE = undefined,
			rewardDateLTOE = undefined,
			rewardAmountGTOE = undefined,
			rewardAmountLTOE = undefined,
		}) {
			const api = self.Communicators.RewardsAPIV2.getRewardsExport;

			return self.Communicators.createRequest(api)
				.withHeaders({ Authorization: self.Orchestrators.Auth.authHeader })
				.withArgs({
					campaignId,
				})
				.withParams({
					search,
					rewardDateGTOE,
					rewardDateLTOE,
					rewardAmountGTOE,
					rewardAmountLTOE,
				})
				.withOptions({
					transformResponse: ({ data }) => ({
						...data.data,
						success: data.success,
						message: data.message,
					}),
					transformErrorResponse: ({ data, status }) => ({ ...data, status }),
				})
				.call();
		},
		login({ email, password }) {
			const api = self.Communicators.AuthAPIV2.auth;
			const options = {
				transformResponse: ({ data }) => ({
					...data.data,
					success: data.success,
					message: data.message,
				}),
				transformErrorResponse: ({ data, status }) => ({
					...data,
					status,
				}),
			};

			return self.Communicators.createRequest(api)
				.withArgs({
					email,
					password,
				})
				.withOptions(options)
				.call();
		},
		generateStateParam() {
			const api = self.Communicators.UsersAPIV2.generateUserStateParam;
			const options = V2Options;

			return self.Communicators.createRequest(api)
				.withHeaders({ Authorization: self.Orchestrators.Auth.authHeader })
				.withOptions(options)
				.call();
		},
		getCampaigns({
			affiliateId = undefined,
			clientId = undefined,
			searchText = undefined,
			clientSearch = undefined,
		}) {
			const api = self.Communicators.CampaignsAPIV2.getCampaignByFilter;
			const options = { ...V2Options, maxAge: -1 };
			const args = {};
			const params = {
				affiliateId,
				clientId,
				searchText,
				clientSearch,
			};

			return self.Communicators.createRequest(api)
				.withHeaders({ Authorization: self.Orchestrators.Auth.authHeader })
				.withArgs(args)
				.withParams(params)
				.withOptions(options)
				.call();
		},
		getCampaignById({ campaignId }) {
			//const api = self.Communicators.CampaignsAPI.getCampaignById;
			const api = self.Communicators.CampaignsAPIV2.getCampaignById;
			return self.Communicators.createRequest(api)
				.withHeaders({ Authorization: self.Orchestrators.Auth.authHeader })
				.withArgs({
					campaignId,
				})
				.withOptions({
					maxAge: -1,
					transformResponse: ({ data }) => ({
						...data.data,
						success: data.success,
						message: data.message,
					}),
					transformErrorResponse: ({ data, status }) => ({
						...data.response,
						status,
					}),
				})
				.call();
		},
		getCampaignEcommerceInjectableScript({ campaignId, providerId }) {
			const api =
				self.Communicators.CampaignsAPI.getCampaignEcommerceInjectableScript;
			return self.Communicators.createRequest(api)
				.withBaseUrl(process.env.BASE_API_ENDPOINT)
				.withHeaders({ Authorization: self.Orchestrators.Auth.authHeader })
				.withArgs({
					campaignId,
					providerId,
				})
				.withOptions({
					transformResponse: ({ data }) => data,
					transformErrorResponse: ({ data, status }) => ({ ...data, status }),
				})
				.call();
		},
		/**
		 * @v2migration
		 * @param {*} param0
		 * @returns
		 */
		getDailyStats({
			affiliateId = undefined,
			campaignId = undefined,
			clientId = undefined,
			fromDate,
			toDate,
		}) {
			const api = self.Communicators.UsersAPIV2.getDailyCampaignStatistics;

			return self.Communicators.createRequest(api)
				.withBaseUrl(process.env.BASE_API_ENDPOINT)
				.withHeaders({ Authorization: self.Orchestrators.Auth.authHeader })
				.withParams({
					affiliateId,
					campaignId,
					clientId,
					fromDate,
					toDate,
				})
				.withOptions(V2Options)
				.call();
		},
		/**
		 * @v2migration
		 * @param {*} param0
		 * @returns
		 */
		getDailyStatsOverview({
			affiliateId = undefined,
			campaignId = undefined,
			clientId = undefined,
			fromDate,
			toDate,
		}) {
			const api = self.Communicators.UsersAPIV2.getCampaignOverviewStatistics;

			return self.Communicators.createRequest(api)
				.withBaseUrl(process.env.BASE_API_ENDPOINT)
				.withHeaders({ Authorization: self.Orchestrators.Auth.authHeader })
				.withParams({
					affiliateId,
					campaignId,
					clientId,
					fromDate,
					toDate,
				})
				.withOptions(V2Options)
				.call();
		},
		getInfluencerById({ campaignId, influencerId }) {
			//const api = self.Communicators.InfluencersAPI.getInfluencerById;
			const api = self.Communicators.CampaignsAPIV2.getInfluencerById;

			return self.Communicators.createRequest(api)
				.withHeaders({ Authorization: self.Orchestrators.Auth.authHeader })
				.withArgs({
					campaignId,
					influencerId,
				})
				.withOptions({
					maxAge: -1,
					transformResponse: ({ data }) => ({
						...data.data,
						success: data.success,
						message: data.message,
					}),
					transformErrorResponse: ({ data, status }) => ({
						...data.response,
						status,
					}),
				})
				.call();
		},
		getProcessorById({ clientId, processorId }) {
			//const api = self.Communicators.ProcessorsAPI.getProcessorById;
			const api =
				self.Communicators.ProcessorsAPIV2.getProcessorInformationById;
			return (
				self.Communicators.createRequest(api)
					//.withBaseUrl(process.env.BASE_API_ENDPOINT)
					.withHeaders({ Authorization: self.Orchestrators.Auth.authHeader })
					.withArgs({
						clientId,
						processorId,
					})
					.withOptions({
						transformResponse: ({ data }) => ({
							...data.data,
							success: data.success,
							message: data.message,
						}),
						transformErrorResponse: ({ data, status }) => ({
							...data.response,
							status,
						}),
					})
					.call()
			);
		},
		getSquareLocations({ clientId, processorId }) {
			const api =
				self.Communicators.ProcessorsAPIV2
					.getSquareLocationsOfTheSquareProcessor;
			return self.Communicators.createRequest(api)
				.withHeaders({ Authorization: self.Orchestrators.Auth.authHeader })
				.withArgs({
					clientId,
					processorId,
				})
				.withOptions(V2Options)
				.call();
		},
		getUsers({
			affiliateId = undefined,
			clientId = undefined,
			searchText = undefined,
			affiliateSearch = undefined,
			clientSearch = undefined,
			status = undefined,
			isArchived = undefined,
		}) {
			const api =
				self.Communicators.UsersAPIV2.getNestedAdminUsersByAffiliateId;
			return self.Communicators.createRequest(api)
				.withHeaders({ Authorization: self.Orchestrators.Auth.authHeader })
				.withParams({
					affiliateId,
					clientId,
					searchText,
					affiliateSearch,
					clientSearch,
					status,
					isArchived,
				})
				.withOptions({
					transformResponse: ({ data }) => ({
						...data.data,
						success: data.success,
						message: data.message,
					}),
					transformErrorResponse: ({ data, status }) => ({
						...data.response,
						status,
					}),
					maxAge: -1,
				})
				.call();
		},
		getAffiliates({
			affiliateId = undefined,
			searchText = undefined,
			status = undefined,
			isArchived = undefined,
		}) {
			const api =
				self.Communicators.AffiliatesAPIV2.getNestedAffiliatesByAffiliateId;
			return self.Communicators.createRequest(api)
				.withHeaders({ Authorization: self.Orchestrators.Auth.authHeader })
				.withParams({
					affiliateId,
					searchText,
					status,
					isArchived,
				})
				.withOptions({
					transformResponse: ({ data }) => ({
						...data.data,
						success: data.success,
						message: data.message,
					}),
					transformErrorResponse: ({ data, status }) => ({
						...data.response,
						status,
					}),
				})
				.call();
		},
		getAffiliateById({ affiliateId = undefined }) {
			const api = self.Communicators.AffiliatesAPIV2.getAffiliateById;
			return self.Communicators.createRequest(api)
				.withHeaders({ Authorization: self.Orchestrators.Auth.authHeader })
				.withArgs({
					affiliateId,
				})
				.withOptions({
					transformResponse: ({ data }) => ({
						...data.data,
						success: data.success,
						message: data.message,
					}),
					transformErrorResponse: ({ data, status }) => ({
						...data.data,
						status,
					}),
				})
				.call();
		},
		getClientById({ clientId = undefined, searchText = undefined }) {
			//const api = self.Communicators.ClientsAPI.getClientById;
			const api = self.Communicators.ClientsAPIV2.getClientById;
			return self.Communicators.createRequest(api)
				.withHeaders({ Authorization: self.Orchestrators.Auth.authHeader })
				.withArgs({
					clientId,
				})
				.withParams({
					searchText,
				})
				.withOptions({
					transformResponse: ({ data }) => ({
						...data.data,
						success: data.success,
						message: data.message,
					}),
					transformErrorResponse: ({ data, status }) => ({
						...data.response,
						status,
					}),
				})
				.call();
		},
		getClientUseCaseStatus({ clientId }) {
			console.debug('hey');
			const api = self.Communicators.ClientsAPIV2.getClientUseCaseStatus;
			return self.Communicators.createRequest(api)
				.withHeaders({ Authorization: self.Orchestrators.Auth.authHeader })
				.withArgs({
					clientId,
				})
				.withOptions({
					transformResponse: ({ data }) => ({
						...data.data,
						success: data.success,
						message: data.message,
					}),
					transformErrorResponse: ({ data, status }) => ({
						...data.response,
						status,
					}),
				})
				.call();
		},
		getClients({
			affiliateId = undefined,
			searchText = undefined,
			status = undefined,
			isArchived = undefined,
		}) {
			//const api = self.Communicators.ClientsAPI.getClientsByLevel;
			const api = self.Communicators.ClientsAPIV2.getNestedClients;
			return self.Communicators.createRequest(api)
				.withHeaders({ Authorization: self.Orchestrators.Auth.authHeader })
				.withParams({
					affiliateId,
					searchText,
					status,
					isArchived,
				})
				.withOptions({
					transformResponse: ({ data }) => ({
						...data.data,
						success: data.success,
						message: data.message,
					}),
					transformErrorResponse: ({ data, status }) => ({
						...data.response,
						status,
					}),
					maxAge: -1,
				})
				.call();
		},
		getCompany({ clientId = undefined }) {
			//const api = self.Communicators.CompaniesAPI.getCompany;
			const api = self.Communicators.CompanyAPIV2.getCompanyInformation;
			return self.Communicators.createRequest(api)
				.withHeaders({ Authorization: self.Orchestrators.Auth.authHeader })
				.withArgs({
					clientId,
				})
				.withOptions({
					transformResponse: ({ data }) => ({
						...data.data,
						success: data.success,
						message: data.message,
					}),
					transformErrorResponse: ({ data, status }) => ({ ...data, status }),
				})
				.call();
		},
		/**
		 * @todo check where this call is being made and move params into its own obj
		 * @param {*} param0
		 * @returns
		 */
		getConversions({
			campaignId,
			influencerId,
			skip = 0,
			limit = 10,
			...params
		}) {
			//const api = self.Communicators.InfluencersAPI.getConversionByInfluencerId;
			const api =
				self.Communicators.CampaignsAPIV2.getConversionsByInfluencerId;
			return (
				self.Communicators.createRequest(api)
					//.withBaseUrl(process.env.BASE_API_ENDPOINT)
					.withHeaders({ Authorization: self.Orchestrators.Auth.authHeader })
					.withArgs({
						campaignId,
						influencerId,
					})
					.withParams({
						skip,
						limit,
						...params,
					})
					.withOptions({
						transformResponse: ({ data }) => data,
						transformErrorResponse: ({ data, status }) => ({ ...data, status }),
					})
					.call()
			);
		},
		getCampaignConversions({
			campaignId,
			conversionType,
			search = undefined,
			conversionDateGTOE = undefined,
			conversionDateLTOE = undefined,
			revenueGTOE = undefined,
			revenueLTOE = undefined,
			status = undefined,
			skip = 0,
			limit = 10,
		}) {
			//const api = self.Communicators.CampaignsAPI.getCampaignConversions;
			const api = self.Communicators.CampaignsAPIV2.getPaginatedConversions;
			return (
				self.Communicators.createRequest(api)
					//.withBaseUrl(process.env.BASE_API_ENDPOINT)
					.withHeaders({ Authorization: self.Orchestrators.Auth.authHeader })
					.withArgs({ campaignId })
					.withParams({
						campaignId,
						conversionType,
						conversionDateGTOE,
						conversionDateLTOE,
						revenueGTOE,
						revenueLTOE,
						status,
						search,
						skip,
						limit,
					})
					.withOptions({
						transformResponse: ({ data }) => ({
							...data.data,
							success: data.success,
							message: data.message,
						}),
						transformErrorResponse: ({ data, status }) => ({ ...data, status }),
					})
					.call()
			);
		},
		getInfluencers({
			campaignId,
			search = undefined,
			createdDateGTOE = undefined,
			createdDateLTOE = undefined,
			goalReached = undefined,
			status = undefined,
			invitesGTOE = undefined,
			invitesLTOE = undefined,
			invitesClickCountGTOE = undefined,
			invitesClickCountLTOE = undefined,
			conversionsGTOE = undefined,
			conversionsLTOE = undefined,
			revenueGTOE = undefined,
			revenueLTOE = undefined,
			isAnonymized = false,
			skip = 0,
			limit = 10,
		}) {
			//const api = self.Communicators.InfluencersAPI.getInfluencers;
			const api = self.Communicators.CampaignsAPIV2.getInfluencers;
			return self.Communicators.createRequest(api)
				.withHeaders({ Authorization: self.Orchestrators.Auth.authHeader })
				.withArgs({ campaignId })
				.withParams({
					hideInfluencerWithoutShare: false,
					isAnonymized,
					skip,
					limit,
					search,
					createdDateGTOE,
					createdDateLTOE,
					goalReached,
					status,
					invitesGTOE,
					invitesLTOE,
					invitesClickCountGTOE,
					invitesClickCountLTOE,
					conversionsGTOE,
					conversionsLTOE,
					revenueGTOE,
					revenueLTOE,
				})
				.withOptions({
					maxAge: -1,
					transformResponse: ({ data }) => ({
						...data.data,
						success: data.success,
						message: data.message,
					}),
					transformErrorResponse: ({ data, status }) => ({
						...data.response,
						status,
					}),
				})
				.call();
		},
		/**
		 * @v2migration
		 * @param {*} param0
		 * @returns
		 */
		getInfluencedConversions({
			campaignId,
			influencerId,
			skip = 0,
			limit = 10,
		}) {
			const api =
				self.Communicators.CampaignsAPIV2
					.getInfluencedConversionsByInvitedInfluencerId;
			const options = { ...V2Options, maxAge: -1 };

			return self.Communicators.createRequest(api)
				.withHeaders({ Authorization: self.Orchestrators.Auth.authHeader })
				.withArgs({
					campaignId,
					influencerId,
				})
				.withParams({
					skip,
					limit,
				})
				.withOptions(options)
				.call();
		},
		getInvites({
			campaignId,
			skip = 0,
			limit = 10,
			type = undefined,
			search = undefined,
			invitationDateGTOE = undefined,
			invitationDateLTOE = undefined,
			inviteClickCountGTOE = undefined,
			inviteClickCountLTOE = undefined,
			conversionsGTOE = undefined,
			conversionsLTOE = undefined,
			revenueGTOE = undefined,
			revenueLTOE = undefined,
			converted = undefined,
		}) {
			//const api = self.Communicators.InvitesAPI.getCampaignInvites;
			const api = self.Communicators.CampaignsAPIV2.getPaginatedInvites;
			return self.Communicators.createRequest(api)
				.withHeaders({ Authorization: self.Orchestrators.Auth.authHeader })
				.withArgs({ campaignId })
				.withParams({
					skip,
					limit,
					type,
					search,
					invitationDateGTOE,
					invitationDateLTOE,
					inviteClickCountGTOE,
					inviteClickCountLTOE,
					conversionsGTOE,
					conversionsLTOE,
					revenueGTOE,
					revenueLTOE,
					converted,
				})
				.withOptions({
					transformResponse: ({ data }) => ({
						...data.data,
						success: data.success,
						message: data.message,
					}),
					transformErrorResponse: ({ data, status }) => ({
						...data.response,
						status,
					}),
				})
				.call();
		},
		getUserById({ userId = undefined }) {
			const api =
				self.Communicators.UsersAPIV2.getASingleUserInformationByUserId;
			return self.Communicators.createRequest(api)
				.withHeaders({ Authorization: self.Orchestrators.Auth.authHeader })
				.withArgs({
					userId,
				})
				.withOptions({
					transformResponse: ({ data }) => ({
						...data.data,
						success: data.success,
						message: data.message,
					}),
					transformErrorResponse: ({ data, status }) => ({
						...data.data,
						status,
					}),
				})
				.call();
		},
		getRewards({
			campaignId,
			influencerId = undefined,
			search = undefined,
			rewardType = undefined,
			rewardDateGTOE = undefined,
			rewardDateLTOE = undefined,
			rewardAmountGTOE = undefined,
			rewardAmountLTOE = undefined,
			skip = 0,
			limit = 10,
		}) {
			const api = self.Communicators.RewardsAPIV2.getPaginatedRewards;
			return self.Communicators.createRequest(api)
				.withHeaders({ Authorization: self.Orchestrators.Auth.authHeader })
				.withArgs({ campaignId })
				.withParams({
					influencerId,
					limit,
					rewardType,
					rewardDateGTOE,
					rewardDateLTOE,
					rewardAmountGTOE,
					rewardAmountLTOE,
					search,
					skip,
				})
				.withOptions({
					transformResponse: ({ data }) => ({
						...data.data,
						success: data.success,
						message: data.message,
					}),
					transformErrorResponse: ({ data, status }) => ({
						...data.response,
						status,
					}),
				})
				.call();
		},
		processInfluencer({ influencerId, campaignId }) {
			const api = self.Communicators.CampaignsAPIV2.processInfluencer;
			const options = V2Options;

			return self.Communicators.createRequest(api)
				.withHeaders({ Authorization: self.Orchestrators.Auth.authHeader })
				.withArgs({
					influencerId,
					campaignId,
				})
				.withOptions(options)
				.call();
		},
		processInfluencerCardFlex({ influencerId, campaignId, body }) {
			const api = self.Communicators.InfluencersAPI.postCliqReward;
			return self.Communicators.createRequest(api)
				.withBaseUrl(process.env.BASE_API_ENDPOINT)
				.withHeaders({ Authorization: self.Orchestrators.Auth.authHeader })
				.withArgs({
					influencerId,
					campaignId,
					...body,
				})
				.withOptions({
					transformResponse: ({ data }) => data,
					transformErrorResponse: ({ data, status }) => ({ ...data, status }),
				})
				.call();
		},
		revokeSquareAccessToken({ clientId, companyId, processorId }) {
			const api = self.Communicators.ProcessorsAPIV2.updateProcessorSquareToken;
			return self.Communicators.createRequest(api)
				.withHeaders({ Authorization: self.Orchestrators.Auth.authHeader })
				.withArgs({
					clientId,
					companyId,
					processorId,
				})
				.withOptions(V2Options)
				.call();
		},
		revokeStripeToken({ clientId, companyId, processorId }) {
			const api = self.Communicators.ProcessorsAPIV2.revokeStripeToken;
			return self.Communicators.createRequest(api)
				.withBaseUrl(process.env.BASE_API_ENDPOINT)
				.withHeaders({ Authorization: self.Orchestrators.Auth.authHeader })
				.withArgs({
					clientId,
					companyId,
					processorId,
				})
				.withOptions({
					transformResponse: ({ data }) => data,
					transformErrorResponse: ({ data, status }) => ({ ...data, status }),
				})
				.call();
		},
		/**
		 * @v2migration
		 * @param {*} param0
		 * @returns
		 */
		updateCompany({ clientId = undefined, formData }) {
			const api = self.Communicators.CompanyAPIV2.updateCompanyInformation;
			const options = { ...V2Options, maxAge: -1 };

			return self.Communicators.createRequest(api)
				.withHeaders({
					'Content-Type': 'multipart/form-data',
					Authorization: self.Orchestrators.Auth.authHeader,
				})
				.withArgs({
					clientId,
					formData,
				})
				.withOptions(options)
				.call();
		},
		/**
		 * @param {*} param0
		 * @returns
		 */
		bulkUpdateCompanyCampaigns({ clientId = undefined, values }) {
			const api = self.Communicators.CompanyAPIV2.bulkUpdateCompanyCampaigns;
			const options = V2Options;

			return self.Communicators.createRequest(api)
				.withHeaders({
					Authorization: self.Orchestrators.Auth.authHeader,
				})
				.withArgs({
					clientId,
					...values,
				})
				.withOptions(options)
				.call();
		},
		updateProcessor({ clientId, companyId, processorId, body }) {
			const api = self.Communicators.ProcessorsAPIV2.updateProcessor;
			const options = V2Options;
			return self.Communicators.createRequest(api)
				.withHeaders({ Authorization: self.Orchestrators.Auth.authHeader })
				.withArgs({
					clientId,
					companyId,
					processorId,
					...body,
				})
				.withOptions(options)
				.call();
		},
		getCampaignDiscounts({ campaignId }) {
			const api = self.Communicators.CampaignsAPIV2.getDiscounts;
			return self.Communicators.createRequest(api)
				.withHeaders({ Authorization: self.Orchestrators.Auth.authHeader })
				.withArgs({ campaignId })
				.withParams({})
				.withOptions({
					maxAge: -1,
					transformResponse: ({ data }) => ({
						...data.data,
						success: data.success,
						message: data.message,
					}),
					transformErrorResponse: ({ data, status }) => ({
						...data.response,
						status,
					}),
				})
				.call();
		},
		updateCampaignDiscounts({ campaignId, discountCode, discountCodeDesc }) {
			const api = self.Communicators.CampaignsAPIV2.updateDiscounts;
			const options = V2Options;

			return self.Communicators.createRequest(api)
				.withHeaders({ Authorization: self.Orchestrators.Auth.authHeader })
				.withArgs({
					campaignId,
					discountCode,
					discountCodeDesc,
				})
				.withOptions(options)
				.call();
		},
		updateCampaignSettings({ campaignId, formData }) {
			const api = self.Communicators.CampaignsAPIV2.updateCampaign;
			const options = { ...V2Options, maxAge: -1 };
			return self.Communicators.createRequest(api)
				.withHeaders({ Authorization: self.Orchestrators.Auth.authHeader })
				.withArgs({
					campaignId,
					formData,
				})
				.withOptions(options)
				.call();
		},
		anonymizeInfluencer({ influencerId, campaignId }) {
			const api = self.Communicators.CampaignsAPIV2.anonymizeInfluencer;
			return self.Communicators.createRequest(api)
				.withHeaders({ Authorization: self.Orchestrators.Auth.authHeader })
				.withArgs({
					influencerId,
					campaignId,
				})
				.withOptions({
					transformResponse: ({ data }) => ({
						...data.data,
						success: data.success,
						message: data.message,
					}),
					transformErrorResponse: ({ data, status }) => ({
						...data.response,
						status,
					}),
				})
				.call();
		},
		updateInfluencer({ influencerId, campaignId, body }) {
			//const api = self.Communicators.InfluencersAPI.updateInfluencer;
			const api = self.Communicators.CampaignsAPIV2.updateInfluencer;
			return self.Communicators.createRequest(api)
				.withHeaders({ Authorization: self.Orchestrators.Auth.authHeader })
				.withArgs({
					influencerId,
					campaignId,
					...body,
				})
				.withOptions({
					maxAge: -1,
					transformResponse: ({ data }) => ({
						...data.data,
						success: data.success,
						message: data.message,
					}),
					transformErrorResponse: ({ data, status }) => ({
						...data.response,
						status,
					}),
				})
				.call();
		},
		/**
		 * @v2migration
		 * @param {*} param0
		 * @returns
		 */
		updateUser({ userId, body }) {
			const api = self.Communicators.UsersAPIV2.updatesAUser;
			const options = { ...V2Options, maxAge: -1 };
			const args = { userId, body };

			return self.Communicators.createRequest(api)
				.withHeaders({ Authorization: self.Orchestrators.Auth.authHeader })
				.withArgs(args)
				.withOptions(options)
				.call();
		},
		checkInfluencerProcessing({ campaignId }) {
			const api =
				self.Communicators.CampaignsAPIV2.showInfluencerRewardInformation;
			const options = V2Options;

			return self.Communicators.createRequest(api)
				.withHeaders({ Authorization: self.Orchestrators.Auth.authHeader })
				.withArgs({
					campaignId,
				})
				.withOptions(options)
				.call();
		},
		updateAffiliate({ affiliateId, body }) {
			const api = self.Communicators.AffiliatesAPIV2.updateAnAffiliate;
			return self.Communicators.createRequest(api)
				.withHeaders({ Authorization: self.Orchestrators.Auth.authHeader })
				.withArgs({
					affiliateId,
					...body,
				})
				.withOptions({
					transformResponse: ({ data }) => ({
						...data.data,
						success: data.success,
						message: data.message,
					}),
					transformErrorResponse: ({ data, status }) => ({
						...data.data,
						status,
					}),
					maxAge: -1,
				})
				.call();
		},
		updateClient({ clientId, body }) {
			//const api = self.Communicators.ClientsAPI.updateClient;
			const api = self.Communicators.ClientsAPIV2.updateClient;
			return self.Communicators.createRequest(api)
				.withHeaders({ Authorization: self.Orchestrators.Auth.authHeader })
				.withArgs({
					clientId,
					...body,
				})
				.withOptions({
					transformResponse: ({ data }) => ({
						...data.data,
						success: data.success,
						message: data.message,
					}),
					transformErrorResponse: ({ data, status }) => ({
						...data.response,
						status,
					}),
					maxAge: -1,
				})
				.call();
		},
	}))
	/** flows */
	.actions((self) => ({
		/**
		 * @static
		 * @generator
		 * @function
		 * @param {string} userId
		 * @returns {void}
		 * @description
		 * Uses localstorage to get logged in info
		 * 1. If the current user is logged in, resolve who that user is.
		 * 2. Set their module access
		 * 3. If that user is a client, resolve their business settings
		 */
		bootstrapFlow: flow(function*() {
			try {
				self.Orchestrators.View.setIsLoading('bootstrap');
				const { _u, _t } =
					JSON.parse(window.localStorage.getItem('ics.admin.1.0.0')) || {};

				if (!_u || !_t) return self.logoutFlow();
				// order matters here because the user on the auth is a reference
				const { user } = yield self.getUserById({ userId: _u });
				self.Collections.Users.addUser(user);
				self.Orchestrators.Auth.setUser(user.id);

				yield self.getCompanyFlow();
			} catch (e) {
				throw e;
			} finally {
				self.Orchestrators.View.setIsLoading('bootstrap', false);
			}
		}),
		createSquareProcessorFlow: flow(function*({ authorizationCode, state }) {
			const squareType = 'Square';
			self.Orchestrators.View.removeError('creatingSquareProcessor');
			self.Orchestrators.View.setIsLoading('creatingSquareProcessor');

			if (self.isCreatingProcessor) return;
			try {
				self.isCreatingProcessor = true;
				const { user, processor } = self.Orchestrators.Auth;
				const { clientId } = user;
				/**
				 * @note the way this is currently set up, this will only work for setting up a processor for the logged in user.
				 * this call should not be used to set up a square processor for a sub-client
				 */
				if ((processor || {}).squareLocationId) return processor;
				// create the processor
				const res = yield self.createProcessorFlow({
					type: squareType,
					clientId,
					authorizationCode,
					state,
				});

				if (!res) throw 'There was a problem setting up your Square Processor';

				const processorId = res?.id;
				// get locations
				const locations = yield self.getSquareLocationsFlow({
					clientId,
					processorId,
				});
				if (!locations?.length) {
					yield self.disconnectSquareProcessorFlow();

					throw 'You have no active Square Locations.  Check your Square Dashboard to see if have at least 1 active location';
				}
			} catch (e) {
				self.Orchestrators.View.setHasErrors('creatingSquareProcessor', e);
				console.warn(e);
			} finally {
				self.isCreatingProcessor = false;
				self.Orchestrators.View.setIsLoading('creatingSquareProcessor', false);
			}
		}),
		createStripeProcessorFlow: flow(function*({ authorizationCode, state }) {
			self.Orchestrators.View.removeError('creatingStripeProcessor');
			self.Orchestrators.View.setIsLoading('creatingStripeProcessor');

			if (self.isCreatingProcessor) return;
			try {
				self.isCreatingProcessor = true;
				const { user, processor } = self.Orchestrators.Auth;
				const { clientId } = user;
				/**
				 * @note the way this is currently set up, this will only work for setting up a processor for the logged in user.
				 * this call should not be used to set up a square processor for a sub-client
				 */
				// if ((processor || {}).stripe) return processor;
				// create the processor
				const res = yield self.createProcessorFlow({
					type: 'Stripe',
					clientId,
					authorizationCode,
					state,
				});

				if (!res) throw 'There was a problem setting up your Stripe Processor';
			} catch (e) {
				self.Orchestrators.View.setHasErrors('creatingStripeProcessor', e);
				console.warn(e);
			} finally {
				self.isCreatingProcessor = false;
				self.Orchestrators.View.setIsLoading('creatingStripeProcessor', false);
			}
		}),
		createAuthorizeNetProcessorFlow: flow(function*({
			authorizenetTransactionKey,
			authorizenetApiLoginKey,
		}) {
			const STATUS_KEY = 'creatingAuthorizeNetProcessor';
			self.Orchestrators.View.removeError(STATUS_KEY);
			self.Orchestrators.View.setIsLoading(STATUS_KEY);
			if (self.isCreatingProcessor) return;
			try {
				self.isCreatingProcessor = true;
				const { user, processor } = self.Orchestrators.Auth;
				const { clientId } = user;
				/**
				 * @note the way this is currently set up, this will only work for setting up a processor for the logged in user.
				 * this call should not be used to set up a square processor for a sub-client
				 *
				 * @note we cannot see if a client has a processor when you are an affiliate
				 */
				if (processor) return processor;
				// create the processor
				yield self.createProcessorFlow({
					type: 'Authorizenet',
					clientId,
					authorizenetTransactionKey,
					authorizenetApiLoginKey,
				});
			} catch (e) {
				self.Orchestrators.View.setHasErrors(STATUS_KEY, e);
				console.warn(e);
			} finally {
				self.isCreatingProcessor = false;
				self.Orchestrators.View.setIsLoading(STATUS_KEY, false);
			}
		}),
		createNMIProcessorFlow: flow(function*({ cardFlexSecurityKey }) {
			if (self.isCreatingProcessor) return;
			try {
				self.isCreatingProcessor = true;
				const { user, processor } = self.Orchestrators.Auth;
				const { clientId } = user;
				/**
				 * @note the way this is currently set up, this will only work for setting up a processor for the logged in user.
				 * this call should not be used to set up a square processor for a sub-client
				 */
				if (processor) return processor;
				// create the processor
				yield self.createProcessorFlow({
					type: 'Cliq',
					clientId,
					cardFlexSecurityKey,
				});
			} catch (e) {
				console.warn(e);
			} finally {
				self.isCreatingProcessor = false;
			}
		}),
		/**
		 * @deprecated
		 */
		dashboardStatsFlow: flow(function*() {
			try {
				self.Orchestrators.View.setIsLoading('dailyStats');
				self.Orchestrators.View.setIsLoading('statsOverview');
				const authUser = self.Orchestrators.Auth.user;
				const dashboardStats = self.Orchestrators.DashboardStats;
				const { affiliateId, clientId, campaignId } = dashboardStats;
				const { from: fromDate, to: toDate } = dashboardStats.DateRange.range;
				/** @todo get campaign id from filter */
				const { items: dailyStats } = yield self.getDailyStats({
					affiliateId: affiliateId || authUser.affiliateId,
					clientId: clientId || authUser.clientId,
					campaignId,
					fromDate,
					toDate,
				});
				self.Orchestrators.View.setIsLoading('dailyStats', false);
				/** @todo determine whether to show calculated or api calculated stats overview */
				const response = yield self.getDailyStatsOverview({
					affiliateId: affiliateId || authUser.affiliateId,
					clientId: clientId || authUser.clientId,
					campaignId,
					fromDate,
					toDate,
				});
				const statsOverview = response?.stats || response;
				self.Orchestrators.View.setIsLoading('statsOverview', false);

				self.Collections.Stats.updateStats(dailyStats);
				self.Orchestrators.DashboardStats.setStatOverview(statsOverview);
			} catch (e) {
				console.warn(e);
				self.Orchestrators.View.setIsLoading('dailyStats', false);
				self.Orchestrators.View.setIsLoading('statsOverview', false);
			}
		}),
		deleteCampaignFlow: flow(function*(
			campaignId = self.Orchestrators.Focused.campaignId,
			callback = () => undefined
		) {
			try {
				const res = yield self.deleteCampaign({ campaignId });
				if (!res.success) {
					throw res.message;
				}
				self.Collections.Campaigns.deleteCampaign(campaignId);
				callback(res);
			} catch (e) {
				console.warn(e);
				self.__displayFlowFailure(e.message | e);
			}
		}),
		cancelConversionFlow: flow(function*({
			campaignId,
			influencerId,
			conversionId,
			callback = () => {},
		}) {
			try {
				const data = yield self.cancelConversion({
					campaignId,
					influencerId,
					conversionId,
				});
				if (!data.success) {
					throw data.message;
				}
				const { conversion } = data;
				self.Collections.Conversions.updateConversion(
					conversion.id,
					conversion
				);
				return conversion;
			} catch (e) {
				console.error(e);
			}
		}),
		deleteConversionFlow: flow(function*({
			campaignId = self.Orchestrators.Focused.campaignId,
			influencerId = self.Orchestrators.Focused.influencerId,
			conversionId,
			callback = () => undefined,
		}) {
			try {
				const data = yield self.deleteConversion({
					campaignId,
					influencerId,
					conversionId,
				});
				if (!data.success) {
					throw data.message;
				}
				self.Collections.Conversions.deleteConversion(campaignId);
				callback(data.conversion);
			} catch (e) {
				console.warn(e);
				self.__displayFlowFailure();
			}
		}),
		/**
		 * @todo could probably delegate to square from here
		 */
		deleteProcessorFlow: flow(function*() {
			try {
				const { clientId, companyId, id: processorId } =
					self.userProcessor || {};
				yield self.updateCompanyFlow({
					processorId: null,
					cardFlexSecurityKey: null,
					authorizenetApiLoginKey: null,
					authorizenetTransactionKey: null,
				});
				yield self.deleteProcessor({ clientId, companyId, processorId });
				self.Collections.RewardProcessors.delete(processorId);
			} catch (e) {
				console.warn(e);
				self.__displayFlowFailure();
			}
		}),
		/**
		 * @static
		 * @generator
		 * @function
		 * @returns {void}
		 * @description
		 * Order of Operations:
		 * 1) revoke the square token so that it doesn't get locked up
		 * 2) update the company processor to null
		 * 3) delete the processor
		 * 4) make sure MST state reflects these changes
		 */
		disconnectSquareProcessorFlow: flow(function*() {
			try {
				const { clientId, companyId, id: processorId } =
					self.userProcessor || {};
				yield self.revokeSquareAccessToken({
					clientId,
					companyId,
					processorId,
				});
				yield self.deleteProcessorFlow();
			} catch (e) {
				console.warn(e);
			}
		}),
		/**
		 * @static
		 * @generator
		 * @function
		 * @returns {void}
		 * @description
		 * Order of Operations:
		 * 1) revoke the stripe token so that it doesn't get locked up
		 * 2) update the company processor to null
		 * 3) delete the processor
		 * 4) make sure MST state reflects these changes
		 */
		disconnectStripeProcessorFlow: flow(function*() {
			try {
				const { clientId, companyId, id: processorId } =
					self.userProcessor || {};
				yield self.revokeStripeToken({
					clientId,
					companyId,
					processorId,
				});
				yield self.deleteProcessorFlow();
			} catch (e) {
				console.warn(e);
			}
		}),
		downloadInfluencersCSVFlow: flow(function*(
			campaignId = self.Orchestrators.Focused.campaignId,
			param = {}
		) {
			try {
				self.Orchestrators.View.setIsLoading('influencersCSV');
				const {
					search,
					createdDateGTOE,
					createdDateLTOE,
					goalReached,
					processed,
					invitesGTOE,
					invitesLTOE,
					invitesClickCountGTOE,
					invitesClickCountLTOE,
					conversionsGTOE,
					conversionsLTOE,
					revenueGTOE,
					revenueLTOE,
				} = param;
				const data = yield self.exportInfluencers({
					campaignId,
					search,
					createdDateGTOE,
					createdDateLTOE,
					goalReached,
					processed,
					invitesGTOE,
					invitesLTOE,
					invitesClickCountGTOE,
					invitesClickCountLTOE,
					conversionsGTOE,
					conversionsLTOE,
					revenueGTOE,
					revenueLTOE,
				});
				if (!data.success) {
					throw data.message;
				}
				return data.influencers;
			} catch (e) {
				console.warn(e);
				self.__displayFlowFailure(e.message || e);
			} finally {
				self.Orchestrators.View.setIsLoading('influencersCSV', false);
			}
		}),
		downloadInvitesCSVFlow: flow(function*(
			campaignId = self.Orchestrators.Focused.campaignId,
			param = {}
		) {
			try {
				self.Orchestrators.View.setIsLoading('invitesCSV');
				const {
					search,
					invitationDateGTOE,
					invitationDateLTOE,
					inviteClickCountGTOE,
					inviteClickCountLTOE,
					conversionsGTOE,
					conversionsLTOE,
					revenueGTOE,
					revenueLTOE,
				} = param;
				const data = yield self.exportInvites({
					campaignId,
					search,
					invitationDateGTOE,
					invitationDateLTOE,
					inviteClickCountGTOE,
					inviteClickCountLTOE,
					conversionsGTOE,
					conversionsLTOE,
					revenueGTOE,
					revenueLTOE,
				});
				if (!data.success) {
					throw data.message;
				}
				return data.invites;
			} catch (e) {
				console.warn(e);
				self.__displayFlowFailure(e.message || e);
			} finally {
				self.Orchestrators.View.setIsLoading('invitesCSV', false);
			}
		}),
		downloadConversionsCSVFlow: flow(function*(
			campaignId = self.Orchestrators.Focused.campaignId
		) {
			try {
				self.Orchestrators.View.setIsLoading('conversionsCSV');
				const data = yield self.exportConversions({
					campaignId,
				});
				if (!data.success) {
					throw data.message;
				}
				return data.rewards;
			} catch (e) {
				console.warn(e);
				self.__displayFlowFailure(e.message || e);
			} finally {
				self.Orchestrators.View.setIsLoading('conversionsCSV', false);
			}
		}),
		downloadRewardsCSVFlow: flow(function*(
			campaignId = self.Orchestrators.Focused.campaignId
		) {
			try {
				self.Orchestrators.View.setIsLoading('rewardsCSV');
				const data = yield self.exportRewards({
					campaignId,
				});
				if (!data.success) {
					throw data.message;
				}
				return data.rewards;
			} catch (e) {
				console.warn(e);
				self.__displayFlowFailure(e.message || e);
			} finally {
				self.Orchestrators.View.setIsLoading('rewardsCSV', false);
			}
		}),
		downloadCampaignConversionsCSVFlow: flow(function*(
			campaignId = self.Orchestrators.Focused.campaignId,
			param = {}
		) {
			try {
				self.Orchestrators.View.setIsLoading('conversionsCSV');
				const {
					search,
					revenueGTOE,
					revenueLTOE,
					conversionDateGTOE,
					conversionDateLTOE,
				} = param;
				const data = yield self.exportConversions({
					campaignId,
					search,
					revenueGTOE,
					revenueLTOE,
					conversionDateGTOE,
					conversionDateLTOE,
				});
				if (!data.success) {
					throw data.message;
				}
				return data.rewards;
			} catch (e) {
				console.warn(e);
				self.__displayFlowFailure();
			} finally {
				self.Orchestrators.View.setIsLoading('conversionsCSV', false);
			}
		}),
		loginFlow: flow(function*(loginInfo = {}) {
			try {
				self.loginError = undefined;
				const data = yield self.login(loginInfo);
				const { token, user } = data;
				if (!token || !user) {
					throw (data.details[0] || {}).loginMessage;
				}
				self.__handleLoginFlowSuccess({ token, user });
			} catch (e) {
				if (e) self.__handleLoginFlowFailure(e);
				// do something with the message
			} finally {
				// any clean up
			}
		}),
		__displayFlowSuccess(message = 'Action completed successfully') {
			self.Orchestrators.View.addNotification({
				text: message,
				type: 'success',
			});
		},
		__displayFlowFailure(message = 'An error has occurred') {
			self.error = message;
			self.Orchestrators.View.addNotification({
				text: message,
				type: 'error',
			});
		},
		__handleLoginFlowFailure(message) {
			self.loginError = message;
			self.Orchestrators.View.addNotification({
				text: message,
				type: 'error',
			});
		},
		__handleLoginFlowSuccess({ token, user }) {
			self.Orchestrators.Auth.setToken(token);
			self.Collections.Users.addUser(user);
			self.Orchestrators.Auth.setUser(user.id);
		},
		logoutFlow(error) {
			self.Orchestrators.Auth.unsetAll();
			self.Collections.unsetAll();
			if (error) {
				// self.Orchestrators.View.setHasErrors()
				self.loginError = error;
				self.Orchestrators.View.addNotification({
					text: 'You have been logged out for security purposes',
					type: 'error',
				});
			}
		},
		getCompanyFlow: flow(function*() {
			try {
				self.Orchestrators.View.setIsLoading('getCompany');
				const clientId = self.Orchestrators.Auth.clientId;
				if (clientId) {
					const data = yield self.getCompany({
						clientId,
					});
					if (!data.success) {
						throw data.message;
					}
					self.Collections.Businesses.set(data.company.id, data.company);
					self.Orchestrators.Auth.setUserBusiness(data.company.id);
				}
				self.Orchestrators.View.setIsLoading('getCompany', false);
			} catch (e) {
				console.warn(e);
				self.__displayFlowFailure();
			}
		}),
		getConversionsFlow: flow(function*(req = {}) {
			try {
				const { campaignId, influencerId, skip, limit } = req;
				const conversions = yield self.getConversions({
					campaignId,
					influencerId,
					skip,
					limit,
				});
				self.Collections.Conversions.setConversions(conversions);
				self.Collections.Conversions.setTotal((conversions || []).length);
			} catch (e) {
				console.warn(e);
				self.__displayFlowFailure();
			}
		}),
		getCampaignConversionFlow: flow(function*(req = {}) {
			self.Orchestrators.View.setIsLoading('campaignConversions');
			try {
				const {
					campaignId,
					skip,
					limit,
					search,
					conversionDateGTOE,
					conversionDateLTOE,
					revenueGTOE,
					revenueLTOE,
				} = req;
				const {
					conversions,
					success,
					message,
				} = yield self.getCampaignConversions({
					campaignId,
					search,
					conversionDateGTOE,
					conversionDateLTOE,
					revenueGTOE,
					revenueLTOE,
					skip,
					limit,
				});
				if (!success) {
					throw message;
				}
				self.Collections.CampaignConversions.setConversions(conversions.rows);
				self.Collections.CampaignConversions.setTotal(conversions.count);
			} catch (e) {
				console.warn(e);
				self.__displayFlowFailure(e.message || e);
			} finally {
				self.Orchestrators.View.setIsLoading('campaignConversions', false);
			}
		}),
		getInfluencedConversionFlow: flow(function*(req = {}) {
			try {
				const { campaignId, influencerId, skip, limit } = req;

				const data = yield self.getInfluencedConversions({
					campaignId,
					influencerId,
					skip,
					limit,
				});

				if (!data.success) {
					throw data.message;
				}
				const conversions = data?.conversions;

				self.Collections.Conversions.setConversions(conversions);
				self.Collections.Conversions.setTotal((conversions || []).length);
			} catch (e) {
				console.warn(e);
				self.__displayFlowFailure(e.message || e);
			}
		}),
		getProcessorFlow: flow(function*() {
			/**
			 * @todo add in the ability to pass in specific processorId
			 */
			self.Orchestrators.View.setIsLoading(`processor`);
			try {
				const processorId = self.userProcessorId;
				if (processorId) {
					const { clientId } = self.Orchestrators.Auth;
					const data = yield self.getProcessorById({
						clientId,
						processorId,
					});
					if (!data.success) {
						throw data.message;
					}
					self.Collections.RewardProcessors.set(processorId, data.processor);
				}
			} catch (e) {
				console.warn(e);
				self.__displayFlowFailure();
			} finally {
				self.Orchestrators.View.setIsLoading(`processor`, false);
			}
		}),
		/**
		 * @static
		 * @generator
		 * @function
		 * @returns {Array<object>} locations
		 * @description
		 * This should only be called if a processor has already been created
		 */
		getSquareLocationsFlow: flow(function*({
			clientId = self.Orchestrators.Auth.clientId,
			processorId = self.userBusiness.processorId,
		}) {
			try {
				const data = yield self.getSquareLocations({
					clientId,
					processorId,
				});
				const locations = (data.data || {}).locations;
				self.Collections.SquareLocations.setList(locations);
				return locations;
			} catch (e) {
				console.warn(e);
				self.__displayFlowFailure();
			}
		}),
		getUsersFlow: flow(function*(
			req = {
				affiliateId: undefined,
				clientId: undefined,
				searchText: undefined,
				affiliateSearch: undefined,
				clientSearch: undefined,
				status: undefined,
				isArchived: undefined,
			}
		) {
			try {
				self.Orchestrators.View.setIsLoading('users');
				const { user } = self.Orchestrators.Auth;
				const data = yield self.getUsers({
					affiliateId: req.affiliateId || user.affiliateId,
					clientId: req.clientId || user.clientId,
					searchText: req.searchText,
					affiliateSearch: req.affiliateSearch,
					clientSearch: req.clientSearch,
					status: req.status,
					isArchived: req.isArchived,
				});
				if (!data.success) {
					throw data.message;
				}
				self.Collections.Users.addUsers(data.users);
				self.Collections.Users.setUserList(data.users.map(({ id }) => id));
			} catch (e) {
				console.warn(e);
				self.__displayFlowFailure(e.message || e);
			} finally {
				self.Orchestrators.View.setIsLoading('users', false);
			}
		}),
		getAffiliatesFlow: flow(function*(
			req = {
				affiliateId: undefined,
				searchText: undefined,
				status: undefined,
				isArchived: undefined,
			}
		) {
			try {
				self.Orchestrators.View.setIsLoading('affiliates');
				const { user } = self.Orchestrators.Auth;
				const { affiliateId: userAffId } = user;
				const data = yield self.getAffiliates({
					affiliateId: req.affiliateId || userAffId,
					searchText: req.searchText,
					status: req.status,
					isArchived: req.isArchived,
				});
				if (!data.success) {
					throw data.message;
				}
				self.Collections.Affiliates.addAffiliates(data.affiliates);
				self.Collections.Affiliates.setAffiliateList(
					data.affiliates.map(({ id }) => id)
				);
			} catch (e) {
				console.warn(e);
				self.__displayFlowFailure(e.message || e);
			} finally {
				self.Orchestrators.View.setIsLoading('affiliates', false);
			}
		}),
		getSelectableAffiliatesFlow: flow(function*(
			req = {
				affiliateId: undefined,
			}
		) {
			try {
				self.Orchestrators.View.setIsLoading('affiliates');
				const { user } = self.Orchestrators.Auth;
				const { affiliateId: userAffId } = user;
				const data = yield self.getAffiliates({
					affiliateId: req.affiliateId || userAffId,
				});
				if (!data.success) {
					throw data.message;
				}
				self.Collections.Affiliates.addAffiliates(data.affiliates);
				self.Collections.Affiliates.setSelectable(data.affiliates);
			} catch (e) {
				console.warn(e);
				self.__displayFlowFailure(e.message || e);
			} finally {
				self.Orchestrators.View.setIsLoading('affiliates', false);
			}
		}),
		getCampaignsFlow: flow(function*(
			req = {
				affiliateId: undefined,
				clientId: undefined,
				searchText: undefined,
				clientSearch: undefined,
			}
		) {
			try {
				self.Orchestrators.View.setIsLoading('campaignsList');
				const { user } = self.Orchestrators.Auth;
				// only use user affiliateId if no affiliateId is provided
				const data = yield self.getCampaigns({
					affiliateId: req.affiliateId || user.affiliateId,
					clientId: req.clientId || user.clientId,
					searchText: req.searchText,
					clientSearch: req.clientSearch,
				});

				const campaigns = data?.campaigns;
				// add error display logic once switched to v2
				self.Collections.Campaigns.setCampaigns(campaigns);
				self.Orchestrators.View.setIsLoading('campaignsList', false);

				return campaigns;
			} catch (e) {
				console.warn(e);
				self.__displayFlowFailure();
			} finally {
				// just in case
				self.Orchestrators.View.setIsLoading('campaignsList', false);
			}
		}),
		getClientsFlow: flow(function*(
			req = {
				affiliateId: undefined,
				searchText: undefined,
				status: undefined,
				isArchived: undefined,
			}
		) {
			try {
				self.Orchestrators.View.setIsLoading('clients');
				const { user } = self.Orchestrators.Auth;
				const { affiliateId: userAffId } = user;
				// only use user affiliateId if no affiliateId is provided
				const data = yield self.getClients({
					affiliateId: req.affiliateId || userAffId,
					searchText: req.searchText,
					status: req.status,
					isArchived: req.isArchived,
				});
				if (!data.success) {
					throw data.message;
				}
				self.Collections.Clients.addClients(data.clients);
				self.Collections.Clients.setClientList(
					data.clients.map(({ id }) => id)
				);
			} catch (e) {
				console.warn(e);
				self.__displayFlowFailure(e.message || e);
			} finally {
				self.Orchestrators.View.setIsLoading('clients', false);
			}
		}),
		getRewardsFlow: flow(function*({
			campaignId,
			skip,
			limit,
			search,
			rewardType,
			rewardDateGTOE,
			rewardDateLTOE,
			rewardAmountGTOE,
			rewardAmountLTOE,
		} = {}) {
			self.Orchestrators.View.setIsLoading('rewards');
			try {
				const { rewards, success, message } = yield self.getRewards({
					campaignId,
					skip,
					limit,
					search,
					rewardType,
					rewardDateGTOE,
					rewardDateLTOE,
					rewardAmountGTOE,
					rewardAmountLTOE,
				});
				if (!success) throw message;
				self.Collections.Rewards.setRewards(rewards.rows);
				self.Collections.Rewards.setTotal(rewards.count);
			} catch (e) {
				console.warn(e);
				self.__displayFlowFailure(e.message || e);
			} finally {
				self.Orchestrators.View.setIsLoading('rewards', false);
			}
		}),

		getInfluencersFlow: flow(function*({
			campaignId,
			skip,
			limit,
			search,
			createdDateGTOE,
			createdDateLTOE,
			goalReached,
			status,
			invitesGTOE,
			invitesLTOE,
			invitesClickCountGTOE,
			invitesClickCountLTOE,
			conversionsGTOE,
			conversionsLTOE,
			revenueGTOE,
			revenueLTOE,
		} = {}) {
			self.Orchestrators.View.setIsLoading('influencers');
			try {
				const { influencers, success, message } = yield self.getInfluencers({
					campaignId,
					skip,
					limit,
					search,
					createdDateGTOE,
					createdDateLTOE,
					goalReached,
					status,
					invitesGTOE,
					invitesLTOE,
					invitesClickCountGTOE,
					invitesClickCountLTOE,
					conversionsGTOE,
					conversionsLTOE,
					revenueGTOE,
					revenueLTOE,
				});
				if (!success) {
					throw message;
				}
				self.Collections.Influencers.setInfluencers(influencers.rows);
				self.Collections.Influencers.setTotal(influencers.count);
			} catch (e) {
				console.warn(e);
				self.__displayFlowFailure(e.message || e);
			} finally {
				self.Orchestrators.View.setIsLoading('influencers', false);
			}
		}),
		getInvitesFlow: flow(function*(req = {}) {
			try {
				self.Orchestrators.View.setIsLoading('invites');
				const {
					campaignId,
					skip,
					limit,
					search,
					invitationDateGTOE,
					invitationDateLTOE,
					inviteClickCountGTOE,
					inviteClickCountLTOE,
					conversionsGTOE,
					conversionsLTOE,
					revenueGTOE,
					revenueLTOE,
					converted,
				} = req;
				const data = yield self.getInvites({
					campaignId,
					skip,
					limit,
					search,
					invitationDateGTOE,
					invitationDateLTOE,
					inviteClickCountGTOE,
					inviteClickCountLTOE,
					conversionsGTOE,
					conversionsLTOE,
					revenueGTOE,
					revenueLTOE,
					converted,
				});
				if (!data.success) {
					throw data.message;
				}
				self.Collections.Invites.setInvites(data.invites.rows);
				self.Collections.Invites.setTotal(data.invites.count);
			} catch (e) {
				console.warn(e);
				self.__displayFlowFailure(e.message || e);
			} finally {
				self.Orchestrators.View.setIsLoading('invites', false);
			}
		}),
		resolveAffiliateFlow: flow(function*(affiliateId) {
			/** See if the affiliate is already in store.  if not, make api call to get it and store it */
			try {
				const affiliateExists = self.Collections.Affiliates.find(affiliateId);
				if (!!!affiliateExists) {
					const data = yield self.getAffiliateById({ affiliateId });
					if (!data.success) {
						throw data.message;
					}
					self.Collections.Affiliates.addAffiliate(data.affiliate);
				}
				self.Orchestrators.Focused.setFocus('affiliate', affiliateId);
			} catch (e) {
				console.warn(e);
				self.__displayFlowFailure(e.message || e);
			}
		}),
		resolveAffiliateFlowCleanUp: () => {
			self.Orchestrators.Focused.unsetFocus('affiliate');
		},
		resolveClientFlow: flow(function*(clientId) {
			/** See if the affiliate is already in store.  if not, make api call to get it and store it */
			try {
				let client = self.Collections.Clients.find(clientId);
				let clientExists = !!client;
				if (!!!clientExists) {
					const data = yield self.getClientById({ clientId });
					try {
						if (!data.success) {
							throw data.message;
						}
						client = data.client;
						self.Collections.Clients.addClient(client);
					} catch (e) {
						client = {};
					}
				}
				clientExists = !!client?.id;
				if (clientExists) {
					self.Orchestrators.Focused.setFocus('client', clientId);
				}
				/**
				 * @todo get processor on a per client basis
				 */
				const processorInitialized = !!self.userProcessor;
				if (!processorInitialized) {
					yield self.getProcessorFlow();
				}
				return client;
			} catch (e) {
				console.warn(e);
				self.__displayFlowFailure(e.message || e);
			}
		}),
		resolveClientFlowCleanUp: () => {
			self.Orchestrators.Focused.unsetFocus('client');
		},
		resolveCampaign: flow(function*(campaignId) {
			try {
				self.Orchestrators.View.setIsLoading(`campaign:${campaignId}`);
				const data = yield self.getCampaignById({ campaignId });

				if (!data.success) {
					throw data.message;
				}
				const campaign = data.campaign;
				self.Collections.Campaigns.addCampaign(campaign);
				return campaign;
			} catch (e) {
				self.__displayFlowFailure(e.message || e);
			} finally {
				self.Orchestrators.View.setIsLoading(`campaign:${campaignId}`, false);
			}
		}),
		/**
		 * @deprecated
		 * try using resolveCampaign instead
		 */
		resolveCampaignFlow: flow(function*(campaignId) {
			/** See if the campaign is already in store.  if not, make api call to get it and store it */
			try {
				self.Orchestrators.View.setIsLoading(`campaign:${campaignId}`);
				let campaign = self.Collections.Campaigns.find(campaignId);
				let campaignExists = !!campaign;
				if (!campaignExists) {
					const data = yield self.getCampaignById({ campaignId });
					try {
						if (!data.success) {
							throw data.message;
						}
						campaign = data.campaign;
						self.Collections.Campaigns.addCampaign(campaign);
					} catch (e) {
						campaign = {};
						self.__displayFlowFailure(e.message || e);
					}
				}
				campaignExists = !!campaign?.id;
				if (campaignExists) {
					self.Orchestrators.Focused.setFocus('campaign', campaignId);
				}
				return campaign;
			} catch (e) {
				console.warn(e);
				self.__displayFlowFailure();
			} finally {
				self.Orchestrators.View.setIsLoading(`campaign:${campaignId}`, false);
			}
		}),
		resolveCampaignFlowCleanUp: () => {
			self.Orchestrators.Focused.unsetFocus('campaign');
		},
		getCampaignEcommerceScript: flow(function*(campaignId, providerId) {
			try {
				const { scripts } = yield self.getCampaignEcommerceInjectableScript({
					campaignId,
					providerId: providerId || '',
				});
				return scripts || {};
			} catch (e) {
				console.warn(e);
				self.__displayFlowFailure();
			}
		}),
		resolveInfluencerConversionsFlow: flow(function*({
			campaignId,
			influencerId,
			skip,
			limit,
		} = {}) {
			try {
				const data = yield self.getInfluencedConversions({
					campaignId,
					influencerId,
					skip,
					limit,
				});

				if (!data.success) {
					throw data.message;
				}

				return data.conversions;
			} catch (e) {
				console.warn(e);
				self.__displayFlowFailure();
			}
		}),
		/**
		 *
		 */
		resolveInfluencer: flow(function*({ influencerId, campaignId }) {
			try {
				const data = yield self.getInfluencerById({
					campaignId,
					influencerId,
				});
				if (!data.success) {
					throw data.message;
				}
				self.Collections.Influencers.addInfluencer(data.influencer);
				return data.influencer;
			} catch (e) {}
		}),
		/**
		 * @deprecated
		 * moving away from the Focused orchestrator.  Try using `resolveInfluencer` instead
		 */
		resolveInfluencerFlow: flow(function*(influencerId, campaignId) {
			/** See if the influencer is already in store.  if not, make api call to get it and store it */
			try {
				const influencerExists = self.Collections.Influencers.find(
					influencerId
				);
				if (!!!influencerExists) {
					const data = yield self.getInfluencerById({
						campaignId,
						influencerId,
					});
					if (!data.success) {
						throw data.message;
					}
					self.Collections.Influencers.addInfluencer(data.influencer);
				}
				self.Orchestrators.Focused.setFocus('influencer', influencerId);
				return self.Orchestrators.Focused.influencer;
			} catch (e) {
				console.warn(e);
				self.__displayFlowFailure(e.message || e);
			}
		}),
		resolveInfluencerFlowCleanUp: () => {
			self.Orchestrators.Focused.unsetFocus('influencer');
		},
		/**
		 * @static
		 * @function
		 * @returns {void}
		 * @description
		 * See if the affiliate is already in store.
		 * If not, make api call to get it and store it
		 */
		resolveUserFlow: flow(function*(userId) {
			try {
				const userExists = self.Collections.Users.find(userId);
				if (!!!userExists) {
					const data = yield self.getUserById({ userId });
					if (!data.success) {
						throw data.message;
					}
					self.Collections.Users.addUser(data.user);
				}
				self.Orchestrators.Focused.setFocus('user', userId);
			} catch (e) {
				console.warn(e);
				self.__displayFlowFailure(e.message || e);
			}
		}),
		/**
		 * @static
		 * @function
		 * @returns {void}
		 * @description
		 * The UI shouldn't need to know to go into orchestrators to unset focus
		 * doing this for clarity/readability on the UI. Good for use in useEffect
		 */
		resolveUserFlowCleanUp: () => {
			self.Orchestrators.Focused.unsetFocus('user');
		},
		createAffiliateFlow: flow(function*(body) {
			try {
				const data = yield self.createAffiliate({ body });
				if (!data.success) {
					throw data.message;
				}
				self.Collections.Affiliates.addAffiliate(data.affiliate);
			} catch (e) {
				console.warn(e);
				self.__displayFlowFailure(e.message || e);
				throw e;
			}
		}),
		createCampaignFlow: flow(function*(values) {
			try {
				self.Orchestrators.View.setIsLoading('createCampaign');
				const formData = new FormData();
				// we need to append images last
				const {
					offerImage,
					thankYouImage,
					shareImage,
					useSMSConsent,
					useEmailConsent,
					...textValues
				} = values;
				Object.keys(textValues).forEach((key) => {
					if (!textValues[key]) return;

					formData.set(key, textValues[key]);
				});
				// handle the consent fields separately because the fact that they're booleans doesn't let the logic above
				//  work correctly for "turning on" a field
				if (useSMSConsent !== undefined) {
					formData.set('useSMSConsent', useSMSConsent);
				}
				if (useEmailConsent !== undefined) {
					formData.set('useEmailConsent', useEmailConsent);
				}
				if (offerImage) {
					formData.set('offerImage', offerImage);
				}
				if (thankYouImage) {
					formData.set('thankYouImage', thankYouImage);
				}
				if (shareImage) {
					formData.set('shareImage', shareImage);
				}

				const data = yield self.createCampaign(formData);
				if (!data.success) {
					throw data.message;
				}
				const { campaign } = data;
				self.Collections.Campaigns.addCampaign(campaign);
				return campaign;
			} catch (e) {
				console.warn(e);
				self.__displayFlowFailure(e.message || e);
			} finally {
				self.Orchestrators.View.setIsLoading('createCampaign', false);
			}
		}),
		createClientFlow: flow(function*(body) {
			let finalClient,
				finalMessage = null;
			try {
				const { client, success, message } = yield self.createClient({
					body,
				});

				if (!success) {
					throw message;
				}
				finalClient = client;
				finalMessage = message;

				/**
				 * some repetition here with the update stuff, but this is ok for now
				 * @todo abstract this
				 */
				if (client.allowSMS) {
					const { client, success, message } = yield self.createClientUseCase({
						clientId: finalClient.id,
					});

					if (!success) {
						throw message;
					}
					finalClient = client;
					finalMessage = `${finalMessage}. ${message}`;
				}

				self.Collections.Clients.addClient(finalClient);
				self.__displayFlowSuccess(finalMessage);
			} catch (e) {
				console.warn(e);
				self.__displayFlowFailure(e.message || e);
				throw e;
			}
		}),
		/**
		 * @note this might be deprecated.  updateProcessorFlow is used instead
		 */
		createProcessorFlow: flow(function*(body) {
			try {
				self.Orchestrators.View.removeError('creatingProcessor');
				const { id: companyId, clientId } = self.userBusiness || {};
				const data = yield self.createProcessor({
					clientId,
					companyId,
					body,
				});
				const processorData = data.processor;

				if (data.status === 403)
					throw "We've just prevented a potential attack on your account.  Please ensure that you connect your processor directly from admin-beta.icecreamsocial.io.";

				if (!!!data.success) throw data.message;

				self.Collections.RewardProcessors.set(processorData.id, processorData);
				// also need to update the company with the processor
				const notify = processorData.type.toLowerCase() !== 'square';
				yield self.updateCompanyFlow({ processorId: processorData.id }, notify);

				return processorData;
			} catch (e) {
				self.Orchestrators.View.setHasErrors('creatingProcessor', e);
				console.warn(e);
			}
		}),
		/**
		 * @v2migration
		 */
		createUserFlow: flow(function*(body) {
			try {
				const cleaned = Object.keys(body).reduce(
					(obj, key) => (body[key] !== '' ? { ...obj, [key]: body[key] } : obj),
					{}
				);

				const data = yield self.createUser({ body: cleaned });
				// v2 includes a user object where as v1 returns the data at the top level
				const user = data?.user;

				if (!user) throw 'An error occurred while creating the user';

				self.Collections.Users.addUser(user);
				self.__displayFlowSuccess(
					`An account for ${user?.email} was successfully created`
				);
			} catch (e) {
				console.warn(e);
				self.__displayFlowFailure(e);
				throw e;
			}
		}),
		processInfluencerFlow: flow(function*(
			influencerId = self.Orchestrators.Focused.influencerId,
			campaignId = self.Orchestrators.Focused.campaignId
		) {
			const STATUS_KEY = 'processingInfluencer';
			try {
				self.Orchestrators.View.removeError(STATUS_KEY);
				self.Orchestrators.View.setIsLoading(STATUS_KEY);

				const data = yield self.processInfluencer({
					influencerId,
					campaignId,
				});
				// v2 contains an influencer object
				const processedInfluencer = data?.influencer;

				if (processedInfluencer?.id) {
					self.Collections.Influencers.updateInfluencer(
						influencerId,
						processedInfluencer
					);

					self.__displayFlowSuccess(
						`"${processedInfluencer.email}" processed successfully!`
					);
					return processedInfluencer;
				}

				throw `There was an error processing the influencer.  Please check your Reward Processor settings`;
			} catch (e) {
				console.warn(e);
				self.__displayFlowFailure(
					`There was an error processing the influencer.  Please check your Reward Processor settings`
				);
				self.Orchestrators.View.setHasErrors(
					STATUS_KEY,
					`There was an error processing the influencer.  Please check your Reward Processor settings`
				);
				throw e;
			} finally {
				self.Orchestrators.View.setIsLoading(STATUS_KEY, false);
			}
		}),
		/**
		 * @static
		 * @generator
		 * @function
		 * @deprecated
		 * @param {object} values business values
		 * @param {boolean} [notify = true] whether or not to show a success notification
		 * @yields {Promise}
		 * @returns {object} business
		 */
		updateCompanyFlow: flow(function*(values, notify = true) {
			try {
				self.Orchestrators.View.setIsLoading('businessSettings');
				const { clientId } = self.userBusiness || {};
				const isFile = (check) => typeof check?.name === 'string';
				// kinda don't want to be accessing the window from here
				const tempLoadingImg = `${window.origin}${icsLoader}`;

				const currentBusiness = self.Collections.Businesses.get(
					self.userBusinessId
				);

				// being optimistic that the company will successfully be updated
				// we exclude logo here because we know that it is a file and not a string (string expected by mst)
				const { logo, ...incomingCompany } = values;
				self.Collections.Businesses.update(self.userBusinessId, {
					...currentBusiness,
					...incomingCompany,
					logo: isFile(logo) ? tempLoadingImg : logo || currentBusiness?.logo,
				});

				// check if it has all required fields
				const isConfigured = self.isUserBusinessSetupComplete;

				const formData = new FormData();

				Object.keys(incomingCompany).forEach((key) => {
					formData.set(key, incomingCompany[key]);
				});
				// set images only if they are files.  if they are strings, they are most likely the previous uri
				// this is so that we don't overwrite the image on subsequent saves
				if (isFile(logo)) {
					formData.set('logo', logo);
				}

				formData.set('isConfigured', isConfigured);
				const data = yield self.updateCompany({
					clientId,
					formData,
				});

				if (!data.success) {
					throw data.message;
				}

				const business = data.company;

				// set the finalized business here
				if (business) {
					self.Collections.Businesses.update(business.id, business);
					if (notify) {
						self.Orchestrators.View.addNotification({
							text: 'Business Settings updated!',
							type: 'success',
						});
					}
					return business;
				} else {
					return {};
				}
			} catch (e) {
				console.warn(e);
				self.__displayFlowFailure(e.message || e);
			} finally {
				self.Orchestrators.View.setIsLoading('businessSettings', false);
			}
		}),
		/**
		 * @static
		 * @generator
		 * @function
		 * @param {object} values business values
		 * @param {boolean} [notify = true] whether or not to show a success notification
		 * @yields {Promise}
		 * @returns {object} business
		 */
		bulkUpdateCompanyCampaignsFlow: flow(function*(values, notify = true) {
			try {
				self.Orchestrators.View.setIsLoading('businessSettings');
				const { clientId } = self.userBusiness || {};
				const currentBusiness = self.Collections.Businesses.get(
					self.userBusinessId
				);

				// being optimistic that the company will successfully be updated
				self.Collections.Businesses.update(self.userBusinessId, {
					...currentBusiness,
					...values,
				});

				// update company settings first
				const business = yield self.updateCompanyFlow(values, false);

				// propagate to company's campaigns
				const data = yield self.bulkUpdateCompanyCampaigns({
					clientId,
					values,
				});

				const cacheBust = -1;
				yield self.getCampaignsFlow(
					{
						clientSearch: clientId,
					},
					cacheBust
				);

				if (!data.success) {
					throw data.message;
				}

				if (notify) {
					self.Orchestrators.View.addNotification({
						text: data.message || 'Business and Campaign Settings updated!',
						type: 'success',
					});
				}

				return business;
			} catch (e) {
				console.warn(e);
				self.__displayFlowFailure(e.message || e);
			} finally {
				self.Orchestrators.View.setIsLoading('businessSettings', false);
			}
		}),
		/**
		 * @static
		 * @generator
		 * @function
		 * @param {object} body
		 * @param {string | number} body.companyId
		 * @param {string | number} body.clientId
		 * @param {string | number} body.id
		 * @param {string} [body.type]
		 * @param {string} [body.squareLocationId]
		 * @param {string} [body.squareLocationName]
		 * @param {string} [body.cardFlexSecurityKey]
		 * @returns {void}
		 */
		updateProcessorFlow: flow(function*(body) {
			try {
				const { id: processorId, companyId, clientId } = body;
				const data = yield self.updateProcessor({
					clientId,
					companyId,
					processorId,
					body,
				});
				const [processor] = data.processor;
				self.Collections.RewardProcessors.set(processorId, processor);
			} catch (e) {
				console.warn(e);
			}
		}),
		getClientUseCaseStatusFlow: flow(function*(clientId) {
			try {
				self.Orchestrators.View.setIsLoading('useCase');
				const { client, success } = yield self.getClientUseCaseStatus({
					clientId,
				});

				if (!success) {
					throw 'Could not get client use case status.';
				}

				self.Collections.Clients.updateClient(clientId, client);
			} catch (e) {
				console.error(e);
			} finally {
				self.Orchestrators.View.setIsLoading('useCase', false);
			}
		}),
		updateCampaignSettingsFlow: flow(function*(
			values,
			campaignId = self.Orchestrators.Focused.campaignId
		) {
			try {
				self.Orchestrators.View.setIsLoading('updateCampaign');
				const formData = new FormData();
				// we need to append images last
				const {
					offerImage,
					thankYouImage,
					shareImage,
					useSMSConsent,
					useEmailConsent,
					useEmail,
					useSMS,
					isArchived,
					...textValues
				} = values;

				Object.keys(textValues).forEach((key) => {
					// don't add null values because api doesn't know how to handle them
					if (!textValues[key] && textValues[key] !== '') return;

					formData.set(key, textValues[key]);
				});

				// handle the boolean fields separately because the fact that they're booleans doesn't let the logic above
				//  work correctly for "turning on" a field
				if (isArchived !== undefined) {
					formData.set('isArchived', isArchived);
				}
				if (useSMSConsent !== undefined) {
					formData.set('useSMSConsent', useSMSConsent);
				}
				if (useEmailConsent !== undefined) {
					formData.set('useEmailConsent', useEmailConsent);
				}
				if (useSMS !== undefined) {
					formData.set('useSMS', useSMS);
				}
				if (useEmail !== undefined) {
					formData.set('useEmail', useEmail);
				}

				// set images only if they are files.  if they are strings, they are most likely the previous uri
				// this is so that we don't overwrite the image on subsequent saves
				if (typeof offerImage?.name === 'string') {
					formData.set('offerImage', offerImage);
				}
				if (typeof thankYouImage?.name === 'string') {
					formData.set('thankYouImage', thankYouImage);
				}
				if (typeof shareImage?.name === 'string') {
					formData.set('shareImage', shareImage);
				}

				const data = yield self.updateCampaignSettings({
					campaignId,
					formData,
				});

				const campaignSettings = data?.campaign;

				if (campaignSettings.id) {
					self.__displayFlowSuccess('Campaign Settings updated');
				} else {
					throw data.message;
				}

				self.Collections.Campaigns.updateCampaign(campaignId, campaignSettings);
			} catch (e) {
				console.warn(e);
			} finally {
				self.Orchestrators.View.setIsLoading('updateCampaign', false);
			}
		}),
		/**
		 * scrub the influencer's data and update the influencer collection.
		 */
		anonymizeInfluencerFlow: flow(function*({ influencerId, campaignId }) {
			try {
				if (!influencerId || !campaignId) return;
				const data = yield self.anonymizeInfluencer({
					influencerId,
					campaignId,
				});
				self.Collections.Influencers.updateInfluencer(
					influencerId,
					data.influencer
				);
				self.__displayFlowSuccess('Influencer data removed');
				return data.influencer;
			} catch (e) {
				console.warn(e);
				self.__displayFlowFailure(e.message || e);
			}
		}),
		updateInfluencerFlow: flow(function*(
			body,
			influencerId = self.Orchestrators.Focused.influencerId,
			campaignId = self.Orchestrators.Focused.campaignId
		) {
			try {
				const data = yield self.updateInfluencer({
					influencerId,
					campaignId,
					body,
				});
				if (!data.success) {
					throw data.message;
				}
				self.Collections.Influencers.updateInfluencer(
					influencerId,
					data.influencer
				);

				return data.influencer;
			} catch (e) {
				console.warn(e);
				self.__displayFlowFailure(e.message || e);
			}
		}),
		/**
		 * @static
		 * @generator
		 * @function
		 * @param {object} values the user information
		 * @param {userId} [string] the user to update
		 * @returns {void}
		 * @description
		 * Updates a user's account information
		 */
		updateUserSettingsFlow: flow(function*(
			values = {},
			userId = self.Orchestrators.Focused.userId
		) {
			try {
				self.Orchestrators.View.setIsLoading('userSettings');
				const { password, ...body } = values;
				// sanity check for empty passwords
				if (!!password) {
					body.password = password;
				}

				delete body.baseUrl;
				delete body.userId;

				const data = yield self.updateUser({ userId, body });
				const userData = data.user;

				if (!data.success) {
					throw data.message;
				}
				if (userData) {
					self.__displayFlowSuccess('User Settings updated!');
				}
				self.Collections.Users.updateUser(userId, userData);
			} catch (e) {
				console.warn(e);
				self.__displayFlowFailure(e.message || e);
			} finally {
				self.Orchestrators.View.setIsLoading('userSettings', false);
			}
		}),
		/**
		 * @static
		 * @generator
		 * @function
		 * @param {object} values the user information
		 * @param {'active'|'disabled'} values.status
		 * @param {boolean} values.isArchived
		 * @param {userId} [string] the user to update
		 * @returns {void}
		 * @description
		 * Updates a user's status.  Essentially the same thing as updating a user's settings.
		 * Separating it out makes it a bit easier to read
		 */
		updateUserStatusFlow: flow(function*(
			values = {},
			userId = self.Orchestrators.Focused.userId
		) {
			try {
				self.Orchestrators.View.setIsLoading('userSettings');
				const userData = yield self.updateUser({
					body: values,
					userId,
				});
				if (userData) {
					self.Orchestrators.View.addNotification({
						text: 'User Status updated!',
						type: 'success',
					});
				}
				self.Collections.Users.updateUser(userId, userData);
			} catch (e) {
				console.warn(e);
			} finally {
				self.Orchestrators.View.setIsLoading('userSettings', false);
			}
		}),
		/**
		 * @deprecated
		 */
		checkInfluencerProcessingFlow: flow(function*(campaignId) {
			try {
				const processingSettings = yield self.checkInfluencerProcessing({
					campaignId,
				}) || {};
				const campaignSettings = yield self.resolveCampaignFlow(campaignId);
				self.Collections.Campaigns.updateCampaign(campaignId, {
					...campaignSettings,
					...processingSettings,
				});
				return processingSettings;
			} catch (e) {
				console.warn(e);
			}
		}),
		updateAffiliateSettingsFlow: flow(function*(
			body,
			affiliateId = self.Orchestrators.Focused.affiliateId
		) {
			try {
				self.Orchestrators.View.setIsLoading('affiliateSettings');
				const data = yield self.updateAffiliate({
					affiliateId,
					body,
				});
				if (!data.success) {
					throw data.message;
				}
				self.Collections.Affiliates.updateAffiliate(
					affiliateId,
					data.affiliate
				);
			} catch (e) {
				console.warn(e);
				self.__displayFlowFailure(e.message || e);
			} finally {
				self.Orchestrators.View.setIsLoading('affiliateSettings', false);
			}
		}),
		updateClientSettingsFlow: flow(function*(
			body,
			clientId = self.Orchestrators.Focused.clientId
		) {
			let finalClient,
				finalMessage = null;
			try {
				self.Orchestrators.View.setIsLoading('clientSettings');
				const { client, message, success } = yield self.updateClient({
					clientId,
					body,
				});
				/**
				 * error updating
				 */
				if (!success) {
					throw message;
				}

				finalClient = client;
				finalMessage = message;

				if (client.allowSMS && !client.smsPhone && !client.messageServiceSID) {
					const { client, success, message } = yield self.createClientUseCase({
						clientId: finalClient.id,
					});

					/**
					 * error with use case
					 */
					if (!success) {
						throw message;
					}
					finalClient = client;
					finalMessage = `${finalMessage}. ${message}`;
				}

				self.__displayFlowSuccess(finalMessage);
				self.Collections.Clients.updateClient(clientId, finalClient);
			} catch (e) {
				console.warn(e);
				self.__displayFlowFailure(e.message || e);
			} finally {
				self.Orchestrators.View.setIsLoading('clientSettings', false);
			}
		}),
	}))
	/** Other actions */
	.actions((self) => ({
		updateStatsRange({ from, to }) {
			self.Orchestrators.DashboardStats.setDateRange({ from, to });
		},
		updateStatsFilters({ affiliateId, clientId, campaignId }) {
			self.Orchestrators.DashboardStats.setFilters({
				affiliateId,
				clientId,
				campaignId,
			});
		},
		exportDailyStats() {
			const csv = self.Collections.Stats.getCSV({
				formatTitles: capitalize,
			});
			const { From, To } = self.Orchestrators.DashboardStats.DateRange;
			const from = From.raw;
			const to = To.raw;
			const title = `ICS_Daily_Stats-${from}-${to}.csv`;
			if (csv) {
				self.Orchestrators.View.addNotification({
					text: 'Daily Stats download started',
					type: 'emphasis',
				});
			}
			exportToCSV(csv, title);
		},
	}));

export default RootStore;
