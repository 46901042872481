import React from 'react';
import { observer } from 'mobx-react-lite';
import { Div } from '@icecreamsocial/components';

import FilterForm from './components/FilterForm';
import { RewardsFiltersProvider } from './components/FilterProvider';
import DataView from './components/DataView';

const Rewards = () => {
	return (
		<RewardsFiltersProvider>
			<Div display='flex'>
				<Div elWidth='100%'>
					<DataView />
				</Div>
				<Div as='aside' elWidth='25%' marginLeft='lg' marginBottom='lg'>
					<Div position='sticky' positionTop='0'>
						<FilterForm />
					</Div>
				</Div>
			</Div>
		</RewardsFiltersProvider>
	);
};

Rewards.displayName = 'Campaigns.Campaign.Reports.Rewards';
export default observer(Rewards);
