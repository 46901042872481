import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Tooltip, withStyles } from '@material-ui/core';
import { Div, Panel, Typography, Select } from '@icecreamsocial/components';
import { formatISO, startOfMonth } from 'date-fns';

import { useStore } from '../../../../../utils/hooks';
import { statPropMap } from '../../utils';
import { useDashboardFilters } from '../../components/FilterProvider';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../../../../../queries/stats';
import { Chart } from '../Chart';

const DEFAULT_STATS = {
	totalRevenue: 0,
	totalConversions: 0,
	totalInvitesClicked: 0,
	totalInfluencers: 0,
	totalUtilizedInfluencers: 0,
	totalUtilizationRate: 0,
};

const defaultDates = (state) => {
	const today = Date.now();
	return {
		fromDate: state.fromDate || formatISO(startOfMonth(today)),
		toDate: state.toDate || formatISO(today),
	};
};

const TempToolStyled = withStyles(() => ({
	arrow: {
		color: '#222',
	},
	tooltip: {
		lineHeight: '1.6em',
		backgroundColor: '#222',
		color: '#fff',
		fontWeight: '500',
		fontSize: '0.8rem',
		wordWrap: 'break-word',
		borderRadius: '16px',
		textAligin: 'center',
		padding: '8px 12px',
	},
}))(Tooltip);

const Block = ({ label, tooltipLabel, stat = 'No Data' }) => (
	<Panel textAlign='center' backgroundColor='ambient'>
		<Panel.Body
			display='flex'
			flexDirection='column'
			alignItems='center'
			style={{ gap: '12px' }}
		>
			<TempToolStyled
				arialLabel='info'
				title={tooltipLabel}
				placement='top'
				arrow
			>
				<Typography.Span
					typoSize='sm'
					borderBottom='1px dashed'
					borderColor='ambientDark'
					elWidth='fit-content'
					style={{ cursor: 'help' }}
				>
					{label}
				</Typography.Span>
			</TempToolStyled>
			<Typography.Span typoWeight='500' typoSize='lg'>
				{stat}
			</Typography.Span>
		</Panel.Body>
	</Panel>
);

const Stats = () => {
	const store = useStore();
	const { state, update } = useDashboardFilters();

	const { isLoading: isStatsLoading, data: dailyStats, refetch } = useQuery(
		queryKeys.getDailyStats({
			affiliateId: store.Orchestrators.Auth.user.affiliateId,
			clientId: store.Orchestrators.Auth.user.clientId || null,
			...state,
			...defaultDates(state),
		}),
		async () => {
			const { items } = await store.getDailyStats({
				affiliateId: store.Orchestrators.Auth.user.affiliateId,
				clientId: store.Orchestrators.Auth.user.clientId || null,
				...state,
				...defaultDates(state),
			});

			const reduced = poolStats(items);

			return Object.keys(DEFAULT_STATS).reduce(
				(stats, key) => ({
					...stats,
					[key]: reduced.map((datum) => ({
						key,
						value: datum[key],
						date: datum.date,
					})),
				}),
				{}
			);
		},

		{ refetchOnWindowFocus: true }
	);

	const { isLoading: isOverviewLoading, data: dailyOverview } = useQuery(
		queryKeys.getStatsOverview({
			affiliateId: store.Orchestrators.Auth.user.affiliateId,
			clientId: store.Orchestrators.Auth.user.clientId || null,
			...state,
			...defaultDates(state),
		}),
		async () => {
			const { stats } = await store.getDailyStatsOverview({
				affiliateId: store.Orchestrators.Auth.user.affiliateId,
				clientId: store.Orchestrators.Auth.user.clientId || null,
				...state,
				...defaultDates(state),
			});
			return stats;
		},
		{ refetchOnWindowFocus: true }
	);

	const handleChartSelect = async (e) => {
		update({ chart: e.value });
	};

	const chartDataProp = state.chart || 'totalRevenue';
	return (
		<Div
			grid
			gridColumns='repeat(3, 1fr)'
			gridColumnGap='12px'
			gridRowGap='12px'
		>
			{Object.keys(DEFAULT_STATS).map((val) => (
				<Block
					key={`${val}-panel`}
					label={statPropMap[val].label}
					tooltipLabel={statPropMap[val].tooltipLabel}
					overviewIsLoading={isOverviewLoading}
					stat={formatStatValue(val, dailyOverview)}
				/>
			))}
			<Select
				onChange={handleChartSelect}
				defaultValue={{
					label: statPropMap[chartDataProp].label,
					value: chartDataProp,
				}}
				options={Object.keys(DEFAULT_STATS).map((val) => ({
					label: statPropMap[val].label,
					value: val,
				}))}
			/>

			<Chart
				gridColumn='span 3'
				isLoading={isStatsLoading}
				name={chartDataProp}
				yLabel={statPropMap[chartDataProp].label}
				yFormat={statPropMap[chartDataProp].yFormat}
				backgroundColor={statPropMap[chartDataProp].bgColor}
				color={statPropMap[chartDataProp].color}
				data={formatStats(dailyStats?.[chartDataProp])}
			/>
		</Div>
	);
};
Stats.displayName = 'Dashboard.Stats';

function formatStats(stats = []) {
	return stats.map(({ date, value }) => ({
		x: date,
		y: value,
	}));
}

type Stat = {
	date: string;
	totalConversions: number;
	totalInfluencers: number;
	totalInvitesClicked: number;
	totalRevenue: number;
	totalUtilizedInfluencers: number;
	totalUtilizationRate: number;
};

/**
 * create bins depending on amount of stats (1 item = 1 daily stats)
 */
function poolStats(items: Stat[]): Stat[] {
	const totalDays = items.length;
	const toPool = [...items];
	const pooled = [];

	let curDate = null,
		index = -1;

	while (toPool.length) {
		const { date, ...rest } = toPool.shift();
		let toMatch = date.split('/');
		// break down by year if 2 years or more
		if (totalDays > 730) {
			toMatch = [toMatch[2]];
		}
		// break down by month
		else if (totalDays > 365) {
			toMatch.splice(1, 1);
		}
		// break down by week
		// else if (totalDays <= 61) {}
		// // break down by day
		const matcher = toMatch.join('/');
		if (matcher !== curDate) {
			curDate = matcher;
			pooled.push([]);
			index++;
		}
		pooled[index].push({ date: toMatch.join('/'), ...rest });
	}

	const reduced = pooled.map((pool) =>
		pool.reduce(
			(
				acc,
				{
					date,
					totalInfluencers,
					totalUtilizedInfluencers,
					totalConversions,
					totalInvitesClicked,
					totalRevenue,
				}
			) => ({
				date,
				totalConversions: acc.totalConversions + totalConversions,
				totalInfluencers: acc.totalInfluencers + totalInfluencers,
				totalInvitesClicked: acc.totalInvitesClicked + totalInvitesClicked,
				totalRevenue: acc.totalRevenue + totalRevenue,
				totalUtilizedInfluencers:
					acc.totalUtilizedInfluencers + totalUtilizedInfluencers,
				// can't total rates, so taking the average rate
				totalUtilizationRate:
					(acc.totalUtilizedInfluencers + totalUtilizedInfluencers) /
						(acc.totalInfluencers + totalInfluencers) || 0,
			}),
			{
				totalInfluencers: 0,
				totalUtilizedInfluencers: 0,
				totalUtilizationRate: 0,
				totalConversions: 0,
				totalInvitesClicked: 0,
				totalRevenue: 0,
			}
		)
	);

	return reduced;
}

function formatStatValue(stat, data): string {
	if (!data) return '0';

	const value = data[stat];
	switch (stat) {
		case 'totalRevenue': {
			return value.toLocaleString('en-US', {
				style: 'currency',
				currency: 'USD',
				minimumFractionDigits: 0,
			});
		}
		case 'totalUtilizationRate': {
			return (value / 100).toLocaleString('en-US', {
				style: 'percent',
				minimumFractionDigits: 0,
			});
		}
		default: {
			return value.toLocaleString('en-US');
		}
	}
}

export default observer(Stats);
