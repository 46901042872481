import React, { useContext, Suspense } from 'react';
import { observer } from 'mobx-react-lite';
import {
	Div,
	MediaCard,
	Img,
	Typography,
	List,
} from '@icecreamsocial/components';
import { CampaignCard, StandalonePagination } from '../';

const CampaignCardGrid = ({
	campaigns = [],
	emptyComponent: EmptyComponent = undefined,
	isClient = true,
	paginationConfig = {},
	...rest
}) => {
	const imageBase = process.env.BASE_IMAGE_ENDPOINT;
	return campaigns.length ? (
		<>
			<List bullets="none" display="flex" flexWrap="wrap" {...rest}>
				{campaigns.map((campaign) => (
					<CampaignCard
						as={List.Item}
						id={campaign.id}
						key={campaign.id}
						img={
							campaign.offerImage
								? `${imageBase}/${campaign.offerImage}`
								: undefined
						}
						imgAlt={campaign.name}
						title={campaign.name}
						client={campaign.clientName}
						status={campaign.status}
						isArchived={campaign.isArchived}
						isClient={isClient}
						margin="0 8px"
						elWidth={['100%', `calc(50% - 16px)`, `calc(33% - 16px)`]}
					/>
				))}
			</List>
			<StandalonePagination collection={campaigns} {...paginationConfig} />
		</>
	) : EmptyComponent ? (
		<EmptyComponent />
	) : (
		<div>No campaigns</div>
	);
};
CampaignCardGrid.displayName = 'CampaignCardGrid';
export default observer(CampaignCardGrid);
