import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import {
	Div,
	Button,
	InputGroup,
	List,
	Panel,
	Typography,
} from '@icecreamsocial/components';
import { useFormik } from 'formik';
import {
	BusinessPaymentProcessorForm,
	EmphasizedAction,
	Pagename,
} from '../../../../presentational';
import { useStore } from '../../../../../utils/hooks';

const PaymentProcessor = ({ beforeRedirect, onComplete, returnTo }) => {
	const store = useStore();

	/** @todo try to grab the processor here */
	const chosenProcessor = store.userProcessorType;

	return (
		<Div>
			<Pagename>Reward Processing</Pagename>
			<Typography.P>
				Connecting with a reward processing option allows you to issue appealing
				campaign rewards to influencers
			</Typography.P>
			<BusinessPaymentProcessorForm
				processor={chosenProcessor}
				beforeRedirect={beforeRedirect}
			/>
			{!!chosenProcessor ? (
				<Panel
					backgroundColor='brandLight'
					marginBottom='lg'
					shadow='none'
					border='none'
				>
					<Panel.Body>
						<Typography.Span typoWeight='bold'>You're all set!</Typography.Span>
						<Typography.P>
							We've got everything we need, one more click and your Business
							Settings are good to go
						</Typography.P>
						<EmphasizedAction type='button' onClick={onComplete}>
							Complete Business Setup
						</EmphasizedAction>
					</Panel.Body>
				</Panel>
			) : (
				<Panel backgroundColor='ambient' marginBottom='lg' shadow='none'>
					<Panel.Body display='flex' alignItems='center'>
						<Div flexGrow='1'>
							<Typography.Span typoWeight='bold'>
								Don’t see one you can use?
							</Typography.Span>
							<Typography.P>
								You can skip this step and set one up later but you’ll have to
								issue rewards manually in the meantime
							</Typography.P>
						</Div>
						<Button
							type='button'
							onClick={onComplete}
							flexShrink='0'
							textDecoration='none'
							textColor='body'
							backgroundColor='ambientLight'
						>
							Skip and review settings
						</Button>
					</Panel.Body>
				</Panel>
			)}
		</Div>
	);
};
PaymentProcessor.displayName = 'BusinessSetUp.PaymentProcessor';

export default observer(PaymentProcessor);
