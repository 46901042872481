import styled from 'styled-components';
import { BaseModalBackground } from 'styled-react-modal';

/**
 * @todo document this
 */
const BlankModalBg = styled(BaseModalBackground)`
	background-color: transparent;
`;

BlankModalBg.displayName = 'BlankModalBg';

export default BlankModalBg;
