import React, { useEffect } from 'react';
import { IcsV1 } from '@icecreamsocial/api-resources';
/**
 * @module useRequestInterceptor
 * @category Shared
 * @subcategory Hooks
 * @exports  useRequestInterceptor
 */

/**
 * @todo document this
 * @function
 * @param {*} onRequest
 * @param {*} onError
 */
export const useRequestInterceptor = (
	onRequest = (next) => {},
	onError = (err) => {}
) =>
	useEffect(() => {
		IcsV1.V1Resource.interceptors.request.use(onRequest, onError);
	}, []);
