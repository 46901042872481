import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Div, Typography } from '@icecreamsocial/components';
import GeneralSettingsForm from '../../../../components/GeneralSettingsForm';
import {
	SteppedFormControls,
	Pagename,
} from '../../../../../../presentational';

const General = ({ handleSubmit, returnTo, savedValues = {} }) => {
	return (
		<Div>
			<Pagename>General Settings</Pagename>
			<GeneralSettingsForm
				onSubmit={handleSubmit}
				allowRouteBlock={false}
				renderControls={() => (
					<SteppedFormControls first={true} onNav={returnTo} />
				)}
				{...savedValues}
			/>
		</Div>
	);
};
General.displayName = 'Campaigns.Setup.General';

export default observer(General);
