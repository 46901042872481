import React, { useState, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Div, Typography, Tag } from '@icecreamsocial/components';
import { Link } from 'react-router-dom';

const MarketingConsentSummary = ({ emailConsent, smsConsent, editLink }) => {
  const consentProps = { textColor: 'good', borderColor: 'good' };
  const nonConsentProps = { textColor: 'ambientDark' };

  const emailProps = useMemo(
    () => (emailConsent ? consentProps : nonConsentProps),
    [emailConsent]
  );
  const smsProps = useMemo(
    () => (smsConsent ? consentProps : nonConsentProps),
    [smsConsent]
  );

  return (
    <Div borderBottom='1px solid' borderColor='ambientLight' marginBottom='md'>
      <Div display='flex' justifyContent='space-between' alignItems='center'>
        <Typography.P textColor='ambientDark'>Marketing Consent</Typography.P>
        <Typography.A as={Link} to={editLink} typoWeight='600'>
          Edit
        </Typography.A>
      </Div>
      <Tag
        {...emailProps}
        padding='sm'
        marginBottom='sm'
        marginRight='sm'
        typoSize='sm'
        display='block'
      >
        {emailConsent ? 'Email subscribed' : 'Email not subscribed'}
      </Tag>
      <Tag
        {...smsProps}
        padding='sm'
        marginBottom='md'
        typoSize='sm'
        display='block'
      >
        {smsConsent ? 'SMS subscribed' : 'SMS not subscribed'}
      </Tag>
    </Div>
  );
};
MarketingConsentSummary.displayName = 'InfluencerManager';

export default observer(MarketingConsentSummary);
