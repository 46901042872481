import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { HexColorInput, HexColorPicker } from 'react-colorful';
import { Div, InputGroup, Panel, Button } from '@icecreamsocial/components';

const ColorPickerPopover = styled(Div)`
	&.react-colorful {
		${({ elWidth }) =>
			elWidth &&
			css`
				width: ${elWidth};
			`}
	}
`;

const ColorPicker = ({ defaultColor, onChange }) => {
	const [color, setColor] = useState(defaultColor);
	const [pickerVisible, setPickerVisible] = useState(false);

	/**
	 * opens the color picker
	 * @param {React.MouseEvent} e
	 */
	const handlePickerFocus = (e) => {
		setPickerVisible(true);
	};

	/**
	 * Focuses the
	 * @param {React.UIEvent<HTMLInputElement>} e
	 */
	const selectText = (e) => {
		e.currentTarget.select();
	};

	/**
	 * Closes the picker and resets the color to the default
	 * @param {React.MouseEvent} e
	 */
	const onCancel = (e) => {
		e.preventDefault();
		setColor(defaultColor);
		setPickerVisible(false);

		if (typeof onChange === 'function') {
			onChange(defaultColor);
		}
	};

	/**
	 * Closes the picker and passes the color to the onChange function
	 * @param {React.MouseEvent} e
	 */
	const onApply = (e) => {
		e.preventDefault();
		setPickerVisible(false);

		if (typeof onChange === 'function') {
			onChange(color);
		}
	};

	return (
		<Div position='relative'>
			<InputGroup display='flex' alignItems='center' style={{ gap: '8px' }}>
				<Div
					backgroundColor={color}
					elWidth='28px'
					elHeight='28px'
					border='1px solid'
					borderColor='ambientDark'
					borderRadius='6px'
				/>
				<InputGroup.Fields
					display='flex'
					alignItems='center'
					position='relative'
					style={{ gap: '8px' }}
				>
					<InputGroup.Icon typoWeight='bold'>#</InputGroup.Icon>
					<InputGroup.Input
						as={HexColorInput}
						onFocus={handlePickerFocus}
						color={color}
					/>
				</InputGroup.Fields>
			</InputGroup>
			{!!pickerVisible && (
				<Panel
					position='absolute'
					positionBottom='0'
					shadow='higher'
					overflow='hidden'
					backgroundColor='rgba(187,187,187,.3)'
					zIndex='navigation'
					style={{
						backdropFilter: 'blur(5px)',
					}}
				>
					<Panel.Body padding='sm'>
						<ColorPickerPopover
							as={HexColorPicker}
							color={color}
							onChange={setColor}
							elWidth='100%'
							marginBottom='md'
						/>
						<InputGroup elWidth='auto' marginBottom='md'>
							<InputGroup.Fields>
								<InputGroup.Icon typoWeight='bold'>#</InputGroup.Icon>
								<InputGroup.Input
									as={HexColorInput}
									color={color}
									onChange={setColor}
									marginLeft='sm'
									onFocus={selectText}
									autoFocus
								/>
							</InputGroup.Fields>
						</InputGroup>
						<Div display='flex' style={{ gap: '8px' }}>
							<Button
								onClick={onApply}
								type='button'
								paddingTop='sm'
								paddingBotom='sm'
								backgroundColor='brand'
								textColor='bodyInverse'
							>
								Apply
							</Button>
							<Button
								onClick={onCancel}
								type='button'
								padding='sm'
								backgroundColor='bodyInverse'
							>
								Cancel
							</Button>
						</Div>
					</Panel.Body>
				</Panel>
			)}
		</Div>
	);
};

export default ColorPicker;
