import React from "react";
import { observer } from "mobx-react-lite";
import { Div, Typography, Panel } from "@icecreamsocial/components";
import { PopsicleLoader } from "../../../../../presentational";

const ProcessingLoader = ({}) => {
  return (
    <Panel elHeight="100%" backgroundColor="bodyInverse">
      <Panel.Body display="flex" alignItems="center" justifyContent="center">
        <Div textAlign="center">
          <PopsicleLoader />
          <Typography.H2
            typoSize="md"
            typoWeight="400"
            marginTop="md"
            marginBottom="sm"
          >
            Processing Reward
          </Typography.H2>
          <Typography.P typoSize="sm">Just a moment</Typography.P>
        </Div>
      </Panel.Body>
    </Panel>
  );
};
ProcessingLoader.displayName = "InfluencerManager.ProcessingLoader";

export default observer(ProcessingLoader);
