import React, { useMemo } from 'react';
import { FormikProps } from 'formik';
import { observer } from 'mobx-react-lite';
import { FormControlLabel, Radio } from '@material-ui/core';
import { InputGroup, Panel, Typography } from '@icecreamsocial/components';

import { AffiliateSelector } from '../../../../../containers';
import { RequiredAsterisk } from '../../../..';
import { useUserSettingsForm } from '../../..';
/**
 * @module AffiliateSelectorToggle
 * @category Components
 * @subcategory Presentational
 * @requires module:UserSettingsForm
 * @requires module:UserRole
 */

/**
 * @name AffiliateSelectorToggle
 * @description
 * A radio that shows or hides the affiliate.  If selected, this will set the `role` to `'affiliate'` and the selector will be required
 * If readonly, the radio will not be shown but the selector will in a readonly state.
 */

const AffiliateSelectorToggle = (props) => {
	const {
		form,
		userAffiliateId,
		isEditing: isReadonly,
	} = useUserSettingsForm();
	return (
		<Panel
			border={form.values.role === 'affiliate' ? 'default' : 'none'}
			backgroundColor={
				form.values.role === 'affiliate' ? 'bodyInverse' : 'ambientLight'
			}
			{...props}
		>
			<Panel.Body>
				{isReadonly ? (
					<Typography.Span typoWeight="bold">Affiliate User</Typography.Span>
				) : (
					<FormControlLabel
						control={
							<Radio
								checked={form.values.role === 'affiliate'}
								name="role"
								value="affiliate"
								onChange={form.handleChange}
								color="primary"
								required
							/>
						}
						label={
							<Typography.Span typoWeight="bold">
								Affiliate User
							</Typography.Span>
						}
					/>
				)}
				<Typography.P>
					Affiliate Users have the ability to create and edit clients and
					sub-affiliates. They can view data related to their clients' campaigns
					but cannot modify them or issue rewards.
				</Typography.P>
				{form.values.role === 'affiliate' && (
					<InputGroup elWidth="100%" marginBottom="sm">
						<InputGroup.Label>
							Affiliate <RequiredAsterisk />
						</InputGroup.Label>
						<AffiliateSelector
							as="select"
							id="affiliateId"
							name="affiliateId"
							affiliateId={userAffiliateId}
							value={form.values.affiliateId}
							onChange={form.handleChange}
							paddingLeft="md"
							readOnly={isReadonly}
							disabled={isReadonly}
						/>
					</InputGroup>
				)}
			</Panel.Body>
		</Panel>
	);
};
AffiliateSelectorToggle.displayName =
	'UserSettingsForm.UserRole.AffiliateSelectorToggle';

export default observer(AffiliateSelectorToggle);
