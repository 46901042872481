import { createQueryKeys } from '@lukemorales/query-key-factory';

export const queryKeys = createQueryKeys('influencers', {
	/**
	 * @param {Partial<InfluencerQueries.List>} params
	 * @returns
	 */
	list: ({
		campaignId,
		search = undefined,
		createdDateGTOE = undefined,
		createdDateLTOE = undefined,
		goalReached = undefined,
		status = undefined,
		invitesGTOE = undefined,
		invitesLTOE = undefined,
		invitesClickCountGTOE = undefined,
		invitesClickCountLTOE = undefined,
		conversionsGTOE = undefined,
		conversionsLTOE = undefined,
		revenueGTOE = undefined,
		revenueLTOE = undefined,
		isAnonymized = false,
		skip = 0,
		limit = 10,
	}) => ({
		hideInfluencerWithoutShare: false,
		campaignId,
		search,
		createdDateGTOE,
		createdDateLTOE,
		goalReached,
		status,
		invitesGTOE,
		invitesLTOE,
		invitesClickCountGTOE,
		invitesClickCountLTOE,
		conversionsGTOE,
		conversionsLTOE,
		revenueGTOE,
		revenueLTOE,
		isAnonymized,
		skip,
		limit,
	}),
	/**
	 * Gets a single influencer by id
	 * @param {InfluencerQueries.Get} params
	 * @returns
	 */
	getInfluencer: ({ campaignId, influencerId }) => ({
		campaignId,
		influencerId,
	}),
	/**
	 * Anonymizes an influencer
	 * @param {InfluencerQueries.Anonymize} params
	 */
	anonymize: ({ campaignId, influencerId }) => ({
		campaignId,
		influencerId,
	}),
	review: ({ campaignId, influencerId }) => ({
		campaignId,
		influencerId,
	}),
	/**
	 * Processes an influencer
	 * @param {InfluencerQueries.Process} params
	 */
	process: ({ campaignId, influencerId }) => ({
		campaignId,
		influencerId,
	}),
	/**
	 * Updates an influencer
	 * @param {Partial<InfluencerQueries.Update>} params
	 * @returns
	 */
	update: ({
		campaignId,
		influencerId,
		identifier,
		name,
		email,
		mobileNumber,
		emailConsent,
		smsConsent,
	}) => ({
		campaignId,
		influencerId,
		identifier,
		name,
		email,
		mobileNumber,
		emailConsent,
		smsConsent,
	}),
	/**
	 * @todo move this to conversion queries
	 * @param {InfluencerQueries.ListConversions} params
	 * @returns
	 */
	listConversions: ({ campaignId, influencerId, limit }) => ({
		campaignId,
		influencerId,
		limit,
	}),
	/**
	 * Cancels a conversion for an influencer
	 * @param {InfluencerQueries.CancelConversions} params
	 */
	cancelConversions: ({ campaignId, influencerId, conversionId }) => ({
		campaignId,
		influencerId,
		conversionId,
	}),
	/**
	 * Exports a csv of the influencer report
	 * @param {InfluencerQueries.ExportConversions} params
	 */
	exportInfluencerReport: (params) => ({ ...params }),
});
