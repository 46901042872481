import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Menu, MenuItem } from '@material-ui/core';
import { Button, Div, Img, List, Typography } from '@icecreamsocial/components';
import { NavbarLink, ReleaseNotes } from '../';
import { useStore, useTheme } from '../../../utils/hooks';
//TODO remove store jocelyn

const PrimaryNav = ({ routes }) => {
	const store = useStore();
	const { colors } = useTheme();

	const [anchorEl, setAnchorEl] = useState(null);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleLogout = () => {
		store.logoutFlow();
	};

	return (
		<Div
			as='nav'
			position='sticky'
			positionTop='0'
			display='flex'
			flexDirection='column'
			textColor='bodyInverse'
			padding='md'
			elHeight='100vh'
			backgroundGradientColor={`128deg, ${colors.brand} 0%, ${colors.accent} 66%, ${colors.accentDark} 100%`}
		>
			<Div
				border='2px solid'
				borderColor='bodyInverse'
				borderRadius='50%'
				margin='auto'
				elWidth='80px'
			>
				<Img
					src='https://d23qvn3b2w9mew.cloudfront.net/img/ics-logo.png'
					alt='Ice Cream Social logo'
				/>
			</Div>
			<Div marginTop='xlg' flexGrow='1'>
				<List bullets='none' padding='none'>
					{routes.map(({ icon: Icon, active, to, name }, i) =>
						to ? (
							<List.Item key={i}>
								<NavbarLink
									data-slate-navbar={name?.toLowerCase()}
									to={to}
									active={active}
								>
									<Icon marginRight='sm' />
									{name}
								</NavbarLink>
							</List.Item>
						) : null
					)}
				</List>
			</Div>
			<ReleaseNotes
				display='block'
				elWidth='100%'
				marginBottom='md'
				textAlign='center'
				textColor='bodyInverse'
				visitedTextColor='bodyInverse'
			/>
			<Button
				onClick={handleClick}
				aria-controls='user-menu'
				aria-haspopup='true'
				backgroundColor='bodyInverse'
				borderRadius='50%'
				margin='auto'
				marginBottom='lg'
				elWidth='50px'
				elHeight='50px'
				display='flex'
				textColor='body'
				alignItems='center'
				justifyContent='center'
			>
				<Typography.Span>{store.userInitials}</Typography.Span>
			</Button>
			<Menu
				id='user-menu'
				anchorEl={anchorEl}
				keepMounted
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<Typography.Span display='block' padding='md'>
					{store.userName}
				</Typography.Span>
				<MenuItem onClick={handleLogout}>Logout</MenuItem>
			</Menu>
		</Div>
	);
};

PrimaryNav.displayName = 'PrimaryNav';

export default observer(PrimaryNav);
