import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Switch, Redirect, Route, useRouteMatch } from 'react-router-dom';
import { Container } from '@icecreamsocial/components';
import { useRouteConfig, useStore } from '../../../utils/hooks';
import NoBusinessState from './components/NoBusinessState';
import CampaignsList from './sub-routes/List';
import Campaign from './sub-routes/Campaign';
import CreateCampaign from './sub-routes/Create';
import { PageMeta } from '../../presentational';

const Campaigns = () => {
	const store = useStore();
	const { path } = useRouteMatch();
	const accessibleRoutes = [
		{
			component: CampaignsList,
		},
		{
			path: `view`,
			component: () => <Redirect to={`${path}`} />,
			exact: true,
		},
		{
			path: `view/:campaignId`,
			component: Campaign,
			exact: false,
		},
	];

	const routeConfig = useMemo(() => {
		const accessible = [...accessibleRoutes];
		if (store.userRole !== 'affiliate') {
			accessible.push({
				path: `create`,
				component: CreateCampaign,
				exact: false,
			});
		}
		return accessible;
	}, [store.userRole]);

	const { routes } = useRouteConfig(routeConfig);

	return (
		<>
			<PageMeta
				title={'Campaigns | Ice Cream Social'}
				description={'Manage your campaigns'}
			/>
			{store.needsBusinessSetup ? (
				<Container>
					<NoBusinessState />
				</Container>
			) : (
				<Switch>
					{routes.map((route, i) => (
						<Route key={i} {...route} />
					))}
					<Redirect to={path} />
				</Switch>
			)}
		</>
	);
};

Campaigns.displayName = 'Campaigns.Main';

export default observer(Campaigns);
