export const statPropMap = {
	totalRevenue: {
		bgColor: 'rgb(99,205,218, 0.2)',
		color: 'rgb(99,205,218)',
		label: 'Revenue',
		tooltipLabel: 'The total earnings generated from all referrals',
		yFormat: ' >-$,.2f',
	},
	totalConversions: {
		bgColor: 'rgb(248,165,194, 0.2)',
		color: 'rgb(248,165,194)',
		label: 'Referrals',
		tooltipLabel:
			"Counts the number of purchases or qualifying actions taken from an influencer's invite link",
		yFormat: ' >-,',
	},
	totalInvitesClicked: {
		bgColor: 'rgb(243,166,131, 0.2)',
		color: 'rgb(243,166,131)',
		label: 'Invites Clicked',
		tooltipLabel:
			"Tracks the number of times individuals click on an influencer's invite link",
		yFormat: ' >-,',
	},
	totalInfluencers: {
		bgColor: 'rgb(162,210,143, 0.2)',
		color: 'rgb(162,210,143)',
		label: 'Created Influencers',
		tooltipLabel:
			'Tallies the number of influencers created for your campaigns',
		yFormat: ' >-,',
	},
	totalUtilizedInfluencers: {
		bgColor: 'rgb(245,205,121, 0.2)',
		color: 'rgb(245,205,121)',
		label: 'Utilized Influencers',
		tooltipLabel:
			'The count of influencers who have successfully prompted at least one click on their invites',
		yFormat: ' >-,',
	},
	totalUtilizationRate: {
		bgColor: 'rgb(189,169,234, 0.2)',
		color: 'rgb(189,169,234)',
		label: 'Utilization Rate',
		tooltipLabel:
			'The percentage of created influencers who have generated invite clicks',
		yFormat: ' >-.2%',
	},
};
