"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ic_check = void 0;
var ic_check = {
  "viewBox": "0 0 24 24",
  "children": [{
    "name": "path",
    "attribs": {
      "d": "M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"
    }
  }]
};
exports.ic_check = ic_check;