import React from 'react';
import { observer } from 'mobx-react-lite';
import { Div, Thumbnail, Typography } from '@icecreamsocial/components';
import { useStore } from '../../../utils/hooks';
import { Link } from 'react-router-dom';

const CompanyLogoLink = () => {
	const store = useStore();

	return (
		store.Orchestrators.Auth.role === 'client' && (
			<Div
				as={Link}
				to="/settings"
				display="flex"
				alignItems="center"
				textDecoration="none"
				textColor="body"
			>
				{store.userBusiness ? (
					<>
						{store.userBusinessLogo && (
							<Thumbnail
								marginRight="md"
								elWidth="32px"
								elHeight="32px"
								src={store.userBusinessLogo}
							/>
						)}
						<Typography.Span>{store.userBusiness?.name}</Typography.Span>
					</>
				) : (
					<>
						<Div
							optimistic
							elWidth="32px"
							elHeight="32px"
							marginRight="md"
							backgroundColor="ambientDark"
							borderRadius="5px"
						/>
						<Typography.Span
							optimistic
							elWidth="75px"
							elHeight="1em"
							borderRadius="5px"
						/>
					</>
				)}
			</Div>
		)
	);
};

CompanyLogoLink.displayName = 'CompanyLogoLink';

export default observer(CompanyLogoLink);
