import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { format } from 'date-fns';
import {
	useDownloadLink,
	useStore,
} from '../../../../../../../../../../../utils/hooks';
import { queryKeys } from '../../../../../../../../../../../queries/rewards';
import { withLoader } from '../../../../../../../../../../../utils/HOCs';
import {
	DownloadIcon,
	EmphasizedAction,
} from '../../../../../../../../../../presentational';
import { useRewardsFilters } from '../FilterProvider';
import { useCampaign } from '../../../../../../providers/Campaign';

export const ExportButton = ({ disable = true }) => {
	const { downloadRewardsCSVFlow } = useStore();
	const { campaignId } = useParams();
	const { state } = useRewardsFilters();
	const { campaign } = useCampaign();
	const handleDownload = useDownloadLink();

	const DownloadButton = withLoader(EmphasizedAction);

	const { ...query } = state;

	const { refetch: download, isInitialLoading } = useQuery(
		queryKeys.exportRewardsReport({
			campaignId,
			...query,
		}),
		() =>
			downloadRewardsCSVFlow(campaignId, {
				...query,
			}),
		{ enabled: false }
	);

	const onClick = async () => {
		const { data: csv } = await download();
		handleDownload(
			`data:text/csv;charset=utf-8,${csv}`,
			`ICSRewards - ${campaign.data?.name} (${format(
				Date.now(),
				'yyyy-MM-dd'
			)}).csv`
		);
	};

	return (
		<DownloadButton
			onClick={onClick}
			isLoading={isInitialLoading}
			disabled={disable || isInitialLoading || campaign.isLoading}
			display='flex'
			alignItems='center'
		>
			<DownloadIcon marginRight='sm' /> Export
		</DownloadButton>
	);
};
