import {
	getParent,
	types,
	destroy,
	SnapshotIn,
	Instance,
} from 'mobx-state-tree';

const __NotificationItem = types
	.model({
		id: types.string,
		type: types.enumeration(['error', 'success', 'warning', 'emphasis']),
		text: types.string,
	})
	.named('NotificationItem')
	.actions((self) => ({
		remove() {
			getParent(self, 2).remove(self);
		},
	}));

const NotificationItem = types.snapshotProcessor(__NotificationItem, {
	preProcessor(snap) {
		return { id: `${new Date().getTime() + Math.random()}`, ...snap };
	},
});

/**
 * @module Notifications
 * @category Shared
 * @subcategory Models
 * @description
 * Contains an array of Notification instances
 * @todo document inner methods
 */

const __Notifications = types
	.model({
		items: types.array(NotificationItem),
	})
	.named('Notifications')
	.views((self) => ({
		find(key) {
			return self.items.find(({ id }) => id === key);
		},
	}))
	.actions((self) => ({
		/**
		 *
		 * @param {SnapshotIn<typeof NotificationItem>} item
		 */
		add(item) {
			self.items.push(item);
		},
		/**
		 *
		 * @returns {Instance<typeof NotificationItem>} item
		 */
		pop() {
			return self.items.pop();
		},
		remove(key) {
			const item = self.find(key);
			if (item) destroy(item);
		},
	}));

const Notifications = types.snapshotProcessor(__Notifications, {
	preProcessor(snap) {
		if (!snap) return { items: [] };
		return snap;
	},
});

export default Notifications;
