import React from 'react';
import { observer } from 'mobx-react-lite';
import {
	Div,
	InputGroup,
	Panel,
	Tag,
	Typography,
} from '@icecreamsocial/components';
import { RequiredAsterisk, EnhancedForm } from '../';
import {
	FormControlLabel,
	RadioGroup,
	Radio,
	Checkbox,
} from '@material-ui/core';
import { flags } from '../../../utils';
import { InfoIcon } from '../SupportedIcons';

/**
 * @feature-flags
 */
const ENABLE_MASK_INFO = flags.isActive(
	flags.names.ENABLE_EXPERIMENTAL_MASK_INFO
);
const ENABLE_SMS_MARKETING_TOGGLE = flags.isActive(
	flags.names.ENABLE_SMS_MARKETING_TOGGLE
);

const DataAndPolicyForm = ({
	maskInfo = undefined,
	termsAndConditionsURL = '',
	privacyPolicyURL = '',
	onSubmit = () => undefined,
	allowRouteBlock = true,
	renderControls = null,
}) => {
	const formikProps = {
		enableReinitialize: true,
		initialValues: {
			maskInfo,
			termsAndConditionsURL,
			privacyPolicyURL,
		},
		onSubmit,
	};

	return (
		<EnhancedForm
			formikProps={formikProps}
			promptIf={({ dirty }) => dirty && allowRouteBlock}
			renderControls={renderControls}
		>
			{(policySettings) => (
				<>
					<Panel backgroundColor='bodyInverse' marginBottom='lg'>
						<Panel.Header backgroundColor='bodyInverse'>
							<Typography.H2
								textColor='body'
								textAlign='center'
								margin='none'
								typoSize='md'
							>
								Terms &amp; Policy Settings
							</Typography.H2>
						</Panel.Header>
						<Panel.Body>
							{ENABLE_MASK_INFO === 'true' && (
								<Div marginBottom='sm'>
									<Div paddingTop='sm' paddingBottom='sm'>
										<Typography.P margin='0'>
											Mask Info <RequiredAsterisk />
										</Typography.P>
										<Typography.P as='small'>
											You can hide influencers and invitees contact info so it
											won't appear in the admin
										</Typography.P>
									</Div>
									<RadioGroup
										name='maskInfo'
										value={`${policySettings.values.maskInfo}`}
										onChange={(e, v) => {
											const value = v === 'true' ? true : false;
											policySettings.setFieldValue('maskInfo', value);
										}}
										aria-label='maskInfo'
									>
										<Div
											backgroundColor='ambientLight'
											paddingLeft='sm'
											paddingRight='sm'
											borderRadius='default'
											marginBottom='sm'
										>
											<FormControlLabel
												value='false'
												control={
													<Radio
														id='maskInfo'
														name='maskInfo'
														color='primary'
														required
													/>
												}
												label="Don't hide contact information"
											/>
										</Div>
										<Div
											backgroundColor='ambientLight'
											paddingLeft='sm'
											paddingRight='sm'
											borderRadius='default'
										>
											<FormControlLabel
												value='true'
												control={
													<Radio
														id='maskInfo'
														name='maskInfo'
														color='primary'
														required
													/>
												}
												label='Hide contact information'
											/>
										</Div>
									</RadioGroup>
								</Div>
							)}
							<InputGroup elWidth='100%' marginBottom='sm'>
								<InputGroup.Label>
									Terms &amp; Conditions URL <RequiredAsterisk />
								</InputGroup.Label>
								<InputGroup.Fields>
									<InputGroup.Input
										id='termsAndConditionsURL'
										name='termsAndConditionsURL'
										paddingLeft='md'
										value={policySettings.values.termsAndConditionsURL}
										onChange={policySettings.handleChange}
										required
									/>
								</InputGroup.Fields>
								<Typography.Span as='small'>
									Please enter a complete URL
								</Typography.Span>
							</InputGroup>
							<InputGroup elWidth='100%' marginBottom='sm'>
								<InputGroup.Label>
									Privacy Policy URL <RequiredAsterisk />
								</InputGroup.Label>
								<InputGroup.Fields>
									<InputGroup.Input
										id='privacyPolicyURL'
										name='privacyPolicyURL'
										paddingLeft='md'
										value={policySettings.values.privacyPolicyURL}
										onChange={policySettings.handleChange}
										required
									/>
								</InputGroup.Fields>
								<Typography.Span as='small'>
									Please enter a complete URL
								</Typography.Span>
							</InputGroup>
						</Panel.Body>
					</Panel>
				</>
			)}
		</EnhancedForm>
	);
};
DataAndPolicyForm.displayName = 'DataAndPolicyForm';

export default observer(DataAndPolicyForm);
