import { types, SnapshotIn } from 'mobx-state-tree';
import { Business } from '../../models';

/**
 * @module BusinessCollection
 * @category Shared
 * @subcategory Models
 * @description
 * Contains instances of a Business model
 */

const __BusinessCollection = types
	.model({
		Map: types.map(Business),
	})
	.named('BusinessCollection')
	.views((self) => ({
		has(id) {
			return self.Map.has(id);
		},
		get(id) {
			return self.Map.get(id);
		},
		getType(id) {
			return self.get(id)?.type;
		},
	}))
	.actions((self) => ({
		/**
		 * set an ICS Business
		 * @param {string} id
		 * @param {SnapshotIn<typeof Business>} business
		 */
		set(id, business) {
			self.Map.set(id, business);
		},
		/**
		 * update an ICS Business
		 * @param {string} id
		 * @param {SnapshotIn<typeof Business>} business
		 */
		update(id, business) {
			const prev = self.get(id) || {};
			self.Map.set(id, { ...prev, ...business });
		},
		delete(id) {
			if (self.has(id)) {
				self.Map.delete(id);
			}
		},
		clear() {
			self.Map.clear();
		},
	}));

const BusinessCollection = types.snapshotProcessor(__BusinessCollection, {
	preProcessor(snapshot) {
		return snapshot?.Map ? snapshot : { Map: {} };
	},
});

export default BusinessCollection;
