import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Div, Typography } from '@icecreamsocial/components';

import {
	BusinessPaymentProcessorForm,
	Pagename,
} from '../../../../presentational';
import { useStore } from '../../../../../utils/hooks';
import { OptimisticPaymentProcessor } from '../../../../optimistic';
const PaymentProcessorSettings = () => {
	const store = useStore();
	useEffect(() => {
		if (store.userBusiness) {
			store.getProcessorFlow();
		}
	}, [store.userProcessorId, store.userBusiness]);

	return (
		<Div>
			<Pagename>Reward Processing</Pagename>
			{store.isLoading('processor') ? (
				<OptimisticPaymentProcessor />
			) : (
				<BusinessPaymentProcessorForm processor={store.userProcessorType} />
			)}
		</Div>
	);
};

PaymentProcessorSettings.displayName = 'Settings.Business.PaymentProcessor';
export default observer(PaymentProcessorSettings);
