import React from 'react';
import { Button, Circle } from '@icecreamsocial/components';
import { ArrowBackIcon } from '../SupportedIcons';

/**
 * @promote to library
 * @param {*} param0
 * @returns
 */
const CloseButton = ({ ...rest }) => (
  <Circle
    as={Button}
    type='button'
    aria-label='Back'
    padding='none'
    backgroundColor='transparent'
    textColor='body'
    shadow='none'
    {...rest}
  >
    <ArrowBackIcon style={{ pointerEvents: 'none' }} />
  </Circle>
);
CloseButton.displayName = 'CloseButton';

export default CloseButton;
