import { types } from 'mobx-state-tree';
import { IcsStat } from '@icecreamsocial/mst-models';

const DEFAULTS = {
	totalConversions: 0,
	totalInvitesClicked: 0,
	totalInfluencers: 0,
	totalRevenue: 0,
	totalUtilizationRate: 0,
	totalUtilizedInfluencers: 0,
};

/**
 * @deprecated
 */
const StatCollection = types
	.model({
		data: types.array(IcsStat),
	})
	.named('StatCollection')
	.preProcessSnapshot((snapshot = {}) => ({
		data: snapshot.data || [],
	}))
	.actions((self) => ({
		updateStats(newStats) {
			self.data.replace(newStats);
		},
		clearAll() {
			self.data.clear();
		},
	}))
	/* "private" views.  not meant to be used outside of this model */
	.views((self) => ({
		__getStatInfo(key) {
			return self.data.map((datum) => ({
				key,
				value: datum[key],
				date: datum.date,
			}));
		},
	}))
	.views((self) => ({
		get byStat() {
			return Object.keys(DEFAULTS).reduce(
				(statMap, statKey) => ({
					...statMap,
					[statKey]: self.__getStatInfo(statKey),
				}),
				{}
			);
		},
		get computedOverview() {
			return self.data.reduce(
				(overview, stats) => ({
					totalConversions: overview.totalConversions + stats.totalConversions,
					totalInvitesClicked:
						overview.totalInvitesClicked + stats.totalInvitesClicked,
					totalInfluencers: overview.totalInfluencers + stats.totalInfluencers,
					totalRevenue: overview.totalRevenue + stats.totalRevenue,
					totalUtilizationRate:
						overview.totalUtilizationRate + stats.totalUtilizationRate,
					totalUtilizedInfluencers:
						overview.totalUtilizedInfluencers + stats.totalUtilizedInfluencers,
				}),
				DEFAULTS
			);
		},
		get isEmpty() {
			return !!!self.data.length;
		},
		map(...args) {
			return self.data.map(...args);
		},
		mapByStat(mapFn) {
			return Object.keys(self.byStat).map((key, i) =>
				mapFn(self.byStat[key], i, key)
			);
		},
		getCSV({ formatTitles = (title) => title }) {
			const data = self.data.toJSON();
			const titleRow = data[0].toJSON();
			const csv = [
				Object.keys(titleRow).map((key) =>
					formatTitles(key.replace(/([A-Z])/g, ' $1'))
				),
			];
			return data.reduce(
				(acc, item) => [
					...acc,
					Object.keys(item.toJSON()).map((key) => item[key]),
				],
				csv
			);
		},
	}));

export default StatCollection;
