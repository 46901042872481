import React from 'react';
import { Button } from '@icecreamsocial/components';
import { useTheme } from '../../../utils/hooks';

const EmphasizedAction = ({ children, ...props }) => {
	const { colors } = useTheme();

	return (
		<Button
			border="none"
			backgroundGradientColor={`128deg, ${colors.brand} 0%, ${colors.accent} 66%, ${colors.accentDark} 100%`}
			textColor="bodyInverse"
			{...props}
		>
			{children}
		</Button>
	);
};
EmphasizedAction.displayName = 'EmphasizedAction';
export default EmphasizedAction;
