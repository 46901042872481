import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { InputGroup } from '@icecreamsocial/components';
import { DropDownIcon } from '..';

const SupportedLanguagesSelect = ({
	id,
	name,
	onChange,
	value = 'en-US',
	...rest
}) => {
	return (
		<InputGroup.Fields>
			<InputGroup.Input
				as="select"
				id={id}
				name={name}
				paddingLeft="md"
				onChange={onChange}
				value={value}
				required
				{...rest}
			>
				<option value="zh-CN">🇨🇳&nbsp;&nbsp;Chinese</option>
				<option value="en-US">🇺🇸&nbsp;&nbsp;English</option>
				<option value="fr-FR">🇫🇷&nbsp;&nbsp;French</option>
				<option value="de-de">🇩🇪&nbsp;&nbsp;German</option>
				<option value="sv-SE">🇸🇪&nbsp;&nbsp;Swedish</option>
				<option value="ja-JP">🇯🇵&nbsp;&nbsp;Japanese</option>
				<option value="ko-KR">🇰🇷&nbsp;&nbsp;Korean</option>
				<option value="es">🇪🇸&nbsp;&nbsp;Spanish</option>
			</InputGroup.Input>
			<InputGroup.Icon>
				<DropDownIcon />
			</InputGroup.Icon>
		</InputGroup.Fields>
	);
};
SupportedLanguagesSelect.displayName = 'SupportedLanguagesSelect';

export default observer(SupportedLanguagesSelect);
