import React from 'react';
import { Button, Circle } from '@icecreamsocial/components';
import { ShareIcon } from '../SupportedIcons';

/**
 * @promote to library
 * @param {*} param0
 * @returns
 */
const ShareButton = ({ ...rest }) => (
	<Circle
		as={Button}
		type="button"
		aria-label="Close"
		padding="none"
		backgroundColor="transparent"
		textColor="body"
		shadow="none"
		{...rest}
	>
		<ShareIcon style={{ pointerEvents: 'none' }} />
	</Circle>
);
ShareButton.displayName = 'ShareButton';

export default ShareButton;
