import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Link, useRouteMatch } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { Button, Div, Panel, Typography } from '@icecreamsocial/components';
import { BaseTable, EditIcon, TableStatusLabel } from '../../presentational';
import { useStore } from '../../../utils/hooks';
//TODO remove store jocelyn

const UsersTable = ({ collection = [], ...rest }) => {
	const store = useStore();

	let columns = [
		{
			name: '',
			cell: (row) => (
				<Button
					as={Link}
					to={
						store.userId === row.id ? '/settings/account' : `/users/${row.id}`
					}
					padding="sm"
					borderRadius="50%"
					shadow="none"
					backgroundColor="transparent"
					textColor="ambientDark"
					data-slate-link="editUser"
				>
					<EditIcon size={16} />
				</Button>
			),
			width: '56px',
			grow: 0,
		},
		{
			name: 'User',
			grow: 1,
			selector: (row) => row.name,
			cell: (row) => (
				<Div padding="md" paddingLeft="none">
					{store.userRole !== 'client' || store.isTeamLeader ? (
						<Typography.A
							as={Link}
							to={`/users/${row.id}`}
							display="block"
							typoWeight="bold"
							textTransform="capitalize"
							textDecoration="none"
						>
							{row.name}
						</Typography.A>
					) : (
						<Typography.Span typoWeight="bold" textTransform="capitalize">
							{row.name}
						</Typography.Span>
					)}
					<Typography.Span display="block">{row.email}</Typography.Span>
				</Div>
			),
			sortable: true,
		},
		{
			name: 'Affiliate',
			selector: (row) => (
				<Typography.A
					as={Link}
					to={`/affiliates/${row.affiliateId}`}
					display="block"
					textTransform="capitalize"
					textDecoration="none"
				>
					{row.affiliateName}
				</Typography.A>
			),
			sortable: true,
			grow: 1,
		},
		{
			name: 'Role',
			selector: (row) => row.role,
			cell: (row) => (
				<Typography.Span textTransform="capitalize">{row.role}</Typography.Span>
			),
			sortable: true,
			grow: 0,
		},
		{
			name: 'Status',
			selector: (row) => row.status,
			cell: (row) => (
				<TableStatusLabel status={row.isArchived ? 'archived' : row.status} />
			),
			sortable: true,
			grow: 0,
			width: '120px',
		},
	];

	const userColumns = useMemo(() => {
		const cols = [...columns];
		if (!store.isTeamLeader) {
			cols.splice(0, 1);
		}

		if (store.userRole === 'client') {
			return cols.filter(({ name }) => name.toLowerCase() !== 'affiliate');
		}

		return cols;
	}, [store.userRole, store.isTeamLeader]);

	return <BaseTable columns={userColumns} collection={collection} {...rest} />;
};

UsersTable.displayName = 'UsersTable';

export default observer(UsersTable);
