import { types, SnapshotIn } from 'mobx-state-tree';
import { IcsCompany } from '@icecreamsocial/mst-models';
/**
 * @module BusinessPreprocessor
 * @category Shared
 * @subcategory Models
 * @description
 * Formats a snapshot for an IcsCompany model
 */

const Business = types.snapshotProcessor(
	IcsCompany,
	{
		/**
		 * format an incoming snapshot of an ICS payment gateway
		 * @param {SnapshotIn<typeof IcsCompany>} snapshot
		 */
		preProcessor(snapshot) {
			return snapshot === null || snapshot === undefined
				? undefined
				: {
						...snapshot,
						clientId: (snapshot || {}).clientId ? `${snapshot.clientId}` : null,
						processorId: (snapshot || {}).processorId
							? `${snapshot.processorId}`
							: null,
				  };
		},
	},
	'IcsBusiness'
);

export default Business;
