import { useRef, useEffect } from 'react';
import { subYears, formatISO } from 'date-fns';
/**
 * @module useCookies
 * @category Shared
 * @subcategory Hooks
 * @exports  useCookies
 */

/**
 * @function
 * @description reads and writes cookies
 */
export const useCookies = (onCookieUpdate = () => {}) => {
	function deleteCookie(key) {
		setCookie(key, undefined, formatISO(subYears(Date.now(), 10)));
	}

	function setCookie(key, val, expires, path) {
		const exp = expires ? new Date(expires).toUTCString() : expires;
		document.cookie = `${key}=${val}; expires=${exp}; path=${path}`;
		return document.cookie;
	}

	function getCookie(key) {
		const keyName = `${key}=`;
		const cookies = getCookies();

		for (let i = 0; i < cookies.length; i++) {
			let search = cookies[i];
			search = search.trim();
			if (search.indexOf(keyName) === 0)
				return search.substring(keyName.length, search.length);
		}
		return '';
	}

	function getCookies() {
		const decoded = decodeURIComponent(document.cookie);
		const cookies = decoded.split(';');
		return cookies;
	}

	useEffect(() => {
		onCookieUpdate();
	}, [document.cookie]);

	return { getCookie, getCookies, setCookie, deleteCookie };
};
