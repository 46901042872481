import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
	Div,
	Typography,
	Panel,
	Button,
	Spanner,
	Thumbnail,
} from '@icecreamsocial/components';
import { V2 } from '@icecreamsocial/api-resources';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import EditPane from '../../../EditPane';
import AuthorizeNetDescription from '../AuthorizeNetDescription';
import CustomDescription from '../CustomDescription';
import NmiDescription from '../NmiDescription';
import SquareDescription from '../SquareDescription';
import StripeDescription from '../StripeDescription';
import ProcessingError from '../ProcessingError';
import { queryKeys } from '../../../../../../queries/influencers';
import { useQueryString, useStore } from '../../../../../../utils/hooks';
import ProcessingSuccess from '../ProcessingSuccess';

// utility function because writing the comparison is annoying
const when = (condition, truthy, falsy) => (condition ? truthy : falsy);

const ProcessingOverview = ({
	backLink,
	campaign,
	currency,
	error,
	handleSubmit,
	influencerName,
	isEligible,
	isProcessed,
	locale,
	transactionId,
}) => {
	const store = useStore();
	const history = useHistory();
	const { query } = useQueryString();
	const { campaignId } = useParams();

	/**
	 * get processing data
	 */
	const {
		isLoading: isReviewingProcessing,
		isError: isReviewingProcessingError,
		data: processingInfo,
	} = useQuery(
		queryKeys.review({
			influencerId: query.iid,
			campaignId,
		}),
		async () => {
			const { data } = await V2.Campaigns.reviewProcessing(
				{
					influencerId: query.iid,
					campaignId,
				},
				{},
				{
					Authorization: store.Orchestrators.Auth.authHeader,
				}
			);
			return data?.data;
		},
		{ enabled: !!query.iid && !!campaignId }
	);

	/**
	 * the formated amount that will actually be processed
	 */
	const amount = processingInfo?.processingAmount ?? 0;
	const processingAmount = new Intl.NumberFormat(locale, {
		style: 'currency',
		currency,
	}).format(amount);

	/**
	 * determine whether a gift card or a credit will be used
	 */
	const rewardUsed = when(
		processingInfo?.processorType?.toLowerCase() === 'square',
		processingInfo?.campaignGiftCardAmount,
		processingInfo?.campaignRefundAmount
	);

	/**
	 * the formated reward amount
	 */
	const maxReward = useMemo(
		() =>
			when(
				rewardUsed,
				new Intl.NumberFormat(locale, {
					style: 'currency',
					currency,
				}).format(rewardUsed),
				null
			),
		[rewardUsed, locale]
	);

	/**
	 * handles the cancel button
	 */
	const onCancel = () => history.push(backLink);

	/**
	 * make optimistic styles
	 */
	const isOptimistic = (optimistic, extraStyles = {}) =>
		when(
			optimistic,
			{
				optimistic,
				borderRadius: 'default',
				...extraStyles,
			},
			{}
		);

	const rewardText = `${processingAmount} ${when(
		processingInfo?.processorType?.toLowerCase() === 'square',
		'Gift Card',
		'Credit'
	)}`;
	const rewardSubtext = when(
		processingInfo?.orderTotal < rewardUsed,
		<Typography.Span typoSize='sm' typoWeight='normal'>
			(Order total less than {maxReward} maximum reward)
		</Typography.Span>,
		null
	);

	return isProcessed ? (
		<ProcessingSuccess
			reward={processingAmount}
			influencerName={influencerName}
			transactionId={transactionId}
			backLink={backLink}
			processorType={processingInfo?.processorType}
		/>
	) : (
		<>
			<Panel backgroundColor='transparent' border='none' marginBottom='xlg'>
				<Panel.Body padding='sm'>
					<Typography.H2 typoWeight='500' marginTop='0'>
						Reward Influencer
					</Typography.H2>
					<Typography.P>
						Once processed, the influencer will be notified their reward has
						been issued
					</Typography.P>

					{error && <ProcessingError message={error} />}

					<Typography.H3>Reward</Typography.H3>
					<Panel
						marginBottom='md'
						textColor='bodyInverse'
						backgroundGradientColor={`-225deg, #4568dc 0%, #321575 100%`}
					>
						<Panel.Body>
							<Typography.Span
								display='block'
								typoWeight='500'
								{...isOptimistic(isReviewingProcessing, {
									elWidth: '100px',
									elHeight: '1.25em',
								})}
							>
								{!isReviewingProcessing && rewardText}{' '}
								{!isReviewingProcessing && rewardSubtext}
							</Typography.Span>
						</Panel.Body>
					</Panel>
					<Typography.H3>Campaign</Typography.H3>
					<Panel marginBottom='md' backgroundColor='bodyInverse'>
						<Panel.Body display='flex' alignItems='center'>
							<Div flex='1 0'>
								<Typography.H4
									display='block'
									typoSize='lg'
									typoWeight='400'
									marginTop='0'
									marginBottom='md'
								>
									{campaign?.offerHeading}
								</Typography.H4>
								<Typography.Span display='block'>
									{maxReward} rewards for {campaign?.conversionGoal} conversions
								</Typography.Span>
							</Div>
							<Thumbnail
								src={`${process.env.BASE_IMAGE_ENDPOINT}/${campaign?.offerImage}`}
							/>
						</Panel.Body>
					</Panel>
					<Typography.H3>Reward Processor</Typography.H3>
					{renderProcessorDescription(processingInfo?.processorType)}
				</Panel.Body>
			</Panel>
			<EditPane.Controls>
				<Spanner
					position='sticky'
					positionBottom='0'
					backgroundColor='bodyInverse'
					borderTop='1px solid'
					borderColor='ambient'
					textAlign='right'
				>
					<Button
						data-slate-action='cancelChanges'
						onClick={onCancel}
						marginRight='lg'
					>
						Cancel
					</Button>
					<Button
						data-slate-action='sendRewards'
						backgroundColor='brand'
						textColor='bodyInverse'
						onClick={handleSubmit}
						disabled={!isEligible}
					>
						Send Rewards
					</Button>
				</Spanner>
			</EditPane.Controls>
		</>
	);
};
ProcessingOverview.displayName = 'InfluencerManager.ProcessingOverview';

function renderProcessorDescription(processorType = '') {
	switch (processorType.toLowerCase()) {
		case 'authorizenet': {
			return <AuthorizeNetDescription />;
		}
		case 'cliq': {
			return <NmiDescription />;
		}
		case 'square': {
			return <SquareDescription />;
		}
		case 'stripe': {
			return <StripeDescription />;
		}
		default: {
			return <CustomDescription />;
		}
	}
}

export default observer(ProcessingOverview);
