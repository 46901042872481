import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Panel } from '@icecreamsocial/components';

const isFunction = (thing) => typeof thing === 'function';

const Accordion = ({ header, body, openByDefult = false }) => {
	const [isOpen, setIsOpen] = useState(openByDefult);

	return (
		<Panel>
			<Panel.Header
				onClick={() => setIsOpen(!isOpen)}
				backgroundColor='bodyInverse'
				textColor='body'
				style={{ cursor: 'pointer' }}
				borderColor='ambient'
				borderBottom={isOpen ? '1px solid' : '0px'}
				borderBottomLeftRadius={!isOpen ? 'default' : null}
				borderBottomRightRadius={!isOpen ? 'default' : null}
			>
				{isFunction(header) ? header(isOpen) : header}
			</Panel.Header>
			{!!isOpen && (
				<Panel.Body>{isFunction(body) ? body(isOpen) : body}</Panel.Body>
			)}
		</Panel>
	);
};

Accordion.displayName = 'Accordion';
export default observer(Accordion);
