import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Div, Typography, Input } from '@icecreamsocial/components';
import { useQueryString } from '../../../../utils/hooks';

const ParentInfluencer = ({ parentInfluencerId = undefined }) => {
	const history = useHistory();
	const location = useLocation();
	const { query: state, addParams } = useQueryString();

	const parentInfluencerManagerHandler = (mode) => {
		const query = addParams(
			state,
			{ key: 'iid', value: parentInfluencerId },
			{ key: 'mode', value: mode }
		);
		history.push(`${location.pathname}?${query}`);
	};

	return (
		<Div>
			{parentInfluencerId ? (
				<Div marginBottom='lg'>
					<Typography.P
						textColor='ambientDark'
						marginBottom='sm'
						marginTop='md'
					>
						Invited By ID
					</Typography.P>
					<Div
						display='flex'
						alignItems='center'
						justifyContent='space-between'
						style={{ cursor: 'pointer' }}
						onClick={() => {
							parentInfluencerManagerHandler('overview');
						}}
					>
						<Typography.Span
							typoWeight='bold'
							textTransform='capitalize'
							display='block'
							textDecoration='none'
							marginBottom='sm'
							textColor='blue'
						>
							{parentInfluencerId}
						</Typography.Span>
					</Div>
				</Div>
			) : (
				<Div></Div>
			)}
		</Div>
	);
};
ParentInfluencer.displayName = 'InfluencerManager.ParentInfluencer';

export default observer(ParentInfluencer);
