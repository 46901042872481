import React, { useCallback, useState } from 'react';
import { Observer, observer } from 'mobx-react-lite';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import {
	Div,
	Button,
	InputGroup,
	Panel,
	Modal,
	Typography,
} from '@icecreamsocial/components';
import BaseModal from '../BaseModal';

/**
 * @module ConfirmationPrompt
 * @category Components
 * @subcategory Presentational
 */

/**
 * @description
 * Asks the user for confirmation before performing an action.  Implementer is responsible for showing and hiding this
 * @param {object} props
 * @param {boolean} [props.when = false] when to show the prompt.  will be hidden by default
 * @param {function} [props.onConfirm = () => null] called when clicking the confirm button
 * @param {function} [props.onCancel = () => {}] called when clicking the cancel button
 * @param {boolean} [props.dangerous = false] The prompt has a dangerous operation, affects color of primary action
 * @param {string} props.title displayed on the top of the modal
 * @param {string} props.message displayed in the body of the modal
 * @param {string} props.confirmText displayed on the primary action of the modal (confirm)
 * @param {string} props.cancelText displayed on the secondary action of the modal (cancel)
 */
const ConfirmationPrompt = ({
	when = false,
	onConfirm = () => {},
	onCancel = () => {},
	dangerous = false,
	title,
	message,
	confirmText,
	cancelText,
}) => {
	return (
		<>
			<BaseModal isOpen={when}>
				<Panel backgroundColor="bodyInverse" elWidth="50%" margin="auto">
					<Panel.Body textAlign="center">
						<Typography.H1 typoWeight="500" typoSize="lg">
							{title}
						</Typography.H1>
						<Typography.P marginBottom="lg"> {message}</Typography.P>
						<Div
							display="flex"
							flexDirection="column"
							margin="auto"
							marginBottom="lg"
							elWidth="50%"
						>
							<Button
								onClick={onConfirm}
								display="block"
								marginBottom="md"
								textColor="bodyInverse"
								backgroundColor={dangerous ? 'bad' : 'brand'}
							>
								{confirmText}
							</Button>
							<Button onClick={onCancel} display="block">
								{cancelText}
							</Button>
						</Div>
					</Panel.Body>
				</Panel>
			</BaseModal>
		</>
	);
};
ConfirmationPrompt.displayName = 'ConfirmationPrompt';

export default observer(ConfirmationPrompt);
