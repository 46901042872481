import React from 'react';
import { observer } from 'mobx-react-lite';
import {
	Div,
	InputGroup,
	Typography,
	Thumbnail,
	Button,
	Img,
} from '@icecreamsocial/components';
import { UploadIcon } from '../';

/**
 * @module ImageUploader
 * @category Components
 * @subcategory Presentational
 */
/**
 * @description
 * value should be a File or a url
 * @todo
 * document this
 */
const ImageUploader = ({
	type = undefined,
	defaultValue = undefined,
	value,
	name,
	handleChange,
	onReplace,
	...rest
}) => {
	const imgUrl =
		value && typeof value.name === 'string'
			? URL.createObjectURL(value)
			: value;

	return (
		<InputGroup elWidth="100%" {...rest}>
			<InputGroup.Fields
				display="flex"
				flexDirection="column"
				justifyContent="center"
				alignItems="center"
				borderStyle="dashed"
				borderWidth="2px"
				padding={type === 'thumb' ? 'xlg' : 'sm'}
			>
				{!!value ? (
					<>
						{type === 'thumb' ? (
							<Thumbnail src={imgUrl} />
						) : (
							<Img
								src={imgUrl}
								border="1px solid"
								borderColor="ambientDark"
								borderRadius="default"
								elMaxWidth="500px"
							/>
						)}
					</>
				) : (
					<>
						<InputGroup.Icon textColor="ambientDark">
							<UploadIcon size={40} />
						</InputGroup.Icon>
						<InputGroup.Label textColor="ambientDark">
							Upload Image
						</InputGroup.Label>
						<InputGroup.Label textColor="link" textDecoration="underline">
							Browse Files
						</InputGroup.Label>
						<InputGroup.Input
							type="file"
							accept="image/*"
							name={name}
							onChange={handleChange}
							hidden
						/>
					</>
				)}
			</InputGroup.Fields>
			{!!value && (
				<>
					<Typography.Span display="block" typoHeight="xlg">
						{value.name}
					</Typography.Span>
					<Button
						type="button"
						onClick={onReplace}
						padding="none"
						shadow="none"
						hoverShadow="none"
						textDecoration="underline"
						textColor="link"
						backgroundColor="bodyInverse"
						typoHeight="xlg"
					>
						Replace
					</Button>
				</>
			)}
		</InputGroup>
	);
};
ImageUploader.displayName = 'ImageUploader';

export default observer(ImageUploader);
