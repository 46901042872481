import React, { useState } from 'react';
import { ClickAwayListener, Tooltip, withStyles } from '@material-ui/core';

const StyledTooltip = withStyles(() => ({
	arrow: {
		color: '#222',
	},
	tooltip: {
		lineHeight: '1.6em',
		backgroundColor: '#222',
		color: '#fff',
		fontWeight: '500',
		fontSize: '0.8rem',
		wordWrap: 'break-word',
		borderRadius: '16px',
		textAligin: 'center',
		padding: '8px 12px',
	},
}))(Tooltip);

/**
 * @type {React.FC<{ text: string, children: (cb: React.Dispatch<boolean>) => JSX.Element }>}
 */
const TooltipListener = ({ text, children }) => {
	const [open, setOpenTooltip] = useState(false);

	const handleClose = () => setOpenTooltip(false);

	return (
		<ClickAwayListener onClickAway={handleClose}>
			<StyledTooltip
				PopperProps={{
					disablePortal: true,
				}}
				onClose={handleClose}
				open={open}
				title={text}
				arrow
				disableFocusListener
				disableHoverListener
				disableTouchListener
			>
				{children(setOpenTooltip)}
			</StyledTooltip>
		</ClickAwayListener>
	);
};

export default TooltipListener;
