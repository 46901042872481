import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
	Route,
	Redirect,
	Switch,
	useParams,
	useRouteMatch,
} from 'react-router-dom';
import { useStore, useRouteConfig } from '../../../../../utils/hooks';
import Dashboard from './sub-routes/Dashboard';
import Settings from './sub-routes/Settings';
import Integrations from './sub-routes/Integrations';
import Reports from './sub-routes/Reports';
import { CampaignProvider } from './providers/Campaign';
import { Header } from './components/Header';

const Campaign = ({ hideHeader }) => {
	const store = useStore();
	const { path } = useRouteMatch();
	const { campaignId, clientId } = useParams();

	const { routes, links } = useRouteConfig([
		{
			component: Dashboard,
			linkText: 'Dashboard',
		},
		{
			path: `reports`,
			component: Reports,
			linkText: 'reports',
			exact: false,
		},
		{
			path: `integrations`,
			component: Integrations,
			linkText: 'integrations',
		},
		{
			path: `settings`,
			component: Settings,
			exact: false,
			linkText: 'settings',
		},
	]);

	const permissableRoutes = useMemo(
		() =>
			store.userRole === 'affiliate'
				? routes.slice(0, routes.length - 1)
				: routes,
		[store.userRole]
	);

	/**
	 * @todo remove this once we refactor the client focus logic
	 */
	useEffect(() => {
		const cId = clientId ?? store.Orchestrators.Auth.user.clientId;
		store.resolveClientFlow(cId);
		return () => {
			store.resolveClientFlowCleanUp();
		};
	}, [campaignId, store.Orchestrators.Auth]);

	return (
		<CampaignProvider campaignId={campaignId}>
			<Header hideHeader={hideHeader} userRole={store.userRole} links={links} />
			<Switch>
				{permissableRoutes.map(({ link, ...route }, i) => (
					<Route key={i} {...route} />
				))}
				<Redirect exact={false} to={`${path}/reports`} />
			</Switch>
		</CampaignProvider>
	);
};

Campaign.displayName = 'Campaigns.Campaign';

export default observer(Campaign);
