import { types } from 'mobx-state-tree';
import { SquareLocation } from '@icecreamsocial/mst-models';

const SquareLocationsCollection = types
	.model({
		list: types.array(SquareLocation),
	})
	.named('SquareLocationsCollection')
	.preProcessSnapshot((snapshot = {}) => ({
		list: snapshot.list || [],
	}))
	.views((self) => ({
		get isEmpty()
		{
			return !!!self.list.length;
		},
		find(id)
		{
			return self.list.find((ref) => ref.id === id);
		},
	}))
	.actions((self) => ({
		setList(locations = [])
		{
			self.list.replace(locations);
		},
		clearAll()
		{
			self.list.clear();
		}
	}));

export default SquareLocationsCollection;
