import React, { createContext, useContext, useEffect, useState } from 'react';
import merge from 'deepmerge';
import { useStore, useTheme } from '../../../../../../../../utils/hooks';
import { observer } from 'mobx-react';

/**
 * @type {ScriptSettings.ScriptContext}
 */
const ScriptContext = createContext(null);

/**
 * @returns {React.Provider<ScriptSettings.ScriptContext>}
 */
export const ScriptSettings = observer(({ children, initialValues }) => {
	const [settings, update] = useState(initialValues);

	/**
	 * @type {ScriptSettings.ScriptContext}
	 */
	const value = {
		update(val) {
			update({ ...settings, ...val });
		},
		updateTheme(theme) {
			update(merge(settings, { theme }));
		},
		updateBehavior(behavior) {
			update(merge(settings, { behavior }));
		},
		get settings() {
			return settings;
		},
	};

	useEffect(() => {
		update(initialValues);
	}, [initialValues]);

	return (
		<ScriptContext.Provider value={value}>{children}</ScriptContext.Provider>
	);
});

export const useScriptSettings = () => {
	return useContext(ScriptContext);
};
