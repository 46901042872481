import React, { useContext, Suspense } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Div, MediaCard, Img, Typography, Tag } from '@icecreamsocial/components';
import { useTheme } from '../../../utils/hooks';
import { TableStatusLabel, Logo } from '..';
import { observer } from 'mobx-react-lite';

const CampaignCard = ({
	img = '',
	imgAlt = 'campaign picture',
	id = '',
	status = 'active',
	title = 'Untitled',
	client = 'Unknown Client',
	isArchived = false,
	isClient = true,
	...rest
}) => {
	const theme = useTheme();

	const containerAnimation = {
		rest: {
			opacity: 0,
			scale: 1.05,
			backgroundColor: theme.colors.bodyInverse,
		},
		hover: {
			scale: 1.1,
			backgroundColor: theme.colors.ambient,
			transition: { damping: 100 },
		},
	};

	return (
		<MediaCard
			as={motion.div}
			variants={containerAnimation}
			whileHover="hover"
			gridItem
			elHeight="250px"
			position="relative"
			{...rest}
		>
			<Div as={Link} to={`campaigns/view/${id}`} textDecoration="none">
				<MediaCard.ImageContainer
					elHeight="150px"
					style={{
						'clip-path': 'polygon(0 0, 100% 0, 100% 100%, 0 80%)',
						'background-image': `url(${img}), linear-gradient(to bottom right, ${theme.colors.accent}, ${theme.colors.brand})`,
						'background-size': 'cover',
						'background-position': 'center center',
					}}
				></MediaCard.ImageContainer>
				{/* <Logo
					src={img}
					position="absolute"
					elWidth="50px"
					elHeight="50px"
					positionTop="100px"
					positionLeft="32px"
					shadow="default"
					backgroundColor="white"
				/> */}
				<MediaCard.Content>
					<Div>
						<Typography.Span
							display="block"
							marginBottom="md"
							typoWeight="500"
							textColor="body"
						>
							{title}
						</Typography.Span>
						{!isClient && (
							<Typography.Span
								as="small"
								display="block"
								marginBottom="md"
								textColor="body"
							>
								{client}
							</Typography.Span>
						)}
						{isArchived ? (
							<Tag textColor="ambientDark" borderColor="ambientDark">
								Archived
							</Tag>
						) : (
							<TableStatusLabel status={status} />
						)}
					</Div>
				</MediaCard.Content>
			</Div>
		</MediaCard>
	);
};

CampaignCard.displayName = 'CampaignCard';
export default observer(CampaignCard);
