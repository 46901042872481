"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ic_open_in_new = void 0;
var ic_open_in_new = {
  "viewBox": "0 0 24 24",
  "children": [{
    "name": "path",
    "attribs": {
      "d": "M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"
    }
  }]
};
exports.ic_open_in_new = ic_open_in_new;