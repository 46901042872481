import { useCallback } from 'react';
import { useStore } from './';

/**
 * @module useUserRole
 * @category Shared
 * @subcategory Hooks
 * @exports  useUserRole
 */
/**
 * @function
 */
export const useUserRole = () => {
	const store = useStore();
	if (store.isAuthenticated) {
		return (module) => store.Orchestrators.Auth.hasModuleAccess(module);
	}
	return () => false;
};
