import { types } from 'mobx-state-tree';
import { IcsInvite } from '@icecreamsocial/mst-models';
import { Pagination } from '../../models';

const InvitesCollection = types
	.compose(
		Pagination,
		types.model({
			inviteMap: types.map(IcsInvite),
			list: types.array(types.reference(IcsInvite)),
		})
	)
	.named('InvitesCollection')
	.preProcessSnapshot((snapshot = {}) => ({
		inviteMap: snapshot.inviteMap || {},
		list: snapshot.list || [],
	}))
	.views((self) => ({
		get hasInvites()
		{
			return !!self.list.length;
		},
		find(id)
		{
			return self.list.find((ref) => ref.id === id);
		},
	}))
	.actions((self) => ({
		addInvite(invite)
		{
			self.inviteMap.put(invite);
		},
		addInvites(invites = [])
		{
			invites.forEach((invite) => self.addInvite(invite));
		},
		setInvites(invites = [])
		{
			try
			{
				self.list.replace([]); // clean up
				invites.forEach((invite) =>
				{
					self.addInvite(invite);
					self.list.push(invite.id);
				});
			} catch (e)
			{
				console.error(e);
			}
		},
		setInviteList(inviteRefs = [])
		{
			self.list.replace(inviteRefs);
		},
		clearAll()
		{
			self.setInviteList();
			self.inviteMap.clear();
		}
	}));

export default InvitesCollection;
