import React from 'react';
import { observer } from 'mobx-react-lite';
import {
	Div,
	InputGroup,
	List,
	Panel,
	Typography,
} from '@icecreamsocial/components';
import { useFormik } from 'formik';
import { RequiredAsterisk, ECommerceRadioGroup, EnhancedForm } from '../';

const ECommerce = ({
	webstoreURL = '',
	eCommerceSolution = '',
	onSubmit = () => undefined,
	allowRouteBlock = true,
	renderControls = null,
}) => {
	const formikProps = {
		enableReinitialize: true,
		initialValues: {
			webstoreURL,
			eCommerceSolution,
		},
		onSubmit,
	};

	return (
		<EnhancedForm
			formikProps={formikProps}
			promptIf={({ dirty }) => allowRouteBlock && dirty}
			renderControls={renderControls}
		>
			{(businessECommerce) => (
				<>
					<Panel backgroundColor="bodyInverse" marginBottom="lg">
						<Panel.Header backgroundColor="bodyInverse">
							<Typography.H2
								textColor="body"
								textAlign="center"
								margin="none"
								typoSize="md"
							>
								Your Store
							</Typography.H2>
						</Panel.Header>
						<Panel.Body>
							<InputGroup elWidth="100%" marginBottom="sm">
								<InputGroup.Label>
									Your Webstore URL <RequiredAsterisk />
								</InputGroup.Label>
								<InputGroup.Fields>
									<InputGroup.Input
										id="webstoreURL"
										name="webstoreURL"
										value={businessECommerce.values.webstoreURL}
										onChange={businessECommerce.handleChange}
										paddingLeft="md"
										required
									/>
								</InputGroup.Fields>
							</InputGroup>
						</Panel.Body>
					</Panel>
					<Panel backgroundColor="bodyInverse" marginBottom="lg">
						<Panel.Header backgroundColor="bodyInverse" textColor="body">
							<Typography.H2
								textColor="body"
								textAlign="center"
								margin="none"
								typoSize="md"
							>
								eCommerce Solution
							</Typography.H2>
						</Panel.Header>
						<Panel.Body>
							<Typography.P>
								Let us know what your store is running on so we can better cater
								Ice Cream Social to your business
							</Typography.P>
							<ECommerceRadioGroup
								name="eCommerceSolution"
								value={businessECommerce.values.eCommerceSolution}
								onChange={businessECommerce.handleChange}
							/>
						</Panel.Body>
					</Panel>
				</>
			)}
		</EnhancedForm>
	);
};
ECommerce.displayName = 'BusinessECommerceForm';

export default observer(ECommerce);
