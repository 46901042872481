import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { InputGroup } from '@icecreamsocial/components';
import { DropDownIcon } from '../../presentational';
import { useStore } from '../../../utils/hooks';

/**
 * @description
 * Container component that retrieves the affiliateId's accessible affiliates
 * @param {object} props
 * @param {boolean} [props.includeParent=false] if true, will show the parent of the provided affiliateId as an option
 */
const AffiliateSelector = ({
	includeParent = false,
	value = '',
	id,
	name,
	onChange,
	affiliateId,
	...rest
}) => {
	const { Collections, getSelectableAffiliatesFlow } = useStore();

	useEffect(() => {
		getSelectableAffiliatesFlow({ affiliateId });
	}, [affiliateId]);

	const current = useMemo(() => {
		return includeParent ? Collections.Affiliates.affiliate(affiliateId) : null;
	}, [includeParent, Collections.Affiliates.affiliate(affiliateId)]);

	return (
		<InputGroup.Fields>
			<InputGroup.Input
				as="select"
				id={id}
				name={name}
				paddingLeft="md"
				onChange={onChange}
				value={value}
				required
				{...rest}
			>
				{current && (
					<option key={current.parentId} value={current.parentId}>
						{current.parentName}
					</option>
				)}
				{Collections.Affiliates.selectable.map(renderOptions)}
			</InputGroup.Input>
			<InputGroup.Icon>
				<DropDownIcon />
			</InputGroup.Icon>
		</InputGroup.Fields>
	);
};
AffiliateSelector.displayName = 'AffiliateSelector';

function renderOptions({ id, name }) {
	return (
		<option key={id} value={id}>
			{name}
		</option>
	);
}

export default observer(AffiliateSelector);
