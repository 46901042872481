import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button } from '@icecreamsocial/components';
import { DynamicBar } from '..';
import { withLoader } from '../../../utils/HOCs';

const LoadingButton = withLoader(Button);
/**
 * @module SteppedFormControls
 * @category Components
 * @subcategory Presentational
 */

/**
 * @callback NavCallback
 * @param {React.MouseEvent} e
 * @returns {*} anything
 */

/**
 * @description used for forms where the user is being guided through a series of steps
 * @param {object} props
 * @param {boolean} [props.last = false]  the type of controls to use for non-submit
 * @param {boolean} [props.first = false]  the type of controls to use for non-submit
 * @param {boolean} [props.disabled = false]  should the controls be disabled?
 * @param {boolean} [props.isLoading = false]  should the submit button show a loader?
 * @param {module:SteppedFormControls~NavCallback} [props.onNav = () => null] the navigation function
 */
const SteppedFormControls = ({
	first = false,
	last = false,
	isLoading = false,
	disabled = false,
	onNav,
}) => (
	<DynamicBar show={true}>
		<Button type="button" disabled={disabled} onClick={onNav} marginRight="lg">
			{first ? 'Exit setup' : 'Previous'}
		</Button>
		<LoadingButton
			type="submit"
			disabled={disabled}
			isLoading={isLoading}
			backgroundColor="brand"
			textColor="bodyInverse"
			containerProps={{ display: 'inline-block' }}
		>
			{last ? 'Complete setup' : 'Continue'}
		</LoadingButton>
	</DynamicBar>
);

SteppedFormControls.displayName = 'SteppedFormControls';
export default observer(SteppedFormControls);
