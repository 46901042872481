import { Div, List, Typography } from '@icecreamsocial/components';
import React from 'react';
import { MultiToggle } from '../../../../../../../../presentational';
import { useScriptSettings } from '../../providers';
import { BehaviorCustomizer } from './components/BehaviorCustomizer';
import { V1BehaviorCustomizer } from './components/V1BehaviorCustomizer';
import { GeneralCustomizer } from './components/GeneralCustomizer';
import { ScriptOptionBlock } from './components/ScriptOptionBlock';
import { ThemeCustomizer } from './components/ThemeCustomizer';
import { TrackingCustomizer } from './components/TrackingCustomizer';
import { DisplayCustomizer } from './components/DisplayCustomizer';
import { TriggerCustomizer } from './components/TriggerCustomizer';
import { TypeCustomizer } from './components/TypeCustomizer';

const SUPPORT_ARTICLE =
	'https://support.icecreamsocial.io/support/solutions/articles/66000510907-integration-script-options';

export const ScriptCustomizer = () => {
	const { settings, update } = useScriptSettings();

	return (
		<Div elHeight='100%' padding='lg'>
			<Typography.H2 marginTop='0'>Script Customizer</Typography.H2>
			<Typography.P>
				Changes are applied immediately to the script, but are only temporary to
				allow for quicker setup. For additional script options, please refer to{' '}
				<Typography.A
					visitedTextColor='link'
					target='_blank'
					href={SUPPORT_ARTICLE}
				>
					this article
				</Typography.A>
			</Typography.P>
			<List bullets='none'>
				<List.Item>
					<ScriptOptionBlock header='General' body={<GeneralCustomizer />} />
				</List.Item>
				<List.Item>
					<ScriptOptionBlock
						header='Widget Type'
						subheader='Determines which widget type will be used'
						body={<TypeCustomizer />}
					/>
				</List.Item>
				<List.Item>
					<ScriptOptionBlock
						header='Tracking'
						subheader='Determine which activity will be tracked by the script'
						body={<TrackingCustomizer />}
					/>
				</List.Item>
				{settings.version !== 'v1' && (
					<List.Item>
						<ScriptOptionBlock
							header='Display Type'
							subheader='Determine the layout of the widget'
							body={<DisplayCustomizer />}
						/>
					</List.Item>
				)}
				{settings.version !== 'v1' ? (
					settings.behavior?.flavor !== 'embedded' ? (
						<List.Item>
							<ScriptOptionBlock
								header='Page Load Behavior'
								subheader='Determine the widget behavior when a page loads'
								body={<BehaviorCustomizer />}
							/>
						</List.Item>
					) : null
				) : (
					<List.Item>
						<ScriptOptionBlock
							header='Page Load Behavior'
							subheader='Determine the widget behavior when a page loads'
							body={<V1BehaviorCustomizer />}
						/>
					</List.Item>
				)}
				{settings.version !== 'v1' && settings.behavior?.flavor !== 'embedded' && (
					<List.Item>
						<ScriptOptionBlock
							header='Trigger'
							subheader='Configure the element that launches the widget'
							body={<TriggerCustomizer />}
						/>
					</List.Item>
				)}
				{settings.version !== 'v1' && (
					<List.Item>
						<ScriptOptionBlock
							header='Theme'
							subheader='Add a splash of color and adjust basic appearance properties'
							body={<ThemeCustomizer />}
						/>
					</List.Item>
				)}
			</List>
		</Div>
	);
};
