import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Div, Panel, Typography } from '@icecreamsocial/components';
import { useStore } from '../../../utils/hooks';
import { ActionPanel, ConfirmationPrompt } from '../../presentational';
/**
 * @module DestructiveUserActions
 * @category Components
 * @subcategory Containers
 */

/**
 * @typedef ConfirmationPromptProps
 * @property {boolean}  [when = false]
 * @property {function}  [onConfirm = () => {}]
 * @property {function}  [onCancel = () => {}]
 * @property {string}  cancelText
 * @property {string}  title
 * @property {string}  message
 * @property {string}  confirmText
 */

/**
 * @description
 * A component that conidtionally shows user actions that affect their ability to perform administrative actions.
 * @param {object} props
 * @param {object} [props.overridePrompt = {}] if you want to oerride the prompt behavior for a specific state
 * @param {ConfirmationPromptProps} props.overridePrompt.CONFIRM_DEACTIVATE if you want to oerride the prompt behavior for a specific state
 * @param {string} props.userId the user to perform these actions on
 * @param {string} props.userName the user name of the user these actions are performed on
 * @param {'active'|'disabled'} props.currentStatus the current status of the user
 * @param {boolean} props.isArchived if the user is currently archived
 */
const DestructiveUserActions = ({
	overridePrompt = {},
	userId,
	userName,
	currentStatus,
	isArchived,
}) => {
	const store = useStore();
	const [confirmationPromptProps, setConfirmationPromptProps] = useState({});

	useEffect(() => {
		// close this when component unmounts
		return () => setConfirmationPromptProps({ when: false });
	}, []);

	// gather overrides
	const { CONFIRM_DEACTIVATE: cofirmDeactivateOvverrides } = overridePrompt;
	/* the following is a bit verbose but makes it easy to read */

	// possible states
	const DISABLED = { status: 'disabled' };
	const ACTIVE = { status: 'active', isArchived: false };
	const ARCHIVED = { ...DISABLED, isArchived: true };

	// constant state
	const COMMON_CONFIRMATION_PROMPT_PROPS = {
		when: true,
		onCancel: () => setConfirmationPromptProps({ when: false }),
		cancelText: 'No, Go Back',
	};

	// show before deactivating
	const CONFIRM_DEACTIVATE = {
		...COMMON_CONFIRMATION_PROMPT_PROPS,
		title: `Deactivate ${userName}?`,
		message: 'This user will no longer be able to access the admin',
		confirmText: 'Yes, Deactivate',
		onConfirm: async () => {
			await store.updateUserStatusFlow(DISABLED, userId);
			setConfirmationPromptProps({ when: false });
		},
		...cofirmDeactivateOvverrides,
	};

	// show before activating
	const CONFIRM_ACTIVATE = {
		...COMMON_CONFIRMATION_PROMPT_PROPS,
		title: `Activate ${userName}?`,
		message: 'Their access will be restored',
		confirmText: 'Yes, Activate',
		onConfirm: async () => {
			await store.updateUserStatusFlow(ACTIVE, userId);
			setConfirmationPromptProps({ when: false });
		},
	};

	// repurposes CONFIRM_ACTIVATE.  does the same thing but changes wording for better UX
	// used when the user is already archived and deactivated
	const CONFIRM_RESTORE = {
		...COMMON_CONFIRMATION_PROMPT_PROPS,
		...CONFIRM_ACTIVATE,
		title: `Restore ${userName}?`,
		confirmText: 'Yes, Restore',
	};

	// show when archiving
	const CONFIRM_ARCHIVE = {
		...COMMON_CONFIRMATION_PROMPT_PROPS,
		title: `Archive ${userName}?`,
		message:
			'This user will remain deactivated and will be hidden from the user list.',
		confirmText: 'Yes, Archive',
		onConfirm: async () => {
			await store.updateUserStatusFlow(ARCHIVED, userId);
			setConfirmationPromptProps({ when: false });
		},
	};

	return (
		<>
			<ConfirmationPrompt {...confirmationPromptProps} />
			{currentStatus === 'active' ? (
				<ActionPanel
					title="Deactivate"
					titleContext="bad"
					description={`Deactivated user accounts will not be able to perform any actions in the admin or login. They can be activated again at any time.`}
					actionText="Deactivate Account"
					onClick={() => setConfirmationPromptProps(CONFIRM_DEACTIVATE)}
				/>
			) : (
				<>
					{!isArchived ? (
						<ActionPanel
							title="Activate"
							titleContext="good"
							description={`Activating a user account will restore their access to the
										Ice Cream Social admin`}
							actionText="Activate Account"
							onClick={() => setConfirmationPromptProps(CONFIRM_ACTIVATE)}
						/>
					) : (
						<ActionPanel
							title="Restore"
							titleContext="good"
							description={`Restoring a user account will re-allow their access to the
										Ice Cream Social Admin and make them visible in the Users
										list.`}
							actionText="Restore Account"
							onClick={() => setConfirmationPromptProps(CONFIRM_RESTORE)}
						/>
					)}

					{!isArchived && (
						<ActionPanel
							title="Archive"
							description={`Archiving helps clear clutter by hiding old items so you're
										only seeing the most relevant results. Archived items can be
										restored anytime.`}
							actionText="Archive Account"
							onClick={() => setConfirmationPromptProps(CONFIRM_ARCHIVE)}
						/>
					)}
				</>
			)}
		</>
	);
};

DestructiveUserActions.displayName = 'DestructiveUserActions';

export default observer(DestructiveUserActions);
