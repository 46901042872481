import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Div, InputGroup, Panel, Typography } from '@icecreamsocial/components';
import { RadioGroup, Radio } from '@material-ui/core';
import { RequiredAsterisk } from '../../../../presentational';

const NonSquareOffers = ({
	disableCredit = false,
	refundAmount,
	handleChange,
}) => {
	const [rewardType, setRewardType] = useState(
		refundAmount ? 'credit' : 'custom'
	);

	// this will reset the state if a reset occurs outside
	useEffect(() => {
		setRewardType(refundAmount ? 'credit' : 'custom');
	}, [refundAmount]);

	return (
		<RadioGroup
			name="offerMethod"
			value={rewardType}
			onChange={(e) => setRewardType(e.target.value)}
		>
			<Panel marginBottom="lg" backgroundColor="ambientLight">
				<Panel.Body padding="md">
					<InputGroup>
						<InputGroup.Fields alignItems="center" border="none" shadow="none">
							<Div typoSize="lg">
								<Radio
									name="credit"
									value="credit"
									disabled={disableCredit}
									onChange={handleChange}
									color="primary"
								/>
							</Div>
							<Div textColor={disableCredit ? 'ambient' : 'body'}>
								<InputGroup.Label typoWeight="600">Credit</InputGroup.Label>
								<Typography.P marginTop="sm">
									The influencer will receive a refund for their order total up
									to a certain amount you designate{' '}
									{disableCredit && (
										<Typography.Span as="em" typoWeight="600" textColor="body">
											Requires a connected Reward Processor
										</Typography.Span>
									)}
								</Typography.P>
							</Div>
						</InputGroup.Fields>
					</InputGroup>
					{rewardType === 'credit' && (
						<InputGroup>
							<InputGroup.Label typoWeight="600">
								Credit <RequiredAsterisk />
							</InputGroup.Label>
							<Div display="flex" alignItems="center">
								<InputGroup.Fields marginRight="md">
									<InputGroup.Input
										id="refundAmount"
										name="refundAmount"
										type="number"
										paddingLeft="md"
										value={refundAmount}
										onChange={handleChange}
									/>
								</InputGroup.Fields>
								<Typography.Span textColor="ambientDark">
									USD awarded
								</Typography.Span>
							</Div>
						</InputGroup>
					)}
				</Panel.Body>
			</Panel>
			<InputGroup>
				<InputGroup.Fields
					padding="md"
					backgroundColor="ambientLight"
					alignItems="center"
					border="none"
				>
					<Div>
						<Radio value="custom" onChange={handleChange} color="primary" />
					</Div>
					<Div>
						<InputGroup.Label typoWeight="600">Custom Reward</InputGroup.Label>
						<Typography.P marginTop="sm">
							Select this option if you will manually reward an influencer by
							another method (For example: gift card, store credit, free
							product)
						</Typography.P>
					</Div>
				</InputGroup.Fields>
			</InputGroup>
		</RadioGroup>
	);
};
NonSquareOffers.displayName = 'NonSquareOffers';

export default NonSquareOffers;
