import React, { useEffect } from 'react';
import { connectReduxDevtools } from 'mst-middlewares';
import { observer } from 'mobx-react-lite';
import { SnackbarProvider } from 'notistack';
import { useStore, useTheme } from '../../utils/hooks';
import { ErrorBoundary } from '../containers';
import Main from './Main';
import { makeStyles } from '@material-ui/core';

/**
 * @module Pages
 * @category Components
 * @subcategory Pages
 */

/**
 * @description Wrapper arround [Main]{@link module:Main}
 * @note including SnackbarProvider here instead of App.js so that we can use the theme to adjust the color classes
 */

const Pages = () => {
	const store = useStore();
	const { colors } = useTheme();

	const useStyles = makeStyles({
		error: { backgroundColor: `${colors.bad} !important` },
		info: { backgroundColor: `${colors.accent} !important` },
		success: { backgroundColor: `${colors.good} !important` },
		warning: { backgroundColor: `${colors.warning} !important` },
	});

	const variants = useStyles();

	const snackStyles = {
		variantError: variants.error,
		variantInfo: variants.info,
		variantSuccess: variants.success,
		variantWarning: variants.warning,
	};

	useEffect(() => {
		connectReduxDevtools(require('remotedev'), store);
	}, []);
	return (
		<ErrorBoundary>
			<SnackbarProvider
				classes={snackStyles}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				preventDuplicate
			>
				<Main />
			</SnackbarProvider>
		</ErrorBoundary>
	);
};
export default observer(Pages);
