import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { format } from 'date-fns';
import {
	useDownloadLink,
	useStore,
} from '../../../../../../../../../../../utils/hooks';
import { queryKeys } from '../../../../../../../../../../../queries/influencers';
import { withLoader } from '../../../../../../../../../../../utils/HOCs';
import {
	DownloadIcon,
	EmphasizedAction,
} from '../../../../../../../../../../presentational';
import { useCampaign } from '../../../../../../providers/Campaign';
import { useInfluencerFilters } from '../FilterProvider';

export const ExportButton = ({ disable = true }) => {
	const { downloadInfluencersCSVFlow } = useStore();
	const { campaignId } = useParams();
	const { state } = useInfluencerFilters();
	const { campaign } = useCampaign();
	const handleDownload = useDownloadLink();

	const DownloadButton = withLoader(EmphasizedAction);

	// some formatting below because the filter state is not 1:1 with api params
	const goalReachedMap = {
		eligible: true,
		ineligible: false,
	};
	const { eligibility, ...query } = state;
	const goalReached = goalReachedMap[eligibility];

	const { refetch: download, isInitialLoading } = useQuery(
		queryKeys.exportInfluencerReport({
			campaignId,
			goalReached,
			...query,
		}),
		() =>
			downloadInfluencersCSVFlow(campaignId, {
				goalReached,
				...query,
			}),
		{ enabled: false }
	);

	const onClick = async () => {
		const { data: csv } = await download();
		handleDownload(
			`data:text/csv;charset=utf-8,${csv}`,
			`ICSInfluencers - ${campaign.data?.name} (${format(
				Date.now(),
				'yyyy-MM-dd'
			)}).csv`
		);
	};

	return (
		<DownloadButton
			onClick={onClick}
			isLoading={isInitialLoading || campaign.isLoading}
			disabled={disable || isInitialLoading || campaign.isLoading}
			display='flex'
			alignItems='center'
		>
			<DownloadIcon marginRight='sm' /> Export
		</DownloadButton>
	);
};
