import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
	Div,
	Button,
	InputGroup,
	Typography,
	Tag,
} from '@icecreamsocial/components';
import { useFormik } from 'formik';
import { SquareLocationsForm } from '..';
import { useStore } from '../../../utils/hooks';
//TODO remove store jocelyn

/**
 * @module SquareConnectionStaus
 * @category Components
 * @subcategory Presentational
 */

/**
 * @description
 * Shows the status of a Square processor
 * @todo show prompt if trying to disconnect square
 */
const SquareConnectionStatus = ({ onDisconnect }) => {
	const store = useStore();
	const locationId = store.userProcessor?.squareLocationId;

	const onSubmit = ({ squareLocationId }) => {
		const squareType = 'Square';
		const { id, name } = store.Collections.SquareConnectionStatus.list[
			squareLocationId
		];
		store.updateProcessorFlow({
			type: squareType, // might need to change this if there is sandbox
			squareLocationId: id,
			squareLocationName: name,
		});
	};

	useEffect(() => {
		if (!Boolean(locationId)) {
			store.getSquareConnectionStatusFlow();
		}
	}, [store.userProcessorId]);

	return !Boolean(locationId) ? (
		<SquareLocationsForm
			squareLocationId={locationId}
			locations={store.Collections.SquareLocations.list}
			onSubmit={onSubmit}
		/>
	) : (
		<Div display="flex" alignItems="center">
			<Tag
				textColor={
					store.userProcessor?.status?.toLowerCase() === 'active'
						? 'good'
						: 'bad'
				}
				borderColor={
					store.userProcessor?.status?.toLowerCase() === 'active'
						? 'good'
						: 'bad'
				}
				marginRight="lg"
				textTransform="capitalize"
			>
				{store.userProcessor?.status}
			</Tag>
			<Div flexGrow="1">
				<Typography.Span display="block" typoWeight="bold">
					Location
				</Typography.Span>
				<Typography.Span>
					{store.userProcessor?.squareLocationName}
				</Typography.Span>
			</Div>
			<Button
				onClick={onDisconnect}
				backgroundColor="ambientLight"
				padding="sm"
				paddingLeft="lg"
				paddingRight="lg"
			>
				Disconnect
			</Button>
		</Div>
	);
};
SquareConnectionStatus.displayName = 'SquareConnectionStatus';

export default observer(SquareConnectionStatus);
