import React from 'react';
import { observer } from 'mobx-react-lite';
import { Div, Typography } from '@icecreamsocial/components';

import {
	BusinessBrandForm,
	EditFormControls,
	Pagename,
} from '../../../../presentational';
import { useStore } from '../../../../../utils/hooks';

const BrandSettings = () => {
	const store = useStore();

	const initialValues = {
		logo: store.userBusiness?.logo,
	};

	const onSubmit = ({ logo }) => {
		store.updateCompanyFlow({ logo }, true);
	};

	return (
		<Div>
			<Pagename>Brand &amp; Appearance</Pagename>
			<BusinessBrandForm
				onSubmit={onSubmit}
				renderControls={(props) => (
					<EditFormControls
						isLoading={store.isLoading('businessSettings')}
						disabled={store.isLoading('businessSettings')}
						{...props}
					/>
				)}
				{...initialValues}
			/>
		</Div>
	);
};

BrandSettings.displayName = 'Settings.Business.Brand';
export default observer(BrandSettings);
