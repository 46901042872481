import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Div, InputGroup, Typography } from '@icecreamsocial/components';
import { useFormik } from 'formik';
import { Checkbox } from '@material-ui/core';
import { CalendarPicker, MinMaxToggle } from '../';

/**
 * @module InfluencersFilterForm
 * @category Components
 * @subcategory Presentational
 */
/**
 *
 * @param {object} props
 * @param {function} props.onSubmit the onSubmit function passed to useFormik
 * @param {function} props.onReset the onReset function passed to useFormik
 * @param {object} props.initialValues
 * @param {boolean} [props.initialValues.showEligible] eligible toggle
 * @param {boolean} [props.initialValues.showIneligible] ineligible toggle
 * @param {boolean} [props.initialValues.processed] processed toggle
 * @param {boolean} [props.initialValues.showInfluencerWithoutShare] whether to show influencers without shares
 * @param {boolean} [props.initialValues.invitesSwitch] whether to show min max field
 * @param {boolean} [props.initialValues.invitesClickCountSwitch] whether to show min max field
 * @param {boolean} [props.initialValues.conversionsSwitch] whether to show min max field
 * @param {boolean} [props.initialValues.revenueSwitch] whether to show min max field
 * @param {number} [props.initialValues.invitesGTOE]
 * @param {number} [props.initialValues.invitesLTOE]
 * @param {number} [props.initialValues.invitesClickCountGTOE]
 * @param {number} [props.initialValues.invitesClickCountLTOE]
 * @param {number} [props.initialValues.conversionsGTOE]
 * @param {number} [props.initialValues.conversionsLTOE]
 * @param {number} [props.initialValues.revenueGTOE]
 * @param {number} [props.initialValues.revenueLTOE]
 */
const InfluencersFilterForm = ({ onSubmit, onReset, initialValues }) => {
	/**
	 * for the filters
	 */
	const filtersForm = useFormik({
		enableReinitialize: true,
		initialValues,
		onSubmit,
		onReset,
	});

	return (
    <form onSubmit={filtersForm.handleSubmit}>
      <Div display="flex" alignItems="center" justifyContent="space-between">
        <Typography.H2 typoWeight="500">Filters</Typography.H2>
        <Button
          onClick={() => {
            filtersForm.handleReset();
            filtersForm.handleSubmit();
          }}
          typoSize="small"
          textDecoration="underline"
          border="none"
          shadow="none"
          hoverShadow="none"
          padding="sm"
          backgroundColor="transparent"
        >
          Reset filters
        </Button>
      </Div>
      <Div as="fieldset" border="none" elWidth="100%">
        <CalendarPicker
          label="Date Created"
          fromDate={filtersForm.values.fromDate}
          onFromDateChange={filtersForm.handleChange}
          toDate={filtersForm.values.toDate}
          onToDateChange={filtersForm.handleChange}
        />
        <Typography.Span
          display="block"
          typoWeight="500"
          typoSize=".9em"
          marginBottom="md"
        >
          Reward Status
        </Typography.Span>
        <InputGroup display="flex" alignItems="center">
          <Checkbox
            color="primary"
            name="showEligible"
            value={filtersForm.values.showEligible}
            checked={filtersForm.values.showEligible}
            onChange={filtersForm.handleChange}
          />{" "}
          <InputGroup.Label display="inline-block">Eligible</InputGroup.Label>
        </InputGroup>
        <InputGroup display="flex" alignItems="center">
          <Checkbox
            color="primary"
            name="showIneligible"
            value={filtersForm.values.showIneligible}
            checked={filtersForm.values.showIneligible}
            onChange={filtersForm.handleChange}
          />{" "}
          <InputGroup.Label display="inline-block">Ineligible</InputGroup.Label>
        </InputGroup>
        <InputGroup display="flex" alignItems="center">
          <Checkbox
            color="primary"
            name="processed"
            value={filtersForm.values.processed}
            checked={filtersForm.values.processed}
            onChange={filtersForm.handleChange}
          />{" "}
          <InputGroup.Label display="inline-block">Processed</InputGroup.Label>
        </InputGroup>
      </Div>
      <Div as="fieldset" border="none" elWidth="100%">
        <Typography.Span
          display="block"
          typoWeight="500"
          typoSize=".9em"
          marginBottom="md"
        >
          Metrics
        </Typography.Span>
        <InputGroup display="flex" alignItems="center">
          <Checkbox
            color="primary"
            name="showInfluencerWithoutShare"
            value={filtersForm.values.showInfluencerWithoutShare}
            checked={filtersForm.values.showInfluencerWithoutShare}
            onChange={filtersForm.handleChange}
          />{" "}
          <InputGroup.Label display="inline-block">
            Influencers with no shares
          </InputGroup.Label>
        </InputGroup>
        <MinMaxToggle
          name="invites"
          label="Lifetime Shares"
          show={filtersForm.values.invitesSwitch}
          minValue={filtersForm.values.invitesGTOE}
          maxValue={filtersForm.values.invitesLTOE}
          handleChange={filtersForm.handleChange}
        />
        <MinMaxToggle
          name="invitesClickCount"
          label="Lifetime Clicks"
          show={filtersForm.values.invitesClickCountSwitch}
          minValue={filtersForm.values.invitesClickCountGTOE}
          maxValue={filtersForm.values.invitesClickCountLTOE}
          handleChange={filtersForm.handleChange}
        />
        <MinMaxToggle
          name="conversions"
          label="Lifetime Conversions"
          show={filtersForm.values.conversionsSwitch}
          minValue={filtersForm.values.conversionsGTOE}
          maxValue={filtersForm.values.conversionsLTOE}
          handleChange={filtersForm.handleChange}
        />
        <MinMaxToggle
          name="revenue"
          label="Lifetime Revenue"
          show={filtersForm.values.revenueSwitch}
          minValue={filtersForm.values.revenueGTOE}
          maxValue={filtersForm.values.revenueLTOE}
          handleChange={filtersForm.handleChange}
        />
      </Div>

      <Button
        type="submit"
        disable={!filtersForm.isValid}
        elWidth="100%"
        textColor="bodyInverse"
        backgroundColor="brand"
        padding="sm"
      >
        Apply Filters
      </Button>
    </form>
  );
};

InfluencersFilterForm.displayName = 'InfluencersFilterForm';
export default observer(InfluencersFilterForm);
