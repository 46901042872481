import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { onSnapshot } from 'mobx-state-tree';
import { Globals, Reset } from '@icecreamsocial/components';
import { ThemeProvider } from './containers';
import { FavIcon, GTM } from './presentational';
import { StoreProvider } from '../utils/hooks';
import Pages from './pages';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
/**
 * @note only comment this in when developing locally.  because of the way one of the lib dependencies is built and bundled (match-sorter-utils)
 * parcel complains about it.  commenting this out before building remedies the issue
 */
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const queryClient = new QueryClient();

export default () => {
	const seed = hydrateState();

	return (
		<>
			<StoreProvider seed={seed} storeFns={[persist]}>
				<QueryClientProvider client={queryClient}>
					<ThemeProvider>
						<FavIcon />
						<Globals />
						<Reset />
						<Router>
							<Route path='/' component={Pages} />
						</Router>
					</ThemeProvider>
					{/* <ReactQueryDevtools /> */}
				</QueryClientProvider>
			</StoreProvider>
			<GTM containerId={process.env.GTM_CONTAINER} />
		</>
	);
};

function persist(store) {
	return onSnapshot(store, (snapshot) => {
		const toPersist = {
			_u: snapshot.Orchestrators.Auth.user,
			_t: snapshot.Orchestrators.Auth.token,
		};
		window.localStorage.setItem('ics.admin.1.0.0', JSON.stringify(toPersist));
	});
}

function hydrateState() {
	const persistedState =
		JSON.parse(window.localStorage.getItem('ics.admin.1.0.0')) || {};

	return Object.keys(persistedState).length
		? {
				Orchestrators: {
					Auth: { token: persistedState._t },
					View: {
						isLoading: {
							bootstrap: true,
						},
						fromPersistedState: true,
					},
				},
		  }
		: {};
}

// function cleanAuth(auth) {
// 	let copy = { ...auth };
// 	delete copy.notifications;
// 	return copy;
// }
