import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
	Div,
	Button,
	InputGroup,
	Typography,
	Tag,
} from '@icecreamsocial/components';
import { useFormik } from 'formik';
import { DropDownIcon, RequiredAsterisk } from '../';

const SquareLocations = ({
	squareLocationId = undefined,
	locations = [],
	onSubmit,
}) => {
	const squareLocations = useFormik({
		enableReinitialize: true,

		initialValues: {
			squareLocationId,
		},
		onSubmit,
	});

	return (
		<form onSubmit={squareLocations.handleSubmit}>
			<InputGroup elWidth="100%" marginBottom="md">
				<InputGroup.Label>
					Your Locations <RequiredAsterisk />
				</InputGroup.Label>
				<InputGroup.Fields>
					<InputGroup.Input
						as="select"
						id="squareLocationId"
						name="squareLocationId"
						paddingLeft="md"
						value={squareLocations.values.squareLocationId}
						onChange={squareLocations.handleChange}
						required
					>
						<option value="">Choose a location</option>
						{locations.map((location, i) => (
							<option key={i} value={i}>
								{location.name}
							</option>
						))}
					</InputGroup.Input>
					<InputGroup.Icon>
						<DropDownIcon />
					</InputGroup.Icon>
				</InputGroup.Fields>
			</InputGroup>
			<Div textAlign="right">
				<Button
					type="submit"
					backgroundColor="brand"
					textColor="bodyInverse"
					disabled={!squareLocations.isValid}
				>
					Use Location
				</Button>
			</Div>
		</form>
	);
};
SquareLocations.displayName = 'SquareLocationsForm';

export default observer(SquareLocations);
