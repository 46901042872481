import React, { useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';

import {
	Div,
	Panel,
	Typography,
	Button,
	List,
} from '@icecreamsocial/components';
import { useStore } from '../../../../../utils/hooks';
import { isICSURL } from '../../../../../utils';
import {
	BusinessPoliciesForm,
	ConfirmationPrompt,
	EditFormControls,
	Pagename,
} from '../../../../presentational';

const PolicySettings = () => {
	const store = useStore();
	const [showBulkUpdatePrompt, setShowBulkUpdatePrompt] = useState(false);
	const valueRef = useRef();
	const initialValues = {
		maskInfo: store.userBusiness?.maskInfo,
		termsAndConditionsURL: store.userBusiness?.termsAndConditionsURL,
		privacyPolicyURL: store.userBusiness?.privacyPolicyURL,
		disclaimerAcknowledgement: !!(
			store.userBusiness?.termsAndConditionsURL &&
			store.userBusiness?.privacyPolicyURL
		),
	};

	const handleSubmit = (values) => {
		if (
			values.termsAndConditionsURL !== initialValues.termsAndConditionsURL ||
			values.privacyPolicyURL !== initialValues.privacyPolicyURL
		) {
			setShowBulkUpdatePrompt(true);
			// this is so that the prompt can know the submitted values
			valueRef.current = values;
		} else {
			store.updateCompanyFlow(values);
		}
	};

	const cancelFromPrompt = () => setShowBulkUpdatePrompt(false);

	const confirmFromPrompt = async () => {
		try {
			await store.bulkUpdateCompanyCampaignsFlow(valueRef.current);
		} catch (e) {
			console.error('error updating company');
		} finally {
			setShowBulkUpdatePrompt(false);
		}
	};

	return (
		<Div>
			<ConfirmationPrompt
				when={showBulkUpdatePrompt}
				title={
					'Updates to your Policy Settings will be applied to all your campaigns'
				}
				message={
					'You will still be able to change Policy Settings for each campaign independently after these changes are applied'
				}
				confirmText={'Go for it'}
				cancelText={'Cancel pending changes'}
				onCancel={cancelFromPrompt}
				onConfirm={confirmFromPrompt}
			/>
			{isICSURL(initialValues.termsAndConditionsURL) ||
				(isICSURL(initialValues.privacyPolicyURL) && (
					<Panel>
						<Panel.Header backgroundColor='warningLight'>
							<Typography.H2 margin='none' textColor='body'>
								Notice
							</Typography.H2>
						</Panel.Header>
						<Panel.Body>
							<Typography.P>
								Ice Cream Social had provided default Privacy Policy and Terms
								and Condition URLs to help clients get their businesses set up
								quickly and effortlessly. We see that you are currently using
								one or more of these URLs. In our effort to achieve global
								privacy and terms compliance, we will be requiring businesses to
								provide their own URLs. There will be transition period for the
								coming months where policy settings cannot be saved if they
								include any of the default URLS{' '}
								<Typography.Span style={{ fontStyle: 'italic' }}>
									(ie. https://icecreamsocial.io/privacy-policy-terms)
								</Typography.Span>
								. Please change your URLs to point to your own policies and
								terms. After the transition period ends, we will automatically
								remove all policy URLs that are set to the defaults and disable
								all campaigns.
							</Typography.P>
						</Panel.Body>
					</Panel>
				))}
			<Div display='flex' style={{ gap: '16px' }}>
				<Div elWidth='75%'>
					<Pagename>Data &amp; Policy</Pagename>
					<BusinessPoliciesForm
						{...initialValues}
						onSubmit={handleSubmit}
						renderControls={(props) => (
							<EditFormControls
								isLoading={store.isLoading('policySettings')}
								disabled={store.isLoading('policySettings')}
								{...props}
							/>
						)}
					/>
				</Div>
				<Div flexGrow='1'>
					<Panel backgroundColor='ambient' marginTop='lg'>
						<Panel.Header
							backgroundColor='transparent'
							textColor='body'
							paddingBottom='sm'
						>
							<Typography.H3 typoWeight='400' margin='none'>
								Related Articles:
							</Typography.H3>
						</Panel.Header>
						<Panel.Body padding='sm'>
							<List>
								<List.Item>
									<Typography.A
										href='https://support.icecreamsocial.io/en/support/solutions/articles/66000509929-ics-gdpr-support'
										target='_blank'
									>
										GDPR Support
									</Typography.A>
								</List.Item>
								<List.Item>
									<Typography.A
										href='https://support.icecreamsocial.io/support/solutions/articles/66000509930-collecting-influencer-consent'
										target='_blank'
									>
										Collecting influencer consent
									</Typography.A>
								</List.Item>
								<List.Item>
									<Typography.A
										href='https://support.icecreamsocial.io/support/solutions/articles/66000509999-responding-to-influencer-data-requests'
										target='_blank'
									>
										Responding to Data Requests
									</Typography.A>
								</List.Item>
							</List>
						</Panel.Body>
					</Panel>
				</Div>
			</Div>
		</Div>
	);
};

PolicySettings.displayName = 'Settings.Business.Policies';
export default observer(PolicySettings);
