import React, { useMemo } from 'react';
import { useTheme } from '../../../utils/hooks';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

const getBrandSwitch = ({ backgroundColor }) => withStyles({
  switchBase: {
    color: 'white',
    '&$checked': {
      color: 'white',
    },
    '&$checked + $track': {
			backgroundColor,
			opacity: 1,
    },
  },
  checked: {},
  track: {},
})(Switch);

export const ThemedSwitch = ({ backgroundColor, ...rest }) => {
	const { colors } = useTheme();
	const SwitchComponent = useMemo(() => getBrandSwitch({
		backgroundColor: backgroundColor || `${colors.brand}`
  }), [backgroundColor]);
	return <SwitchComponent {...rest} />;
};