import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../../utils/hooks';
import EditPane from '../EditPane';
import ProcessingLoader from './components/ProcessingLoader';
import ProcessingOverview from './components/ProcessingOverview';

const RewardsIssuer = ({
	locale = 'en-US',
	currency = 'USD',
	statusKey = 'processingInfluencer',
	isLoading = true,
	influencer = {},
	campaign,
	backLink,
	handleSubmit,
}) => {
	const store = useStore();

	const {
		rewardsDue,
		rewardsIssued,
		goalsProcessed,
		name,
		transactionId,
	} = influencer as {
		rewardsDue: number;
		rewardsIssued: number;
		goalsProcessed: number;
		name: string;
		transactionId: null | string;
	};

	/**
	 * determines whether or not this influencer is eligible for rewards
	 */
	const isEligible = rewardsDue > 0;
	const isProcessed =
		(rewardsDue === 0 && rewardsIssued > 0) || goalsProcessed > 0;

	return (
		<EditPane>
			{isLoading ? (
				<ProcessingLoader />
			) : (
				<ProcessingOverview
					backLink={backLink}
					campaign={campaign}
					currency={currency}
					error={store.getErrors(statusKey)}
					handleSubmit={handleSubmit}
					influencerName={name}
					isEligible={isEligible}
					isProcessed={isProcessed}
					locale={locale}
					transactionId={transactionId}
				/>
			)}
		</EditPane>
	);
};
RewardsIssuer.displayName = 'InfluencerManager.RewardsIssuer';

export default observer(RewardsIssuer);
