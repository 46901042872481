import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

/**
 * @module useTheme
 * @category Shared
 * @subcategory Hooks
 */

/**
 * @function
 * @category Shared
 * @subcategory Hooks
 */
export const useTheme = () => {
	const theme = useContext(ThemeContext);
	return theme;
};
