import React, { useEffect, useState } from 'react';
import { Div, InputGroup } from '@icecreamsocial/components';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import styled from 'styled-components';

const CalenderPickerWrapper = styled.div`
	.CalendarDay__selected,
	.CalendarDay__selected:active,
	.CalendarDay__selected:hover,
	.CalendarDay__selected_span:active,
	.CalendarDay__selected_span:hover {
		background: rgb(4, 114, 214);
			border: 1px double rgb(4, 114, 214);
	}
	.CalendarDay__selected_span {
		background: rgb(44, 152, 251);
		border: 1px double rgb(44, 152, 251);
	}
	.DateInput_input__focused {
		border-bottom: 2px solid rgb(4, 114, 214);
	}
	.DateRangePickerInput {
		display: flex;
    align-items: center;
    justify-content: center;
	}
	.DateRangePickerInput__withBorder {
		border: none;
	}
	.DateRangePicker_picker {
		z-index: 2;
	}
	.DateInput_fang {
		margin-top: 1px;
	}
	.DateRangePickerInput,
	.DateInput,
	.DateInput_input {
		background: none;
	}
`;

const CalendarPicker = ({
	fromDate, fromDateId, onFromDateChange,
	toDate, toDateId, onToDateChange,
	onChange,
	...props
}) => {
	const returnValidMoment = (date) => !date || !moment(date)._isValid ? undefined : moment(date);
	const [startDate, setStartDate] = useState(
		returnValidMoment(fromDate)
	);
	const [endDate, setEndDate] = useState(
		returnValidMoment(toDate)
	);
	const [focusedInput, setFocusedInput] = useState();

	useEffect(() => {
		handleChange({
			startDate: returnValidMoment(fromDate),
			endDate: returnValidMoment(toDate),
		})
	}, [fromDate, toDate]);

	const handleChange = ({ startDate, endDate }) => {
		setStartDate(startDate);
		setEndDate(endDate);
		const fromDate = typeof (startDate || {}).format === 'function'
			? startDate.utc().startOf('day').format()
			: undefined;
		const toDate = typeof (endDate || {}).format === 'function'
			? endDate.utc().endOf('day').format()
			: undefined;

		if (typeof onChange === 'function') {
			onChange(fromDate, toDate);
		}
		if (typeof onFromDateChange === 'function') {
			onFromDateChange({ target: { name: 'fromDate', value: fromDate } });
		}
		if (typeof onToDateChange === 'function') {
			onToDateChange({ target: { name: 'toDate', value: toDate } });
		}
	};

	const onFocusChange = (focusedInput) => {
		setFocusedInput(focusedInput);
	};

	return (
		<InputGroup elWidth="100%" marginBottom="sm">
			<InputGroup.Label>{props.label}</InputGroup.Label>
			<InputGroup.Fields style={{ overflow: 'initial' }}>
				<Div textAlign="center" elWidth="100%">
					<CalenderPickerWrapper>
						<DateRangePicker
							startDate={startDate}
							startDateId={fromDateId || "icsStartDateId"}
							endDate={endDate}
							endDateId={toDateId || "icsEndDateId"}
							onDatesChange={handleChange}
							focusedInput={focusedInput}
							onFocusChange={onFocusChange}
							isOutsideRange={() => false}
						/>
					</CalenderPickerWrapper>
				</Div>
			</InputGroup.Fields>
		</InputGroup>
	);
};

export default CalendarPicker;