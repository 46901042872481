import React from 'react';
import { Div } from '@icecreamsocial/components';
import { PopsicleLoader } from '../../components/presentational';

/**
 * @module withLoader
 * @category Shared
 * @subcategory HOCs
 */

/**
 * @function
 * @param {typeof React.Component} Component
 * @returns {import React.FunctionComponent} LoaderElement
 * @description
 * Wraps a component with a Component
 */
export const withLoader = (Component) => {
	return ({
		isLoading = false,
		loaderProps = {},
		containerProps = {},
		...rest
	}) => (
		<Div position="relative" {...containerProps}>
			<Component {...rest} />
			{isLoading && (
				<PopsicleLoader
					elWidth="32px"
					elHeight="32px"
					backgroundColor="bodyInverse"
					position="absolute"
					positionTop="0"
					positionBottom="0"
					positionRight="0"
					positionLeft="0"
					margin="auto"
					shadow="default"
					{...loaderProps}
				/>
			)}
		</Div>
	);
};
