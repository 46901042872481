import React from 'react';
import {
	Button,
	Div,
	Modal,
	// ModalBackground,
	Panel,
	// Spanner,
	Typography,
} from '@icecreamsocial/components';
import BaseModal from '../BaseModal';

const ConfirmConversionDeletionModal = ({
	isOpen,
	conversions,
	onConfirm,
	closeModal,
}) => {
	return (
		<BaseModal isOpen={isOpen}>
			<Panel
				backgroundColor="ambientLight"
				border="none"
				color="inverse"
				elWidth="100%"
				elHeight={['100%', '90%']}
				shadow="raised"
			>
				<Panel.Body>
					<Typography.P textAlign="center">
						Are you sure you want to delete{' '}
						{conversions.length > 1
							? `${conversions.length} conversions`
							: 'this conversion'}
						?
					</Typography.P>
					<Div
						alignItems="center"
						display="flex"
						justifyContent="center"
						paddingBottom="md"
					>
						<Button onClick={closeModal} marginRight="md">
							Cancel
						</Button>
						<Button
							onClick={onConfirm}
							backgroundColor="bad"
							textColor="bodyInverse"
							marginLeft="sm"
						>
							Delete
						</Button>
					</Div>
				</Panel.Body>
			</Panel>
		</BaseModal>
	);
};

ConfirmConversionDeletionModal.displayName = 'ConfirmConversionDeletionModal';

export default ConfirmConversionDeletionModal;
