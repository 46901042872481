import React from 'react';
import { observer } from 'mobx-react-lite';
import { Div, Typography } from '@icecreamsocial/components';
import ShareSettingsForm from '../../../../../../components/ShareSettingsForm';
import {
	EditFormControls,
	Pagename,
} from '../../../../../../../../presentational';
import { formatImageURL } from '../../../../../../../../../utils';
import { useCampaign } from '../../../../providers/Campaign';

const ShareSettings = () => {
	const { campaign, update } = useCampaign();
	const { data } = campaign;

	const initialValues = {
		conversionGoal: data?.conversionGoal,
		shareTitle: data?.shareTitle,
		shareMessage: data?.shareMessage,
		shareImage: formatImageURL(data?.shareImage),
		emailSubject: data?.emailSubject,
		emailNote: data?.emailNote,
		redirectUrl: data?.redirectUrl,
	};

	const onSubmit = async ({
		shareTitle,
		shareMessage,
		shareImage,
		emailSubject,
		emailNote,
		redirectUrl,
	}) => {
		await update.mutate({
			shareTitle,
			shareImage,
			emailSubject,
			shareMessage,
			emailNote,
			redirectUrl,
		});
	};

	return (
		<Div>
			<Pagename>Share Settings</Pagename>
			<Typography.P>
				Please make sure that the content on this step follows the{' '}
				<Typography.A
					href='https://www.facebook.com/communitystandards/'
					target='__blank'
				>
					Facebook Community Standards
				</Typography.A>{' '}
				to prevent any posts from being flagged and removed
			</Typography.P>
			{data && (
				<ShareSettingsForm
					onSubmit={onSubmit}
					renderControls={(props) => <EditFormControls {...props} />}
					{...initialValues}
				/>
			)}
		</Div>
	);
};
ShareSettings.displayName = 'Campaigns.Campaign.Settings.General';

export default observer(ShareSettings);
