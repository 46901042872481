import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Div, InputGroup, Typography } from '@icecreamsocial/components';
import { useFormik } from 'formik';
import { Checkbox } from '@material-ui/core';
import { AffiliateSelector } from "../../containers";

/**
 * @module ClientsFilterForm
 * @category Components
 * @subcategory Presentational
 */

/**
 *
 * @param {object} props
 * @param {function} props.onSubmit the onSubmit function passed to useFormik
 * @param {function} props.onReset the onReset function passed to useFormik
 * @param {string}	props.affiliateId is the auth user's affiliate id
 * @param {object} props.initialValues
 * @param {boolean} [props.initialValues.active] is the client active
 * @param {boolean} [props.initialValues.disabled] is the client disabled
 * @param {boolean} [props.initialValues.archived] is the client archived
 */
const ClientsFilterForm = ({ onSubmit, onReset, affiliateId, initialValues }) => {
	/**
	 * for the filters
	 */
	const filtersForm = useFormik({
		enableReinitialize: true,
		initialValues,
		onSubmit,
		onReset,
	});

	return (
    <form onSubmit={filtersForm.handleSubmit}>
      <Div display="flex" alignItems="center" justifyContent="space-between">
        <Typography.H2 typoWeight="500">Filters</Typography.H2>
        <Button
          onClick={() => {
            filtersForm.handleReset();
            filtersForm.handleSubmit();
          }}
          typoSize="small"
          textDecoration="underline"
          border="none"
          shadow="none"
          hoverShadow="none"
          padding="sm"
          backgroundColor="transparent"
        >
          Reset filters
        </Button>
      </Div>
      <Div as="fieldset" border="none" elWidth="100%">
        <InputGroup elWidth="100%" marginBottom="sm">
          <InputGroup.Label>Affiliate</InputGroup.Label>
          <AffiliateSelector
            as="select"
            id="affiliateId"
            name="affiliateId"
            affiliateId={affiliateId}
            value={filtersForm.values.affiliateId}
            onChange={filtersForm.handleChange}
            paddingLeft="md"
            elWidth="100%"
          />
        </InputGroup>

        <Typography.Span
          display="block"
          typoWeight="500"
          typoSize=".9em"
          marginBottom="md"
        >
          Status
        </Typography.Span>
        <InputGroup display="flex" alignItems="center">
          <Checkbox
            color="primary"
            name="active"
            value={filtersForm.values.active}
            checked={filtersForm.values.active}
            onChange={filtersForm.handleChange}
          />{" "}
          <InputGroup.Label display="inline-block">Active</InputGroup.Label>
        </InputGroup>
        <InputGroup display="flex" alignItems="center">
          <Checkbox
            color="primary"
            name="disabled"
            value={filtersForm.values.disabled}
            checked={filtersForm.values.disabled}
            onChange={filtersForm.handleChange}
          />{" "}
          <InputGroup.Label display="inline-block">Disabled</InputGroup.Label>
        </InputGroup>
        <InputGroup display="flex" alignItems="center">
          <Checkbox
            color="primary"
            name="archived"
            value={filtersForm.values.archived}
            checked={filtersForm.values.archived}
            onChange={filtersForm.handleChange}
          />{" "}
          <InputGroup.Label display="inline-block">Archived</InputGroup.Label>
        </InputGroup>
      </Div>

      <Button
        type="submit"
        disable={!filtersForm.isValid}
        elWidth="100%"
        textColor="bodyInverse"
        backgroundColor="brand"
        padding="sm"
      >
        Apply Filters
      </Button>
    </form>
  );
};

ClientsFilterForm.displayName = 'ClientsFilterForm';
export default observer(ClientsFilterForm);
