import React from 'react';
import { observer } from 'mobx-react-lite';
import { Link, useRouteMatch } from 'react-router-dom';
import { Button, Div, Panel, Typography } from '@icecreamsocial/components';
import { EmailIcon, TableStatusLabel } from '../../presentational';
import BaseTable from '../BaseTable';

const InvitesTable = ({ collection = [], ...rest }) => {
	const columns = [
		// {
		// 	name: '',
		// 	cell: (row) => (
		// 		<Button
		// 			as={Link}
		// 			to={`/affiliates/${row.id}/settings`}
		// 			padding="sm"
		// 			borderRadius="50%"
		// 			shadow="none"
		// 			backgroundColor="transparent"
		// 			textColor="ambientDark"
		// 			textDecoration="none"
		// 		>
		// 			<EditIcon size={16} />
		// 		</Button>
		// 	),
		// 	width: '56px',
		// 	grow: 0,
		// },
		// {
		// 	name: 'Id',
		// 	grow: 0,
		// 	selector: (row) => row.id,
		// 	cell: (row) => (
		// 		<Typography.Span textDecoration="none">{row.id}</Typography.Span>
		// 	),
		// },
		{
			name: 'Invitee',
			selector: (row) => row.identifier || row.email,
			cell: (row) => (
				<Div padding='sm' paddingLeft='none'>
					<Typography.Span
						typoWeight='bold'
						textTransform='capitalize'
						display='block'
						textDecoration='none'
						marginBottom='sm'
					>
						{row.identifier || row.email || '-'}
					</Typography.Span>
					{row.name && (
						<Typography.Span
							display='block'
							textTransform='capitalize'
							textDecoration='none'
						>
							{row.name}
						</Typography.Span>
					)}
				</Div>
			),
			sortable: true,
			grow: 1,
		},
		{
			name: 'Type',
			selector: (row) => row.type,
			cell: (row) => (row.type === 'gm' ? <EmailIcon /> : '-'),
			grow: 0,
			center: true,
		},
		{
			name: 'Lifetime Clicks',
			selector: (row) => row.inviteClickCount,
			cell: (row) => <Typography.Span>{row.inviteClickCount}</Typography.Span>,
			grow: 0,
			center: true,
		},
		/**
		 * @todo add lifetime conversions back when it's returned by api again
		 * was temporairly removed from api due to it heavily affecting invite loading times
		 */
		// {
		//   name: "Lifetime Conversions",
		//   selector: (row) => row.conversionCount,
		//   cell: (row) => <Typography.Span>{row.conversionCount}</Typography.Span>,
		//   grow: 0,
		//   center: true,
		// },
		{
			name: 'Lifetime Revenue Generated',
			selector: (row) => row.convertedRevenue,
			cell: (row) => (
				<Typography.Span>
					{row.convertedRevenue.formatLocale('en-US', {
						currency: 'USD',
						style: 'currency',
					})}
				</Typography.Span>
			),
			grow: 0,
			center: true,
		},
		{
			name: 'Date Invited',
			selector: (row) => row.createdAt,
			cell: (row) => (
				<Typography.Span>
					{row.createdAt.localeDateString('en-US', {
						month: 'numeric',
						day: 'numeric',
						year: 'numeric',
						hour: 'numeric',
						minute: 'numeric',
					})}
				</Typography.Span>
			),
		},
		{
			name: 'Updated At',
			selector: (row) => row.updatedAt,
			omit: true,
		},
	];

	return <BaseTable columns={columns} collection={collection} {...rest} />;
};
InvitesTable.displayName = 'InvitesTable';

export default observer(InvitesTable);
