import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
	Link,
	Switch,
	Route,
	useHistory,
	useRouteMatch,
	useLocation,
	useParams,
	Redirect,
} from 'react-router-dom';
import {
	Button,
	Div,
	List,
	Spanner,
	Tag,
	Typography,
} from '@icecreamsocial/components';
import { HeaderPortal } from '../../../Main';
import { useStore } from '../../../../../utils/hooks';
import { ArrowBackIcon } from '../../../../presentational';
import Clients from '../Clients';
import Campaigns from '../Campaigns';
import SubAffiliates from '../SubAffiliates';
import Users from '../Users';
import Settings from '../Settings';

const Affiliate = () => {
	const store = useStore();
	const history = useHistory();
	const { path, url } = useRouteMatch();
	const { affiliateId } = useParams();

	useEffect(() => {
		store.resolveAffiliateFlow(affiliateId);

		return () => store.resolveAffiliateFlowCleanUp();
	}, [affiliateId]);

	const routes = [
		{
			path: `${path}`,
			component: () => <Redirect to={`${url}/clients`} />,
			exact: true,
		},
		{
			path: `${path}/clients`,
			component: Clients,
			exact: true,
			link: {
				text: 'clients',
				to: `${url}/clients`,
				active: (useRouteMatch(`${path}/clients`) || {}).isExact,
			},
		},
		{
			path: `${path}/campaigns`,
			component: Campaigns,
			exact: true,
			link: {
				text: 'campaigns',
				to: `${url}/campaigns`,
				active: (useRouteMatch(`${path}/campaigns`) || {}).isExact,
			},
		},
		{
			path: `${path}/sub-affiliates`,
			component: SubAffiliates,
			exact: true,
			link: {
				text: 'sub-affiliates',
				to: `${url}/sub-affiliates`,
				active: (useRouteMatch(`${path}/sub-affiliates`) || {}).isExact,
			},
		},
		{
			path: `${path}/users`,
			component: Users,
			exact: true,
			link: {
				text: 'users',
				to: `${url}/users`,
				active: (useRouteMatch(`${path}/users`) || {}).isExact,
			},
		},
		{
			path: `${path}/settings`,
			component: Settings,
			exact: false,
			link: {
				text: 'settings',
				to: `${url}/settings`,
				active: useRouteMatch(`${path}/settings`),
			},
		},
	];

	const focusedAffiliate = store.Orchestrators.Focused.affiliate || {};

	return (
		<>
			<HeaderPortal>
				<Div backgroundColor="#EBEBEB" textAlign="center" padding="md">
					<Spanner grid gridColumns="repeat(3, 1fr)" padding="0">
						<Div display="flex" alignItems="center">
							<Div elWidth="100%" textAlign="center">
								<Button
									alignItems="center"
									backgroundColor="transparent"
									display="flex"
									hoverShadow="none"
									margin="0"
									onClick={() => history.push('/affiliates')}
									padding="0"
									shadow="none"
								>
									<ArrowBackIcon marginRight="md" /> Affiliates
								</Button>
							</Div>
						</Div>
						<Div display="flex" flexDirection="column" alignItems="center">
							{focusedAffiliate && (
								<>
									<Div elWidth="100%" textAlign="center">
										<Typography.Span>Affiliate</Typography.Span>
										<Typography.Span
											marginLeft="sm"
											textTransform="capitalize"
											typoWeight="bold"
										>
											{focusedAffiliate.name}
										</Typography.Span>
									</Div>
									<Div
										elWidth="100%"
										textAlign="center"
										marginTop="sm"
										textColor="grey"
									>
										<Typography.Span as="small" textTransform="capitalize">
											Affiliate ID &#x23;{focusedAffiliate.id}
										</Typography.Span>
									</Div>
								</>
							)}
						</Div>
						<Div elWidth="100%" textAlign="right">
							{focusedAffiliate && (
								<Tag
									textTransform="capitalize"
									textColor={
										focusedAffiliate.status === 'active' ? 'good' : 'accent'
									}
									borderColor={
										focusedAffiliate.status === 'active' ? 'good' : 'accent'
									}
								>
									{focusedAffiliate.status}
								</Tag>
							)}
						</Div>
					</Spanner>
				</Div>
				<Div backgroundColor="#EBEBEB">
					<Div elMaxWidth={['100%', '66%']} margin="auto">
						<List
							bullets="none"
							display="flex"
							justifyContent="space-between"
							padding="md"
						>
							{routes.slice(1).map(({ link }, i) => (
								<List.Item
									key={i}
									flexGrow="1"
									textAlign="center"
									borderBottom="3px solid"
									borderColor={link.active ? 'brand' : 'transparent'}
									marginBottom="0"
								>
									<Typography.Span
										as={Link}
										to={link.to}
										display="block"
										elWidth="100%"
										paddingTop="md"
										paddingBottom="md"
										textTransform="capitalize"
										textDecoration="none"
										textColor="default"
									>
										{link.text}
									</Typography.Span>
								</List.Item>
							))}
						</List>
					</Div>
				</Div>
			</HeaderPortal>
			<Switch>
				{routes.map(({ link, ...route }, i) => (
					<Route key={i} {...route} />
				))}
				<Redirect to={routes[0].path} />
			</Switch>
		</>
	);
};

Affiliate.displayName = 'Affiliates.Affiliate';

export default observer(Affiliate);
