import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory, Link } from 'react-router-dom';
import { Div, Spanner, Typography } from '@icecreamsocial/components';
import { useStore } from '../../../../../utils/hooks';
import { HeaderPortal } from '../../../Main';
import {
	ArrowBackIcon,
	AffiliateSettingsForm,
	EditFormControls,
	RouteChangeBlocker,
} from '../../../../presentational';

const CreateAffiliate = (props) => {
	const store = useStore();
	const history = useHistory();
	const [allowNavigation, setAllowNavigation] = useState(false);

	/**
	 * @todo
	 * resolve the current affiliate user
	 */
	useEffect(() => {
		store.resolveUserFlow(store.Orchestrators.Auth.user.id);

		return () => store.resolveUserFlowCleanUp();
	}, [store.Orchestrators.Auth.user.id]);

	// set defaults to create an affiliate under the current logged in user
	const initialValues = {
		name: '',
		parentId: store.userParentAffiliateId,
	};

	const handleSubmit = async (values) => {
		await store.createAffiliateFlow(values);
		if (props.nextPage) {
			setAllowNavigation(true);
			history.push(props.nextPage);
		}
	};

	return (
		<>
			<HeaderPortal>
				<Div backgroundColor="#EBEBEB" textAlign="center">
					<Spanner grid gridColumns="repeat(3, 1fr)">
						<Typography.Span
							as={Link}
							to="/affiliates"
							textColor="body"
							display="flex"
							alignItems="center"
						>
							<ArrowBackIcon marginRight="md" /> Back
						</Typography.Span>
						<Typography.Span>New Affiliate</Typography.Span>
					</Spanner>
				</Div>
			</HeaderPortal>
			<Div marginTop="lg">
				<AffiliateSettingsForm
					initialValues={initialValues}
					userAffiliateId={store.userParentAffiliateId}
					onSubmit={handleSubmit}
					alwaysShowSubmit={true}
					allowRouteBlock={false}
					renderControls={(props) => (
						<EditFormControls
							isLoading={store.isLoading('affiliateSettings')}
							disabled={store.isLoading('affiliateSettings')}
							{...props}
						/>
					)}
				/>
			</Div>
			<RouteChangeBlocker
				when={!allowNavigation}
				title="Abandon Affiliate?"
				message="This affiliate will not be created"
				confirmText="Abandon Affiliate"
				cancelText="Continue Affiliate Creation"
			/>
		</>
	);
};

CreateAffiliate.displayName = 'Affiliates.Create';

export default observer(CreateAffiliate);
