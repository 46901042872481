import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Div, Img, Tag, Typography } from '@icecreamsocial/components';
import { useHistory, useLocation } from 'react-router-dom';
import { LinearProgress } from '@material-ui/core';
import { EmailIcon, ShareIcon, SmsIcon } from '..';
import BaseTable from '../BaseTable';
import logo_fb from '../../../assets/logo_fb.svg';
import logo_fbMessenger from '../../../assets/logo_fbMessenger.png';
import logo_weChat from '../../../assets/logo_weChat.png';
import logo_kakaoTalk from '../../../assets/logo_kakaoTalk.svg';
import logo_whatsapp from '../../../assets/logo_whatsapp.svg';
import { useQueryString } from '../../../utils/hooks';
import { InfluencerManager } from '../../containers';

const ChannelTag = ({ children, ...props }) => (
	<Tag backgroundColor='ambientLight' paddingLeft='8px !important' {...props}>
		<Div display='flex' alignItems='center' textColor={props.textColor}>
			{children}
		</Div>
	</Tag>
);

/**
 * @module InfluencersTableNew
 * @category Components
 * @subcategory Presentational
 */
/**
 * @description
 * A table that displays influencers
 * @param {object} props
 * @param {array} props.collection influencer collection
 * @param {object} props.campaign focused campaign
 * @param {function} props.onInfluencerSelect called when there is a focused influencer
 * @param {function} props.onInfluencerReset
 * @param {string} props.userRole current user's role
 */

const InfluencersTableNew = ({
	collection = [],
	campaign,
	onInfluencerSelect,
	onInfluencerReset,
	userRole,
	...rest
}) => {
	const history = useHistory();
	const location = useLocation();
	const { query: state, addParams } = useQueryString();

	/**
	 *
	 * @param {'overview' | 'reward'} mode
	 * @returns
	 */
	const influencerManagerHandler = (mode, row) => {
		const query = addParams(
			state,
			{ key: 'iid', value: row.id },
			{ key: 'mode', value: mode }
		);
		history.push(`${location.pathname}?${query}`);
	};

	/**
	 *
	 * @param {*} param0
	 * @returns {boolean} true if processed, false if not
	 */
	const processingStatus = ({ rewardsDue, rewardsIssued, goalsProcessed }) =>
		(rewardsDue === 0 && rewardsIssued > 0) || goalsProcessed > 0;
	/**
	 *
	 * @param {*} param0
	 * @returns {boolean} true if eligible for goal, false if not
	 */
	const goalStatus = ({ rewardsDue }) => rewardsDue > 0; // goals reached is for backwards compatibility.  will be phased out

	const columns = [
		{
			name: 'Influencer',
			selector: (row) => row.identifier,
			cell: (row) => (
				<Div
					onClick={() => {
						influencerManagerHandler('overview', row);
					}}
					padding='sm'
					paddingLeft='none'
					style={{ cursor: 'pointer' }}
				>
					<Typography.Span
						typoWeight='bold'
						textTransform='capitalize'
						display='block'
						textDecoration='none'
						marginBottom='sm'
						textColor='blue'
					>
						{row.name || '-'}
					</Typography.Span>
					{row.email && (
						<Typography.Span display='block' textDecoration='none'>
							{row.email}
						</Typography.Span>
					)}
				</Div>
			),
			sortable: true,
			grow: 3,
			minWidth: '250px',
		},
		{
			name: 'Lifetime Clicks',
			selector: (row) => row.invitesClicked,
			cell: ({
				facebook,
				messenger,
				wechat,
				kakao,
				whatsapp,
				influencerLink,
				sms,
				emailChannel,
				invitesClicked,
				isV2,
			}) => {
				const isValidNumber = (val) => !isNaN(val) && val > 0;
				const chooseIcon = (channel) => {
					switch (channel) {
						case 'facebook': {
							return <Img elWidth='16px' marginRight='sm' src={logo_fb} />;
						}
						case 'messenger': {
							return (
								<Img elWidth='16px' marginRight='sm' src={logo_fbMessenger} />
							);
						}
						case 'wechat': {
							return <Img elWidth='16px' marginRight='sm' src={logo_weChat} />;
						}
						case 'kakao': {
							return (
								<Img elWidth='16px' marginRight='sm' src={logo_kakaoTalk} />
							);
						}
						case 'whatsapp': {
							return (
								<Img elWidth='16px' marginRight='sm' src={logo_whatsapp} />
							);
						}
						case 'sms': {
							return <SmsIcon size={16} marginRight='sm' textColor='accent' />;
						}
						case 'emailChannel': {
							return (
								<EmailIcon size={16} marginRight='sm' textColor='accent' />
							);
						}
						default: {
							return (
								<ShareIcon size={16} marginRight='sm' textColor='accent' />
							);
						}
					}
				};

				const channels = isV2
					? { invitesClicked }
					: {
							facebook,
							messenger,
							wechat,
							kakao,
							whatsapp,
							influencerLink,
							sms,
							emailChannel,
					  };

				const validChannels = Object.keys(channels)
					.filter((key) => isValidNumber(channels[key]))
					.map((key) => ({
						Icon: chooseIcon(key),
						value: channels[key],
					}));

				return (
					<>
						{validChannels.length ? (
							validChannels.map((channel, index) => (
								<ChannelTag
									key={index}
									paddingLeft='md'
									borderColor='transparent'
									marginRight='sm'
								>
									{channel.Icon}
									{channel.value}
								</ChannelTag>
							))
						) : (
							<ChannelTag paddingLeft='md' borderColor='transparent'>
								No Data
							</ChannelTag>
						)}
					</>
				);
			},
			style: {
				wordBreak: 'normal',
			},
			grow: 10,
			wrap: false,
			minWidth: '200px',
		},
		{
			name: 'Reward Progress',
			selector: (row) => row.conversionCount,
			cell: (row) => {
				const { conversionCount } = row;
				const value =
					conversionCount <= campaign?.conversionGoal
						? (conversionCount / campaign?.conversionGoal) * 100
						: 100;
				return (
					<Div
						onClick={() => {
							influencerManagerHandler('overview', row);
						}}
						elWidth='100%'
					>
						<Div marginBottom='sm'>
							{conversionCount || 0} / {campaign?.conversionGoal}
						</Div>
						<LinearProgress variant='determinate' value={value} />
					</Div>
				);
			},
			grow: 1,
			width: '200px',
			sortable: true,
		},
		{
			name: 'Reward Status',
			selector: (row) => row?.rewardsDue || row?.goalsReached,
			cell: (row = {}) => {
				const isProcessed = processingStatus(row);
				const isEligible = goalStatus(row);

				const color = isProcessed ? 'good' : isEligible ? 'accent' : undefined;
				const label = isProcessed
					? 'Processed'
					: isEligible
					? 'Eligible'
					: 'Ineligible';

				return (
					<Tag
						onClick={() => {
							influencerManagerHandler('overview', row);
						}}
						textTransform='capitalize'
						textColor={color}
						borderColor={color}
						style={{ whiteSpace: 'nowrap' }}
					>
						{label}
					</Tag>
				);
			},
			grow: 1,
			minWidth: '200px',
			center: true,
		},
		{
			name: 'Process',
			selector: (row) => row?.rewardsDue || row?.goalsReached,
			cell: (row) => {
				const PROCESSED = processingStatus(row);
				const ELIGIBLE = goalStatus(row);

				const enabled =
					ELIGIBLE &&
					!PROCESSED &&
					userRole !== 'client' &&
					userRole !== 'superuser';

				return (
					<Button
						onClick={() => {
							influencerManagerHandler('reward', row);
						}}
						disabled={!enabled}
						data-slate-action='process'
					>
						Process
					</Button>
				);
			},
			grow: 1,
			width: '150px',
		},
		{
			name: 'Lifetime Shares',
			selector: (row) => row.inviteCount,
			cell: (row) => <Typography.Span>{row.inviteCount}</Typography.Span>,
			grow: 0,
			center: true,
			sortable: true,
		},
		{
			name: 'Lifetime Page Views',
			selector: (row) => row.pageViewConversions,
			cell: (row) => (
				<Typography.Span>{row.pageViewConversions}</Typography.Span>
			),
			grow: 0,
			width: '75px',
			center: true,
			sortable: true,
		},
		{
			name: 'Lifetime Revenue Generated',
			selector: (row) => row.conversionRevenue,
			cell: (row) => <Typography.Span>{row.conversionRevenue}</Typography.Span>,
			grow: 0,
			sortable: true,
		},
		{
			name: 'Lifetime Sign Ups',
			selector: (row) => row.signupConversions,
			cell: (row) => <Typography.Span>{row.signupConversions}</Typography.Span>,
			grow: 0,
			width: '75px',
			center: true,
			sortable: true,
		},
		{
			name: 'Lifetime Participations',
			selector: (row) => row.participationConversions,
			cell: (row) => (
				<Typography.Span>{row.participationConversions}</Typography.Span>
			),
			grow: 0,
			width: '75px',
			center: true,
			sortable: true,
		},
		{
			name: 'Influencer ID',
			selector: (row) => row.id,
			cell: (row) => (
				<Typography.Span textDecoration='none'>{row.id}</Typography.Span>
			),
			grow: 0,
		},
		// {
		//   name: "Order ID",
		//   selector: (row) => row.orderId,
		//   cell: (row) => (
		//     <Typography.Span
		//       onClick={() => {
		//         setFocusedInfluencerId(row.id);
		//         openInfluencerConversionsModal(true);
		//       }}
		//       textDecoration="none"
		//     >
		//       {row.orderId}
		//     </Typography.Span>
		//   ),
		//   grow: 0,
		// },
		{
			name: 'Updated At',
			selector: (row) => row.updatedAt,
			omit: true,
		},
	];

	const influencerColumns = useMemo(() => {
		const cols = [...columns];
		if (userRole === 'affiliate') {
			cols.splice(4, 1);
		}
		return cols;
	}, [userRole, state]);

	return (
		<>
			<BaseTable
				columns={influencerColumns}
				collection={collection}
				{...rest}
			/>
			<InfluencerManager />
		</>
	);
};

InfluencersTableNew.displayName = 'InfluencersTableNew';

export default observer(InfluencersTableNew);
