import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
	Button,
	Div,
	InputGroup,
	Panel,
	Typography,
} from '@icecreamsocial/components';
import {
	Checkbox,
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup,
} from '@material-ui/core';
import { EnhancedForm, RequiredAsterisk, DropDownIcon } from '../';
import { AffiliateSelector, ClientSelector } from '../../containers';
/**
 * @module AccountSettingsForm
 * @category Components
 * @subcategory Presentational
 * @deprecated
 */

/**
 * @name AccountSettingsForm
 * @component
 * @todo isCurrentUser should be deprecated in the future once we separate the forms
 * @deprecated
 */
const AccountSettings = ({
	onSubmit = () => undefined,
	renderControls = null,
	allowRouteBlock = true,
	id,
	email,
	firstName,
	lastName,
	role,
	isTeamAdmin,
	affiliateId,
	affiliateName,
	clientId,
	clientName,
	ip,
	status,
	isArchived,
	// isSuperUser,
	disabled = {},
	cannotChangeAccess,
	cannotActivate,
	cannotArchive,
	focusedUser = {},
	isCurrentUser = false,
	isCreating = false,
}) => {
	const [showAdvanced, setShowAdvanced] = useState(false);
	const { affiliateId: userAffiliateId, clientId: userClientId } = focusedUser;

	const formikProps = {
		enableReinitialize: true,
		initialValues: {
			id,
			email,
			password: undefined,
			firstName,
			lastName,
			role,
			isTeamAdmin,
			affiliateId,
			affiliateName,
			clientId,
			clientName,
			ip,
			status,
			isArchived,
			// isSuperUser,
		},
		onSubmit,
	};

	return (
		<EnhancedForm
			formikProps={formikProps}
			promptIf={({ dirty }) => allowRouteBlock && dirty}
			renderControls={renderControls}
			autoComplete="off"
		>
			{(accountSettings) => (
				<>
					<Panel backgroundColor="bodyInverse" marginBottom="lg">
						<Panel.Body>
							<InputGroup elWidth="100%" marginBottom="sm">
								<InputGroup.Label>
									Email <RequiredAsterisk />
								</InputGroup.Label>
								<InputGroup.Fields>
									<InputGroup.Input
										id="email"
										name="email"
										type="email"
										value={accountSettings.values.email}
										onChange={accountSettings.handleChange}
										paddingLeft="md"
										autoFocus
										required
									/>
								</InputGroup.Fields>
							</InputGroup>

							<input type="password" style={{ display: 'none' }} />
							<InputGroup elWidth="100%" marginBottom="sm">
								<InputGroup.Label>New Password</InputGroup.Label>
								<Div marginBottom="sm">
									<Typography.Span as="small">
										Your password is{' '}
										{(accountSettings.values.password || '').length} characters
										long (minimum 8).
									</Typography.Span>
								</Div>
								<InputGroup.Fields>
									<InputGroup.Input
										id="password"
										name="password"
										type="password"
										value={accountSettings.values.password}
										onChange={accountSettings.handleChange}
										paddingLeft="md"
										autoComplete="off"
									/>
								</InputGroup.Fields>
								<Typography.Span as="small">
									Passwords should contain at least 8 characters, a lowercase
									letter, an uppercase letter, a number, and a special
									character.
								</Typography.Span>
							</InputGroup>

							<Div display="flex" marginBottom="sm">
								<InputGroup elWidth="100%" marginRight="sm" style={{ flex: 1 }}>
									<InputGroup.Label>
										First Name <RequiredAsterisk />
									</InputGroup.Label>
									<InputGroup.Fields>
										<InputGroup.Input
											id="firstName"
											name="firstName"
											value={accountSettings.values.firstName}
											onChange={accountSettings.handleChange}
											paddingLeft="md"
											required
										/>
									</InputGroup.Fields>
								</InputGroup>

								<InputGroup elWidth="100%" marginRight="sm" style={{ flex: 1 }}>
									<InputGroup.Label>
										Last Name <RequiredAsterisk />
									</InputGroup.Label>
									<InputGroup.Fields>
										<InputGroup.Input
											id="lastName"
											name="lastName"
											value={accountSettings.values.lastName}
											onChange={accountSettings.handleChange}
											paddingLeft="md"
											required
										/>
									</InputGroup.Fields>
								</InputGroup>
							</Div>

							{/* 
							// disabling role changing because of unknown side effects
							<Div marginBottom="sm">
								<Div paddingTop="sm" paddingBottom="sm">
									<FormLabel>
										Role <RequiredAsterisk />
									</FormLabel>
								</Div>
								<RadioGroup
									name="role"
									value={`${accountSettings.values.role}`}
									onChange={accountSettings.handleChange}
									aria-label="role"
								>
									<Div
										backgroundColor="ambientLight"
										paddingLeft="sm"
										paddingRight="sm"
										borderRadius="default"
										marginBottom="sm"
									>
										<FormControlLabel
											value="affiliate"
											label="Affiliate"
											control={
												<Radio id="role" name="role" color="primary" required />
											}
											disabled={cannotChangeAccess || disabled?.role}
										/>
									</Div>
									<Div
										backgroundColor="ambientLight"
										paddingLeft="sm"
										paddingRight="sm"
										borderRadius="default"
										marginBottom="sm"
									>
										<FormControlLabel
											label="Client"
											value="client"
											control={
												<Radio id="role" name="role" color="primary" required />
											}
											disabled={cannotChangeAccess || disabled?.role}
										/>
									</Div>
								</RadioGroup>
							</Div> */}

							<Div marginBottom="sm">
								<FormControlLabel
									control={
										<Checkbox
											id="isTeamAdmin"
											name="isTeamAdmin"
											defaultChecked={accountSettings.values.isTeamAdmin}
											onChange={accountSettings.handleChange}
										/>
									}
									label="Team Leader"
									disabled={cannotChangeAccess}
								/>
							</Div>

							{!isCurrentUser &&
								accountSettings.values.role === 'affiliate' &&
								isCreating && (
									<>
										<InputGroup elWidth="100%" marginBottom="sm">
											<InputGroup.Label>
												Affiliate <RequiredAsterisk />
											</InputGroup.Label>
											<AffiliateSelector
												as="select"
												id="affiliateId"
												name="affiliateId"
												affiliateId={userAffiliateId}
												value={accountSettings.values.affiliateId}
												onChange={accountSettings.handleChange}
												disabled={disabled?.affiliateId}
												paddingLeft="md"
												required
											/>
										</InputGroup>
										{/* <InputGroup elWidth="100%" marginBottom="sm">
											<InputGroup.Label>
												Client <RequiredAsterisk />
											</InputGroup.Label>
											<ClientSelector
												id="clientId"
												name="clientId"
												clientId={userClientId}
												value={accountSettings.values.clientId}
												onChange={(e, { affiliateId }) => {
													accountSettings.values.affiliateId = affiliateId;
													accountSettings.handleChange(e);
												}}
												disabled={disabled?.clientId}
												paddingLeft="md"
												required
											/>
										</InputGroup> */}
									</>
								)}

							{!isCurrentUser && !isCreating && (
								<>
									<Typography.A
										as={Button}
										type="button"
										role="button"
										padding="none"
										backgroundColor="transparent"
										textColor="ambientDark"
										shadow="none"
										hoverShadow="none"
										display="flex"
										alignItems="center"
										onClick={() => setShowAdvanced(!showAdvanced)}
									>
										<DropDownIcon />
										Advanced Options
									</Typography.A>
									{showAdvanced && (
										<>
											{/* error: malformed array literal: "[]" being returned from api */}
											{/* <InputGroup elWidth="100%" marginBottom="sm">
								<InputGroup.Label>IP Address Whitelist</InputGroup.Label>
								<InputGroup.Fields>
									<InputGroup.Input
										id="ip"
										name="ip"
										value={
											Array.isArray(accountSettings.values.ip)
												? (accountSettings.values.ip || []).join(',')
												: accountSettings.values.ip
										}
										onChange={accountSettings.handleChange}
										paddingLeft="md"
									/>
								</InputGroup.Fields>
								<Typography.Span as="small">
									Separate multiple IP addresses with commas. Don't include
									spaces between entries.
								</Typography.Span>
							</InputGroup> */}

											<Div marginTop="sm" marginBottom="sm">
												<Div paddingTop="sm" paddingBottom="sm">
													<FormLabel>User Status</FormLabel>
												</Div>
												<RadioGroup
													name="status"
													value={`${accountSettings.values.status}`}
													onChange={accountSettings.handleChange}
													aria-label="status"
												>
													<Div
														backgroundColor="ambientLight"
														paddingLeft="sm"
														paddingRight="sm"
														borderRadius="default"
														marginBottom="sm"
													>
														<FormControlLabel
															value="active"
															label="Active"
															control={
																<Radio
																	id="status"
																	name="status"
																	color="primary"
																	required
																/>
															}
															disabled={cannotActivate}
														/>
													</Div>
													<Div
														backgroundColor="ambientLight"
														paddingLeft="sm"
														paddingRight="sm"
														borderRadius="default"
														marginBottom="sm"
													>
														<FormControlLabel
															value="disabled"
															label="Disabled"
															control={
																<Radio
																	id="status"
																	name="status"
																	color="primary"
																	required
																/>
															}
															disabled={cannotActivate}
														/>
													</Div>
												</RadioGroup>
											</Div>

											<Div marginTop="lg" marginBottom="sm">
												<Div paddingTop="sm" paddingBottom="sm">
													<FormLabel>Archived Status</FormLabel>
												</Div>
												<RadioGroup
													name="isArchived"
													value={`${accountSettings.values.isArchived}`}
													onChange={accountSettings.handleChange}
													aria-label="isArchived"
												>
													<Div
														backgroundColor="ambientLight"
														paddingLeft="sm"
														paddingRight="sm"
														borderRadius="default"
														marginBottom="sm"
													>
														<FormControlLabel
															value="false"
															label="Not Archived"
															control={
																<Radio
																	id="isArchived"
																	name="isArchived"
																	color="primary"
																	required
																/>
															}
															disabled={cannotArchive}
														/>
													</Div>
													<Div
														backgroundColor="ambientLight"
														paddingLeft="sm"
														paddingRight="sm"
														borderRadius="default"
														marginBottom="sm"
													>
														<FormControlLabel
															value="true"
															label="Archived"
															control={
																<Radio
																	id="isArchived"
																	name="isArchived"
																	color="primary"
																	required
																/>
															}
															disabled={cannotArchive}
														/>
													</Div>
												</RadioGroup>
											</Div>
										</>
									)}
								</>
							)}
						</Panel.Body>
					</Panel>
				</>
			)}
		</EnhancedForm>
	);
};
AccountSettings.displayName = 'AccountSettingsForm';

export default observer(AccountSettings);
