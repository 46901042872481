import './config/startup';

import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
// import './utils/I18n';
// import './utils/Currencies';
import App from './components/App';
import * as Sentry from '@sentry/browser';
import * as serviceWorker from './serviceWorker';

try {
	// Sentry.init({
	// 	dsn: 'https://835ad4d789344904b14ace516b2078dd@sentry.io/1849329',
	// 	environment: process.env.SENTRY_ENV,
	// });

	ReactDOM.render(<App />, document.getElementById('root'));

	// If you want your app to work offline and load faster, you can change
	// unregister() to register() below. Note this comes with some pitfalls.
	// Learn more about service workers: http://bit.ly/CRA-PWA
	serviceWorker.unregister();

	console.debug(
		'BUILD',
		`${process.env.CIRCLE_BRANCH} ${process.env.CIRCLE_BUILD_NUM}`
	);
} catch (e) {
	Sentry.captureException(e);
}
