import { useCallback, useRef } from 'react';

/**
 * @module usePagination
 * @category Shared
 * @subcategory Hooks
 * @exports  usePagination
 */

/**
 * @typedef {object} PaginationOptions
 * @property {number} [page = 1]
 * @property {number} [limit]
 */

/**
 * @typedef {object} PaginationValues
 * @property {number} page
 * @property {number} limit
 * @property {number} skip
 */

/**
 * @callback UpdatePaginationFn
 * @param {module:usePagination~PaginationOptions} newPagination
 * @return {module:usePagination~PaginationValues} the updated pagination values
 */

/**
 * @callback ResetPaginationFn
 * @description resets the current PaginationValues to the default
 * @return {void}
 */

/**
 * @typedef {Array<UpdatePaginationFn,ResetPaginationFn>} PaginationFns
 * @see {@link module:usePagination~UpdatePaginationFn}
 * @see {@link module:usePagination~ResetPaginationFn}
 */

/**
 * @todo make sure to set a default limit
 * @function
 * @category Shared
 * @subcategory Hooks
 * @description Calculates a skip and limit for any given page.
 * These values can be used to make api calls or whatever.
 * @param {module:usePagination~PaginationOptions} [defaults = {}] the default pagination
 * @returns {module:usePagination~PaginationFns} array of functions used to update pagination state (similar to React.useState)
 */
export const usePagination = (defaults = {}) => {
	const skip = useRef(defaults.skip);
	const take = useRef(defaults.limit);
	return [
		({ page = 1, limit = take.current }) => {
			skip.current = (page - 1) * limit;
			take.current = limit;
			return {
				skip: skip.current,
				page,
				limit,
			};
		},
		() => {
			skip.current = defaults.skip;
			take.current = defaults.limit;
		},
	];
};
