import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
	Button,
	Div,
	InputGroup,
	Panel,
	Typography,
} from '@icecreamsocial/components';
import { useFormik } from 'formik';
import {
	DeleteIcon,
	EnhancedForm,
	ImageUploader,
	RequiredAsterisk,
} from '../../../../presentational';
import NonSquareOffers from './NonSquareOffers';
import SquareOffers from './SquareOffers';
import ExamplePanel from '../ExamplePanel';
import { flags } from '../../../../../utils';

const OfferSettings = ({
	giftCardAmount = 0,
	refundAmount = 0,
	conversionGoal = 5,
	discountCode = '',
	discountCodeDesc = '',
	offerHeading = undefined,
	offerInstructions = undefined,
	offerImageCaption = undefined,
	offerImageTitle = undefined,
	offerImage = undefined,
	thankYouImage = undefined,
	isSquare = true,
	hasProcessor = false,
	allowRouteBlock = true,
	renderControls = null,
	useFixedGiftCardValue,
	onSubmit,
}) => {
	const ENABLE_NEW_OFFER = flags.isActive(
		flags.names.ENABLE_NEW_CAMPAIGN_OFFER
	);

	/** hidden inputs controls */
	const [showOfferImageCaptionField, setShowOfferImageCaption] = useState(
		!!offerImageCaption
	);
	const [showOfferImageTitleField, setOfferImageTitleField] = useState(
		!!offerImageTitle
	);
	// toggle open or close on updates
	useEffect(() => {
		setShowOfferImageCaption(!!offerImageCaption);
		setOfferImageTitleField(!!offerImageTitle);
	}, [offerImageCaption, offerImageTitle]);

	/**
	 * Recommended/example texts
	 */
	const recommendedOfferHeading = isSquare
		? `Invite Your Friends & Get MONEY BACK!`
		: `Invite Your Friends & Get a 50% REFUND!`;
	const recommendedOfferInstructions = isSquare
		? `Send this link to your friends! When they buy something, you get a ${Intl.NumberFormat(
				'en-US',
				{
					currency: 'USD',
					style: 'currency',
				}
		  ).format(
				giftCardAmount
		  )} gift card and they’ll get 10% off their order! Thanks for spreading the love! 🙌`
		: `Send this link to your friends! When they buy something, you get a refund and they’ll get 10% off their order! Thanks for spreading the love! 🙌`;

	/**
	 * default values
	 */
	const defaultHeading = !offerHeading ? recommendedOfferHeading : offerHeading;

	const defaultInstructions = !offerInstructions
		? recommendedOfferInstructions
		: offerInstructions;

	const initValues = ENABLE_NEW_OFFER
		? { offerHeading, offerInstructions }
		: { offerHeading: defaultHeading, offerInstructions: defaultInstructions };

	const formikProps = {
		enableReinitialize: true,

		initialValues: {
			conversionGoal,
			discountCode,
			discountCodeDesc,
			giftCardAmount,
			offerImageCaption,
			offerImageTitle,
			offerImage,
			refundAmount,
			useFixedGiftCardValue,
			...initValues,
		},
		validate: (values) => {
			const ONE_MB = 1024 * 1024; // 1 MB
			const errors = {};

			if (!values.offerImage) {
				errors.offerImage = 'Please upload an Offer Image';
			}

			if (values.offerImage?.size > ONE_MB) {
				errors.offerImage = `Your image has exceeded the maximum file size of 1MB. (Size: ${(
					values.offerImage?.size / ONE_MB || 0
				).toFixed(2)}MB )`;
			}

			console.debug(errors);

			return errors;
		},
		onSubmit: (values) => {
			if (values.offerMethod === 'custom') {
				isSquare ? (values.giftCardAmount = '0') : (values.refundAmount = '0');
			}
			return onSubmit(values);
		},
	};

	/**
	 * Offer
	 */

	return (
		<EnhancedForm
			formikProps={formikProps}
			promptIf={({ dirty }) => allowRouteBlock && dirty}
			renderControls={renderControls}
		>
			{(offerSettings) => (
				<>
					<Panel backgroundColor='bodyInverse' marginBottom='lg'>
						<Panel.Header
							backgroundColor='bodyInverse'
							textColor='body'
							paddingBottom='0'
						>
							<Typography.H2
								textColor='body'
								textAlign='center'
								margin='none'
								typoSize='md'
							>
								Influencer Reward
							</Typography.H2>
							<Typography.P marginBottom='0'>
								After an influencer reaches their referral goal, how will they
								be rewarded?
							</Typography.P>
						</Panel.Header>
						<Panel.Body>
							{isSquare ? (
								<SquareOffers
									value={offerSettings.values.giftCardAmount}
									defaultFixedGiftCardCriteria={
										offerSettings.values.useFixedGiftCardValue
									}
									handleChange={offerSettings.handleChange}
								/>
							) : (
								<NonSquareOffers
									disableCredit={!hasProcessor}
									refundAmount={offerSettings.values.refundAmount}
									handleChange={offerSettings.handleChange}
								/>
							)}
						</Panel.Body>
					</Panel>
					<Panel backgroundColor='bodyInverse' marginBottom='lg'>
						<Panel.Header
							backgroundColor='bodyInverse'
							textColor='body'
							paddingBottom='0'
						>
							<Typography.H2
								textColor='body'
								textAlign='center'
								margin='none'
								typoSize='md'
							>
								Referral Goal
							</Typography.H2>
							<Typography.P marginBottom='0'>
								To earn their reward, an influencer must get this many friends
								to take your desired action
							</Typography.P>
						</Panel.Header>
						<Panel.Body>
							<InputGroup marginBottom='sm'>
								<InputGroup.Label>
									Referral Goal <RequiredAsterisk />
								</InputGroup.Label>
								<InputGroup.Fields marginRight='md'>
									<InputGroup.Input
										id='conversionGoal'
										name='conversionGoal'
										paddingLeft='md'
										value={offerSettings.values.conversionGoal}
										onChange={offerSettings.handleChange}
										required
									/>
								</InputGroup.Fields>
							</InputGroup>
						</Panel.Body>
					</Panel>
					<Panel backgroundColor='bodyInverse' marginBottom='lg'>
						<Panel.Header
							backgroundColor='bodyInverse'
							textColor='body'
							paddingBottom='0'
						>
							<Typography.H2
								textColor='body'
								textAlign='center'
								margin='none'
								typoSize='md'
							>
								Invite Reward
							</Typography.H2>
							<Typography.P marginBottom='0'>
								Invite Rewards allow you to offer exclusive discounts to
								invitees landing from a share link, incentivizing them to enroll
								and make a purchase. Configure these settings to work alongside
								your site's Incentive Widget. For detailed setup, see our{' '}
								<Typography.A
									href='https://support.icecreamsocial.io/support/solutions/articles/66000519030-setting-up-invite-rewards'
									target='_blank'
								>
									support article
								</Typography.A>
								.
							</Typography.P>
						</Panel.Header>
						<Panel.Body>
							<InputGroup marginBottom='sm' elWidth='100%'>
								<InputGroup.Label>Discount Code</InputGroup.Label>
								<InputGroup.Fields marginRight='md'>
									<InputGroup.Input
										id='discountCode'
										name='discountCode'
										type='text'
										paddingLeft='md'
										value={offerSettings.values.discountCode}
										onChange={offerSettings.handleChange}
									/>
								</InputGroup.Fields>
							</InputGroup>
							<InputGroup marginBottom='sm' elWidth='100%'>
								<InputGroup.Label>Discount Code Description</InputGroup.Label>
								<InputGroup.Fields marginRight='md'>
									<InputGroup.Input
										as='textarea'
										id='discountCodeDesc'
										name='discountCodeDesc'
										value={offerSettings.values.discountCodeDesc}
										onChange={offerSettings.handleChange}
										paddingLeft='md'
										rows='5'
									/>
								</InputGroup.Fields>
							</InputGroup>
						</Panel.Body>
					</Panel>
					{ENABLE_NEW_OFFER ? (
						<Panel backgroundColor='bodyInverse' marginBottom='lg'>
							<Panel.Header
								backgroundColor='bodyInverse'
								textColor='body'
								paddingBottom='0'
							>
								<Typography.H2
									textColor='body'
									textAlign='center'
									margin='none'
									typoSize='md'
									gridColumn='2'
								>
									Offer Description
								</Typography.H2>
								<Typography.P marginBottom='0'>
									Craft an offer description that showcases the rewards and
									benefits influencers can earn by promoting your campaign. Try
									choosing a tone that reflects your brand to connect with your
									audience.
								</Typography.P>
							</Panel.Header>
							<Panel.Body>
								<InputGroup marginBottom='sm' elWidth='100%'>
									<InputGroup.Label>
										Offer Header <RequiredAsterisk />
									</InputGroup.Label>
									<InputGroup.Fields marginRight='md'>
										<InputGroup.Input
											id='offerHeading'
											name='offerHeading'
											paddingLeft='md'
											value={offerSettings.values.offerHeading}
											onChange={offerSettings.handleChange}
											required
										/>
									</InputGroup.Fields>
								</InputGroup>
								<InputGroup elWidth='100%'>
									<InputGroup.Label>
										Offer Details <RequiredAsterisk />
									</InputGroup.Label>
									<InputGroup.Fields marginRight='md'>
										<InputGroup.Input
											as='textarea'
											id='offerInstructions'
											name='offerInstructions'
											paddingLeft='md'
											rows='5'
											value={offerSettings.values.offerInstructions}
											onChange={offerSettings.handleChange}
											required
										/>
									</InputGroup.Fields>
									<Typography.Span as='small'>
										Tip: If you're using an Invite Rewards, make sure to let
										invitees know they'll get a discount too
									</Typography.Span>
								</InputGroup>
								<ExamplePanel
									title={recommendedOfferHeading}
									text={recommendedOfferInstructions}
									isExampleUsed={
										offerSettings.values.offerHeading !==
											recommendedOfferHeading ||
										offerSettings.values.offerInstructions !==
											recommendedOfferInstructions
									}
									exampleImg={offerSettings.values.offerImage}
									onClick={() => {
										offerSettings.setFieldValue(
											'offerHeading',
											recommendedOfferHeading
										);
										offerSettings.setFieldValue(
											'offerInstructions',
											recommendedOfferInstructions
										);
									}}
								></ExamplePanel>
							</Panel.Body>
						</Panel>
					) : (
						<Panel backgroundColor='bodyInverse' marginBottom='lg'>
							<Panel.Header
								backgroundColor='bodyInverse'
								textColor='body'
								paddingBottom='0'
								grid
								gridColumns='repeat(3, 1fr)'
								alignItems='center'
							>
								<Typography.H2
									textColor='body'
									textAlign='center'
									margin='none'
									typoSize='md'
									gridColumn='2'
								>
									Campaign Offer Details
								</Typography.H2>
								<Button
									type='button'
									textDecoration={
										offerSettings.values.offerHeading !==
											recommendedOfferHeading ||
										offerSettings.values.offerInstructions !==
											recommendedOfferInstructions
											? 'underline'
											: undefined
									}
									textColor={
										offerSettings.values.offerHeading !==
											recommendedOfferHeading ||
										offerSettings.values.offerInstructions !==
											recommendedOfferInstructions
											? 'link'
											: 'ambient'
									}
									onClick={() => {
										offerSettings.setFieldValue(
											'offerHeading',
											recommendedOfferHeading
										);
										offerSettings.setFieldValue(
											'offerInstructions',
											recommendedOfferInstructions
										);
									}}
									backgroundColor='transparent'
									shadow='none'
									hoverShadow='none'
									border='none'
									padding='none'
								>
									Use Recommended Text
								</Button>
							</Panel.Header>
							<Panel.Body>
								<InputGroup marginBottom='sm' elWidth='100%'>
									<InputGroup.Label>
										Offer Header <RequiredAsterisk />
									</InputGroup.Label>
									<InputGroup.Fields marginRight='md'>
										<InputGroup.Input
											id='offerHeading'
											name='offerHeading'
											paddingLeft='md'
											value={offerSettings.values.offerHeading}
											onChange={offerSettings.handleChange}
											required
										/>
									</InputGroup.Fields>
								</InputGroup>
								<InputGroup elWidth='100%'>
									<InputGroup.Label>
										Offer Instructions <RequiredAsterisk />
									</InputGroup.Label>
									<InputGroup.Fields marginRight='md'>
										<InputGroup.Input
											as='textarea'
											id='offerInstructions'
											name='offerInstructions'
											paddingLeft='md'
											rows='5'
											value={offerSettings.values.offerInstructions}
											onChange={offerSettings.handleChange}
											required
										/>
									</InputGroup.Fields>
								</InputGroup>
							</Panel.Body>
						</Panel>
					)}
					<Panel backgroundColor='bodyInverse' marginBottom='lg'>
						<Panel.Header
							backgroundColor='bodyInverse'
							textColor='body'
							paddingBottom='0'
						>
							<Typography.H2
								textColor='body'
								textAlign='center'
								margin='none'
								typoSize='md'
							>
								Offer Image <RequiredAsterisk />
							</Typography.H2>
							<Typography.P marginBottom='0'>
								This image will be the focal point of the offer and draw people
								in. Before uploading an image, please review our Image
								Guidelines. Violating the guidelines will result in a disruption
								of service.
							</Typography.P>
						</Panel.Header>
						<Panel.Body>
							<Div marginBottom='md'>
								{!showOfferImageTitleField && (
									<Button
										type='button'
										onClick={() => setOfferImageTitleField(true)}
										textDecoration='underline'
										textColor='link'
										backgroundColor='transparent'
										padding='0'
										border='0'
										hoverShadow='none'
										shadow='none'
									>
										+ Add Image Title
									</Button>
								)}
								{!showOfferImageTitleField && !showOfferImageCaptionField && (
									<Typography.Span margin='0 8px' textColor='ambient'>
										|
									</Typography.Span>
								)}
								{!showOfferImageCaptionField && (
									<Button
										type='button'
										onClick={() => setShowOfferImageCaption(true)}
										textDecoration='underline'
										textColor='link'
										backgroundColor='transparent'
										padding='0'
										border='0'
										hoverShadow='none'
										shadow='none'
									>
										+ Add Image Caption
									</Button>
								)}
								{showOfferImageTitleField && (
									<InputGroup marginTop='sm' elWidth='100%'>
										<InputGroup.Label>Offer Image Title</InputGroup.Label>
										<InputGroup.Fields marginRight='md'>
											<InputGroup.Input
												id='offerImageTitle'
												name='offerImageTitle'
												paddingLeft='md'
												value={offerSettings.values.offerImageTitle}
												onChange={offerSettings.handleChange}
											/>
											<InputGroup.Actions display='flex' alignItems='center'>
												<InputGroup.Action
													type='button'
													onClick={() => {
														if (offerSettings.values.offerImageTitle) {
															offerSettings.setFieldValue(
																'offerImageTitle',
																''
															);
														}
													}}
												>
													<DeleteIcon textColor='ambientDark' />
												</InputGroup.Action>
											</InputGroup.Actions>
										</InputGroup.Fields>
									</InputGroup>
								)}
								{showOfferImageCaptionField && (
									<InputGroup marginTop='sm' elWidth='100%'>
										<InputGroup.Label>Offer Image Caption</InputGroup.Label>
										<InputGroup.Fields marginRight='md'>
											<InputGroup.Input
												id='offerImageCaption'
												name='offerImageCaption'
												paddingLeft='md'
												value={offerSettings.values.offerImageCaption}
												onChange={offerSettings.handleChange}
											/>
											<InputGroup.Actions display='flex' alignItems='center'>
												<InputGroup.Action
													type='button'
													onClick={() => {
														if (offerSettings.values.offerImageCaption) {
															offerSettings.setFieldValue(
																'offerImageCaption',
																''
															);
														}
													}}
												>
													<DeleteIcon textColor='ambientDark' />
												</InputGroup.Action>
											</InputGroup.Actions>
										</InputGroup.Fields>
									</InputGroup>
								)}
							</Div>
							<ImageUploader
								name='offerImage'
								value={offerSettings.values.offerImage}
								handleChange={(e) =>
									offerSettings.setFieldValue(
										'offerImage',
										e.currentTarget.files[0]
									)
								}
								onReplace={(e) => {
									e.preventDefault();
									offerSettings.setFieldValue('offerImage', undefined);
								}}
								required={true}
							/>
							{offerSettings.errors.offerImage && (
								<Typography.P textColor='bad'>
									{offerSettings.errors.offerImage}
								</Typography.P>
							)}
						</Panel.Body>
					</Panel>
				</>
			)}
		</EnhancedForm>
	);
};
OfferSettings.displayName = 'OfferSettingsForm';

export default observer(OfferSettings);
