import { useEffect } from 'react';

/**
 * @module useScrollTop
 * @exports  useScrollTop
 * @category Shared
 * @subcategory Hooks
 */

/**
 * Automatically moves the user's window to the top once per render.
 * @function
 * @param {Array.<*>} [dependencies = []] array of dependencies to listen to
 * @returns {void}
 */
export const useScrollTop = (dependencies = []) => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, dependencies);
};
