import React from "react";
import { Redirect, useLocation, useParams } from "react-router-dom";
// import { Container, Div, Typography } from '@icecreamsocial/components';
// import { HeaderPortal } from '../../../../../Main';
/**
 * @module CampaignDashboard
 * @category Components
 * @subcategory Pages
 * @description
 * the root route will be the "Dashboard" in the future
 * So for now we will redirect to the influencers tab.
 */

/**
 * @note the root route will be the "Dashboard" in the future
 * So for now we will redirect to the influencers tab.
 * @component
 */
const CampaignDashboard = () => {
  const { pathname } = useLocation();
  // const { campaignId } = useParams();

  //return <Redirect to={{ pathname: `${pathname}/influencer` }} />;
  return <Redirect to={{ pathname: `${pathname}/reports` }} />;
};

CampaignDashboard.displayName = "Campaigns.Campaign.Dashboard";

export default CampaignDashboard;
