import { types } from 'mobx-state-tree';
import Auth from './Auth';
import DashboardStats from './DashboardStats';
import Focused from './Focused';
import View from './View';

const Orchestrators = types
	.model({
		Auth,
		DashboardStats,
		Focused,
		View,
	})
	.preProcessSnapshot((snapshot = {}) => ({
		Auth: snapshot.Auth || {},
		DashboardStats: snapshot.DashboardStats || {},
		Focused: snapshot.Focused || {},
		View: snapshot.View || {},
	}));

export default Orchestrators;
