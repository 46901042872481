"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ic_store = void 0;
var ic_store = {
  "viewBox": "0 0 24 24",
  "children": [{
    "name": "path",
    "attribs": {
      "d": "M20 4H4v2h16V4zm1 10v-2l-1-5H4l-1 5v2h1v6h10v-6h4v6h2v-6h1zm-9 4H6v-4h6v4z"
    }
  }]
};
exports.ic_store = ic_store;