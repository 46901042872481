import React from 'react';
import { observer } from 'mobx-react-lite';
import { Div, Button, Typography } from '@icecreamsocial/components';

import {
	BusinessEcommerceForm,
	SteppedFormControls,
	Pagename,
} from '../../../../presentational';

const ECommerce = ({ handleSubmit, returnTo, savedValues = {} }) => {
	return (
		<Div>
			<Pagename>eCommerce</Pagename>
			<BusinessEcommerceForm
				onSubmit={handleSubmit}
				alwaysShowSubmit={true}
				allowRouteBlock={false}
				renderControls={() => <SteppedFormControls onNav={returnTo} />}
				{...savedValues}
			/>
		</Div>
	);
};
ECommerce.displayName = 'BusinessSetUp.ECommerce';

export default observer(ECommerce);
