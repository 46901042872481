"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ic_content_copy = void 0;
var ic_content_copy = {
  "viewBox": "0 0 24 24",
  "children": [{
    "name": "path",
    "attribs": {
      "d": "M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"
    }
  }]
};
exports.ic_content_copy = ic_content_copy;