import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Div, Panel, Typography } from '@icecreamsocial/components';
/**
 * @module ActionPanel
 * @category Components
 * @subcategory Presentational
 * @lib_candidate
 */
/**
 * @description
 * A component that describes ONE action.  Used for complex actions that have logistical implications
 * @param {object} props
 * @param {object} [props.panelProps = {}] additional props to be passed tot he containing `Panel` component
 * @param {string} props.title Glanceable description of what the action does
 * @param {string} props.description detailed information about what the action will do
 * @param {string} props.actionText displayed on the action button
 * @param {function} props.onClick called when the action button is clicked
 * @param {'good'|'bad'|'warning'|'default'|undefined} props.titleContext changes the title text color
 * @param {function} props.onClick called when the action button is clicked
 */
const ActionPanel = ({
	panelProps = {},
	title,
	description,
	actionText,
	titleContext,
	onClick,
}) => (
	<Panel backgroundColor="bodyInverse" marginBottom="lg" {...panelProps}>
		<Panel.Body display="flex">
			<Div flexGrow="1" alignItems="center">
				<Typography.Span typoWeight="bold" textColor={titleContext}>
					{title}
				</Typography.Span>
				<Typography.P marginBottom="0">{description}</Typography.P>
			</Div>
			<Div flexShrink="0" alignSelf="center" marginLeft="md">
				<Button onClick={onClick}>{actionText}</Button>
			</Div>
		</Panel.Body>
	</Panel>
);

ActionPanel.displayName = 'ActionPanel';

export default observer(ActionPanel);
