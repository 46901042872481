import React from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Div, Typography } from '@icecreamsocial/components';
import { useStore } from '../../../../../../../../../../../utils/hooks';
import { queryKeys } from '../../../../../../../../../../../queries/rewards';
import {
	QuerySearchBar,
	Pagename,
	PopsicleLoader,
} from '../../../../../../../../../../presentational';

import { Table } from '../Table';
import { ExportButton } from '../ExportButton';
import { useRewardsFilters } from '../FilterProvider';
import { useCampaign } from '../../../../../../providers/Campaign';

/**
 * Fetches and Displays data based on filters
 */
const DataView = () => {
	const { getRewards } = useStore();
	const { campaignId } = useParams();
	const { state } = useRewardsFilters();
	const { campaign } = useCampaign();
	const { data } = campaign;

	/**
	 * update the rewards list on relevant query state changes or when data is stale
	 */
	const { data: rewards, isLoading, isRefetching, error } = useQuery(
		queryKeys.list({
			campaignId,
			...state,
		}),
		() =>
			getRewards({
				campaignId,
				...state,
			}),
		{
			enabled: !!campaignId,
			refetchOnWindowFocus: false,
		}
	);

	return (
		<>
			<Div display='flex' alignItems='center' justifyContent='space-between'>
				<Pagename>Rewards</Pagename>
				<ExportButton disable={!rewards?.rewards.count} />
			</Div>
			<Div>
				<QuerySearchBar
					id='rewardSearch'
					name='search'
					placeholder='Find Rewards'
				/>

				{data ? (
					<Table
						campaign={data}
						rewards={rewards?.rewards}
						isLoading={isLoading || isRefetching}
						error={error}
					/>
				) : (
					<Div
						display='flex'
						padding='xlg'
						elMinHeight='300px'
						alignItems='center'
						justifyContent='center'
						flexDirection='column'
						backgroundColor='bodyInverse'
						borderRadius='default'
						borderColor='ambient'
						border='1px solid'
					>
						<PopsicleLoader />
						<Typography.P margin='none' textColor='ambientDark'>
							Just a moment...
						</Typography.P>
					</Div>
				)}
			</Div>
		</>
	);
};

DataView.displayName = 'Campaigns.Campaign.Reports.Rewards.DataView';
export default observer(DataView);
