import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Button, Div } from '@icecreamsocial/components';

const Wrapper = styled.div`
	overflow: hidden;

	${Button}:not(:last-child) {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		border-right: 1px solid;
	}

	${Button}:not(:first-child) {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		border-left: 1px solid;
	}

	${Button} {
		border-color: ${({ theme }) =>
			css`
				${theme.colors.brand}
			`} !important;
	}
`;

/**
 * @promote to library
 * @param {*} param0
 * @returns
 */
const MultiToggle = ({ defaultActive = 0, options, ...anatomyProps }) => {
	const [activeKey, setActiveKey] = useState(defaultActive);

	const proxyClick = (onClick, key) => (e) => {
		setActiveKey(key);
		onClick(e, activeKey);
	};

	const colorProps = (key) =>
		activeKey === key
			? {
					backgroundColor: 'brand',
					textColor: 'bodyInverse',
			  }
			: {
					backgroundColor: 'bodyInverse',
					textColor: 'brand',
			  };

	return options.length ? (
		<Div
			as={Wrapper}
			grid
			gridColumns={`repeat(${options.length}, 1fr)`}
			{...anatomyProps}
		>
			{options.map(({ text, onClick }, key) => (
				<Button
					type='button'
					key={key}
					onClick={proxyClick(onClick, key)}
					{...colorProps(key)}
					display='block'
					border='2px solid'
					shadow='none'
					hoverShadow='none'
				>
					{text}
				</Button>
			))}
		</Div>
	) : null;
};

MultiToggle.displayName = 'MultiToggle';

export default MultiToggle;
