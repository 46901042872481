import React, { useEffect } from 'react';
import { useField, useFormikContext } from 'formik';
import {
	Button,
	InputGroup,
	Panel,
	Typography,
} from '@icecreamsocial/components';
import { Checkbox } from '@material-ui/core';

export const DisclaimerAcknowledgementField = ({ toggleDisclaimer }) => {
	const NAME = 'disclaimerAcknowledgement';
	const { values, touched, dirty, setFieldValue, ...rest } = useFormikContext();
	const [field, meta] = useField(NAME);

	useEffect(() => {
		if (dirty) {
			setFieldValue(NAME, false);
		}
	}, [dirty]);

	return (
		<>
			<Panel
				backgroundColor='ambient'
				borderColor={meta.error ? 'bad' : 'transparent'}
				borderWidth={meta.error ? '2px' : null}
			>
				<Panel.Body madding='sm'>
					<InputGroup
						elWidth='100%'
						display='flex'
						alignItems='center'
						style={{ gap: '16px' }}
					>
						<Checkbox
							id={NAME}
							color='primary'
							{...field}
							checked={meta.value}
							disabled={!dirty}
							required
						/>
						<InputGroup.Label>
							I have read and agree to the{' '}
							<Typography.A
								as={Button}
								type='button'
								role='button'
								padding='none'
								shadow='none'
								hoverShadow='none'
								backgroundColor='transparent'
								onClick={toggleDisclaimer}
							>
								disclaimer provided here
							</Typography.A>{' '}
							regarding my company's policies
						</InputGroup.Label>
					</InputGroup>
				</Panel.Body>
			</Panel>
			{!!meta.error && (
				<Typography.Span as='small' textColor={meta.error ? 'bad' : null}>
					{meta.error}
				</Typography.Span>
			)}
		</>
	);
};
