import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
	Button,
	Div,
	List,
	Spanner,
	Tag,
	Typography,
} from '@icecreamsocial/components';
import { HeaderPortal } from '../../../../Main';
import { ArrowBackIcon } from '../../../../../presentational';
import { OptimisticText } from '../../../../../optimistic';
import { useCampaign } from '../providers/Campaign';

const CampaignHeader = ({ hideHeader, userRole, links }) => {
	const history = useHistory();
	const { pathname } = useLocation();
	const { campaign } = useCampaign();
	const { data: focusedCampaign, isLoading } = campaign;

	const permissableLinks = useMemo(
		() => (userRole === 'affiliate' ? links.slice(0, links.length - 1) : links),
		[focusedCampaign, pathname]
	);

	return (
		!hideHeader && (
			<HeaderPortal>
				<Div backgroundColor='#EBEBEB' textAlign='center' padding='md'>
					<Spanner grid gridColumns='repeat(3, 1fr)' padding='0'>
						<Div display='flex' alignItems='center'>
							<Div elWidth='100%' textAlign='center'>
								<Button
									alignItems='center'
									backgroundColor='transparent'
									display='flex'
									hoverShadow='none'
									margin='0'
									onClick={() => history.push('/campaigns')}
									padding='0'
									shadow='none'
								>
									<ArrowBackIcon marginRight='md' /> Campaigns
								</Button>
							</Div>
						</Div>
						<Div display='flex' flexDirection='column' alignItems='center'>
							{focusedCampaign && (
								<>
									{isLoading ? (
										<Div elWidth='100%' textAlign='center'>
											<OptimisticText
												elHeight='1em'
												elWidth='150px'
												margin='8px auto'
											/>
										</Div>
									) : (
										<>
											<Div elWidth='100%' textAlign='center'>
												<Typography.Span marginLeft='sm' typoWeight='bold'>
													{focusedCampaign.name}
												</Typography.Span>
											</Div>
											<Div
												elWidth='100%'
												textAlign='center'
												marginTop='sm'
												textColor='grey'
											>
												<Typography.Span as='small' textTransform='capitalize'>
													Campaign ID &#x23;{focusedCampaign.id}
												</Typography.Span>
											</Div>
										</>
									)}
								</>
							)}
						</Div>
						<Div elWidth='100%' textAlign='right'>
							{isLoading ? (
								<Tag optimistic elWidth='80px' borderRadius='default'>
									&nbsp;
								</Tag>
							) : (
								<Tag
									textTransform='capitalize'
									textColor={
										focusedCampaign.status === 'active' ? 'good' : 'accent'
									}
									borderColor={
										focusedCampaign.status === 'active' ? 'good' : 'accent'
									}
								>
									{focusedCampaign.status}
								</Tag>
							)}
						</Div>
					</Spanner>
				</Div>
				<Div backgroundColor='#EBEBEB'>
					<Div elMaxWidth={['100%', '66%']} margin='auto'>
						<List
							bullets='none'
							display='flex'
							justifyContent='space-between'
							padding='md'
						>
							{permissableLinks.slice(1).map(({ active, text, to }, i) => (
								<List.Item
									key={i}
									flexGrow='1'
									textAlign='center'
									borderBottom='3px solid'
									borderColor={active ? 'brand' : 'transparent'}
									marginBottom='0'
								>
									<Typography.Span
										as={Link}
										to={to}
										display='block'
										elWidth='100%'
										paddingTop='md'
										paddingBottom='md'
										textTransform='capitalize'
										textDecoration='none'
										textColor='default'
									>
										{text}
									</Typography.Span>
								</List.Item>
							))}
						</List>
					</Div>
				</Div>
			</HeaderPortal>
		)
	);
};

CampaignHeader.displayName = 'Campaigns.Campaign.Header';

export const Header = observer(CampaignHeader);
