import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
	Button,
	Div,
	InputGroup,
	Panel,
	Typography,
} from '@icecreamsocial/components';
import {
	FormControlLabel,
	RadioGroup,
	Radio,
	Checkbox,
} from '@material-ui/core';
import { EnhancedForm2, RequiredAsterisk } from '..';
import { flags, isICSURL } from '../../../utils';
import { Disclaimer } from './components/Disclaimer';
import { DisclaimerAcknowledgementField } from './components/DisclaimerAcknowledgementField';

/**
 * @feature-flags
 */
const ENABLE_MASK_INFO = flags.isActive(
	flags.names.ENABLE_EXPERIMENTAL_MASK_INFO
);

const BusinessPolicies = ({
	maskInfo = false,
	termsAndConditionsURL = '',
	privacyPolicyURL = '',
	onSubmit = () => undefined,
	allowRouteBlock = true,
	renderControls = null,
	disclaimerAcknowledgement = false,
}) => {
	const [openDisclaimer, setOpenDisclaimer] = useState(false);

	const toggleDisclaimer = () => {
		setOpenDisclaimer(!openDisclaimer);
	};

	const makeAgreeCallback = (formik) => () => {
		console.debug(formik.values);
		formik.setFieldValue('disclaimerAcknowledgement', true);
		console.debug(formik.values);

		toggleDisclaimer();
	};

	const formikProps = {
		enableReinitialize: true,
		initialValues: {
			disclaimerAcknowledgement,
			maskInfo,
			termsAndConditionsURL,
			privacyPolicyURL,
		},
		onSubmit,
		validate(values) {
			const DEFAULT_URL_ERROR = 'You must provide your own URL';
			const ACKNOWLEDGEMENT_REQUIRED =
				'You must review and agree to the disclaimer to update your policies';
			const errors = {};
			const {
				privacyPolicyURL,
				termsAndConditionsURL,
				disclaimerAcknowledgement,
			} = values;

			if (isICSURL(privacyPolicyURL)) {
				errors.privacyPolicyURL = DEFAULT_URL_ERROR;
			}
			if (isICSURL(termsAndConditionsURL)) {
				errors.termsAndConditionsURL = DEFAULT_URL_ERROR;
			}

			if (!disclaimerAcknowledgement) {
				errors.disclaimerAcknowledgement = ACKNOWLEDGEMENT_REQUIRED;
			}

			return errors;
		},
	};
	return (
		<>
			<EnhancedForm2
				formikProps={formikProps}
				promptIf={({ dirty }) => allowRouteBlock && dirty}
				renderControls={renderControls}
			>
				{(businessPolicies) => (
					<>
						<Disclaimer
							isOpen={openDisclaimer}
							onClose={toggleDisclaimer}
							onCancel={toggleDisclaimer}
							onAgree={makeAgreeCallback(businessPolicies)}
							showButtons={businessPolicies.dirty}
						/>
						<Panel backgroundColor='bodyInverse' marginBottom='lg'>
							<Panel.Header backgroundColor='bodyInverse' textColor='body'>
								<Typography.H2 margin='none' typoSize='md'>
									Terms &amp; Policy Settings
								</Typography.H2>
								<Typography.P typoSize='sm' marginBottom='none'>
									These policies represent the agreement between your business
									and customers. Clients are responsible for ensuring their
									referral campaigns are compliant with necessary privacy and
									data laws and understanding how ICS processes their customers'
									data. For more information, please review Ice Cream Social's{' '}
									<Typography.A
										target='_blank'
										href='https://icecreamsocial.io/privacy-policy-terms/ '
									>
										Privacy Policy
									</Typography.A>{' '}
									and
									<Typography.A
										target='_blank'
										href='https://icecreamsocial.io/terms-of-service/'
									>
										Terms of Service
									</Typography.A>
								</Typography.P>
							</Panel.Header>
							<Panel.Body paddingTop='none'>
								{ENABLE_MASK_INFO && (
									<Div marginBottom='sm'>
										<Div paddingTop='sm' paddingBottom='sm'>
											<Typography.P margin='0'>
												Mask Info <RequiredAsterisk />
											</Typography.P>
											<Typography.P as='small'>
												You can hide influencers and invitees contact info so it
												won't appear in the admin
											</Typography.P>
										</Div>
										<RadioGroup
											name='maskInfo'
											value={`${businessPolicies.values.maskInfo}`}
											onChange={(e, v) => {
												const value = v === 'true' ? true : false;
												businessPolicies.setFieldValue('maskInfo', value);
											}}
											aria-label='maskInfo'
										>
											<Div
												backgroundColor='ambientLight'
												paddingLeft='sm'
												paddingRight='sm'
												borderRadius='default'
												marginBottom='sm'
											>
												<FormControlLabel
													value='false'
													control={
														<Radio
															id='maskInfo'
															name='maskInfo'
															color='primary'
															required
														/>
													}
													label="Don't hide contact information"
												/>
											</Div>
											<Div
												backgroundColor='ambientLight'
												paddingLeft='sm'
												paddingRight='sm'
												borderRadius='default'
											>
												<FormControlLabel
													value='true'
													control={
														<Radio
															id='maskInfo'
															name='maskInfo'
															color='primary'
															required
														/>
													}
													label='Hide contact information'
												/>
											</Div>
										</RadioGroup>
									</Div>
								)}
								<InputGroup elWidth='100%' marginBottom='sm'>
									<InputGroup.Label>
										Terms &amp; Conditions URL <RequiredAsterisk />
									</InputGroup.Label>
									<InputGroup.Fields>
										<InputGroup.Input
											id='termsAndConditionsURL'
											name='termsAndConditionsURL'
											type='url'
											paddingLeft='md'
											value={businessPolicies.values.termsAndConditionsURL}
											onChange={businessPolicies.handleChange}
											required
										/>
									</InputGroup.Fields>
									<Typography.Span
										as='small'
										textColor={
											businessPolicies.errors.termsAndConditionsURL
												? 'bad'
												: null
										}
									>
										{businessPolicies.errors.termsAndConditionsURL
											? businessPolicies.errors.termsAndConditionsURL
											: 'Please enter a complete URL including http/https'}
									</Typography.Span>
								</InputGroup>
								<InputGroup elWidth='100%' marginBottom='sm'>
									<InputGroup.Label>
										Privacy Policy URL <RequiredAsterisk />
									</InputGroup.Label>
									<InputGroup.Fields>
										<InputGroup.Input
											id='privacyPolicyURL'
											name='privacyPolicyURL'
											type='url'
											paddingLeft='md'
											value={businessPolicies.values.privacyPolicyURL}
											onChange={businessPolicies.handleChange}
											required
										/>
									</InputGroup.Fields>
									<Typography.Span
										as='small'
										textColor={
											businessPolicies.errors.privacyPolicyURL ? 'bad' : null
										}
									>
										{businessPolicies.errors.privacyPolicyURL
											? businessPolicies.errors.privacyPolicyURL
											: 'Please enter a complete URL including http/https'}
									</Typography.Span>
								</InputGroup>
							</Panel.Body>
							<Panel.Footer paddingTop='none'>
								<DisclaimerAcknowledgementField
									toggleDisclaimer={toggleDisclaimer}
								/>
							</Panel.Footer>
						</Panel>
					</>
				)}
			</EnhancedForm2>
		</>
	);
};
BusinessPolicies.displayName = 'BusinessPoliciesForm';

export default observer(BusinessPolicies);
