import React, { createContext, useContext, useReducer } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useQueryString } from '../hooks';

/**
 * This is for a function that maps an extended state to the defined ConversionFilters state.  All params are optional
 */
export type MapFn<State, Unmapped> = (
	query: Partial<State & Unmapped>
) => Partial<State>;

/**
 * This is for the update function of the filter provider
 */
export type Update<State> = (query: Partial<State>) => void;

export type Exceptions<State> = (keyof State)[];

/**
 * This is the shape of the Filter context
 */
export type Context<State> = null | {
	state: Partial<State>;
	update: Update<State>;
	clear: Clear<State>;
};

/**
 * This is for the clear functions of the filter provider
 * any keys provided to the exceptions list will not be cleared
 */
export type Clear<State> = (...exceptions: Exceptions<State>) => void;

export type FilterProviderReturn<State, Unmapped> = {
	Context: React.Context<Context<State>>;
	Provider: React.FC<{ mapControlsToState?: MapFn<State, Unmapped> }>;
	useFilters: () => Context<State>;
};

export type FilterFactoryFn<Filters, Unmapped> = (
	filterState: Filters
) => FilterProviderReturn<Filters, Unmapped>;

/**
 * @internal
 * @template State
 */

/**
 * creates a new filter context and provider
 */
export const filterProviderFactory = () => {
	const Context = createContext(undefined);
	const Provider = createProvider(Context);

	const useFilters = () => {
		const context = useContext(Context);
		if (context === undefined) {
			throw new Error('useFilter must be used within a FilterProvider');
		}

		return context;
	};

	return {
		Context,
		Provider,
		useFilters,
	};
};

/**
 * creates an enhanced provider from the default context
 * @param {React.Context<FilterProviderFactory.Context<State> | undefined>} Context
 * @returns {React.FC<{ mapControlsToState?: FilterProviderFactory.MapFn<State>}>}
 */
const createProvider = (Context) => ({ children, mapControlsToState }) => {
	/**
	 * we expect the query string to include part of the expected state
	 * @type {{ query: Partial<State>, qs: any }}
	 */
	const { query: state, qs } = useQueryString();
	const { pathname } = useLocation();
	const history = useHistory();

	/**
	 * update the the managed state in the query, ignoring all irrelevant params
	 * @param {Partial<State>} values the incoming values to update
	 */
	const update = (values) => {
		const merged = { ...state, ...values };
		const mapped =
			typeof mapControlsToState === 'function'
				? mapControlsToState(merged)
				: merged;
		const cleaned = removeEmptyValues(mapped);
		const stringified = qs.stringify(cleaned);
		const url = `${pathname}${stringified.length ? `?${stringified}` : ''}`;
		history.push(url);
	};

	/**
	 * clear the managed state ignoring all irrelevant params
	 * @param {FilterProviderFactory.Exceptions<State>} exceptions values in the managed state to ignore when clearing
	 */
	const clear = (...exceptions) => {
		if (!state) return;

		/**
		 * this is the state that this filter provider manages
		 */
		const managedState =
			typeof mapControlsToState === 'function'
				? mapControlsToState(state)
				: { ...state };

		/**
		 * remove a key from the params if it is managed AND if it is NOT in the exceptions
		 * @param {string} key
		 * @returns {boolean} true if it should be removed, false otherwise
		 */
		const checkToRemove = (key) =>
			managedState[key] && exceptions.indexOf(key) === -1;

		/**
		 * create an object of the unmanaged state
		 */
		const other = Object.keys(state).reduce(
			(unmanaged, key) =>
				checkToRemove(key)
					? {
							...unmanaged,
					  }
					: { [key]: state[key], ...unmanaged },
			{}
		);

		const stringified = qs.stringify(other);
		const url = `${pathname}${stringified.length ? `?${stringified}` : ''}`;

		history.push(url);
	};

	/**
	 * the value that will be accessible from useContext
	 * @type {FilterProviderFactory.Context<State>}
	 */
	const value = { state, update, clear };

	return <Context.Provider value={value}>{children}</Context.Provider>;
};

/**
 * Removes empty values so that url string is clean
 * @param {*} values
 * @returns {Partial<State>}
 */
function removeEmptyValues(values) {
	const cleaned = Object.keys(values).reduce((vals, key) => {
		if (values[key] === undefined || values[key] === null || values[key] === '')
			return { ...vals };
		return { ...vals, [key]: values[key] };
	}, {});
	return cleaned;
}
