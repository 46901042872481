import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
	Button,
	Div,
	InputGroup,
	Select,
	Typography,
} from '@icecreamsocial/components';
import { useFormik } from 'formik';
import { AffiliateSelector } from '../../containers';
import { CalendarPicker, DropDownIcon } from '../';
import { useStore } from '../../../utils/hooks';
/**
 * @module DashboardFilterForm
 * @category Components
 * @subcategory Presentational
 */
//TODO remove store jocelyn

/**
 *
 * @param {object} props
 * @param {function} props.onSubmit the onSubmit function passed to useFormik
 * @param {function} props.onReset the onReset function passed to useFormik
 * @param {object} props.initialValues
 * @param {string} [props.initialValues.affiliateId] filter by an affiliate
 * @param {string} [props.initialValues.clientId] filter by a client
 * @param {string} [props.initialValues.campaignId] filter by a campaign
 */
const DashboardFilterForm = ({ onSubmit, onReset, initialValues }) => {
	const store = useStore();
	const authUser = store.Orchestrators.Auth.user;

	/**
	 * @refactor do not destructure mst models because observability may be lost.  This means that UI will not be updated on changes.
	 */
	const {
		role: userRole,
		affiliateId: userAffiliateId,
		affiliateName: userAffiliateName,
		clientId: userClientId,
		clientName: userClientName,
	} = authUser;

	/**
	 * for the filters
	 */
	const filtersForm = useFormik({
		enableReinitialize: true,
		initialValues,
		onSubmit,
		onReset,
	});

	let affiliateOptions = [];
	let clientOptions = [];
	let campaignOptions = [];

	useEffect(() => {
		if (userRole !== 'client') {
			store.getAffiliatesFlow({ affiliateId: userAffiliateId });
			store.getClientsFlow({ clientId: userClientId });
		}
		store.getCampaignsFlow({
			affiliateId: filtersForm.values.affiliateId || userAffiliateId,
			clientId: filtersForm.values.clientId || userClientId,
			clientSearch: filtersForm.values.clientId || userClientId,
			fromDate: filtersForm.values.fromDate,
			toDate: filtersForm.values.toDate,
		});
	}, [userRole, userAffiliateId, userClientId]);

	if (userRole !== 'client') {
		affiliateOptions = store.Collections.Affiliates.list
			.toJSON()
			.map(({ id, name }) => ({ value: id, label: name }));
		clientOptions = store.Collections.Clients.list
			.toJSON()
			.map(({ id, name }) => ({ value: id, label: name }));
	} else {
		affiliateOptions = [{ value: userAffiliateId, label: userAffiliateName }];
		clientOptions = [{ value: userClientId, label: userClientName }];
	}
	campaignOptions = store.Collections.Campaigns.list
		.toJSON()
		.map(({ id, name }) => ({ value: id, label: name }));

	const selectedCampaign = campaignOptions.filter(
		({ value }) => value === filtersForm.values.clientId
	);
	console.debug(selectedCampaign);

	return (
		<form onSubmit={filtersForm.handleSubmit}>
			<Div display='flex' alignItems='center' justifyContent='space-between'>
				<Typography.H2 typoWeight='500'>Filters</Typography.H2>
				<Button
					onClick={() => {
						filtersForm.handleReset();
						filtersForm.handleSubmit();
					}}
					typoSize='small'
					textDecoration='underline'
					border='none'
					shadow='none'
					hoverShadow='none'
					padding='sm'
					backgroundColor='transparent'
				>
					Reset filters
				</Button>
			</Div>
			<CalendarPicker
				label='Date Created'
				fromDate={filtersForm.values.fromDate}
				onFromDateChange={filtersForm.handleChange}
				toDate={filtersForm.values.toDate}
				onToDateChange={filtersForm.handleChange}
			/>
			{authUser.role !== 'client' && (
				<AffiliateSelector
					id='affiliateId'
					name='affiliateId'
					value={filtersForm.values.affiliateId}
					onChange={(val) => {
						filtersForm.setFieldValue('affiliateId', val);
					}}
				/>
				// <InputGroup elWidth="100%" marginBottom="sm">
				// 	<InputGroup.Label>Affiliate</InputGroup.Label>
				// 	<InputGroup.Fields>
				// 		<InputGroup.Input
				// 			as="select"
				// 			id="affiliateId"
				// 			name="affiliateId"
				// 			value={filtersForm.values.affiliateId}
				// 			onChange={filtersForm.handleChange}
				// 			paddingLeft="md"
				// 			elWidth="100%"
				// 		>
				// 			<option value="">Select One</option>
				// 			{affiliateOptions.map(({ value, label }) => (
				// 				<option value={value}>{label}</option>
				// 			))}
				// 		</InputGroup.Input>
				// 		<InputGroup.Icon>
				// 			<DropDownIcon />
				// 		</InputGroup.Icon>
				// 	</InputGroup.Fields>
				// </InputGroup>
			)}
			{authUser.role !== 'client' && (
				<InputGroup elWidth='100%' marginBottom='sm'>
					<InputGroup.Label>Client</InputGroup.Label>
					<Select
						name='clientId'
						options={[{ value: '', label: 'Select One' }, ...clientOptions]}
						defaultValue={() =>
							clientOptions.filter(
								(option) => option.value === filtersForm.values.clientId
							)[0]
						}
						onChange={(option) =>
							filtersForm.setFieldValue('clientId', option.value)
						}
					/>
				</InputGroup>
			)}
			<InputGroup elWidth='100%' marginBottom='lg'>
				<InputGroup.Label>Campaign</InputGroup.Label>
				<Select
					name='campaignId'
					placeholder='All Campaigns'
					options={campaignOptions}
					onChange={(option) =>
						filtersForm.setFieldValue('campaignId', option.value)
					}
				/>
			</InputGroup>

			<Button
				type='submit'
				disable={!filtersForm.isValid}
				elWidth='100%'
				textColor='bodyInverse'
				backgroundColor='brand'
				padding='sm'
			>
				Apply Filters
			</Button>
		</form>
	);
};

DashboardFilterForm.displayName = 'DashboardFilterForm';
export default observer(DashboardFilterForm);
