import React from 'react';
import { observer } from 'mobx-react-lite';
import { useFormik } from 'formik';
import {
	Panel,
	Typography,
	Div,
	InputGroup,
	Button,
	List,
	Img,
} from '@icecreamsocial/components';
import { PageMeta } from '../../presentational';
import { useTheme, useStore } from '../../../utils/hooks';
import backgroundImg from '../../../assets/background_login.svg';
import logo from '../../../assets/logo_ics_login.svg';

// should probably just use Div instead of Panel.  It works but isn't semantically
// correct

const Auth = () => {
	const store = useStore();
	const formik = useFormik({
		initialValues: {
			email: '',
			password: '',
		},
		onSubmit: (loginInfo) => {
			store.loginFlow(loginInfo);
			// console.log(loginInfo);
		},
	});

	return (
		<>
			<PageMeta
				title="Login | Ice Cream Social"
				description="Log into your admin account"
			/>
			<Panel
				flexDirection="row"
				style={{ overflow: 'hidden', backgroundImage: `url(${backgroundImg})` }}
				borderRadius="none"
				border="none"
			>
				<Panel.Body
					elWidth="50%"
					display="flex"
					flexDirection="column"
					alignItems="space-between"
					elHeight="100vh"
					padding="xlg"
					backgroundColor="bodyInverse"
					borderTopRightRadius="10%"
					borderBottomRightRadius="10%"
					shadow="highest"
				>
					<Div
						elWidth="100%"
						elMaxWidth="450px"
						flexGrow="1"
						margin="auto"
						marginTop="10%"
					>
						<Img src={logo} elWidth="88px" />
						<Typography.P>
							Sign in to your Ice Cream Social account
						</Typography.P>
						{!!store.loginError && (
							<Typography.Span textColor="bad">
								{store.loginError}
							</Typography.Span>
						)}
						<form onSubmit={formik.handleSubmit}>
							<InputGroup display="block">
								<InputGroup.Label>Email</InputGroup.Label>
								<InputGroup.Fields>
									<InputGroup.Input
										id="emailAddress"
										name="email"
										type="email"
										onChange={formik.handleChange}
										paddingLeft="lg"
										required
									/>
								</InputGroup.Fields>
							</InputGroup>
							<InputGroup display="block" marginTop="md" marginBottom="xlg">
								<InputGroup.Label>Password</InputGroup.Label>
								<InputGroup.Fields>
									<InputGroup.Input
										id="password"
										name="password"
										paddingLeft="lg"
										onChange={formik.handleChange}
										type="password"
										required
									/>
								</InputGroup.Fields>
							</InputGroup>
							<Button
								type="submit"
								backgroundColor="brand"
								textColor="bodyInverse"
								display="block"
								elWidth="100%"
							>
								Sign in
							</Button>
						</form>
					</Div>
					<Div
						elWidth="100%"
						elMaxWidth="450px"
						margin="auto"
						textAlign="center"
					>
						<Typography.Span>&copy; Ice Cream Social</Typography.Span>
						<List
							bullets="none"
							display="flex"
							justifyContent="space-between"
							marginTop="md"
						>
							<List.Item>
								<Typography.A
									target="_blank"
									href="http://support.icecreamsocial.io/"
									textColor="ambientDark"
									visitedTextColor="ambientDark"
								>
									Support
								</Typography.A>
							</List.Item>
							<List.Item>
								<Typography.A
									target="_blank"
									href="https://icecreamsocial.io/privacy-policy-terms/"
									textColor="ambientDark"
									visitedTextColor="ambientDark"
								>
									Privacy Policy
								</Typography.A>
							</List.Item>
							<List.Item>
								<Typography.A
									target="_blank"
									href="https://icecreamsocial.io/terms-of-service/"
									textColor="ambientDark"
									visitedTextColor="ambientDark"
								>
									Terms &amp; Conditions
								</Typography.A>
							</List.Item>
						</List>
					</Div>
				</Panel.Body>
				<Panel.Body
					elWidth="50%"
					textColor="bodyInverse"
					textAlign="center"
					display="flex"
					alignItems="center"
					padding="xlg"
				/>
			</Panel>
		</>
	);
};

Auth.displayName = 'Auth';

export default observer(Auth);
