import React from 'react';
import { observer } from 'mobx-react-lite';
import { Panel, Typography } from '@icecreamsocial/components';

import { ImageUploader, EnhancedForm, RequiredAsterisk } from '../';
import { formatImageURL } from '../../../utils';

const Brand = ({
	logo = undefined,
	allowRouteBlock = true,
	renderControls = null,
	onSubmit,
}) => {
	const formikProps = {
		enableReinitialize: true,
		initialValues: {
			logo: logo ? formatImageURL(logo) : undefined,
		},
		validate: (values) => {
			const ONE_MB = 1024 * 1024;
			const errors = {};
			if (!values.logo) errors.logo = 'Please upload a logo';
			if (values.logo?.size > ONE_MB) {
				errors.logo = `Your image has exceeded the maximum file size of 1MB. (Size: ${(
					values.logo?.size / ONE_MB || 0
				).toFixed(2)}MB )`;
			}
			return errors;
		},
		onSubmit,
	};

	return (
		<EnhancedForm
			formikProps={formikProps}
			promptIf={({ dirty }) => allowRouteBlock && dirty}
			renderControls={renderControls}
		>
			{(brand) => (
				<Panel backgroundColor="bodyInverse" marginBottom="lg">
					<Panel.Header backgroundColor="bodyInverse">
						<Typography.H2
							textColor="body"
							textAlign="center"
							margin="none"
							typoSize="md"
						>
							Logo <RequiredAsterisk />
						</Typography.H2>
					</Panel.Header>
					<Panel.Body>
						<ImageUploader
							name="logo"
							type="thumb"
							value={brand.values.logo}
							handleChange={(e) =>
								brand.setFieldValue('logo', e.currentTarget.files[0])
							}
							onReplace={(e) => {
								e.preventDefault();
								brand.setFieldValue('logo', undefined);
							}}
							required={true}
						/>
						{brand.errors?.logo && (
							<Typography.P textColor="bad">{brand.errors?.logo}</Typography.P>
						)}
					</Panel.Body>
				</Panel>
			)}
		</EnhancedForm>
	);
};
Brand.displayName = 'BusinessForm.Brand';

export default observer(Brand);
