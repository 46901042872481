import React from 'react';
import { RadioGroup, Switch } from '@material-ui/core';
import {
	Button,
	Div,
	Img,
	InputGroup,
	Thumbnail,
	Typography,
} from '@icecreamsocial/components';
import {
	ColorPicker,
	DropDownIcon,
} from '../../../../../../../../../../presentational';
import { useScriptSettings } from '../../../../providers';
import { flags } from '../../../../../../../../../../../utils';
import crown from '../../../../assets/crown.svg';
import diamond from '../../../../assets/diamond.svg';
import discountCoupon from '../../../../assets/discount-coupon.svg';
import discountStar from '../../../../assets/discount-star.svg';
import loot from '../../../../assets/loot.svg';
import medal from '../../../../assets/medal.svg';
import megaphone from '../../../../assets/megaphone.svg';
import popsicle from '../../../../assets/popsicle.svg';
import present from '../../../../assets/present.svg';
import presents from '../../../../assets/presents.svg';
import ribbon from '../../../../assets/ribbon.svg';
import trophy from '../../../../assets/trophy.svg';

const icons = {
	popsicle,
	'discount-coupon': discountCoupon,
	'discount-star': discountStar,
	crown,
	diamond,
	loot,
	medal,
	megaphone,
	present,
	presents,
	ribbon,
	trophy,
};

export const TriggerCustomizer = () => {
	const { settings, updateBehavior } = useScriptSettings();
	const defaultIcon = 'popsicle';

	const handleTriggerChange = (field) => (e) => {
		const { checked } = e.currentTarget;
		updateBehavior({ trigger: { [field]: checked } });
	};

	/**
	 * handles the icon highlighting
	 */
	const getIconProps = (name) =>
		settings.behavior?.trigger?.backgroundColor
			? // only set background color if background color has been selected and the icon name matches the selected icon
			  {
					backgroundColor:
						settings.behavior?.trigger?.icon === name
							? settings.behavior?.trigger?.backgroundColor
							: 'ambientDark',
			  }
			: // default to background gradient if no background color is set
			  // default to popsicle if no icon has been chosen yet
			  {
					backgroundGradientColor:
						settings.behavior?.trigger?.icon === name ||
						(!settings.behavior?.trigger?.icon && name === defaultIcon)
							? `-225deg, #4568dc 0%, #321575 100%`
							: null,
			  };

	return (
		<form>
			<InputGroup elWidth='100%' marginBottom='md'>
				<InputGroup.Label typoWeight='500'>Type</InputGroup.Label>
				<InputGroup.Fields>
					<InputGroup.Input
						name='triggerType'
						as='select'
						value='FAB'
						paddingLeft='md'
						readOnly
						disabled
					>
						<option value='FAB'>FAB</option>
					</InputGroup.Input>
					<InputGroup.Icon>
						<DropDownIcon />
					</InputGroup.Icon>
				</InputGroup.Fields>
			</InputGroup>

			<RadioGroup name='icon'>
				<InputGroup.Label typoWeight='500'>Icon</InputGroup.Label>
				<Div
					grid
					gridColumns='repeat(auto-fit, minmax(48px, .5fr))'
					elWidth='100%'
					marginBottom='lg'
					style={{ gap: '12px' }}
				>
					{Object.keys(icons).map((name, key) => (
						<Button
							key={key}
							title={name}
							onClick={() =>
								updateBehavior({
									trigger: { icon: name },
								})
							}
							type='button'
							display='flex'
							alignItems='center'
							borderRadius='50%'
							padding='md'
							backgroundColor='ambientDark'
							style={{ cursor: 'pointer' }}
							{...getIconProps(name)}
						>
							<Img src={icons[name]} elWidth='100%' display='block' />
						</Button>
					))}
				</Div>
			</RadioGroup>

			<Div marginBottom='md'>
				<Typography.Span display='block' typoWeight='500' marginBottom='sm'>
					Background color
				</Typography.Span>
				<ColorPicker
					defaultColor={settings.behavior?.trigger?.backgroundColor}
					onChange={(backgroundColor) => {
						updateBehavior({
							trigger: { backgroundColor },
						});
					}}
				/>
			</Div>

			<InputGroup elWidth='100%' marginBottom='md'>
				<InputGroup.Label typoWeight='500'>Position</InputGroup.Label>
				<InputGroup.Fields>
					<InputGroup.Input
						name='triggerPosition'
						as='select'
						value={settings.behavior?.trigger?.position}
						onChange={(e) =>
							updateBehavior({
								trigger: { position: e.currentTarget.value },
							})
						}
						paddingLeft='md'
					>
						<option value='bottom'>Bottom</option>
						<option value='top'>Top</option>
						<option value='left'>Left</option>
						<option value='right'>Right</option>
						<option value='bottom left'>Bottom Left</option>
						<option value='bottom right'>Bottom Right</option>
						<option value='top left'>Top Left</option>
						<option value='top right'>Top Right</option>
					</InputGroup.Input>
					<InputGroup.Icon>
						<DropDownIcon />
					</InputGroup.Icon>
				</InputGroup.Fields>
			</InputGroup>
			<InputGroup display='flex' alignItems='center' marginBottom='md'>
				<Switch
					name={`hide`}
					color='primary'
					value={settings.behavior?.trigger?.hide}
					checked={settings.behavior?.trigger?.hide}
					onChange={handleTriggerChange('hide')}
				/>{' '}
				<InputGroup.Label display='inline-block'>Hide</InputGroup.Label>
			</InputGroup>
		</form>
	);
};
