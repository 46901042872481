import { types } from 'mobx-state-tree';
import {
	BaseCommunicator,
	ICSAffiliatesAPI,
	ICSAuthAPI,
	ICSCampaignsAPI,
	ICSClientsAPI,
	ICSProcessorsAPI,
	ICSCompaniesAPI,
	ICSInfluencersAPI,
	ICSInvitesAPI,
	ICSStatsAPI,
	ICSUsersAPI,
	ICSV2,
} from '@icecreamsocial/communicators';
const Communicators = types
	.compose(
		BaseCommunicator,
		types.model({
			AffiliatesAPI: ICSAffiliatesAPI,
			AuthAPI: ICSAuthAPI,
			CampaignsAPI: ICSCampaignsAPI,
			ClientsAPI: ICSClientsAPI,
			CompaniesAPI: ICSCompaniesAPI,
			InfluencersAPI: ICSInfluencersAPI,
			InvitesAPI: ICSInvitesAPI,
			ProcessorsAPI: ICSProcessorsAPI,
			StatsAPI: ICSStatsAPI,
			UsersAPI: ICSUsersAPI,
			AuthAPIV2: ICSV2.ICSAuthAPIV2,
			AffiliatesAPIV2: ICSV2.ICSAffiliatesAPIV2,
			UsersAPIV2: ICSV2.ICSUsersAPIV2,
			ClientsAPIV2: ICSV2.ICSClientsAPIV2,
			CampaignsAPIV2: ICSV2.ICSCampaignsAPIV2,
			CompanyAPIV2: ICSV2.ICSCompanyAPIV2,
			ProcessorsAPIV2: ICSV2.ICSProcessorsAPIV2,
			RewardsAPIV2: ICSV2.ICSRewardsAPIV2,
		})
	)
	.named('Communicators')
	.preProcessSnapshot((snapshot = {}) => ({
		...snapshot,
		AffiliatesAPI: snapshot.AffiliatesAPI || {},
		AuthAPI: snapshot.AuthAPI || {},
		CampaignsAPI: snapshot.CampaignsAPI || {},
		ClientsAPI: snapshot.ClientsAPI || {},
		CompaniesAPI: snapshot.CompaniesAPI || {},
		InfluencersAPI: snapshot.InfluencersAPI || {},
		InvitesAPI: snapshot.InvitesAPI || {},
		ProcessorsAPI: snapshot.ProcessorsAPI || {},
		StatsAPI: snapshot.StatsAPI || {},
		UsersAPI: snapshot.UsersAPI || {},
		AuthAPIV2: snapshot.ICSAuthAPIV2 || {},
		AffiliatesAPIV2: snapshot.ICSAffiliatesAPIV2 || {},
		UsersAPIV2: snapshot.ICSUsersAPIV2 || {},
		ClientsAPIV2: snapshot.ICSClientsAPIV2 || {},
		CampaignsAPIV2: snapshot.ICSCampaignsAPIV2 || {},
		CompanyAPIV2: snapshot.ICSCompanyAPIV2 || {},
		ProcessorsAPIV2: snapshot.ICSProcessorsAPIV2 || {},
		RewardsAPIV2: snapshot.ICSRewardsAPIV2 || {},
	}));

export default Communicators;
