import React, { useState } from 'react';
import { Button, Div } from '@icecreamsocial/components';
// import { CodeBlock } from '@atlaskit/code';
import Highlight, { defaultProps } from 'prism-react-renderer';
import theme from 'prism-react-renderer/themes/nightOwl';
import TooltipListener from '../TooltipListener';

/**
 * @note this is not super re-usable because of line 31 where marginLeft is set
 * this is because pretty printing a script dynamically is oddly difficult.
 * if you need to make this into something a bit more reusable, you can remove that line and make a new component
 * this is currently only used on the campaign integration page
 */
const ScriptViewer = ({ code, publicKey }) => {
	const copy = (txt) => {
		navigator.clipboard.writeText(txt);
	};
	return (
		<Div position='relative'>
			<Div
				position='absolute'
				positionTop='8px'
				positionRight='8px'
				display='flex'
				style={{ gap: '8px' }}
			>
				<TooltipListener text='Copied!'>
					{(openTooltip) => (
						<Button
							backgroundColor='brand'
							textColor='bodyInverse'
							paddingTop='sm'
							paddingBottom='sm'
							onClick={() => {
								copy(publicKey);
								openTooltip(true);
							}}
							typoSize='sm'
						>
							Copy Public Key
						</Button>
					)}
				</TooltipListener>
				<TooltipListener text='Copied!'>
					{(openTooltip) => (
						<Button
							backgroundColor='brand'
							textColor='bodyInverse'
							paddingTop='sm'
							paddingBottom='sm'
							onClick={() => {
								copy(code);
								openTooltip(true);
							}}
							typoSize='sm'
						>
							Copy Script
						</Button>
					)}
				</TooltipListener>
			</Div>
			<Highlight {...defaultProps} language='markup' theme={theme} code={code}>
				{({ className, style, tokens, getLineProps, getTokenProps }) => (
					<Div
						as='pre'
						className={className}
						style={style}
						padding='md'
						paddingTop='lg'
						borderRadius='default'
						overflow='auto'
					>
						{tokens.map((line, i) => {
							if (
								i === tokens.length - 1 &&
								line.length === 1 &&
								line[0].content === '\n'
							) {
								return null;
							}
							return (
								<Div
									marginLeft={i >= 3 && i <= tokens.length - 5 ? '2em' : null}
									{...getLineProps({ line, key: i })}
								>
									{line.map((token, key) => (
										<span {...getTokenProps({ token, key })} />
									))}
								</Div>
							);
						})}
					</Div>
				)}
			</Highlight>
		</Div>
	);
};

export default ScriptViewer;
