import React, { useState, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useFormik, FormikConfig, FormikProps } from 'formik';
import { RouteChangeBlocker } from '../';
/**
 * @module EnhancedForm
 * @category Components
 * @subcategory Presentational
 */

/**
 * @description callback function that receives the FormikProps and returns a boolean
 * @callback PromptIfCallback
 * @param {FormikProps} formikValues
 * @returns {boolean}
 */

/**
 * @description callback function that receives the FormikProps and returns a react element
 * @callback RenderFunc
 * @param {FormikProps<*>} formikValues
 * @returns {React.ReactElement | null}
 */

/**
 * @description A form with extra capabilities for blocking routes and rendering specific controls
 * @param {object} 											props
 * @param {module:EnhancedForm~RenderFunc} 									props.children React children with the form inputs
 * @param {FormikConfig}								props.formikProps configuration for the form using formik
 * @param {PromptIfCallback | boolean}	props.promptIf A callback that returns a boolean or a boolean to let the form know when it should prompt the users when submitting
 * @param {module:EnhancedForm~RenderFunc | null}						[props.renderControls = null] A render function mainly for convenience.  It takes care of a common pattern for creating forms
 * @param {string}											[props.promptMsg = null] message to be prompted with
 */
const EnhancedForm = ({
	children,
	formikProps,
	promptIf,
	renderControls = null,
	promptMsg = null,
	...rest
}) => {
	const formik = useFormik(formikProps);
	const shouldPrompt = useMemo(() => {
		const evalPrompt =
			typeof promptIf === 'function' ? promptIf(formik) : promptIf;
		return evalPrompt;
	}, [formik]);

	return (
		<>
			<form onSubmit={formik.handleSubmit} {...rest}>
				{children(formik)}
				{renderControls && renderControls(formik)}
			</form>
			<RouteChangeBlocker when={shouldPrompt} message={promptMsg} />
		</>
	);
};
EnhancedForm.displayName = 'EnhancedForm';

export default observer(EnhancedForm);
