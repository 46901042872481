import React from 'react';
import { observer } from 'mobx-react-lite';
import { InputGroup, Panel, Typography } from '@icecreamsocial/components';
import {
	DropDownIcon,
	EnhancedForm,
	RequiredAsterisk,
	SupportedLanguageSelect,
} from '..';

const BusinessProfile = ({
	businessName = '',
	businessAddress = '',
	supportEmail = '',
	currency = 'USD',
	language = 'en-US',
	maskInfo = false,
	termsAndConditionsURL = '',
	privacyPolicyURL = '',
	onSubmit = () => undefined,
	allowRouteBlock = true,
	renderControls = null,
}) => {
	const formikProps = {
		enableReinitialize: true,
		initialValues: {
			name: businessName,
			businessAddress,
			supportEmail,
			currency,
			language,
		},
		onSubmit,
	};
	return (
		<EnhancedForm
			formikProps={formikProps}
			promptIf={({ dirty }) => allowRouteBlock && dirty}
			renderControls={renderControls}
		>
			{(businessProfile) => (
				<>
					<Panel backgroundColor="bodyInverse" marginBottom="lg">
						<Panel.Header backgroundColor="bodyInverse">
							<Typography.H2
								textColor="body"
								textAlign="center"
								margin="none"
								typoSize="md"
							>
								General Info
							</Typography.H2>
						</Panel.Header>
						<Panel.Body>
							<InputGroup elWidth="100%" marginBottom="sm">
								<InputGroup.Label>
									Business Name (Legal) <RequiredAsterisk />
								</InputGroup.Label>
								<InputGroup.Fields>
									<InputGroup.Input
										id="name"
										name="name"
										paddingLeft="md"
										value={businessProfile.values.name}
										onChange={businessProfile.handleChange}
										autofocus
										required
									/>
								</InputGroup.Fields>
							</InputGroup>
							<InputGroup elWidth="100%" marginBottom="sm">
								<InputGroup.Label>
									Business Mailing Address (Legal) <RequiredAsterisk />
								</InputGroup.Label>
								<InputGroup.Fields>
									<InputGroup.Input
										id="businessAddress"
										name="businessAddress"
										paddingLeft="md"
										value={businessProfile.values.businessAddress}
										onChange={businessProfile.handleChange}
										required
									/>
								</InputGroup.Fields>
								<Typography.Span as="small">
									Don't worry, we won't be sending you any mail! We just need
									this to display on our outgoing emails for compliance
								</Typography.Span>
							</InputGroup>
							<InputGroup elWidth="100%" marginBottom="sm">
								<InputGroup.Label>
									Support Email <RequiredAsterisk />
								</InputGroup.Label>
								<InputGroup.Fields>
									<InputGroup.Input
										id="supportEmail"
										name="supportEmail"
										paddingLeft="md"
										value={businessProfile.values.supportEmail}
										onChange={businessProfile.handleChange}
										required
									/>
								</InputGroup.Fields>
								<Typography.Span as="small">
									We display this on our emails so influencers have a way to
									reach you.
								</Typography.Span>
							</InputGroup>
							<InputGroup elWidth="100%" marginBottom="sm">
								<InputGroup.Label>
									Currency <RequiredAsterisk />
								</InputGroup.Label>
								<InputGroup.Fields>
									<InputGroup.Input
										as="select"
										id="currency"
										name="currency"
										paddingLeft="md"
										value={businessProfile.values.currency}
										onChange={businessProfile.handleChange}
										required
									>
										<option value="">Choose Currency</option>
										<option value="USD">USD</option>
										<option value="KRW">KRW</option>
									</InputGroup.Input>
									<InputGroup.Icon>
										<DropDownIcon />
									</InputGroup.Icon>
								</InputGroup.Fields>
							</InputGroup>
							<InputGroup elWidth="100%" marginBottom="sm">
								<InputGroup.Label>
									Default Language <RequiredAsterisk />
								</InputGroup.Label>
								<SupportedLanguageSelect
									id="language"
									name="language"
									value={businessProfile.values.language}
									onChange={businessProfile.handleChange}
								/>
							</InputGroup>
						</Panel.Body>
					</Panel>
				</>
			)}
		</EnhancedForm>
	);
};
BusinessProfile.displayName = 'BusinessProfileForm';

export default observer(BusinessProfile);
