import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
	Div,
	InputGroup,
	Panel,
	Tag,
	Typography,
} from '@icecreamsocial/components';
import { useStore } from '../../../../../../../../../utils/hooks';
import {
	EditFormControls,
	EnhancedForm2,
	InfoIcon,
	Pagename,
	SecureIcon,
} from '../../../../../../../../presentational';
import { Checkbox, Radio, RadioGroup } from '@material-ui/core';
import SMSConsent from './components/SMSConsent';
import { flags } from '../../../../../../../../../utils';
import { useCampaign } from '../../../../providers/Campaign';

const NotificationSettings = () => {
	const store = useStore();
	const { campaign, update } = useCampaign();
	const { data } = campaign;
	const client = store.Orchestrators.Focused?.client || {};
	const allowSMS = client.allowSMS;

	const smsHeadingColor = allowSMS ? 'body' : 'ambient';
	const v2WidgetTagBgColor = allowSMS ? 'accentLight' : 'ambientLight';
	const v2WidgetTagColor = allowSMS ? 'accent' : 'ambient';

	const onSubmit = (values) => {
		update.mutate(values);
	};

	const formikProps = {
		enableReinitialize: true,
		initialValues: {
			useEmail: data?.useEmail,
			useEmailConsent: data?.useEmailConsent,
			useSMS: data?.useSMS,
			useSMSConsent: data?.useSMSConsent,
		},
		onSubmit,
	};

	useEffect(() => {
		if (client.id) {
			store.getClientUseCaseStatusFlow(client.id);
		}
	}, [client]);

	return (
		<Div>
			<Pagename>Notifications</Pagename>
			<Typography.P>
				Keep influencers in the loop by enabling notifications, informing them
				about offers and rewards while building trust through transparent
				consent management
			</Typography.P>
			{data && (
				<EnhancedForm2
					formikProps={formikProps}
					promptIf={({ dirty }) => dirty}
					renderControls={(props) => <EditFormControls {...props} />}
				>
					{(settings) => (
						<>
							<Panel backgroundColor='bodyInverse' marginBottom='lg'>
								<Panel.Body>
									<Typography.H2
										textColor='body'
										textAlign='center'
										margin='none'
										typoSize='md'
									>
										Email
									</Typography.H2>
									<Typography.P marginBottom='xlg'>
										This setting allows you to send email notifications to your
										customers. While collecting consent for email notifications
										is optional, obtaining consent is recommended for customer
										trust and best practice in email marketing.
									</Typography.P>
									<InputGroup
										marginBottom='md'
										display='flex'
										alignItems='center'
										style={{ gap: '16px' }}
									>
										<Checkbox
											name='useEmail'
											color='primary'
											value={settings.values.useEmail}
											checked={settings.values.useEmail === true}
											onClick={settings.handleChange}
										/>
										<InputGroup.Label>
											Send email notifications
										</InputGroup.Label>
									</InputGroup>
									<InputGroup
										marginBottom='md'
										display='flex'
										alignItems='center'
										style={{ gap: '16px' }}
									>
										<Checkbox
											name='useEmailConsent'
											color='primary'
											value={settings.values.useEmailConsent}
											checked={settings.values.useEmailConsent === true}
											onClick={settings.handleChange}
										/>

										<Div>
											<InputGroup.Label>
												Collect email marketing consent
											</InputGroup.Label>
											<Typography.Span
												textColor='bodyMuted'
												typoHeight='1.33em'
											>
												Collecting marketing consent respects legal and privacy
												guidelines, sending promotional content only to
												consenting influencers
											</Typography.Span>
										</Div>
									</InputGroup>
								</Panel.Body>
							</Panel>

							{flags.isActive(
								flags.names.ENABLE_EXPERIMENTAL_CAMPAIGN_SMS_SETTINGS
							) ? (
								<>
									<Panel backgroundColor='bodyInverse' marginBottom='lg'>
										<Panel.Header
											backgroundColor='bodyInverse'
											textColor='body'
											paddingBottom='none'
										>
											<Div
												grid
												gridColumns='repeat(3, 1fr)'
												alignItems='center'
											>
												&nbsp;
												<Typography.H2
													textColor={smsHeadingColor}
													textAlign='center'
													margin='none'
													typoSize='md'
												>
													SMS
												</Typography.H2>
												<Div textAlign='right'>
													<Tag
														backgroundColor={v2WidgetTagBgColor}
														textColor={v2WidgetTagColor}
														border='none'
														borderColor='transparent'
														typoSize='sm'
														typoWeight='normal'
														padding='sm'
														textAlign='center'
														elWidth='max-content'
													>
														<Div display='flex' alignItems='center'>
															<InfoIcon size={16} marginRight='sm' /> V2 Widget
															Only
														</Div>
													</Tag>
												</Div>
											</Div>
										</Panel.Header>
										{allowSMS ? (
											<Panel.Body>
												<Typography.P marginBottom='xlg'>
													By enabling SMS notifications for this campaign,
													influencer phone numbers will be collected and they
													will have to grant consent through ICS widgets to
													receive them. Influencers can opt-out of SMS
													notifications at any time.
												</Typography.P>
												<InputGroup
													marginBottom='md'
													display='flex'
													alignItems='center'
													style={{ gap: '16px' }}
												>
													<Checkbox
														name='useSMS'
														color='primary'
														value={settings.values.useSMS}
														checked={settings.values.useSMS === true}
														onClick={(e) => {
															settings.setFieldTouched('useSMS', true);
															settings.handleChange(e);
														}}
													/>
													<InputGroup.Label>
														Send SMS notifications
													</InputGroup.Label>
												</InputGroup>
												<InputGroup
													marginBottom='md'
													display='flex'
													alignItems='center'
													style={{ gap: '16px' }}
												>
													<SMSConsent
														name='useSMSConsent'
														value={settings.values.useSMSConsent}
													/>
													<Div>
														<InputGroup.Label>
															Collect SMS marketing consent
														</InputGroup.Label>
														<Typography.Span
															textColor='bodyMuted'
															typoHeight='1.33em'
														>
															When SMS marketing messages are enabled, consent
															must be collected from customers in compliance
															with privacy regulations. This setting ensures
															that the necessary consent is obtained before
															sending messages.
														</Typography.Span>
													</Div>
												</InputGroup>
											</Panel.Body>
										) : (
											<Panel.Body>
												<Panel backgroundColor='ambientLight' shadow='raised'>
													<Panel.Body
														display='flex'
														alignItems='center'
														style={{ gap: '16px' }}
													>
														<SecureIcon />
														<Typography.P>
															If you're interested in sending SMS notifications
															to your influencers, reach out to your ICS
															representative for assistance in enabling this
															feature
														</Typography.P>
													</Panel.Body>
												</Panel>
											</Panel.Body>
										)}
									</Panel>
								</>
							) : null}
						</>
					)}
				</EnhancedForm2>
			)}
		</Div>
	);
};
NotificationSettings.displayName = 'Campaigns.Campaign.Settings.Notifications';

export default observer(NotificationSettings);
