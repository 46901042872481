import { useEffect } from 'react';
import { autorun } from 'mobx';
import { useSnackbar } from 'notistack';
import { useStore } from './useStore';
/**
 * @module useNotificationQueue
 * @category Shared
 * @subcategory Hooks
 * @exports  useNotificationQueue
 */
/**
 * @function
 * @description Subscribes to the store's notification list and enqueues a snackbar on update.
 * @returns {void}
 */
export const useNotificationQueue = () => {
	const store = useStore();
	const { enqueueSnackbar } = useSnackbar();
	useEffect(() => {
		autorun(() => {
			try {
				if (store.Orchestrators.View.notifications.items.length) {
					const notification = store.Orchestrators.View.notifications.pop();
					const { text, id, type } = notification;
					enqueueSnackbar(text, {
						key: id,
						variant: mapTypeToVariant(type),
					});
				}
			} catch (e) {
				console.warn(e);
			}
		});
	}, []);
};

function mapTypeToVariant(type) {
	return type === 'emphasis' ? 'info' : type;
}
