import { Div, InputGroup, Typography } from '@icecreamsocial/components';
import React from 'react';
import { ColorPicker } from '../../../../../../../../../../presentational';
import { useScriptSettings } from '../../../../providers';

export const ThemeCustomizer = () => {
	const { updateTheme, settings } = useScriptSettings();
	const handleChange = (field, value) => {
		updateTheme({ ...(settings.theme || {}), [field]: value });
	};

	return (
		<form>
			<Div marginBottom='md'>
				<Typography.Span display='block' typoWeight='500' marginBottom='sm'>
					Accent Color
				</Typography.Span>
				<ColorPicker
					defaultColor={settings.theme?.accent}
					onChange={(color) => {
						handleChange('colors', { accent: color });
					}}
				/>
				<Typography.Span as='small'>
					Determines the color of important interactive elements
				</Typography.Span>
			</Div>
			<Div marginBottom='md'>
				<Typography.Span display='block' typoWeight='500' marginBottom='sm'>
					Ambient Dark
				</Typography.Span>
				<ColorPicker
					defaultColor={settings.theme?.ambientDark}
					onChange={(color) => {
						handleChange('colors', { ambientDark: color });
					}}
				/>
				<Typography.Span as='small'>
					Determines the color of informative panels
				</Typography.Span>
			</Div>
			<Div marginBottom='md'>
				<Typography.Span display='block' typoWeight='500' marginBottom='sm'>
					Ambient Light
				</Typography.Span>
				<ColorPicker
					defaultColor={settings.theme?.ambientLight}
					onChange={(color) => {
						handleChange('colors', { ambientLight: color });
					}}
				/>
				<Typography.Span as='small'>
					Determines the widget background color
				</Typography.Span>
			</Div>
			<InputGroup marginBottom='md'>
				<InputGroup.Label typoWeight='500' paddingTop='0'>
					Border Radius
				</InputGroup.Label>
				<InputGroup.Fields>
					<InputGroup.Input
						name='borderRadius'
						value={settings.theme?.borderRadius}
						onChange={(e) => {
							handleChange('borderRadius', e.currentTarget.value);
						}}
						marginLeft='md'
					/>
				</InputGroup.Fields>
			</InputGroup>
		</form>
	);
};
