import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Panel, Div } from '@icecreamsocial/components';
import { BaseModal } from '../../presentational';
import {
	useQueryString,
	useTheme,
	useStore,
	useCopyRef,
} from '../../../utils/hooks';
import { flags, toggle } from '../../../utils';
import Header from './Header';
import ProfileSummary from './ProfileSummary';
import MarketingConsentSummary from './MarketingConsentSummary';
import InfluencerLink from './InfluencerLink';
import MetaData from './MetaData';
import Overview from './Overview';
import ProfileEdit from './ProfileEdit';
import MarketingConsentEdit from './MarketingConsentEdit';
import { formatDateTime, StoreProvider } from '../../../utils';
import ConversionsCancel from './ConversionsCancel';
import RewardsIssuer from './RewardsIssuer';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '../../../queries/influencers';
import { queryKeys as rewardsQueryKeys } from '../../../queries/rewards';
import ParentInfluencer from './ParentInfluencer';

/**
 * @typedef InfluencerManagerProps
 * @property {*} influencer
 * @property {*} campaign
 */

/**
 *
 * @param {InfluencerManagerProps} props
 * @returns
 */
const InfluencerManager = ({ influencer, campaign }) => {
	const store = useStore();
	const { campaignId } = useParams();
	const history = useHistory();
	const { pathname } = useLocation();
	const queryClient = useQueryClient();
	const { query, addParam, getParam } = useQueryString();
	const [modalOpen, setModalOpen] = useState(false);

	// moving away from the "Focused" Orchestrator for simplification
	// mst sets focus under the hood
	const [focusedCampaign, setFocusedCampaign] = useState({});

	const getCampaign = async () => {
		const focused = await store.resolveCampaign(campaignId);
		setFocusedCampaign(focused);
	};

	/**
	 * get influencer data
	 */
	const {
		isLoading: isInfluencerLoading,
		isError: isInfluencerError,
		data: focusedInfluencer,
	} = useQuery(
		queryKeys.getInfluencer({
			influencerId: query.iid,
			campaignId,
		}),
		() =>
			store.resolveInfluencer({
				influencerId: query.iid,
				campaignId,
			}),
		{ enabled: !!query.iid }
	);

	/**
	 * updates an influencer's profile
	 * @returns
	 */
	const updateInfluencerMutation = async ({
		phone: mobileNumber,
		name,
		email,
	}) => {
		if (!query.iid || !focusedInfluencer) throw Error('No influencer');
		const influencer = await store.updateInfluencerFlow(
			{
				identifier: focusedInfluencer.identifier,
				name,
				email,
				mobileNumber,
			},
			query.iid,
			campaignId
		);
		return influencer;
	};

	/**
	 * updates an influencer's consent
	 */
	const updateConsentMutation = async ({ emailConsent, smsConsent }) => {
		if (!query.iid || !focusedInfluencer) throw Error('No influencer');

		const influencer = await store.updateInfluencerFlow(
			{
				identifier: focusedInfluencer.identifier,
				name: focusedInfluencer.name,
				email: focusedInfluencer.email,
				emailConsent,
				smsConsent,
			},
			query.iid,
			campaignId
		);
		return influencer;
	};

	/**
	 * anonymize an influencer
	 */
	const anonymizeInfluencerMutation = async () => {
		if (!query.iid || !focusedInfluencer) throw Error('No influencer');
		const influencer = await store.anonymizeInfluencerFlow({
			influencerId: query.iid,
			campaignId,
		});
		return influencer;
	};

	/**
	 * cancel conversions
	 * @todo remove dependence on Collections
	 */
	const cancelConversionMutation = async ({ conversionId }) =>
		store.cancelConversionFlow({
			influencerId: query.iid,
			campaignId,
			conversionId,
		});

	/**
	 * process an influencer
	 */
	const processInfluencerMutation = () => {
		if (!query.iid) throw Error('No influencer');

		return store.processInfluencerFlow(query.iid, campaignId);
	};

	/**
	 * mutate influencer data with profile data
	 */
	const { mutate: updateInfluencer } = useMutation(updateInfluencerMutation, {
		onSuccess: (data, variables) => {
			queryClient.invalidateQueries(queryKeys.list._def);
			queryClient.setQueryData(
				queryKeys.getInfluencer({ influencerId: query.iid, campaignId }),
				data
			);
		},
	});

	/**
	 * mutate influencer data with consent data
	 */
	const { mutate: updateConsent } = useMutation(updateConsentMutation, {
		onSuccess: (data) => {
			queryClient.invalidateQueries(queryKeys.list._def);
			queryClient.setQueryData(
				queryKeys.getInfluencer({ influencerId: query.iid, campaignId }),
				data
			);
		},
	});

	/**
	 * mutate influencer with anonymized data
	 */
	const { mutate: anonymize } = useMutation(anonymizeInfluencerMutation, {
		onSuccess: (data) => {
			queryClient.invalidateQueries(queryKeys.list._def);
			queryClient.setQueryData(
				queryKeys.getInfluencer({ influencerId: query.iid, campaignId }),
				data
			);
			history.replace(
				`${pathname}?${addParam({ key: 'mode', value: 'overview' })}`
			);
		},
	});

	/**
	 * mutate influenced conversions
	 */
	const { mutateAsync: cancelConversions } = useMutation(
		cancelConversionMutation,
		{
			onSuccess: (data) => {},
		}
	);

	/**
	 * mutate the influencer with reward processing data
	 */
	const { mutate: processInfluencer, isLoading } = useMutation(
		processInfluencerMutation,
		{
			onSuccess: () => {
				queryClient.invalidateQueries(queryKeys.list._def);
				queryClient.invalidateQueries(queryKeys.getInfluencer._def);
				queryClient.invalidateQueries(rewardsQueryKeys.list._def);
			},
		}
	);

	/**
	 * called when canceling conversions
	 */
	const onConversionCancel = useCallback(
		async (selected) => {
			try {
				if (!query.iid || !focusedInfluencer || !selected?.size)
					throw new Error('Missing arguments');

				/**
				 * loop through and mutate
				 */
				for (const conversionId of selected.values()) {
					await cancelConversions({
						conversionId,
					});
				}

				/**
				 * Invalidate queries on success
				 */
				await queryClient.invalidateQueries(
					queryKeys.listConversions({ influencerId: query.iid, campaignId })
				);

				/**
				 * not going to await these since the updated information is not needed immediately
				 */
				queryClient.invalidateQueries(
					queryKeys.getInfluencer({ influencerId: query.iid, campaignId })
				);
				queryClient.invalidateQueries(queryKeys.list._def);
			} catch (e) {
				console.error(e);
			}
		},
		[query.iid, campaignId, focusedInfluencer]
	);

	// handle the modal
	useEffect(() => {
		if (query.iid) {
			setModalOpen(true);
		} else {
			setModalOpen(false);
		}
	}, [query.iid]);

	// find the campaign
	useEffect(() => {
		getCampaign();
	}, [campaignId]);

	/**
	 * below are the routes for the different "views" of the modal.
	 * the links are for routing to the view
	 * the config determines if the component should show itself based on the query params
	 * (react router does not route based on query params out of the box, which is why we do it this way.
	 * history is still preserved though, so back buttons and history.back() should work as expected)
	 */

	const profileRoute = useMemo(
		() => ({
			link: `${pathname}?${addParam({
				key: 'mode',
				value: 'profile',
			})}`,
			config: {
				match: getParam('mode') === 'profile',
				component: () => (
					<ProfileEdit
						name={focusedInfluencer?.name}
						email={focusedInfluencer?.email}
						phone={focusedInfluencer?.mobileNumber}
						handleSubmit={updateInfluencer}
						onRemove={anonymize}
						backLink={`${pathname}?${addParam({
							key: 'mode',
							value: 'overview',
						})}`}
					/>
				),
			},
		}),
		[query.mode, query.iid, focusedInfluencer]
	);

	const consentRoute = useMemo(
		() => ({
			link: `${pathname}?${addParam({
				key: 'mode',
				value: 'consent',
			})}`,
			config: {
				match: getParam('mode') === 'consent',
				component: () => (
					<MarketingConsentEdit
						emailConsent={focusedInfluencer?.emailConsent}
						smsConsent={focusedInfluencer?.smsConsent}
						handleSubmit={updateConsent}
						backLink={`${pathname}?${addParam({
							key: 'mode',
							value: 'overview',
						})}`}
					/>
				),
			},
		}),
		[query.mode, query.iid, focusedInfluencer]
	);

	const conversionsRoute = useMemo(
		() => ({
			link: `${pathname}?${addParam({
				key: 'mode',
				value: 'conversions',
			})}`,
			config: {
				match: getParam('mode') === 'conversions',
				component: () => (
					<ConversionsCancel
						influencer={focusedInfluencer}
						campaignId={campaignId}
						onConversionCancel={onConversionCancel}
						backLink={`${pathname}?${addParam({
							key: 'mode',
							value: 'overview',
						})}`}
					/>
				),
			},
		}),
		[query.mode, query.iid, focusedInfluencer]
	);

	const rewardsIssuerRoute = useMemo(
		() => ({
			link: `${pathname}?${addParam({
				key: 'mode',
				value: 'reward',
			})}`,
			config: {
				match: getParam('mode') === 'reward',
				component: observer(() => (
					<RewardsIssuer
						influencer={focusedInfluencer}
						campaign={focusedCampaign}
						handleSubmit={processInfluencer}
						isLoading={isLoading}
						backLink={`${pathname}?${addParam({
							key: 'mode',
							value: 'overview',
						})}`}
					/>
				)),
			},
		}),
		[
			query.mode,
			query.iid,
			focusedCampaign,
			focusedInfluencer,
			store.getErrors('processingInfluencer'),
		]
	);

	const overviewRoute = useMemo(
		() => ({
			link: `${pathname}?${addParam({
				key: 'mode',
				value: 'overview',
			})}`,
			config: {
				match: getParam('mode') === 'overview',
				component: () => (
					<Overview
						influencer={focusedInfluencer}
						campaign={focusedCampaign}
						userRole={store.userRole}
						conversionsLink={conversionsRoute.link}
						rewardLink={rewardsIssuerRoute.link}
					/>
				),
			},
		}),
		[query.mode, query.iid, focusedInfluencer]
	);

	const queryRoutes = useMemo(
		() => [
			overviewRoute.config,
			profileRoute.config,
			consentRoute.config,
			conversionsRoute.config,
			rewardsIssuerRoute.config,
		],
		[query.mode, focusedInfluencer, focusedCampaign, store.userRole]
	);

	return (
		<BaseModal isOpen={modalOpen} elMaxWidth='900px'>
			<Panel
				backgroundColor='ambientLight'
				flexDirection='row'
				overflow='hidden'
				elHeight={['100%', '85vh']}
			>
				<Panel.Body
					display='flex'
					flexDirection='column'
					padding='none'
					flexGrow='1'
					elHeight='100%'
					overflow='auto'
					position='relative'
				>
					<Header name={focusedInfluencer?.name} />
					{queryRoutes.map(
						(route, key) => route.match && <route.component key={key} />
					)}
				</Panel.Body>
				<Panel.Body
					role='complementary'
					elWidth='300px'
					backgroundColor='bodyInverse'
					flexGrow='0'
					flexShrink='0'
					elHeight='100%'
					overflow='auto'
					borderLeft='1px solid'
					borderColor='ambientLight'
				>
					<ProfileSummary
						editLink={profileRoute.link}
						email={focusedInfluencer?.email}
						phone={focusedInfluencer?.mobileNumber}
					/>
					<MarketingConsentSummary
						editLink={consentRoute.link}
						emailConsent={focusedInfluencer?.emailConsent}
						smsConsent={focusedInfluencer?.smsConsent}
					/>
					<InfluencerLink link={focusedInfluencer?.inviteLink} />
					<MetaData
						createdAt={focusedInfluencer?.createdAt}
						influencerId={focusedInfluencer?.id}
						orderId={focusedInfluencer?.orderId}
						transactionId={focusedInfluencer?.transactionId}
					/>
					<ParentInfluencer
						parentInfluencerId={focusedInfluencer?.parentInfluencerId}
					/>
				</Panel.Body>
			</Panel>
		</BaseModal>
	);
};
InfluencerManager.displayName = 'InfluencerManager';

export default observer(InfluencerManager);
