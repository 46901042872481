import React, { useEffect, useState, useCallback } from 'react';

import { observer } from 'mobx-react-lite';
import {
	Link,
	Switch,
	Redirect,
	Route,
	useRouteMatch,
	useLocation,
	useHistory,
} from 'react-router-dom';
import { Stepper, Step, StepLabel } from '@material-ui/core';
import {
	Container,
	Div,
	Spanner,
	Typography,
} from '@icecreamsocial/components';
import {
	ArrowBackIcon,
	RouteChangeBlocker,
	PageMeta,
} from '../../presentational';
import { HeaderPortal } from '../Main';
import Brand from './sub-routes/Brand';
import BusinessProfile from './sub-routes/BusinessProfile';
import ECommerce from './sub-routes/ECommerce';
import PaymentProcessor from './sub-routes/PaymentProcessor';
import Policies from './sub-routes/Policies';
import { useStore, useRouteConfig } from '../../../utils/hooks';

const BusinessSetUp = () => {
	const store = useStore();
	const { path } = useRouteMatch();
	const history = useHistory();
	const { pathname } = useLocation();
	const [navigateMessage, setNavigateMessage] = useState({
		title: '',
		message: '',
		confirmText: '',
		cancelText: '',
	});

	/**
	 * set the layout
	 */
	useEffect(() => {
		store.Orchestrators.View.setSingleColumn();
		return () => {
			store.Orchestrators.View.setSingleColumn(false);
		};
	}, []);

	/**
	 * update the route blocker
	 */
	useEffect(() => {
		store.Orchestrators.View.setBlockRouteChange(
			!store.isUserBusinessSetupComplete
		);
	}, [pathname, store.isUserBusinessSetupComplete]);

	const resetNavigateMessage = () => {
		const title = 'Exit Business Setup?';
		const message = "Your progress won't be saved";
		const confirmText = 'Discard Changes & Exit';
		const cancelText = 'Continue Setup';

		setNavigateMessage({ title, message, confirmText, cancelText });
	};

	/**
	 * update the route message whenever the pathname changes
	 */
	useEffect(() => {
		resetNavigateMessage();
	}, [pathname]);

	const activeStep = determineStepFromPath(pathname);

	/**
	 * @todo we actually save via api on each step
	 * @param {*} values
	 * @param {*} nextRoute
	 */
	const handlePartialFormSubmit = async (values, nextRoute) => {
		await store.updateCompanyFlow(values);
		store.Orchestrators.View.setBlockRouteChange(false);
		history.push(nextRoute);
	};

	const onComplete = () => {
		store.Orchestrators.View.setBlockRouteChange(false);
		history.push('/settings');
	};

	const createBackHandler = (returnTo) => () => {
		const title = 'Go to Previous Step?';
		const message = "Your progress on the current step won't be saved";
		const confirmText = 'Discard Changes & Go Back a Step';
		const cancelText = 'Continue Current Step';
		setNavigateMessage({ title, message, confirmText, cancelText });
		history.push(returnTo);
	};

	const beforeRedirect = () => {
		store.Orchestrators.View.setBlockRouteChange(false);
	};

	const { routes, links } = useRouteConfig([
		{
			component: () => <Redirect to={`${path}/profile`} />,
		},
		{
			path: `profile`,
			component: () => (
				<BusinessProfile
					handleSubmit={(values) =>
						handlePartialFormSubmit(values, `${path}/ecommerce`)
					}
					returnTo={() => history.push('/settings')}
				/>
			),
			name: 'profile',
			linkText: 'Business Profile',
		},
		{
			path: `ecommerce`,
			component: () => (
				<ECommerce
					handleSubmit={(values) =>
						handlePartialFormSubmit(values, `${path}/brand`)
					}
					returnTo={createBackHandler(`${path}/profile`)}
					savedValues={store.userBusiness}
				/>
			),
			name: 'ecomm',
			linkText: 'eCommerce',
		},
		{
			path: `brand`,
			component: () => (
				<Brand
					handleSubmit={(values) =>
						handlePartialFormSubmit(values, `${path}/data-and-policy`)
					}
					returnTo={createBackHandler(`${path}/ecommerce`)}
					savedValues={{ logo: store.userBusiness?.logo }}
				/>
			),
			name: 'brand',
			linkText: 'Brand & Appearance',
		},
		{
			path: `data-and-policy`,
			component: () => (
				<Policies
					handleSubmit={(values) =>
						handlePartialFormSubmit(values, `${path}/reward-processor`)
					}
					returnTo={createBackHandler(`${path}/brand`)}
					savedValues={store.userBusiness}
				/>
			),
			name: 'dataAndPolicy',
			linkText: 'Data & Policy',
		},
		{
			path: `reward-processor`,
			component: () => (
				<PaymentProcessor
					onComplete={onComplete}
					beforeRedirect={beforeRedirect}
					returnTo={createBackHandler(`/settings/business-profile`)}
				/>
			),
			name: 'payment',
			linkText: 'Reward Processing',
		},
	]);

	return (
		<>
			<PageMeta
				title={'Business Setup | Ice Cream Social'}
				description={'Set up your Ice Cream Social business'}
			/>
			<HeaderPortal>
				<Spanner
					grid
					gridColumns='repeat(3, 1fr)'
					backgroundColor='brandDark'
					textColor='bodyInverse'
				>
					<Typography.Span
						as={Link}
						to='/settings'
						textColor='bodyInverse'
						display='flex'
						alignItems='center'
					>
						<ArrowBackIcon marginRight='md' /> Finish later
					</Typography.Span>
					<Typography.Span textAlign='center'>
						Business Settings
					</Typography.Span>
				</Spanner>
			</HeaderPortal>
			<Container>
				<Div
					as={Stepper}
					activeStep={activeStep}
					backgroundColor='transparent !important'
				>
					{links.slice(1).map(({ text }, i) => (
						<Step key={i}>
							<StepLabel>{text}</StepLabel>
						</Step>
					))}
				</Div>
				<Switch>
					{routes.map(({ link, ...route }, i) => (
						<Route key={i} {...route} />
					))}
				</Switch>
			</Container>
			<RouteChangeBlocker
				when={store.Orchestrators.View.blockRouteChange}
				title={navigateMessage.title}
				message={navigateMessage.message}
				confirmText={navigateMessage.confirmText}
				cancelText={navigateMessage.cancelText}
				beforeCancelNavigation={resetNavigateMessage}
			/>
		</>
	);
};
BusinessSetUp.displayName = 'BusinessSetUp';

function determineStepFromPath(path) {
	const steps = {
		profile: 0,
		ecommerce: 1,
		brand: 2,
		'data-and-policy': 3,
		'reward-processor': 4,
	};
	const key = path.split('/business-setup/')[1];

	return steps[key];
}
export default observer(BusinessSetUp);
