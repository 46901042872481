"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ic_remove = void 0;
var ic_remove = {
  "viewBox": "0 0 24 24",
  "children": [{
    "name": "path",
    "attribs": {
      "d": "M19 13H5v-2h14v2z"
    }
  }]
};
exports.ic_remove = ic_remove;