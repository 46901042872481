import { types } from 'mobx-state-tree';
import { IcsConversion } from '@icecreamsocial/mst-models';
import { Pagination } from '../../models';

const ConversionsCollection = types
  .compose(
    Pagination,
    types.model({
      conversionMap: types.map(IcsConversion),
      list: types.array(types.reference(IcsConversion)),
    })
  )
  .named('ConversionsCollection')
  .preProcessSnapshot((snapshot = {}) => ({
    conversionMap: snapshot.conversionMap || {},
    list: snapshot.list || [],
  }))
  .views((self) => ({
    get hasConversions() {
      return !!self.list.length;
    },
    find(id) {
      return self.list.find((ref) => ref.id === id);
    },
  }))
  .actions((self) => ({
    addConversion(conversion) {
      self.conversionMap.put(conversion);
    },
    addConversions(conversions = []) {
      conversions.forEach((conversion) => self.addConversion(conversion));
    },
    deleteConversion(id) {
      self.list.remove(id);
      self.conversionMap.delete(id);
    },
    updateConversion(id, conversion) {
      self.conversionMap.set(id, conversion);
    },
    setConversions(conversions = []) {
      try {
        self.list.replace([]); // clean up
        conversions.forEach((conversion) => {
          self.addConversion(conversion);
          self.list.push(conversion.id);
        });
      } catch (e) {
        console.error(e);
      }
    },
    setConversionList(conversionRefs = []) {
      self.list.replace(conversionRefs);
    },
    clearAll() {
      self.setConversionList();
      self.conversionMap.clear();
    },
  }));

export default ConversionsCollection;
