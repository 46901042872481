import React from 'react';
import { observer } from 'mobx-react-lite';
import DataTable from 'react-data-table-component';
import { Panel } from '@icecreamsocial/components';
/**
 * @module StandalonePagination
 * @category Components
 * @subcategory Presentational
 */

/**
 * @description
 * Borrows the pagination from `react-data-table-component` for UI consistency and convenience
 * @todo
 * document this
 */
const StandalonePagination = ({ columns = [], collection = [], ...rest }) =>
	collection && (
		<Panel marginBottom="xlg" style={{ overflow: 'hidden' }}>
			<DataTable
				columns={[{ name: 'blank', cell: () => null }]}
				data={collection}
				style={{ display: 'none', borderBottom: 'none' }}
				customStyles={{
					pagination: {
						style: {
							borderTopWidth: '0',
						},
					},
				}}
				highlightOnHover
				pagination
				noHeader
				noTableHead
				{...rest}
			/>
		</Panel>
	);
StandalonePagination.displayName = 'StandalonePagination';

export default observer(StandalonePagination);
