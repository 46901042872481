import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../../../utils/hooks';
import { ClientsTable, Pagename } from '../../../../presentational';

const Clients = () => {
	const store = useStore();
	const { affiliateId } = useParams();
	useEffect(() => {
		store.getClientsFlow({ affiliateId });
	}, [affiliateId]);

	return (
		<>
			<Pagename>Clients</Pagename>
			{store.Collections.Clients.hasClients && (
				<ClientsTable collection={store.Collections.Clients.list.toJS()} />
			)}
		</>
	);
};

export default observer(Clients);
