import { createQueryKeys } from '@lukemorales/query-key-factory';

/**
 * reuable query keys for Converions for use with useQuery or useMutation
 */
export const queryKeys = createQueryKeys('conversions', {
	/**
	 * @param {ConversionQueries.List} params
	 */
	listConversions: (params) => ({ ...params }),
	listInfluencedConversions: (params) => ({ ...params }),
});
