import React from 'react';
import { observer } from 'mobx-react-lite';
import { Div, Input, Typography } from '@icecreamsocial/components';
import {
  ArrowBackIcon,
  BackButton,
  CloseButton,
  ShareButton,
} from '../../../presentational';
import { useCopyRef, useQueryString, useStore } from '../../../../utils/hooks';
import { Link, useHistory, useLocation } from 'react-router-dom';

const InfluencerManagerHeader = ({ name }) => {
  const store = useStore();
  const history = useHistory();
  const { pathname, search } = useLocation();
  const { query, addParam, withoutParams } = useQueryString();
  const { ref, copy } = useCopyRef(() => {
    store.Orchestrators.View.addNotification({
      text: 'Link copied to clipboard!',
      type: 'emphasis',
    });
  });

  /**
   * @todo make the routing for this a little more centralized. this files assumes you know all the paths
   * of the influencer manager
   */
  const { mode } = query;
  const url = `${window.location.origin}${pathname}${search}`;
  const overview = `${pathname}?${addParam({
    key: 'mode',
    value: 'overview',
  })}`;

  const onClose = () => {
    history.push(`${pathname}?${withoutParams('iid', 'mode')}`);
  };

  return (
    <Div
      grid
      gridColumns='1fr 3fr 1fr'
      padding='md'
      alignItems='center'
      justifyContent='space-between'
      backgroundColor='ambientLight'
      position='sticky'
      positionTop='0'
      marginBottom='xlg'
    >
      {mode === 'overview' ? (
        <CloseButton onClick={onClose} />
      ) : (
        <Typography.A
          as={Link}
          to={overview}
          display='flex'
          alignItems='center'
          textColor='body'
          visitedTextColor='body'
          textDecoration='none'
          style={{ gap: '16px' }}
        >
          <Typography.Span display='flex' alignItems='center'>
            <ArrowBackIcon />
          </Typography.Span>
          <Typography.Span>Overview</Typography.Span>
        </Typography.A>
      )}
      <Typography.H1
        margin='0'
        typoWeight='bold'
        typoSize='lg'
        textAlign='center'
      >
        {name}
      </Typography.H1>
      <ShareButton onClick={copy} style={{ justifySelf: 'end' }} />
      {/* this is a hidden input to copy the URL */}
      <Input
        ref={ref}
        value={url}
        position='fixed'
        style={{
          opacity: 0,
          transform: 'translateY(-100%)',
          pointerEvents: 'none',
        }}
        zIndex={-1}
        tabIndex={-1}
        readOnly
      />
    </Div>
  );
};
InfluencerManagerHeader.displayName = 'InfluencerManager.Header';

export default observer(InfluencerManagerHeader);
