import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory, Link } from 'react-router-dom';
import {
	Button,
	Div,
	Panel,
	Spanner,
	Typography,
} from '@icecreamsocial/components';
import { useStore } from '../../../../../utils/hooks';
import { HeaderPortal } from '../../../Main';
import {
	AffiliateSettingsForm,
	EditFormControls,
} from '../../../../presentational';
import { DestructiveUserActions } from '../../../../containers';

const AffiliateSettings = (props) => {
	const store = useStore();
	const history = useHistory();
	const DEACTIVATE_FEATURE = false;

	const focusedAffiliate = store.Orchestrators.Focused.affiliate || {};

	const initialValues = {
		name: focusedAffiliate.name,
		parentId: focusedAffiliate.parentId,
	};

	const [allowNavigation, setAllowNavigation] = useState(false);

	// Focus current authenticated user so that their parent affiliate will be used as defaults
	const userId = store.Orchestrators.Auth.user.id;

	useEffect(() => {
		store.resolveUserFlow(userId);

		return () => store.resolveUserFlowCleanUp();
	}, [store.Orchestrators.Auth.user.id]);

	const handleSubmit = async (values) => {
		await store.updateAffiliateSettingsFlow(values);
		if (props.nextPage) {
			setAllowNavigation(true);
			history.push(props.nextPage);
		}
	};

	return (
		<>
			<Div marginTop="lg">
				<AffiliateSettingsForm
					initialValues={initialValues}
					userAffiliateId={store.userParentAffiliateId}
					onSubmit={handleSubmit}
					renderControls={(props) => (
						<EditFormControls
							isLoading={store.isLoading('affiliateSettings')}
							disabled={store.isLoading('affiliateSettings')}
							{...props}
						/>
					)}
					isEditing
				/>
				{DEACTIVATE_FEATURE && (
					<>
						<Div
							as="hr"
							borderColor="ambient"
							borderTop="none"
							marginBottom="lg"
						/>
						<DestructiveUserActions
							userId={focusedAffiliate?.id}
							userName={focusedAffiliate?.name}
							currentStatus={focusedAffiliate?.status}
							isArchived={focusedAffiliate?.isArchived}
						/>
					</>
				)}
			</Div>
		</>
	);
};

AffiliateSettings.displayName = 'Affiliates.Settings';

export default observer(AffiliateSettings);
