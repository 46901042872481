import React from 'react';
import { Tooltip, withStyles } from '@material-ui/core';
import { Div } from '@icecreamsocial/components';
import { FaInfoCircle } from 'react-icons/fa';

const TempToolStyled = withStyles(() => ({
	arrow: {
		color: '#222',
	},
	tooltip: {
		lineHeight: '1.6em',
		backgroundColor: '#222',
		color: '#fff',
		fontWeight: '500',
		fontSize: '0.8rem',
		wordWrap: 'break-word',
		borderRadius: '16px',
		textAligin: 'center',
		padding: '8px 12px',
	},
}))(Tooltip);

const TooltipInfo = ({ text, ...rest }) => {
	return (
		<Div display='flex'>
			{text && (
				<TempToolStyled
					arialLabel='info'
					title={text}
					placement='top'
					{...rest}
					arrow
				>
					<Div>
						<FaInfoCircle
							style={{
								color: '#4444',
								backgroundColor: '#fff',
								borderRadius: '50%',
							}}
						/>
					</Div>
				</TempToolStyled>
			)}
		</Div>
	);
};

export default TooltipInfo;
