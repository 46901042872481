import React from 'react';
import { observer } from 'mobx-react-lite';
import { Div, Img, Spanner, Typography } from '@icecreamsocial/components';
import NotFoundImg from '../../../assets/scoops-404.svg';
import { CompanyLogoLink } from '../../containers';
import { PageMeta } from '../../presentational';
import { HeaderPortal } from '../Main';

const NotFound = () => {
	return (
		<>
			<PageMeta
				title="Not Found | Ice Cream Social"
				description="Log into your admin account"
			/>
			<HeaderPortal>
				<Div backgroundColor="#EBEBEB" textAlign="center" padding="md">
					<Spanner grid gridColumns="repeat(3, 1fr)" padding="0">
						<Div display="flex" alignItems="center">
							<CompanyLogoLink />
						</Div>
						<Div display="flex" alignItems="center">
							<Div elWidth="100%" textAlign="center">
								<Typography.Span typoWeight="bold">
									Ice Cream Social
								</Typography.Span>
							</Div>
						</Div>
					</Spanner>
				</Div>
			</HeaderPortal>
			<Div
				elWidth={['100%', '66%', '50%']}
				margin="auto"
				marginTop="xlg"
				marginBottom="xlg"
				padding="lg"
			>
				<Img src={NotFoundImg} />
				<Div textAlign="center">
					<Typography.H3>Something is amiss</Typography.H3>
					<Typography.Span>
						This page doesn't appear to exist or there was an error
					</Typography.Span>
				</Div>
			</Div>
		</>
	);
};

NotFound.displayName = 'NotFound';

export default observer(NotFound);
