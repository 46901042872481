import { Div, Panel, Typography } from '@icecreamsocial/components';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useStore } from '../../../../../../../../../utils/hooks';
import { CodeSnippet } from '../../../../../../../../presentational';
import { useScriptSettings } from '../../providers';
import { makeScript } from './utils/makeScript';
import { useCampaign } from '../../../../providers/Campaign';

export const ScriptPreview = observer(() => {
	const store = useStore();
	const { settings } = useScriptSettings();
	const [landingCode, setLandingCode] = useState('');
	const [conversionCode, setConversionCode] = useState('');
	const { campaign } = useCampaign();
	const { client } = store.Orchestrators.Focused;
	const { data } = campaign || {};
	const campaignId = data?.id;

	useEffect(() => {
		setLandingCode(
			makeScript({
				requiresData: false,
				version: settings.version,
				publicKey: client?.publicKey,
				locale: settings.locale,
				theme: settings.theme,
				behavior: settings.behavior,
				platform: settings.platform,
				engagementType: settings.engagementType,
				campaignId,
			})
		);

		setConversionCode(
			makeScript({
				requiresData: true,
				version: settings.version,
				publicKey: client?.publicKey,
				locale: settings.locale,
				theme: settings.theme,
				behavior: settings.behavior,
				platform: settings.platform,
				engagementType: settings.engagementType,
				campaignId,
			})
		);
	}, [client, campaignId, settings]);

	return (
		<Div>
			<Div marginBottom='lg' marginTop='lg'>
				<Typography.H2 marginTop='none'>
					Step 1: Include on Landing Pages
				</Typography.H2>
				<Typography.P>
					This script should be placed throughout your site so invites can be
					tracked even when the user is transitioning from page to page.
					Configure the script accordingly if you want your offer to be shown to
					a prospective influencer at this point, or have it be invisible until
					conversion pages.
				</Typography.P>
				<CodeSnippet code={landingCode} publicKey={client?.publicKey} />
			</Div>
			<Div marginBottom='lg' marginTop='lg'>
				<Typography.H2>Step 2: Include on Conversion Pages</Typography.H2>
				<Typography.P>
					Place this script on pages or within elements where you plan on
					tracking {settings.engagementType || 'transaction'} conversions, like
					the order confirmation page of a checkout, a sign up page, etc.
				</Typography.P>
				<CodeSnippet code={conversionCode} publicKey={client?.publicKey} />
			</Div>
		</Div>
	);
});
