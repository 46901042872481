import React from 'react';
import { Div } from '@icecreamsocial/components';

const OptimisticText = ({ ...rest }) => (
	<Div optimistic borderRadius="default" elWidth="80px" {...rest}>
		&nbsp;
	</Div>
);
OptimisticText.displayName = 'OptimisticText';
export default OptimisticText;
