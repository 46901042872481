import React, { useMemo } from "react";
import { observer } from "mobx-react-lite";
import {
  Button,
  Div,
  InputGroup,
  Panel,
  Typography,
  Spanner,
} from "@icecreamsocial/components";
import { useFormik } from "formik";
import {
  RequiredAsterisk,
  EnhancedForm,
  SupportedLanguageSelect,
} from "../../../../presentational";

const GeneralSettings = ({
  campaignName = "",
  locale = "en-US",
  fromEmail = "noreply@icecreamsocial.io",
  onSubmit = () => undefined,
  allowRouteBlock = true,
  renderControls = null,
  fromName,
}) => {
  const formikProps = {
    enableReinitialize: true,
    initialValues: {
      campaignName,
      locale,
      fromEmail,
      fromName,
    },
    onSubmit,
  };

  return (
    <EnhancedForm
      formikProps={formikProps}
      promptIf={({ dirty }) => allowRouteBlock && dirty}
      renderControls={renderControls}
    >
      {(generalSettings) => (
        <>
          <Panel backgroundColor="bodyInverse" marginBottom="lg">
            <Panel.Header backgroundColor="bodyInverse">
              <Typography.H2
                textColor="body"
                textAlign="center"
                margin="none"
                typoSize="md"
              >
                Basic Campaign Details
              </Typography.H2>
            </Panel.Header>
            <Panel.Body>
              <InputGroup elWidth="100%" marginBottom="sm">
                <InputGroup.Label>
                  Campaign Name <RequiredAsterisk />
                </InputGroup.Label>
                <InputGroup.Fields>
                  <InputGroup.Input
                    id="campaignName"
                    name="campaignName"
                    paddingLeft="md"
                    onChange={generalSettings.handleChange}
                    value={generalSettings.values.campaignName}
                    required
                  />
                </InputGroup.Fields>
                <Typography.Span as="small">
                  Influencers will see this, make sure to pick an engaging
                  campaign name
                </Typography.Span>
              </InputGroup>
              <InputGroup elWidth="100%" marginBottom="sm">
                <InputGroup.Label>
                  Language <RequiredAsterisk />
                </InputGroup.Label>
                <SupportedLanguageSelect
                  id="locale"
                  name="locale"
                  onChange={generalSettings.handleChange}
                  value={generalSettings.values.locale}
                />
              </InputGroup>
            </Panel.Body>
          </Panel>
          <Panel backgroundColor="bodyInverse" marginBottom="lg">
            <Panel.Header backgroundColor="bodyInverse" textColor="body">
              <Typography.H2
                textColor="body"
                textAlign="center"
                margin="none"
                typoSize="md"
              >
                Email
              </Typography.H2>
              <Typography.P marginBottom="0">
                Some emails, like the offer reminder, will be sent on behalf of
                your company to increase the chances a customer will open it
              </Typography.P>
            </Panel.Header>
            <Panel.Body>
              <InputGroup elWidth="100%" marginBottom="sm">
                <InputGroup.Label>
                  Sender's Email Address <RequiredAsterisk />
                </InputGroup.Label>
                <InputGroup.Fields>
                  <InputGroup.Input
                    id="fromEmail"
                    name="fromEmail"
                    type="email"
                    paddingLeft="md"
                    onChange={generalSettings.handleChange}
                    value={generalSettings.values.fromEmail}
                    required
                  />
                </InputGroup.Fields>
                <Typography.Span as="small">
                  Typically an info@yourdomainname.com works best
                </Typography.Span>
              </InputGroup>

              <InputGroup elWidth="100%" marginBottom="sm">
                <InputGroup.Label>Sender's Name</InputGroup.Label>
                <InputGroup.Fields>
                  <InputGroup.Input
                    id="fromName"
                    name="fromName"
                    type="text"
                    paddingLeft="md"
                    onChange={generalSettings.handleChange}
                    value={generalSettings.values.fromName}
                  />
                </InputGroup.Fields>
              </InputGroup>
            </Panel.Body>
          </Panel>
        </>
      )}
    </EnhancedForm>
  );
};
GeneralSettings.displayName = "GeneralCampaignSettings";

export default observer(GeneralSettings);
