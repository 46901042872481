import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Div, InputGroup } from '@icecreamsocial/components';
import { SwitchField } from '../';

const MinMaxToggle = ({
	minValue,
	maxValue,
	show = false,
	name,
	label,
	handleChange,
}) => {
	return (
		<SwitchField
			name={name}
			label={label}
			handleToggle={handleChange}
			show={show}
		>
			<Div marginLeft="lg" marginBottom="md">
				<InputGroup marginBottom="sm" elWidth="100%">
					<InputGroup.Label display="inline-block" typoSize="sm">
						Greater Than or Equal To
					</InputGroup.Label>
					<InputGroup.Fields>
						<InputGroup.Icon typoSize="sm" typoWeight="bold">
							MIN
						</InputGroup.Icon>
						<InputGroup.Input
							type="number"
							min="0"
							max={maxValue || Infinity}
							name={`${name}GTOE`}
							value={minValue}
							onChange={handleChange}
						/>
					</InputGroup.Fields>
				</InputGroup>
				<InputGroup elWidth="100%">
					<InputGroup.Label display="inline-block" typoSize="sm">
						Less Than or Equal To
					</InputGroup.Label>
					<InputGroup.Fields>
						<InputGroup.Icon typoSize="sm" typoWeight="bold">
							MAX
						</InputGroup.Icon>
						<InputGroup.Input
							type="number"
							min={minValue || 0}
							name={`${name}LTOE`}
							value={maxValue}
							onChange={handleChange}
						/>
					</InputGroup.Fields>
				</InputGroup>
			</Div>
		</SwitchField>
	);
};

MinMaxToggle.displayName = 'MinMaxToggle';
export default observer(MinMaxToggle);
