import React from 'react';
import {
	Div,
	Button,
	MediaCard,
	List,
	Typography,
	Panel,
} from '@icecreamsocial/components';
import { observer } from 'mobx-react-lite';
import OptimisticText from '../Text';

const OptimisticPaymentProcessor = () => (
	<Panel
		backgroundColor="bodyInverse"
		marginBottom="lg"
		shadow="none"
		style={{ overflow: 'hidden' }}
	>
		<Panel.Header
			display="flex"
			alignItems="center"
			textColor="body"
			backgroundColor="bodyInverse"
		>
			<Div
				optimistic
				borderRadius="default"
				marginRight="md"
				elHeight="60px"
				elWidth="60px"
			/>
			<Div flexGrow="1">
				<OptimisticText elWidth="15%" marginBottom="md" />
				<OptimisticText elWidth="50%" />
			</Div>
			{/* <Div optimistic elWidth="20%" elHeight="40px" borderRadius="default" /> */}
		</Panel.Header>
	</Panel>
);

OptimisticPaymentProcessor.displayName = 'OptimisticPaymentProcessor';
export default observer(OptimisticPaymentProcessor);
