import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Container } from '@icecreamsocial/components';
import { useRouteConfig } from '../../../utils/hooks';
import List from './sub-routes/List';
import Affiliate from './sub-routes/Affiliate';
import CreateAffiliate from './sub-routes/Create';
import { PageMeta } from '../../presentational';

const Affiliates = () => {
	const { path } = useRouteMatch();
	const CreateAffiliateComponent = () => <CreateAffiliate nextPage={path} />;
	const routes = [
		{
			path,
			component: List,
			exact: true,
		},
		{
			path: `${path}/create`,
			component: CreateAffiliateComponent,
			exact: false,
		},
		{
			path: `${path}/:affiliateId`,
			component: Affiliate,
			exact: false,
		},
	];

	return (
		<>
			<PageMeta
				title={'Affiliates | Ice Cream Social'}
				description={'Manage your affiliates'}
			/>
			<Container>
				<Switch>
					{routes.map((route, i) => (
						<Route key={i} {...route} />
					))}
				</Switch>
			</Container>
		</>
	);
};

export default observer(Affiliates);
