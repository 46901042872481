import React from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Div, Typography } from '@icecreamsocial/components';
import { useStore } from '../../../../../../../../../../../utils/hooks';
import { queryKeys } from '../../../../../../../../../../../queries/conversions';
import {
	QuerySearchBar,
	Pagename,
	PopsicleLoader,
} from '../../../../../../../../../../presentational';

import { Table } from '../Table';
import { ExportButton } from '../ExportButton';
import { useReferralFilters } from '../FilterProvider';
import { useCampaign } from '../../../../../../providers/Campaign';

/**
 * Fetches and Displays data based on filters
 */
const DataView = () => {
	const { getCampaignConversions } = useStore();
	const { campaignId } = useParams();
	const { campaign } = useCampaign();
	const { state } = useReferralFilters();

	const { data } = campaign;
	const { eligibility, ...query } = state;
	const goalReached = eligibility === 'eligible';

	/**
	 * update the influencer list on relevant query state changes or when data is stale
	 */
	const { data: referrals, isLoading, isRefetching, error } = useQuery(
		queryKeys.listConversions({
			campaignId,
			goalReached,
			...query,
		}),
		() =>
			getCampaignConversions({
				conversionType: 'Transaction',
				campaignId,
				goalReached,
				...query,
			}),
		{ enabled: !!campaignId, refetchOnWindowFocus: false }
	);

	return (
		<>
			<Div display='flex' alignItems='center' justifyContent='space-between'>
				<Pagename>Influencer Performance</Pagename>
				<ExportButton disable={!referrals?.conversions.count} />
			</Div>
			<Div>
				<QuerySearchBar
					id='influencerSearch'
					name='search'
					placeholder='Find Influencers'
				/>
				{data ? (
					<Table
						referrals={referrals?.conversions}
						isLoading={isLoading || isRefetching}
						error={error}
					/>
				) : (
					<Div
						display='flex'
						padding='xlg'
						elMinHeight='300px'
						alignItems='center'
						justifyContent='center'
						flexDirection='column'
						backgroundColor='bodyInverse'
						borderRadius='default'
						borderColor='ambient'
						border='1px solid'
					>
						<PopsicleLoader />
						<Typography.P margin='none' textColor='ambientDark'>
							Just a moment...
						</Typography.P>
					</Div>
				)}
			</Div>
		</>
	);
};

DataView.displayName =
	'Campaigns.Campaign.Reports.InfluencerPerformance.DataView';
export default observer(DataView);
