import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { format } from 'date-fns';
import {
	Button,
	Div,
	InputGroup,
	Typography,
} from '@icecreamsocial/components';
import {
	InvitesFilterForm,
	InvitesTable,
	EmphasizedAction,
	DownloadIcon,
	QuerySearchBar,
	Pagename,
} from '../../../../../../../../presentational';
import {
	useStore,
	usePagination,
	useQueryString,
	useDownloadLink,
} from '../../../../../../../../../utils/hooks';
// import { formatISO } from 'date-fns';
import { withLoader } from '../../../../../../../../../utils/HOCs';

/**
 * @deprecated
 * TODO:
 *  only difference between this and old Invites page seem to be label changes
 *  so is this really needed?
 */
const NewInvites = () => {
	const store = useStore();
	const history = useHistory();
	const DownloadButton = withLoader(EmphasizedAction);

	const { query, addParam, withoutParam, contains, qs } = useQueryString();
	const { pathname, search: qParams } = useLocation();

	// const today = Date.now();
	const defaultFilters = {
		// invitationDateGTOE: formatISO(today),
		// invitationDateLTOE: formatISO(today),
		inviteClickCountSwitch: false,
		inviteClickCountGTOE: undefined,
		inviteClickCountLTOE: undefined,
		conversionsSwitch: false,
		conversionsGTOE: undefined,
		conversionsLTOE: undefined,
		revenueSwitch: false,
		revenueGTOE: undefined,
		revenueLTOE: undefined,
		converted: undefined,
	};
	const [filters, setFilters] = useState({
		invitationDateGTOE:
			query.invitationDateGTOE || defaultFilters.invitationDateGTOE,
		invitationDateLTOE:
			query.invitationDateLTOE || defaultFilters.invitationDateLTOE,
		inviteClickCountSwitch:
			contains('inviteClickCountGTOE') || contains('inviteClickCountLTOE'),
		inviteClickCountGTOE: query.inviteClickCountGTOE,
		inviteClickCountLTOE: query.inviteClickCountLTOE,
		conversionsSwitch:
			contains('conversionsGTOE') || contains('conversionsLTOE'),
		conversionsGTOE: query.conversionsGTOE,
		conversionsLTOE: query.conversionsLTOE,
		revenueSwitch: contains('revenueGTOE') || contains('revenueLTOE'),
		revenueGTOE: query.revenueGTOE,
		revenueLTOE: query.revenueLTOE,
		converted: contains('converted'),
	});
	const { campaignId } = useParams();
	const [getPagination, resetPagination] = usePagination({ limit: 10 });
	const download = useDownloadLink();

	const getInvites = ({ campaignId, skip, limit, ...query }) => {
		return store.getInvitesFlow({
			campaignId,
			skip,
			limit,
			...query,
		});
	};

	/**
	 * will get influencers everytime the campaign or query changes
	 */
	useEffect(() => {
		getInvites({ campaignId, ...query });
		return () => {
			store.Collections.Invites.setInviteList([]);
			resetPagination();
		};
	}, [campaignId, qParams]);

	const paginationConfig = {
		paginationServer: true,
		paginationDefaultPage: 1, // make this from a query param in the future
		paginationTotalRows: store.Collections.Invites.total,
		paginationPerPage: 10,
		onChangePage: (page) => {
			const { skip, limit } = getPagination({ page });
			getInvites({
				campaignId,
				skip,
				limit,
				...query,
			});
		},
		onChangeRowsPerPage: (currentRowsPerPage, page) => {
			const { skip, limit } = getPagination({
				page,
				limit: currentRowsPerPage,
			});
			getInvites({
				campaignId,
				skip,
				limit,
				...query,
			});
		},
	};

	const sortConfig = {
		sortServer: true,
		defaultSortField: 'updatedAt',
	};

	/**
	 * for the filters
	 * keeping this super straight forward and dirty.  can do something more elegant later
	 * @refactor
	 */

	// map values from query to form
	const clearForm = () => {
		setFilters(defaultFilters);
	};

	// map values from form to query
	const filterSubmit = ({ fromDate, toDate, ...values }) => {
		const q = { ...values };
		if (contains('search')) {
			q.search = query.search;
		}
		if (fromDate) {
			q.invitationDateGTOE = fromDate;
		}
		if (toDate) {
			q.invitationDateLTOE = toDate;
		}

		// clean the object of params we don't want to include in the url
		delete q.fromDate;
		delete q.toDate;
		if (q.inviteClickCountSwitch === false) {
			delete q.inviteClickCountGTOE;
			delete q.inviteClickCountLTOE;
		}
		if (q.conversionsSwitch === false) {
			delete q.conversionsGTOE;
			delete q.conversionsLTOE;
		}
		if (q.revenueSwitch === false) {
			delete q.revenueGTOE;
			delete q.revenueLTOE;
		}
		if (q.conversions === false) {
			delete q.conversions;
		}
		Object.keys(q).forEach((key) => {
			if (q[key] === undefined) {
				delete q[key];
			}
		});

		// delete all the switches since we don't need them
		delete q.inviteClickCountSwitch;
		delete q.conversionsSwitch;
		delete q.revenueSwitch;
		// now form the url
		const cleaned = qs.stringify(q);
		const url = `${pathname}${q ? `?${cleaned}` : ''}`;
		history.push(url);
	};

	return (
		<Div display='flex'>
			<Div elWidth='100%'>
				<Div display='flex' alignItems='center' justifyContent='space-between'>
					<Pagename>Invites</Pagename>
					<DownloadButton
						onClick={async () => {
							const csv = await store.downloadInvitesCSVFlow(campaignId, {
								...query,
							});
							download(
								`data:text/csv;charset=utf-8,${csv}`,
								`ICSInvites - ${
									(
										store.Collections.Campaigns.campaignMap.get(campaignId) ||
										{}
									).name
								} (${format(Date.now(), 'yyyy-MM-dd')}).csv`
							);
						}}
						isLoading={store.isLoading('invitesCSV')}
						disabled={store.isLoading('invitesCSV')}
						display='flex'
						alignItems='center'
					>
						<DownloadIcon marginRight='sm' /> Export
					</DownloadButton>
				</Div>
				<Div>
					<QuerySearchBar
						id='invitesSearch'
						name='search'
						placeholder='Find Invites'
					/>
					<InvitesTable
						collection={store.Collections.Invites.list.toJS()}
						progressPending={store.isLoading('invites')}
						{...paginationConfig}
						{...sortConfig}
					/>
				</Div>
			</Div>
			<Div as='aside' elWidth='25%' marginLeft='lg' marginBottom='lg'>
				<Div position='sticky' positionTop='0'>
					<InvitesFilterForm
						onSubmit={filterSubmit}
						onReset={clearForm}
						initialValues={filters}
					/>
				</Div>
			</Div>
		</Div>
	);
};

NewInvites.displayName = 'Campaigns.Campaign.Reports.NewInvite';
export default observer(NewInvites);
