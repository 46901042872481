import React, { useEffect, useState, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useFormik } from 'formik';
import {
	Link,
	useHistory,
	useLocation,
	// useParams,
	useRouteMatch,
} from 'react-router-dom';
import {
	Div,
	Spanner,
	Typography,
	Container,
} from '@icecreamsocial/components';
import { HeaderPortal } from '../../../Main';
import {
	CampaignsFilterForm,
	CampaignCardGrid,
	EmphasizedAction,
	QuerySearchBar,
	Pagename,
} from '../../../../presentational';
import { OptimisticCampaignCards } from '../../../../optimistic';
import { CompanyLogoLink } from '../../../../containers';

import {
	useStore,
	usePagination,
	useQueryString,
} from '../../../../../utils/hooks';

const CampaignsList = () => {
	const PAGINATION_LIMIT = 6;

	const store = useStore();
	const history = useHistory();
	const { pathname, search: qParams } = useLocation();
	const { path } = useRouteMatch();
	const { query, addParam, withoutParam, contains, qs } = useQueryString();

	const isClient = useMemo(
		() => store.Orchestrators.Auth.user?.role === 'client',
		[store.Orchestrators.Auth.user]
	);

	const SEARCH_NAME = 'searchText';
	const DEFAULT_FILTERS = {
		disabled: false,
		archived: false,
		clientSearch: null,
	};
	const [filters, setFilters] = useState({
		...DEFAULT_FILTERS,
		disabled: query.status === 'disabled',
		archived: contains('archived'),
		clientSearch: { value: query.clientSearch },
	});
	const [getPagination, resetPagination] = usePagination({
		limit: PAGINATION_LIMIT,
	});

	useEffect(() => {
		const params = {};
		const authUser = store.Orchestrators.Auth.user;
		if (authUser.role === 'client' && authUser.clientId) {
			params.clientSearch = authUser.clientId;
		}
		store.getCampaignsFlow({ ...params, ...query });
		store.Collections.Campaigns.setLocalPagination(0, PAGINATION_LIMIT);
		store.Collections.Campaigns.setIncludedBy(query);
		return () => {
			resetPagination();
		};
	}, [qParams]);

	/**
	 * for the filters
	 */
	const clearForm = () => {
		setFilters(DEFAULT_FILTERS);
	};

	const filterBy = useMemo(
		() => ({
			status: query.status,
			isArchived: contains('archived') && !!query.archived,
		}),
		[qParams]
	);

	// map values from form to query
	const filterSubmit = ({ archived, disabled, clientSearch, ...rest }) => {
		const q = { clientSearch: clientSearch?.value, archived, ...rest };
		if (contains(SEARCH_NAME)) {
			q[SEARCH_NAME] = query[SEARCH_NAME];
		}
		// status can be active | disabled
		if (disabled === true) {
			q.status = 'disabled';
		}
		// clean the object of params we don't want to include in the url
		if (q.archived === false) {
			delete q.archived;
		}
		const params = qs.stringify(q) ? `?${qs.stringify(q)}` : '';
		const url = `${pathname}${params}`;
		history.push(url);
	};

	const paginationConfig = {
		paginationServer: true,
		paginationDefaultPage: 1, // get this from a query param in the future
		paginationTotalRows: store.Collections.Campaigns.filterBy(filterBy).length,
		paginationPerPage: PAGINATION_LIMIT,
		paginationRowsPerPageOptions: [6, 9, 12, 15, 18],
		onChangePage: (page) => {
			const { skip, limit } = getPagination({
				page,
			});
			store.Collections.Campaigns.setLocalPagination(skip, skip + limit);
		},
		onChangeRowsPerPage: (currentRowsPerPage, page) => {
			const { skip, limit } = getPagination({
				page,
				limit: currentRowsPerPage,
			});
			store.Collections.Campaigns.setLocalPagination(skip, skip + limit);
		},
	};

	return (
		<Container>
			<HeaderPortal>
				<Div backgroundColor='#EBEBEB' textAlign='center' padding='md'>
					<Spanner grid gridColumns='repeat(3, 1fr)' padding='0'>
						<Div display='flex' alignItems='center'>
							<CompanyLogoLink />
						</Div>
						<Div display='flex' alignItems='center'>
							<Div elWidth='100%' textAlign='center'>
								<Typography.Span typoWeight='bold'>
									Ice Cream Social
								</Typography.Span>
							</Div>
						</Div>
					</Spanner>
				</Div>
			</HeaderPortal>
			<Div display='flex'>
				<Div elWidth='75%'>
					<Div
						display='flex'
						alignItems='center'
						justifyContent='space-between'
					>
						<Pagename>Campaigns</Pagename>
						{store.userRole !== 'affiliate' && (
							<EmphasizedAction
								as={Link}
								to={`${path}/create`}
								textDecoration='none'
								data-slate-action='newCampaign'
							>
								New Campaign
							</EmphasizedAction>
						)}
					</Div>
					<QuerySearchBar
						id='campaignsSearch'
						name={SEARCH_NAME}
						placeholder='Find Campaigns'
					/>
					{store.isLoading('campaignsList') ? (
						<OptimisticCampaignCards marginTop='lg' />
					) : (
						<CampaignCardGrid
							data-slate-list='campaigns'
							marginTop='lg'
							campaigns={store.Collections.Campaigns.paginateBy(filterBy)}
							paginationConfig={paginationConfig}
							isClient={isClient}
						/>
					)}
				</Div>
				<Div as='aside' elWidth='25%' marginLeft='lg'>
					<CampaignsFilterForm
						affiliateId={store.userParentAffiliateId}
						showClientFilter={store.userRole !== 'client'}
						onSubmit={filterSubmit}
						onReset={clearForm}
						initialValues={filters}
					/>
				</Div>
			</Div>
		</Container>
	);
};

CampaignsList.displayName = 'Campaigns.List';

export default observer(CampaignsList);
