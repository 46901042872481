import React, { useState, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import {
	Div,
	Button,
	InputGroup,
	List,
	Panel,
	Tag,
	Typography,
} from '@icecreamsocial/components';
import { useFormik } from 'formik';
import { ProcessorPanel, RequiredAsterisk, RouteChangeBlocker } from '..';
import authorizeNetLogo from '../../../assets/logo_authorize-net.svg';

const AuthorizeNetPanel = ({
	selected = false,
	processorData = {},
	onProcessorToggle,
	onSubmit,
	onDisconnect,
}) => {
	const authorizeNetForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			authorizenetApiLoginKey: processorData.authorizenetApiLoginKey,
			authorizenetTransactionKey: processorData.authorizenetTransactionKey,
		},
		onSubmit,
	});

	const activated = useMemo(
		() =>
			!!processorData.authorizenetApiLoginKey &&
			!!processorData.authorizenetTransactionKey,
		[
			processorData.authorizenetApiLoginKey,
			processorData.authorizenetTransactionKey,
		]
	);

	return (
		<>
			<ProcessorPanel
				name="authorize.net"
				description="Reward influencers through order refund credit"
				isActivated={activated}
				isFocused={selected}
				handleChangeProcessor={onProcessorToggle}
				logoSrc={authorizeNetLogo}
			>
				{!activated ? (
					<form onSubmit={authorizeNetForm.handleSubmit}>
						<InputGroup elWidth="100%" marginBottom="md">
							<InputGroup.Label>
								Transaction Key <RequiredAsterisk />
							</InputGroup.Label>
							<InputGroup.Fields>
								<InputGroup.Input
									id="authorizenetTransactionKey"
									name="authorizenetTransactionKey"
									paddingLeft="md"
									value={authorizeNetForm.values.authorizenetTransactionKey}
									onChange={authorizeNetForm.handleChange}
									required={selected}
								/>
							</InputGroup.Fields>
						</InputGroup>
						<InputGroup elWidth="100%" marginBottom="md">
							<InputGroup.Label>
								API Login ID <RequiredAsterisk />
							</InputGroup.Label>
							<InputGroup.Fields>
								<InputGroup.Input
									id="authorizenetApiLoginKey"
									name="authorizenetApiLoginKey"
									paddingLeft="md"
									value={authorizeNetForm.values.authorizenetApiLoginKey}
									onChange={authorizeNetForm.handleChange}
									required={selected}
								/>
							</InputGroup.Fields>
						</InputGroup>
						<Div textAlign="right">
							<Button
								type="submit"
								backgroundColor="brand"
								textColor="bodyInverse"
							>
								Connect
							</Button>
						</Div>
					</form>
				) : (
					<Div display="flex" alignItems="center">
						<Tag
							textColor="good"
							borderColor="good"
							marginRight="lg"
							textTransform="capitalize"
						>
							{processorData.status}
						</Tag>
						<Div flexGrow="1">
							<Typography.Span display="block" typoWeight="bold">
								API Login ID
							</Typography.Span>
							<Typography.Span>*************</Typography.Span>
						</Div>
						<Button
							onClick={onDisconnect}
							backgroundColor="ambientLight"
							padding="sm"
							paddingLeft="lg"
							paddingRight="lg"
						>
							Disconnect
						</Button>
					</Div>
				)}
			</ProcessorPanel>
			<RouteChangeBlocker
				when={authorizeNetForm.dirty}
				title={'Exit Reward Processor Setup?'}
				message={"Your progress won't be saved"}
				confirmText="Discard Changes & Exit"
				cancelText={'Continue Setup'}
			/>
		</>
	);
};
AuthorizeNetPanel.displayName = 'BusinessAuthorizeNetPanelForm';

export default observer(AuthorizeNetPanel);
