"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ic_code = void 0;
var ic_code = {
  "viewBox": "0 0 24 24",
  "children": [{
    "name": "path",
    "attribs": {
      "d": "M9.4 16.6L4.8 12l4.6-4.6L8 6l-6 6 6 6 1.4-1.4zm5.2 0l4.6-4.6-4.6-4.6L16 6l6 6-6 6-1.4-1.4z"
    }
  }]
};
exports.ic_code = ic_code;