import React from "react";
import { observer } from "mobx-react-lite";
import { Button, Div, InputGroup, Typography } from "@icecreamsocial/components";
import { useFormik } from "formik";
import { Checkbox } from "@material-ui/core";
import { CalendarPicker, MinMaxToggle } from "../";
/**
 * @module ConversionsFilterForm
 * @category Components
 * @subcategory Presentational
 */
/**
 *
 * @param {object} props
 * @param {function} props.onSubmit the onSubmit function passed to useFormik
 * @param {function} props.onReset the onReset function passed to useFormik
 * @param {object} props.initialValues
 * @param {boolean} [props.initialValues.revenueSwitch] whether to show min max field
 * @param {number} [props.initialValues.revenueGTOE]
 * @param {number} [props.initialValues.revenueLTOE]
 * @param {number} [props.initialValues.conversionDateGTOE]
 * @param {number} [props.initialValues.conversionDateLTOE]
 *
 */
const ConversionsFilterForm = ({ onSubmit, onReset, initialValues }) => {
  /**
   * for the filters
   */
  const filtersForm = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit,
    onReset,
  });

  return (
    <form onSubmit={filtersForm.handleSubmit}>
      <Div display="flex" alignItems="center" justifyContent="space-between">
        <Typography.H2 typoWeight="500">Filters</Typography.H2>
        <Button
          onClick={() => {
            filtersForm.handleReset();
            filtersForm.handleSubmit();
          }}
          typoSize="small"
          textDecoration="underline"
          border="none"
          shadow="none"
          hoverShadow="none"
          padding="sm"
          backgroundColor="transparent"
        >
          Reset filters
        </Button>
      </Div>
      <Div as="fieldset" border="none" elWidth="100%">
        <CalendarPicker
          label="Date Created"
          fromDate={filtersForm.values.fromDate}
          onFromDateChange={filtersForm.handleChange}
          toDate={filtersForm.values.toDate}
          onToDateChange={filtersForm.handleChange}
        />
      </Div>

      {/* Conversion does not currently provide a status 
      <Typography.Span
        display="block"
        typoWeight="500"
        typoSize=".9em"
        marginBottom="md"
      >
        Conversion Status
      </Typography.Span>
      <InputGroup display="flex" alignItems="center">
        <Checkbox
          color="primary"
          name="showEligible"
          value={filtersForm.values.showConfirmed}
          checked={filtersForm.values.showConfirmed}
          onChange={filtersForm.handleChange}
        />{" "}
        <InputGroup.Label display="inline-block">Confirmed</InputGroup.Label>
      </InputGroup>
      <InputGroup display="flex" alignItems="center">
        <Checkbox
          color="primary"
          name="showEligible"
          value={filtersForm.values.showPending}
          checked={filtersForm.values.showPending}
          onChange={filtersForm.handleChange}
        />{" "}
        <InputGroup.Label display="inline-block">Pending</InputGroup.Label>
      </InputGroup>
      <InputGroup display="flex" alignItems="center">
        <Checkbox
          color="primary"
          name="showEligible"
          value={filtersForm.values.showCanceled}
          checked={filtersForm.values.showCanceled}
          onChange={filtersForm.handleChange}
        />{" "}
        <InputGroup.Label display="inline-block">Canceled</InputGroup.Label>
      </InputGroup> */}

      <Div as="fieldset" border="none" elWidth="100%">
        <Typography.Span
          display="block"
          typoWeight="500"
          typoSize=".9em"
          marginBottom="md"
        >
          Metrics
        </Typography.Span>
        <MinMaxToggle
          name="revenue"
          label="Order Total"
          show={filtersForm.values.revenueSwitch}
          minValue={filtersForm.values.revenueGTOE}
          maxValue={filtersForm.values.revenueLTOE}
          handleChange={filtersForm.handleChange}
        />
      </Div>

      <Button
        type="submit"
        disable={!filtersForm.isValid}
        elWidth="100%"
        textColor="bodyInverse"
        backgroundColor="brand"
        padding="sm"
      >
        Apply Filters
      </Button>
    </form>
  );
};

ConversionsFilterForm.displayName = "ConversionsFilterForm";
export default observer(ConversionsFilterForm);
