import React, { useEffect } from 'react';
import { Div, List, Panel } from '@icecreamsocial/components';
import OptimisticText from '../Text';

const OptimisticSettings = () => {
	return (
		<>
			<Div display="flex" padding="md">
				<Div>
					<Div as="aside" elWidth={['200px']} flexShrink="0">
						<List bullets="none" position="sticky" positionTop="16px">
							<List.Item>
								<Div
									optimistic
									elWidth="100%"
									elHeight="36px"
									borderRadius="default"
								/>
							</List.Item>
							<List.Item>
								<Div
									optimistic
									elWidth="100%"
									elHeight="36px"
									borderRadius="default"
								/>
							</List.Item>
							<List.Item>
								<Div
									optimistic
									elWidth="100%"
									elHeight="36px"
									borderRadius="default"
								/>
							</List.Item>
						</List>
					</Div>
				</Div>
				<Div marginLeft="lg" flexGrow="1">
					<OptimisticText elHeight="2em" elWidth="150px" marginTop="lg" />
					<Panel backgroundColor="bodyInverse" marginTop="lg">
						<Panel.Header backgroundColor="bodyInverse">
							<OptimisticText elHeight="1.5em" elWidth="150px" margin="auto" />
						</Panel.Header>
						<Panel.Body>
							<Div marginBottom="lg">
								<OptimisticText
									elHeight="1em"
									elWidth="10%"
									marginBottom="sm"
								/>
								<Div
									optimistic
									elWidth="100%"
									elHeight="36px"
									borderRadius="default"
								/>
							</Div>
							<Div marginBottom="lg">
								<OptimisticText
									elHeight="1em"
									elWidth="20%"
									marginBottom="sm"
								/>
								<Div display="flex" alignItems="center" marginBottom="sm">
									<Div
										optimistic
										elWidth="24px"
										elHeight="24px"
										borderRadius="default"
										marginRight="md"
									/>
									<OptimisticText elHeight="1em" elWidth="90px" />
								</Div>
								<Div display="flex" alignItems="center" marginBottom="sm">
									<Div
										optimistic
										elWidth="24px"
										elHeight="24px"
										borderRadius="default"
										marginRight="md"
									/>
									<OptimisticText elHeight="1em" elWidth="90px" />
								</Div>
								<Div display="flex" alignItems="center" marginBottom="sm">
									<Div
										optimistic
										elWidth="24px"
										elHeight="24px"
										borderRadius="default"
										marginRight="md"
									/>
									<OptimisticText elHeight="1em" elWidth="90px" />
								</Div>
							</Div>
						</Panel.Body>
					</Panel>
				</Div>
			</Div>
		</>
	);
};

OptimisticSettings.displayName = 'OptimisticSettings';

export default OptimisticSettings;
