import React, { useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import {
	Button,
	Div,
	InputGroup,
	Typography,
} from '@icecreamsocial/components';
import { useFormik } from 'formik';
import { Checkbox, Radio, RadioGroup, Switch } from '@material-ui/core';
import {
	CalendarPicker,
	MinMaxToggle,
} from '../../../../../../../../../../presentational';
import { useInfluencerFilters } from '../FilterProvider';
import { useQueryString } from '../../../../../../../../../../../utils/hooks';

/**
 * @module FilterForm
 * @category Components
 * @subcategory Containers
 */
/**
 * @type {React.FC<{}>}
 */
const FilterForm = () => {
	/**
	 * @type {InfluencerFilters.Context<InfluencerPerformance.ControlsState>}
	 */
	const { state, update, clear } = useInfluencerFilters();
	const { contains } = useQueryString();

	const clearControls = () => {
		clear('search', 'page', 'limit', 'skip');
	};

	/**
	 * @internal
	 * Map query state to actual form values if needed
	 * @param {InfluencerFilters.State | InfluencerFilters.ExtendedState<InfluencerPerformance.ControlsState>} values
	 * @return {InfluencerFilters.ExtendedState<InfluencerPerformance.ControlsState>}
	 */
	const mapStateToForm = (values) => {
		const {
			page,
			skip,
			limit,
			createdDateGTOE,
			createdDateLTOE,
			isAnonymized: showAnonymized,
			...rest
		} = values;

		const invitesSwitch = contains('invitesGTOE') || contains('invitesLTOE');
		const invitesClickCountSwitch =
			contains('invitesClickCountGTOE') || contains('invitesClickCountLTOE');
		const conversionsSwitch =
			contains('conversionsGTOE') || contains('conversionsLTOE');
		const revenueSwitch = contains('revenueGTOE') || contains('revenueLTOE');
		const isAnonymized = showAnonymized === 'true' ? true : false;

		return {
			fromDate: createdDateGTOE,
			toDate: createdDateLTOE,
			invitesSwitch,
			invitesClickCountSwitch,
			conversionsSwitch,
			revenueSwitch,
			isAnonymized,
			...rest,
		};
	};

	/**
	 * for the filters
	 */
	const filtersForm = useFormik({
		enableReinitialize: true,
		initialValues: mapStateToForm(state),
		onSubmit: update,
	});

	return (
		<form onSubmit={filtersForm.handleSubmit}>
			<Div display='flex' alignItems='center' justifyContent='space-between'>
				<Typography.H2 typoWeight='500'>Filters</Typography.H2>
				<Button
					onClick={(e) => {
						clearControls();
					}}
					typoSize='small'
					textDecoration='underline'
					border='none'
					shadow='none'
					hoverShadow='none'
					padding='sm'
					backgroundColor='transparent'
				>
					Reset filters
				</Button>
			</Div>
			<Div as='fieldset' border='none' elWidth='100%'>
				<CalendarPicker
					label='Date Created'
					fromDate={filtersForm.values.fromDate}
					onFromDateChange={filtersForm.handleChange}
					toDate={filtersForm.values.toDate}
					onToDateChange={filtersForm.handleChange}
				/>
			</Div>
			<Div as='fieldset' border='none' elWidth='100%'>
				<Typography.Span
					display='block'
					typoWeight='500'
					typoSize='.9em'
					marginBottom='sm'
				>
					Reward Eligibility
				</Typography.Span>
				<RadioGroup name='eligibility'>
					<InputGroup display='flex' alignItems='center'>
						<Radio
							color='primary'
							value={undefined}
							checked={!filtersForm.values.eligibility}
							onChange={filtersForm.handleChange}
						/>{' '}
						<InputGroup.Label display='inline-block'>Any</InputGroup.Label>
					</InputGroup>
					<InputGroup display='flex' alignItems='center'>
						<Radio
							color='primary'
							value='eligible'
							checked={filtersForm.values.eligibility === 'eligible'}
							onChange={filtersForm.handleChange}
						/>{' '}
						<InputGroup.Label display='inline-block'>Eligible</InputGroup.Label>
					</InputGroup>
					<InputGroup display='flex' alignItems='center'>
						<Radio
							color='primary'
							value='ineligible'
							checked={filtersForm.values.eligibility === 'ineligible'}
							onChange={filtersForm.handleChange}
						/>{' '}
						<InputGroup.Label display='inline-block'>
							Ineligible
						</InputGroup.Label>
					</InputGroup>
				</RadioGroup>
			</Div>
			<Div as='fieldset' border='none' elWidth='100%'>
				<Typography.Span
					display='block'
					typoWeight='500'
					typoSize='.9em'
					marginBottom='sm'
				>
					Reward Status
				</Typography.Span>
				<RadioGroup name='status'>
					<InputGroup display='flex' alignItems='center'>
						<Radio
							color='primary'
							value={undefined}
							checked={!filtersForm.values.status}
							onChange={filtersForm.handleChange}
						/>{' '}
						<InputGroup.Label display='inline-block'>Any</InputGroup.Label>
					</InputGroup>
					<InputGroup display='flex' alignItems='center'>
						<Radio
							color='primary'
							value='processed'
							checked={filtersForm.values.status === 'processed'}
							onChange={filtersForm.handleChange}
						/>{' '}
						<InputGroup.Label display='inline-block'>
							Processed
						</InputGroup.Label>
					</InputGroup>
				</RadioGroup>
			</Div>

			<Div as='fieldset' border='none' elWidth='100%'>
				<Typography.Span
					display='block'
					typoWeight='500'
					typoSize='.9em'
					marginBottom='sm'
				>
					Metrics
				</Typography.Span>
				<MinMaxToggle
					name='invites'
					label='Lifetime Shares'
					show={filtersForm.values.invitesSwitch}
					minValue={filtersForm.values.invitesGTOE}
					maxValue={filtersForm.values.invitesLTOE}
					handleChange={filtersForm.handleChange}
				/>
				<MinMaxToggle
					name='invitesClickCount'
					label='Lifetime Clicks'
					show={filtersForm.values.invitesClickCountSwitch}
					minValue={filtersForm.values.invitesClickCountGTOE}
					maxValue={filtersForm.values.invitesClickCountLTOE}
					handleChange={filtersForm.handleChange}
				/>
				<MinMaxToggle
					name='conversions'
					label='Lifetime Referrals'
					show={filtersForm.values.conversionsSwitch}
					minValue={filtersForm.values.conversionsGTOE}
					maxValue={filtersForm.values.conversionsLTOE}
					handleChange={filtersForm.handleChange}
				/>
				<MinMaxToggle
					name='revenue'
					label='Lifetime Revenue'
					show={filtersForm.values.revenueSwitch}
					minValue={filtersForm.values.revenueGTOE}
					maxValue={filtersForm.values.revenueLTOE}
					handleChange={filtersForm.handleChange}
				/>
			</Div>

			<Div as='fieldset' border='none' elWidth='100%'>
				<Typography.Span
					display='block'
					typoWeight='500'
					typoSize='.9em'
					marginBottom='sm'
				>
					Other
				</Typography.Span>

				<InputGroup display='flex' alignItems='center'>
					<Checkbox
						color='primary'
						name='isAnonymized'
						value='false'
						checked={filtersForm.values.isAnonymized === true}
						onChange={filtersForm.handleChange}
					/>{' '}
					<InputGroup.Label display='inline-block'>
						Anonymous Only
					</InputGroup.Label>
				</InputGroup>
			</Div>

			<Button
				type='submit'
				disable={!filtersForm.isValid}
				elWidth='100%'
				textColor='bodyInverse'
				backgroundColor='brand'
				padding='sm'
			>
				Apply Filters
			</Button>
		</form>
	);
};

FilterForm.displayName = 'FilterForm';
export default observer(FilterForm);
