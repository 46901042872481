import React, { useEffect, useState, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Div, Panel, Typography } from '@icecreamsocial/components';
import TooltipInfo from '../../../components/presentational/TooltipInfo';
import { ResponsiveLineCanvas } from '@nivo/line';
import { OptimisticText, OptimisticChart } from '../../optimistic';
import { flags } from '../../../utils';

const StatPanel = ({
	overviewIsLoading = false,
	dataIsLoading = false,
	backgroundColor,
	color,
	data,
	label,
	tooltipLabel,
	name,
	value,
	...rest
}) => {
	return (
		<Panel
			elHeight='150px'
			backgroundColor={backgroundColor}
			border='0'
			marginTop='lg'
			marginBottom='lg'
			{...rest}
		>
			<Panel.Body
				as='figure'
				display='flex'
				alignItems='center'
				style={{ overflow: 'hidden' }}
			>
				<Div
					as='figcaption'
					elMinWidth='25%'
					borderRight='1px solid'
					borderColor={color}
				>
					{flags.isActive(flags.names.ENABLE_DASHBOARD_TOOLTIP) ? (
						<Div grid gridColumns='2fr 1fr'>
							<Typography.P
								typoSize='1.15em'
								textTransform='capitalize'
								marginTop='0'
							>
								{label}
							</Typography.P>
							<Div marginLeft='md'>
								<TooltipInfo text={tooltipLabel}></TooltipInfo>
							</Div>
						</Div>
					) : (
						<Typography.P
							typoSize='1.15em'
							textTransform='capitalize'
							marginTop='0'
						>
							{label}
						</Typography.P>
					)}

					<Typography.Span typoSize='lg' typoWeight='bold'>
						{!overviewIsLoading ? value : <OptimisticText />}
					</Typography.Span>
				</Div>
				{!dataIsLoading ? (
					<ResponsiveLineCanvas
						data={[{ id: name, data }]}
						colors={color}
						axisBottom={null}
						axisLeft={null}
						margin={{ bottom: 20, left: 20, right: 20, top: 20 }}
						enableGridX={false}
						enableGridY={false}
						enableCrosshair={false}
						enableSlices='x'
						lineWidth={2}
						pointBorderColor={color}
						pointBorderWidth={3}
						pointColor={backgroundColor}
						pointLabel='y'
						pointLabelYOffset={-12}
						pointSize={3}
						xScale={{ type: 'point' }}
						yScale={{
							type: 'linear',
							stacked: true,
							min: 0,
							max: 'auto',
						}}
						sliceTooltip={({ slice }) => (
							<>
								{slice.points &&
									slice.points.map((point, i) => (
										<Div
											key={i}
											shadow='raised'
											padding='sm'
											textAlign='center'
											backgroundColor='body'
											borderRadius='3px'
											textColor='bodyInverse'
										>
											<Typography.Span typoSize='lg' display='block'>
												{point.data.yFormatted}
											</Typography.Span>
											<Typography.Span typoWeight='light' display='block'>
												{point.data.xFormatted}
											</Typography.Span>
										</Div>
									))}
							</>
						)}
						// style={{
						// 	width: '100%',
						// }}
					/>
				) : (
					<OptimisticChart marginLeft='md' />
				)}
			</Panel.Body>
		</Panel>
	);
};
StatPanel.displayName = 'StatPanel';

export default observer(StatPanel);
