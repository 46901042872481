import React from 'react';
import { Panel, Typography, Div } from '@icecreamsocial/components';

const CustomDescription = () => {
	return (
		<Panel marginBottom="md" backgroundColor="bodyInverse">
			<Panel.Body>
				<Typography.H4
					display="block"
					typoSize="lg"
					typoWeight="400"
					marginTop="0"
					marginBottom="md"
				>
					None
				</Typography.H4>
				<Typography.Span display="block">
					Custom rewards are issued and fulfilled by you
				</Typography.Span>
			</Panel.Body>
		</Panel>
	);
};

CustomDescription.displayName = 'CustomDescription';

export default CustomDescription;
