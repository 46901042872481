import React from 'react';
import { observer } from 'mobx-react-lite';
import {
	Img,
	Div,
	Container,
	Typography,
	Button,
} from '@icecreamsocial/components';
import icsMelted from '../../../assets/ics-melting.png';

const NoFilterData = ({ resetFilter }) => {
	return (
		<Container padding='md' backgroundColor='bodyInverse'>
			<Div textAlign='center'>
				<Img elWidth='40%' flexShrink='0' src={icsMelted} />
			</Div>
			<Div textAlign='center' paddingTop='md' paddingBottom='md'>
				<Typography.Span typoWeight='600'>No results found</Typography.Span>
				<Typography.P>
					We couldn't find any data matching your search criteria, try adjusting
					filters or search terms to see more results
				</Typography.P>
				<Button
					onClick={() => {
						resetFilter();
					}}
				>
					Reset Search & Filters
				</Button>
			</Div>
		</Container>
	);
};

NoFilterData.displayName = 'NoFilterData';
export default observer(NoFilterData);
