import React from 'react';
import { observer } from 'mobx-react-lite';
import { Div, InputGroup, Panel, Typography } from '@icecreamsocial/components';
import { FormikProps } from 'formik';
import { RequiredAsterisk } from '../../';
import { useUserSettingsForm } from '../';
/**
 * @module ProfileSettings
 * @category Components
 * @subcategory Presentational
 * @requires module:UserSettingsForm
 */

/**
 * @name ProfileSettings
 * @description
 * A set of inputs for creating and editing a user's information.
 */

const ProfileSettings = () => {
	const { form, isEditing } = useUserSettingsForm();

	return (
		<Panel backgroundColor="bodyInverse" marginBottom="lg">
			<Panel.Header backgroundColor="bodyInverse" paddingBottom="0">
				<Typography.H2
					textColor="body"
					textAlign="center"
					margin="none"
					typoSize="md"
				>
					Profile Settings
				</Typography.H2>
			</Panel.Header>
			<Panel.Body>
				<InputGroup elWidth="100%" marginBottom="sm">
					<InputGroup.Label>
						Email <RequiredAsterisk />
					</InputGroup.Label>
					<InputGroup.Fields>
						<InputGroup.Input
							id="email"
							name="email"
							type="email"
							value={form.values.email}
							onChange={form.handleChange}
							paddingLeft="md"
							autoComplete="off"
							autoFocus
							required
						/>
					</InputGroup.Fields>
				</InputGroup>
				<InputGroup elWidth="100%" marginBottom="sm">
					<InputGroup.Label>Password</InputGroup.Label>
					<Div marginBottom="sm">
						<Typography.Span as="small">
							Your password is {(form.values.password || '').length} characters
							long (minimum 8).
						</Typography.Span>
					</Div>
					<InputGroup.Fields>
						<InputGroup.Input
							id="password"
							name="password"
							type="password"
							value={form.values.password}
							onChange={form.handleChange}
							paddingLeft="md"
							autoComplete="new-password"
							required={!isEditing}
						/>
					</InputGroup.Fields>
					<Typography.Span as="small">
						Passwords should contain at least 8 characters, a lowercase letter,
						an uppercase letter, a number, and a special character.
					</Typography.Span>
				</InputGroup>

				<Div display="flex" marginBottom="sm">
					<InputGroup elWidth="100%" marginRight="sm" style={{ flex: 1 }}>
						<InputGroup.Label>
							First Name <RequiredAsterisk />
						</InputGroup.Label>
						<InputGroup.Fields>
							<InputGroup.Input
								id="firstName"
								name="firstName"
								value={form.values.firstName}
								onChange={form.handleChange}
								paddingLeft="md"
								required
							/>
						</InputGroup.Fields>
					</InputGroup>

					<InputGroup elWidth="100%" marginRight="sm" style={{ flex: 1 }}>
						<InputGroup.Label>
							Last Name <RequiredAsterisk />
						</InputGroup.Label>
						<InputGroup.Fields>
							<InputGroup.Input
								id="lastName"
								name="lastName"
								value={form.values.lastName}
								onChange={form.handleChange}
								paddingLeft="md"
								required
							/>
						</InputGroup.Fields>
					</InputGroup>
				</Div>
			</Panel.Body>
		</Panel>
	);
};
ProfileSettings.displayName = 'UserSettingsForm.Profile';

export default observer(ProfileSettings);
