import React from 'react';
import { observer } from 'mobx-react-lite';
import { Div } from '@icecreamsocial/components';
import { useTheme } from '../../../../utils/hooks';

const MainPane = ({ children, ...rest }) => {
	const { spacing } = useTheme();
	return (
		<Div
			flexGrow={1}
			padding={`0 ${spacing.md} ${spacing.lg} ${spacing.md}`}
			{...rest}
		>
			{children}
		</Div>
	);
};
MainPane.displayName = 'InfluencerManager.MainPane';

export default observer(MainPane);
