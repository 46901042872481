import React from 'react';
import { observer } from 'mobx-react-lite';
import {
	Container,
	Div,
	Spanner,
	Typography,
} from '@icecreamsocial/components';
import { HeaderPortal } from '../Main';
import {
	DownloadIcon,
	EmphasizedAction,
	PageMeta,
	Pagename,
} from '../../presentational';
import { CompanyLogoLink } from '../../containers';

import { useStore } from '../../../utils/hooks';
import { withLoader } from '../../../utils/HOCs';
import EmptyState from './components/EmptyState';
import { DashboardFiltersProvider } from './components/FilterProvider';
import FilterForm from './components/FilterForm';
import OldStats from './components/Stats';
import Stats from './components/NewStats';
import { flags } from '../../../utils';

const USE_NEW_STATS = flags.isActive(flags.names.ENABLE_NEW_DASHBOARD);

const DownloadButton = withLoader(EmphasizedAction);

const Dashboard = () => {
	const store = useStore();
	const role = store.Orchestrators.Auth.user?.role || 'client';

	const exportStats = () => {
		store.exportDailyStats();
	};

	return (
		<DashboardFiltersProvider>
			<PageMeta
				title={'Dashboard | Ice Cream Social'}
				description={'Daily stats'}
			/>
			<HeaderPortal>
				<Div backgroundColor='#EBEBEB' textAlign='center' padding='md'>
					<Spanner grid gridColumns='repeat(3, 1fr)' padding='0'>
						<Div display='flex' alignItems='center'>
							<CompanyLogoLink />
						</Div>
						<Div display='flex' alignItems='center'>
							<Div elWidth='100%' textAlign='center'>
								<Typography.Span typoWeight='bold'>
									Ice Cream Social
								</Typography.Span>
							</Div>
						</Div>
					</Spanner>
				</Div>
			</HeaderPortal>
			<Container>
				<Div display='flex'>
					{store.needsBusinessSetup ? (
						<EmptyState user={store.userName} />
					) : (
						<>
							<Div elWidth='75%'>
								<Div
									display='flex'
									alignItems='center'
									justifyContent='space-between'
								>
									<Pagename>Dashboard</Pagename>
									<DownloadButton
										onClick={exportStats}
										isLoading={false}
										disabled={false}
										alignItems='center'
										display='flex'
										textDecoration='none'
									>
										<DownloadIcon size={18} marginRight='sm' />
										<Typography.Span>Daily Stats</Typography.Span>
									</DownloadButton>
								</Div>
								{USE_NEW_STATS ? <Stats /> : <OldStats />}
							</Div>
							<Div as='aside' elWidth='25%' marginLeft='lg'>
								<Div position='sticky' positionTop='0'>
									<FilterForm
										role={role}
										userAffiliateId={store.Orchestrators.Auth.user?.affiliateId}
										userClientId={store.Orchestrators.Auth.user?.clientId}
									/>
								</Div>
							</Div>
						</>
					)}
				</Div>
			</Container>
		</DashboardFiltersProvider>
	);
};
Dashboard.displayName = 'Dashboard';

export default observer(Dashboard);
