import { createQueryKeys } from '@lukemorales/query-key-factory';

/**
 * reuable query keys for Converions for use with useQuery or useMutation
 */
export const queryKeys = createQueryKeys('clients', {
	getClients: ({ affiliateId, searchText, status, isArchived }) => ({
		affiliateId,
		searchText,
		status,
		isArchived,
	}),
	/**
	 * @param {ClientsQueries.CoreParams} params
	 */
	getClientUseCaseStatus: ({ clientId }) => ({ clientId }),
});
