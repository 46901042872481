import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { useStore } from '../../../../../utils/hooks';
import { AffiliatesTable, Pagename } from '../../../../presentational';

const Affiliates = () => {
	const store = useStore();
	const { affiliateId } = useParams();
	useEffect(() => {
		store.getAffiliatesFlow({ affiliateId });
	}, [affiliateId]);

	return (
		<>
			<Pagename>Sub-Affiliates</Pagename>
			<AffiliatesTable collection={store.Collections.Affiliates.list.toJS()} />
		</>
	);
};

export default observer(Affiliates);
