import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import InfluencerEligibleState from '../InfluencerEligibleState';
import Rewards from '../Rewards';
import Conversions from '../Conversions';
import MainPane from '../MainPane';
import { useQueryString } from '../../../../utils/hooks';

const Overview = ({
	influencer,
	campaign,
	userRole,
	conversionsLink,
	rewardLink,
}) => {
	const { query } = useQueryString();
	const isEligible = influencer?.rewardsDue > 0;

	return (
		<MainPane>
			{isEligible && (
				<InfluencerEligibleState userRole={userRole} rewardLink={rewardLink} />
			)}
			<Rewards
				influencerId={query.iid}
				conversionCount={influencer?.conversionCount}
				conversionGoal={campaign?.conversionGoal}
			/>
			<Conversions
				influencer={influencer}
				campaignId={campaign?.id}
				conversionsGenerated={influencer?.conversionCount}
				revenueGenerated={influencer?.conversionRevenue}
				manageLink={conversionsLink}
			/>
		</MainPane>
	);
};
Overview.displayName = 'InfluencerManager.Overview';

export default observer(Overview);
