import React from 'react';
import { observer } from 'mobx-react-lite';
import { Div, Typography } from '@icecreamsocial/components';
import { formatDateTime } from '../../../../utils';

const MetaData = ({
  orderId = 'None provided',
  transactionId = 'None provided',
  createdAt,
  influencerId,
}) => {
  const metaData = [
    {
      name: 'Influencer Since',
      value: createdAt ? formatDateTime(new Date(createdAt)) : undefined,
    },
    {
      name: 'Influencer ID',
      value: influencerId,
    },
    {
      name: 'Order ID',
      value: orderId,
    },
    {
      name: 'Transaction ID',
      value: transactionId,
    },
  ];

  return (
    <>
      {metaData.map(({ name, value }, key) => (
        <Div key={key} marginBottom='lg'>
          <Typography.Span
            display='block'
            textColor='ambientDark'
            marginBottom='sm'
            marginTop='md'
          >
            {name}
          </Typography.Span>
          <Typography.Span style={{ fontStyle: value ? 'normal' : 'italic' }}>
            {value || 'None'}
          </Typography.Span>
        </Div>
      ))}
    </>
  );
};
MetaData.displayName = 'InfluencerManager.MetaData';

export default observer(MetaData);
