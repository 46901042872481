import { FeatureFlags } from '@icecreamsocial/feature-flags';

const localEnvs = process.env.NODE_ENV === 'local';
const devEnvs = localEnvs || process.env.NODE_ENV === 'development';
const stageEnvs = devEnvs || process.env.NODE_ENV === 'staging';

/**
 * This file contains all features used throughout the app
 * All feature flags must be set here.  They do NOT need to be determined by environment variables
 */
const envFeatureFlags = {
	/**
	 * @date 04/24/2024
	 * @description enablesnew dashboard view
	 * @remove when dashboard redesign is qa'd
	 */
	ENABLE_NEW_DASHBOARD: true,
	/**
	 * @date 03/28/2024
	 */
	ENABLE_NEW_CAMPAIGN_OFFER: true,
	/**
	 * @date 03/26/2024
	 */
	ENABLE_NEW_CAMPAIGN_SHARE: true,
	/**
	 * @date 03/22/2024
	 */
	DISABLE_INVITE_EMAIL_CONTENT: true,
	/**
	 * @date 03/18/2024
	 */
	ENABLE_DASHBOARD_TOOLTIP: true,
	/**
	 * @date 02/29/2024
	 */
	ENABLE_CUSTOM_NO_DATA_RESPONSE: true,
	/**
	 * @date 01/09/2024
	 */
	ENABLE_SQUARE_CUSTOM_REWARD: true,

	/**
	 * @date 08/28/2023
	 */
	DISABLE_INVITE_REPORT: true,
	/**
	 * @date 04/10/2023
	 */
	ENABLE_EXPERIMENTAL_CAMPAIGN_SMS_SETTINGS: stageEnvs,
	/**
	 * @date 7/5/22
	 * @description
	 * Enables the new Square Connect URL
	 */
	ENABLE_EXPERIMENTAL_SQUARE_CONNECT_URL: stageEnvs,
	/**
	 * @date 6/15/22
	 * @description
	 * Enables the SMS marketing field in Policy Settings form
	 * @remove
	 * when SMS marketing is supported
	 */
	ENABLE_SMS_MARKETING_TOGGLE: stageEnvs,
	/**
	 * @date 4/5/2022
	 * @description
	 * Enables authorize.net to be used as a processor
	 * @removeCondition
	 * Authorize.net processor implementation passes QA and Code Review
	 */
	ENABLE_EXPERIMENTAL_AUTHORIZE_NET:
		process.env.ENABLE_EXPERIMENTAL_AUTHORIZE_NET === 'true' ? true : false,
	/**
	 * @date 4/5/2022
	 * @description
	 * Replaces select inputs with new Combobox input.  Currently not used everywhere
	 * @removeCondition
	 * Combobox component passes code review and QA.  Currently on hold
	 */
	ENABLE_EXPERIMENTAL_COMBOBOX:
		process.env.ENABLE_EXPERIMENTAL_COMBOBOX === 'true' ? true : false,
	/**
	 * @date 4/5/2022
	 * @description
	 * Allows a user to deactivate themselves
	 * @removeConditions
	 * Investigate repercussions on data when user deactivates themselves
	 */
	ENABLE_EXPERIMENTAL_DEACTIVATE_SELF:
		process.env.ENABLE_EXPERIMENTAL_DEACTIVATE_SELF === 'true' ? true : false,
	/**
	 * @date 4/5/2022
	 * @description
	 * currently disabled on all environments
	 * @removeCondition
	 * Masking info is supported again
	 */
	ENABLE_EXPERIMENTAL_MASK_INFO: false,
};

/**
 * @type {FeatureFlags}
 */
export const flags = new FeatureFlags({
	...envFeatureFlags,
});
