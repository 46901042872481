import React, { useState, useContext, useCallback, useEffect } from 'react';
import { createPortal } from 'react-dom';
import {
	Switch,
	Route,
	useLocation,
	Redirect,
	useHistory,
} from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Div, GridLayout, Typography } from '@icecreamsocial/components';
import {
	useStore,
	useTheme,
	useAllowedRoutes,
	// useRequestThrottle,
	useResponseInterceptor,
	useNotificationQueue,
} from '../../../utils/hooks';
import {
	BusinessSetupBanner,
	PrimaryNav,
	PolicyWarningBanner,
	BlankModalBg,
} from '../../presentational';
import { HeaderPortalContext } from '../../providers';
import { ConditionalRoute } from '../../containers';
import Auth from '../Auth';
import { ModalProvider } from 'styled-react-modal';
import { flags, isICSURL } from '../../../utils';

/**
 * @module Main
 * @category Components
 * @subcategory Pages
 */

/**
 * @description
 * This is the main abstraction route.  Since the layout changes between an admin/one column layout,
 * we will structure the route naming based on this.  the single column layouts are used when creating
 * and updating different settings.  if the path contains `:module/edit/`, we will use the single column layout
 *
 * Exposes a HeaderPortal which hooks into the layout's Header portion, allowing all child routes to inject content
 * into them easily
 */

const Main = () => {
	const store = useStore();
	const routes = useAllowedRoutes();
	const [headerRef, setHeaderRef] = useState({
		current: null,
	});
	const { baseUnit, colors } = useTheme();
	const callbackRef = useCallback((ref) => setHeaderRef({ current: ref }), [
		headerRef.current,
	]);
	const history = useHistory();
	const { pathname } = useLocation();

	/**
	 * listen for notifications
	 */
	useNotificationQueue();

	/** handle 401s */
	useResponseInterceptor(
		(next) => Promise.resolve(next),
		(err) => {
			if (err.response.status === 401) {
				store.logoutFlow('Please login to continue');
			}
			return Promise.reject(err);
		}
	);

	/** handle 403s */
	useResponseInterceptor(
		(next) => Promise.resolve(next),
		(err) => {
			if (store.isAuthenticated && err.response.status === 403) {
				history.replace('/access-denied');
			}
			return Promise.reject(err);
		}
	);

	/** handle 404s */
	useResponseInterceptor(
		(next) => Promise.resolve(next),
		(err) => {
			if (store.isAuthenticated && err.response.status === 404) {
				history.replace('/not-found');
			}
			return Promise.reject(err);
		}
	);

	useEffect(() => {
		store.bootstrapFlow();
	}, [store.isAuthenticated]);

	return (
		!store.isLoading('bootstrap') && (
			<ModalProvider backgroundComponent={BlankModalBg}>
				<Switch>
					<ConditionalRoute
						path='/auth'
						redirectPath='/dashboard'
						shouldRedirect={store.isAuthenticated}
						exact
					>
						<Auth />
					</ConditionalRoute>
					<ConditionalRoute
						path='*'
						redirectPath='/auth'
						shouldRedirect={!store.isAuthenticated}
					>
						<HeaderPortalContext.Provider value={{ portalRef: headerRef }}>
							<GridLayout
								preset={
									store.Orchestrators.View.singleColumn ? 'store' : 'admin'
								}
							>
								{!store.Orchestrators.View.singleColumn && (
									<GridLayout.Nav zIndex='20'>
										<PrimaryNav routes={routes} />
									</GridLayout.Nav>
								)}
								<GridLayout.Header
									ref={callbackRef}
									backgroundColor='ambientLight'
								/>
								<GridLayout.Main
									marginBottom={
										store.Orchestrators.View.singleColumn
											? `${baseUnit * 16}px`
											: 'lg'
									}
								>
									<BusinessSetupBanner
										show={
											store.needsBusinessSetup &&
											!store.isLoading('getCompany') &&
											!store.Orchestrators.View.singleColumn
										}
									/>
									<PolicyWarningBanner
										show={
											!store.isLoading('getCompany') &&
											(isICSURL(store.userBusiness?.termsAndConditionsURL) ||
												isICSURL(store.userBusiness?.privacyPolicyURL)) &&
											!store.Orchestrators.View.singleColumn
										}
									/>
									<Switch>
										{routes.map((route, i) => (
											<Route key={i} {...route.route} />
										))}
									</Switch>
								</GridLayout.Main>
								{!store.Orchestrators.View.singleColumn && (
									<GridLayout.Footer
										backgroundColor='brandDark'
										textColor='bodyInverse'
										padding='xlg'
										display='flex'
										alignItems='center'
									>
										<Div
											display={['block', 'flex']}
											alignItems='center'
											justifyContent='space-between'
											elWidth={['100%', '75%', '50%']}
											typoSize='.9em'
										>
											<Typography.A
												href='https://support.icecreamsocial.io/'
												target='__blank'
												textColor='bodyInverse'
												visitedTextColor='bodyInverse'
											>
												Support
											</Typography.A>
											<Typography.A
												href='https://support.icecreamsocial.io/support/solutions/articles/66000243293-getting-started-best-practice-for-integrations-'
												target='__blank'
												textColor='bodyInverse'
												visitedTextColor='bodyInverse'
											>
												Getting Started
											</Typography.A>
											<Typography.Span typoWeight='lighter'>|</Typography.Span>
											<Typography.A
												href='https://icecreamsocial.io/privacy-policy-terms/'
												target='__blank'
												textColor='bodyInverse'
												visitedTextColor='bodyInverse'
											>
												Privacy Policy
											</Typography.A>
											<Typography.A
												href='https://icecreamsocial.io/terms-of-service/'
												target='__blank'
												textColor='bodyInverse'
												visitedTextColor='bodyInverse'
											>
												Terms &amp; Conditions
											</Typography.A>
											<Typography.Span typoWeight='lighter'>|</Typography.Span>
											<Typography.A
												href='https://icecreamsocial.releasenotes.io/'
												target='__blank'
												textColor='bodyInverse'
												visitedTextColor='bodyInverse'
											>
												Updates
											</Typography.A>
										</Div>
										<Typography.Span
											typoSize='sm'
											flexGrow='1'
											textAlign='right'
										>
											&copy; Ice Cream Social
										</Typography.Span>
									</GridLayout.Footer>
								)}
							</GridLayout>
						</HeaderPortalContext.Provider>
					</ConditionalRoute>
				</Switch>
			</ModalProvider>
		)
	);
};

Main.displayName = 'MainLayout';

const useHeaderPortal = () => {
	return (children) => {
		const { portalRef } = useContext(HeaderPortalContext);
		return portalRef.current && createPortal(children, portalRef.current);
	};
};
export default observer(Main);

export const HeaderPortal = observer(({ children }) => {
	const renderPortal = useHeaderPortal();
	return renderPortal(children);
});
