import React from 'react';
import { Helmet } from 'react-helmet';
import favicon16x16 from '../../../assets/favicon/favicon-16x16.png';
import favicon32x32 from '../../../assets/favicon/favicon-32x32.png';
import faviconApple from '../../../assets/favicon/apple-touch-icon.png';

const Favicon = () => (
	<Helmet>
		<link rel="apple-touch-icon" sizes="180x180" href={faviconApple} />
		<link rel="icon" type="image/png" sizes="32x32" href={favicon32x32} />
		<link rel="icon" type="image/png" sizes="16x16" href={favicon16x16} />
	</Helmet>
);

export default Favicon;
