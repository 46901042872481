import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Panel, Typography } from '@icecreamsocial/components';

import { useUserSettingsForm } from '..';
import { AffiliateSelectorToggle, ClientSelectorToggle } from './components';
/**
 * @module UserRole
 * @category Components
 * @subcategory Presentational
 * @requires module:UserSettingsForm
 */

/**
 * @name UserRole
 * @description
 * A set of inputs for choosing a user's role
 */

const UserRole = ({ userAffiliateId }) => {
	const { form, isEditing } = useUserSettingsForm();
	// @todo always show if superadmin
	const showAffiliateSelector = useMemo(
		() => !isEditing || form.initialValues.role === 'affiliate',
		[isEditing, form.initialValues.role]
	);

	const showClientSelector = useMemo(
		() => !isEditing || form.initialValues.role === 'client',
		[isEditing, form.initialValues.role]
	);

	return (
		<Panel
			data-cy='userRolePanel'
			backgroundColor='bodyInverse'
			marginBottom='lg'
		>
			<Panel.Header
				backgroundColor='bodyInverse'
				textColor='body'
				paddingBottom='0'
			>
				<Typography.H2
					textColor='body'
					textAlign='center'
					margin='none'
					typoSize='md'
				>
					User Role
				</Typography.H2>
				<Typography.P>
					Determines what a user can do and access throughout the admin. This
					can't be changed later.
				</Typography.P>
			</Panel.Header>
			<Panel.Body>
				{showAffiliateSelector && <AffiliateSelectorToggle marginBottom='md' />}
				{showClientSelector && (
					<ClientSelectorToggle affiliateId={userAffiliateId} />
				)}
			</Panel.Body>
		</Panel>
	);
};
UserRole.displayName = 'UserSettingsForm.UserRole';

export default observer(UserRole);
