import React, { useRef } from 'react';
import { observer } from 'mobx-react-lite';
import {
	Button,
	Div,
	InputGroup,
	Typography,
} from '@icecreamsocial/components';
import { Formik, useFormik } from 'formik';
import { Checkbox } from '@material-ui/core';
import { ClientSelector } from '../../containers';

/**
 * @module CampaignsFilterForm
 * @category Components
 * @subcategory Presentational
 */

/**
 *
 * @param {object} props
 * @param {function} props.onSubmit the onSubmit function passed to useFormik
 * @param {function} props.onReset the onReset function passed to useFormik
 * @param {string}	props.affiliateId is the auth user's affiliate id
 * @param {boolean}	[props.showClientFilter = true]
 * @param {object} props.initialValues
 * @param {boolean} [props.initialValues.active] is the campaign active
 * @param {boolean} [props.initialValues.disabled] is the campaign disabled
 * @param {boolean} [props.initialValues.archived] is the campaign archived
 */

const CampaignsFilterForm = ({
	showClientFilter = true,
	onSubmit,
	onReset,
	affiliateId,
	initialValues,
	...rest
}) => {
	const selectRef = useRef();
	/**
	 * for the filters
	 */
	const formikProps = {
		enableReinitialize: true,
		initialValues,
		onSubmit,
		onReset,
	};

	return (
		<Formik {...formikProps}>
			{(filtersForm) => (
				<form onSubmit={filtersForm.handleSubmit}>
					<Div
						display='flex'
						alignItems='center'
						justifyContent='space-between'
					>
						<Typography.H2 typoWeight='500'>Filters</Typography.H2>
						<Button
							onClick={() => {
								filtersForm.handleReset();
								selectRef.current?.clearSelect();
							}}
							typoSize='small'
							textDecoration='underline'
							border='none'
							shadow='none'
							hoverShadow='none'
							padding='sm'
							backgroundColor='transparent'
						>
							Reset filters
						</Button>
					</Div>
					<Div as='fieldset' border='none' elWidth='100%'>
						<Typography.Span
							display='block'
							typoWeight='500'
							typoSize='.9em'
							marginBottom='md'
						>
							Status
						</Typography.Span>
						<InputGroup display='flex' alignItems='center'>
							<Checkbox
								color='primary'
								name='disabled'
								value={filtersForm.values.disabled}
								checked={filtersForm.values.disabled}
								onChange={filtersForm.handleChange}
							/>{' '}
							<InputGroup.Label display='inline-block'>
								Disabled
							</InputGroup.Label>
						</InputGroup>
						<InputGroup display='flex' alignItems='center'>
							<Checkbox
								color='primary'
								name='archived'
								value={filtersForm.values.archived}
								checked={filtersForm.values.archived}
								onChange={filtersForm.handleChange}
							/>{' '}
							<InputGroup.Label display='inline-block'>
								Archived
							</InputGroup.Label>
						</InputGroup>
					</Div>

					{showClientFilter && (
						<InputGroup elWidth='100%' marginBottom='sm'>
							<InputGroup.Label>Client</InputGroup.Label>
							<ClientSelector
								ref={selectRef}
								name='clientSearch'
								placeholder='All clients'
								affiliateId={affiliateId}
								paddingLeft='md'
							/>
						</InputGroup>
					)}

					<Button
						type='submit'
						disable={!filtersForm.isValid}
						elWidth='100%'
						textColor='bodyInverse'
						backgroundColor='brand'
						padding='sm'
					>
						Apply Filters
					</Button>
				</form>
			)}
		</Formik>
	);
};

CampaignsFilterForm.displayName = 'CampaignsFilterForm';
export default observer(CampaignsFilterForm);
