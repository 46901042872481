import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import {
	Button,
	Div,
	Img,
	Panel,
	Typography,
	Spanner,
	Container,
} from '@icecreamsocial/components';
import BusinessSetup from '../BusinessSetup';

const BusinessSetupBanner = ({ show = false }) => {
	return (
		show && (
			<Spanner
				backgroundColor="accentLight"
				position="sticky"
				top="0"
				marginBottom="xlg"
			>
				<Container>
					<BusinessSetup />
				</Container>
			</Spanner>
		)
	);
};

BusinessSetupBanner.displayName = 'BusinessSetupBanner';
export default BusinessSetupBanner;
