import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { Typography } from '@icecreamsocial/components';
import { useStore } from '../../../../../utils/hooks';
import { UsersTable, Pagename } from '../../../../presentational';

const Users = () => {
	const store = useStore();
	const { clientId } = useParams();
	const { affiliateId } = store.Orchestrators.Focused.client || {};
	useEffect(() => {
		store.getUsersFlow({ affiliateId, clientSearch: clientId });
	}, [affiliateId, clientId]);

	return (
		<>
			<Pagename>Users</Pagename>
			<UsersTable collection={store.Collections.Users.list.toJS()} />
		</>
	);
};
Users.displayName = 'Affiliates.Affiliate.User';

export default observer(Users);
