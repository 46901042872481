import React from 'react';
import { observer } from 'mobx-react-lite';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { Panel, Typography } from '@icecreamsocial/components';
import { FormikProps } from 'formik';
import { useUserSettingsForm } from '..';
/**
 * @module TeamLeader
 * @category Components
 * @subcategory Presentational
 * @requires module:UserSettingsForm
 */

/**
 * @name TeamLeader
 * @description
 * A set toggle for whether or not this user should be a team leader
 */

const TeamLeader = () => {
	const { form } = useUserSettingsForm();

	return (
		<Panel backgroundColor="bodyInverse" marginBottom="lg">
			<Panel.Header
				backgroundColor="bodyInverse"
				textColor="body"
				paddingBottom="0"
			>
				<Typography.H2
					textColor="body"
					textAlign="center"
					margin="none"
					typoSize="md"
				>
					Team Leader
				</Typography.H2>
				<Typography.P>
					Team Leaders have the ability to create and modify users allowing both
					affiliates and clients to manage their own teams
				</Typography.P>
			</Panel.Header>
			<Panel.Body>
				<Panel
					border={form.values.isTeamAdmin ? 'default' : 'none'}
					backgroundColor={form.values.isTeamAdmin ? undefined : 'ambientLight'}
				>
					<Panel.Body>
						<FormControlLabel
							control={
								<Checkbox
									id="isTeamAdmin"
									name="isTeamAdmin"
									checked={form.values.isTeamAdmin}
									value={form.values.isTeamAdmin}
									onChange={form.handleChange}
									color="primary"
								/>
							}
							label={
								<Typography.Span typoWeight="bold">Team Leader</Typography.Span>
							}
						/>
						<Typography.P>
							This user{' '}
							<Typography.Span typoWeight="bold">
								{form.values.isTeamAdmin ? 'does' : 'does not'}
							</Typography.Span>{' '}
							have permission to create and modify users
						</Typography.P>
					</Panel.Body>
				</Panel>
			</Panel.Body>
		</Panel>
	);
};
TeamLeader.displayName = 'UserSettingsForm.TeamLeader';

export default observer(TeamLeader);
