import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Div, Typography } from '@icecreamsocial/components';
import { useStore } from '../../../../../../../utils/hooks';
import {
	PolicySettingsForm,
	Pagename,
	SteppedFormControls,
} from '../../../../../../presentational';

const PolicySettings = ({
	disabled = false,
	isLoading = false,
	savedValues = {},
	returnTo,
	handleSubmit,
}) => {
	return (
		<Div>
			<Pagename>Data & Policy Settings</Pagename>
			<PolicySettingsForm
				onSubmit={handleSubmit}
				renderControls={() => (
					<SteppedFormControls
						onNav={returnTo}
						last={true}
						disabled={disabled}
						isLoading={isLoading}
					/>
				)}
				{...savedValues}
			/>
		</Div>
	);
};
PolicySettings.displayName = 'Campaigns.Campaign.Settings.Policy';

export default observer(PolicySettings);
