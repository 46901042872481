import React, { useEffect } from 'react';
import { IcsV1 } from '@icecreamsocial/api-resources';
import { useRequestInterceptor } from './useRequestInterceptor';
import { useResponseInterceptor } from './useResponseInterceptor';
import { useStore } from './useStore';

/**
 * @module useRequestThrottle
 * @category Shared
 * @subcategory Hooks
 * @exports  useRequestThrottle
 */

/**
 * @function
 * @deprecated
 * @param {*} onRequest
 * @param {*} onError
 */
export const useRequestThrottle = (
	onRequest = undefined,
	onError = (err) => {}
) => {
	const store = useStore();
	const INTERVAL = 100;
	const MAX = 5;
	let requestCount = 0;
	return {
		makeRequestInterceptor: (onReq, onErr) =>
			useRequestInterceptor(
				(req) =>
					new Promise((resolve, reject) => {
						console.group('makeRequest');
						console.debug(requestCount, Date.now());
						console.debug(req.url);
						let interval = setInterval(() => {
							// if (requestCount < MAX) {
							if (store.userBusiness) {
								requestCount++;
								clearInterval(interval);
								resolve(req);
							}
						}, INTERVAL);
						console.groupEnd();
					})
			),
		makeResponseInterceptor: (onRes, onErr) =>
			useResponseInterceptor((res) => {
				requestCount = Math.max(0, requestCount - 1);
				return Promise.resolve(res);
			}, onErr),
	};
};
