import React, { useImperativeHandle, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Div, Select, Typography } from '@icecreamsocial/components';
import { useStore, useTheme } from '../../../utils/hooks';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../../../queries/campaigns';
import { useField, useFormikContext } from 'formik';
import styled from 'styled-components';

const OptionContainer = styled(Div)`
	&:hover {
		background-color: ${({ theme }) => theme.colors.brandLight};
	}
`;

const CampaignOption = ({ data, innerProps, theme, ...rest }) => (
	<OptionContainer
		display='flex'
		alignItems='center'
		justifyContent='space-between'
		padding='sm'
		paddingLeft='md'
		{...innerProps}
		{...rest}
	>
		<Typography.Span
			flexGrow={1}
			style={{
				overflow: 'hidden',
				whiteSpace: 'nowrap',
				textOverflow: 'ellipsis',
			}}
		>
			{data.label}
		</Typography.Span>
		<Typography.Span textColor='ambientDark'>#{data.value}</Typography.Span>
	</OptionContainer>
);

/**
 * @note Use within Formik component.
 */

const CampaignSelector = (
	{
		isMulti = false,
		queryWhen = true,
		name,
		affiliateId,
		clientId,
		clientSearch,
		searchText,
		placeholder,
		...rest
	},
	ref
) => {
	const { getCampaigns } = useStore();
	const formik = useFormikContext();
	const [field, meta, helpers] = useField(name);

	/**
	 * since the label is not present when the default value is being set, we update the default value
	 * once the data has been fetched
	 */
	const getDefault = (options) => {
		if (!options) return;

		const def = options.filter(
			({ value: optValue }) =>
				optValue.toString() === formik.initialValues[name]?.value
		)?.[0];

		helpers.setValue(def);
		// don't want to count this as the input being touched
		helpers.setTouched(false);
	};

	const { data, isLoading } = useQuery(
		queryKeys.getCampaigns({ affiliateId, clientId, clientSearch, searchText }),
		async () => {
			const { campaigns } = await getCampaigns({
				affiliateId,
				clientId,
				clientSearch,
				searchText,
			});

			const options = campaigns?.map(({ id: value, name: label }) => ({
				label,
				value,
			}));

			getDefault(options);

			return options;
		},
		{ refetchOnWindowFocus: false, enabled: !!queryWhen }
	);

	/**
	 * imperatively set the field value on change
	 */
	const onChange = (value) => helpers.setValue(value);

	/**
	 * imperatively set the field to touched on blur
	 */
	const onBlur = () => helpers.setTouched(true);

	/**
	 * expose the `clearSelect` method on the ref of this component
	 */
	useImperativeHandle(ref, () => ({
		clearSelect: () => {
			helpers.setValue(isMulti ? [] : null);
		},
	}));

	return isLoading ? (
		<Div optimistic padding='12px' borderRadius='default'>
			<Typography.P margin='0'>&nbsp;</Typography.P>
		</Div>
	) : (
		<Select
			{...field}
			components={{ Option: CampaignOption }}
			placeholder={data.length ? placeholder : 'No items'}
			isLoading={isLoading}
			isDisabled={isLoading}
			onChange={onChange}
			onBlur={onBlur}
			options={data}
			{...rest}
		/>
	);
};
CampaignSelector.displayName = 'CampaignSelector';

export default observer(CampaignSelector, { forwardRef: true });
