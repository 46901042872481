import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Div, Panel, Typography } from '@icecreamsocial/components';
import { useStore } from '../../../../../../../../../utils/hooks';
import GeneralSettingsForm from '../../../../../../components/GeneralSettingsForm';
import {
	EditFormControls,
	Pagename,
} from '../../../../../../../../presentational';
import { useCampaign } from '../../../../providers/Campaign';

const GeneralSettings = () => {
	const store = useStore();
	const { campaign, update } = useCampaign();
	const { data } = campaign;

	const initialValues = {
		campaignName: data?.name,
		locale: data?.locale || store.Orchestrators.Auth.locale,
		fromEmail: data?.fromEmail,
		fromName: data?.fromName,
	};

	const activeStatusToggle = (truthy, falsy) =>
		data?.status?.toLowerCase() === 'active' ? truthy : falsy;

	return (
		<Div>
			<Pagename>General</Pagename>
			{data && (
				<>
					{data.isArchived && (
						<Panel
							borderColor='warning'
							marginBottom='lg'
							flexDirection='row'
							alignItems='center'
						>
							<Panel.Body>
								<Typography.H2 marginTop='0' typoSize='md'>
									This campaign is archived.
								</Typography.H2>
								<Typography.P>
									You can unarchive this campaign by clicking here.
								</Typography.P>
							</Panel.Body>
							<Panel.Footer flexShrink='0'>
								<Button
									onClick={(e) => {
										update.mutate({ isArchived: false });
									}}
								>
									Unarchive Campaign
								</Button>
							</Panel.Footer>
						</Panel>
					)}
					{!data.isArchived && (
						<>
							<GeneralSettingsForm
								onSubmit={({
									campaignName: name,
									locale,
									fromEmail,
									fromName,
								}) => {
									update.mutate({
										name,
										fromEmail,
										locale,
										fromName,
									});
								}}
								renderControls={(props) => <EditFormControls {...props} />}
								{...initialValues}
							/>
							<Panel
								backgroundColor='ambient'
								marginBottom='lg'
								flexDirection='row'
								alignItems='center'
							>
								<Panel.Body>
									<Typography.H2 marginTop='0' typoSize='md'>
										{activeStatusToggle('Disable', 'Activate')}
									</Typography.H2>
									<Typography.P>
										{activeStatusToggle(
											"Referrals won't be tracked for disabled campaigns. You can reactivate it at any time.",
											'Activating this campaign will enable referral tracking.'
										)}
									</Typography.P>
								</Panel.Body>
								<Panel.Footer flexShrink='0'>
									<Button
										onClick={(e) => {
											update.mutate({
												status: activeStatusToggle('disabled', 'active'),
											});
										}}
									>
										{activeStatusToggle(
											'Disable Campaign',
											'Activate campaign'
										)}
									</Button>
								</Panel.Footer>
							</Panel>
							<Panel
								backgroundColor='ambient'
								marginBottom='lg'
								flexDirection='row'
								alignItems='center'
							>
								<Panel.Body>
									<Typography.H2 marginTop='0' typoSize='md'>
										Archive
									</Typography.H2>
									<Typography.P>
										You can archive a campaign when you're done with it.
										Archived campaigns can be restored at any point in time.
									</Typography.P>
								</Panel.Body>
								<Panel.Footer flexShrink='0'>
									<Button
										onClick={(e) => {
											update.mutate({ isArchived: true });
										}}
									>
										Archive Campaign
									</Button>
								</Panel.Footer>
							</Panel>
						</>
					)}
					{/**
						<Panel
						backgroundColor="ambient"
						marginBottom="lg"
						flexDirection="row"
						alignItems="center"
					>
						<Panel.Body>
							<Typography.H2 marginTop="0" typoSize="md" textColor="bad">
								Delete
							</Typography.H2>
							<Typography.P>
								Deleting a campaign is permanent, it won't be able to be
								recovered by anyone
							</Typography.P>
						</Panel.Body>
						<Panel.Footer flexShrink="0">
							<Button
								onClick={e => {
									store.deleteCampaignFlow(undefined, () => {
										history.push('/campaigns');
									});
								}}
								textColor="bodyInverse"
								backgroundColor="bad"
							>
								Delete Campaign
							</Button>
						</Panel.Footer>
					</Panel>
				 */}
				</>
			)}
		</Div>
	);
};
GeneralSettings.displayName = 'Campaigns.Campaign.Settings.General';

export default observer(GeneralSettings);
