import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import * as Yup from 'yup';
import {
	Div,
	Typography,
	List,
	InputGroup,
	Panel,
	Button,
	Spanner,
} from '@icecreamsocial/components';
import {
	ArrowBackIcon,
	ConfirmationPrompt,
	EditFormControls,
	EnhancedForm,
} from '../../../presentational';
import { Link } from 'react-router-dom';
import EditPane from '../EditPane';

const ProfileEdit = ({
	name = '',
	email = '',
	phone = '',
	handleSubmit,
	onRemove,
}) => {
	const [showConfirmation, setShowConfirmation] = useState(false);
	const cancelRemove = () => setShowConfirmation(false);
	const promptRemoval = () => setShowConfirmation(true);
	const confirmRemoval = async () => {
		await onRemove();
		setShowConfirmation(true);
	};
	// from https://www.sitepoint.com/community/t/phone-number-regular-expression-validation/2204
	const phoneRegEx = /^\+*(([1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
	const EditSchema = Yup.object().shape({
		email: Yup.string()
			.email('Please enter a valid email')
			.nullable()
			.default(''),
		phone: Yup.string()
			.matches(phoneRegEx, {
				message: 'Please enter a valid phone number',
				excludeEmptyString: true,
			})
			.nullable()
			.default(''),
		name: Yup.string().default(''),
	});

	const formikProps = {
		enableReinitialize: true,
		onSubmit: handleSubmit,
		validationSchema: EditSchema,
		initialValues: {
			email,
			phone,
			name,
		},
	};
	return (
		<EditPane>
			<ConfirmationPrompt
				when={showConfirmation}
				onConfirm={confirmRemoval}
				onCancel={cancelRemove}
				title='Remove influencer data?'
				message='This cannot be undone'
				confirmText='Yes, Remove Data'
				cancelText='No, Go Back'
				dangerous
			/>

			<Panel backgroundColor='bodyInverse' marginBottom='lg'>
				<Panel.Body>
					<Typography.H2 typoWeight='500' marginTop='0' textAlign='center'>
						Edit Profile
					</Typography.H2>
					<EnhancedForm
						formikProps={formikProps}
						promptIf={({ dirty }) => dirty}
						renderControls={(props) => (
							<EditPane.Controls>
								<EditFormControls position='sticky' {...props} />
							</EditPane.Controls>
						)}
					>
						{(influencerProfile) => (
							<>
								<InputGroup elWidth='100%' marginBottom='md'>
									<InputGroup.Label>Name</InputGroup.Label>
									<InputGroup.Fields paddingLeft='md'>
										<InputGroup.Input
											name='name'
											value={influencerProfile.values.name}
											onChange={influencerProfile.handleChange}
											autoFocus
										/>
									</InputGroup.Fields>
									{influencerProfile.errors.name && (
										<InputGroup.Label typoSize='sm' textColor='bad'>
											{influencerProfile.errors.name}
										</InputGroup.Label>
									)}
								</InputGroup>
								<InputGroup elWidth='100%' marginBottom='md'>
									<InputGroup.Label>Email</InputGroup.Label>
									<InputGroup.Fields paddingLeft='md'>
										<InputGroup.Input
											name='email'
											type='email'
											value={influencerProfile.values.email}
											onChange={influencerProfile.handleChange}
										/>
									</InputGroup.Fields>
									{influencerProfile.errors.email && (
										<InputGroup.Label typoSize='sm' textColor='bad'>
											{influencerProfile.errors.email}
										</InputGroup.Label>
									)}
								</InputGroup>
								<InputGroup elWidth='100%' marginBottom='md'>
									<InputGroup.Label>Phone</InputGroup.Label>
									<InputGroup.Fields paddingLeft='md'>
										<InputGroup.Input
											name='phone'
											type='tel'
											value={influencerProfile.values.phone}
											onChange={influencerProfile.handleChange}
										/>
									</InputGroup.Fields>
									{influencerProfile.errors.phone && (
										<InputGroup.Label typoSize='sm' textColor='bad'>
											{influencerProfile.errors.phone}
										</InputGroup.Label>
									)}
								</InputGroup>
							</>
						)}
					</EnhancedForm>
				</Panel.Body>
			</Panel>
			<Panel backgroundColor='bodyInverse'>
				<Panel.Body>
					<Typography.H3 textColor='bad' typoSize='md' typoWeight='500'>
						Remove Personal Information
					</Typography.H3>
					<Typography.P>
						This influencer's personal information can be{' '}
						<Typography.Span as='strong'>permanently</Typography.Span> removed
						and they will no longer be able to participate in this campaign and
						any earned rewards will be forfeited
					</Typography.P>
					<Typography.P>
						Anonymous activity and statistics will be retained for reporting
						purposes
					</Typography.P>
					<Button
						backgroundColor='bad'
						textColor='bodyInverse'
						elWidth='33%'
						onClick={promptRemoval}
					>
						Remove
					</Button>
				</Panel.Body>
			</Panel>
		</EditPane>
	);
};
ProfileEdit.displayName = 'InfluencerManager.ProfileEdit';

export default observer(ProfileEdit);
