import React from 'react';
import { observer } from 'mobx-react-lite';
import { Img, Div, Container, Typography } from '@icecreamsocial/components';
import icsShares from '../../../assets/ics-shares.png';

const NoReferralData = ({ headerText, bodyText }) => {
	return (
		<Container padding='md' backgroundColor='bodyInverse'>
			<Div textAlign='center'>
				<Img elWidth='40%' flexShrink='0' src={icsShares} />
			</Div>
			<Div textAlign='center' paddingTop='md'>
				<Typography.Span typoWeight='600'>{headerText}</Typography.Span>
				<Typography.P>{bodyText}</Typography.P>
			</Div>
		</Container>
	);
};

NoReferralData.displayName = 'NoReferralData';
export default observer(NoReferralData);
