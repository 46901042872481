// @ts-nocheck
import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Checkbox } from '@material-ui/core';
import {
	Button,
	Div,
	Typography,
	Tag,
	List,
	Circle,
	InputGroup,
} from '@icecreamsocial/components';
import { ChevronLeftIcon, ChevronRightIcon } from '../SupportedIcons';
import { formatDateTime } from '../../../utils';

/**
 * @typedef CondensedConversion
 * @property {string | number} revenue
 * @property {string} status
 * @property {string} id should be unique
 * @property {string} createdAt dateTime string
 * @property {string} influencerEmail
 */

/**
 * @callback OnConversionSelected
 * @param {CondensedConversion} conversion the selected conversion.
 * @return {*}
 */

/**
 * @callback MapCollectionToSelected
 * @param {CondensedConversion} conversion a conversion to compare to see if it should be selected
 * @return {boolean} whether or not to display
 */

/**
 * @typedef CondensedConversionProps
 * @property {Array.<CondensedConversion>} collection all conversions to display
 * @property {boolean | undefined | null} [selectable = undefined] whether or not to allow row selection
 * @property {MapCollectionToSelected} [mapCollectionToSelected] whether or not to allow row selection. required  when `selectable` is defined
 * @property {OnConversionSelected} [onSelect] must be specified if `selectable` is true
 */

/**
 * Displays a condensed list of Conversions
 * Can be made selectable.  Will function as an uncontrolled component.
 * @param {CondensedConversionProps} props
 * @returns {React.Component}
 */
const CondensedConversions = ({
	collection = [],
	mapCollectionToSelected,
	selectable,
	onSelect,
	...rest
}) => {
	const handleSelect = (conversion) => {
		return onSelect && onSelect(conversion);
	};
	/**
	 * this assumes the collection is not paginated.  consider moving this out and passing them as props.
	 * keeping this here to make it simple until we have a need for paginating large results
	 */
	const pagination = {
		total: collection.length,
		start: 1,
		end: collection.length,
	};

	return (
		!!collection.length && (
			<>
				<List bullets='none' borderTop='1px solid' borderColor='ambient'>
					{collection.map(
						({
							revenue = 0,
							status,
							id,
							createdAt,
							influencerEmail,
							conversionType,
							...extra
						}) => (
							<List.Item
								key={id}
								borderBottom='1px solid'
								borderColor='ambient'
								padding='md'
								marginBottom='0'
								display='flex'
								alignItems='center'
								style={{ gap: '16px' }}
							>
								{selectable && (
									<InputGroup>
										<Checkbox
											id={id}
											name={id}
											color='primary'
											disabled={status.toLowerCase() === 'canceled'}
											checked={mapCollectionToSelected({
												revenue,
												status,
												id,
												createdAt,
												influencerEmail,
												...extra,
											})}
											defaultChecked={false}
											onChange={(_) =>
												handleSelect({
													revenue,
													status,
													id,
													createdAt,
													influencerEmail,
													...extra,
												})
											}
											style={{ transform: 'scale(1.5)' }}
										/>
									</InputGroup>
								)}
								<Div flex='1 1'>
									<Div
										display='flex'
										alignItems='center'
										justifyContent='space-between'
										style={{ gap: '8px' }}
									>
										<Typography.H3
											title={influencerEmail}
											typoSize='md'
											typoWeight='500'
											margin='0'
											overflow='hidden'
											style={{ whiteSpace: 'noWrap', textOverflow: 'ellipsis' }}
										>
											{influencerEmail}
										</Typography.H3>
										<Typography.Span
											flexShrink='0'
											display='block'
											typoSize='md'
											typoWeight='500'
											textColor='ambientDark'
										>
											{formatDateTime(new Date(createdAt))}
										</Typography.Span>
									</Div>
									<Div
										display='flex'
										alignItems='center'
										justifyContent='space-between'
									>
										<Div
											display='flex'
											alignItems='center'
											marginTop='sm'
											marginBottom='sm'
											style={{ gap: '16px' }}
										>
											<Typography.Span>
												{new Intl.NumberFormat('en-US', {
													style: 'currency',
													currency: 'USD',
												}).format(revenue)}
											</Typography.Span>
										</Div>
										<Div
											textAlign='right'
											marginTop='sm'
											marginBottom='sm'
											display='flex'
											style={{ alignSelf: 'end', gap: '16px' }}
										>
											<Tag>{conversionType}</Tag>
											<Tag
												borderColor={
													status.toLowerCase() === 'confirmed' ? 'good' : 'bad'
												}
												textColor={
													status.toLowerCase() === 'confirmed' ? 'good' : 'bad'
												}
											>
												{status}
											</Tag>
										</Div>
									</Div>
								</Div>
							</List.Item>
						)
					)}
				</List>
				<Div
					elWidth='100%'
					backgroundColor='ambientLight'
					display='flex'
					alignItems='center'
					padding='md'
					borderBottomLeftRadius='default'
					borderBottomRightRadius='default'
				>
					<Typography.Span flex='1 0'>
						{pagination.start} - {pagination.end} of {pagination.total}{' '}
						referrals
					</Typography.Span>
					<Div display='flex' flex='0 1' style={{ gap: '8px' }}>
						<Circle
							as={Button}
							padding='sm'
							backgroundColor='transparent !important'
							textColor='body'
							shadow='none'
							disabled={pagination.start === 1}
						>
							<ChevronLeftIcon />
						</Circle>
						<Circle
							as={Button}
							padding='sm'
							backgroundColor='transparent !important'
							textColor='body'
							shadow='none'
							disabled={pagination.end === pagination.total}
						>
							<ChevronRightIcon />
						</Circle>
					</Div>
				</Div>
			</>
		)
	);
};

CondensedConversions.displayName = 'CondensedConversions';

export default observer(CondensedConversions);
