import React from 'react';
import {
	Div,
	MediaCard,
	List,
	Typography,
	Tag,
} from '@icecreamsocial/components';
import { Logo } from '..';
import { observer } from 'mobx-react-lite';

const OptimisticCampaignCards = ({ length = 6, ...rest }) => {
	const blanks = [];
	let i = 0;
	while (blanks.length < length) {
		blanks.push(i++);
	}

	return (
		<List bullets="none" display="flex" flexWrap="wrap" {...rest}>
			{blanks.map((i) => (
				<MediaCard
					key={i}
					whileHover="hover"
					gridItem
					elHeight="250px"
					position="relative"
					margin="0 8px"
					elWidth={['100%', `calc(50% - 16px)`, `calc(33% - 16px)`]}
				>
					<MediaCard.ImageContainer
						optimistic
						elHeight="150px"
						style={{
							'clip-path': 'polygon(0 0, 100% 0, 100% 100%, 0 80%)',
						}}
					/>
					{/* <Logo
					src={img}
					position="absolute"
					elWidth="50px"
					elHeight="50px"
					positionTop="100px"
					positionLeft="32px"
					shadow="default"
					backgroundColor="white"
				/> */}
					<MediaCard.Content>
						<Div>
							<Typography.Span
								optimistic
								display="block"
								marginBottom="md"
								elWidth="80px"
								elHeight="1em"
								borderRadius="default"
							/>
							<Tag optimistic />
						</Div>
					</MediaCard.Content>
				</MediaCard>
			))}
		</List>
	);
};

OptimisticCampaignCards.displayName = 'OptimisticCampaignCards';
export default observer(OptimisticCampaignCards);
