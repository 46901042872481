import React, { useLayoutEffect } from 'react';

/**
 * Only enabled on NODE_ENV === production
 * @param {*} props
 * @param {string} props.containerId the id of the tag manager container
 */
const GTM = ({ containerId }) => {
	// using useLayoutEffect because we want to load the script as soon as possible
	useLayoutEffect(() => {
		if (process.env.NODE_ENV !== 'production') return;

		const gtmSnippet = document.createElement('script');
		gtmSnippet.text = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${containerId}');
    `;

		document.head.appendChild(gtmSnippet);
	}, []);
	return (
		<noscript>
			<iframe
				src={`https://www.googletagmanager.com/ns.html?id=${containerId}`}
				height="0"
				width="0"
				style="display:none;visibility:hidden"
			></iframe>
		</noscript>
	);
};

export default GTM;
