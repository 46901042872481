import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import {
	Div,
	Button,
	InputGroup,
	List,
	Panel,
	Typography,
	Thumbnail,
} from '@icecreamsocial/components';
import { useFormik } from 'formik';
import { RequiredAsterisk } from '../';

const ProcessorPanel = ({
	name,
	description,
	isActivated = false,
	isFocused = false,
	handleChangeProcessor = (bool) => undefined,
	logoSrc = undefined,
	children,
}) => {
	const [expandIfConnected, setExpandIfConnected] = useState(isFocused);

	useEffect(() => setExpandIfConnected(isFocused), [isFocused]);

	const onChangeProcessor = (expand) => {
		setExpandIfConnected(expand);
		handleChangeProcessor(expand);
	};

	return (
		<Panel
			backgroundColor="bodyInverse"
			marginBottom="lg"
			shadow="none"
			style={{ overflow: 'hidden' }}
		>
			<Panel.Header
				display="flex"
				alignItems="center"
				textColor="body"
				backgroundColor="bodyInverse"
			>
				{logoSrc && (
					<Thumbnail src={logoSrc} marginRight="md" elHeight="60px" />
				)}
				<Div flexGrow="1">
					<Typography.Span typoWeight="bold">{name}</Typography.Span>
					<Typography.P marginBottom="0">{description}</Typography.P>
				</Div>
				{!isActivated &&
					(expandIfConnected ? (
						<Button onClick={() => onChangeProcessor(false)}>
							Change Processor
						</Button>
					) : (
						<Button
							backgroundColor="brand"
							textColor="bodyInverse"
							onClick={() => onChangeProcessor(true)}
						>
							Connect
						</Button>
					))}
			</Panel.Header>
			{expandIfConnected && (
				<Panel.Body borderTop="1px solid" borderColor="ambient">
					{children}
				</Panel.Body>
			)}
		</Panel>
	);
};
ProcessorPanel.displayName = 'BusinessProcessorPanelForm';

export default observer(ProcessorPanel);
