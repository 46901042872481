import React from 'react';
import { observer } from 'mobx-react-lite';
import DataTable from 'react-data-table-component';
import { Div, Panel, Typography } from '@icecreamsocial/components';
import { PopsicleLoader } from '..';

const Table = ({ columns = [], collection = [], ...rest }) =>
	collection && (
		<Panel marginBottom="xlg" style={{ overflow: 'hidden' }} data-cy="table">
			<DataTable
				columns={columns}
				data={collection}
				highlightOnHover
				pagination
				noHeader
				progressComponent={
					<Div
						display="flex"
						padding="xlg"
						elMinHeight="300px"
						alignItems="center"
						justifyContent="center"
						flexDirection="column"
					>
						<PopsicleLoader />
						<Typography.P margin="none" textColor="ambientDark">
							Just a moment...
						</Typography.P>
					</Div>
				}
				{...rest}
			/>
		</Panel>
	);
Table.displayName = 'Table';

export default observer(Table);
