import { useRef } from 'react';

/**
 * Use to copy text.  Attach ref to component with text and pass in callback when copy is executed
 * @param {function} onCopy
 * @returns
 */
export const useCopyRef = (onCopy = () => null) => {
	const ref = useRef(null);
	/**
	 * simple memoized implementation of copying based on https://www.w3schools.com/howto/howto_js_copy_clipboard.asp
	 * @param {function} onCopy callback once copy is called
	 */
	const copy = () => {
		ref.current.select();
		ref.current.setSelectionRange(0, 99999); // for mobile

		document.execCommand('copy');
		onCopy(ref.current.value);
	};

	return { ref, copy };
};
