import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import {
	Button,
	Div,
	InputGroup,
	Thumbnail,
	Typography,
} from '@icecreamsocial/components';
import { useFormik } from 'formik';
import { Radio } from '@material-ui/core';
import { Icon } from 'react-icons-kit';
import { ic_arrow_drop_down } from 'react-icons-kit/md/ic_arrow_drop_down';
import { ic_arrow_drop_up } from 'react-icons-kit/md/ic_arrow_drop_up';
import logoShopifySvg from '../../../assets/logo_shopify.svg';
import logoBigCommerce from '../../../assets/logo_bigcommerce.png';
import logoWoocommerceSvg from '../../../assets/logo_woocommerce.svg';
import logoTicketSocketSvg from '../../../assets/logo_ticketsocket.png';
import logoMagento from '../../../assets/logo_magento.svg';
import logoSquarespace from '../../../assets/logo_squarespace.svg';
import logoSquare from '../../../assets/logo_square.svg';
import { RequiredAsterisk, DropDownIcon, DropUpIcon } from '../';

const ECommerceOptions = [
	{ value: 'square', label: 'Square Online', logo: logoSquare },
	{ value: 'shopify', label: 'Shopify', logo: logoShopifySvg },
	{ value: 'woocommerce', label: 'WooCommerce', logo: logoWoocommerceSvg },
	{ value: 'ticketsocket', label: 'TicketSocket', logo: logoTicketSocketSvg },
	{
		value: 'bigcommerce',
		label: 'BigCommerce',
		logo: logoBigCommerce,
	},
	{ value: 'magento', label: 'Magento', logo: logoMagento },
	{ value: 'squarespace', label: 'Squarespace', logo: logoSquarespace },
];

const RadioContainer = styled(Div).attrs({
	position: 'absolute',
	positionTop: '4px',
	positionRight: '4px',
	zIndex: '2',
})`
	& .MuiSvgIcon-root {
		font-size: 2rem;
	}
	& .MuiIconButton-label {
		background-color: #ffffff;
		border-radius: 50%;
	}
`;

const ECommerceRadioGroup = ({ name, value: selected, onChange }) => {
	const [showOtherField, setShowOtherField] = useState(false);

	return (
		<>
			<Div display="flex" alignItems="center" flexWrap="wrap">
				{ECommerceOptions.map(({ value, label, logo }, index) => (
					<InputGroup key={index} margin="sm">
						<InputGroup.Fields
							border="3px solid"
							borderColor="transparent"
							display="flex"
							position="relative"
							flexDirection="column"
							alignItems="center"
							justifyContent="center"
							padding="lg"
						>
							<Thumbnail
								src={logo}
								elHeight="125px"
								elWidth="125px"
								backgroundColor="white"
							/>
							<InputGroup.Label>{label}</InputGroup.Label>
							<RadioContainer>
								<Radio
									checked={selected === value}
									id={name}
									name={name}
									value={value}
									onChange={onChange}
									color="primary"
								/>
							</RadioContainer>
						</InputGroup.Fields>
					</InputGroup>
				))}
			</Div>
			<Div>
				<Button
					type="button"
					role="button"
					onClick={() => setShowOtherField(!showOtherField)}
					display="flex"
					alignItems="center"
					marginTop="md"
					shadow="none"
					hoverShadow="none"
					border="none"
					backgroundColor="bodyInverse"
					padding="sm"
				>
					{showOtherField ? <DropUpIcon /> : <DropDownIcon />}
					<Typography.Span>Don't see your service listed?</Typography.Span>
				</Button>
				{showOtherField && (
					<InputGroup elWidth="100%">
						<InputGroup.Label>
							Other eCommerce Solution <RequiredAsterisk />
						</InputGroup.Label>
						<InputGroup.Fields>
							<InputGroup.Input
								id={name}
								name={name}
								value={selected}
								onChange={onChange}
								paddingLeft="md"
							/>
						</InputGroup.Fields>
					</InputGroup>
				)}
			</Div>
		</>
	);
};
ECommerceRadioGroup.displayName = 'ECommerceRadioGroup';

export default observer(ECommerceRadioGroup);
