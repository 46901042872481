import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
/**
 * @module ConditionalRoute
 * @category Components
 * @subcategory Containers
 */
/**
 * @lib_candidate
 * @todo document this
 */
const ConditionalRoute = ({
	path,
	redirectPath,
	shouldRedirect = false,
	children = null,
	...rest
}) => {
	const location = useLocation();
	const redirect = {
		pathname: redirectPath,
		state: { from: location },
	};

	return (
		<Route path={path} {...rest}>
			{shouldRedirect ? <Redirect to={redirect} /> : children}
		</Route>
	);
};

export default observer(ConditionalRoute);
