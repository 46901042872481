import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Div, Button, Typography, Tag } from '@icecreamsocial/components';
import { useStore } from '../../../utils/hooks';
import { formatRelative } from 'date-fns';
//TODO remove store jocelyn

/**
 * @module SquareConnectionStaus
 * @category Components
 * @subcategory Presentational
 */

/**
 * @description
 * Shows the status of a Stripe processor
 * @todo show prompt if trying to disconnect square
 */
const StripeConnectionStatus = ({ onDisconnect }) => {
	const store = useStore();

	return (
		<Div display="flex" alignItems="center">
			<Tag
				textColor={
					store.userProcessor?.status?.toLowerCase() === 'active'
						? 'good'
						: 'bad'
				}
				borderColor={
					store.userProcessor?.status?.toLowerCase() === 'active'
						? 'good'
						: 'bad'
				}
				marginRight="lg"
				textTransform="capitalize"
			>
				{store.userProcessor?.status}
			</Tag>
			<Div flexGrow="1">
				<Typography.Span display="block" typoWeight="bold">
					Last updated
				</Typography.Span>
				<Typography.Span>
					{formatRelative(
						new Date(store.userProcessor?.updatedAt.raw),
						new Date()
					)}
				</Typography.Span>
			</Div>
			<Button
				onClick={onDisconnect}
				backgroundColor="ambientLight"
				padding="sm"
				paddingLeft="lg"
				paddingRight="lg"
			>
				Disconnect
			</Button>
		</Div>
	);
};
StripeConnectionStatus.displayName = 'StripeConnectionStatus';

export default observer(StripeConnectionStatus);
