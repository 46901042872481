import React from 'react';
import { usePagination } from '../../../../../../../../../../../utils/hooks';
import {
	ConversionsTableNew,
	NoFilterData,
	NoReferralData,
} from '../../../../../../../../../../presentational';
import { useReferralFilters } from '../FilterProvider';
import { flags } from '../../../../../../../../../../../utils';

export const Table = ({ referrals, isLoading = true, error = undefined }) => {
	const { state, update, clear } = useReferralFilters();
	const [getPagination] = usePagination({
		limit: state.limit || 10,
	});

	/**
	 * clear filters for NoFilteredData
	 */
	const clearControls = () => {
		clear('page', 'limit', 'skip');
	};
	/**
	 * @returns length of filters to be counted for NoData result
	 */
	const filterCount = () => {
		const skipFilters = ['page', 'limit', 'skip'];
		const queries = Object.keys({ ...state });
		const result = queries.filter((query) => !skipFilters.includes(query));
		return result.length;
	};

	/**
	 * control pagination on table component
	 */
	const paginationConfig = {
		paginationServer: true,
		paginationDefaultPage: state.page || 1,
		paginationTotalRows: referrals?.count,
		paginationPerPage: state.limit || 10,
		onChangePage: (next) => {
			const { skip, page } = getPagination({
				page: next,
			});
			update({ skip, page });
		},
		onChangeRowsPerPage: (currentRowsPerPage, page) => {
			const { skip, limit } = getPagination({
				page,
				limit: currentRowsPerPage,
			});
			update({ skip, limit });
		},
	};

	return error ? (
		error?.message
	) : (
		<ConversionsTableNew
			collection={referrals?.rows}
			progressPending={isLoading}
			noDataComponent={
				flags.isActive(flags.names.ENABLE_CUSTOM_NO_DATA_RESPONSE) ? (
					filterCount() > 0 ? (
						<NoFilterData resetFilter={clearControls} />
					) : (
						<NoReferralData
							headerText='No referral data yet'
							bodyText="It looks like we don't have any data to show yet! As soon as your campaigns start running and influencers begin their magic, you'll see referral details here"
						/>
					)
				) : (
					undefined
				)
			}
			{...paginationConfig}
		/>
	);
};
