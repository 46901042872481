/**
 * map containing the format of ecommerce specific variables
 */
const DATA_LAYER = {
	shopify: {
		accountId: '{{checkout.customer.id}}',
		orderId: '{{checkout.order_number}}',
		transactionId: '{{transactions[0].receipt.transaction_id}}',
		email: '{{checkout.email}}',
		mobileNumber: '{{checkout.customer.phone}}',
		subtotal:
			'{{ checkout.line_items_subtotal_price | money_without_currency }}',
		name: '{{checkout.customer.first_name}} {{checkout.customer.last_name}}',
	},
	square: {
		orderId: '{txid}',
		transactionId: '{orderid}',
		email: '{email}',
		subtotal: '{subtotal}',
		name: '{name}',
	},
	undefined: {
		orderId: 'REPLACE_ORDER_ID',
		transactionId: 'REPLACE_TRANSACTION_ID',
		email: 'REPLACE_EMAIL',
		subtotal: 'REPLACE_SUBTOTAL',
		name: 'REPLACE_NAME',
	},
};

/**
 * Formats the version specific config
 * @param {ScriptGen.MakeScriptOpts} options
 * @returns
 */
const formatConfig = ({
	requiresData,
	version,
	publicKey,
	campaignId,
	locale,
	theme,
	behavior,
	platform,
	engagementType,
}) => {
	const base = {
		version,
		publicKey,
		campaignId,
	};

	// we only have specific datalayer examples for shopify and square
	const mappedPlatform =
		platform === 'shopify' || platform === 'square' ? platform : undefined;

	const data = requiresData
		? version === 'latest'
			? {
					engagementType: engagementType || undefined,
					...DATA_LAYER[mappedPlatform],
			  }
			: {}
		: undefined;

	return version === 'latest'
		? {
				...base,
				locale,
				theme,
				behavior,
				data,
		  }
		: base;
};

/**
 * Generates the v2 script code as a string
 * @param {ScriptGen.MakeScriptOpts} options
 * @returns string
 */
const generateV2Script = (options) => {
	const config = formatConfig(options);

	const code = removeQuotesFromJSONKeys(
		JSON.stringify(config, null, 2).replace(/"(.+[^"])"(:)/g, '$1$2')
	);

	return `
ICS.init(${code});
`;
};

/**
 * Generates the v1 script code as a string
 * @param {ScriptGen.MakeScriptOpts} options
 * @returns string
 */
const generateV1Script = (options) => {
	const config = formatConfig(options);
	const { platform } = options;
	// we only have specific datalayer examples for shopify and square
	const mappedPlatform =
		platform === 'shopify' || platform === 'square' ? platform : undefined;
	const { subtotal, ...V1_DATA } = DATA_LAYER[mappedPlatform];
	// the following values should have no quotes
	// dummy variable so we don't get errors when trying to generate the script
	const DUMMY_ICS = 'ICS.v1InviteCookie';

	const v1Args = {
		...V1_DATA,
		revenue: subtotal,
		locale: options?.locale,
		displayType: options?.behavior?.hide ? 'none' : undefined,
		inviteCookie: DUMMY_ICS,
	};

	const code = removeQuotesFromJSONKeys(JSON.stringify(config, null, 2));
	const v1Opts = removeQuotesFromJSONKeys(
		JSON.stringify(v1Args, null, 2).replace(/"(ICS.v1InviteCookie)"/g, '$1')
	);

	const v1Action = {
		transaction: 'addTransaction',
		signup: 'signUp',
		participation: 'participation',
		undefined: 'addTransaction',
	};

	const v1Script = options.requiresData
		? `

window.ics && ics('${v1Action[options.engagementType]}', ${v1Opts});`
		: '';

	return `
ICS.init(${code});${v1Script}
`;
};

// removes string from the key of a property in a json string
const removeQuotesFromJSONKeys = (str) => str.replace(/"(.+[^"])"(:)/g, '$1$2');

/**
 *
 * @param {ScriptGen.MakeScriptOpts} options
 * @returns
 */
export const makeScript = (options) => {
	const scriptTag = document.createElement('script');

	const code =
		options.version === 'v1'
			? generateV1Script(options)
			: generateV2Script(options);

	scriptTag.innerHTML = `
  window.onIcsLoaded = function() {\
    ${code}\
  };
`;
	return `
${scriptTag.outerHTML}
<script src="${process.env.SDK_URL}" async defer crossorigin="anonymous"></script>
`;
};
