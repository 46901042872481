import React, { useEffect, useState, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useFormik } from 'formik';
import {
	Link,
	useRouteMatch,
	useLocation,
	useParams,
	useHistory,
} from 'react-router-dom';
import {
	Container,
	Div,
	Spanner,
	Typography,
} from '@icecreamsocial/components';
import { HeaderPortal } from '../../../Main';
import {
	UserFilterForm,
	UsersTable,
	EmphasizedAction,
	QuerySearchBar,
	Pagename,
} from '../../../../presentational';
import { CompanyLogoLink } from '../../../../containers';

import { useStore, useQueryString } from '../../../../../utils/hooks';

const UsersList = () => {
	const store = useStore();
	const history = useHistory();
	const { path } = useRouteMatch();
	const { query, addParam, withoutParam, contains, qs } = useQueryString();
	const { pathname, search: qParams } = useLocation();
	const authUser = store.Orchestrators.Auth.user;
	const [filters, setFilters] = useState({
		affiliateSearch: query.affiliateSearch,
		clientSearch: query.clientSearch,
		active: contains('active'),
		disabled: contains('disabled'),
		archived: contains('archived'),
	});

	const params = {};
	if (authUser.role === 'client' && authUser.clientId) {
		params.clientSearch = authUser.clientId;
	}

	useEffect(() => {
		let status = undefined;
		let isArchived = query.archived || false;
		if (!(query.active === 'true' && query.disabled === 'true')) {
			if (query.active === 'true') {
				status = 'active';
			}
			if (query.disabled === 'true') {
				status = 'disabled';
			}
		}
		store.getUsersFlow({
			...params,
			...query,
			status,
			isArchived,
		});
	}, [qParams]);

	/**
	 * for the filters
	 */
	const clearForm = () => {
		setFilters({
			affiliateSearch: undefined,
			clientSearch: undefined,
			active: false,
			disabled: false,
			archived: false,
		});
	};

	const filterBy = useMemo(
		() => ({
			affiliateSearch: query.affiliateSearch,
			clientSearch: query.clientSearch,
			status: query.status,
			isArchived: contains('archived') && !!query.archived,
		}),
		[qParams]
	);

	// map values from form to query
	const filterSubmit = ({
		affiliateSearch,
		clientSearch,
		active,
		disabled,
		archived,
	}) => {
		const q = { clientSearch: clientSearch?.value, affiliateSearch };
		if (active) {
			q.active = active;
		}
		if (disabled) {
			q.disabled = disabled;
		}
		if (archived) {
			q.archived = archived;
		}
		if (contains('searchText')) {
			q.searchText = query.searchText;
		}
		// clean the object of params we don't want to include in the url
		if (q.archived === false) {
			delete q.archived;
		}
		const params = qs.stringify(q) ? `?${qs.stringify(q)}` : '';
		const url = `${pathname}${params}`;
		history.push(url);
	};

	return (
		<>
			<HeaderPortal>
				<Div backgroundColor='#EBEBEB' textAlign='center' padding='md'>
					<Spanner grid gridColumns='repeat(3, 1fr)' padding='0'>
						<Div display='flex' alignItems='center'>
							<CompanyLogoLink />
						</Div>
						<Div display='flex' alignItems='center'>
							<Div elWidth='100%' textAlign='center'>
								<Typography.Span typoWeight='bold'>
									Ice Cream Social
								</Typography.Span>
							</Div>
						</Div>
					</Spanner>
				</Div>
			</HeaderPortal>
			<Div display='flex'>
				<Div elWidth='75%'>
					<Div
						display='flex'
						alignItems='center'
						justifyContent='space-between'
					>
						<Pagename>Users</Pagename>
						{store.isTeamLeader && (
							<EmphasizedAction
								as={Link}
								to={`${path}/create`}
								textDecoration='none'
								data-slate-action='newUser'
							>
								New User
							</EmphasizedAction>
						)}
					</Div>
					<QuerySearchBar
						id='influencerSearch'
						name='searchText'
						placeholder='Find Users'
					/>
					<UsersTable
						collection={store.Collections.Users.list.toJS()}
						progressPending={store.isLoading('users')}
					/>
				</Div>
				<Div as='aside' elWidth='25%' marginLeft='lg'>
					<Div position='sticky' positionTop='0'>
						<UserFilterForm
							onSubmit={filterSubmit}
							onReset={clearForm}
							affiliateId={store.userParentAffiliateId}
							clientId={store.userClientId}
							role={store.userRole}
							initialValues={filters}
						/>
					</Div>
				</Div>
			</Div>
		</>
	);
};

UsersList.displayName = 'Users.List';

export default observer(UsersList);
