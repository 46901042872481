import React, { useEffect, useState, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useFormik } from 'formik';
import {
	// Link,
	useHistory,
	useLocation,
	useParams,
	// useRouteMatch,
} from 'react-router-dom';
import { Div, Typography } from '@icecreamsocial/components';
import {
	CampaignsFilterForm,
	CampaignCardGrid,
	QuerySearchBar,
	Pagename,
} from '../../../../presentational';
import { OptimisticCampaignCards } from '../../../../optimistic';

import {
	useStore,
	usePagination,
	useQueryString,
} from '../../../../../utils/hooks';

const Campaigns = () => {
	const PAGINATION_LIMIT = 6;

	const store = useStore();
	const history = useHistory();
	const { pathname, search: qParams } = useLocation();
	const { affiliateId } = useParams();
	// const { path } = useRouteMatch();
	const { query, addParam, withoutParam, contains, qs } = useQueryString();

	const isClient = useMemo(
		() => store.Orchestrators.Auth.user?.role === 'client',
		[store.Orchestrators.Auth.user]
	);
	const DEFAULT_FILTERS = {
		disabled: false,
		archived: false,
		affiliateId,
	};
	const [filters, setFilters] = useState({
		...DEFAULT_FILTERS,
		disabled: query.status === 'disabled',
		archived: contains('archived'),
		affiliateId,
	});
	const [getPagination, resetPagination] = usePagination({
		limit: PAGINATION_LIMIT,
	});

	useEffect(() => {
		store.getCampaignsFlow({ ...query, affiliateId });
		store.Collections.Campaigns.setLocalPagination(0, PAGINATION_LIMIT);
		store.Collections.Campaigns.setIncludedBy(query);
		return () => {
			resetPagination();
		};
	}, [qParams]);

	/**
	 * for the filters
	 */
	const clearForm = () => {
		setFilters(DEFAULT_FILTERS);
	};

	const filterBy = useMemo(
		() => ({
			status: query.status,
			isArchived: contains('archived') && !!query.archived,
		}),
		[qParams]
	);

	// map values from form to query
	const filterSubmit = ({ archived, disabled, ...rest }) => {
		const q = { archived, ...rest };
		if (contains('searchText')) {
			q.searchText = query.searchText;
		}
		// status can be active | disabled
		if (disabled === true) {
			q.status = 'disabled';
		}
		// clean the object of params we don't want to include in the url
		if (q.archived === false) {
			delete q.archived;
		}
		const params = qs.stringify(q) ? `?${qs.stringify(q)}` : '';
		const url = `${pathname}${params}`;
		history.push(url);
	};

	const paginationConfig = {
		paginationServer: true,
		paginationDefaultPage: 1, // get this from a query param in the future
		paginationTotalRows: store.Collections.Campaigns.filterBy(filterBy).length,
		paginationPerPage: PAGINATION_LIMIT,
		paginationRowsPerPageOptions: [6, 9, 12, 15, 18],
		onChangePage: (page) => {
			const { skip, limit } = getPagination({
				page,
			});
			store.Collections.Campaigns.setLocalPagination(skip, skip + limit);
		},
		onChangeRowsPerPage: (currentRowsPerPage, page) => {
			const { skip, limit } = getPagination({
				page,
				limit: currentRowsPerPage,
			});
			store.Collections.Campaigns.setLocalPagination(skip, skip + limit);
		},
	};

	return (
    <Div display="flex">
      <Div elWidth="75%">
        <Div display="flex" alignItems="center" justifyContent="space-between">
          <Pagename>Campaigns</Pagename>
        </Div>
        <QuerySearchBar
          id="campaignsSearch"
          name="searchText"
          placeholder="Find Campaigns"
        />
        {store.isLoading("campaignsList") ? (
          <OptimisticCampaignCards marginTop="lg" />
        ) : (
          <CampaignCardGrid
            marginTop="lg"
            campaigns={store.Collections.Campaigns.paginateBy(filterBy)}
            paginationConfig={paginationConfig}
            isClient={isClient}
          />
        )}
      </Div>
      <Div as="aside" elWidth="25%" marginLeft="lg">
        <CampaignsFilterForm
          onSubmit={filterSubmit}
          onReset={clearForm}
          affiliateId={affiliateId}
          initialValues={filters}
        />
      </Div>
    </Div>
  );
};

Campaigns.displayName = 'Affiliate.Campaigns';

export default observer(Campaigns);
