import { Div, Typography } from '@icecreamsocial/components';
import React from 'react';
import {
	Accordion,
	AddIcon,
	RemoveIcon,
} from '../../../../../../../../../../presentational';

export const ScriptOptionBlock = ({ subheader = '', header, body }) => {
	return (
		<Accordion
			header={(isOpen) => (
				<>
					<Div
						display='flex'
						justifyContent='space-between'
						alignItems='center'
						style={{ gap: '8px' }}
					>
						<Typography.H3 margin='none'>{header}</Typography.H3>
						{isOpen ? <RemoveIcon textColor='ambientDark' /> : <AddIcon />}
					</Div>
					{subheader && (
						<Typography.P marginBottom='none' typoHeight='1.1em'>
							{subheader}
						</Typography.P>
					)}
				</>
			)}
			body={body}
		/>
	);
};
