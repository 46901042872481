import { InputGroup, Typography } from '@icecreamsocial/components';
import React from 'react';
import {
	DropDownIcon,
	SupportedLanguageSelect,
} from '../../../../../../../../../../presentational';
import { useScriptSettings } from '../../../../providers';

export const GeneralCustomizer = () => {
	const { settings, update } = useScriptSettings();

	const handleChange = (field) => (e) => {
		const { value } = e.currentTarget;
		update({ [field]: value });
	};

	return (
		<form>
			<InputGroup elWidth='100%' marginBottom='md'>
				<InputGroup.Label>Widget Locale</InputGroup.Label>
				<SupportedLanguageSelect
					id='locale'
					name='locale'
					onChange={handleChange('locale')}
					value={settings.locale}
				/>
				<Typography.Span as='small' typoSize='70%'>
					Determines things like language, currency symbol, and reading
					direction on the widget
				</Typography.Span>
			</InputGroup>
			<InputGroup elWidth='100%' marginBottom='md'>
				<InputGroup.Label>Platform</InputGroup.Label>
				<InputGroup.Fields>
					<InputGroup.Input
						name='platform'
						as='select'
						onChange={handleChange('platform')}
						value={settings.platform}
						paddingLeft='md'
					>
						<option value='shopify'>Shopify</option>
						<option value='square'>Square Online</option>
						<option value='undefined'>Other</option>
					</InputGroup.Input>
					<InputGroup.Icon>
						<DropDownIcon />
					</InputGroup.Icon>
				</InputGroup.Fields>
				<Typography.Span as='small' typoSize='70%'>
					Adjusts data collected with platform specific values
				</Typography.Span>
			</InputGroup>
		</form>
	);
};
