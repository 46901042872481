import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { LinearProgress } from '@material-ui/core';
import { Div, Panel, Typography } from '@icecreamsocial/components';
import { V2 } from '@icecreamsocial/api-resources';
import { useStore } from '../../../../utils/hooks';
import { queryKeys } from '../../../../queries/rewards';
import { useQuery } from '@tanstack/react-query';
import { CondensedRewards } from '../../../presentational';
import { useParams } from 'react-router-dom';

const InfluencerRewards = ({
	influencerId,
	conversionCount = 0,
	conversionGoal = 0,
	isProcessed = false,
}) => {
	const { Orchestrators } = useStore();
	const { campaignId } = useParams();

	/**
	 * get rewards for this influencer
	 */
	/**
	 * update the rewards list on relevant query state changes or when data is stale
	 */
	const { isFetching, data, ...rest } = useQuery(
		queryKeys.list({
			campaignId,
			influencerId,
		}),
		async () => {
			const { data: res } = await V2.Rewards.getPaginatedRewards(
				{
					campaignId,
				},
				{
					influencerId,
				},
				{
					Authorization: Orchestrators.Auth.authHeader,
				}
			);
			return res?.data;
		},
		{
			enabled: !!campaignId && !!influencerId,
		}
	);

	const sortConfig = {
		sortServer: true,
		defaultSortField: 'updatedAt',
	};

	const progress = useMemo(() => {
		const clamped = Math.min(Math.max(conversionCount, 0), conversionGoal);
		return (clamped / conversionGoal) * 100;
	}, [conversionCount, conversionGoal]);

	return (
		<Panel backgroundColor='bodyInverse' marginBottom='xlg'>
			<Panel.Body>
				<Typography.H2 typoWeight='500' marginTop='0'>
					Rewards
				</Typography.H2>
				<Div>
					<Typography.H3 typoWeight='500' marginTop='none' marginBottom='sm'>
						Progress to Next Reward
					</Typography.H3>

					<Div
						alignItems='center'
						display='flex'
						justifyContent='space-between'
						padding='none'
					>
						<Typography.Span>
							{conversionCount} / {conversionGoal} Conversions (
							{isProcessed
								? 'All done!'
								: `${
										conversionGoal - conversionCount >= 0
											? conversionGoal - conversionCount
											: 0
								  } more to go`}
							)
						</Typography.Span>
					</Div>
					<Div paddingTop='md' paddingBottom='md'>
						<LinearProgress variant='determinate' value={progress} />
					</Div>
				</Div>
				<CondensedRewards
					collection={data?.rewards?.rows}
					progressPending={isFetching}
					{...sortConfig}
				/>
			</Panel.Body>
		</Panel>
	);
};
InfluencerRewards.displayName = 'InfluencerManager.Rewards';

export default observer(InfluencerRewards);
