import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { useStore } from '../../../../../utils/hooks';
import { UsersTable, Pagename } from '../../../../presentational';

const Users = () => {
	const store = useStore();
	const { affiliateId } = useParams();
	useEffect(() => {
		store.getUsersFlow({ affiliateId, affiliateSearch: affiliateId });
	}, [affiliateId]);

	return (
		<>
			<Pagename>Users</Pagename>
			<UsersTable collection={store.Collections.Users.list.toJS()} />
		</>
	);
};
Users.displayName = 'Affiliates.Affiliate.User';

export default observer(Users);
