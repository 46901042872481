import React from 'react';
import { usePagination } from '../../../../../../../../../../../utils/hooks';
import {
	InfluencersTable,
	NoFilterData,
	NoReferralData,
} from '../../../../../../../../../../presentational';
import { useInfluencerFilters } from '../FilterProvider';
import { flags } from '../../../../../../../../../../../utils';

/**
 * @description Responsible for pagination and displaying data
 */
export const Table = ({
	campaign,
	influencers,
	isLoading = true,
	error = undefined,
}) => {
	const { state, update, clear } = useInfluencerFilters();
	const [getPagination] = usePagination({
		limit: state.limit || 10,
	});
	const { eligibility, ...query } = state;

	/**
	 * clear filters for NoFilteredData
	 */
	const clearControls = () => {
		clear('page', 'limit', 'skip');
	};

	/**
	 * @returns length of filters to be counted for NoData result
	 */
	const filterCount = () => {
		const skipFilters = ['isAnonymized', 'page', 'limit', 'skip'];
		const queries = Object.keys(
			eligibility ? { ...query, eligibility } : { ...query }
		);
		const result = queries.filter((query) => !skipFilters.includes(query));
		return result.length;
	};

	/**
	 * control pagination
	 */
	const paginationConfig = {
		paginationServer: true,
		paginationDefaultPage: state.page || 1,
		paginationTotalRows: influencers?.count,
		paginationPerPage: state.limit || 10,
		onChangePage: (next) => {
			const { skip, page } = getPagination({ page: next });
			update({ skip, page });
		},
		onChangeRowsPerPage: (currentRowsPerPage, page) => {
			const { skip, limit } = getPagination({
				page,
				limit: currentRowsPerPage,
			});
			update({ skip, limit });
		},
	};

	return error ? (
		error?.message
	) : (
		<InfluencersTable
			collection={influencers?.rows}
			campaign={campaign}
			progressPending={isLoading}
			onInfluencerSelect={() => undefined}
			noDataComponent={
				flags.isActive(flags.names.ENABLE_CUSTOM_NO_DATA_RESPONSE) ? (
					filterCount() > 0 ? (
						<NoFilterData resetFilter={clearControls} />
					) : (
						<NoReferralData
							headerText='No influencer data yet'
							bodyText="It looks like we don't have any data to show yet! As soon as your campaigns start running and influencers begin their magic, you'll see influencer details here"
						/>
					)
				) : (
					undefined
				)
			}
			{...paginationConfig}
		/>
	);
};
