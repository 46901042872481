import React from 'react';
import { observer } from 'mobx-react-lite';
import { useFormik } from 'formik';
import { RadioGroup, Radio } from '@material-ui/core';
import {
	Button,
	Div,
	InputGroup,
	Typography,
} from '@icecreamsocial/components';
import {
	CalendarPicker,
	MinMaxToggle,
} from '../../../../../../../../../../presentational';
import { useReferralFilters } from '../FilterProvider';
import { useQueryString } from '../../../../../../../../../../../utils/hooks';
/**
 * @module ConversionsFilterForm
 * @category Components
 * @subcategory Containers
 */

/**
 * @type {React.FC<{}>}
 */
const FilterForm = () => {
	/**
	 * @type { ConversionFilters.Context<ConversionsFilterForm.Props>}
	 */
	const { state, update, clear } = useReferralFilters();
	const { contains } = useQueryString();

	const clearControls = () => {
		const exceptions = ['search', 'page', 'limit', 'skip'];
		clear(...exceptions);
	};

	const mapStateToForm = (values) => {
		const {
			page,
			skip,
			limit,
			conversionDateGTOE,
			conversionDateLTOE,
			...rest
		} = values;

		const revenueSwitch = contains('revenueGTOE') | contains('revenueLTOE');

		return {
			fromDate: conversionDateGTOE,
			toDate: conversionDateLTOE,
			revenueSwitch,
			...rest,
		};
	};

	/**
	 * for the filters
	 */
	const filtersForm = useFormik({
		enableReinitialize: true,
		initialValues: mapStateToForm(state),
		onSubmit: update,
	});

	return (
		<form onSubmit={filtersForm.handleSubmit}>
			<Div display='flex' alignItems='center' justifyContent='space-between'>
				<Typography.H2 typoWeight='500'>Filters</Typography.H2>
				<Button
					onClick={clearControls}
					typoSize='small'
					textDecoration='underline'
					border='none'
					shadow='none'
					hoverShadow='none'
					padding='sm'
					backgroundColor='transparent'
				>
					Reset filters
				</Button>
			</Div>
			<Div as='fieldset' border='none' elWidth='100%'>
				<CalendarPicker
					label='Date Created'
					fromDate={filtersForm.values.fromDate}
					onFromDateChange={filtersForm.handleChange}
					toDate={filtersForm.values.toDate}
					onToDateChange={filtersForm.handleChange}
				/>
			</Div>

			<Div as='fieldset' border='none' elWidth='100%'>
				<Typography.Span
					display='block'
					typoWeight='500'
					typoSize='.9em'
					marginBottom='md'
				>
					Referral Status
				</Typography.Span>

				<RadioGroup name='status'>
					<InputGroup display='flex' alignItems='center'>
						<Radio
							color='primary'
							value={undefined}
							checked={!filtersForm.values.status}
							onChange={filtersForm.handleChange}
						/>{' '}
						<InputGroup.Label display='inline-block'>Any</InputGroup.Label>
					</InputGroup>
					<InputGroup display='flex' alignItems='center'>
						<Radio
							color='primary'
							value='Confirmed'
							checked={filtersForm.values.status === 'Confirmed'}
							onChange={filtersForm.handleChange}
						/>{' '}
						<InputGroup.Label display='inline-block'>
							Confirmed
						</InputGroup.Label>
					</InputGroup>
					<InputGroup display='flex' alignItems='center'>
						<Radio
							color='primary'
							value='Pending'
							checked={filtersForm.values.status === 'Pending'}
							onChange={filtersForm.handleChange}
						/>{' '}
						<InputGroup.Label display='inline-block'>Pending</InputGroup.Label>
					</InputGroup>
					<InputGroup display='flex' alignItems='center'>
						<Radio
							color='primary'
							value='Canceled'
							checked={filtersForm.values.status === 'Canceled'}
							onChange={filtersForm.handleChange}
						/>{' '}
						<InputGroup.Label display='inline-block'>Canceled</InputGroup.Label>
					</InputGroup>
				</RadioGroup>
			</Div>

			<Div as='fieldset' border='none' elWidth='100%'>
				<Typography.Span
					display='block'
					typoWeight='500'
					typoSize='.9em'
					marginBottom='md'
				>
					Metrics
				</Typography.Span>
				<MinMaxToggle
					name='revenue'
					label='Order Total'
					show={filtersForm.values.revenueSwitch}
					minValue={filtersForm.values.revenueGTOE}
					maxValue={filtersForm.values.revenueLTOE}
					handleChange={filtersForm.handleChange}
				/>
			</Div>

			<Button
				type='submit'
				disable={!filtersForm.isValid}
				elWidth='100%'
				textColor='bodyInverse'
				backgroundColor='brand'
				padding='sm'
			>
				Apply Filters
			</Button>
		</form>
	);
};

FilterForm.displayName = 'ConversionsFilterForm';
export default observer(FilterForm);
