import React from 'react';
import { Div, Img, Typography, Spanner } from '@icecreamsocial/components';
import { HeaderPortal } from '../../Main';
import emptyStateSvg from '../assets/campaigns_empty_state.svg';
import { CompanyLogoLink } from '../../../containers';

const NoBusinessSettingsState = () => (
	<>
		<HeaderPortal>
			<Div backgroundColor="#EBEBEB" textAlign="center" padding="md">
				<Spanner grid gridColumns="repeat(3, 1fr)" padding="0">
					<Div display="flex" alignItems="center">
						<CompanyLogoLink />
					</Div>
					<Div display="flex" alignItems="center">
						<Div elWidth="100%" textAlign="center">
							<Typography.Span typoWeight="bold">
								Ice Cream Social
							</Typography.Span>
						</Div>
					</Div>
				</Spanner>
			</Div>
		</HeaderPortal>
		<Div
			elWidth={['100%', '66%', '50%']}
			margin="auto"
			marginTop="xlg"
			marginBottom="xlg"
			padding="lg"
		>
			<Typography.H1>
				This is where you'll create and manage your campaigns
			</Typography.H1>
			<Typography.P>
				Check back here once you finish setting up your Business Settings
			</Typography.P>
			<Img src={emptyStateSvg} />
		</Div>
	</>
);

NoBusinessSettingsState.displayName = 'Campaings.NoBusinessSettings';
export default NoBusinessSettingsState;
