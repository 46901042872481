import React, { useMemo } from 'react';
import { Instance } from 'mobx-state-tree';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';
import { IcsPaymentGateway } from '@icecreamsocial/mst-models';
import { Button, Typography, Div } from '@icecreamsocial/components';
import { addMinutes } from 'date-fns';
import { ProcessorPanel, StripeConnectionStatus } from '../';
import stripeLogo from '../../../assets/logo_stripe_blurple.svg';
import {
	useQueryString,
	useStore,
	useCookies,
	useStripeConnect,
} from '../../../utils/hooks';

/**
 * @module StripePanel
 * @category Components
 * @subcategory Presentational
 */

/**
 * @depends on IcsPaymentGateway model
 */

/**
 * @callback onProcessorToggle
 * @param {boolean} selected whether or not the toggle object has been selected
 * @return {*}
 */

/**
 *
 * @param {object} props stripe panel props
 * @param {boolean} [props.activated = false]
 * @param {boolean} [props.selected = false]
 * @param {Instance<typeof IcsPaymentGateway> | object} [props.processorData]
 * @param {function} [props.beforeRedirect = undefined]
 * @param {module:StripePanel~onProcessorToggle} props.onProcessorToggle
 * @param {*} props.onDisconnect
 */
//TODO remove store jocelyn

const StripePanel = ({
	selected = false,
	processorData = {},
	beforeRedirect = () => undefined,
	onProcessorToggle,
	onDisconnect,
}) => {
	const { handleOAuthRedirect } = useStripeConnect(beforeRedirect);

	const activated = useMemo(() => processorData.type === 'Stripe', [
		processorData,
	]);

	return (
		<ProcessorPanel
			name="Stripe"
			description="Reward influencers with your Stripe account"
			isActivated={activated}
			isFocused={selected}
			handleChangeProcessor={onProcessorToggle}
			logoSrc={stripeLogo}
		>
			{!activated ? (
				<>
					<Typography.Span typoWeight="bold">
						Connect with Stripe
					</Typography.Span>
					<Typography.P>
						To connect with Stripe, you'll be redirected to their site. You will
						be brought back to the admin when set up is complete.
					</Typography.P>
					<Button
						onClick={async (e) => await handleOAuthRedirect(e)}
						backgroundColor="brand"
						textColor="bodyInverse"
					>
						Connect Stripe Account
					</Button>
				</>
			) : (
				<StripeConnectionStatus onDisconnect={onDisconnect} />
			)}
		</ProcessorPanel>
	);
};
StripePanel.displayName = 'BusinessStripePanelForm';

export default observer(StripePanel);
