import React, { useEffect, useState, useCallback, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { SquarePanel, StripePanel, NmiPanel, AuthorizeNetPanel } from "../";
import { useStore } from "../../../utils/hooks";

//TODO: remove store Jocelyn
const PaymentProcessor = ({ processor = undefined, beforeRedirect }) => {
  const store = useStore();
  const [currentProcessor, setCurrentProcessor] = useState(
    normalizeProcessorName(processor)
  );

  // this will make sure that the togling resets when the processor changes
  useEffect(() => {
    setCurrentProcessor(normalizeProcessorName(processor));
  }, [processor]);

  const isProcessor = useCallback(
    (proc = "") => currentProcessor === normalizeProcessorName(proc),
    [processor, currentProcessor, store.userProcessor]
  );

  const isSquare = useMemo(
    () => isProcessor("square") || isProcessor("square sandbox"),
    [processor, currentProcessor]
  );

  const isStripe = useMemo(
    () => isProcessor("stripe") || isProcessor("stripe test"),
    [processor, currentProcessor]
  );

  const isCliq = useMemo(() => isProcessor("cliq"), [
    processor,
    currentProcessor,
  ]);

  const isAuthorizeNet = useMemo(() => isProcessor("authorizenet"), [
    processor,
    currentProcessor,
  ]);

  const handleToggle = (isSelected, processor) =>
    isSelected ? setCurrentProcessor(processor) : setCurrentProcessor(null);

  const handleDisconnect = async (disconnectPromise) => {
    await disconnectPromise();
    setCurrentProcessor(undefined);
  };

  return (
    <>
      {isSquare || !!!currentProcessor ? (
        <SquarePanel
          selected={isSquare}
          onProcessorToggle={(selected) => handleToggle(selected, "square")}
          processorData={store.userProcessor}
          onDisconnect={() =>
            handleDisconnect(store.disconnectSquareProcessorFlow)
          }
          beforeRedirect={beforeRedirect}
        />
      ) : null}
      {isStripe || !!!currentProcessor ? (
        <StripePanel
          selected={isStripe}
          onProcessorToggle={(selected) => handleToggle(selected, "stripe")}
          processorData={store.userProcessor}
          onDisconnect={() =>
            handleDisconnect(store.disconnectStripeProcessorFlow)
          }
          beforeRedirect={beforeRedirect}
        />
      ) : null}
      {isCliq || !!!currentProcessor ? (
        <NmiPanel
          selected={isCliq}
          onProcessorToggle={(selected) => handleToggle(selected, "cliq")}
          processorData={store.userProcessor}
          onSubmit={store.createNMIProcessorFlow}
          onDisconnect={() => handleDisconnect(store.deleteProcessorFlow)}
        />
      ) : null}
      {isAuthorizeNet || !!!currentProcessor ? (
        <AuthorizeNetPanel
          selected={isAuthorizeNet}
          onProcessorToggle={(selected) =>
            handleToggle(selected, "authorizenet")
          }
          processorData={store.userProcessor}
          onSubmit={store.createAuthorizeNetProcessorFlow}
          onDisconnect={() => handleDisconnect(store.deleteProcessorFlow)}
        />
      ) : null}
    </>
  );
};
PaymentProcessor.displayName = "BusinessPaymentProcessorForm";

function normalizeProcessorName(name = null) {
  return name === null ? name : name.toLowerCase();
}

export default observer(PaymentProcessor);
