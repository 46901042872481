import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Div, Typography } from '@icecreamsocial/components';
import { useStore } from '../../../../../../../../../utils/hooks';
import {
	EditFormControls,
	PolicySettingsForm,
	Pagename,
} from '../../../../../../../../presentational';
import { useCampaign } from '../../../../providers/Campaign';

const PolicySettings = () => {
	const { campaign, update } = useCampaign();
	const { data } = campaign;
	const initialValues = {
		maskInfo: data?.maskInfo,
		termsAndConditionsURL: data?.termsAndConditionsURL,
		privacyPolicyURL: data?.privacyPolicyURL,
		useEmailConsent: data?.useEmailConsent,
		useSMSConsent: data?.useSMSConsent,
	};

	const onSubmit = ({
		maskInfo,
		termsAndConditionsURL,
		privacyPolicyURL,
		useEmailConsent,
		useSMSConsent,
	}) => {
		update.mutate({
			maskInfo,
			termsAndConditionsURL: termsAndConditionsURL,
			privacyPolicyURL: privacyPolicyURL,
			useEmailConsent,
			useSMSConsent,
		});
	};

	return (
		<Div>
			<Pagename>Data & Policy Settings</Pagename>
			{data && (
				<PolicySettingsForm
					onSubmit={onSubmit}
					renderControls={(props) => <EditFormControls {...props} />}
					{...initialValues}
				/>
			)}
		</Div>
	);
};
PolicySettings.displayName = 'Campaigns.Campaign.Settings.Policy';

export default observer(PolicySettings);
