import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from '../../../../../utils/hooks';
import { statPropMap } from '../../utils';
import { useDashboardFilters } from '../../components/FilterProvider';
import { StatPanel } from '../../../../presentational';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../../../../../queries/stats';
import { Typography } from '@icecreamsocial/components';
import { formatISO, startOfMonth } from 'date-fns';

const DEFAULT_STATS = {
	totalRevenue: 0,
	totalConversions: 0,
	totalInvitesClicked: 0,
	totalInfluencers: 0,
	totalUtilizedInfluencers: 0,
	totalUtilizationRate: 0,
};

const defaultDates = (state) => {
	const today = Date.now();
	return {
		fromDate: state.fromDate || formatISO(startOfMonth(today)),
		toDate: state.toDate || formatISO(today),
	};
};

const Stats = () => {
	const store = useStore();
	const { state } = useDashboardFilters();

	const { isLoading: isStatsLoading, data: dailyStats } = useQuery(
		queryKeys.getDailyStats({
			affiliateId: store.Orchestrators.Auth.user.affiliateId,
			clientId: store.Orchestrators.Auth.user.clientId || null,
			...state,
			...defaultDates(state),
		}),
		async () => {
			const { items } = await store.getDailyStats({
				affiliateId: store.Orchestrators.Auth.user.affiliateId,
				clientId: store.Orchestrators.Auth.user.clientId || null,
				...state,
				...defaultDates(state),
			});

			return Object.keys(DEFAULT_STATS).reduce(
				(stats, key) => ({
					...stats,
					[key]: items.map((datum) => ({
						key,
						value: datum[key],
						date: datum.date,
					})),
				}),
				{}
			);
		},

		{ refetchOnWindowFocus: true }
	);

	const { isLoading: isOverviewLoading, data: dailyOverview } = useQuery(
		queryKeys.getStatsOverview({
			affiliateId: store.Orchestrators.Auth.user.affiliateId,
			clientId: store.Orchestrators.Auth.user.clientId || null,
			...state,
			...defaultDates(state),
		}),
		async () => {
			const { stats } = await store.getDailyStatsOverview({
				affiliateId: store.Orchestrators.Auth.user.affiliateId,
				clientId: store.Orchestrators.Auth.user.clientId || null,
				...state,
				...defaultDates(state),
			});
			return stats;
		},
		{ refetchOnWindowFocus: true }
	);

	const formatStatValue = (stat) => {
		if (!dailyOverview) return {};

		const value = dailyOverview[stat];
		switch (stat) {
			case 'totalRevenue': {
				return value.toLocaleString('en-US', {
					style: 'currency',
					currency: 'USD',
					minimumFractionDigits: 0,
				});
			}
			case 'totalUtilizationRate': {
				return (value / 100).toLocaleString('en-US', {
					style: 'percent',
					minimumFractionDigits: 0,
				});
			}
			default: {
				return value;
			}
		}
	};

	return Object.keys(DEFAULT_STATS).map((val) => (
		<StatPanel
			key={`${val}-panel`}
			name={val}
			label={statPropMap[val].label}
			tooltipLabel={statPropMap[val].tooltipLabel}
			backgroundColor={statPropMap[val].bgColor}
			color={statPropMap[val].color}
			dataIsLoading={isStatsLoading}
			overviewIsLoading={isOverviewLoading}
			data={formatStats(dailyStats?.[val])}
			value={formatStatValue(val)}
		/>
	));
};
Stats.displayName = 'Dashboard.Stats';

function formatStats(stats = []) {
	return stats.map(({ date, value }) => ({
		x: date,
		y: value,
	}));
}

export default observer(Stats);
