import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Div,
  Typography,
  List,
  InputGroup,
  Panel,
  Button,
  Spanner,
} from '@icecreamsocial/components';
import {
  ArrowBackIcon,
  ConfirmationPrompt,
  EditFormControls,
  EnhancedForm,
} from '../../../presentational';
import { Link } from 'react-router-dom';
import EditPane from '../EditPane';
import { Checkbox } from '@material-ui/core';

const MarketingConsentEdit = ({
  emailConsent = false,
  smsConsent = false,
  handleSubmit,
}) => {
  const formikProps = {
    enableReinitialize: true,
    onSubmit: handleSubmit,
    initialValues: {
      emailConsent,
      smsConsent,
    },
  };

  return (
    <EditPane>
      <Panel backgroundColor='bodyInverse'>
        <Panel.Body>
          <Typography.H2 typoWeight='500' marginTop='0' textAlign='center'>
            Manage Influencer Consent
          </Typography.H2>
          <Typography.P marginBottom='lg'>
            Remember to ask for permission from an influencer before you opt
            them into receiving marketing emails or SMS
          </Typography.P>
          <EnhancedForm
            formikProps={formikProps}
            promptIf={({ dirty }) => dirty}
            renderControls={(props) => (
              <EditPane.Controls>
                <EditFormControls position='sticky' {...props} />
              </EditPane.Controls>
            )}
          >
            {(influencerConsent) => (
              <>
                <InputGroup
                  elWidth='100%'
                  marginBottom='md'
                  display='flex'
                  alignItems='center'
                  style={{ gap: '16px' }}
                >
                  <Checkbox
                    id='emailConsent'
                    name='emailConsent'
                    color='primary'
                    value={influencerConsent.values.emailConsent}
                    checked={influencerConsent.values.emailConsent}
                    onChange={influencerConsent.handleChange}
                  />
                  <InputGroup.Label>
                    Influencer has agreed to receive marketing emails
                  </InputGroup.Label>
                </InputGroup>
                <InputGroup
                  elWidth='100%'
                  marginBottom='md'
                  display='flex'
                  alignItems='center'
                  style={{ gap: '16px' }}
                >
                  <Checkbox
                    id='smsConsent'
                    name='smsConsent'
                    color='primary'
                    value={influencerConsent.values.smsConsent}
                    checked={influencerConsent.values.smsConsent}
                    onChange={influencerConsent.handleChange}
                  />
                  <InputGroup.Label>
                    Influencer has agreed to receive SMS messages
                  </InputGroup.Label>
                </InputGroup>
              </>
            )}
          </EnhancedForm>
        </Panel.Body>
      </Panel>
    </EditPane>
  );
};
MarketingConsentEdit.displayName = 'InfluencerManager.MarketingConsentEdit';

export default observer(MarketingConsentEdit);
