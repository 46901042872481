import React from 'react';
import { Typography } from '@icecreamsocial/components';
import { observer } from 'mobx-react-lite';
/**
 * @module Pagename
 * @category Components
 * @subcategory Presentational
 */

/**
 * @name Pagename
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @description Used to display the name of the page being viewed.  Should only be one per page
 */
const Pagename = ({ children, ...props }) => (
	<Typography.H1 data-cy='pagename' typoWeight='500' {...props}>
		{children}
	</Typography.H1>
);

Pagename.displayName = 'Pagename';
export default observer(Pagename);
