import { types, SnapshotIn } from 'mobx-state-tree';
import { IcsPaymentGateway } from '@icecreamsocial/mst-models';

/**
 * @module RewardProcessorPreprocessor
 * @category Shared
 * @subcategory Models
 * @description
 * Formats a snapshot for an IcsPaymentGateway model
 */
const RewardProcessor = types.snapshotProcessor(IcsPaymentGateway, {
	/**
	 * format an incoming snapshot of an ICS payment gateway
	 * @param {SnapshotIn<typeof IcsPaymentGateway>} snapshot
	 */
	preProcessor(snapshot) {
		return snapshot === null || snapshot === undefined
			? undefined
			: {
					...snapshot,
					cardFlexSecurityKey: snapshot.cardFlexSecurityKey || null,
					clientId:
						snapshot.clientId === null
							? snapshot.clientId
							: `${snapshot.clientId}`,
					companyId:
						snapshot.companyId === null
							? snapshot.companyId
							: `${snapshot.companyId}`,
					type: snapshot.type || null,
					name: snapshot.name || null,
					squareAppId: snapshot.squareAppId || null,
					squareLocationId: snapshot.squareLocationId || null,
					squareLocationName: snapshot.squareLocationName || null,
					squareAppAccessToken: snapshot.squareAppAccessToken || null,
					squareOAuthAccessToken: snapshot.squareOAuthAccessToken || null,
					squareOAuthAccessTokenExpiration:
						snapshot.squareOAuthAccessTokenExpiration || null,
					squareOAuthRefreshToken: snapshot.squareOAuthRefreshToken || null,
					squareMerchantId: snapshot.squareMerchantId || null,
					stripeAccessToken: snapshot.stripeAccessToken || null,
					stripeAccountId: snapshot.stripeAccountId || null,
					stripePublishKey: snapshot.stripePublishKey || null,
					stripeRefreshToken: snapshot.stripeRefreshToken || null,
			  };
	},
});

export default RewardProcessor;
