import React from 'react';
import { Div, Img, Typography } from '@icecreamsocial/components';
import emptyStateSvg from '../assets/dashboard_empty_state.svg';

const EmptyState = ({ user = '' }) => (
	<Div
		elWidth={['100%', '66%', '50%']}
		margin="auto"
		marginTop="xlg"
		marginBottom="xlg"
		padding="lg"
	>
		<Typography.H1>Hey {user}, welcome to Ice Cream Social!</Typography.H1>
		<Typography.P>
			On this page, you'll be able to see an overview of activity for your
			campaigns! Check back once you have a campaign up and running
		</Typography.P>
		<Img src={emptyStateSvg} />
	</Div>
);

EmptyState.displayName = 'Dashboard.EmptyState';
export default EmptyState;
