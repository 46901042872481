import { lighten, darken } from 'polished';

function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread2(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
function _toPrimitive(t, r) {
  if ("object" != typeof t || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != typeof i) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == typeof i ? i : String(i);
}
function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
function _defineProperty(obj, key, value) {
  key = _toPropertyKey(key);
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }
  return obj;
}
function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;
  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }
  return target;
}
function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};
  var target = _objectWithoutPropertiesLoose(source, excluded);
  var key, i;
  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }
  return target;
}

var baseUnit = 8;

var globals = {
  baseFactor: 8,
  baseFontSize: '16px',
  fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'
};

var palette = {
  white: '#FFFFFF',
  grey: '#2C2C2C',
  lightGrey: '#EEEEEE',
  blue: '#2C98FB',
  green: '#00BD9D',
  red: '#F00807',
  yellow: '#FFA200',
  purple: '#5956D7'
};
var color = {
  "default": palette.grey,
  defaultLight: lighten(0.25, palette.grey),
  defaultDark: darken(0.15, palette.grey),
  link: palette.blue,
  linkVisited: palette.purple,
  body: palette.grey,
  bodyMuted: '#888888',
  bodyInverse: '#FFFFFF',
  bodyBg: '#F8F8F9',
  accent: palette.purple,
  accentLight: lighten(0.3, palette.purple),
  accentDark: darken(0.15, palette.purple),
  brand: palette.blue,
  brandLight: lighten(0.3, palette.blue),
  brandDark: darken(0.15, palette.blue),
  ambient: '#DDDDDD',
  ambientLight: '#f8f8f9',
  ambientDark: '#BBBBBB',
  good: palette.green,
  goodLight: lighten(0.3, palette.green),
  goodDark: darken(0.15, palette.green),
  bad: palette.red,
  badLight: lighten(0.3, palette.red),
  badDark: darken(0.15, palette.red),
  warning: palette.yellow,
  warningLight: lighten(0.3, palette.yellow),
  warningDark: darken(0.15, palette.yellow),
  transparent: 'transparent',
  fb: '#3C5A99',
  fbMessenger: '#0078FF',
  kakaoTalk: '#FFE812',
  weChat: '#7BB32E',
  whatsapp: '#25D366',
  google: '#DB4437'
};

var SPACING_FACTOR = 8;
var spacing = {
  none: '0',
  auto: 'auto',
  sm: "".concat(SPACING_FACTOR, "px"),
  md: "".concat(SPACING_FACTOR * 2, "px"),
  lg: "".concat(SPACING_FACTOR * 3, "px"),
  xlg: "".concat(SPACING_FACTOR * 4, "px")
};

// TODO: get actual values from bryan
var lineHeight = {
  sm: '.8',
  md: '1.3',
  lg: '1.6',
  xlg: '2',
  "default": 'normal'
};
var fontWeight = {
  lightest: '100',
  light: 'lighter',
  strong: 'bold',
  strongest: 'bolder'
};
var fontSize = {
  sm: '.8em',
  md: '1em',
  lg: '1.5em',
  xlg: '3em',
  "default": '1em'
};
var typography = {
  lineHeight: lineHeight,
  fontWeight: fontWeight,
  fontSize: fontSize
};

/**
 *  mobile first breakpoints
 * @returns {Number[]} first value is the second smallest breakpoint, followed by the second largest, followed by the largest breakpoint
 */
function makeBreakpoints() {
  var smallScreen = 600; // TODO: edit these values
  var mediumScreenAndUp = smallScreen + 1;
  var mediumScreen = 900; //
  var largeScreenAndUp = mediumScreen + 1;
  var largeScreen = 1200; //
  var extraLargeScreenAndUp = largeScreen + 1;
  var ultraLargeScreen = 1440;
  // const extraLargeScreen = 1800;

  // const sizes = {
  // 	smallScreen,
  // 	mediumScreenAndUp,
  // 	mediumScreen,
  // 	largeScreenAndUp,
  // 	largeScreen,
  // 	largeScreenAndDown,
  // 	extraLargeScreenAndUp,
  // 	extraLargeScreen,
  // };
  return [smallScreen, mediumScreenAndUp, largeScreenAndUp, extraLargeScreenAndUp, ultraLargeScreen];
}
var breakpoints = makeBreakpoints();

var style = 'solid';
var width = '1px';
var radius = {
  "default": '16px',
  none: '0'
};
var borders = {
  none: '0',
  "default": "".concat(width, " ").concat(style),
  style: style,
  width: width,
  radius: radius
};

// values from https://codepen.io/sdthornton/pen/wBZdXq
var boxShadows = {
  none: 'none',
  "default": '0 1px 3px rgba(0,0,0,0.12),0 1px 2px rgba(0,0,0,0.24)',
  raised: '0 3px 6px rgba(0,0,0,0.16),0 3px 6px rgba(0,0,0,0.23)',
  high: '0 10px 20px rgba(0,0,0,0.19),0 6px 6px rgba(0,0,0,0.23)',
  higher: '0 14px 28px rgba(0,0,0,0.25),0 10px 10px rgba(0,0,0,0.22)',
  highest: '0 19px 38px rgba(0,0,0,0.30),0 15px 12px rgba(0,0,0,0.22)'
};

var zIndex = {
  "default": '1',
  navigation: '10',
  modal: '9999'
};

var SlateTheme = {
  baseUnit: baseUnit,
  globals: globals,
  colors: _objectSpread2({}, color),
  spacing: _objectSpread2({}, spacing),
  typography: _objectSpread2({}, typography),
  breakpoints: breakpoints,
  borders: borders,
  boxShadows: boxShadows,
  zIndex: zIndex
};

var _excluded = ["colors"];
var makeTheme = function makeTheme() {
  var config = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var baseTheme = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : SlateTheme;
  var colors = config.colors,
    themeVals = _objectWithoutProperties(config, _excluded);
  var filled = _objectSpread2(_objectSpread2({}, themeVals), {}, {
    colors: fillColors(colors)
  });
  var base = mergeThemeValues(baseTheme, filled);
  return base;
};

// set some intermediary colors if not explicitly set
function fillColors() {
  var defaultColors = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var filled = defaultColors;
  if (!defaultColors.accentDark) filled.accentDark = darken(0.15, defaultColors.accent || SlateTheme.colors.accent);
  if (!defaultColors.accentLight) filled.accentDark = lighten(0.3, defaultColors.accent || SlateTheme.colors.accent);
  if (!defaultColors.brandDark) filled.brandDark = darken(0.15, defaultColors.brand || SlateTheme.colors.brand);
  if (!defaultColors.brandLight) filled.brandLight = lighten(0.3, defaultColors.brand || SlateTheme.colors.brand);
  return filled;
}
function mergeThemeValues(defaults, overrides) {
  var nullish = overrides === undefined || overrides === null;
  // if no overrides, just continue with defaults
  if (nullish) return defaults;

  // if the default is not an object, we are at a leaf property.
  if (_typeof(defaults) !== 'object' && !Array.isArray(defaults)) return nullish ? defaults : overrides;

  // recurse through the theme properties
  return Object.keys(defaults).reduce(function (theme, prop) {
    return _objectSpread2(_objectSpread2({}, theme), {}, _defineProperty({}, prop, mergeThemeValues(defaults[prop], overrides[prop])));
  }, {});
}

export { baseUnit, borders, boxShadows, breakpoints, color, SlateTheme as default, globals, makeTheme, spacing, typography, zIndex };
