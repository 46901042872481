import React, { createContext, useContext } from 'react';
import { useHistory, useLocation } from 'react-router';
import {
	filterProviderFactory,
	MapFn,
} from '../../../../../../../../../../../utils/filterProviderFactory';
import { useQueryString } from '../../../../../../../../../../../utils/hooks';

/**
 * The filter state.  This will be directly written to the URL
 */
type State = {
	eligibility: 'eligible' | 'ineligible';
	status: 'processed' | 'canceled';
	isAnonymized: boolean | 'true' | 'false';
	search: string;
	createdDateGTOE: string;
	createdDateLTOE: string;
	invitesGTOE: string;
	invitesLTOE: string;
	invitesClickCountGTOE: string;
	invitesClickCountLTOE: string;
	conversionsGTOE: string;
	conversionsLTOE: string;
	revenueGTOE: string;
	revenueLTOE: string;
	page: string;
	skip: string;
	limit: string;
};

type Unmapped = {
	invitesClickCountSwitch: boolean;
	invitesSwitch: boolean;
	revenueSwitch: boolean;
	conversionsSwitch: boolean;
	fromDate: string;
	toDate: string;
};

const { Provider, useFilters } = filterProviderFactory();
/**
 * Conversion filters
 */
export const InfluencerFiltersProvider = ({ children }) => {
	/**
	 * @param {State} controlValues
	 */
	const mapControlsToState: MapFn<State, Unmapped> = (controlVals) => {
		const {
			invitesClickCountSwitch,
			invitesSwitch,
			revenueSwitch,
			conversionsSwitch,
			fromDate,
			toDate,
			...state
		} = controlVals;

		return {
			createdDateGTOE: fromDate,
			createdDateLTOE: toDate,
			...state,
		};
	};

	return (
		<Provider mapControlsToState={mapControlsToState}>{children}</Provider>
	);
};

export const useInfluencerFilters = useFilters;
