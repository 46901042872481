import React, { useEffect } from 'react';
import { IcsV1 } from '@icecreamsocial/api-resources';
/**
 * @module useResponseInterceptor
 * @category Shared
 * @subcategory Hooks
 * @exports  useResponseInterceptor
 */

/**
 * @function
 * @param {*} onResponse
 * @param {*} onError
 */
export const useResponseInterceptor = (
	onResponse = (next) => {},
	onError = (err) => {}
) =>
	useEffect(() => {
		IcsV1.V1Resource.interceptors.response.use(onResponse, onError);
	}, []);
