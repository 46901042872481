import { createQueryKeys } from '@lukemorales/query-key-factory';

/**
 * params for a majority of campaign calls
 */
type CoreParams = {
	affiliateId: string;
	clientId: string;
	searchText: string;
	clientSearch: string;
};

/**
 * params for general campaign calls
 */
type SimpleParams = {
	campaignId: string;
};

/**
 * this should probably be in a different library
 */
export type Campaign = {
	userId: number;
	clientId: number;
	name: string;
	fromEmail: string;
	fromName: string;
	emailSubject: string;
	active: boolean;
	conversionGoal: number;
	locale: string;
	offerHeading: string;
	offerInstructions: string;
	thankYouImage: string;
	thankYouImageMobile: string;
	offerImage: string;
	offerImageMobile: string;
	shareTitle: string;
	shareMessage: string;
	shareImage: string;
	shareVideo: string;
	offerImageTitle: string;
	offerImageCaption: string;
	redirectUrl: string;
	maskInfo: boolean;
	emailNote: string;
	status: string;
	isArchived: boolean;
	termsAndConditionsURL: string;
	privacyPolicyURL: string;
	refundAmount: number;
	giftCardAmount: number;
	refundFullAmount: boolean;
	automaticRewardProcessing: boolean;
	useNewInfluencerEmail: boolean;
	useNewConversionEmail: boolean;
	useGoalReachedEmail: boolean;
	useGoalProcessedEmail: boolean;
	useRewardProcessedEmail: boolean;
	useInviteReminderEmail: boolean;
	useNotifyAdminGoalReachedEmail: boolean;
	useConversionDeletedEmail: boolean;
	useEmailConsent: boolean;
	useSMSConsent: boolean;
	useSMS: boolean;
	useEmail: boolean;
	id: number;
	createdAt: string;
	updatedAt: string;
};

/**
 * reuable query keys for Converions for use with useQuery or useMutation
 */
export const queryKeys = createQueryKeys('campaigns', {
	getCampaign: ({ campaignId }: SimpleParams) => ({
		campaignId,
	}),
	/**
	 * gets campaigns based on params
	 */
	getCampaigns: ({
		affiliateId,
		clientId,
		clientSearch,
		searchText,
	}: CoreParams) => ({
		affiliateId,
		clientId,
		clientSearch,
		searchText,
	}),
	getDiscounts: ({ campaignId }: SimpleParams) => ({
		campaignId,
	}),
	updateCampaign: ({
		campaignId,
		...rest
	}: Partial<Campaign> & SimpleParams) => ({ campaignId, ...rest }),
	updateDiscounts: ({ campaignId }: SimpleParams) => ({
		campaignId,
	}),
});
