import React, { useRef } from 'react';
import { observer } from 'mobx-react-lite';
import {
	Button,
	Div,
	InputGroup,
	Typography,
} from '@icecreamsocial/components';
import { Formik } from 'formik';
import { Checkbox } from '@material-ui/core';
import { AffiliateSelector, ClientSelector } from '../../containers';

/**
 * @module UserFilterForm
 * @category Components
 * @subcategory Presentational
 */
/**
 *
 * @param {object} props
 * @param {function} props.onSubmit the onSubmit function passed to useFormik
 * @param {function} props.onReset the onReset function passed to useFormik
 * @param {string} props.affiliateId auth user's affiliateId
 * @param {string} props.role auth user's role
 * @param {object} props.initialValues
 * @param {boolean} [props.initialValues.active] is the user active
 * @param {boolean} [props.initialValues.disabled] is the user disabled
 * @param {boolean} [props.initialValues.archived] is the user archived
 */
const UsersFilterForm = ({
	onSubmit,
	onReset,
	affiliateId,
	role,
	initialValues,
}) => {
	const selectRef = useRef();
	/**
	 * for the filters
	 */
	const formikProps = {
		enableReinitialize: true,
		initialValues,
		onSubmit,
		onReset,
	};

	return (
		<Formik {...formikProps}>
			{(filtersForm) => (
				<form onSubmit={filtersForm.handleSubmit}>
					<Div
						display='flex'
						alignItems='center'
						justifyContent='space-between'
					>
						<Typography.H2 typoWeight='500'>Filters</Typography.H2>
						<Button
							onClick={() => {
								filtersForm.handleReset();
								selectRef.current?.clearSelect();
							}}
							typoSize='small'
							textDecoration='underline'
							border='none'
							shadow='none'
							hoverShadow='none'
							padding='sm'
							backgroundColor='transparent'
						>
							Reset filters
						</Button>
					</Div>
					<Div as='fieldset' border='none' elWidth='100%'>
						{role !== 'client' && (
							<>
								<InputGroup elWidth='100%' marginBottom='sm'>
									<InputGroup.Label>Parent Affiliate</InputGroup.Label>
									<AffiliateSelector
										as='select'
										id='affiliateSearch'
										name='affiliateSearch'
										affiliateId={affiliateId}
										value={filtersForm.values.affiliateSearch}
										onChange={filtersForm.handleChange}
										paddingLeft='md'
										elWidth='100%'
									></AffiliateSelector>
								</InputGroup>

								<InputGroup elWidth='100%' marginBottom='sm'>
									<InputGroup.Label>Parent Client</InputGroup.Label>
									<ClientSelector
										ref={selectRef}
										name='clientSearch'
										placeholder='All clients'
										affiliateId={
											filtersForm.values.affiliateSearch || affiliateId
										}
										paddingLeft='md'
									/>
								</InputGroup>
							</>
						)}

						<Typography.Span
							display='block'
							typoWeight='500'
							typoSize='.9em'
							marginBottom='md'
						>
							Status
						</Typography.Span>
						<InputGroup display='flex' alignItems='center'>
							<Checkbox
								color='primary'
								name='active'
								value={filtersForm.values.active}
								checked={filtersForm.values.active}
								onChange={filtersForm.handleChange}
							/>{' '}
							<InputGroup.Label display='inline-block'>Active</InputGroup.Label>
						</InputGroup>
						<InputGroup display='flex' alignItems='center'>
							<Checkbox
								color='primary'
								name='disabled'
								value={filtersForm.values.disabled}
								checked={filtersForm.values.disabled}
								onChange={filtersForm.handleChange}
							/>{' '}
							<InputGroup.Label display='inline-block'>
								Disabled
							</InputGroup.Label>
						</InputGroup>
						<InputGroup display='flex' alignItems='center'>
							<Checkbox
								color='primary'
								name='archived'
								value={filtersForm.values.archived}
								checked={filtersForm.values.archived}
								onChange={filtersForm.handleChange}
							/>{' '}
							<InputGroup.Label display='inline-block'>
								Archived
							</InputGroup.Label>
						</InputGroup>
					</Div>

					<Button
						type='submit'
						disable={!filtersForm.isValid}
						elWidth='100%'
						textColor='bodyInverse'
						backgroundColor='brand'
						padding='sm'
					>
						Apply Filters
					</Button>
				</form>
			)}
		</Formik>
	);
};

UsersFilterForm.displayName = 'UsersFilterForm';
export default observer(UsersFilterForm);
