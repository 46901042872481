import React, { useState } from 'react';
import { Container, Div, Typography } from '@icecreamsocial/components';
import { useTheme } from '../../../utils/hooks';
import { HeaderPortal } from '../Main';
import { PageMeta } from '../../presentational';

export default () => {
	return (
		<>
			<PageMeta
				title={'Logs | Ice Cream Social'}
				description={'View data logs'}
			/>
			<HeaderPortal>
				<Div backgroundColor="#EBEBEB" textAlign="center" padding="md">
					<Typography.Span typoWeight="bold">Logs</Typography.Span>
				</Div>
			</HeaderPortal>
			<Container>Logs</Container>
		</>
	);
};
