import React, { useCallback, useState } from 'react';
import { Observer, observer } from 'mobx-react-lite';
import { useFormik } from 'formik';
import { useHistory, Prompt } from 'react-router-dom';
import {
	Div,
	Button,
	InputGroup,
	Panel,
	Modal,
	Typography,
} from '@icecreamsocial/components';
import { useRouteBlockerPrompt } from '../../../utils/hooks';
import BaseModal from '../BaseModal';

const BlockerPrompt = observer(Prompt);

/**
 * @module RouteChangeBlocker
 * @category Components
 * @subcategory Presentational
 */

/**
 * @description
 * based on [this article]{@link https://medium.com/@michaelchan_13570/using-react-router-v4-prompt-with-custom-modal-component-ca839f5faf39}
 * @param {*} props
 * @todo
 * document this
 */
const RouteChangeBlocker = ({
	when = false,
	title = 'Discard Changes?',
	message = "Any changes you've made won't be saved",
	confirmText = 'Discard Changes',
	cancelText = 'Keep Editing',
	beforeConfirmNavigation = () => {},
	beforeCancelNavigation = () => {},
}) => {
	const {
		showPrompt,
		handleCancelNavigation,
		handleConfirmNavigation,
		overrideMessage,
	} = useRouteBlockerPrompt(when);

	const onConfirm = useCallback(
		(e) => {
			beforeConfirmNavigation();
			handleConfirmNavigation(e);
		},
		[beforeConfirmNavigation]
	);
	const onCancel = useCallback(
		(e) => {
			beforeCancelNavigation();
			handleCancelNavigation(e);
		},
		[beforeCancelNavigation]
	);

	return (
		<>
			<BlockerPrompt when={when} message={overrideMessage} />
			<BaseModal isOpen={showPrompt} elWidth="50%">
				<Panel backgroundColor="bodyInverse">
					<Panel.Body textAlign="center">
						<Typography.H1 typoWeight="500" typoSize="lg">
							{title}
						</Typography.H1>
						<Typography.P marginBottom="lg"> {message}</Typography.P>
						<Div
							display="flex"
							flexDirection="column"
							margin="auto"
							marginBottom="lg"
							elWidth="50%"
						>
							<Button
								onClick={onConfirm}
								display="block"
								marginBottom="md"
								textColor="bodyInverse"
								backgroundColor="brand"
							>
								{confirmText}
							</Button>
							<Button onClick={onCancel} display="block">
								{cancelText}
							</Button>
						</Div>
					</Panel.Body>
				</Panel>
			</BaseModal>
		</>
	);
};
RouteChangeBlocker.displayName = 'RouteChangeBlocker';

export default observer(RouteChangeBlocker);
