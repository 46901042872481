import React from 'react';
import { observer } from 'mobx-react-lite';
import {
	InputGroup,
	Img,
	Panel,
	Typography,
	Button,
	Div,
} from '@icecreamsocial/components';
import {
	RequiredAsterisk,
	EnhancedForm2,
	ImageUploader,
} from '../../../../presentational';
import { flags } from '../../../../../utils';
import ExamplePanel from '../ExamplePanel';

const ShareSettings = ({
	shareTitle = undefined,
	shareMessage = undefined,
	shareImage = undefined,
	emailSubject = undefined,
	emailNote = '',
	redirectUrl = '',
	allowRouteBlock = true,
	renderControls = null,
	onSubmit,
}) => {
	const DISABLE_INVITE_EMAIL = flags.isActive(
		flags.names.DISABLE_INVITE_EMAIL_CONTENT
	);
	const ENABLE_NEW_SHARE = flags.isActive(
		flags.names.ENABLE_NEW_CAMPAIGN_SHARE
	);

	/**
	 * Recommended/example text
	 */
	const recommendedShareTitle =
		'Follow my link to get a discount on {{ product.name }}!';
	const recommendedShareMessage =
		"I'm loving my new {{ product.name }} and thought you might too! Check it out to claim a special discount";
	const recommendedEmailSubject =
		'{{ influencer.name }} sent you a BUSINESS NAME 10% off Promo Code!';

	/**
	 * default text
	 */
	const defaultShareTitle = shareTitle ?? recommendedShareTitle;
	const defaultShareMessage = shareMessage ?? recommendedShareMessage;
	const defaultEmailSubject = emailSubject ?? recommendedEmailSubject;

	const initValues = ENABLE_NEW_SHARE
		? { shareTitle, shareMessage, emailSubject: defaultEmailSubject }
		: {
				shareTitle: defaultShareTitle,
				shareMessage: defaultShareMessage,
				emailSubject: defaultEmailSubject,
		  };

	const formikProps = {
		enableReinitialize: true,

		initialValues: {
			shareImage,
			emailNote,
			redirectUrl,
			...initValues,
		},
		validate: (values) => {
			const ONE_MB = 1024 * 1024; // 1 MB
			const errors = {};
			if (!values.shareImage) {
				errors.shareImage = 'Please upload a Share Image';
			}

			if (values.shareImage?.size > ONE_MB) {
				errors.shareImage = `Your image has exceeded the maximum file size of 1MB. (Size: ${(
					values.shareImage?.size / ONE_MB || 0
				).toFixed(2)}MB )`;
			}

			if (!values.redirectUrl?.match(/https?:\/\//)) {
				errors.redirectUrl = 'Enter a full URL including http:// or https://';
			}

			return errors;
		},
		onSubmit,
	};

	return (
		<EnhancedForm2
			formikProps={formikProps}
			promptIf={({ dirty }) => allowRouteBlock && dirty}
			renderControls={renderControls}
		>
			{(shareSettings) => (
				<>
					{ENABLE_NEW_SHARE ? (
						<Panel backgroundColor='bodyInverse' marginBottom='lg'>
							<Panel.Header
								backgroundColor='bodyInverse'
								textColor='body'
								paddingBottom='0'
							>
								<Typography.H2
									textColor='body'
									textAlign='center'
									margin='none'
									typoSize='md'
									gridColumn='2'
								>
									Share Message
								</Typography.H2>

								<Typography.P marginBottom='0'>
									This content populates the share message when an influencer
									promotes your campaign on social media.{' '}
									<Typography.Span
										as='code'
										backgroundColor='ambientLight'
										padding='2px'
										borderRadius='2px'
									>
										&#123;&#123; Bracketed &#125;&#125;
									</Typography.Span>{' '}
									values are placeholders that will automatically filled with
									relevant content when displayed.
								</Typography.P>
							</Panel.Header>
							<Panel.Body>
								<InputGroup marginBottom='sm' elWidth='100%'>
									<InputGroup.Label>
										Share Title <RequiredAsterisk />
									</InputGroup.Label>
									<InputGroup.Fields marginRight='md'>
										<InputGroup.Input
											id='shareTitle'
											name='shareTitle'
											paddingLeft='md'
											value={shareSettings.values.shareTitle}
											onChange={shareSettings.handleChange}
											required
										/>
									</InputGroup.Fields>
								</InputGroup>
								<InputGroup elWidth='100%'>
									<InputGroup.Label>
										Share Message <RequiredAsterisk />
									</InputGroup.Label>
									<InputGroup.Fields marginRight='md'>
										<InputGroup.Input
											as='textarea'
											id='shareMessage'
											name='shareMessage'
											paddingLeft='md'
											rows='5'
											value={shareSettings.values.shareMessage}
											onChange={shareSettings.handleChange}
											required
										/>
									</InputGroup.Fields>
									<Typography.Span as='small'>
										Tip: If you're using an Invite Rewards, make sure to let
										invitees know they'll get a discount too
									</Typography.Span>
								</InputGroup>
								<ExamplePanel
									title={recommendedShareTitle}
									text={recommendedShareMessage}
									isExampleUsed={
										shareSettings.values.shareMessage !==
											recommendedShareMessage ||
										shareSettings.values.shareTitle !== recommendedShareTitle
									}
									exampleImg={shareSettings.values.shareImage}
									onClick={() => {
										shareSettings.setFieldValue(
											'shareTitle',
											recommendedShareTitle
										);
										shareSettings.setFieldValue(
											'shareMessage',
											recommendedShareMessage
										);
									}}
								></ExamplePanel>
							</Panel.Body>
						</Panel>
					) : (
						<Panel backgroundColor='bodyInverse' marginBottom='lg'>
							<Panel.Header
								backgroundColor='bodyInverse'
								textColor='body'
								paddingBottom='0'
							>
								<Div grid gridColumns='repeat(3, 1fr)' alignItems='center'>
									<Typography.H2
										textColor='body'
										textAlign='center'
										margin='none'
										typoSize='md'
										gridColumn='2'
									>
										Share Copy
									</Typography.H2>
									<Button
										type='button'
										textDecoration={
											shareSettings.values.shareMessage !==
												recommendedShareMessage ||
											shareSettings.values.shareTitle !== recommendedShareTitle
												? 'underline'
												: undefined
										}
										textColor={
											shareSettings.values.shareMessage !==
												recommendedShareMessage ||
											shareSettings.values.shareTitle !== recommendedShareTitle
												? 'link'
												: 'ambient'
										}
										onClick={() => {
											shareSettings.setFieldValue(
												'shareTitle',
												recommendedShareTitle
											);
											shareSettings.setFieldValue(
												'shareMessage',
												recommendedShareMessage
											);
										}}
										backgroundColor='transparent'
										shadow='none'
										hoverShadow='none'
										border='none'
										padding='none'
									>
										Use Recommended Text
									</Button>
								</Div>

								<Typography.P marginBottom='0'>
									If an influencer chooses to share a campaign through social
									media platforms, this is the content that will appear in the
									share message. Any{' '}
									<Typography.Span
										as='code'
										backgroundColor='ambientLight'
										padding='2px'
										borderRadius='2px'
									>
										&#123;&#123; bracketed &#125;&#125;
									</Typography.Span>{' '}
									values are dynamic variables, and will be filled with the
									appropiate content when displayed.
								</Typography.P>
							</Panel.Header>
							<Panel.Body>
								<InputGroup marginBottom='sm' elWidth='100%'>
									<InputGroup.Label>
										Share Title <RequiredAsterisk />
									</InputGroup.Label>
									<InputGroup.Fields marginRight='md'>
										<InputGroup.Input
											id='shareTitle'
											name='shareTitle'
											paddingLeft='md'
											value={shareSettings.values.shareTitle}
											onChange={shareSettings.handleChange}
											required
										/>
									</InputGroup.Fields>
								</InputGroup>
								<InputGroup elWidth='100%'>
									<InputGroup.Label>
										Share Message <RequiredAsterisk />
									</InputGroup.Label>
									<InputGroup.Fields marginRight='md'>
										<InputGroup.Input
											as='textarea'
											id='shareMessage'
											name='shareMessage'
											paddingLeft='md'
											rows='5'
											value={shareSettings.values.shareMessage}
											onChange={shareSettings.handleChange}
											required
										/>
									</InputGroup.Fields>
								</InputGroup>
							</Panel.Body>
						</Panel>
					)}

					<Panel backgroundColor='bodyInverse' marginBottom='lg'>
						<Panel.Header
							backgroundColor='bodyInverse'
							textColor='body'
							paddingBottom='0'
						>
							<Typography.H2
								textColor='body'
								textAlign='center'
								margin='none'
								typoSize='md'
							>
								Share Image <RequiredAsterisk />
							</Typography.H2>
							<Typography.P marginBottom='0'>
								This image will appear along with the Share Copy in shares and
								email invites
							</Typography.P>
						</Panel.Header>
						<Panel.Body>
							<ImageUploader
								name='shareImage'
								value={shareSettings.values.shareImage}
								handleChange={(e) =>
									shareSettings.setFieldValue(
										'shareImage',
										e.currentTarget.files[0]
									)
								}
								onReplace={(e) => {
									e.preventDefault();
									shareSettings.setFieldValue('shareImage', undefined);
								}}
							/>
							{shareSettings.errors.shareImage && (
								<Typography.P textColor='bad'>
									{shareSettings.errors.shareImage}
								</Typography.P>
							)}
							{/* <InputGroup marginBottom="sm" elWidth="100%">
								<InputGroup.Label>
									Image URL <RequiredAsterisk />
								</InputGroup.Label>
								<InputGroup.Fields marginRight="md">
									<InputGroup.Input
										id="shareImage"
										name="shareImage"
										paddingLeft="md"
										value={shareSettings.values.shareImage}
										onChange={shareSettings.handleChange}
										required
									/>
								</InputGroup.Fields>
							</InputGroup> */}
							{/* {!!shareSettings.values.shareImage && (
								<Img
									src={shareSettings.values.shareImage}
									border="1px solid"
									borderColor="ambientDark"
									borderRadius="default"
									elMaxWidth="500px"
								/>
							)} */}
						</Panel.Body>
					</Panel>
					{!DISABLE_INVITE_EMAIL && (
						<Panel backgroundColor='bodyInverse' marginBottom='lg'>
							<Panel.Header
								backgroundColor='bodyInverse'
								textColor='body'
								paddingBottom='0'
							>
								<Div grid gridColumns='repeat(3, 1fr)' alignItems='center'>
									<Typography.H2
										textColor='body'
										textAlign='center'
										margin='none'
										typoSize='md'
										gridColumn='2'
									>
										Invite Email Content
									</Typography.H2>
									<Button
										type='button'
										textDecoration={
											shareSettings.values.emailSubject !==
											recommendedEmailSubject
												? 'underline'
												: undefined
										}
										textColor={
											shareSettings.values.emailSubject !==
											recommendedEmailSubject
												? 'link'
												: 'ambient'
										}
										onClick={() => {
											shareSettings.setFieldValue(
												'emailSubject',
												recommendedEmailSubject
											);
										}}
										backgroundColor='transparent'
										shadow='none'
										hoverShadow='none'
										border='none'
										padding='none'
									>
										Use Recommended Text
									</Button>
								</Div>

								<Typography.P marginBottom='0'>
									If an influencer chooses to share a campaign through email
									with their friends, this is the copy that will appear in that
									email.
								</Typography.P>
							</Panel.Header>
							<Panel.Body>
								<InputGroup marginBottom='sm' elWidth='100%'>
									<InputGroup.Label>
										Email Subject <RequiredAsterisk />
									</InputGroup.Label>
									<InputGroup.Fields marginRight='md'>
										<InputGroup.Input
											id='emailSubject'
											name='emailSubject'
											paddingLeft='md'
											value={shareSettings.values.emailSubject}
											onChange={shareSettings.handleChange}
											required
										/>
									</InputGroup.Fields>
								</InputGroup>
								<InputGroup elWidth='100%'>
									<InputGroup.Label>Additional Message</InputGroup.Label>
									<InputGroup.Fields marginRight='md'>
										<InputGroup.Input
											as='textarea'
											id='emailNote'
											name='emailNote'
											paddingLeft='md'
											rows='5'
											value={shareSettings.values.emailNote}
											onChange={shareSettings.handleChange}
										/>
									</InputGroup.Fields>
								</InputGroup>
							</Panel.Body>
						</Panel>
					)}
					<Panel backgroundColor='bodyInverse' marginBottom='lg'>
						<Panel.Header
							backgroundColor='bodyInverse'
							textColor='body'
							paddingBottom='0'
						>
							<Typography.H2
								textColor='body'
								textAlign='center'
								margin='none'
								typoSize='md'
							>
								Redirect URL
							</Typography.H2>
							<Typography.P marginBottom='0'>
								Clicking the share will take the invitee here
							</Typography.P>
						</Panel.Header>
						<Panel.Body>
							<InputGroup marginBottom='sm' elWidth='100%'>
								<InputGroup.Label>
									Redirect URL <RequiredAsterisk />
								</InputGroup.Label>
								<InputGroup.Fields marginRight='md'>
									<InputGroup.Input
										id='redirectUrl'
										name='redirectUrl'
										type='url'
										pattern='https?://.*'
										title='Enter a full URL including http:// or https://'
										paddingLeft='md'
										value={shareSettings.values.redirectUrl}
										onChange={shareSettings.handleChange}
										required
									/>
								</InputGroup.Fields>
								{shareSettings.errors.redirectUrl && (
									<Typography.P textColor='bad'>
										{shareSettings.errors.redirectUrl}
									</Typography.P>
								)}
							</InputGroup>
						</Panel.Body>
					</Panel>
				</>
			)}
		</EnhancedForm2>
	);
};
ShareSettings.displayName = 'ShareSettingsForm';

export default observer(ShareSettings);
