import React from "react";
import { observer } from "mobx-react-lite";
import { useFormik } from "formik";
import { useLocation, useHistory } from "react-router-dom";
import { InputGroup } from "@icecreamsocial/components";
import { useQueryString } from "../../../utils/hooks";
import { SearchIcon } from "..";
/**
 * @module QuerySearchBar
 * @category Components
 * @subcategory Presentational
 */

/**
 * @description
 * This is form consisting of one text input field and a button to submit or reset the form.
 * This form mutates history by appending or removing the search value to the url.  This is useful for making a shareable
 * filter search.
 *
 * This form is *NOT* responsible for making API calls.  The implementer should instead subscribe to the url value that they register to
 * a `<QuerySearchBar />` denoted by the `name` property
 * @example
 * // SomeList.js
 *
 * const SomeList = () => {
 * 		//...other logic
 * 		const SEARCH_PARAM = 'mySearchField';
 * 		const params = getURLParamsSomeHow();
 *
 * 		useEffect(() => {
 * 			if (params[SEARCH_PARAM]) {
 * 				callAPIToFind(params[SEARCH_PARAM]);
 * 			}
 * 		}, [params[SEARCH_PARAM]]);
 *
 * 	return <QuerySearchBar id="mySearchBar" name={SEARCH_PARAM} placeholder="Find something"/>
 * }
 *
 * @note
 *  using button type='reset' sometimes called the form reset unpredictably
 *  not using button type='button' on clear would cause form to trigger onSubmit aswell
 * @todo
 * document this
 */
const QuerySearchBar = ({
  id = undefined,
  autoFocus = false,
  placeholder = "",
  name,
}) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { query, addParam, withoutParam } = useQueryString();

  // this is renamed just for clarity
  const queryName = name;
  const queryValue = query[name];
  const search = useFormik({
    enableReinitialize: true,
    initialValues: {
      [queryName]: queryValue || "",
    },
    onSubmit: (values) => {
      let url = pathname;
      if (values[queryName]) {
        url = `${pathname}?${addParam({
          key: `${queryName}`,
          value: values[queryName],
        })}`;
      } else {
        const without = withoutParam(queryName);
        url = `${pathname}${without ? `?${without}` : ""}`;
      }
      history.push(url);
    },
  });

  /**
   * @note
   * We are enabling "enableReinitialize" so that formik can update the form when initialValues is updated
   * However, whenever a submit happens, initialValues is updated and formik calls reset automatically therefor nullifying the submit
   * in order to prevent this, we are using our own handleReset function
   */
  const handleReset = () => {
    const without = withoutParam(queryName);
    const url = `${pathname}${without ? `?${without}` : ""}`;
    history.push(url);
  };

  return (
    <form onSubmit={search.handleSubmit} data-slate-search={id || queryName}>
      <InputGroup elWidth="100%" marginBottom="lg">
        <InputGroup.Fields>
          <InputGroup.Icon textColor="ambientDark">
            <SearchIcon />
          </InputGroup.Icon>
          <InputGroup.Input
            id={id || queryName}
            name={queryName}
            value={search.values[queryName]}
            onChange={search.handleChange}
            placeholder={placeholder}
            autoFocus={autoFocus}
          />
          <InputGroup.Actions display="flex" alignItems="center">
            {search.initialValues[queryName] === "" ||
            search.initialValues[queryName] !== search.values[queryName] ? (
              <InputGroup.Action>Search</InputGroup.Action>
            ) : (
              <InputGroup.Action type="button" onClick={handleReset}>
                Clear
              </InputGroup.Action>
            )}
          </InputGroup.Actions>
        </InputGroup.Fields>
      </InputGroup>
    </form>
  );
};

QuerySearchBar.displayName = "QuerySearchBar";
export default observer(QuerySearchBar);
