import React from 'react';
import { Thumbnail } from '@icecreamsocial/components';
import { observer } from 'mobx-react-lite';

const Logo = ({ src, ...rest }) => {
	return <Thumbnail src={src} borderRadius="50%" {...rest} />;
};

Logo.displayName = 'Logo';
export default observer(Logo);
