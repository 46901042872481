import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import {
	Button,
	Div,
	Img,
	Panel,
	Typography,
	Spanner,
	Container,
} from '@icecreamsocial/components';
import { EmphasizedAction } from '..';
import unfinishedBusiness from '../../../assets/unfinished_business.svg';

const BusinessSetupMessage = ({ ...rest }) => {
	return (
		<Div display="flex" alignItems="center" {...rest}>
			<Img
				src={unfinishedBusiness}
				elWidth="180px"
				padding="md"
				marginRight="md"
			/>
			<Div flexGrow="1">
				<Typography.Span typoSize="lg" typoWeight="500" margin="0">
					Business settings not configured
				</Typography.Span>
				<Typography.P>Take a minute to set these up</Typography.P>
				<EmphasizedAction as={Link} to="/business-setup" textDecoration="none">
					Set up
				</EmphasizedAction>
			</Div>
		</Div>
	);
};

BusinessSetupMessage.displayName = 'BusinessSetupMessage';
export default BusinessSetupMessage;
