import React from 'react';
import { observer } from 'mobx-react-lite';
import {
	Button,
	Div,
	Typography,
	List,
	Circle,
} from '@icecreamsocial/components';
import { ChevronLeftIcon, ChevronRightIcon } from '../SupportedIcons';
import { formatDateTime } from '../../../utils';
import PopsicleLoader from '../PopsicleLoader';

type CondensedReward = {
	id: string;
	rewardAmount: number;
	rewardType: string;
	updatedAt: string;
};

type CondensedRewardProps = {
	collection: CondensedReward[];
	currency?: string;
	progressPending: boolean;
};

/**
 * Displays a condensed list of Rewards
 * Can be made selectable.  Will function as an uncontrolled component.
 */
const CondensedRewards = ({
	collection = [],
	currency = 'USD',
	progressPending,
}: CondensedRewardProps) => {
	/**
	 * this assumes the collection is not paginated.  consider moving this out and passing them as props.
	 * keeping this here to make it simple until we have a need for paginating large results
	 */
	const pagination = {
		total: collection.length,
		start: 1,
		end: collection.length,
	};

	if (progressPending)
		return (
			<Div textAlign='center' elHeigh='75px'>
				<PopsicleLoader />
			</Div>
		);

	return collection.length ? (
		<>
			<List bullets='none' borderTop='1px solid' borderColor='ambient'>
				{collection.map(({ rewardAmount = 0, rewardType, id, updatedAt }) => (
					<List.Item
						key={id}
						borderBottom='1px solid'
						borderColor='ambient'
						padding='md'
						marginBottom='0'
						display='flex'
						alignItems='center'
						style={{ gap: '16px' }}
					>
						<Div flex='1 1'>
							<Div
								display='flex'
								alignItems='center'
								justifyContent='space-between'
								style={{ gap: '8px' }}
							>
								<Div>
									<Typography.H3
										title={rewardType}
										textTransform='capitalize'
										typoSize='md'
										typoWeight='500'
										margin='0'
										marginBottom='sm'
										overflow='hidden'
										style={{ whiteSpace: 'noWrap', textOverflow: 'ellipsis' }}
									>
										{rewardType}
									</Typography.H3>
									<Typography.Span
										flexShrink='0'
										display='block'
										typoSize='md'
										typoWeight='500'
									>
										{new Intl.NumberFormat('en-US', {
											style: 'currency',
											currency,
										}).format(rewardAmount)}{' '}
										{currency}
									</Typography.Span>
								</Div>
								<Div textAlign='right'>
									<Typography.Span textColor='ambientDark'>
										{formatDateTime(new Date(updatedAt))}
									</Typography.Span>
								</Div>
							</Div>
						</Div>
					</List.Item>
				))}
			</List>
			<Div
				elWidth='100%'
				backgroundColor='ambientLight'
				display='flex'
				alignItems='center'
				padding='md'
				borderBottomLeftRadius='default'
				borderBottomRightRadius='default'
			>
				<Typography.Span flex='1 0'>
					{pagination.start} - {pagination.end} of {pagination.total} rewards
				</Typography.Span>
				<Div display='flex' flex='0 1' style={{ gap: '8px' }}>
					<Circle
						as={Button}
						padding='sm'
						backgroundColor='transparent !important'
						textColor='body'
						shadow='none'
						disabled={pagination.start === 1}
					>
						<ChevronLeftIcon />
					</Circle>
					<Circle
						as={Button}
						padding='sm'
						backgroundColor='transparent !important'
						textColor='body'
						shadow='none'
						disabled={pagination.end === pagination.total}
					>
						<ChevronRightIcon />
					</Circle>
				</Div>
			</Div>
		</>
	) : (
		<Div
			borderTop='1px solid'
			borderBottom='1px solid'
			borderColor='ambient'
			padding='lg'
		>
			<Typography.Span
				display='block'
				textAlign='center'
				textColor='ambientDark'
			>
				No rewards issued
			</Typography.Span>
		</Div>
	);
};

CondensedRewards.displayName = 'CondensedRewards';

export default observer(CondensedRewards);
