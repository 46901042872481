import { types } from 'mobx-state-tree';
import { IcsClient } from '@icecreamsocial/mst-models';

const ClientsCollection = types
	.model({
		clientMap: types.map(IcsClient),
		list: types.array(types.reference(IcsClient)),
	})
	.named('ClientsCollection')
	.preProcessSnapshot((snapshot = {}) => ({
		clientMap: snapshot.clientMap || {},
		list: snapshot.list || [],
	}))
	.views((self) => ({
		get hasClients()
		{
			return !!self.list.length;
		},
		find(id)
		{
			return self.list.find((ref) => ref.id === id);
		},
	}))
	.actions((self) => ({
		addClient(client)
		{
			self.clientMap.put(client);
		},
		addClients(clients = [])
		{
			clients.forEach((client) => self.addClient(client));
		},
		setClientList(clientRefs = [])
		{
			self.list.replace(clientRefs);
		},
		updateClient(id, client)
		{
			self.clientMap.set(id, client);
		},
		clearAll()
		{
			self.setClientList();
			self.clientMap.clear();
		}
	}));

export default ClientsCollection;
